import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Box from "@material-ui/core/Box";
import NavBar from "./components/NavBar";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useAppEventTracking } from "../../common/useAppEventTracking";
// Assets
import { ReactComponent as ArrowRight } from "../../assets/icons/ArrowRight.svg";
// Redux
import { submitSearchRequest } from "../../store/search/requests";
import {
    getCompanyName,
    getCompanyWebsite,
    getCompanyDescription,
} from "../../store/search/selectors";
import { getActiveViewSelector } from "../../store/results/selectors";

const AddMoreDetails = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { state: locationState, pathname } = useLocation();
    const dispatch = useDispatch();
    const [animateOut, setAnimateOut] = useState(false);
    const [animateInBack, setAnimateInBack] = useState(false);
    const [animateOutBack, setAnimateOutBack] = useState(false);
    const [nameValue, setNameValue] = useState("");
    const [descriptionValue, setDescriptionValue] = useState("");
    const [websiteValue, setWebsiteValue] = useState("");
    const [formValid, setFormValid] = useState(false);
    const companyName = useSelector(getCompanyName);
    const companyWebsite = useSelector(getCompanyWebsite);
    const companyDescription = useSelector(getCompanyDescription);
    const { mixpanelTrackEvent } = useAppEventTracking();
    const viewType = useSelector(getActiveViewSelector);

    useEffect(() => {
        setNameValue(companyName);
        setWebsiteValue(companyWebsite);
        setDescriptionValue(companyDescription);
    }, [companyName, companyWebsite, companyDescription]);

    const handleNameChange = e => {
        setNameValue(e.target.value);
    };

    const handleDescriptionChange = e => {
        setDescriptionValue(e.target.value);
    };

    const handleWebsiteChange = e => {
        setWebsiteValue(e.target.value);
    };

    useEffect(() => {
        if (nameValue !== "" && descriptionValue !== "") {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [nameValue, descriptionValue, websiteValue]);

    const findSimilarCompanies = () => {
        localStorage.setItem("lookalikeDescriptionValue", descriptionValue);
        localStorage.setItem("lookalikeNameValue", nameValue);
        localStorage.setItem("lookalikeWebsiteValue", websiteValue);
        dispatch(
            submitSearchRequest({
                description: descriptionValue,
                searchSectors: null,
                scope: "Find look-alike",
                searchType: 1,
                lookAlikeName: nameValue,
                perPage: viewType === "card" ? 10 : 50,
                resultsLimit: 100,
            }),
        );
        mixpanelTrackEvent("Company search", {
            Type: "Look-alike",
            Page: "/add-more-details",
            "Company name": nameValue,
            "Company description": descriptionValue,
            "Company website": websiteValue,
        });
        setAnimateInBack(false);
        setAnimateOut(true);
        setTimeout(() => {
            const path = "/search-results-loading";
            history.replace(path, { from: pathname, someOtherProp: "" });
        }, 200);
    };

    const goBack = () => history.goBack();

    useEffect(() => {
        setAnimateInBack(
            locationState && locationState.from === "/search-results-loading",
        );
    }, [locationState]);

    return (
        <div
            className={classnames([
                {
                    [classes.animateOut]: animateOut,
                    [classes.animateInBack]: animateInBack,
                    [classes.animateOutBack]: animateOutBack,
                    [classes.animateIn]: !animateInBack,
                },
            ])}>
            <div className={classnames([classes.root, classes.roleRoot])}>
                <NavBar />
                <div className={classes.industryRoot}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-between"
                        className={classnames([
                            classes.wrapper,
                            classes.roleWrapper,
                        ])}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={classes.contentWrapper}>
                            <Typography
                                variant="subtitle1"
                                className={classes.industryTitle}>
                                {t("addMoreItems.title")}
                            </Typography>
                            {/* <form className={classes.form}> */}
                            <div className={classes.formFieldsWrapper}>
                                <FormControl className={classes.inputWrapper}>
                                    <Typography
                                        variant="body"
                                        color="textLighter"
                                        gutterBottom
                                        className={classes.mono}>
                                        {t("addMoreItems.nameLabel")}
                                        <Typography
                                            variant="bodySmall"
                                            color="primaryDark"
                                            gutterBottom
                                            className={classes.mono}>
                                            {t("addMoreItems.star")}
                                        </Typography>
                                    </Typography>
                                    <TextField
                                        color="primary"
                                        variant="outlined"
                                        id="name"
                                        InputLabelProps={{
                                            required: true,
                                            shrink: true,
                                            classes: {
                                                root: classes.inputLabel,
                                                shrink: classes.labelShrinked,
                                                focused: classes.focusedLabel,
                                            },
                                        }}
                                        value={nameValue}
                                        onChange={handleNameChange}
                                        className={classes.newTextField}
                                        InputProps={{
                                            classes: {
                                                underline: classes.input,
                                                root: classes.inputRoot,
                                            },
                                            fullWidth: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl className={classes.inputWrapper}>
                                    <Typography
                                        variant="body"
                                        color="textLighter"
                                        gutterBottom
                                        className={classes.mono}>
                                        {t("addMoreItems.descriptionLabel")}
                                        <Typography
                                            variant="bodySmall"
                                            color="primaryDark"
                                            gutterBottom
                                            className={classes.mono}>
                                            {t("addMoreItems.star")}
                                        </Typography>
                                    </Typography>
                                    <TextField
                                        multiline
                                        color="primary"
                                        variant="outlined"
                                        InputLabelProps={{
                                            required: true,
                                            shrink: true,
                                            classes: {
                                                root: classes.inputLabel,
                                                shrink: classes.labelShrinked,
                                                focused: classes.focusedLabel,
                                            },
                                        }}
                                        id="description"
                                        value={descriptionValue}
                                        onChange={handleDescriptionChange}
                                        className={classes.newTextField}
                                        InputProps={{
                                            classes: {
                                                underline: classes.input,
                                                root: classes.inputRoot,
                                            },
                                            fullWidth: true,
                                        }}
                                    />
                                    <Typography
                                        variant="label"
                                        className={classnames([
                                            classes.characterCount,
                                            classes.mono,
                                        ])}>
                                        {descriptionValue &&
                                            descriptionValue.length}
                                        /100
                                    </Typography>
                                </FormControl>
                                <FormControl className={classes.inputWrapper}>
                                    <Typography
                                        variant="body"
                                        color="textLighter"
                                        gutterBottom
                                        className={classes.mono}>
                                        {t("addMoreItems.websitePlaceholder")}
                                        <Typography
                                            variant="bodySmall"
                                            color="primaryDark"
                                            gutterBottom
                                            className={classes.mono}>
                                            {t("addMoreItems.optional")}
                                        </Typography>
                                    </Typography>
                                    <TextField
                                        id="website"
                                        type="text"
                                        color="primary"
                                        variant="outlined"
                                        value={websiteValue}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                root: classes.inputLabel,
                                                shrink: classes.labelShrinked,
                                                focused: classes.focusedLabel,
                                            },
                                        }}
                                        onChange={handleWebsiteChange}
                                        className={classes.newTextField}
                                        InputProps={{
                                            classes: {
                                                underline: classes.input,
                                                root: classes.inputRoot,
                                            },
                                            fullWidth: true,
                                        }}
                                    />
                                    <Typography
                                        variant="label"
                                        className={classnames([
                                            classes.characterCount,
                                            classes.mono,
                                        ])}>
                                        {websiteValue && websiteValue.length}
                                        /100
                                    </Typography>
                                </FormControl>
                            </div>
                            <div className={classes.ctasWrapper}>
                                <div className={classes.createAccountButton}>
                                    <div>
                                        <Typography
                                            onClick={() => goBack()}
                                            variant="body2"
                                            rotateIcon
                                            color="black"
                                            className={classnames([
                                                classes.backToCompany,
                                                classes.mono,
                                            ])}>
                                            <ArrowRight
                                                className={classes.backToArray}
                                            />
                                            {t("addMoreItems.backTo")}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.centeredContent}>
                                <Button
                                    variant="primaryDarkContained"
                                    disabled={!formValid}
                                    className={classes.findSimilarCompanies}
                                    onClick={findSimilarCompanies}>
                                    {t("industry.search")}
                                </Button>
                            </div>
                            {/* </form> */}
                        </Box>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default withRouter(AddMoreDetails);
