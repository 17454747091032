import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";
export const INITIAL_STATE = {
    getClusterStatus: REQUEST_UNDEFINED,
    clusterData: null,
};

export const REDUCER_PREFIX = "cluster";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getGenerateClusterDataStatusPanding: state => {
            state.getClusterStatus = REQUEST_PENDING;
        },
        getGenerateClusterDataStatusSuccess: state => {
            state.getClusterStatus = REQUEST_SUCCESS;
        },
        getGenerateClusterDataStatusFailure: state => {
            state.getClusterStatus = REQUEST_FAILURE;
        },
        setGenerateClusterData: (state, { payload }) => {
            state.clusterData = payload;
        },
    },
});
export { reducer, actions };
