import React, { useEffect, useState } from "react";
// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../../../components/Button/Button";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useAppEventTracking } from "../../../../common/useAppEventTracking";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import OrderReportImg from "../../../../assets/images/NoOrders.png";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getEnrichCompanyStatusSelector } from "../../../../store/projects/selectors";
import { enrichCompanyRequest } from "../../../../store/projects/requests";
import { enrichTechnologyRequest } from "../../../../store/technologies/requests";
import {
    enrichTechnologyCompanyStatusSelector,
    enrichTechnologyStatusSelector,
} from "../../../../store/technologies/selectors";
import { useIntercom } from "react-use-intercom";
// Constants
import {
    REQUEST_PENDING,
    REQUEST_SUCCESS,
} from "../../../../constants/statuses";

const EnrichRequestModal = ({
    technologySlug,
    dialogOpened,
    onChange,
    company,
    enrichSuccess,
    credits,
    cId,
    source,
}) => {
    const { t } = useTranslation();
    const { show } = useIntercom();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();

    // useEffect(() => {
    //     if (!technologySlug) {
    //         const chosenProject = projects.length > 0 && projects.find(project => project.is_in_project === true);
    //         setSelectedProject(chosenProject);
    //     }
    // }, [projects])

    const getEnrichCompanyStatus = useSelector(getEnrichCompanyStatusSelector);
    const getEnrichTechnologyCompany = useSelector(
        enrichTechnologyCompanyStatusSelector,
    );
    const getEnrichTechnology = useSelector(enrichTechnologyStatusSelector);
    const [selectedProject, setSelectedProject] = useState();
    // const handleSelectProject = (project) => {
    //     if (selectedProject && selectedProject.slug === project.slug) {
    //         setSelectedProject(null);
    //     } else {
    //         setSelectedProject(project);
    //     }
    // };
    const enrich = () => {
        // if (selectedProject) {
        //     technologySlug ? dispatch(enrichTechnologyCompanyRequest({ slug: technologySlug, projectSlug: selectedProject.slug, cId }))
        //         :
        //         dispatch(enrichRequest({ company, projectSlug: selectedProject.slug, cId: cId || company.id }));
        // } else {
        //     dispatch(enrichTechnologyRequest({ slug: technologySlug, }))
        // }
        source === "company"
            ? dispatch(
                  enrichCompanyRequest({
                      cId: cId || company.id,
                      slug: company.slug,
                  }),
              )
            : dispatch(enrichTechnologyRequest({ slug: technologySlug }));
    };

    const openIntercom = e => {
        e.preventDefault();

        if (process.env.REACT_APP_ENV === "prod") {
            show();
        }
    };

    useEffect(() => {
        let intercomEventName = "Enrichment request";
        let mixpanelEventName = "Company report requested";
        let data = { info: "Not specified" };
        if (getEnrichCompanyStatus === REQUEST_SUCCESS) {
            data = {
                "Company slug": company?.slug,
                "Company name": company?.name,
            };
        } else if (getEnrichTechnology === REQUEST_SUCCESS) {
            intercomEventName = "Technology research requested";
            mixpanelEventName = "Technology report requested ";
            data = {
                "Technology slug": technologySlug,
            };
        }

        if (
            getEnrichCompanyStatus === REQUEST_SUCCESS ||
            getEnrichTechnology === REQUEST_SUCCESS
        ) {
            enrichSuccess();
            //Intercom event tracking
            intercomTrackEvent(intercomEventName, data);
            //Mixpanel event tracking
            mixpanelTrackEvent(mixpanelEventName, data);
        }
    }, [getEnrichCompanyStatus, getEnrichTechnology]);

    return (
        <Dialog
            onClose={onChange}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    onChange(event, reason);
                }
            }}
            open={dialogOpened}>
            <IconButton className={classes.closeButton} onClick={onChange}>
                <img
                    src={CloseIcon}
                    alt={t("scope.step2")}
                    className={classes.stepIcon}
                />
            </IconButton>
            <img
                src={OrderReportImg}
                alt={t("companyProfile.orderAnalystResearchPaper")}
            />
            <MuiDialogTitle disableTypography>
                <Typography
                    component="div"
                    variant="h6"
                    color="black"
                    className={classes.title}>
                    {t("companyProfile.orderAnalystResearchPaper")}
                </Typography>
                <Typography component="div" variant="bodySmall" align="center">
                    {t("companyProfile.thisWillCost", { count: 1 })}
                </Typography>
                <Typography component="div" variant="bodySmall" align="center">
                    {t("companyProfile.youHaveCredits", { count: credits })}
                </Typography>
                <Typography
                    component="div"
                    variant="bodySmall"
                    align="center"
                    className={classes.analystsCanPrepare}>
                    {t("companyProfile.analystsCanPrepare")}
                </Typography>
            </MuiDialogTitle>
            {/*{source !== 'technology' && <MuiDialogContent className={classes.dialogContent}>*/}
            {/*    {projects && projects.length === 0 ? <Typography className={classes.noCreatedProjects} variant="body2">{t("enrichRequest.noCreatedProjects")}</Typography>*/}
            {/*        : projects && projects.length > 0 && projects.map((project, i) => {*/}
            {/*            return <div className={classes.projectRow} key={i} onClick={() => handleSelectProject(project)}>*/}
            {/*                <Typography variant="body2" className={classnames([classes.projectTitle, { [classes.selectedProject]: selectedProject && selectedProject.slug === project.slug }])}>{project.project}</Typography>*/}
            {/*                {selectedProject && selectedProject.slug === project.slug ? <CheckMarkChecked /> :*/}
            {/*                    <CheckMark />}*/}
            {/*            </div>*/}
            {/*        })*/}
            {/*    }*/}
            {/*</MuiDialogContent>}*/}
            <div className={classes.buttons}>
                {/*{source !== 'technology' &&*/}
                {/*    <Button*/}
                {/*        className={classes.createButton}*/}
                {/*        onClick={createProjectDialogOpened}*/}
                {/*        variant="primary">*/}
                {/*        {t("enrichRequest.createProject")}*/}
                {/*    </Button>*/}
                {/*}*/}
                <Button
                    className={classes.requestButton}
                    loading={getEnrichCompanyStatus === REQUEST_PENDING}
                    onClick={enrich}
                    data-intercom-target={
                        source === "company"
                            ? "Request enrichment button"
                            : "Request deep dive button"
                    }
                    variant="primary">
                    {t("companyProfile.requestEnrichment")}
                </Button>
                <Button
                    className={classes.cancelButton}
                    onClick={onChange}
                    data-intercom-target={
                        source === "company"
                            ? "Cancel enrichment button"
                            : "Cancel deep dive"
                    }
                    variant="tertiary">
                    {t("enrichRequest.cancel")}
                </Button>
            </div>
            <div className={classes.footer}>
                <Typography
                    component="div"
                    variant="bodySmall"
                    color="textSecondary"
                    align="center">
                    {t("companyProfile.enrichRequestModalFooter.text")}
                    <a onClick={openIntercom}>
                        {t("companyProfile.enrichRequestModalFooter.link")}
                    </a>
                </Typography>
            </div>
        </Dialog>
    );
};
export default EnrichRequestModal;
