import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
    createStyles({
        disabled: {
            "& .MuiIconButton-label": {
                backgroundColor: `${palette.text.lightest} !important`,
                border: `1px solid ${palette.text.backgroundMain}`,
                borderRadius: 4,
                "& svg": {
                    opacity: 0,
                },
            },
        },
        smallCheckbox: {
            width: 20,
            height: 20,
        },
        indeterminate: {
            color: palette.primary.dark,
            "& svg": {
                width: 32,
                height: 32,
                marginLeft: -3,
                marginRight: -2,
            },
        },
    }),
);
