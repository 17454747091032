import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    postMixpanelStatus: REQUEST_UNDEFINED,
};

export const REDUCER_PREFIX = "mixpanel";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        postMixpanelPending: state => {
            state.postMixpanelStatus = REQUEST_PENDING;
        },
        postMixpanelSuccess: state => {
            state.postMixpanelStatus = REQUEST_SUCCESS;
        },
        postMixpanelFailure: state => {
            state.postMixpanelStatus = REQUEST_FAILURE;
        },
    },
});
export { reducer, actions };
