import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        dialogRoot: {
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: "left",
        },
        dialog: {
            padding: spacing(6),
            background: palette.primary.backgroundLight,
            borderRadius: spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [breakpoint.down("lg")]: {
                padding: spacing(4),
            },
            [breakpoint.down("sm")]: {
                margin: 0,
                height: "100%",
                maxHeight: "none",
                borderRadius: 0,
            },
        },
        dialogScrollPaper: {
            maxWidth: 800,
        },
        dialogContent: {
            overflow: "auto",
            padding: 0,
            "&::-webkit-scrollbar": {
                width: 2,
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: palette.text.main,
                borderRadius: 0,
            },
        },
        closeButton: {
            position: "absolute !important",
            right: spacing(4),
            top: spacing(3),
            color: palette.grey[500],
        },
        dialogTitle: {
            // borderBottom: `1px solid ${palette.text.lighter}`,
            width: "100%",
            textAlign: "center",
            paddingBottom: spacing(3),
            marginBottom: spacing(5),
            [breakpoint.down("sm")]: {
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "center",
            },
        },
        goNext: {
            cursor: "pointer",
            width: "100%",
            marginTop: spacing(4),
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        arrowImg: {
            marginLeft: spacing(2),
        },
        lockIcon: {
            marginLeft: spacing(3),
        },
        requestButton: {
            marginTop: spacing(6),
        },
    }),
);
