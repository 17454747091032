import React from "react";
import { useEffect } from "react";
// Hooks
import { useStyles } from "./style.js";
import { useSelector } from "react-redux";
// Components
import CreateProjectModal from "../../Results/components/modals/CreateProjectModal.js";
import { mixpanelTrackRequest } from "../../../store/mixpanel/requests";
import Typography from "../../../components/Typography/Typography";
import TopUpCreditsModal from "../../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";
import { useProjectProfileHook } from "../hooks/useProjectProfileHook.js";
//Constants
import { getPricingPage } from "../../../constants/endpoints";
import { REQUEST_SUCCESS } from "../../../constants/statuses";
// Redux
import { getSearchesSelector } from "../../../store/results/selectors";
import { getUpdateProfileStatusSelector } from "../../../store/auth/selectors";
import { fetchSearchesRequest } from "../../../store/results/requests";

const ProjectHeader = ({ projects, user }) => {
    const classes = useStyles();
    const searches = useSelector(getSearchesSelector);
    const {
        t,
        dispatch,
        createProjectModalShow,
        handleClosingDialog,
        topUpCreditsModalShow,
        handleBuyingTopUpCreditsDialog,
        handleClosingTopUpCreditsDialog,
    } = useProjectProfileHook({ projects });
    const getUpdateProfileStatus = useSelector(getUpdateProfileStatusSelector);

    useEffect(() => {
        const data = {
            orderDirection: "desc",
            orderBy: "created-at",
            page: 1,
            savedSearches: true,
        };
        (!searches || false) && dispatch(fetchSearchesRequest({ data }));
    }, []);

    useEffect(() => {
        dispatch(mixpanelTrackRequest({ eventName: "Projects" }));
    }, [dispatch]);

    useEffect(() => {
        if (getUpdateProfileStatus === REQUEST_SUCCESS) {
            window.location.href = getPricingPage(!!user && "free");
        }
    }, [getUpdateProfileStatus]);

    return (
        <div className={classes.root}>
            <div className={classes.flexColumn}>
                <div className={classes.flex}>
                    <div className={classes.numProjects}>
                        <Typography
                            className={classes.title}
                            variant="subtitle1">
                            <strong>{t("projectHeader.title")}</strong>
                        </Typography>
                    </div>
                    {/* <Button className={classes.createProjectButton} onClick={openCreateProjectModal} variant="primary">{t("projectHeader.createProject")}</Button> */}
                </div>
                {/* <TextField fullWidth={isLess768} endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput} onChange={handleSearch}></TextField> */}
            </div>

            <CreateProjectModal
                source="project"
                dialogOpened={createProjectModalShow}
                onChange={handleClosingDialog}
            />
            <TopUpCreditsModal
                dialogOpened={topUpCreditsModalShow}
                onBuy={handleBuyingTopUpCreditsDialog}
                onChange={handleClosingTopUpCreditsDialog}
            />
        </div>
    );
};
export default ProjectHeader;
