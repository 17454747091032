import { all, takeEvery } from "redux-saga/effects";
import { fetchSectorsRequest, fetchSubsectorsRequest } from "../requests";
import { fetchSectors, fetchSubsectors } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchSectors() {
    yield takeEvery(convertTypeFromAction(fetchSectorsRequest), fetchSectors);
}

function* watchFetchSubsectors() {
    yield takeEvery(
        convertTypeFromAction(fetchSubsectorsRequest),
        ({ payload: sectorsSlug }) => fetchSubsectors(sectorsSlug),
    );
}

function* saga() {
    yield all([watchFetchSectors(), watchFetchSubsectors()]);
}

export { saga };
