import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import {
    getIndustries,
    getIndustry,
    getIndustryCompany,
    getIndustryCompanies,
    postEnrichIndustry,
} from "./helpers";
import {
    getCompaniesChart,
    getTeamSizeChart,
} from "../../results/saga/helpers";

function* fetchIndustries(page) {
    try {
        yield put(actions.fetchIndustriesPending());
        const response = yield call(getIndustries, page);
        yield put(actions.setIndustries(response.data));
        yield put(actions.fetchIndustriesSuccess());
    } catch (err) {
        yield put(actions.fetchIndustriesFailure());
    }
}

function* fetchIndustry({ slug }) {
    try {
        yield put(actions.fetchIndustryPending());
        const response = yield call(getIndustry, slug);
        yield put(actions.setIndustry(response));
        yield put(actions.fetchIndustrySuccess());
    } catch (err) {
        yield put(actions.fetchIndustryFailure());
    }
}

function* fetchIndustryCompany({ slug }) {
    try {
        yield put(actions.fetchIndustryCompanyPending());
        const response = yield call(getIndustryCompany, slug);
        yield put(actions.setIndustryCompany(response));
        yield put(actions.fetchIndustryCompanySuccess());
    } catch (err) {
        yield put(actions.fetchIndustryCompanyFailure());
    }
}

function* fetchIndustryCompanies(slug, data, clearResults, isGuest) {
    try {
        if (clearResults) yield put(actions.clearCompaniesResults());
        yield put(actions.fetchIndustryCompaniesPending());
        isGuest
            ? yield put(actions.setIsGuest(true))
            : yield put(actions.setIsGuest(false));
        const response = yield call(getIndustryCompanies, slug, data);

        yield put(actions.setIndustryResults(response.data));
        yield put(actions.fetchIndustryCompaniesSuccess());
    } catch (err) {
        yield put(actions.fetchIndustryCompaniesFailure());
    }
}

function* fetchIndustryCompaniesCount(
    slug,
    data,
    clearResults,
    isGuest,
    lastFilteredField,
    lastFilteredFieldValue,
) {
    try {
        yield put(actions.fetchIndustryCompaniesCountPending());
        isGuest
            ? yield put(actions.setIsGuest(true))
            : yield put(actions.setIsGuest(false));
        const response = yield call(getIndustryCompanies, slug, data);

        yield put(
            actions.setIndustryCompaniesCount({
                sliceData: response.data,
                lastFilteredField,
                lastFilteredFieldValue,
            }),
        );
        yield put(actions.setLastFilteredField(lastFilteredField));
        yield put(actions.fetchIndustryCompaniesCountSuccess());
    } catch (err) {
        yield put(actions.fetchIndustryCompaniesCountFailure());
    }
}

function* fetchIndustryCompaniesChart(id, type, grouped, filterNameValue) {
    try {
        yield put(actions.getIndustryCompaniesChartPending());
        const response = yield call(
            getCompaniesChart,
            id,
            type,
            grouped,
            filterNameValue,
        );
        yield put(actions.getIndustryCompaniesChartSuccess());
        yield put(actions.setIndustryCompaniesChart(response.data));
    } catch (err) {
        yield put(actions.getIndustryCompaniesChartFailure());
    }
}

function* fetchIndustryCompaniesTeamSizeChart(
    id,
    type,
    grouped,
    filterNameValue,
) {
    try {
        yield put(actions.getIndustryCompaniesTeamSizeChartPending());
        const response = yield call(
            getTeamSizeChart,
            id,
            type,
            filterNameValue,
        );
        yield put(actions.getIndustryCompaniesTeamSizeChartSuccess());
        yield put(actions.setIndustryCompaniesTeamSizeChart(response));
    } catch (err) {
        yield put(actions.getIndustryCompaniesTeamSizeChartFailure());
    }
}

function* fetchEnrichIndustry(slug) {
    try {
        yield put(actions.enrichIndustryPending());
        yield call(postEnrichIndustry, slug);
        yield put(actions.enrichIndustrySuccess());
    } catch (err) {
        yield put(actions.enrichIndustryFailure());
    }
}

export {
    fetchIndustries,
    fetchIndustry,
    fetchIndustryCompany,
    fetchIndustryCompanies,
    fetchIndustryCompaniesCount,
    fetchIndustryCompaniesChart,
    fetchIndustryCompaniesTeamSizeChart,
    fetchEnrichIndustry,
};
