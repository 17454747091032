import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, shape, breakpoint, typography }) =>
        createStyles({
            root: {
                margin: `0 auto`,
                padding: spacing(4),
                maxWidth: "1136px",
                [breakpoint.down("sm")]: {
                    padding: 0,
                },
            },
            headerWrapper: {
                [breakpoint.down("sm")]: {
                    padding: spacing(4),
                },
            },
            main: {
                background: palette.common.white,
                paddingBottom: spacing(3),
                marginTop: spacing(4),
                borderRadius: spacing(4),
                position: "relative",
            },
            wrap: {
                display: "flex",
                flexWrap: "wrap",
            },
            imgWrap: {
                width: 48,
                height: 48,
                border: `1px solid ${palette.text.backgroundMain}`,
                borderRadius: spacing(1),
                marginRight: spacing(2),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            scores: {
                padding: `${spacing(2)}px 0 0 0`,
                display: "flex",
                flexDirection: "column",
                flex: "100%",
                width: "100%",
                [breakpoint.down("lg")]: {
                    flex: "100%",
                    padding: 0,
                },
            },
            imagePlaceholder: {
                cursor: "pointer",
                maxWidth: 36,
            },
            profileImage: {
                maxWidth: 48,
            },
            companyName: {
                color: palette.secondary.light,
            },
            matchScore: {
                color: palette.text.dark,
                display: "flex",
                alignItems: "center",
                marginTop: spacing(2),
            },
            infoIcon: {
                cursor: "pointer",
                marginLeft: spacing(2),
            },
            mono: {
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            valuerScoreNum: {
                color: palette.secondary.light,
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            },
            basicInfo: {
                marginTop: "34px",
                [breakpoint.down("lg")]: {
                    marginTop: 0,
                },
            },
            infoTitle: {
                fontWeight: "700",
                color: palette.text.main,
                width: 211,
                [breakpoint.down("lg")]: {
                    color: palette.common.black,
                    width: "auto",
                },
            },
            infoValue: {
                fontWeight: "300",
                color: palette.text.lighter,
                width: 211,
                maxWidth: "100%",
                [breakpoint.down("lg")]: {
                    textAlign: "right",
                    width: "auto",
                    color: palette.success.dark,
                },
            },
            commentsText: {
                [breakpoint.down("lg")]: {
                    textAlign: "left",
                },
            },
            link: {
                color: palette.primary.dark,
                wordBreak: "break-all",
            },
            money: {
                color: palette.success.main,
            },
            description: {
                fontWeight: "300",
                color: palette.text.main,
                overflowY: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                [breakpoint.down("lg")]: {
                    maxHeight: 352,
                },
            },
            descriptionExpanded: {
                color: palette.text.main,
                maxHeight: "none",
            },
            technologies: {
                marginTop: spacing(3),
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                [breakpoint.down("lg")]: {
                    "& $flexRow": {
                        flexDirection: "row !important",
                    },
                },
            },
            tag: {
                fontWeight: "500",
                marginRight: spacing(1),
            },
            sdgWrap: {
                width: "50%",
                padding: `${spacing(4)}px ${spacing(4)}px 0px ${spacing(4)}px`,
                display: "flex",
                flexDirection: "column",
                borderRight: `1px solid ${palette.text.backgroundMain}`,
            },
            sdg: {
                marginRight: spacing(1),
                marginTop: spacing(1),
                cursor: "pointer",
            },
            sdgTooltip: {
                textAlign: "center",
            },
            sdgTooltipTitle: {
                fontWeight: "700",
            },
            sdgUnlockToAccess: {
                color: palette.grey.main,
                verticalAlign: "super",
            },
            dataTitle: {
                color: palette.secondary.light,
            },
            dataValue: {
                color: palette.primary.main,
                marginLeft: spacing(1),
            },
            dataCompleteness: {
                display: "flex",
                cursor: "pointer",
            },
            tabs: {
                height: 74,
                borderBottom: `2px solid ${palette.text.backgroundMain}`,
                display: "flex",
                alignItems: "flex-end",
            },
            tab: {
                color: palette.text.light,
                minWidth: 76,
                marginRight: 58,
                cursor: "pointer",
                textAlign: "center",
                borderBottom: "2px solid transparent",
                paddingBottom: spacing(2),
                marginTop: spacing(2),
            },
            activeTab: {
                color: palette.secondary.dark,
                minWidth: 76,
                marginRight: 58,
                borderBottom: "2px solid",
                borderBottomColor: palette.secondary.dark,
            },
            nameImageWrap: {
                order: 1,
                paddingBottom: spacing(2),
                marginLeft: spacing(4),
                borderBottom: "1px solid rgba(107, 119, 140, 0.1)",
                [breakpoint.down("lg")]: {
                    order: 3,
                    borderBottom: "none",
                    padding: "0 32px 24px 0",
                    width: "100%",
                },
                [breakpoint.down("sm")]: {
                    marginLeft: spacing(2),
                },
            },
            nameImageWrapFullWidth: {
                width: "100%",
            },
            nameImageWrapPartialWidth: {
                width: "calc(100% - 272px)",
            },
            chartWrap: {
                width: 100,
                height: 100,
                borderRadius: "50%",
                backgroundImage: ({
                    numToDeg,
                }) => `conic-gradient(${palette.primary.light} 0deg, ${palette.primary.light} ${numToDeg}deg, transparent ${numToDeg}deg),
        repeating-conic-gradient(${palette.primary.light} 0deg 0.5deg, ${palette.primary.backgroundMedium} 0.5deg 3deg)`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: spacing(2),
                [breakpoint.down("lg")]: {
                    margin: 0,
                },
                [breakpoint.down("md")]: {
                    width: 88,
                    height: 88,
                    marginTop: 0,
                },
            },
            savedInLink: {
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            savedIn: {
                order: 2,
                position: "relative",
                borderTopRightRadius: spacing(3),
                borderBottom: "1px solid rgba(107, 119, 140, 0.1)",
                width: 232,
                display: "flex",
                alignItems: "center",
                paddingRight: spacing(3),
                [breakpoint.down("lg")]: {
                    order: 1,
                    background: "transparent",
                    width: "100%",
                    borderBottom: "none",
                    height: 100,
                    padding: `0 ${spacing(4)}px`,
                },
            },
            valuerScore: {
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                [breakpoint.down("lg")]: {
                    flexDirection: "row",
                    justifyContent: "flex-start",
                },
                "& > figure": {
                    marginRight: 21,
                    marginLeft: spacing(3),
                },
            },
            chart: {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: spacing(2),
            },
            scoreBreakdown: {
                marginTop: -spacing(1),
                marginBottom: spacing(2),
                cursor: "pointer",
                [breakpoint.down("lg")]: {
                    margin: 0,
                    textDecoration: "underline",
                    color: palette.text.main,
                    ...typography.bodyButton,
                },
            },
            infoIconChart: {
                // marginLeft: spacing(1)
                position: "absolute",
                top: 12,
                right: 12,
                cursor: "pointer",
                [breakpoint.down("lg")]: {
                    top: 24,
                    right: 24,
                },
            },
            score: {
                lineHeight: "40px",
                position: "absolute",
                top: 60,
            },
            descriptionScores: {
                marginTop: spacing(2),
                display: "flex",
                order: 4,
                padding: "0 32px",
                width: "50%",
                [breakpoint.down("lg")]: {
                    marginTop: 0,
                    background: palette.success.backgroundLight,
                    borderRadius: spacing(2),
                    marginLeft: spacing(4),
                    padding: `${spacing(4)}px ${spacing(3)}px`,
                    width: "100%",
                    margin: `0 ${spacing(4)}px`,
                },
                [breakpoint.down("sm")]: {
                    margin: `0 ${spacing(2)}px`,
                    padding: `${spacing(2)}px ${spacing(3)}px`,
                },
            },
            lockAlertWrapper: {
                marginTop: spacing(4),
                [breakpoint.down("md")]: {
                    padding: `0 ${spacing(3)}px`,
                },
                [breakpoint.down("sm")]: {
                    padding: `0 ${spacing(2)}px`,
                },
            },
            scoresRows: {
                "&:last-child": {
                    "& > $scoreRow:last-child": {
                        borderBottom: "none",
                    },
                },
            },
            scoreRow: {
                display: "flex",
                flexDirection: "column",
                maxWidth: "50%",
                [breakpoint.down("lg")]: {
                    flexDirection: "row",
                    width: "100%",
                    maxWidth: "100%",
                    borderBottom: `1px dashed #666666`,
                    justifyContent: "space-between",
                    padding: "10px 0",
                },
            },
            descriptionBox: {
                order: 3,
                paddingRight: spacing(4),
                marginTop: spacing(4),
                borderRight: `1px solid ${palette.text.backgroundMain}`,
                flex: "50%",
                maxWidth: "50%",
                padding: "0 32px",
                [breakpoint.down("lg")]: {
                    order: 2,
                    marginTop: 0,
                    maxWidth: "100%",
                    flex: "100%",
                },
                [breakpoint.down("sm")]: {
                    padding: "0 32px 0 16px",
                },
            },
            descriptionBoxProject: {
                marginTop: spacing(4),
            },
            actionItems: {
                paddingTop: spacing(3),
                borderTop: "1px solid #F0F1F3",
                display: "flex",
                justifyContent: "space-between",
            },
            commentActionItems: {
                paddingTop: spacing(3),
                marginTop: spacing(3),
                borderTop: "1px solid #F0F1F3",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
            },
            commentActionItem: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                [breakpoint.down("lg")]: {
                    flexDirection: "column",
                },
            },
            userVoted: {
                color: `${palette.primary.dark}!important`,
            },
            votersCount: {
                padding: `${spacing(1)}px 0`,
                color: palette.text.lighter,
                cursor: "pointer",
            },
            commentCountWrap: {
                display: "flex",
                alignItems: "center",
            },
            communicationIcon: {
                padding: `${spacing(1)}px ${spacing(1)}px`,
                cursor: "pointer",
            },
            numberOfEmployees: {
                minHeight: 18,
            },
            enrichedProfileTag: {
                color: palette.common.white,
                background: palette.primary.main,
                padding: `0 ${spacing(1)}px`,
                borderRadius: shape.borderRadius,
                height: spacing(3),
                display: "flex",
                alignItems: "center",
                width: 105,
            },
            emptyTag: {
                height: spacing(3),
            },
            companyVoteActions: {
                padding: `${spacing(2)}px ${spacing(4)}px`,
                marginTop: spacing(3),
                borderTop: "1px solid #F0F1F3",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                width: "100%",
            },
            companyVote: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
            },
            questionMark: {
                marginRight: spacing(1),
            },
            voteIcon: {
                "&:hover": {
                    cursor: "pointer",
                },
            },
            dislikeImg: {
                transform: "rotate(180deg)",
                marginLeft: spacing(2),
                marginRight: spacing(1),
                cursor: "pointer",
            },
            flexSpaceStart: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
            },
            flexColumn: {
                display: "flex",
                flexDirection: "column",
            },
            flexRow: {
                display: "flex",
                flexDirection: "row",
                [breakpoint.down("lg")]: {
                    flexDirection: "column",
                },
            },
            flexCenter: {
                display: "flex",
                alignItems: "center",
            },
            flexWrap: {
                display: "flex",
                flexWrap: "wrap",
            },
            flexSpaceBetween: {
                display: "flex",
                justifyContent: "space-between",
            },
            notFoundImg: {
                margin: `${spacing(4)}px auto 0 auto`,
                maxWidth: "75%",
                display: "block",
            },
            tabLinksWrapper: {
                position: "relative",
                marginTop: spacing(4),
                maxWidth: "100vw",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                [breakpoint.down("sm")]: {
                    justifyContent: "flex-start",
                    paddingLeft: spacing(2),
                },
                "&::-webkit-scrollbar": {
                    display: "none",
                    "-ms-overflow-style": "none",
                    scrollbarWidth: "none",
                },
            },
            tabLinks: {
                minWidth: 445,
                display: "flex",
            },
            tabLink: {
                "&:last-child": {
                    marginRight: 0,
                },
                cursor: "pointer",
                marginRight: spacing(1),
                padding: "11px 24px",
                borderRadius: spacing(2),
                background: palette.text.backgroundMain,
                [breakpoint.down("xs")]: {
                    display: "flex",
                    alignItems: "center",
                },
            },
            activeTabLink: {
                color: palette.common.white,
                position: "relative",
                background: palette.secondary.dark,
                "& > $tabLinkText": {
                    color: palette.common.white,
                },
            },
            tabLinkText: {
                color: palette.secondary.main,
            },
            showMore: {
                cursor: "pointer",
                textAlign: "center",
                position: "relative",
                marginTop: spacing(4),
                "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 11,
                    left: 0,
                    width: "42%",
                    height: 1,
                    background: palette.text.backgroundMain,
                },
                "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 11,
                    right: 0,
                    width: "42%",
                    height: 1,
                    background: palette.text.backgroundMain,
                },
            },
            likeDislikeIcons: {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            },
            likeDislikeWrap: {
                display: "flex",
                alignItems: "center",
            },
            commentsCount: {
                color: palette.text.lighter,
            },
            displayFlex: {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            },
            likeIcon: {
                marginLeft: spacing(2),
                marginRight: spacing(1),
                cursor: "pointer",
            },
            clicable: {
                cursor: "pointer !important",
            },
            notClickable: {
                cursor: "text !important",
            },
            seeLookALikeBtn: {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: spacing(2),
            },
            lookALikeImg: {
                background: palette.success.backgroundDark,
                borderRadius: spacing(1),
                width: 36,
                height: 34,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            lookALikeText: {
                marginLeft: spacing(1),
                color: palette.primary.dark,
                "&:hover": {
                    textDecoration: "underline",
                },
            },
            savedInBlock: {
                display: "flex",
                flexDirection: "column",
                minWidth: 140,
                [breakpoint.down("lg")]: {
                    padding: 0,
                    flexDirection: "row",
                    border: "none",
                    alignItems: "baseline",
                },
            },
            percentageMatch: {
                display: "flex",
                alignItems: "baseline",
                [breakpoint.down("lg")]: {
                    marginLeft: spacing(1),
                },
            },
            scoreBar: {
                position: "relative",
                width: 58,
                height: 6,
                background: palette.text.backgroundMain,
                marginTop: 4,
                marginBottom: 0,
                marginLeft: 10,
                borderRadius: spacing(1),
            },
            scoreBarInside: {
                position: "absolute",
                height: 6,
                top: 0,
                width: 25,
                borderRadius: spacing(1),
                background: palette.primary.dark,
            },
            weakScore: {
                background: palette.error.dark,
            },
            goodScore: {
                background: palette.warning.dark,
            },
            strongScore: {
                background: palette.success.dark,
            },
            weakText: {
                color: palette.error.dark,
            },
            goodText: {
                color: palette.warning.dark,
            },
            strongText: {
                color: palette.success.dark,
            },
            enrichmentBanner: {
                textAlign: "center",
                marginTop: spacing(6),
                marginBottom: spacing(6),
            },
            enrichmentBannerTitle: {
                width: "100%",
                display: "block",
                marginBottom: spacing(2),
            },
            enrichmentBannerDescription: {
                width: "100%",
                display: "block",
                marginBottom: spacing(2),
            },
            enrichmentBannerButton: {
                marginBottom: spacing(2),
            },
            enrichmentBannerLink: {
                width: "100%",
                display: "block",
                marginBottom: spacing(2),
            },
            deepDiveIcon: {
                marginLeft: spacing(2),
            },
            companyNotFound: {
                padding: spacing(2)
            }
        }),
);
