export const objectHasEmptyValues = obj => {
    for (var key in obj) {
        if (
            obj[key] !== null &&
            obj[key] !== "" &&
            obj[key] &&
            obj[key].length !== 0
        )
            return false;
    }
    return true;
};

export const isEmptyObject = obj => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};
