import { combineReducers } from "@reduxjs/toolkit";
import { reducer as search } from "./search";
import { reducer as sectors } from "./sectors";
import { reducer as results } from "./results";
import { reducer as auth } from "./auth";
import { reducer as projects } from "./projects";
import { reducer as mixpanel } from "./mixpanel";
import { reducer as companyProfile } from "./companyProfile";
import { reducer as invites } from "./invites";
import { reducer as countries } from "./countries";
import { reducer as credits } from "./credits";
import { reducer as billing } from "./billing";
import { reducer as technologies } from "./technologies";
import { reducer as industries } from "./industries";
import { reducer as freeTrial } from "./freeTrial";
import { reducer as orders } from "./orders";
import { reducer as dashboard } from "./dashboard";
import { reducer as cluster } from "./cluster";
import { reducer as userConversation } from "./userConversation";

export default combineReducers({
    search,
    sectors,
    results,
    auth,
    projects,
    mixpanel,
    companyProfile,
    invites,
    countries,
    credits,
    billing,
    technologies,
    industries,
    freeTrial,
    orders,
    dashboard,
    cluster,
    userConversation,
});
