import { all, takeEvery } from "redux-saga/effects";
import { fetchOrdersRequest, exportCsvRequest } from "../requests";
import { fetchOrders, exportCsv } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchOrders() {
    yield takeEvery(
        convertTypeFromAction(fetchOrdersRequest),
        ({ payload: { type, team } }) => fetchOrders(type, team),
    );
}

function* watchExportCsv() {
    yield takeEvery(
        convertTypeFromAction(exportCsvRequest),
        ({
            payload: {
                ids,
                type,
                name,
                idType,
                idValue,
                doc,
                sortBy,
                sortDirection,
                pageName,
            },
        }) =>
            exportCsv(
                ids,
                type,
                name,
                idType,
                idValue,
                doc,
                sortBy,
                sortDirection,
                pageName,
            ),
    );
}

function* saga() {
    yield all([watchFetchOrders(), watchExportCsv()]);
}

export { saga };
