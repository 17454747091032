import React, { useEffect, useState } from "react";
// UI components
import Box from "@material-ui/core/Box";
import Typography from "../Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "../Button/Button";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { useAppEventTracking } from "../../common/useAppEventTracking";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { WithContext as ReactTags } from "react-tag-input";
import Tag from "../../components/Tag/Tag";
//Assets
import CloseIcon from "../../assets/icons/CloseIcon.svg";
import RemoveInvitedUser from "../../assets/icons/RemoveInvitedUser.svg";
import InviteUsersCtaIcon from "../../assets/icons/InviteUsersCtaIcon.svg";
import { ReactComponent as TooltipCheck } from "../../assets/icons/TooltipCheck.svg";
//Redux
import { sendInviteCoworkersRequest } from "../../store/invites/requests";
import {
    getSendInviteCoworkersStatusSelector,
    getInviteFailureMessageSelector,
} from "../../store/invites/selectors";
import {
    getShareableLinkStateSelector,
    getShareableLinkStatusSelector,
} from "../../store/results/selectors";
//Shared
import { Shared } from "../../utils/shared.js";

import {
    REQUEST_FAILURE,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
} from "../../constants/statuses";
import { generateShareLinkRequest } from "../../store/results/requests";
import { actions as resultsActions } from "../../store/results/slice";

const RemoveComponent = props => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { className, onRemove } = props;
    return (
        <button onClick={onRemove} className={className}>
            <img
                src={RemoveInvitedUser}
                alt={t("industry.remove")}
                className={classes.removeComponent}
            />
        </button>
    );
};

const InviteUsersModal = ({
    showShareSection = false,
    shareData = {},
    dialogOpened,
    onChange,
    startProductTour,
    page,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const { keyCodes, emailReg } = Shared({});
    const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();

    const [usersToInvite, setUsersToInvite] = useState([]);
    const delimiters = [...keyCodes.enter, keyCodes.comma];
    const [inviteSendingError, setInviteSendingError] = useState(false);
    const sendInviteStatus = useSelector(getSendInviteCoworkersStatusSelector);
    const getInviteFailureMessage = useSelector(
        getInviteFailureMessageSelector,
    );
    const [emailError, setEmailError] = useState(null);
    const [emailCausedError, setEmailCausedError] = useState("");
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const shareableLinkSelector = useSelector(getShareableLinkStateSelector);
    const shareableLinkStatusSelector = useSelector(
        getShareableLinkStatusSelector,
    );
    const [sharedSearchUrl, setSharedSearchUrl] = useState("");

    const handleDelete = i => {
        let tags = usersToInvite;
        setUsersToInvite(tags.filter((tag, index) => index !== i));
        let email = tags.filter((tag, index) => index === i)[0];
        email.text === emailCausedError.text && setEmailError(null);
    };
    const handleAddition = tag => {
        if (emailReg.test(tag.text)) {
            setEmailCausedError(tag);
            setUsersToInvite([...usersToInvite, tag]);
        } else {
            alert("Not valid email!");
        }
    };
    const sendInvitation = () => {
        let emailsPrepared = "";
        usersToInvite.forEach(email => {
            emailsPrepared += email.text + ",";
        });
        emailsPrepared = emailsPrepared.slice(0, emailsPrepared.length - 1);
        dispatch(sendInviteCoworkersRequest({ emailsPrepared }));
    };
    useEffect(() => {
        let timeout = null;
        if (sendInviteStatus === REQUEST_SUCCESS) {
            //Intercom event tracking
            let emailsPrepared = "";
            usersToInvite.forEach(email => {
                emailsPrepared += email.text + ",";
            });
            intercomTrackEvent("Number of invites", {
                emails: emailsPrepared,
            });
            //start product tour
            if (startProductTour) {
                intercomTrackEvent("product tour ready");
            }
            onChange();
        } else if (sendInviteStatus === REQUEST_FAILURE) {
            setInviteSendingError(true);
            timeout = setTimeout(() => {
                setInviteSendingError(false);
            }, 3000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [sendInviteStatus, onChange, usersToInvite]);

    const onClosingModal = e => {
        e.preventDefault();
        e.stopPropagation();
        setEmailError(null);
        setEmailCausedError("");
        onChange();
        if (startProductTour) {
            intercomTrackEvent("product tour ready");
        }
    };

    const generateShareLink = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(generateShareLinkRequest({ data: shareData }));
        mixpanelTrackEvent("Search shared", { Page: page });
    };

    useEffect(() => {
        if (shareableLinkStatusSelector === REQUEST_SUCCESS) {
            dispatch(resultsActions.postGenerateLinkUndefined());
            dispatch(resultsActions.generateShareLinkUndefined());
            shareableLinkSelector && setSharedSearchUrl(shareableLinkSelector);
        }
    }, [shareableLinkStatusSelector, shareableLinkSelector]);

    useEffect(() => {
        let timeout = null;
        if (sharedSearchUrl) {
            setCopiedToClipboard(true);
            navigator.clipboard.writeText(sharedSearchUrl);
            timeout = setTimeout(() => {
                setCopiedToClipboard(false);
            }, 1500);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [sharedSearchUrl]);

    const handleStopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Dialog
            onClose={onClosingModal}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
                paperWidthLg: classes.dialogPaperScrollPaper,
            }}
            open={dialogOpened}
            onClick={handleStopPropagation}>
            {dialogOpened ? (
                <IconButton
                    className={classes.closeButton}
                    onClick={onClosingModal}>
                    <img
                        src={CloseIcon}
                        alt={t("scope.step2")}
                        className={classes.stepIcon}
                    />
                </IconButton>
            ) : null}
            <MuiDialogTitle
                disableTypography
                className={classes.dialogTitleWrapper}>
                {showShareSection ? (
                    <>
                        <Typography
                            className={classes.inviteTitle}
                            variant="subtitle1"
                            component="div">
                            {t("inviteUsersModal.shareTitle")}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            className={classes.inviteTitle}
                            variant="subtitle1"
                            color="secondaryDark"
                            component="div">
                            {t("inviteUsersModal.title")}
                        </Typography>
                        <Typography
                            className={classes.inviteSubtitle}
                            variant="bodyXs"
                            color="textLighter">
                            {t("inviteUsersModal.subtitle")}
                        </Typography>
                    </>
                )}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                {/* TODO@jkica: wait for them to figure out the functionality for share feature and than remove this if necessary */}
                {!showShareSection && (
                    <div>
                        <Box className={classes.tagsWrap}>
                            <ReactTags
                                classNames={{
                                    tag: classes.tagsClass,
                                    tagInputField: classes.tagInputFieldClass,
                                    remove: classes.removeClass,
                                }}
                                tags={usersToInvite}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                delimiters={delimiters}
                                allowAdditionFromPaste={true}
                                placeholder="Enter email address"
                                removeComponent={RemoveComponent}
                                autofocus={true}
                            />
                        </Box>
                        {emailError ? (
                            <Box
                                className={classes.errorInvite}
                                component="span">
                                <Typography
                                    className={classes.errorInviteMsg}
                                    variant="caption">
                                    {emailError}
                                </Typography>
                            </Box>
                        ) : (
                            <Box
                                className={classes.errorInvite}
                                component="span">
                                {inviteSendingError && (
                                    <Typography
                                        className={classes.errorInviteMsg}
                                        variant="caption">
                                        {getInviteFailureMessage.length > 0
                                            ? getInviteFailureMessage
                                            : t("inviteUsersModal.emailExists")}
                                    </Typography>
                                )}
                            </Box>
                        )}
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="column">
                            <Typography component="div" variant="bodyXs">
                                {t("inviteUsersModal.youHave")}{" "}
                                <Tag>
                                    <Typography
                                        variant="label"
                                        className={classes.usersCount}>
                                        {t("inviteUsersModal.usersNum", {
                                            count: usersToInvite.length,
                                        })}
                                    </Typography>
                                </Tag>{" "}
                                {t("inviteUsersModal.selected")}
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="flex-end"
                                className={classes.inviteCtas}>
                                <Button
                                    className={classes.cancelButton}
                                    variant="tertiaryBlack"
                                    onClick={onChange}>
                                    {t("inviteUsersModal.cancel")}
                                </Button>
                                <Button
                                    loading={
                                        sendInviteStatus === REQUEST_PENDING
                                    }
                                    disabled={
                                        usersToInvite.length === 0 || emailError
                                    }
                                    variant="primary"
                                    onClick={sendInvitation}>
                                    {t("inviteUsersModal.invite")}
                                    <img
                                        src={InviteUsersCtaIcon}
                                        alt={t("scope.step2")}
                                        className={classes.inviteCtaIcon}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </div>
                )}
                {showShareSection && (
                    <>
                        {/*<div className={classes.divider}/>*/}
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="column">
                            <Typography
                                className={classes.shareText}
                                component="div"
                                variant="bodyXs"
                                dangerouslySetInnerHTML={{
                                    __html: t("inviteUsersModal.shareText"),
                                }}
                            />
                            <Button
                                onClick={generateShareLink}
                                className={classes.copyButton}
                                variant="primary">
                                {t("inviteUsersModal.copyShareLink")}
                            </Button>
                            {copiedToClipboard && (
                                <div className={classes.tooltipCopy}>
                                    <TooltipCheck
                                        className={classes.checkImg}
                                    />
                                    <Typography color="white" variant="body2">
                                        {t("companyProfile.copiedToClipboard")}
                                    </Typography>
                                </div>
                            )}
                        </Box>
                    </>
                )}
            </MuiDialogContent>
        </Dialog>
    );
};
export default InviteUsersModal;
