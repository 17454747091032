import React, { useEffect, useState } from "react";
import classnames from "classnames";
// Hooks
import { useStyles } from "./style.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAppEventTracking } from "../../common/useAppEventTracking";
//UI components
import Typography from "../../components/Typography/Typography";
import CreditsIcon from "../../assets/icons/dashboardCreditsIcon.svg";
import LikedCompaniesIcon from "../../assets/icons/dashboardLikedCompaniesIcon.svg";
import MyOrdersIcon from "../../assets/icons/dashboardMyOrdersIcon.svg";
import ProjectsIcon from "../../assets/icons/dashboardProjectsIcon.svg";
import DashboardCardBig from "./components/DashboardCardBig";
import DashboardCardSmall from "./components/DashboardCardSmall";
import DashboardStepperCard from "./components/DashboardStepperCard";
import SubscribeConfirmationModal from "../Results/components/modals/SubscribeConfirmationModal";
import SubscriptionSuccessfulModal from "../Results/components/modals/SubscriptionSuccessfulModal";
// Assets
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDownGreyL.svg";
import GirlWithGlass from "../../assets/images/GirlWithGlass.png";
import DashboardLookAlikeSearch from "../../assets/images/DashboardLookAlikeSearch.png";
// Redux
import { getPlanStatusSelector } from "../../store/credits/selectors";
import { getPlanRequest } from "../../store/credits/requests";
import { subscribeRequest } from "../../store/auth/requests";
import { fetchDashboardRequest } from "../../store/dashboard/requests";
import { getSuggestedCompaniesRequest } from "../../store/companyProfile/requests";
import { getSuggestedCompaniesStateSelector } from "../../store/companyProfile/selectors.js";
import { getSearchesSelector } from "../../store/results/selectors";
import { fetchSearchesRequest } from "../../store/results/requests";
import {
    getQuickStatsSelector,
    getLatestOrdersSelector,
    getLatestActivitiesSelector,
    getPopularCompaniesSelector,
} from "../../store/dashboard/selectors";
import {
    getUserStatusSelector,
    getSubscribeStatusSelector,
    getCheckOutUrlSelector,
    getRegisterStatusSelector,
} from "../../store/auth/selectors";
// Constants
import { REQUEST_SUCCESS, REQUEST_PENDING } from "../../constants/statuses";
// import Description from "../SearchCompanies/Description";
// import SearchLookAlike from "../SearchCompanies/SearchLookAlike";
import { actions as searchActions } from "../../store/search/slice";

const Dashboard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state: locationState } = useLocation();
    const history = useHistory();
    const { pathname } = useLocation();
    const {
        userLoading,
        isAuthenticated,
        user,
        isGuestUser,
        isFreeTrialExpiredUser,
    } = useAuthHook();
    const registerStatus = useSelector(getRegisterStatusSelector);
    const searches = useSelector(getSearchesSelector);
    const quickStats = useSelector(getQuickStatsSelector);
    const suggestedCompanies = useSelector(getSuggestedCompaniesStateSelector);
    const getLatestOrders = useSelector(getLatestOrdersSelector);
    const getLatestActivities = useSelector(getLatestActivitiesSelector);
    const popularCompanies = useSelector(getPopularCompaniesSelector);
    const { openPage } = useRouterHook();
    const [quickStatsCollapsed, setQuickStatsCollapsed] = useState(
        document.body.offsetWidth <= 768 ? true : false,
    );
    const [searchHistory, setSearchHistory] = useState(searches);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchDashboardRequest());
        dispatch(getSuggestedCompaniesRequest({ data: {} }));
        const data = {
            orderDirection: "desc",
            orderBy: "created-at",
            page: 1,
            savedSearches: true,
        };
        dispatch(fetchSearchesRequest({ data }));
    }, [dispatch]);

    useEffect(() => {
        setSearchHistory(searches);
        searches &&
            setSavedSearches({
                type: "savedSearches",
                subitems: searches,
            });
    }, [searches]);

    useEffect(() => {
        // TODO@jkica:revert this quickfix after auth fixes are done (branch name BUGS/register-loop)
        // if (!userLoading && isGuestUser && registerStatus === REQUEST_UNDEFINED) {
        //     openPage('/sign-in')
        // }
    }, [userLoading, registerStatus]);

    const [smallCards, setSmallCards] = useState([]);

    useEffect(() => {
        quickStats &&
            setSmallCards([
                {
                    type: "projects",
                    icon: ProjectsIcon,
                    data: {
                        firstValue: quickStats.projects_count,
                    },
                    path: "workspace",
                },
                /* {
                type: 'likedCompanies',
                icon: LikedCompaniesIcon,
                data: {
                    firstValue: quickStats.liked_companies,
                    secondValue: null
                },
	            path: 'liked-companies',
            }, */
                {
                    type: "myOrders",
                    icon: MyOrdersIcon,
                    data: {
                        firstValue: quickStats.orders_count,
                        secondValue: null,
                    },
                    path: "orders",
                },
                {
                    type: "credits",
                    icon: CreditsIcon,
                    data: {
                        firstValue: quickStats.credits,
                        secondValue: null,
                    },
                    path: "credit-summary",
                    timestamp: quickStats.credits_reset_date_formated,
                },
            ]);
    }, [quickStats]);
    const [latestOrders, setLatestOrders] = useState({
        type: "latestOrders",
        subitems: [],
    });

    useEffect(() => {
        getLatestOrders &&
            setLatestOrders({
                type: "latestOrders",
                subitems: getLatestOrders,
            });
    }, [getLatestOrders]);

    const [latestActivities, setLatestActivities] = useState({
        type: "latestActivities",
        subitems: getLatestActivities,
    });

    useEffect(() => {
        getLatestActivities &&
            setLatestActivities({
                type: "latestActivities",
                subitems: getLatestActivities,
            });
    }, [getLatestActivities]);

    const [savedSearches, setSavedSearches] = useState({
        type: "savedSearches",
        subitems: searches,
    });

    const { mixpanelTrackEvent, intercomTrackEvent } = useAppEventTracking();
    let { search } = useLocation();
    const [isSubscribeDialogOpened, setIsSubscribeDialogOpened] =
        useState(false);
    const [
        isSubscriptionSuccessfulModalOpened,
        setIsSubscriptionSuccessfulModalOpened,
    ] = useState(false);
    const planStatus = useSelector(getPlanStatusSelector);
    const [subscriptionDetails, setSubscriptionDetails] = useState();
    const getMeStatus = useSelector(getUserStatusSelector);
    const subscribeStatus = useSelector(getSubscribeStatusSelector);
    const checkOutUrl = useSelector(getCheckOutUrlSelector);

    const handleSubscriptionAttempt = queryParams => {
        if (isAuthenticated) {
            const query = new URLSearchParams(search);

            if (!query.get("subscribed")) {
                setIsSubscribeDialogOpened(true);
            }
            localStorage.removeItem("subscriptionAttempt");
        } else {
            if (!localStorage.getItem("subscriptionAttempt")) {
                localStorage.setItem(
                    "subscriptionAttempt",
                    JSON.stringify(queryParams),
                );
                openPage("/sign-up?subscriptionAttempt");
            }
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(search);
        const storageData = JSON.parse(
            localStorage.getItem("subscriptionAttempt"),
        );
        setSubscriptionDetails({
            subscribe: storageData
                ? storageData.subscribe
                : query.get("subscribe"),
            yearly: storageData ? storageData.yearly : query.get("yearly"),
            monthly: storageData ? storageData.monthly : query.get("monthly"),
            currency: storageData
                ? storageData.currency
                : query.get("currency"),
        });

        if (!userLoading) {
            subscriptionDetails && subscriptionDetails.subscribe
                ? handleSubscriptionAttempt(subscriptionDetails)
                : localStorage.removeItem("subscriptionAttempt");
        }
    }, [userLoading]);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const subscribe = query.get("subscribe");
        const yearly = query.get("yearly");
        const monthly = query.get("monthly");
        const currency = query.get("currency") || "";
        if (
            !!subscribe &&
            planStatus !== REQUEST_SUCCESS &&
            planStatus !== REQUEST_PENDING
        ) {
            dispatch(
                getPlanRequest({
                    plan: subscribe,
                    period: yearly ? "yearly" : "monthly",
                    amount: yearly ? yearly : monthly,
                    currency: currency,
                }),
            );
        }
    }, [getMeStatus, search]);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const subscribe = query.get("subscribe");
        const subscribed = query.get("subscribed");
        !!subscribed && setIsSubscriptionSuccessfulModalOpened(true);
        if (
            !!subscribe &&
            planStatus === REQUEST_SUCCESS &&
            !!isAuthenticated &&
            !userLoading
        ) {
            setIsSubscribeDialogOpened(true);
            if (subscribe === "pay-as-you-go") {
                mixpanelTrackEvent("Selected plan pay as you go");
            }
        }
    }, [planStatus, getMeStatus]);

    useEffect(() => {
        if (subscribeStatus === REQUEST_SUCCESS) {
            if (checkOutUrl && checkOutUrl.length > 0) {
                window.location.href = checkOutUrl;
            }
        }
    }, [checkOutUrl, subscribeStatus]);

    // reset selected sectors and input fields on component mount
    useEffect(() => {
        if (
            locationState == undefined ||
            locationState.from !== "/search-results-loading"
        ) {
            dispatch(searchActions.clearSearch());
        }

        if (!window.location.search.includes("subscribe")) {
            history.replace({ state: {} });
        }
    }, []);

    const confirmSubscription = () => {
        const {
            subscribe,
            yearly,
            monthly,
            currency = "",
        } = subscriptionDetails;

        dispatch(
            subscribeRequest({
                data: {
                    plan: subscribe,
                    period: yearly ? "yearly" : "monthly",
                    amount: yearly ? yearly : monthly,
                    currency: currency,
                },
            }),
        );
    };

    const [activeTab, setactiveTab] = useState(
        pathname === "/dashboard/lookalike" ? 2 : 1,
    );

    useEffect(() => {
        activeTab === 2
            ? openPage("/dashboard/lookalike" + window.location.search)
            : openPage("/dashboard" + window.location.search);
    }, [activeTab]);

    const changeTab = tabNumber => {
        setactiveTab(tabNumber);
    };

    const isBiggestDesktop = document.body.offsetWidth >= 1920;

    return (
        <div className={classes.main}>
            <div className={classes.searchAndStats}>
                {/*<DashboardSearch />*/}
                {/* <div className={classes.dashboardMainSearchBoxWrapper}>
                    <div className={classes.mainSearchBoxWrapper}>
                        <div className={classes.mainSearchBoxTabs}>
                            <div
                                onClick={() => changeTab(1)}
                                className={classnames([classes.mainSearchBoxTabOne, activeTab === 1 ? classes.mainSearchBoxTabActive : ""])}>
                                {t(`dashboard.aiSearch`)}
                            </div>
                            <div
                                onClick={() => changeTab(2)}
                                className={classnames([classes.mainSearchBoxTabTwo, activeTab === 2 ? classes.mainSearchBoxTabActive : ""])}>
                                {t(`dashboard.lookalikes`)}
                            </div>
                        </div>
                            <div className={classes.mainSearchBox}>
                                { activeTab === 1 ? <Description isDashboard={true} /> : <SearchLookAlike /> }
                            </div>
                    </div>
                </div> */}
                <div
                    className={classnames([
                        classes.smallCardsWrapper,
                        {
                            [classes.smallCardsWrapperCollapsed]:
                                quickStatsCollapsed,
                        },
                    ])}>
                    <Typography
                        variant="h6"
                        color="black"
                        component="div"
                        className={classes.qoTitleBiggestDesktop}
                        testId="quick-overview-title">
                        <strong>{t("dashboard.dashboardOverview")}</strong>
                    </Typography>
                    <Typography
                        variant="body"
                        color="black"
                        className={classes.qoSubtitleBiggestDesktop}
                        dangerouslySetInnerHTML={{
                            __html: t(`dashboard.dashboardOverviewSubtitle`),
                        }}
                    />
                    <Typography
                        variant="body"
                        color="textLight"
                        className={classes.quickOverviewTitle}
                        onClick={() =>
                            setQuickStatsCollapsed(!quickStatsCollapsed)
                        }
                        testId="quick-overview-title">
                        {t("dashboard.quickOverviewTitle")}
                        <ArrowDown
                            className={classnames([
                                classes.collapseArrow,
                                {
                                    [classes.collapseArrowReversed]:
                                        quickStatsCollapsed,
                                },
                            ])}
                        />
                    </Typography>
                    <div className={classes.quickStatsWrapper}>
                        {!quickStatsCollapsed &&
                            smallCards.length > 0 &&
                            smallCards.map((smallCard, index) => (
                                <DashboardCardSmall
                                    item={smallCard}
                                    key={"dashboard-small-card-item-" + index}
                                    testId={
                                        "dashboard-small-card-item-" + index
                                    }
                                />
                            ))}
                        <Link
                            to={"search-type"}
                            className={classnames([
                                classes.dashboardCardSmall,
                                classes.quickStatSearch,
                            ])}
                            test-id={"dashboard-small-card-item-4"}>
                            <img
                                src={GirlWithGlass}
                                alt={t("industry.search")}
                            />
                            <Typography
                                color="black"
                                variant="bodyButton"
                                className={classes.mt4}>
                                {t("industry.search")} →
                            </Typography>
                        </Link>
                        <Link
                            to={"search-type/lookalike"}
                            className={classnames([
                                classes.dashboardCardSmall,
                                classes.quickStatSearch,
                            ])}
                            test-id={"dashboard-small-card-item-5"}>
                            <img
                                src={DashboardLookAlikeSearch}
                                alt={t("dashboard.lookalikeSearch")}
                            />
                            <Typography
                                color="black"
                                variant="bodyButton"
                                className={classes.mt4}>
                                {t("dashboard.lookalikeSearch")} →
                            </Typography>
                        </Link>
                    </div>
                </div>
            </div>

            <div className={classes.bigCardsWrapperHolder}>
                <div className={classes.bigCardsWrapper}>
                    <DashboardCardBig
                        items={latestOrders}
                        type="orders"
                        testId="latest-orders-card"
                    />
                    <DashboardCardBig
                        items={latestActivities}
                        type="activities"
                        testId="latest-activities-card"
                    />
                    <DashboardCardBig
                        items={savedSearches}
                        type="savedSearches"
                        testId="latest-searches-card"
                    />
                    {!isBiggestDesktop ? (
                        <div className={classes.bigCardsWrapperCompanies}>
                            <DashboardStepperCard
                                type={"suggestedCompanies"}
                                testId="suggested-companies-card"
                                items={suggestedCompanies || []}
                                showNeedPlan={isFreeTrialExpiredUser}
                                showNoSuggested={
                                    searchHistory &&
                                    searchHistory.length === 0 &&
                                    suggestedCompanies &&
                                    suggestedCompanies.length === 0
                                }
                            />
                            <DashboardStepperCard
                                type={"popularCompanies"}
                                testId="popular-companies-card"
                                items={popularCompanies || []}
                                showNeedPlan={isFreeTrialExpiredUser}
                            />
                        </div>
                    ) : null}
                </div>
                {isBiggestDesktop ? (
                    <div className={classes.bigCardsWrapperCompanies}>
                        <DashboardStepperCard
                            type={"suggestedCompanies"}
                            testId="suggested-companies-card"
                            items={suggestedCompanies || []}
                            showNeedPlan={isFreeTrialExpiredUser}
                            showNoSuggested={
                                searchHistory &&
                                searchHistory.length === 0 &&
                                suggestedCompanies &&
                                suggestedCompanies.length === 0
                            }
                        />
                        <DashboardStepperCard
                            type={"popularCompanies"}
                            testId="popular-companies-card"
                            items={popularCompanies || []}
                            showNeedPlan={isFreeTrialExpiredUser}
                        />
                    </div>
                ) : null}
            </div>
            <SubscribeConfirmationModal
                dialogOpened={isSubscribeDialogOpened}
                onChange={() => setIsSubscribeDialogOpened(false)}
                onConfirm={confirmSubscription && confirmSubscription}
                setSubscriptionDetails={setSubscriptionDetails}
            />
            <SubscriptionSuccessfulModal
                dialogOpened={isSubscriptionSuccessfulModalOpened}
                onChange={() => setIsSubscriptionSuccessfulModalOpened(false)}
                subscriptionName={
                    user && user.subscription && user.subscription.name
                }
            />
        </div>
    );
};

export default Dashboard;
