import { call } from "redux-saga/effects";
import axios from "axios";
import {
    START_FREE_TRIAL,
    FREE_TRIAL_TO_FREE_URL,
    EMAIL_VERIFICATION_URL,
    emailValidationURL,
} from "../../../constants/endpoints";

function* startFreeTrial(email) {
    let data = null;
    if (email) {
        data = email;
    }
    const response = yield call(axios, {
        method: "POST",
        url: `${START_FREE_TRIAL}`,
        data,
        withCredentials: true,
    });
    return response.data;
}
function* updateFreeTrialPut(data) {
    const response = yield call(axios, {
        method: "PUT",
        url: `${FREE_TRIAL_TO_FREE_URL}`,
        data,
        withCredentials: true,
    });
    return response.data;
}
function* emailVerification() {
    const response = yield call(axios, {
        method: "GET",
        url: `${EMAIL_VERIFICATION_URL}`,
        withCredentials: true,
    });
    return response.data;
}
function* emailValidation(data) {
    const response = yield call(axios, {
        method: "GET",
        url: `${emailValidationURL(data.email)}`,
        withCredentials: true,
    });
    return response.data;
}

export {
    startFreeTrial,
    updateFreeTrialPut,
    emailVerification,
    emailValidation,
};
