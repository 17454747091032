import React, { useEffect } from "react";
// Hooks
import { useTranslation } from "react-i18next";
import useStyles from "./style.js";
import { useRouterHook } from "../../../common/useRouterHook";
import { useIntercom } from "react-use-intercom";
// UI components
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
// Assets
import { ReactComponent as TextualLogo } from "../../../assets/icons/TextualLogo.svg";
import Failure from "../../../assets/icons/Failure.png";

const MLFailure = ({ type }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const { trackEvent } = useIntercom();
    let title = t("mlFailure.title");
    let intercomDescription = "ML Request failure";
    if (type && type === "timeout") {
        title = t("mlFailure.timeoutTitle");
        intercomDescription = "ML Request timeout";
    } else if (type === "company-not-found") {
        title = t("mlFailure.companyNotFoundTitle");
        intercomDescription = "ML company not found";
    }
    useEffect(() => {
        if (
            process.env.REACT_APP_ENV === "prod" ||
            process.env.REACT_APP_ENV === "staging"
        ) {
            //Intercom event tracking
            trackEvent("Error message seen", {
                description: intercomDescription,
            });
        }
    }, []);
    return (
        <div className={classes.root}>
            <div className={classes.logoSection}>
                <TextualLogo className={classes.logo} />
            </div>
            <div className={classes.content}>
                <img src={Failure} className={classes.img} />
                <Typography align="center" variant="h6">
                    {title}
                </Typography>
                <Button variant="primary" onClick={() => openPage("/")}>
                    {t("mlFailure.goToHomepage")}
                </Button>
            </div>
        </div>
    );
};
export default MLFailure;
