import React from "react";
//Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useAuthHook } from "../../../../../common/useAuthHook";
//Components
import Typography from "../../../../../components/Typography/Typography";
import HeaderFilters from "../../../../../components/HeaderFilters/HeaderFilters";
import TableToolbar from "../../../../Results/components/ListView/TableToolbar";
import TableToolbarRight from "../../../../Results/components/ListView/TableToolbarRight";
//Redux
import {
    getTotalCountSelector,
    getTechnologyCompaniesCountSelector,
    getTechnologyCompaniesCountStatus,
} from "../../../../../store/technologies/selectors";
import {
    getIndustryCompaniesCountSelector,
    getIndustryCompaniesCountStatusSelector,
} from "../../../../../store/industries/selectors";
import { useSelector, useDispatch } from "react-redux";

const Header = ({
    isAuthenticated,
    onSearch,
    onSortChange,
    setModalFilters,
    setSelectedFilters,
    displayedFilters,
    searchResults,
    onFilterSelected,
    filters,
    allResults,
    resetFilters,
    stageOptions,
    fundingOptionsMin,
    fundingOptionsMax,
    yearOptionsMin,
    yearOptionsMax,
    teamsizeOptions,
    valuerScoreOptionsMin,
    valuerScoreOptionsMax,
    handleFieldChange,
    handleSectorChange,
    handleSubsectorChange,
    handleStageChange,
    handleLocationChange,
    resetFilter,
    handleFundingMinChange,
    handleYearMinChange,
    handleMatchingScoreMinChange,
    teamSizeOptionsMax,
    handleTeamSizeMinChange,
    allSectors,
    allSubsectors,
    selectedFilters,
    allCountries,
    setDisplayedFilters,
    isLess768,
    total,
    isIndustry,
    isTechnology,
    isProject,
    slug,
    isFilterFetching,
    request,
    countRequest,
    overviewVisibility,
    setOverviewVisibility,
    totalPages,
    page,
    isLikedCompanies,
    name,
    filterStats,
    handleSectorsClose,
    handleSubsectorsClose,
    preselectedSectors,
    setPreselectedSectors,
    preselectedSubsectors,
    setPreselectedSubsectors,
    activeView,
    setActiveView,
    companiesPageId,
    regionsOptions,
    handleRegionChange,
    filterMapping,
    tableToolbarClassName,
    selected,
    setSelected,
    selectedNames,
    sortValue,
    sortDirection,
    tableToolbarRef,
    handleSelectAllClick,
    isWorkspace = false,
    handleExcludeKeywordsChange,
    handleIncludeKeywordsChange,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user } = useAuthHook();
    const totalNumCompanies = useSelector(getTotalCountSelector);
    const dispatch = useDispatch();

    return (
        <div className={classes.root}>
            <div className={classes.searchFilters}>
                {isWorkspace && activeView === "card" && (
                    <Typography gutterBottom variant="subtitle1">
                        {t("workspace.listView.title")}
                    </Typography>
                )}
                {(!isWorkspace || (isWorkspace && activeView === "card")) && (
                    <div className={classes.numButton}>
                        {isLikedCompanies || isIndustry ? (
                            <div>
                                <Typography color="black" variant="subtitle1">
                                    {!isLikedCompanies
                                        ? t("industryProfile.companiesTitle")
                                        : t(
                                              "industryProfile.likedCompaniesTitle",
                                          )}
                                </Typography>
                                <Typography
                                    className={classes.ml8}
                                    color="light"
                                    variant="bodySmall">
                                    {t("industryProfile.companiesResultsNum", {
                                        num: total,
                                        page,
                                        totalPages,
                                    })}
                                </Typography>
                            </div>
                        ) : (
                            <Typography variant="label">
                                {t(
                                    "resultsHeader.showingResultsPaginationLabel",
                                    { count: total, page, totalPages },
                                )}
                            </Typography>
                        )}
                    </div>
                )}
                <div className={classes.headerFiltersWrapper}>
                    {!isWorkspace && (
                        <TableToolbar
                            classes={classes}
                            ref={tableToolbarRef}
                            className={tableToolbarClassName}
                            numSelected={selected.length}
                            rowCount={searchResults?.length || 0}
                            onSelectAllClick={handleSelectAllClick}
                            dispatch={dispatch}
                            isTechnology={false}
                            isIndustry={false}
                            searchResults={searchResults}
                            selected={selected}
                            selectedNames={selectedNames}
                            isAuthenticated={isAuthenticated}
                            user={user}
                            isProject={false}
                            // projectSlug={projectSlug}
                            id={companiesPageId}
                            isLikedCompany={false}
                            sortValue={sortValue}
                            sortDirection={sortDirection}
                        />
                    )}
                    {(!isWorkspace ||
                        (isWorkspace && activeView === "card")) && (
                        <HeaderFilters
                            onSearch={onSearch}
                            onFilterSelected={onFilterSelected}
                            searchResults={searchResults}
                            isAuthenticated={isAuthenticated}
                            user={user}
                            onSortChange={onSortChange}
                            filters={filters}
                            total={total}
                            resetFilters={resetFilters}
                            stageOptions={stageOptions}
                            fundingOptionsMin={fundingOptionsMin}
                            fundingOptionsMax={fundingOptionsMax}
                            yearOptionsMin={yearOptionsMin}
                            yearOptionsMax={yearOptionsMax}
                            teamsizeOptions={teamsizeOptions}
                            valuerScoreOptionsMin={valuerScoreOptionsMin}
                            valuerScoreOptionsMax={valuerScoreOptionsMax}
                            teamSizeOptionsMax={teamSizeOptionsMax}
                            handleTeamSizeMinChange={handleTeamSizeMinChange}
                            handleFieldChange={handleFieldChange}
                            handleSectorChange={handleSectorChange}
                            handleSubsectorChange={handleSubsectorChange}
                            handleStageChange={handleStageChange}
                            handleLocationChange={handleLocationChange}
                            resetFilter={resetFilter}
                            handleFundingMinChange={handleFundingMinChange}
                            handleYearMinChange={handleYearMinChange}
                            handleMatchingScoreMinChange={
                                handleMatchingScoreMinChange
                            }
                            allSectors={allSectors}
                            allSubsectors={allSubsectors}
                            selectedFilters={selectedFilters}
                            allCountries={allCountries}
                            displayedFilters={displayedFilters}
                            setSelectedFilters={setSelectedFilters}
                            setDisplayedFilters={setDisplayedFilters}
                            isFilterFetching={isFilterFetching}
                            setModalFilters={setModalFilters}
                            isLookAlikeResults={false}
                            isLikedCompanies={isLikedCompanies}
                            searchType={"technology"}
                            isIndustry={isIndustry}
                            isTechnology={isTechnology}
                            isProject={isProject}
                            countSelector={
                                isIndustry
                                    ? getIndustryCompaniesCountSelector
                                    : getTechnologyCompaniesCountSelector
                            }
                            getCountStatusSelector={
                                isIndustry
                                    ? getIndustryCompaniesCountStatusSelector
                                    : getTechnologyCompaniesCountStatus
                            }
                            localStorageSlugPrefix={
                                isIndustry
                                    ? "industry"
                                    : isLikedCompanies
                                    ? "likedCompanies"
                                    : "technology"
                            }
                            slug={slug}
                            request={request}
                            countRequest={countRequest}
                            overviewVisibility={overviewVisibility}
                            setOverviewVisibility={setOverviewVisibility}
                            name={name}
                            filterStats={filterStats}
                            handleSectorsClose={handleSectorsClose}
                            handleSubsectorsClose={handleSubsectorsClose}
                            preselectedSectors={preselectedSectors}
                            setPreselectedSectors={setPreselectedSectors}
                            preselectedSubsectors={preselectedSubsectors}
                            setPreselectedSubsectors={setPreselectedSubsectors}
                            activeView={activeView}
                            setActiveView={setActiveView}
                            companiesPageId={companiesPageId}
                            regionsOptions={regionsOptions}
                            handleRegionChange={handleRegionChange}
                            filterMapping={filterMapping}
                            isWorkspace={isWorkspace}
                            selected={selected}
                            setSelected={setSelected}
                            handleExcludeKeywordsChange={
                                handleExcludeKeywordsChange
                            }
                            handleIncludeKeywordsChange={
                                handleIncludeKeywordsChange
                            }
                        />
                    )}
                    {!isWorkspace && (
                        <TableToolbarRight
                            umSelected={0}
                            rowCount={0}
                            onSelectAllClick={() => console.log("Selected all")}
                            selected={selected}
                            selectedNames={selectedNames}
                            className={classes.header}
                            classes={classes}
                            user={user}
                            isTechnology={false}
                            isIndustry={false}
                            isProject={false}
                            sortDirection={sortDirection}
                            sortValue={sortValue}
                            dispatch={dispatch}
                            id={companiesPageId}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export default Header;
