import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useStyles } from "./style.js";
import { useTranslation } from "react-i18next";
// Components
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import Paper from "@material-ui/core/Paper";
import EnrichRequestModal from "../../../../pages/Company/LockedPage/EnrichRequestModal/EnrichRequestModal.js";
import TopUpCreditsModal from "../../../../pages/Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";
import NotEnoughCreditsModal from "../../../../pages/Company/LockedPage/NotEnoughCreditsModal/NotEnoughCreditsModal.js";
import OrderReceivedModal from "../../../../pages/Company/LockedPage/OrderReceivedModal/OrderReceivedModal.js";
import NoResults from "../../../../pages/Results/components/NoResults/NoResults.js";
import AreYouSureModal from "../../../../components/AreYouSureModal/AreYouSureModal";
import Typography from "../../../../components/Typography/Typography";
// Hooks
import { useAuthHook } from "../../../../common/useAuthHook";
import { useLockedPageHook } from "../../../../pages/Company/LockedPage/hooks/useLockedPageHook.js";
import { useHeaderHook } from "../../../../pages/Company/Header/hooks/useHeaderHook.js";
import { useAppEventTracking } from "../../../../common/useAppEventTracking";
// Constants
import { REQUEST_SUCCESS } from "../../../../constants/statuses";
import { getPricingPage } from "../../../../constants/endpoints";
// Redux
import { exportCsvRequest } from "../../../../store/orders/requests";
// Utils
import { createCompanyData } from "../../../../utils/companyUtils.js";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function ListView({
    companies,
    isTechnology,
    isIndustry,
    isSharedPage = false,
    searchResults,
    onSortChange,
    sortDirection,
    sortValue,
    isProject = false,
    openRemoveWarningModal,
    projectSlug,
    matchRemoved = false,
    searchResultsSid,
    source,
    isLikedCompany,
    overviewVisibility,
    setTableToolbarClassName,
    setSelected,
    setSelectedCompanies,
    setSelectedCompany,
    setSelectedNames,
    selected,
    selectedNames,
    selectedCompany,
    selectedCompanies,
    tableToolbar,
	page
}) {
    const { t } = useTranslation();
    const { user } = useAuthHook();
    const { mixpanelTrackEvent } = useAppEventTracking();
    const [order, setOrder] = useState(sortDirection);
    const [orderBy, setOrderBy] = useState(sortValue);
    const tableHeader = useRef(null);
    const table = useRef(null);
    const classes = useStyles({ user, tableWidth: table.current?.offsetWidth });
    const [tableHeaderClassName, setTableHeaderClassName] = useState(
        classes.header,
    );
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
        useState(false);
    const [downloadProps, setDownloadProps] = useState();

    useEffect(() => {
        return () => {
            setOrder(sortDirection);
            setOrderBy(sortValue);
            setTableHeaderClassName(classes.header);
            setTableToolbarClassName(classes.header);
            setShowAreYouSureModal(false);
            setShowDownloadsExceededModal(false);
            setDownloadProps();
            tableToolbar.current = null;
            tableHeader.current = null;
            table.current = null;
        };
    }, []);

    const dispatch = useDispatch();
    const rows =
        companies &&
        companies.map(company =>
            createCompanyData(
                company.name,
                company.description,
                company.total_funding_amount_dollars,
                company.founded_date,
                company.number_of_employees,
                company.investment_stage,
                company.hq_country,
                matchRemoved ? null : (company.degree_of_fit * 100).toFixed(),
                company.slug,
                company.id,
                company.enrichment_request,
                company.ready_for_release,
                company.website,
                company.deck,
                company.sectors,
                company.comments_cnt,
                company.subsectors,
                company.projects,
            ),
        );
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        onSortChange(property, isDesc ? "asc" : "desc");
    };

    useEffect(() => {
        return () => {
            setOrder();
            setOrderBy();
            setTableHeaderClassName(classes.header);
            setTableToolbarClassName(classes.header);
            setShowAreYouSureModal(false);
            setShowDownloadsExceededModal(false);
            setDownloadProps();
        };
    }, []);

    useEffect(() => {
        setOrder(sortDirection);
        setOrderBy(sortValue);
    }, [sortDirection, sortValue]);

    const isSelected = id => selected.indexOf(id) !== -1;

    useEffect(() => {
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (!isSharedPage && table && table.current) {
                if (window.pageYOffset > table.current.offsetTop) {
                    setTableHeaderClassName(classes.stickyHeader);
                    setTableToolbarClassName(classes.stickyToolbar);
                } else {
                    setTableHeaderClassName(classes.header);
                    setTableToolbarClassName(classes.header);
                }
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, [overviewVisibility]);

    const {
        getCredits,
        getUser,
        setCredits,
        enrichRequestModalShow,
        notEnoughCreditsModalShow,
        topUpCreditsModalShow,
        showEnrichSuccess,
        handleClosingTopUpCreditsDialog,
        handleClosingNotEnoughCreditsDialog,
        handleTopUp,
        handleBuyingTopUpCreditsDialog,
        handleClosingEnrichDialog,
        showEnrichRequestModal,
        handleCreateProjectDialogOpened,
        handleEnrichSuccess,
        handleClosingSuccess,
        credits,
        location,
    } = useLockedPageHook({
        company: selectedCompany ? selectedCompany : selectedCompanies[0],
        type: "company",
    });

    const {
        handleToggleDialog,
        openCreateProjectDialog,
        addSelectedCompanyToProject,
        addItemToProjectStatus,
        dialogOpened,
        anchorEl,
        open,
        tagName,
        errorTagNameInput,
        handleTagNameInputKeyUp,
    } = useHeaderHook({ getUser, company: selectedCompany });

    const [showProjectPopup, setShowProjectPopup] = useState(false);

    useEffect(() => {
        if (addItemToProjectStatus === REQUEST_SUCCESS) {
            setShowProjectPopup(false);
        }
    }, [addItemToProjectStatus]);

    useEffect(() => {
        getCredits
            ? setCredits(getCredits)
            : getUser &&
              getUser.team &&
              getUser.team.credits &&
              setCredits(getUser.team.credits);
    }, [getCredits, getUser, setCredits]);
	
	useEffect(()=>{
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	},[page])

    const getDownloadProps = ({ ids, type, page, pageType, doc, name }) => {
        setDownloadProps({ ids, type, page, pageType, doc, name });
    };

    const download = () => {
        dispatch(exportCsvRequest(downloadProps));
        mixpanelTrackEvent(`Downloaded document`, {
            type: downloadProps.doc,
            page: isIndustry
                ? "industry"
                : isTechnology
                ? "technology"
                : isProject
                ? "project"
                : "results",
        });
    };

    return (
        <>
            {rows && rows.length > 0 ? (
                <Paper
                    sx={{ width: "100%", mb: 2 }}
                    className={classnames([
                        classes.tablePaper,
                        {
                            [classes.tablePaperWithStickyHeader]:
                                tableHeaderClassName === classes.stickyHeader,
                        },
                    ])}>
                    <div
                        aria-labelledby="tableTitle"
                        ref={table}
                        className={classes.tableTitle}>
                        <TableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            ref={tableHeader}
                            className={tableHeaderClassName}
                            width={`${table.current?.offsetWidth}px`}
                            matchRemoved={matchRemoved}
                        />
                        <>
                            <div className={classes.customRows}>
                                {rows
                                    .slice()
                                    .sort(getComparator(order, orderBy))
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.id,
                                        );

                                        return (
                                            <TableRow
                                                company={row}
                                                index={index}
                                                isItemSelected={isItemSelected}
                                                selected={selected}
                                                setSelected={setSelected}
                                                selectedCompanies={
                                                    selectedCompanies
                                                }
                                                setSelectedCompanies={
                                                    setSelectedCompanies
                                                }
                                                selectedNames={selectedNames}
                                                setSelectedNames={
                                                    setSelectedNames
                                                }
                                                user={user}
                                                isEnriched={
                                                    row.ready_for_release
                                                }
                                                isTechnology={isTechnology}
                                                isIndustry={isIndustry}
                                                showEnrichRequestModal={
                                                    showEnrichRequestModal
                                                }
                                                selectedCompany={
                                                    selectedCompany
                                                }
                                                setSelectedCompany={
                                                    setSelectedCompany
                                                }
                                                matchRemoved={matchRemoved}
                                                openRemoveWarningModal={
                                                    openRemoveWarningModal
                                                }
                                                projectSlug={projectSlug}
                                                isProject={isProject}
                                                isLikedCompany={isLikedCompany}
                                                searchResultsSid={
                                                    searchResultsSid
                                                }
                                                source={source}
                                                key={`${row.id}-list-view-row`}
                                                setShowAreYouSureModal={
                                                    setShowAreYouSureModal
                                                }
                                                setShowDownloadsExceededModal={
                                                    setShowDownloadsExceededModal
                                                }
                                                setDownloadProps={
                                                    getDownloadProps
                                                }
                                                type={"company"}
                                            />
                                        );
                                    })}
                            </div>
                        </>
                    </div>
                    <EnrichRequestModal
                        source="company"
                        credits={credits}
                        company={selectedCompany}
                        dialogOpened={enrichRequestModalShow}
                        onChange={handleClosingEnrichDialog}
                        createProjectDialogOpened={
                            handleCreateProjectDialogOpened
                        }
                        enrichSuccess={handleEnrichSuccess}
                        cId={selectedCompany && selectedCompany.id}
                    />
                    <NotEnoughCreditsModal
                        credits={credits}
                        dialogOpened={notEnoughCreditsModalShow}
                        onChange={handleClosingNotEnoughCreditsDialog}
                        onTopUp={handleTopUp}
                    />
                    {showEnrichSuccess && (
                        <OrderReceivedModal
                            dialogOpened={showEnrichSuccess}
                            onChange={handleClosingSuccess}
                        />
                    )}
                    <TopUpCreditsModal
                        dialogOpened={topUpCreditsModalShow}
                        onBuy={handleBuyingTopUpCreditsDialog}
                        onChange={handleClosingTopUpCreditsDialog}
                    />
                    <AreYouSureModal
                        dialogOpened={showAreYouSureModal}
                        onChange={() => setShowAreYouSureModal(false)}
                        title={t("download.areYouSureTitle")}
                        subtitle={
                            <>
                                {t("download.subtitleFreeTrial1")}
                                <Typography
                                    component="div"
                                    className={classes.actionText}
                                    variant="body"
                                    color="secondaryDark">
                                    {t("download.subtitleFreeTrial2")}
                                </Typography>
                            </>
                        }
                        actionButtonText={t("download.iWantToDownload")}
                        cancelButtonText={t("download.letsTryLater")}
                        onActionClick={download}
                    />
                    <AreYouSureModal
                        dialogOpened={showDownloadsExceededModal}
                        onChange={() => setShowDownloadsExceededModal(false)}
                        title={t("download.downloadsExceededTitle")}
                        subtitle={t("download.downloadsExceededSubtitle")}
                        actionButtonText={t("download.upgradePlan")}
                        cancelButtonText={t("download.upgradeLater")}
                        onActionClick={() =>
                            (window.location.href = getPricingPage(!!user))
                        }
                    />
                </Paper>
            ) : (
                <NoResults
                    reason={
                        "searchResults.length === 0 && !loading && noSearches "
                    }
                />
            )}
        </>
    );
}
