import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, typography, breakpoint }) =>
        createStyles({
            root: {
                // marginRight: 20
            },
            nav: {
                display: "flex",
                justifyContent: "space-between",
            },
            infoActions: {
                display: "flex",
                justifyContent: "space-between",
                marginTop: spacing(4),
            },
            breadCrumb: {
                display: "flex",
                alignItems: "flex-end",
            },
            breadCrumbIndustry: {
                display: "flex",
                flexDirection: "column",
            },
            breadCrumbSubIndustry: {
                display: "flex",
                flexDirection: "column",
            },
            imgArrowRight: {
                marginLeft: spacing(2),
                marginRight: spacing(2),
                marginBottom: 6,
            },
            request100Button: {
                background: "transparent",
                marginRight: spacing(2),
                border: "none",
                "&:hover": {
                    background: `${palette.primary.backgroundLight}!important`,
                    border: "none",
                },
            },
            mr16: {
                marginRight: spacing(2),
            },
            actions: {
                display: "flex",
                alignItems: "center",
            },
            searchFilters: {
                display: "flex",
                flexDirection: "column",
                marginTop: spacing(3),
            },
            refineAndSearch: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                [breakpoint.down("sm")]: {
                    width: "100%",
                    "& input": {
                        width: "100%",
                    },
                },
            },
            startEndornment: {
                marginRight: `${spacing(1)}px`,
            },
            goBack: {
                display: "flex",
                alignItems: "center",
                "& img": {
                    marginRight: spacing(1),
                },
                cursor: "pointer",
            },
            requestLink: {
                cursor: "pointer",
            },
            headerInput: {
                "& input": {
                    width: 167,
                },
                [breakpoint.down("sm")]: {
                    width: "100%",
                    "& input": {
                        width: "100%",
                    },
                },
            },
            filterWrap: {
                marginLeft: `${spacing(2)}px`,
                display: "flex",
                alignItems: "center",
                [breakpoint.down("md")]: {
                    marginLeft: 0,
                },
            },
            sortedBy: {
                marginRight: spacing(1),
            },
            multiselectAdjust: {
                width: 236,
                [breakpoint.down("md")]: {
                    width: `auto`,
                },
            },
            arrowIcon: {
                marginRight: `${spacing(1)}px`,
                cursor: "pointer",
                transform: "rotate(0deg) scale(1.4)",
            },
            rotateIcon: {
                transform: "rotate(180deg) scale(1.4)",
            },
            numButton: {
                display: "flex",
                alignItems: "center",
                marginBottom: spacing(2),
            },
            headerSortAndFilter: {
                display: "flex",
                flexDirection: "row",
                // [breakpoint.down("md")]: {
                //     marginTop: spacing(1)
                // }
            },
            sortingButton: {
                background: palette.common.white,
                borderTopLeftRadius: 24,
                borderBottomLeftRadius: 24,
                padding: "7px 8px",
                display: "flex",
                border: `1px solid ${palette.text.lightest}`,
                borderRight: 0,
                cursor: "pointer",
            },
            sortingIcon: {
                width: 24,
                height: 24,
            },
            rotateSortingButton: {
                transform: "rotate(180deg)",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 24,
                borderBottomRightRadius: 24,
                borderLeft: 0,
                borderRight: `1px solid ${palette.text.lightest}`,
            },
            filterButton: {
                position: "relative",
                background: `${palette.common.white} !important`,
                border: `1px solid ${palette.text.lightest}`,
                padding: `11px 23px`,
                borderRadius: 24,
                marginLeft: `${spacing(2)}px`,
                display: "flex",
                alignItems: "center",
                height: 40,
                "&:hover": {
                    background: `${palette.primary.backgroundLight}!important`,
                    cursor: "pointer",
                },
                [breakpoint.down("lg")]: {
                    marginLeft: spacing(1),
                },
            },
            filterButtonFull: {
                border: `1px solid ${palette.common.black}`,
            },
            displayedFiltersCount: {
                position: "absolute",
                top: -1,
                right: -1,
                background: palette.primary.dark,
                borderRadius: "50%",
                width: 16,
                height: 16,
                color: palette.common.white,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...typography.bodyXs,
            },
            arrowDown: {
                width: 12,
                position: "absolute",
                right: spacing(2),
                zIndex: 2,
                cursor: "pointer",
            },
            filterIcon: {
                "& > path": {
                    stroke: palette.text.lighter,
                },
            },
            filterIconFull: {
                "& > path": {
                    stroke: palette.primary.dark,
                },
            },
            ml8: {
                marginLeft: spacing(1),
            },
            header: {
                position: "relative",
                paddingLeft: spacing(2),
            },
            headerFiltersWrapper: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                flexWrap: "wrap",
                /* height: '65px' */
            },
            toolbarRight: {
                display: "flex",
                alignItems: "center",
                // marginLeft: "auto",
                [breakpoint.down("sm")]: {
                    order: 3,
                    marginLeft: 0,
                },
            },
            toolbarRightButtons: {
                display: "flex",
                alignItems: "center",
            },
            saveButton: {
                whiteSpace: "nowrap",
                marginRight: spacing(2),
                color: palette.common.black,
                cursor: "pointer",
                display: "flex",
                gap: 15,
                padding: `${spacing(1)}px ${spacing(3)}px`,
                alignItems: "center",
                borderRadius: "100px",
                background: "transparent",
                border: `1px solid ${palette.text.lightest}`,
                "& svg": {
                    filter: "grayscale(100%)",
                    opacity: 0.3,
                },
                "&:hover": {
                    background: palette.success.backgroundLight,
                    borderColor: "none",
                },
            },
            tableToolbar: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: "none",
                padding: spacing(2),
                [breakpoint.down("sm")]: {
                    order: 2,
                    width: "auto",
                    flexDirection: "row",
                },
            },
            tableCellCheckbox: {
                flex: 1,
                alignSelf: "flex-start",
                wordBreak: "break-word",
                maxWidth: 56,
                minWidth: 56,
                margin: "auto",
            },
            iconEnabled: {
                "& path": {
                    fill: palette.common.black,
                },
            },
            iconDownloadOpen: {
                "& path": {
                    fill: palette.primary.dark,
                },
            },
        }),
);
