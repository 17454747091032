import React, { useEffect, useState } from "react";
import moment from "moment";
// Hooks
import { useStyles } from "./style.js";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useAppEventTracking } from "../../common/useAppEventTracking";
import { useAuthHook } from "../../common/useAuthHook";
import { useRouterHook } from "../../common/useRouterHook";
//UI Components
import Box from "@material-ui/core/Box";
import Typography from "../../components/Typography/Typography.js";
import Layout from "../Layout/Layout";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Link from "../../components/Link/Link";
//Assets
import { ReactComponent as Coins } from "../../assets/icons/Coins.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/ArrowRight.svg";
// Redux
import {
    getCreditsSelector,
    getSubscriptionSelector,
    getUserSelector,
} from "../../store/auth/selectors";
import { getCreditsSummarySelector } from "../../store/credits/selectors";
import { getCreditsSummaryRequest } from "../../store/credits/requests";
import { fetchInvoicesRequest } from "../../store/billing/requests";
import {
    getBillingSelector,
    getInvoicesStatusSelector,
} from "../../store/billing/selectors";
import { REQUEST_PENDING } from "../../constants/statuses.js";
import ContentLoader from "../../components/ContentLoader/ContentLoader.js";
import TopUpCreditsModal from "../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal";

const CreditSummary = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { userLoading } = useAuthHook();
    const dispatch = useDispatch();
    const getCredits = useSelector(getCreditsSelector());
    const getUser = useSelector(getUserSelector);
    const subscription = useSelector(getSubscriptionSelector());
    const creditsSummary = useSelector(getCreditsSummarySelector);
    const [shouldShowBillingDate, setShouldShowBillingDate] = useState(false);
    const [credits, setCredits] = useState(0);
    const billings = useSelector(getBillingSelector);
    const invoicesStatusSelector = useSelector(getInvoicesStatusSelector);
    const { goBack } = useRouterHook();
    const [topUpCreditsModalShow, setTopUpCreditsModalShow] = useState(false);
    const { mixpanelTrackEvent } = useAppEventTracking();

    useEffect(() => {
        mixpanelTrackEvent("Credit summary visited");
    }, []);

    useEffect(() => {
        dispatch(getCreditsSummaryRequest());
        dispatch(fetchInvoicesRequest());
    }, [dispatch]);

    useEffect(() => {
        getCredits
            ? setCredits(getCredits)
            : getUser &&
              getUser.team &&
              getUser.team.credits &&
              setCredits(getUser.team.credits);
    }, [getCredits, getUser]);

    useEffect(() => {
        !userLoading &&
            billings &&
            setShouldShowBillingDate(
                billings &&
                    billings.meta &&
                    billings.meta.next_billing_date &&
                    (subscription.name === "single" ||
                        subscription.name === "pro" ||
                        subscription.name === "teams"),
            );
    }, [subscription, billings, userLoading]);

    const isOwner = getUser && getUser.team && getUser.team.role === "owner";
    const isOtherPlan =
        getUser &&
        getUser.subscription &&
        Object.keys(getUser.subscription).length !== 0 &&
        getUser.subscription.constructor === Object &&
        getUser.subscription &&
        getUser.subscription.name !== "pro" &&
        getUser.subscription &&
        getUser.subscription.name !== "teams";
    const shouldBeBuying = isOwner || isOtherPlan;

    const isLoading = () => {
        return invoicesStatusSelector === REQUEST_PENDING;
    };

    return (
        <Layout match="credit-summary">
            {isLoading() ? (
                <ContentLoader />
            ) : (
                <div className={classes.root}>
                    <div>
                        <Link
                            onClick={goBack}
                            variant="body2"
                            rotateIcon
                            color="secondary"
                            className={classes.goBack}>
                            {t("companyProfile.backToResults")}
                            <ArrowRight />
                        </Link>
                    </div>
                    <Typography variant="subtitle1">
                        {t("creditSummary.title")}
                    </Typography>
                    <Box>
                        {shouldBeBuying && (
                            <div>
                                <Typography
                                    variant="bodyButton"
                                    color="textLight"
                                    component="span">
                                    {t("creditSummary.toGetMoreCredits")}
                                </Typography>
                                <Typography
                                    variant="bodyButton"
                                    className={classes.upgradeYourPlan}
                                    component="span"
                                    inline
                                    onClick={() =>
                                        setTopUpCreditsModalShow(true)
                                    }>
                                    {t("creditSummary.getMoreCredits")}
                                </Typography>
                            </div>
                        )}
                        <div className={classes.availableCreditsBox}>
                            <div className={classes.creditsHeader}>
                                <Coins className={classes.coins} />
                                <div className={classes.availableCredits}>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.credits}>
                                        <strong>{credits}</strong>
                                    </Typography>
                                    <Typography
                                        variant="bodyXs"
                                        color="secondaryDark">
                                        {t("profileSettings.availableCredits")}
                                    </Typography>
                                </div>
                            </div>
                            {shouldShowBillingDate && (
                                <Typography
                                    variant="bodyButton"
                                    color="success">
                                    {t("creditSummary.nextCreditsArrive", {
                                        date: moment(
                                            billings &&
                                                billings.meta &&
                                                billings.meta.next_billing_date,
                                        ).format("MMM D, YYYY"),
                                    })}
                                </Typography>
                            )}
                        </div>
                        {creditsSummary.length > 0 && (
                            <div>
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="bodyButton"
                                                        color="secondaryDark"
                                                        component="div">
                                                        {t(
                                                            "profileSettings.date",
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="bodyButton"
                                                        color="secondaryDark"
                                                        component="div">
                                                        {t(
                                                            "creditSummary.report",
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="bodyButton"
                                                        color="secondaryDark"
                                                        component="div">
                                                        {t(
                                                            "creditSummary.cost",
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {creditsSummary.map((credit, i) => (
                                                <TableRow
                                                    className={classes.tableRow}
                                                    key={`${i}-credit`}>
                                                    <TableCell
                                                        align="left"
                                                        component="th"
                                                        scope="row">
                                                        <Typography
                                                            variant="bodyButton"
                                                            color="secondaryDark"
                                                            component="div">
                                                            {moment(
                                                                credit.date,
                                                            ).format(
                                                                "MMM D, YYYY",
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            variant="bodyButton"
                                                            color="secondaryDark"
                                                            component="div">
                                                            {credit.description}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography
                                                            variant="bodyButton"
                                                            color="secondaryDark"
                                                            component="div">
                                                            {credit.amount}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </Box>
                </div>
            )}
            <TopUpCreditsModal
                dialogOpened={topUpCreditsModalShow}
                onBuy={() => setTopUpCreditsModalShow(false)}
                onChange={() => setTopUpCreditsModalShow(false)}
            />
        </Layout>
    );
};
export default CreditSummary;
