import { makeStyles, createStyles } from "@material-ui/core/styles";
import BlurryResults from "../../../assets/images/BlurryResults.png";
export const useStyles = makeStyles(
    ({ palette, spacing, shape, typography, breakpoint }) =>
        createStyles({
            resultsContent: {
                marginTop: spacing(3),
                padding: 0,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: spacing(2),
                [breakpoint.down("lg")]: {
                    padding: `${spacing(2)}px 0`,
                    marginTop: 0,
                },
            },
            signUpBox: {
                maxWidth: 640,
                width: "100%",
                left: "calc(50% - 320px)",
                top: spacing(5),
                background: palette.secondary.main,
                color: palette.common.white,
                padding: spacing(4),
            },
            signUpText: {
                maxWidth: 344,
                marginTop: spacing(1),
                marginBottom: spacing(2),
            },
            selectedCard: {
                border: `1px solid ${palette.primary.main} !important`,
            },
            appliedFiltersWrap: {
                marginTop: spacing(1),
                display: "flex",
                flexDirection: "column",
            },
            appliedFilters: {
                display: "flex",
                flexWrap: "wrap",
            },
            filterTag: {
                background: palette.common.white,
                border: `1px solid ${palette.secondary.main};`,
                color: palette.secondary.main,
                padding: `0 ${spacing(1)}px`,
                borderRadius: shape.borderRadius,
                boxSizing: "border-box",
                marginTop: spacing(1),
                marginRight: spacing(1),
            },
            closeIcon: {
                maxWidth: spacing(1),
                maxHeight: spacing(1),
                marginLeft: spacing(1),
                cursor: "pointer",
            },
            root: {
                // background: palette.common.white,
                // border: "1px solid #F0F1F3",
                flex: "calc(50% - 8px)",
                maxWidth: "calc(50% - 8px)",
                // boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
                // borderRadius: 4,
                marginBottom: spacing(2),
                // padding: spacing(3),
                "&:nth-child(odd)": {
                    marginRight: spacing(2),
                    [breakpoint.down("sm")]: {
                        marginRight: 0,
                    },
                },
                "&:hover": {
                    boxShadow:
                        "0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)",
                    cursor: "pointer",
                },
                position: "relative",
                [breakpoint.down("sm")]: {
                    width: "100%",
                    flex: "100%",
                    maxWidth: "100%",
                },
            },
            header: {
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                alignItems: "center",
                [breakpoint.down("xl")]: {
                    height: 64,
                },
            },
            tags: {
                display: "flex",
                marginTop: spacing(2),
            },
            tag: {
                marginRight: spacing(1),
            },
            avatar: {
                border: "1px solid #F0F1F3",
                borderRadius: 4,
                padding: 4,
                marginRight: spacing(2),
            },
            arrowDown: {
                marginLeft: spacing(2),
            },
            score: {
                lineHeight: "40px",
                position: "absolute",
                top: 60,
            },
            scoreCaption: {
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",
            },
            infoIcon: {
                // marginLeft: spacing(1)
                position: "absolute",
                top: 12,
                right: 12,
            },
            typographyTag: {
                alignItems: "center",
                background: palette.primary.backgroundLight,
                height: 20,
                color: palette.text.main,
                padding: "4px 12px",
                borderRadius: "8px",
                display: "inline-flex",
                fontFamily: "GT Flexa Mono, sans-serif",
                ...typography.caption,
            },
            description: {
                marginBottom: spacing(3),
                width: "100%",
                color: palette.text.main,
                "-webkit-line-clamp": 2,
                "-webkit-box-orient": "vertical",
                display: "-webkit-box",
                overflowY: "hidden",
                maxHeight: 48,
            },
            mt24: {
                marginTop: spacing(3),
            },
            mt0: {
                marginTop: -9,
            },
            info: {
                marginBottom: spacing(3),
                flex: "50%",
                [breakpoint.down("xl")]: {
                    marginBottom: 0,
                },
            },
            infoTitle: {
                marginBottom: 2,
                color: palette.text.main,
            },
            rssButton: {
                border: "none",
                background: "transparent!important",
                color: palette.text.main,
                "&:hover": {
                    background: `${palette.primary.backgroundLight}!important`,
                    border: "none",
                },
                position: "relative",
            },
            questionMark: {
                marginRight: spacing(1),
            },
            communicationIcon: {
                padding: `${spacing(1)}px ${spacing(2)}px`,
                cursor: "pointer",
            },
            likeIcon: {
                marginLeft: spacing(2),
                marginRight: spacing(1),
                [breakpoint.down("lg")]: {
                    marginLeft: 0,
                },
            },
            numberOfEmployees: {
                minHeight: 18,
            },
            enrichedProfileTag: {
                color: palette.common.white,
                background: palette.primary.main,
                padding: `0 ${spacing(1)}px`,
                borderRadius: shape.borderRadius,
                height: spacing(3),
                display: "flex",
                alignItems: "center",
                width: 105,
            },
            emptyTag: {
                height: spacing(3),
            },
            valuerScoreBlock: {
                position: "absolute",
                top: -32,
                right: -32,
                background: palette.primary.backgroundMedium,
                boxShadow: `inset 1px 0px 0px #EFEFEE`,
                height: 164,
                width: 164,
                borderTopRightRadius: spacing(2),
                [breakpoint.down("xl")]: {
                    top: -16,
                    right: -16,
                    width: 80,
                    height: 80,
                    borderTopRightRadius: spacing(2),
                },
            },
            valuerScore: {
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            },
            sdg: {
                marginRight: spacing(1),
            },
            sdgTooltip: {
                textAlign: "center",
                color: palette.common.white,
            },
            sdgTooltipTitle: {
                fontWeight: "700",
            },
            chart: {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: spacing(2),
            },
            scoreBreakdown: {
                marginTop: -spacing(1),
                position: "relative",
                color: palette.secondary.dark,
            },
            projectName: {
                display: "flex",
                alignItems: "center",
            },
            rotateArrow: {
                transform: "rotate(180deg)",
            },
            arrowIcon: {
                marginLeft: spacing(1),
            },
            disabled: {
                // pointerEvents: "none",
                opacity: 0.5,
            },
            blurryBg: {
                position: "relative",
                backgroundImage: `url(${BlurryResults})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 611px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: spacing(2),
                minHeight: 611,
                width: "100%",
                height: 800,
                marginTop: spacing(1),
                [breakpoint.down("xl")]: {
                    backgroundImage: "none",
                    minHeight: 500,
                    height: 650,
                },
                [breakpoint.down("md")]: {
                    backgroundImage: "none",
                    minHeight: 500,
                    height: "auto",
                },
            },
            guestBlock: {
                background: palette.success.backgroundLight,
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.16)",
                borderRadius: spacing(2),
                padding: `${spacing(8)}px ${spacing(8)}px ${spacing(
                    5,
                )}px ${spacing(8)}px`,
                width: "86%",
                position: "absolute",
                marginTop: 100,
                textAlign: "center",
                marginBottom: 77,
                [breakpoint.down("xl")]: {
                    padding: `${spacing(3)}px ${spacing(4)}px ${spacing(
                        4,
                    )}px ${spacing(4)}px`,
                    margin: 0,
                    width: "100%",
                    position: "relative",
                    top: 0,
                },
            },
            guestBlockContent: {
                display: "flex",
                flexDirection: "column",
            },
            freeStartTrial: {
                position: "absolute",
                right: 0,
            },
            readyText: {
                color: palette.text.dark,
            },
            startTrialText: {
                color: palette.text.dark,
                marginTop: spacing(1),
            },
            getAccessText: {
                marginTop: spacing(3),
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            actions: {
                marginTop: spacing(4),
                display: "flex",
                justifyContent: "center",
            },
            signUp: {
                background: palette.success.dark,
                width: 200,
                border: "none",
                marginLeft: spacing(3),
            },
            signIn: {
                background: "transparent",
                border: `1px solid ${palette.success.dark}`,
                color: palette.success.dark,
                width: 200,
            },
            cardName: {
                display: "flex",
                flexDirection: "column",
                minHeight: 61,
            },
            headerWrapper: {
                minHeight: 132,
                position: "relative",
                "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: -32,
                    height: 1,
                    background: palette.text.backgroundMain,
                    [breakpoint.down("xl")]: {
                        width: "calc(100% + 16px)",
                    },
                },
                [breakpoint.down("xl")]: {
                    minHeight: 0,
                },
            },
            noFilteredResults: {
                margin: `${spacing(9)}px auto`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            noFilteredResultsImg: {
                marginTop: spacing(4),
            },
            dislikeImg: {
                transform: "rotate(180deg)",
                marginLeft: spacing(2),
                marginRight: spacing(1),
            },
            noResults: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: `${spacing(8)}px 0`,
            },
            checkEnrichedCompanyImg: {
                marginRight: `${spacing(1)}px`,
            },
            enrichedCompanyInfo: {
                display: "flex",
            },
            enrichedCompanyInfoTitle: {
                color: palette.success.dark,
            },
            enrichedCompany: {
                display: "flex",
                alignItems: "center",
                backgroundColor: palette.success.backgroundLight,
                padding: `${spacing(1)}px`,
                borderRadius: `${spacing(1)}px`,
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            sdgs: {
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            checkEnrichedCompanyImgHeader: {
                width: 16,
                height: 16,
                marginRight: 3,
            },
            enrichedCompanyHeader: {
                display: "none",
                alignItems: "center",
                padding: 0,
                borderRadius: `${spacing(1)}px`,
                [breakpoint.down("xl")]: {
                    display: "flex",
                },
            },
            loader: {
                margin: `${spacing(10)}px auto`,
            },
            sectorTagsWrapper: {
                maxWidth: "calc(100% - 140px)",
                marginTop: spacing(2),
            },
            scoreMatchWrap: {
                position: "absolute",
                top: 0,
                right: 142,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: -20,
                [breakpoint.down("xl")]: {
                    position: "relative",
                    top: "auto",
                    right: "auto",
                    marginTop: 0,
                    justifyContent: "flex-start",
                },
            },
            infoIconNew: {
                marginLeft: spacing(1),
            },
            titleWrap: {
                display: "flex",
                flexDirection: "column",
                [breakpoint.down("xl")]: {
                    marginTop: -6,
                },
            },
            arrowUp: {
                position: "fixed",
                bottom: 10,
                right: 80,
                fill: "#0B295F",
                cursor: "pointer",
                zIndex: 2,
                "&:hover": {
                    fill: "#485E87",
                },
            },
            resultsLoader: {
                width: "100%",
            },
            formFieldsWrapper: {
                // maxHeight: 287,
                overflowY: "auto",
                marginBottom: spacing(3),
            },
            ctasWrapper: {
                display: "flex",
                flexDirection: "row",
            },
            createAccountButton: {
                margin: `0 ${spacing(2)}px 0 0`,
            },
            signInCopy: {
                textAlign: "left",
                marginBottom: 6,
            },
            form: {
                marginTop: spacing(2),
            },
            signUpBtn: {
                marginTop: 4,
            },
            passwordInputWrapper: {
                marginBottom: spacing(3),
                width: "calc(50% - 8px)",
            },
            confirmPasswordInputWrapper: {
                marginBottom: spacing(3),
                marginLeft: spacing(2),
                width: "calc(50% - 8px)",
            },
            [breakpoint.down("md")]: {
                blurryBg: {
                    // height: 980
                },
                guestBlock: {
                    padding: "24px 24px 62px 24px",
                },
                ctasWrapper: {
                    flexDirection: "column",
                },
                signInCopy: {
                    textAlign: "center",
                    marginBottom: spacing(2),
                },
                signUpBtn: {
                    marginBottom: spacing(4),
                },
                formFieldsWrapper: {
                    maxHeight: "none",
                },
                checkboxRoot: {
                    alignSelf: "flex-start",
                    paddingTop: 3,
                },
                checkboxLabel: {
                    textAlign: "left",
                },
                confirmPasswordInputWrapper: {
                    width: "100%",
                    marginBottom: spacing(3),
                    marginLeft: 0,
                },
                passwordInputWrapper: {
                    width: "100%",
                    marginBottom: spacing(3),
                },
            },
            likeDislikeIcons: {
                display: "flex",
                [breakpoint.down("lg")]: {
                    width: "100%",
                },
            },
            likeDislikeWrap: {
                display: "flex",
                alignItems: "center",
            },
            commentsCount: {
                color: palette.text.lighter,
            },
        }),
);
