import React, { useState, useEffect } from "react";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Menu from "../Menu/Menu";
import CheckBox from "../Checkbox/Checkbox";
import Typography from "../Typography/Typography";
import Button from "../Button/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as MoreIcon } from "../../assets/icons/MoreIconWorkspace.svg";
import Save from "../../assets/icons/SaveW.svg";
import Preview from "../../assets/icons/PreviewW.svg";
import FullProfile from "../../assets/icons/FullProfileW.svg";
import OrderReport from "../../assets/icons/OrderReportW.svg";
import Company from "../../assets/icons/Company.svg";
import Technology from "../../assets/icons/Technology.svg";
import SearchQuery from "../../assets/icons/SearchQuery.svg";
import Industry from "../../assets/icons/Industry.svg";
import PreviewModal from "../../pages/Results/components/modals/PreviewModal";
import { useLockedPageHook } from "../../pages/Company/LockedPage/hooks/useLockedPageHook.js";
import EnrichRequestModal from "../../pages/Company/LockedPage/EnrichRequestModal/EnrichRequestModal.js";
import TopUpCreditsModal from "../../pages/Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";
import NotEnoughCreditsModal from "../../pages/Company/LockedPage/NotEnoughCreditsModal/NotEnoughCreditsModal.js";
import OrderReceivedModal from "../../pages/Company/LockedPage/OrderReceivedModal/OrderReceivedModal.js";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
import RemoveWarningModal from "../../pages/Projects/ProjectProfile/RemoveWarningModal/RemoveWarningModal";
import { useAppEventTracking } from "../../common/useAppEventTracking";
import { getPricingPage } from "../../constants/endpoints";
import { deleteProjectRequest } from "../../store/projects/requests";
import { useDispatch } from "react-redux";
import { exportCsvRequest } from "../../store/orders/requests";
import { AddToProjectMenu } from "../AddToProjectMenu/AddToProjectMenu";
import OrderReportModal from "../../pages/Technologies/Components/OrderReportModal/OrderReportModal.js";

export const WorkspaceTableRow = ({
    rowData,
    openPage,
    isAuthenticated,
    user,
    projectSlug,
    project
}) => {
    const { name, deck, description, type, subType, report } = rowData;
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [isPreviewModalOpened, setIsPreviewModalOpened] = useState(false);
    const [removeWarningModalOpened, setRemoveWarningModalOpened] =
        useState(false);
    const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
    const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
        useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [saveMenuOpened, setSaveMenuOpened] = useState(false);
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const handleOpenCompanyPreview = () => {
        setIsPreviewModalOpened(true);
    };

    const download = docType => {
        let downloadType;
        switch (type) {
            case "technology":
                downloadType = "technologies";
                break;
            case "industry":
                downloadType = "industries";
                break;
            default:
                downloadType = "companies";
        }
        let payload = {
            ids: rowData.id,
            type: downloadType,
            doc: docType,
            name: `valuer_${name}_report`,
            pageName: "project",
        };
        dispatch(exportCsvRequest(payload));
        mixpanelTrackEvent(`Downloaded document`, {
            type: "pdf",
            page: "project",
        });
    };

    const handleDownloadToCsv = e => {
        e.preventDefault();
        e.stopPropagation();
        if (
            user &&
            user.downloads === 1 &&
            (user.subscription.plan_type === "free_trial" ||
                user.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            user &&
            user.subscription.plan_type === "free_trial" &&
            user.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            user &&
            user.subscription.plan_type === "free_trial" &&
            !user.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!user);
        } else if (user && user.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            download("csv");
        }
    };

    const handleDownloadToPdf = e => {
        e.preventDefault();
        e.stopPropagation();
        if (
            user.downloads === 1 &&
            (user.subscription.plan_type === "free_trial" ||
                user.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            user.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            !user.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!user);
        } else if (user.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            download("pdf");
        }
    };

    const removeProject = e => {
        e.preventDefault();
        e.stopPropagation();
        setRemoveWarningModalOpened(true);
    };

    const {
        enrichRequestModalShow,
        notEnoughCreditsModalShow,
        topUpCreditsModalShow,
        showEnrichSuccess,
        handleClosingTopUpCreditsDialog,
        handleClosingNotEnoughCreditsDialog,
        handleTopUp,
        handleBuyingTopUpCreditsDialog,
        handleClosingEnrichDialog,
        showEnrichRequestModal,
        handleCreateProjectDialogOpened,
        handleEnrichSuccess,
        handleClosingSuccess,
        credits,
        showOrderReportModal,
        setShowOrderReportModal,
        setNotEnoughCreditsModalShow,
    } = useLockedPageHook({ company: rowData, type });

    let iconProperty = null;
    const renderIcon = () => {
        switch (type) {
            case "company":
                iconProperty = classes.iconCompany;
                return Company;
            case "technology":
                iconProperty = classes.iconTechnology;
                return Technology;
            case "search":
                iconProperty = classes.iconSearch;
                if (subType === "Look-alike Search") {
                    iconProperty = classes.iconIndustry;
                    return Industry;
                }
                return SearchQuery;
            case "industry":
                iconProperty = classes.iconIndustry;
                return Industry;
        }
    };

    const capitalType = type && type.charAt(0).toUpperCase() + type.slice(1);

    const iconBg =
        subType && subType === "Look-alike Search"
            ? classes.searchLookAlike
            : classes[type];
    // const iconProperty = subType && subType === "Look-alike Search" ?
    // 	classes.iconIndustry : classes.icon+`${capitalType}`;

    const handleMenuClick = company => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
    };

    const [menuItems, setMenuItems] = useState(
        type === "industry"
            ? []
            : [
                  {
                      label: (
                          <Typography variant="bodyXs" className={classes.mono}>
                              {t("common.downloadAsCSV")}
                          </Typography>
                      ),
                      onClick: handleDownloadToCsv,
                      intercomId: "Download company as CSV",
                  },
                  {
                      label: (
                          <Typography variant="bodyXs" className={classes.mono}>
                              {t("common.downloadAsPDF")}
                          </Typography>
                      ),
                      onClick: handleDownloadToPdf,
                      intercomId: "Download company as PDF",
                  },
                  // {
                  //   label: <Typography variant="bodyXs" className={classes.mono}>{t('companyProfile.enrichmentBanner.button.paying')}</Typography>,
                  //   onClick: showEnrichRequestModal,
                  //   disabled: enrichment_request,
                  //   intercomId: "Request Enrichment button"
                  // }
              ],
    );

    const handleEnoughCredits = value => {
        setShowOrderReportModal(false);
        setNotEnoughCreditsModalShow(true);
    };

    const openProfile = e => {
        e.preventDefault();
        e.stopPropagation();
        if (type === "industry") {
            mixpanelTrackEvent(`${type} profiles viewed under industry`, {
                [`${type} name`]: rowData.name,
            });
            openPage(`/industries/${rowData.slug}`, false);
            return;
        } else if (type === "technology") {
            mixpanelTrackEvent(`${type} profile under technology opened`, {
                [`${type} name`]: name,
            });
            openPage(`/technologies/${rowData.slug}`, false);
            return;
        } else if (type === "company") {
            let url = `/companies/${rowData.slug}`;

            if (rowData && rowData.id) {
                if (type === "technology" || type === "industry") {
                    url = `${url}?cid=${rowData.id}`;
                } else {
                    isAuthenticated
                        ? (url = `${url}?cid=${rowData.id}`)
                        : (url = ``);
                }
            }
            openPage(url, false);
        }
        intercomTrackEvent(`${type} profiles viewed`, {
            [`${type} name`]: rowData.name,
            [`${type} slug`]: rowData.slug,
        });
    };

    const previewModalOnChange = () => {
        setIsPreviewModalOpened(false);
    };

    return (
        <div
            className={classnames([classes.tableRow, classes.rowHover])}
            role="checkbox"
            //   aria-checked={isItemSelected}
            tabIndex={-1}
            //   key={`${id}-workspace-list-view-row`}
            //   selected={isItemSelected}
        >
            {/*<div padding="checkbox" className={classes.tableCellCheckbox}>*/}
            {/* <CheckBox
              className={classes.checkbox}
              color="primary"
            //   checked={isItemSelected}
            //   inputProps={{
            //     'aria-labelledby': `table-checkbox-${index}`,
            //   }}
            /> */}
            {/*</div>*/}
            <div
                // id={`table-checkbox-${index}`}
                scope="row"
                padding="none"
                className={classes.tableCellName}>
                <Typography
                    component="a"
                    //   href={`/companies/${slug}?cid=${id}`}
                    onClick={handleOpenCompanyPreview}
                    variant="bodyXl"
                    color="black"
                    className={classes.companyName}>
                    <Tooltip
                        title={
                            <Typography
                                component="div"
                                align="left"
                                variant="bodyXs"
                                color="black">
                                {name}
                            </Typography>
                        }
                        classes={{
                            tooltip: classes.tooltip,
                            arrow: classes.tooltipArrow,
                        }}
                        arrow
                        placement="bottom">
                        <>
                            <div
                                className={classnames(
                                    classes.iconWrapper,
                                    iconBg,
                                )}>
                                <img
                                    src={renderIcon()}
                                    alt="Icon"
                                    className={classnames(
                                        iconProperty,
                                        iconBg,
                                        {
                                            [classes[`${type}Enriched`]]:
                                                !!report,
                                        },
                                    )}
                                />
                                {report && (
                                    <img
                                        src={OrderReport}
                                        alt="Report ordered"
                                        className={classes.enrichedIcon}
                                    />
                                )}
                            </div>
                            <span>{name}</span>
                        </>
                    </Tooltip>
                </Typography>
            </div>
            <Typography
                component="div"
                variant="body"
                className={classes.tableCell2}>
                <Typography className={classnames(classes.typeTag, iconBg)}>
                    {subType
                        ? subType.replace("_", " ")
                        : type.replace("_", " ")}
                </Typography>
            </Typography>
            <Typography
                component="div"
                align="left"
                variant="body"
                className={classnames([
                    classes.tableCellDescription,
                    classes.description,
                    classes.hideOnHover,
                ])}>
                <Typography
                    component="div"
                    align="left"
                    variant="body"
                    className={classes.tooltipDescriptionContent}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </Typography>
            <Typography
                component="div"
                align="left"
                variant="body"
                className={classes.showOnHover}>
                {!report && type !== "industry" ? (
                    <Typography
                        className={classes.hoverIcon}
                        onClick={e => {
                            showEnrichRequestModal(e, rowData);
                        }}>
                        <img src={OrderReport} alt="Order report" />
                        <span>
                            {report
                                ? t("workspace.listView.seeReport")
                                : t("workspace.listView.orderReport")}
                        </span>
                    </Typography>
                ) : null}
                <Typography className={classes.hoverIcon} onClick={openProfile}>
                    <img src={FullProfile} alt="Full profile" />
                    <span>
                        {type === "search"
                            ? t("workspace.listView.fullList")
                            : t("workspace.listView.fullProfile")}
                    </span>
                </Typography>
                <Typography
                    className={classes.hoverIcon}
                    onClick={handleOpenCompanyPreview}>
                    <img src={Preview} alt="Preview" />
                    <span>{t("workspace.listView.preview")}</span>
                </Typography>
                <AddToProjectMenu
                    buttonType="save"
                    company={rowData}
                    isMenuOpenedListener={setSaveMenuOpened}
                    type={type}
                    isProject={true}
                    projectSlug={projectSlug}
                />
            </Typography>
            {/*<div className={classes.tableCell1} align="right">*/}
            {/*{type !== "industry" ?*/}
            {/*	<Menu*/}
            {/*		btnClassName={classes.moreButton}*/}
            {/*		onChange={() => handleMenuClick(rowData)}*/}
            {/*		menuItems={menuItems}*/}
            {/*	>*/}
            {/*		<MoreIcon className={classnames([classes.moreIcon])}/>*/}
            {/*	</Menu> :*/}
            {/*	<MoreIcon className={classnames([classes.moreIcon])}/>*/}
            {/*}*/}
            {/*</div>*/}
            {isPreviewModalOpened && (
                <PreviewModal
                    key={rowData.id}
                    dialogOpened={isPreviewModalOpened}
                    onChange={previewModalOnChange}
                    isProject={true}
                    isIndustry={type === "industry"}
                    isTechnology={type === "technology"}
                    isSearch={type === "search"}
                    data={rowData}
                    handleOpenCompany={openProfile}
                    showEnrichRequestModal={showEnrichRequestModal}
                    user={user}
                    projectSlug={projectSlug}
                    project={project}
                    type={type}
                    onOpen={openProfile}
                    shouldShowMatchScore={false}
                    isWorkspace={true}
                />
            )}
            {enrichRequestModalShow && (
                <EnrichRequestModal
                    source={type === "company" ? "company" : "technology"}
                    credits={credits}
                    company={rowData}
                    dialogOpened={enrichRequestModalShow}
                    onChange={handleClosingEnrichDialog}
                    createProjectDialogOpened={handleCreateProjectDialogOpened}
                    enrichSuccess={handleEnrichSuccess}
                    cId={rowData && rowData.id}
                    technologySlug={rowData.slug}
                />
            )}
            {showOrderReportModal && (
                <OrderReportModal
                    enrichSuccess={handleEnrichSuccess}
                    technology={rowData}
                    user={user}
                    dialogOpened={showOrderReportModal}
                    onChange={() => setShowOrderReportModal(false)}
                    onNotEnoughCredits={handleEnoughCredits}
                    type={type}
                />
            )}
            {notEnoughCreditsModalShow && (
                <NotEnoughCreditsModal
                    credits={credits}
                    dialogOpened={notEnoughCreditsModalShow}
                    onChange={handleClosingNotEnoughCreditsDialog}
                    onTopUp={handleTopUp}
                />
            )}
            {showEnrichSuccess && (
                <OrderReceivedModal
                    dialogOpened={showEnrichSuccess}
                    onChange={handleClosingSuccess}
                />
            )}
            {topUpCreditsModalShow && (
                <TopUpCreditsModal
                    dialogOpened={topUpCreditsModalShow}
                    onBuy={handleBuyingTopUpCreditsDialog}
                    onChange={handleClosingTopUpCreditsDialog}
                />
            )}
            {showAreYouSureModal && (
                <AreYouSureModal
                    dialogOpened={showAreYouSureModal}
                    onChange={() => setShowAreYouSureModal(false)}
                    title={t("download.areYouSureTitle")}
                    subtitle={
                        <>
                            {t("download.subtitleFreeTrial1")}
                            <Typography
                                component="div"
                                className={classes.actionText}
                                variant="body"
                                color="secondaryDark">
                                {t("download.subtitleFreeTrial2")}
                            </Typography>
                        </>
                    }
                    actionButtonText={t("download.iWantToDownload")}
                    cancelButtonText={t("download.letsTryLater")}
                    onActionClick={download}
                />
            )}
            {showDownloadsExceededModal && (
                <AreYouSureModal
                    dialogOpened={showDownloadsExceededModal}
                    onChange={() => setShowDownloadsExceededModal(false)}
                    title={t("download.downloadsExceededTitle")}
                    subtitle={t("download.downloadsExceededSubtitle")}
                    actionButtonText={t("download.upgradePlan")}
                    cancelButtonText={t("download.upgradeLater")}
                    onActionClick={() =>
                        (window.location.href = getPricingPage(!!user))
                    }
                />
            )}
            {removeWarningModalOpened && (
                <RemoveWarningModal
                    projectSlug={rowData.slug}
                    project={project}
                    dialogOpened={removeWarningModalOpened}
                    onChange={() =>
                        dispatch(deleteProjectRequest({ slug: rowData.slug }))
                    }
                    onClose={() => setRemoveWarningModalOpened(false)}
                    isDeleteProject={true}
                    type={type}
                />
            )}
        </div>
    );
};
