import React, { useState } from "react";
// UI components
import Box from "@material-ui/core/Box";
import Link from "../../../components/Link/Link";
import WarningModal from "../../Results/components/modals/WarningModal/WarningModal";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "../style.js";
import { useAuthHook } from "../../../common/useAuthHook";
// Assets
import { ReactComponent as ArrowRight } from "../../../assets/icons/ArrowRight.svg";
import Logo from "../../../assets/icons/LogoNavbar.svg";

const NavBar = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated } = useAuthHook();
    const [showWarningModal, setShowWarningModal] = useState(false);
    const handleGoBack = () => {
        setShowWarningModal(!showWarningModal);
    };
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                className={classes.navigation}>
                <Link
                    onClick={handleGoBack}
                    href="#"
                    variant="body2"
                    rotateIcon
                    color="textSecondary"
                    className={classes.goBack}>
                    {t("getStarted.backCta")}
                    <ArrowRight />
                </Link>
                <img src={Logo} alt={t("companyName")} />
            </Box>
            <WarningModal
                dialogOpened={showWarningModal}
                onClose={() => setShowWarningModal(false)}
                backToSearchPage={!!isAuthenticated}
            />
        </>
    );
};

export default NavBar;
