const getProjectsStatusSelector = state => state.projects.getProjectsStatus;
const getProjectsSelector = state => state.projects.pool;
const getProjectSelector = state => state.projects.project;
const getProjectStatusSelector = state => state.projects.getProjectStatus;
const getToggleProjectsStatusSelector = state =>
    state.projects.postToggleProjectsStatus;
const getPostProjectsStatusSelector = state =>
    state.projects.postProjectsStatus;
const getNewProjectSelector = state => state.projects.newProject;
const getPostUpdateProjectsStatusSelector = state =>
    state.projects.postUpdateProjectsStatus;
const getAddItemToProjectStatusSelector = state =>
    state.projects.addItemToProjectStatus;
const getDeleteCompanyStatusSelector = state =>
    state.projects.postDeleteCompanyStatus;
const getEnrichCompanyStatusSelector = state =>
    state.projects.postEnrichCompanyStatus;
const getCheckProjectTitleStatusSelector = state =>
    state.projects.checkProjectTitleStatus;
const getCheckProjectTitleStateSelector = state =>
    state.projects.projectTitleStatus;
const getCheckProjectsStatusSelector = state =>
    state.projects.postCheckProjectsStatus;
const getCheckProjectsSelector = state => state.projects.projectsCheckState;
const getDeleteProjectStatusSelector = state =>
    state.projects.deleteProjectStatus;
const getPostProjectsErrorMessageSelector = state =>
    state.projects.postProjectsErrorMessage;
const getProjectCompaniesTeamSizeChartSelector = state =>
    state.projects.projectCompaniesTeamSizeChart;
const getProjectCompaniesChartSelector = state =>
    state.projects.projectCompaniesChart;
const getProjectCompaniesTeamSizeChartStatusSelector = state =>
    state.projects.getProjectCompaniesTeamSizeChartStatus;
const getProjectCompaniesChartStatusSelector = state =>
    state.projects.getProjectCompaniesChartStatus;
const getProjectCompaniesTeamSizeChartUnspecifiedAllSelector = state =>
    state.projects.unspecifiedAll;
const getProjectCompaniesFilterStats = state => state.projects.filterStats;
const getProjectCompaniesTotalCountSelector = state =>
    state.projects.totalCount;
const getFilterProjectCompaniesTotalPagesSelector = state =>
    state.projects.totalPages;

export {
    getProjectsStatusSelector,
    getProjectsSelector,
    getToggleProjectsStatusSelector,
    getPostProjectsStatusSelector,
    getPostUpdateProjectsStatusSelector,
    getAddItemToProjectStatusSelector,
    getProjectSelector,
    getDeleteCompanyStatusSelector,
    getEnrichCompanyStatusSelector,
    getCheckProjectTitleStatusSelector,
    getCheckProjectTitleStateSelector,
    getCheckProjectsSelector,
    getCheckProjectsStatusSelector,
    getNewProjectSelector,
    getDeleteProjectStatusSelector,
    getProjectStatusSelector,
    getPostProjectsErrorMessageSelector,
    getProjectCompaniesTeamSizeChartSelector,
    getProjectCompaniesChartSelector,
    getProjectCompaniesTeamSizeChartStatusSelector,
    getProjectCompaniesChartStatusSelector,
    getProjectCompaniesFilterStats,
    getProjectCompaniesTeamSizeChartUnspecifiedAllSelector,
    getProjectCompaniesTotalCountSelector,
    getFilterProjectCompaniesTotalPagesSelector,
};
