import { put, call } from "redux-saga/effects";
import { actions } from "../slice";

import { generateClusterData } from "./helpers";

function* getGenerateClusterData(data) {
    try {
        yield put(actions.getGenerateClusterDataStatusPanding());
        const response = yield call(generateClusterData, data);
        yield put(actions.setGenerateClusterData(response.data.cluster));
        yield put(actions.getGenerateClusterDataStatusSuccess());
    } catch (err) {
        yield put(actions.getGenerateClusterDataStatusFailure());
    }
}

export { getGenerateClusterData };
