import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) =>
    createStyles({
        dialogRoot: {
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: "left",
        },
        dialog: {
            padding: spacing(8),
            borderRadius: spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dialogFilter: {
            padding: spacing(3),
            background: palette.common.white,
            borderRadius: shape.borderRadius,
        },
        dialogScrollPaper: {
            maxWidth: 800,
        },
        dialogContent: {
            width: 369,
            overflow: "auto",
            marginTop: spacing(1),
            margin: "auto",
        },
        closeButton: {
            position: "absolute !important",
            right: spacing(4),
            top: spacing(3),
            color: palette.grey[500],
        },
        title: {
            marginTop: spacing(2),
            marginBottom: spacing(2),
            textAlign: "center",
        },
        subtitle: {
            color: palette.text.main,
            marginTop: spacing(2),
        },
        projectRow: {
            paddingBottom: spacing(1),
            borderBottom: "1px solid",
            borderBottomColor: palette.secondary.main,
            marginTop: spacing(3),
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        selectedProject: {
            color: palette.primary.main,
        },
        buttons: {
            marginTop: spacing(4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        requestIcon: {
            marginRight: spacing(1),
        },
        createButton: {
            marginRight: spacing(1),
            background: palette.primary.dark,
        },
        noCreatedProjects: {
            color: palette.grey.main,
        },
        cancelButton: {
            background: "transparent",
            width: "100%",
            height: spacing(5),
            marginTop: spacing(2),
            borderColor: palette.primary.dark,
            color: palette.primary.dark,
            "&:hover": {
                background: "transparent",
                borderColor: palette.primary.dark,
            },
        },
        footer: {
            marginTop: spacing(4),

            "& a": {
                marginLeft: spacing(1),
            },
        },
    }),
);
