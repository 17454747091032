import { put, call } from "redux-saga/effects";
import { actions } from "../slice";

import { inviteCoworkers } from "./helpers";

function* sendInviteCoworkers(emails) {
    try {
        yield put(actions.inviteSendingPending());
        const res = yield call(inviteCoworkers, emails);
        if (res.status_code === 403 || res.status_code === 500) {
            yield put(actions.inviteSendingFailure(res.message));
            yield put(actions.inviteSendingSetStatusCode(res.status_code));
        } else {
            yield put(actions.inviteSendingSuccess());
            yield put(actions.inviteSendingUndefined());
        }
    } catch (err) {
        const errorMsg =
            err.response && err.response.data ? err.response.data.message : "";
        yield put(actions.inviteSendingFailure(errorMsg));
    }
    // yield put(actions.inviteSendingUndefined());
}
export { sendInviteCoworkers };
