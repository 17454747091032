import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            padding: `${spacing(3)}px ${spacing(3)}px ${spacing(3)}px ${spacing(
                3,
            )}px`,
        },
        main: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
        },
        title: {
            marginBottom: spacing(3),
        },
        headerInput: {
            [breakpoint.down("md")]: {
                width: "100%",
            },
            "& input": {
                width: 288,

                [breakpoint.down("md")]: {
                    width: "100%",
                },
            },
            marginTop: spacing(2),
        },
        buttonsWrap: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        changeEmailText: {
            color: palette.grey.main,
            marginTop: spacing(2),
            marginBottom: spacing(2),
        },
        haveQuestionWrapText: {
            display: "flex",
            justifyContent: "center",
            marginTop: spacing(2),
        },
        haveQuestion: {},
        reachOut: {
            color: palette.primary.main,
            textDecoration: "underline",
            marginLeft: spacing(1),
            cursor: "pointer",
        },
        upgradeButton: {
            marginTop: spacing(3),
            width: 165,
        },
        continueButton: {
            marginTop: spacing(2),
            width: 210,
        },
        emailErrorText: {
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
        },
        invalidEmail: {
            "& div": {
                borderColor: palette.error.dark,
            },
        },
        errorText: {
            color: palette.error.dark,
        },
        talkToSalesWrap: {
            display: "flex",
            marginTop: spacing(1),
        },
        startTrialButton: {
            marginTop: spacing(2),
            marginBottom: spacing(1),
            fontSize: 13,
        },
        talkSales: {
            color: palette.primary.dark,
            marginLeft: 4,
            cursor: "pointer",
            zIndex: 3,
        },
    }),
);
