import React from "react";
// Hooks
import { useStyles } from "../style.js";
import { useTranslation } from "react-i18next";
//UI components
import Typography from "../../../components/Typography/Typography";
import { Link } from "react-router-dom";
// Assets
const DashboardCardSmall = ({ item, testId }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Link
            to={item.path}
            className={classes.dashboardCardSmall}
            test-id={testId}>
            <div className={classes.dashboardCardSmallHeader}>
                <img src={item.icon} />
            </div>
            <div className={classes.dashboardCardSmallBody}>
                <Typography variant="bodySmall" color="black">
                    {t(`dashboard.smallCards.${item.type}.title`)}
                </Typography>
                <Typography variant="subtitle1" color="secondaryDark">
                    <strong>
                        {item.data.firstValue}
                        {item.data.secondValue && item.data.secondValue < 101
                            ? "/" + item.data.secondValue
                            : ""}
                    </strong>
                </Typography>
                {item.timestamp && (
                    <Typography
                        variant="bodyXs"
                        color="textLight"
                        className={classes.dashboardCardSmallFooter}>
                        {item.timestamp &&
                            t(`dashboard.smallCards.${item.type}.footer`)}
                        {item.timestamp}
                    </Typography>
                )}
            </div>
        </Link>
    );
};
// Redux

// Constants

export default DashboardCardSmall;
