import React, { useState, useEffect, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import FormControl from "@material-ui/core/FormControl";
import Link from "../../components/Link/Link";
import Box from "@material-ui/core/Box";
import { UserProvider } from "../Layout/UserProvider";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
import { useHistory } from "react-router-dom";
import { useAppEventTracking } from "../../common/useAppEventTracking";
// Assets
import PumaLogo from "../../assets/icons/LoginPUMA.svg";
import LogoNavbar from "../../assets/icons/LogoNavbar.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/ArrowRight.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/EyeIconNew.svg";
import { ReactComponent as EyeIconActive } from "../../assets/icons/EyeIconNewActive.svg";
// Redux
import {
    postLoginRequest,
    handleInvitationRequest,
    getMeRequest,
} from "../../store/auth/requests";
import { actions as resultsActions } from "../../store/results/slice";
import { useSelector, useDispatch } from "react-redux";
import {
    getLoginStatusSelector,
    getLoginMessageSelector,
    getLogoutStatusSelector,
    getRegisterStatusSelector,
    getUserStatusSelector,
} from "../../store/auth/selectors";
// Constants
import {
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_PENDING,
    REQUEST_UNDEFINED,
} from "../../constants/statuses";

const SignIn = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { goBack, openPage } = useRouterHook();
    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const dispatch = useDispatch();
    const loginStatus = useSelector(getLoginStatusSelector);
    const getUserStatus = useSelector(getUserStatusSelector);
    const registerStatus = useSelector(getRegisterStatusSelector);
    const loginMessage = useSelector(getLoginMessageSelector);
    const logoutStatus = useSelector(getLogoutStatusSelector);
    const { isAuthenticated, user, getMeStatus } = useAuthHook();
    const isSharedResultsPageToken = localStorage.getItem(
        "isSharedResultsPageToken",
    );
    const { mixpanelTrackEvent, mixpanelSetUser } = useAppEventTracking();
    const validateEmail = email => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const { state: locationState, search } = useLocation();

    useEffect(() => {
        if (loginStatus === REQUEST_SUCCESS) {
            setTimeout(() => {
                dispatch(getMeRequest());
            }, 1000);
        }
    }, [loginStatus]);

    useEffect(() => {
        process.env.REACT_APP_ENV === "production" &&
            loginMessage &&
            reCaptchaRef.current.reset();
    }, [loginMessage]);

    const handleCaptchaChange = () => {
        dispatch(resultsActions.getSearchesStatusUndefined());
        const query = new URLSearchParams(search);
        const token = query.get("token");
        dispatch(postLoginRequest({ emailValue, passwordValue, token }));
    };

    const signIn = e => {
        e.preventDefault();
        if (process.env.REACT_APP_ENV === "production") {
            reCaptchaRef.current.execute();
        } else {
            handleCaptchaChange();
        }
    };

    const handlePasswordFieldTypeChange = () => {
        setPasswordFieldType(!passwordFieldType);
    };
    const [passwordFieldType, setPasswordFieldType] = useState(true);

    const handleSubscriptionAttempt = subscriptionAttempt => {
        if (subscriptionAttempt) {
            const base = window.location.origin + "/search-type?";
            const subscribe = `subscribe=${
                subscriptionAttempt.subscribe || ""
            }`;
            const monthly = `&monthly=${subscriptionAttempt.monthly || ""}`;
            const yearly = `&yearly=${subscriptionAttempt.yearly || ""}` || "";
            const currency = `&currency=${subscriptionAttempt.currency || ""}`;
            const url = base + subscribe + monthly + yearly + currency;

            window.location.replace(url);
        }
    };

    const history = useHistory();

    useEffect(() => {
        const query = new URLSearchParams(search);
        const subscribe = query.get("subscribe");
        const yearly = query.get("yearly");
        const monthly = query.get("monthly");
        const currency = query.get("currency") || "";
        const signInForFreeTrial = query.get("signInForFreeTrial");

        if (
            logoutStatus === REQUEST_UNDEFINED &&
            (loginStatus === REQUEST_SUCCESS ||
                registerStatus === REQUEST_SUCCESS) &&
            !!isAuthenticated
        ) {
            let mixpanelProps = {};
            if (
                history.location.state &&
                history.location.state.hasOwnProperty("mixpanelProps")
            ) {
                mixpanelProps = {
                    ...history.location.state.mixpanelProps,
                    "From url": history.location.state.from,
                };
            }
            if (process.env.REACT_APP_ENV !== "local" && user) {
                mixpanelSetUser(user);
            }
            mixpanelTrackEvent("Sign in", mixpanelProps);
            let isFromCompanyProfile;
            let fromCompanyUrl;
            const subscriptionAttempt = localStorage.getItem(
                "subscriptionAttempt",
            );

            if (subscriptionAttempt) {
                handleSubscriptionAttempt(JSON.parse(subscriptionAttempt));
            }

            if (locationState) {
                isFromCompanyProfile =
                    locationState && locationState.from.includes("companies/");
                fromCompanyUrl = locationState.cid
                    ? locationState.from + "?cid=" + locationState.cid
                    : locationState.from;
            }

            if (locationState && locationState.guestSignIn) {
                fromCompanyUrl = "/results";
            }
            if (!!signInForFreeTrial) {
                openPage("dashboard?startFreeTrial=true");
            } else {
                if (!!subscribe) {
                    openPage(
                        `/search-type?subscribe=${subscribe}&${
                            !!yearly ? `yearly=${yearly}` : `monthly=${monthly}`
                        }&currency=${currency}`,
                    );
                } else {
                    if (locationState && locationState.from) {
                        openPage(
                            isFromCompanyProfile
                                ? fromCompanyUrl
                                : locationState.from === "/" ||
                                  locationState.from === "/search-type" ||
                                  locationState.from === "/description" ||
                                  locationState.from === "/search-look-alike" ||
                                  locationState.from === "/search-patent"
                                ? "dashboard"
                                : locationState.from,
                        );
                    } else {
                        if (
                            isSharedResultsPageToken &&
                            isSharedResultsPageToken !== "undefined"
                        ) {
                            openPage(
                                `/share/search/${isSharedResultsPageToken}`,
                            );
                        } else {
                            openPage("search-type");
                        }
                    }
                }
            }
        }
    }, [
        logoutStatus,
        loginStatus,
        registerStatus,
        openPage,
        isAuthenticated,
        user,
    ]);

    const handleEmailChange = e => {
        setEmailValue(e.target.value);
        setEmailValid(validateEmail(e.target.value));
    };

    const handlePasswordChange = e => {
        setPasswordValue(e.target.value);
    };

    useEffect(() => {
        if (
            !!isAuthenticated &&
            (getMeStatus === REQUEST_SUCCESS ||
                getMeStatus === REQUEST_FAILURE) &&
            logoutStatus !== REQUEST_SUCCESS
        ) {
            const query = new URLSearchParams(search);
            const token = query.get("token");
            const signInForFreeTrial = query.get("signInForFreeTrial");
            if (token) {
                dispatch(handleInvitationRequest({ token: token }));
            }
            setTimeout(() => {
                if (!!signInForFreeTrial) {
                    openPage("dashboard?startFreeTrial=true");
                } else {
                    isSharedResultsPageToken &&
                    isSharedResultsPageToken !== "undefined"
                        ? openPage(`/share/search/${isSharedResultsPageToken}`)
                        : openPage("dashboard");
                }
            }, 200);
        }
    }, [isAuthenticated, getMeStatus, openPage]);
    const reCaptchaRef = createRef();

    return (
        <UserProvider>
            <Box display="flex" flexDirection="row" className={classes.root}>
                <div className={classes.firstColumWrapper}>
                    <div className={classes.illustrationWrapper}>
                        <img
                            src={PumaLogo}
                            className={classes.signInIllustration}
                            alt={t("companyName")}
                        />
                        <div className={classes.pumaText}>PUMA</div>
                        <div className={classes.pumaDescription}>
                            I'm the world's first AI model specialised in research and business strategy-related workflows.
                        </div>
                    </div>
                    <div className={classes.logoWrapper}>
                        <span>Powered by</span>
                        <img
                            src={LogoNavbar}
                            className={classes.logo}
                            alt={t("companyName")}
                        />
                    </div>
                </div>
                <div className={classes.formWrapperRoot}>
                    <Box
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        className={classes.formWrapper}>
                        <div className={classes.signUpTitleDesc}>
                            Sign-in to PUMA
                        </div>
                        <form onSubmit={signIn} className={classes.form}>
                            <div className={classes.formFieldWrapper}>
                                <div className={classes.field}>
                                    <label
                                        className={classes.inputLabel}
                                        for="email">
                                        Email
                                    </label>
                                    <input
                                        className={classnames([
                                            classes.input,
                                            {
                                                [classes.errorField]: !emailValid,
                                            },
                                        ])}
                                        id="email"
                                        onChange={handleEmailChange}
                                        value={emailValue}
                                    />
                                </div>
                                {!emailValid &&<div className={classes.errorMsg}>
                                    {t("signIn.emailError")}
                                </div>}
                            </div>
                            <div className={classes.formFieldWrapper}>
                                <div className={classes.field}>
                                    <label
                                        className={classes.inputLabel}
                                        for="password">
                                        Password
                                    </label>
                                    <input
                                        className={classes.input}
                                        id="password"
                                        type={
                                            passwordFieldType
                                                ? "password"
                                                : "text"
                                        }
                                        value={passwordValue}
                                        onChange={handlePasswordChange}
                                    />
                                    <div className={classes.showPasswordIcon}>
                                        {passwordFieldType ? (
                                            <EyeIcon
                                                className={classes.eye}
                                                onClick={
                                                    handlePasswordFieldTypeChange
                                                }
                                            />
                                        ) : (
                                            <EyeIconActive
                                                className={classes.eye}
                                                onClick={
                                                    handlePasswordFieldTypeChange
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                {/* {passwordValue && passwordValue.length < 8 && <div className={classes.fieldError}>
                                    {t("signUp.weakPassword",{count:8 -passwordValue.length})}
                                </div>} */}
                            </div>
                            <div className={classes.createAccountButton}>
                                {loginMessage && (
                                    <Typography
                                        component="div"
                                        className={classes.formError}
                                        variant="caption"
                                        color="error">
                                        {loginMessage}
                                    </Typography>
                                )}
                                <Button
                                    className={classes.signInBtn}
                                    variant="primaryDarkContained"
                                    disabled={!emailValid || !passwordValue}
                                    loading={
                                        loginStatus === REQUEST_PENDING ||
                                        loginStatus === REQUEST_SUCCESS ||
                                        getUserStatus === REQUEST_PENDING
                                    }>
                                    {t("signIn.signIn")}
                                </Button>
                            </div>
                            <Box
                                display="flex"
                                flexDirection="row"
                                className={classes.rememberMeForgotWrapper}>
                                <Link
                                    href="forgot-password"
                                    variant="body2"
                                    color="primary"
                                    className={classes.forgotPassword}>
                                    {t("signIn.forgotPassword")}
                                </Link>
                            </Box>
                            <div className={classes.signInCopy}>
                                <Link
                                    href="mailto:vik@valuer.ai?subject=Account request - login screen"
                                    target="_blank"
                                    variant="body2"
                                    className={[
                                        classes.signInCopyText,
                                        classes.signInCopyLink,
                                    ]}
                                    color="primary">
                                    {t("signIn.dontHaveAccount")}
                                </Link>
                            </div>
                            {/* <div className={classes.signInCopy}>
                                <Typography variant="body2" color="textSecondary" className={classes.signInCopyText} component="span">{t('signIn.dontHaveAccount')}</Typography>
                                <Link href="/sign-up" variant="body2" className={[classes.signInCopyText, classes.signInCopyLink]} color="primary">
                                    {t('signIn.signUpHere')}
                                </Link>
                            </div> */}
                            {/* <div>
                                <Typography variant="body2" color="textSecondary" className={classes.signInCopyText} component="span">{t('signIn.skipSignIn')}</Typography>
                                <Link href="/" variant="body2" className={[classes.signInCopyText, classes.signInCopyLink]} color="primary">
                                    {t('signUp.startSearch')}
                                </Link>
                            </div> */}
                            {process.env.REACT_APP_ENV === "production" && (
                                <FormControl>
                                    <ReCAPTCHA
                                        sitekey={
                                            process.env
                                                .REACT_APP_CAPTCHA_APP_KEY
                                        }
                                        size="invisible"
                                        badge="bottomleft"
                                        ref={reCaptchaRef}
                                        onChange={handleCaptchaChange}
                                    />
                                </FormControl>
                            )}
                        </form>
                    </Box>
                </div>
            </Box>
        </UserProvider>
    );
};

export default SignIn;
