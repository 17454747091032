import React from "react";

export const Hammer = ({ strokeColor = "#C2C9D7" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.6729 6.69364L16.0558 4.07655C15.3617 3.38225 14.4201 2.99219 13.4383 2.99219C12.4564 2.99219 11.5149 3.38225 10.8207 4.07655L9.64718 5.25004C9.51472 5.38223 9.47506 5.58126 9.54671 5.75413C9.61835 5.92701 9.78718 6.03962 9.97432 6.03937H10.7086C11.1994 6.03955 11.6701 6.23458 12.0172 6.58159L12.7835 7.34791V8.65746L14.7473 10.6203L15.7577 10.3201L16.7101 11.2715V12.5801L17.2724 13.1423C17.6849 13.5546 18.3535 13.5546 18.766 13.1423L21.198 10.7093C21.3965 10.5115 21.508 10.2427 21.508 9.9625C21.508 9.68225 21.3965 9.41353 21.198 9.21569L20.6368 8.65446H19.3272L18.3758 7.70206L18.6729 6.69364Z"
            stroke={strokeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.18862 20.3031V20.3031C3.65837 20.7727 4.30329 21.0241 4.9669 20.9963C5.63051 20.9686 6.25218 20.6642 6.68108 20.157L14.7474 10.6191L12.7836 8.65625L3.31568 16.8257C2.81597 17.257 2.51837 17.877 2.49426 18.5367C2.47016 19.1964 2.72173 19.8364 3.18862 20.3031Z"
            stroke={strokeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
