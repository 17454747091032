import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, typography, breakpoint }) => ({
    headerFiltersMain: {
        width: "100%",
        borderBottom: `1px solid ${palette.success.backgroundLight}`,
        marginBottom: "10px",
    },
    header: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        // borderBottom: `1px solid ${palette.success.medium}`,
        // marginBottom: "10px",
    },
    headerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "26px",
        // marginLeft: "20px",
    },
    tab: {
        width: "100%",

        background: `${palette.secondary.backgroundLight}`,
        color: `${palette.secondary.light}`,
        display: "flex",
        whiteSpace: "nowrap",
        justifyContent: "center",
        marginRight: "2px",
        padding: "3px 6px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        cursor: "pointer",
        [breakpoint.down("xl")]: {
            width: ({ user }) => (user?.csp ? "100%" : "120px"),
        },
        [breakpoint.down("md")]: {
            width: "100%",
        },
    },
    activeTab: {
        background: `${palette.secondary.backgroundDark} !important`,
        color: `${palette.secondary.dark} !important`,
        fontWeight: "bolder",
    },
}));
