import React, { useEffect, useState } from "react";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
//UI components
import Typography from "../Typography/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import Button from "../Button/Button.js";
//Assets
import CloseIconBlue from "../../assets/icons/CloseIconBlue.svg";

const AreYouSureModal = ({
    dialogOpened,
    onChange,
    title,
    subtitle,
    warningAction = false,
    onActionClick,
    actionButtonText,
    cancelButtonText,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleActionClick = e => {
        e.preventDefault();
        e.stopPropagation();
        onActionClick();
        onChange();
    };

    const closeModal = e => {
        e.preventDefault();
        e.stopPropagation();
        onChange();
    };

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    onChange(event, reason);
                }
            }}
            open={dialogOpened}>
            <IconButton
                className={classes.closeButton}
                onClick={e => closeModal(e)}>
                <img
                    src={CloseIconBlue}
                    alt={t("filterModal.closeX")}
                    className={classes.stepIcon}
                />
            </IconButton>
            <MuiDialogContent className={classes.dialogContent}>
                <div className={classes.flexColumn}>
                    <Typography
                        component="div"
                        className={classes.sureText}
                        variant="subtitle1"
                        color="black">
                        {title}
                    </Typography>
                    <Typography
                        component="div"
                        className={classes.actionText}
                        variant="body"
                        color="secondaryDark">
                        {subtitle}
                    </Typography>
                </div>
                <div className={classes.flexCenter}>
                    <Button
                        onClick={handleActionClick}
                        variant={warningAction ? "tertiary" : "primary"}
                        className={classes.actionButton}>
                        {actionButtonText}
                    </Button>
                    <Button
                        onClick={closeModal}
                        variant="tertiary"
                        className={classes.noButton}>
                        {cancelButtonText}
                    </Button>
                </div>
            </MuiDialogContent>
        </Dialog>
    );
};
export default AreYouSureModal;
