import { useState, createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { getUserSelector } from "../../../store/auth/selectors";
import {
    getProjectSelector,
    getProjectStatusSelector,
    getDeleteCompanyStatusSelector,
    getEnrichCompanyStatusSelector,
    getToggleProjectsStatusSelector,
} from "../../../store/projects/selectors";
import { toggleProjectRequest } from "../../../store/projects/requests";
//Hooks
import { useAuthHook } from "../../../common/useAuthHook";
import { useRouterHook } from "../../../common/useRouterHook";
//Common
import { Shared } from "../../../utils/shared";

export const useProjectProfileHook = ({ projects }) => {
    const { t } = useTranslation();
    let { slug } = useParams();
    const { isAuthenticated, userLoading, user } = useAuthHook();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const project = useSelector(getProjectSelector);
    const getUser = useSelector(getUserSelector);

    const [showActions, setShowActions] = useState(false);
    const [createProjectModalShow, setCreateProjectModalShow] = useState(false);
    const [projectActive, setProjectActive] = useState(project.active);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [allSectors, setAllSectors] = useState([]);
    const [loading, setLoading] = useState(true);
    const tagsWrapperRef = createRef(null);
    const projectStatusSelector = useSelector(getProjectStatusSelector);
    const deleteCompanyStatus = useSelector(getDeleteCompanyStatusSelector);
    const enrichCompanyStatus = useSelector(getEnrichCompanyStatusSelector);
    const toggleProjectsStatus = useSelector(getToggleProjectsStatusSelector);
    const [topUpCreditsModalShow, setTopUpCreditsModalShow] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [sessionCloseAlert, setSessionCloseAlert] = useState(false);
    const { getProjectAverageScore, userSwitchedFromTrialToFree } = Shared({
        getUser,
        project,
        projects,
    });

    useEffect(() => {
        if (userSwitchedFromTrialToFree()) {
            let s = sessionStorage.getItem("closedBannerFreeTrial");
            setSessionCloseAlert(s === "projectsBanner");
        } else {
            let s = sessionStorage.getItem("closedBannerRL");
            setSessionCloseAlert(s === "projectLimit");
        }
    }, [userSwitchedFromTrialToFree]);

    const handleOnClose = () => {
        if (userSwitchedFromTrialToFree()) {
            sessionStorage.setItem("closedBannerFreeTrial", "projectsBanner");
            let s = sessionStorage.getItem("closedBannerFreeTrial");
            setSessionCloseAlert(s === "projectsBanner");
        } else {
            sessionStorage.setItem("closedBannerRL", "projectLimit");
            let s = sessionStorage.getItem("closedBannerRL");
            setSessionCloseAlert(s === "projectLimit");
        }
    };

    const toggleProjectStatus = (e, projectSlug) => {
        e.preventDefault();
        e.stopPropagation();
        setProjectActive(!projectActive);
        dispatch(toggleProjectRequest({ projectSlug }));
        setShowActions(false);
    };
    const handleShowActions = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowActions(!showActions);
    };
    const editProject = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowActions(false);
        setCreateProjectModalShow(true);
    };
    const deleteProject = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowActions(false);
        setShowWarningModal(true);
    };
    const deactivateProject = (projectSlug, e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowActions(false);
        dispatch(toggleProjectRequest({ projectSlug }));
    };
    const handleClosingDialog = e => {
        e.preventDefault();
        e.stopPropagation();
        setCreateProjectModalShow(false);
    };
    const handleClosingWarningModal = () => {
        setShowWarningModal(false);
    };

    const openCreateProjectModal = () => {
        setCreateProjectModalShow(true);
    };
    const handleBuyingTopUpCreditsDialog = () => {
        setTopUpCreditsModalShow(false);
    };
    const handleShowTopUpModal = () => {
        setTopUpCreditsModalShow(true);
    };
    const handleClosingTopUpCreditsDialog = () => {
        setTopUpCreditsModalShow(false);
    };

    const isFreeUserOrGuest =
        !user ||
        (user &&
            user.subscription &&
            (user.subscription.name === "free" ||
                !user.subscription.is_active));

    return {
        isAuthenticated,
        userLoading,
        user,
        t,
        dispatch,
        tagsWrapperRef,
        slug,
        deleteCompanyStatus,
        enrichCompanyStatus,
        toggleProjectsStatus,
        projectStatusSelector,
        loading,
        setLoading,
        project,
        setAllSectors,
        showActions,
        projectActive,
        toggleProjectStatus,
        handleShowActions,
        editProject,
        deactivateProject,
        createProjectModalShow,
        handleClosingDialog,
        setProjectActive,
        setShowActions,
        deleteProject,
        showWarningModal,
        handleClosingWarningModal,
        allSectors,
        openPage,
        getProjectAverageScore,
        openCreateProjectModal,
        handleBuyingTopUpCreditsDialog,
        handleShowTopUpModal,
        handleClosingTopUpCreditsDialog,
        topUpCreditsModalShow,
        searchValue,
        setSearchValue,
        handleOnClose,
        isFreeUserOrGuest,
        userSwitchedFromTrialToFree,
    };
};
