import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        card: {
            borderRadius: spacing(2),
            background: palette.common.white,
            padding: spacing(2),
            border: `1px solid ${palette.text.lightest}`,
            [breakpoint.down("xl")]: {
                padding: ({ isSuggestedCompany }) => spacing(2),
            },
        },
    }),
);
