import { all, takeEvery } from "redux-saga/effects";
import {
    fetchDashboardRequest,
    markActivitySeenRequest,
    fetchActivitiesRequest,
} from "../requests";
import { fetchDashboard, markActivitySeen, fetchActivities } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchDashboard() {
    yield takeEvery(
        convertTypeFromAction(fetchDashboardRequest),
        fetchDashboard,
    );
}

function* watchMarkActivitySeen() {
    yield takeEvery(
        convertTypeFromAction(markActivitySeenRequest),
        ({ payload: { id } }) => markActivitySeen(id),
    );
}

function* watchGetActivities() {
    yield takeEvery(
        convertTypeFromAction(fetchActivitiesRequest),
        fetchActivities,
    );
}

function* saga() {
    yield all([
        watchFetchDashboard(),
        watchMarkActivitySeen(),
        watchGetActivities(),
    ]);
}

export { saga };
