import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Box from "@material-ui/core/Box";
import NavBar from "./components/NavBar";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useSectorsHook } from "./hooks/useSectorsHook";
// Redux
import { fetchSectorsRequest } from "../../store/sectors/requests";
//import { mixpanelTrackRequest } from "../../store/mixpanel/requests";

const IndustriesAdded = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const [animateOut, setAnimateOut] = useState(false);
    const [animateInBack, setAnimateInBack] = useState(false);
    const [animateOutBack, setAnimateOutBack] = useState(false);
    const { state: locationState, pathname } = useLocation();
    const history = useHistory();
    const { selectedSectors } = useSectorsHook();
    useEffect(() => {
        dispatch(fetchSectorsRequest());
    }, [dispatch]);

    const nextStepBtnClick = () => {
        //Make string representation of slected sectors and subsectors
        let selectedSectorString = "";
        if (selectedSectors.length) {
            for (let index in selectedSectors) {
                const sector = selectedSectors[index];
                selectedSectorString += sector.name;
                if (sector.subsectors && sector.subsectors.length) {
                    selectedSectorString += "(Subsectors: ";
                    for (let sub_index in sector.subsectors) {
                        const subSector = sector.subsectors[sub_index];
                        selectedSectorString += subSector.name + ", ";
                    }
                    selectedSectorString = selectedSectorString.slice(0, -2);
                    selectedSectorString += ")";
                }
                selectedSectorString += ", ";
            }
            selectedSectorString = selectedSectorString.slice(0, -2);
        }
        /* dispatch(mixpanelTrackRequest({
      eventName: 'Search companies - Next step', 
      data: {
        "Current Step": "Industry", 
        Button: "Next Step", 
        "User input": selectedSectorString
      }
    })); */
        setAnimateOut(true);
        setTimeout(() => {
            openPage("subsectors");
        }, 200);
    };

    const goToPreviousPage = () => {
        setAnimateOutBack(true);
        setTimeout(() => {
            // const hasSectors = selectedSectors.length;
            // const hasSubsectors = hasSectors && selectedSectors[0].subsectors;
            const path = "description";
            history.replace(path, { from: pathname, someOtherProp: "" });
        }, 200);
    };

    useEffect(() => {
        setAnimateInBack(
            locationState &&
                (locationState.from === "/subsectors" ||
                    locationState.from === "/summary"),
        );
    }, [locationState]);

    const handleScroll = e => {
        const currentScrollY = window.scrollY;
        e.deltaY < 0 &&
            e.movementY === 0 &&
            currentScrollY === 0 &&
            pathname === "/industries-added" &&
            goToPreviousPage();
    };

    useEffect(() => {
        window.addEventListener("wheel", handleScroll, { passive: false });
        return () => window.removeEventListener("wheel", handleScroll);
    }, []);

    return (
        <div
            className={classnames([
                {
                    [classes.animateOut]: animateOut,
                    [classes.animateInBack]: animateInBack,
                    [classes.animateOutBack]: animateOutBack,
                    [classes.animateIn]: !animateInBack,
                },
            ])}>
            <div className={classnames([classes.root, classes.roleRoot])}>
                <NavBar />
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classnames([
                        classes.wrapper,
                        classes.roleWrapper,
                    ])}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        className={classes.contentWrapper}>
                        <Typography
                            variant="subtitle1"
                            className={classes.focusTitle}
                            align="center">
                            {t("industriesAdded.title")}
                        </Typography>
                        <Typography
                            variant="body"
                            color="secondary"
                            className={classes.industriesAddedSubtitle}
                            align="center">
                            {t("industriesAdded.subtitle")}
                        </Typography>
                        <div>
                            <Button
                                variant="primary"
                                className={classes.continueIndustriesAddedCta}
                                onClick={() => openPage("summary")}>
                                {t("industry.continue")}
                            </Button>
                            <Button
                                variant="textPrimary"
                                className={classes.continueIndustriesAddedCta}
                                onClick={nextStepBtnClick}>
                                {t("industriesAdded.addSubsectors")}
                            </Button>
                        </div>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default IndustriesAdded;
