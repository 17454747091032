import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape, typography }) =>
    createStyles({
        dialogRoot: {
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        dialog: {
            padding: spacing(6),
            background: palette.common.white,
        },
        dialogFilter: {
            padding: spacing(3),
            background: palette.common.white,
            borderRadius: shape.borderRadius,
        },
        dialogScrollPaper: {
            maxWidth: 800,
        },
        dialogPaperScrollPaper: {
            borderRadius: spacing(2),
        },
        dialogContent: {
            marginTop: spacing(2),
            padding: 0,
        },
        closeButton: {
            position: "absolute !important",
            right: spacing(2),
            top: spacing(2),
            color: palette.grey[500],
        },
        projectTitle: {
            paddingTop: spacing(2),
        },
        projectTitleInput: {
            color: palette.secondary.main,

            "& input": {
                width: 430,
            },
        },
        projectDescription: {
            marginTop: spacing(3),
            marginBottom: spacing(1),
        },
        dialogTitleWrapper: {
            padding: "0!important",
            paddingLeft: spacing(3),
        },
        cancelButton: {
            marginRight: spacing(1),
        },
        tagsWrap: {
            padding: 12,
            border: `1px solid ${palette.text.lighter}`,
            borderRadius: spacing(1),
            marginBottom: spacing(2),
        },
        tagsClass: {
            backgroundColor: palette.primary.backgroundLight,
            borderRadius: spacing(1),
            padding: "4px 4px 4px 12px",
            marginRight: spacing(3),
            color: palette.text.main,
            position: "relative",
            ...typography.label,
        },
        tagInputFieldClass: {
            backgroundColor: palette.common.white,
            marginTop: spacing(1),
            borderColor: "transparent",
            width: "100%",
            flexGrow: 1,
            border: "none",
            outline: 0,
            boxShadow: "none",
            "-webkit-appearance": "none",
        },
        removeClass: {
            backgroundColor: palette.primary.backgroundLight,
            border: "none",
            cursor: "pointer",
        },
        numTag: {
            fontSize: 11,
        },
        inviteTitle: {
            marginBottom: spacing(3),
        },
        inviteSubtitle: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        errorInvite: {
            minHeight: spacing(2),
        },
        errorInviteMsg: {
            color: palette.error.main,
        },
        removeComponent: {
            marginLeft: 4,
        },
        usersCount: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        inviteCtaIcon: {
            marginLeft: spacing(1),
        },
        inviteCtas: {
            marginTop: spacing(4),
        },
        divider: {
            width: "100%",
            height: "1px",
            background: palette.grey[300],
            margin: `${spacing(4)}px 0px`,
        },
        shareText: {
            margin: "auto 0",
        },
        tooltipCopy: {
            backgroundColor: palette.secondary.dark,
            boxShadow:
                "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
            borderRadius: spacing(1),
            color: palette.common.white,
            right: spacing(2),
            bottom: spacing(2),
            position: "absolute",
            height: 30,
            width: 220,
            padding: spacing(1),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
        },
        checkImg: {
            marginRight: spacing(1),
        },
        copyButton: {
            marginTop: spacing(4),
        },
    }),
);
