import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    getInviteStatus: REQUEST_UNDEFINED,
    getInviteFailureMessage: "",
    statusCode: null,
};

export const REDUCER_PREFIX = "invites";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        inviteSendingPending: state => {
            state.getInviteStatus = REQUEST_PENDING;
        },
        inviteSendingSuccess: state => {
            state.getInviteStatus = REQUEST_SUCCESS;
        },
        inviteSendingFailure: (state, { payload }) => {
            state.getInviteStatus = REQUEST_FAILURE;
            state.getInviteFailureMessage = payload;
        },
        inviteSendingUndefined: state => {
            state.getInviteStatus = REQUEST_UNDEFINED;
        },
        inviteSendingSetStatusCode: (state, { payload }) => {
            state.statusCode = payload;
        },
    },
});
export { reducer, actions };
