import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing, breakpoint }) => ({
    root: {
        width: "100%",
        backgroundColor: palette.success.backgroundLight,
        borderRadius: spacing(3),
        padding: `${spacing(4)}px ${spacing(4)}px ${spacing(2)}px ${spacing(
            4,
        )}px`,
        marginTop: spacing(2),
    },
    titleInfoWrap: {
        display: "flex",
        alignItems: "center",
    },
    infoButton: {
        backgroundColor: palette.success.backgroundDark,
        width: spacing(3),
        height: spacing(3),
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: palette.text.dark,
        marginLeft: spacing(2),
        cursor: "pointer",
    },
    cards: {
        marginTop: spacing(3),
        display: "flex",
        flexWrap: "wrap",
    },
    marginTopBottom: {
        margin: `${spacing(3)}px 0`,
    },
    mt24: {
        marginTop: spacing(3),
    },
    beta: {
        fontSize: 14,
        lineHeight: "17px",
        color: palette.common.white,
        textTransform: "uppercase",
        padding: "4px 17px",
        background: palette.primary.dark,
        borderRadius: spacing(1),
        marginLeft: spacing(2),
    },
    similarCompaniesTooltipTitle: {
        display: "flex",
        alignItems: "baseline",
    },
    ourChatLink: {
        textDecoration: "underline",
        color: palette.success.dark,
        cursor: "pointer",
    },
    noCompanies: {
        padding: `${spacing(5)}px ${spacing(4)}px`,
        paddingTop: 0,
        width: "50%",
        background: palette.success.backgroundLight,
        borderRadius: "24px",
        marginTop: spacing(3),
        marginBottom: spacing(3),
    },
    oopsLabel: {
        marginBottom: spacing(2),
    },
    description2: {
        marginTop: spacing(3),
    },

    // stepper card
    slideWrap: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    slideTitle: {
        marginTop: spacing(3),
        minHeight: 64,
        display: "flex",
        alignItems: "center",
    },
    slideDescription: {
        marginTop: spacing(3),
        minHeight: 110,
    },
    nextBtnWrapper: {
        marginLeft: spacing(6),
        width: 87,

        "& a": {
            marginTop: "10px",
        },

        [breakpoint.down("sm")]: {
            width: "auto",
            marginLeft: 0,
            marginTop: spacing(2),
        },
    },
    backBtnWrapper: {
        marginRight: spacing(6),
        width: 105,

        "& a": {
            marginTop: "10px",
            float: "right",
        },

        [breakpoint.down("md")]: {
            width: "auto",
            marginRight: 0,
            marginTop: spacing(3),
        },
    },
    dotsWrapp: {
        display: "flex",
        alignItems: "center",
        height: 40,
        [breakpoint.down("sm")]: {
            marginTop: spacing(2),
        },
    },
    dot: {
        width: 6,
        height: 6,
        background: palette.success.light,
        borderRadius: "50%",
        marginLeft: 6,
    },
    activeDot: {
        width: 8,
        height: 8,
        background: palette.success.dark,
    },
    stepper: {
        display: "flex",
        justifyContent: "center",
        height: "50px",
        marginTop: spacing(2),
        marginRight: "5%",

        [breakpoint.down("md")]: {
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginTop: "-5px",

            "&>*": {
                margin: "auto 0",
            },
        },
    },
    arrowNext: {
        marginRight: "0 !important",
    },
    arrowBack: {
        marginRight: "0 !important",
    },
    buttonBack: {
        padding: "12px 20px 12px 16px",
    },
    buttonNext: {
        padding: "12px 16px 12px 20px",
    },
    averageDataTooltip: {
        background: `${palette.common.white} !important`,
        color: `${palette.text.main} !important`,
    },
    cursorPointer: {
        "&:hover": {
            cursor: "pointer",
        },
    },
}));
