import React, { useEffect, useState, createRef } from "react";
import classnames from "classnames";
import { useLocation, useHistory } from "react-router-dom";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Box from "@material-ui/core/Box";
import NavBar from "./components/NavBar";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Grow from "@material-ui/core/Grow";
import SubsectorsDialog from "./components/SubsectorsDialog";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useSectorsHook } from "./hooks/useSectorsHook";
import { useDispatch } from "react-redux";
// Assets
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";
import PageUpIcon from "../../assets/icons/PageUpIcon.svg";
import PageDownIcon from "../../assets/icons/PageDownIcon.svg";
// Redux
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";

const Subsectors = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const { state: locationState, pathname } = useLocation();
    const history = useHistory();
    const sectorsRef = createRef(null);
    // const [animateOut, setAnimateOut] = useState(false);
    // const [animateInBack, setAnimateInBack] = useState(false);
    // const [animateOutBack, setAnimateOutBack] = useState(false);
    const {
        selectedSearchSector,
        sector,
        newSubsector,
        handleSubsectorSelect,
        handleAddSubsector,
        handleSubsectorRemove,
        handleAddNewSubsector,
        openSubsectorsDialog,
        handleToggleDialog,
        dialogOpened,
        selectedSectors,
    } = useSectorsHook();

    const [backDialogOpened, setBackDialogOpened] = useState(false);

    useEffect(() => {
        dispatch(mixpanelTrackRequest({ eventName: "Select sub-sectors" }));
    }, [dispatch]);

    const handleToggleBackDialog = () => {
        setBackDialogOpened(!backDialogOpened);
    };

    const handleBackToValuer = () => {
        handleToggleBackDialog();
    };

    const openSummary = () => {
        // setAnimateInBack(false);
        // setAnimateOut(true);
        setTimeout(() => {
            openPage("summary");
        }, 200);
    };

    const goToIndustryPage = () => {
        // setAnimateOutBack(true);
        setTimeout(() => {
            history.replace("/industry", { from: pathname, someOtherProp: "" });
        }, 200);
    };

    // const handleScroll = e => {
    //   setTimeout(() => {
    //     const currentScrollY = window.scrollY;
    //     e.deltaY < 0 && e.movementY === 0 && currentScrollY === 0 && pathname === "/subsectors" && goToIndustryPage();
    //   }, 500)
    // }

    // useEffect(() => {
    //   !dialogOpened ? document.body.addEventListener("wheel", handleScroll, { passive: false }) : document.body.removeEventListener("wheel", handleScroll);
    //   return () => document.body.removeEventListener("wheel", handleScroll);
    // }, [dialogOpened]);

    useEffect(() => {
        sectorsRef.current.addEventListener("wheel", e => e.stopPropagation());
    }, [dialogOpened]);

    // useEffect(() => {
    //   setAnimateInBack(locationState && locationState.from === "/summary")
    // }, [locationState]);

    return (
        <div>
            <div className={classnames([classes.root, classes.roleRoot])}>
                <NavBar goBack={handleBackToValuer} />
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classnames([
                        classes.wrapper,
                        classes.roleWrapper,
                    ])}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        className={classnames([
                            classes.contentWrapper,
                            classes.subsectorsContentWrapper,
                        ])}>
                        <Typography
                            variant="subtitle1"
                            color="black"
                            className={classes.focusTitle}>
                            {t("subsectors.title")}
                        </Typography>
                        <Typography
                            className={classes.subsectorsSubtitle}
                            variant="body"
                            color="text">
                            {t("subsectors.subtitle")}
                        </Typography>
                        <div className={classes.relative}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="start"
                                className={classes.subsectors}
                                ref={sectorsRef}>
                                {selectedSectors.map(sector => (
                                    <Box
                                        className={classes.subsectorSection}
                                        key={`selected-sector-${sector.slug}`}>
                                        <div
                                            className={
                                                classes.subsectorSectionIndustry
                                            }>
                                            <Typography
                                                variant="bodyButton"
                                                color="primaryDark"
                                                component="div"
                                                className={
                                                    classes.industryLabel
                                                }>
                                                {t("subsectors.industry")}
                                            </Typography>
                                            <Button
                                                variant="pill"
                                                className={classnames([
                                                    classes.sector,
                                                    classes.selectedSubsector,
                                                ])}
                                                disabled>
                                                {sector.name}
                                            </Button>
                                            <Button
                                                variant="textPrimary"
                                                className={
                                                    classes.subsectorsEditLabel
                                                }
                                                onClick={() =>
                                                    openSubsectorsDialog(sector)
                                                }>
                                                {t("subsectors.edit")}
                                            </Button>
                                        </div>
                                        <div
                                            className={
                                                classes.subsectorSectionIndustry
                                            }>
                                            <Typography
                                                variant="bodyButton"
                                                color="primaryDark"
                                                component="div"
                                                className={
                                                    classes.subsectorsLabel
                                                }>
                                                {t("subsectors.subsectors")}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                flexWrap="wrap">
                                                {sector &&
                                                    sector.subsectors &&
                                                    sector.subsectors.length >
                                                        0 && (
                                                        <>
                                                            <div
                                                                className={
                                                                    classes.subsectorsWrapper
                                                                }>
                                                                {sector.subsectors.map(
                                                                    selectedSubsector => (
                                                                        <Button
                                                                            variant="pillAdd"
                                                                            key={
                                                                                selectedSubsector.slug
                                                                            }
                                                                            className={classnames(
                                                                                [
                                                                                    classes.sector,
                                                                                    classes.selectedSubsector,
                                                                                ],
                                                                            )}
                                                                            deactivateButton={() =>
                                                                                handleSubsectorRemove(
                                                                                    sector.slug,
                                                                                    selectedSubsector,
                                                                                )
                                                                            }
                                                                            disabled>
                                                                            {
                                                                                selectedSubsector.name
                                                                            }
                                                                        </Button>
                                                                    ),
                                                                )}
                                                            </div>
                                                        </>
                                                    )}

                                                {(!sector.subsectors ||
                                                    (sector.subsectors &&
                                                        sector.subsectors
                                                            .length === 0)) && (
                                                    <Button
                                                        variant="pillAdd"
                                                        className={classnames([
                                                            classes.sector,
                                                            classes.selectedSubsector,
                                                        ])}
                                                        onClick={() =>
                                                            openSubsectorsDialog(
                                                                sector,
                                                            )
                                                        }>
                                                        <Plus
                                                            className={
                                                                classes.previousIcon
                                                            }
                                                        />
                                                        {t(
                                                            "focus.addSubsector",
                                                        )}
                                                    </Button>
                                                )}
                                            </Box>
                                        </div>
                                    </Box>
                                ))}
                            </Box>
                            <div className={classes.bgGradient}></div>
                        </div>
                        <div className={classes.subsectorsButtons}>
                            <Button
                                variant="primary"
                                className={classes.continueSubsectorsCta}
                                onClick={openSummary}>
                                {t("industry.continue")}
                            </Button>
                            <Button
                                variant="tertiary"
                                className={classes.skipSubsectorsCta}
                                onClick={openSummary}>
                                {t("industry.skip")}
                            </Button>
                        </div>
                    </Box>
                </Box>
                {/* Subindustries dialog */}
                <SubsectorsDialog
                    classes={classes}
                    selectedSearchSector={selectedSearchSector}
                    sector={sector}
                    newSubsector={newSubsector}
                    handleSubsectorSelect={handleSubsectorSelect}
                    handleAddSubsector={handleAddSubsector}
                    handleSubsectorRemove={handleSubsectorRemove}
                    handleAddNewSubsector={handleAddNewSubsector}
                    handleToggleDialog={handleToggleDialog}
                    dialogOpened={dialogOpened}
                />
                {/* Going back to Valuer website dialog */}
                <Dialog
                    maxWidth="lg"
                    TransitionComponent={Grow}
                    classes={{
                        root: classes.dialogRoot,
                        paper: classes.dialog,
                        scrollPaper: classes.dialogScrollPaper,
                    }}
                    onClose={(event, reason) => {
                        if (reason === "backdropClick") {
                            handleToggleBackDialog();
                        }
                    }}
                    open={backDialogOpened}>
                    <MuiDialogTitle
                        disableTypography
                        className={classes.dialogTitleWrapper}>
                        <Typography variant="subtitle1" color="secondary">
                            {t("summary.areYouSure")}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className={classnames([
                                classes.greyText,
                                classes.backDialogSubtitle,
                            ])}>
                            {t("summary.progressWillNotBeSaved")}
                        </Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent className={classes.dialogContent}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            className={classes.saveAndExit}
                            justifyContent="center">
                            <Button
                                variant="tertiary"
                                disabled={false}
                                size="large"
                                onClick={handleToggleBackDialog}
                                className={classes.backDialogCancel}>
                                {t("summary.backDialogCancel")}
                            </Button>
                            <Button
                                variant="primary"
                                disabled={false}
                                size="large"
                                error
                                onClick={() =>
                                    (window.location.href =
                                        "https://www.valuer.ai/")
                                }>
                                {t("summary.backDialogConfirm")}
                            </Button>
                        </Box>
                    </MuiDialogContent>
                </Dialog>
            </div>
            {/*<div className={classes.arrowsUpDown}>*/}
            {/*  <div className={classes.goUpBtn} onClick={goToIndustryPage}>*/}
            {/*    <img src={PageUpIcon} alt={t("companyName")} />*/}
            {/*  </div>*/}
            {/*  <div className={classes.goUpBtn} onClick={openSummary}>*/}
            {/*    <img src={PageDownIcon} alt={t("companyName")} />*/}
            {/*  </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default Subsectors;
