import React from "react";
// Hooks
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core";
import { useFreeTrialHook } from "../../../Layout/hooks/useFreeTrialHook";
//assets
import CloseButton from "../../../../assets/icons/CloseIcon.svg";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
// Components
import Modal from "../../../../components/Modal/Modal";

const ConfirmLeaveTheTeamModal = ({
    onClose,
    dialogOpened,
    user,
    onConfirm,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const { talkToSales } = useFreeTrialHook({ user: user });
    return (
        <Modal
            background={theme.palette.primary.backgroundMedium}
            dialogOpened={dialogOpened}
            onClose={onClose}
            borderRadius="16px"
            closeButtonIcon={CloseButton}
            maxWidth="593px"
            persistent>
            <div className={classes.root}>
                <div className={classes.main}>
                    <Typography
                        className={classes.title}
                        variant="subtitle1"
                        color="black">
                        {user && user.team && user.team.role === "owner"
                            ? t("profileSettings.leaveThisTeamOwnerModalTitle")
                            : t("profileSettings.leaveThisTeamModalTitle")}
                    </Typography>
                    {user && user.team && user.team.role !== "owner" ? (
                        <div className={classes.buttonsWrap}>
                            <Button
                                onClick={onConfirm}
                                className={classes.upgradeButton}
                                variant="primaryDarkContained">
                                {t("subscribeConfirmModal.confirm")}
                            </Button>
                            <Button
                                onClick={onClose}
                                className={classes.continueButton}
                                variant="quaternary">
                                {t("inviteUsersModal.cancel")}
                            </Button>
                        </div>
                    ) : (
                        <div className={classes.buttonsWrap}>
                            <Button
                                onClick={() => talkToSales()}
                                className={classes.upgradeButton}
                                variant="primaryDarkContained">
                                {t("freeTrial.talkToSales")}
                            </Button>
                            <Button
                                onClick={onClose}
                                className={classes.continueButton}
                                variant="quaternary">
                                {t("inviteUsersModal.cancel")}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
export default ConfirmLeaveTheTeamModal;
