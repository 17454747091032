import React, { useState, useEffect } from "react";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import FormControl from "@material-ui/core/FormControl";
import Link from "../../components/Link/Link";
import Box from "@material-ui/core/Box";
import { UserProvider } from "../Layout/UserProvider";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
// Hooks
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
// Assets
import SignUpIllustration from "../../assets/icons/SignInManIllustration.svg";
import LogoNavbar from "../../assets/icons/LogoNavbar.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/ArrowRight.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/EyeIconNew.svg";
import { ReactComponent as EyeIconActive } from "../../assets/icons/EyeIconNewActive.svg";
// Redux
import {
    resetPasswordRequest,
    checkResetLinkRequest,
} from "../../store/auth/requests";
import { useSelector, useDispatch } from "react-redux";
import {
    getResetPasswordStatusSelector,
    getResetPasswordMessageSelector,
    getCheckResetLinkMessageSelector,
    getCheckResetLinkStatusSelector,
} from "../../store/auth/selectors";
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
// Constants
import { REQUEST_SUCCESS } from "../../constants/statuses";

const ResetPassword = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    let { search } = useLocation();
    const { goBack, openPage } = useRouterHook();
    const [passwordValue, setPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
    const dispatch = useDispatch();
    const resetPasswordStatus = useSelector(getResetPasswordStatusSelector);
    const resetPasswordMessage = useSelector(getResetPasswordMessageSelector);
    const checkResetLinkMessage = useSelector(getCheckResetLinkMessageSelector);
    const checkResetLinkStatus = useSelector(getCheckResetLinkStatusSelector);
    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");
    const [passwordFieldType, setPasswordFieldType] = useState(true);
    const [passwordConfirmFieldType, setPasswordConfirmFieldType] =
        useState(true);

    useEffect(() => {
        let emailStart = search.lastIndexOf("&email=") + 7;
        let tokenEnd = search.lastIndexOf("&email=");
        const emailString = search.substring(emailStart, search.length);
        const email = emailString.replace("%40", "@");
        setToken(search.substring(7, tokenEnd));
        setEmail(email);
        dispatch(checkResetLinkRequest({ data: { email } }));
    }, [search, dispatch]);

    useEffect(() => {
        checkResetLinkStatus === REQUEST_SUCCESS &&
            checkResetLinkMessage &&
            !checkResetLinkMessage.tokenExists &&
            openPage("sign-up");
    }, [checkResetLinkStatus, checkResetLinkMessage, openPage]);

    const resetPassword = e => {
        e.preventDefault();
        dispatch(
            resetPasswordRequest({
                data: {
                    email,
                    token,
                    password: passwordValue,
                    password_confirmation: confirmPasswordValue,
                },
            }),
        );
    };

    const handlePasswordChange = e => {
        setPasswordValue(e.target.value);
    };

    const handleConfirmPasswordChange = e => {
        setConfirmPasswordValue(e.target.value);
    };

    const handlePasswordFieldTypeChange = () => {
        setPasswordFieldType(!passwordFieldType);
    };
    const handlePasswordConfirmFieldTypeChange = () => {
        setPasswordConfirmFieldType(!passwordConfirmFieldType);
    };

    useEffect(() => {
        dispatch(mixpanelTrackRequest({ eventName: "Reset password" }));
    }, [dispatch]);

    return (
        <UserProvider>
            <Box display="flex" flexDirection="row" className={classes.root}>
                <Link
                    href="#"
                    variant="body2"
                    rotateIcon
                    color="textSecondary"
                    onClick={goBack}
                    className={classes.goBack}>
                    {t("signUp.goBack")}
                    <ArrowRight />
                </Link>
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    className={classes.illustrationWrapper}>
                    <div className={classes.logoWrapper}>
                        <img
                            src={LogoNavbar}
                            className={classes.logo}
                            alt={t("companyName")}
                        />
                    </div>
                    <img
                        src={SignUpIllustration}
                        className={classes.signInIllustration}
                        alt={t("signUp.illustrationTitle")}
                    />
                </Box>
                <div className={classes.formWrapperRoot}>
                    <Box
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        className={classes.formWrapper}>
                        <Typography
                            className={classes.signUpTitle}
                            variant="h4">
                            <strong>{t("resetPassword.title")}</strong>
                        </Typography>
                        <form onSubmit={resetPassword} className={classes.form}>
                            <FormControl className={classes.inputWrapper}>
                                <InputLabel
                                    required={true}
                                    classes={{
                                        root: classes.inputLabel,
                                        shrink: classes.labelShrinked,
                                        focused: classes.focusedLabel,
                                    }}
                                    htmlFor="password">
                                    {t("resetPassword.passwordLabel")}
                                </InputLabel>
                                <Input
                                    InputLabelProps={{ required: true }}
                                    inputProps={{ fullWidth: true }}
                                    placeholder={t(
                                        "resetPassword.passwordPlaceholder",
                                    )}
                                    id="password"
                                    endAdornment={
                                        passwordFieldType ? (
                                            <EyeIcon
                                                className={classes.eye}
                                                onClick={
                                                    handlePasswordFieldTypeChange
                                                }
                                            />
                                        ) : (
                                            <EyeIconActive
                                                className={classes.eye}
                                                onClick={
                                                    handlePasswordFieldTypeChange
                                                }
                                            />
                                        )
                                    }
                                    type={
                                        passwordFieldType ? "password" : "text"
                                    }
                                    value={passwordValue}
                                    onChange={handlePasswordChange}
                                    error={
                                        passwordValue &&
                                        passwordValue.length < 8
                                    }
                                    classes={{
                                        underline: classes.input,
                                    }}
                                    errorLabel={t("signUp.weakPassword", {
                                        count: 8 - passwordValue.length,
                                    })}
                                />
                            </FormControl>
                            <FormControl className={classes.inputWrapper}>
                                <InputLabel
                                    required={true}
                                    classes={{
                                        root: classes.inputLabel,
                                        shrink: classes.labelShrinked,
                                        focused: classes.focusedLabel,
                                    }}
                                    htmlFor="confirm-password">
                                    {t("resetPassword.passwordConfirmLabel")}
                                </InputLabel>
                                <Input
                                    inputProps={{ fullWidth: true }}
                                    placeholder={t(
                                        "resetPassword.passwordConfirmPlaceholder",
                                    )}
                                    id="confirm-password"
                                    endAdornment={
                                        passwordConfirmFieldType ? (
                                            <EyeIcon
                                                className={classes.eye}
                                                onClick={
                                                    handlePasswordConfirmFieldTypeChange
                                                }
                                            />
                                        ) : (
                                            <EyeIconActive
                                                className={classes.eye}
                                                onClick={
                                                    handlePasswordConfirmFieldTypeChange
                                                }
                                            />
                                        )
                                    }
                                    type={
                                        passwordConfirmFieldType
                                            ? "password"
                                            : "text"
                                    }
                                    value={confirmPasswordValue}
                                    onChange={handleConfirmPasswordChange}
                                    error={
                                        confirmPasswordValue &&
                                        passwordValue !== confirmPasswordValue
                                    }
                                    classes={{
                                        underline: classes.input,
                                    }}
                                    errorLabel={t("signUp.passwordMustMatch")}
                                />
                            </FormControl>
                            <div className={classes.createAccountButton}>
                                {resetPasswordMessage && (
                                    <Typography
                                        component="div"
                                        className={classes.formError}
                                        variant="caption"
                                        color={
                                            resetPasswordStatus ===
                                            REQUEST_SUCCESS
                                                ? "primary"
                                                : "error"
                                        }>
                                        {resetPasswordMessage}
                                    </Typography>
                                )}
                                {resetPasswordStatus === REQUEST_SUCCESS && (
                                    <Link
                                        className={classes.signInButton}
                                        align="center"
                                        variant="body2"
                                        color="primary"
                                        onClick={() => openPage("/sign-in")}>
                                        {t("resetPassword.signIn")}
                                    </Link>
                                )}
                                <Button
                                    variant="primary"
                                    disabled={
                                        passwordValue.length < 8 ||
                                        passwordValue !== confirmPasswordValue
                                    }>
                                    {t("resetPassword.cta")}
                                </Button>
                            </div>
                        </form>
                    </Box>
                </div>
            </Box>
        </UserProvider>
    );
};

export default ResetPassword;
