import React, { useState, useEffect } from "react";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import { Box } from "@material-ui/core";
import Typography from "../Typography/Typography";
import classnames from "classnames";
import usePrevious from "../../common/usePrevious";
import { useAppEventTracking } from "../../common/useAppEventTracking";
import { createCompanyData } from "../../utils/companyUtils.js";
//Components
import TableHead from "../../pages/Results/components/ListView/TableHead";
import Header from "../../pages/Results/components/Header";
import { WorkspaceTableRow } from "./WorkspaceTableRow";
import { ReactComponent as ListViewIcon } from "../../assets/icons/ListViewIcon.svg";
import { ReactComponent as CardViewIcon } from "../../assets/icons/CardViewIcon.svg";
//Redux
import { useAuthHook } from "../../common/useAuthHook";
import TableToolbar from "../../pages/Results/components/ListView/TableToolbar";
import TableToolbarRight from "../../pages/Results/components/ListView/TableToolbarRight";
import { useRouterHook } from "../../common/useRouterHook";

export const WorkspaceListView = ({
    items,
    activeView,
    setActiveView,
    total,
    page,
    totalPages,
    projectSlug,
    project
}) => {
    const { isAuthenticated, user } = useAuthHook();
    const classes = useStyles();
    const prevActiveView = usePrevious(activeView);
    const { t } = useTranslation();
    const [activeResultView, setActiveResultView] = useState(activeView);
    const { mixpanelTrackEvent } = useAppEventTracking();
    const { openPage } = useRouterHook();
    useEffect(() => {
        prevActiveView !== activeView && setActiveResultView(activeView);
    }, [activeView]);

    const hanldeViewIconClick = function () {
        if (activeView === "list") {
            setActiveView("card");
            mixpanelTrackEvent("Companies view switched", { Type: "card" });
        } else if (activeView === "card") {
            setActiveView("list");
            mixpanelTrackEvent("Companies view switched", { Type: "list" });
        }
    };

    const itemsResults =
        items &&
        items.map(item => {
            if (item.type === "company") {
                return createCompanyData(
                    item.name,
                    item.description,
                    item.total_funding_amount_dollars,
                    item.founded_date,
                    item.number_of_employees,
                    item.investment_stage,
                    item.hq_country,
                    (item.degree_of_fit * 100).toFixed(),
                    item.slug,
                    item.id,
                    item.enrichment_request,
                    item.ready_for_release,
                    item.website,
                    item.deck,
                    item.sectors,
                    item.comments_cnt,
                    item.subsectors,
                    item.projects,
                );
            } else return item;
        });

    return (
        <Paper
            sx={{ width: "100%", mb: 2, px: 2 }}
            className={classes.tablePaper}>
            <Box display="flex">
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="baseline"
                    className={classes.titleWrapper}>
                    <Typography gutterBottom variant="subtitle1" align="center">
                        {t("workspace.listView.title")}
                        <Typography
                            variant="bodySmall"
                            className={classes.resultsTotal}
                            align="center"
                            data-intercom-target="Number of results label">
                            {t("resultsHeader.showingResultsPaginationLabel", {
                                count: total,
                                page,
                                totalPages,
                            })}
                        </Typography>
                    </Typography>
                    <div className={classes.viewSwitcherWrapper}>
                        <ListViewIcon
                            data-intercom-target="List view icon"
                            className={classnames([
                                {
                                    [classes.inactiveViewIcon]:
                                        activeResultView !== "list",
                                    [classes.activeViewIcon]:
                                        activeResultView === "list",
                                },
                            ])}
                            onClick={hanldeViewIconClick}
                        />
                        <CardViewIcon
                            data-intercom-target="Card view icon"
                            className={classnames([
                                {
                                    [classes.inactiveViewIcon]:
                                        activeResultView !== "card",
                                    [classes.activeViewIcon]:
                                        activeResultView === "card",
                                },
                            ])}
                            onClick={hanldeViewIconClick}
                        />
                    </div>
                </Box>
            </Box>
            {/* <Header
                    onSortChange={() => console.log("On sort change")}
                    selectedFilters={[]}
                    displayedFilters={[]}
                    isAuthenticated={isAuthenticated}
                    user={user}
                    isWorkspace={true}
                    selected={[]}
                    searchResults={[]}
                    activeView="list"
                /> */}
            <TableHead
                numSelected={0}
                matchRemoved={true}
                infoRemoved={true}
                typeRemoved={false}
                isWorkspace={true}
            />
            {itemsResults &&
                itemsResults.map((item, key) => (
                    <WorkspaceTableRow
                        rowData={{
                            id: item.itd,
                            name: item.name,
                            description: item.description,
                            type: item.type ? item.type : "company",
                            subType: item.subtype ? item.subtype : null,
                            report: !!item.enrichment_request,
                            search_name:
                                item.type === "search" ? item.name : null,
                            created_date: item.created_date
                                ? item.created_date
                                : null,
                            ...item,
                        }}
                        key={`saved-item-${key}`}
                        openPage={openPage}
                        isAuthenticated={isAuthenticated}
                        user={user}
                        projectSlug={projectSlug}
                        project={project}
                    />
                ))}
        </Paper>
    );
};
