import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// UI components
import Box from "@material-ui/core/Box";
import Typography from "../../components/Typography/Typography.js";
import Button from "../../components/Button/Button.js";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../assets/icons/CloseIcon.svg";
// Redux
import {
    getTeamRequest,
    removeMemberRequest,
    removeInvitationRequest,
} from "../../store/auth/requests";
import {
    getRemoveMemberStatusSelector,
    getRemoveInvitationStatusSelector,
} from "../../store/auth/selectors";
import { REQUEST_SUCCESS, REQUEST_FAILURE } from "../../constants/statuses";

const DeleteUserModal = ({ dialogOpened, user, onChange }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const getRemoveMemberStatus = useSelector(getRemoveMemberStatusSelector);
    const getRemoveInvitationStatus = useSelector(
        getRemoveInvitationStatusSelector,
    );

    const handleDelete = () => {
        dispatch(
            user.status === "pending"
                ? removeInvitationRequest({ email: user.email })
                : removeMemberRequest({ email: user.email }),
        );
    };
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(window.innerWidth <= 575);
    }, []);

    useEffect(() => {
        if (
            getRemoveMemberStatus === REQUEST_SUCCESS ||
            getRemoveInvitationStatus === REQUEST_SUCCESS
        ) {
            dispatch(getTeamRequest({ team: null }));
            onChange();
        }
    }, [getRemoveMemberStatus, getRemoveInvitationStatus]);

    return (
        <Dialog
            fullScreen={isMobile}
            onClose={onChange}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                paperScrollPaper: classes.dialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    onChange(event, reason);
                }
            }}
            open={dialogOpened}>
            <IconButton className={classes.closeButton} onClick={onChange}>
                <img
                    src={CloseIcon}
                    alt={t("scope.step2")}
                    className={classes.stepIcon}
                />
            </IconButton>
            <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant="subtitle1">
                    {t("profileSettings.deleteUserTitle", { param: user.name })}
                </Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <Typography
                    className={classes.dialogValue}
                    color="secondaryDark"
                    align="center"
                    variant="body"
                    component="div">
                    {t("profileSettings.deleteUserDescription")}
                </Typography>
            </MuiDialogContent>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                className={classes.dialodCtas}>
                <Button variant="error" onClick={handleDelete}>
                    {t("profileSettings.deleteUserCta")}
                </Button>
                <Button
                    className={classes.deleteButton}
                    variant="secondary"
                    onClick={onChange}>
                    {t("profileSettings.deleteUserCancel")}
                </Button>
            </Box>
        </Dialog>
    );
};
export default DeleteUserModal;
