import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, typography }) =>
    createStyles({
        snackbar: {
            maxWidth: "calc(100% - 300px)",
            top: 80,
            right: 32,
            zIndex: 1201,
        },
        standardSuccess: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            padding: `${spacing(2)}px ${spacing(3)}px !important`,
            background: `${palette.common.white} !important`,
            ...typography.body,
            color: `${palette.common.black} !important`,
            fontFamily: "GT Flexa Mono, sans-serif",
            border: `1px solid ${palette.success.dark}`,
            borderRadius: `${spacing(2)}px !important`,
            textAlign: "right",
            boxShadow: "none !important",
        },
        standardWarning: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            padding: `${spacing(2)}px ${spacing(3)}px`,
            background: palette.common.white,
            ...typography.body,
            color: palette.common.black,
            fontFamily: "GT Flexa Mono, sans-serif",
            border: `1px solid ${palette.primary.dark}`,
            borderRadius: spacing(2),
            textAlign: "right",
        },
        standardError: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            padding: `${spacing(2)}px ${spacing(3)}px`,
            background: palette.common.white,
            ...typography.body,
            color: palette.common.black,
            fontFamily: "GT Flexa Mono, sans-serif",
            border: `1px solid ${palette.error.main}`,
            borderRadius: `${spacing(2)}px !important`,
            textAlign: "right",
        },
        icon: {
            padding: "0 !important",
            height: 40,
        },
        iconButton: {
            padding: 0,
            marginLeft: spacing(2),
            position: "absolute",
            top: `calc(50% - ${spacing(2)}px)`,
            right: 16,
        },
        message: {
            padding: `8px 40px 0 0 !important`,
        },
        warningIcon: {
            paddingTop: spacing(1),
        },
        warningIconFill: {
            fill: palette.primary.dark,
        },
        errorIconFill: {
            fill: palette.error.main,
        },
        root: {
            borderRadius: spacing(2),
        },
    }),
);
