import React, { useState, useEffect } from "react";
import Slider, { Handle } from "rc-slider";
import "rc-slider/assets/index.css";

import { useTheme } from "@material-ui/core/styles";
import classnames from "classnames";
import {
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as ChartTooltip,
    Bar,
    ResponsiveContainer,
    Cell,
} from "recharts";
import { useStyles } from "./style.js";
import { useGraph } from "./useGraph.js";
import { useTeamSizeGraph } from "./useTeamSizeGraph.js";
import { PieChart } from "react-minimal-pie-chart";
import MapChart from "./MapChart";
// MapChart is dependent on the ReactTooltip
import ReactTooltip from "react-tooltip";
import { useAuthHook } from "../../common/useAuthHook.js";

// Assets
import { ReactComponent as SuccessInfoIcon } from "../../assets/icons/SuccessInfoIcon.svg";
import { ReactComponent as ArrowDownBlack } from "../../assets/icons/ArrowDownBlack.svg";
import { ReactComponent as ResetArrow } from "../../assets/icons/ResetArrow.svg";
import { ReactComponent as NoIndustriesSelected } from "../../assets/icons/NoIndustriesSelected.svg";
import { range } from "../../constants/filters";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "../../components/Typography/Typography";
import ContentLoader from "../ContentLoader/ContentLoader.js";

function median(values) {
    if (values.length === 0) throw new Error("No inputs");

    values.sort(function (a, b) {
        return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2.0;
}

const GraphsContainer = ({
    t,
    chartYears,
    selectedFilters,
    displayedFilters,
    setDisplayedFilters,
    slug,
    setSelectedFilters,
    overviewActive,
    setOverviewActive,
    totalCount,
    resetFilter,
    teamsizes,
    localStorageSlugPrefix,
    unspecifiedAll,
    setPage,
    filterStats,
}) => {
    const { user } = useAuthHook();
    const classes = useStyles({ user });
    const [loading, setLoading] = useState(false);
    const [marks, setMarks] = useState([]);
    const [sizeMarks, setSizeMarks] = useState([]);
    const [sliderMarks, setSliderMarks] = useState([]);
    const [sizeSliderMarks, setSizeSliderMarks] = useState([]);
    const [yearsChartArray, setYearsChartArray] = useState([]);
    const [teamsizesArray, setTeamsizesArray] = useState([]);
    const [teamsizesMedian, setTeamsizesMedian] = useState("");
    const [topIndustries, setTopIndustries] = useState(null);
    const [hasTopIndustries, setHasTopIndustries] = useState(false);
    const [topLocations, setTopLocations] = useState(null);
    const [totalCompanies, setTotalCompanies] = useState(null);
    const [pieChartItems, setPieChartItems] = useState([]);

    // order is important, don't change
    const pieChartColors = [
        "#FD750D",
        "#FD9749",
        "#FEBA86",
        "#FED1AE",
        "#FFE3CF",
    ];
    const [pieChartTooltip, setPieChartTooltip] = useState({
        segmentIndex: null,
        position: {
            x: 0,
            y: 0,
        },
    });

    useEffect(() => {
        // we cant use totalCount here because a company can be in 2 industries at the same time
        if (filterStats && filterStats.sectors) {
            setTotalCompanies(
                Object.values(filterStats.sectors).reduce(
                    (accumulator, value) => {
                        return accumulator + value;
                    },
                    0,
                ),
            );
        }
    }, [filterStats]);

    const getPercentage = (total, single) => {
        return Math.round((100 * single) / total);
    };

    const getRemainingPercentage = arrayOfValues => {
        const result = 100 - arrayOfValues.reduce((a, b) => a + b, 0);

        if (result < 0) {
            return 0;
        }

        return result;
    };

    // set top industries
    useEffect(() => {
        if (filterStats && filterStats.sectors && totalCompanies) {
            setHasTopIndustries(true);
            const sectorNames = Object.keys(filterStats.sectors);

            setTopIndustries(
                sectorNames
                    // initialize data
                    .map(sectorName => {
                        return {
                            name: sectorName,
                            value: getPercentage(
                                totalCompanies,
                                filterStats.sectors[sectorName],
                            ),
                            numberOfCompanies: filterStats.sectors[sectorName],
                            color: "",
                        };
                    })
                    // sort by percentage
                    .sort((a, b) => b.value - a.value)
                    // assign colors
                    .map((item, index) => {
                        return {
                            ...item,
                            color: pieChartColors[index],
                        };
                    }),
            );
        } else {
            setHasTopIndustries(false);
        }
    }, [filterStats, totalCompanies]);

    const [locationsTooltipContent, setLocationsTooltipContent] = useState("");
    //set top locations
    useEffect(() => {
        if (filterStats && filterStats.regions) {
            const regionNames = Object.keys(filterStats.regions);

            setTopLocations(
                regionNames
                    // initialize data
                    .map(regionName => {
                        return {
                            name: regionName.toLowerCase().includes("asia")
                                ? "Asia"
                                : regionName,
                            value: getPercentage(
                                totalCount,
                                filterStats.regions[regionName],
                            ),
                            numberOfCompanies: filterStats.regions[regionName],
                            color: "",
                        };
                    })
                    // merge all asia regions into 1
                    .reduce(function (accumulator, cur) {
                        const name = cur.name,
                            found = accumulator.find(function (elem) {
                                return elem.name == name;
                            });
                        if (found) {
                            found.value += cur.value;
                            found.numberOfCompanies += cur.numberOfCompanies;
                        } else accumulator.push(cur);

                        return accumulator;
                    }, [])
                    // sort by percentage
                    .sort((a, b) => b.numberOfCompanies - a.numberOfCompanies)
                    // assign colors
                    .map((item, index) => {
                        return {
                            ...item,
                            color: pieChartColors[index],
                        };
                    }),
            );
        }
    }, [filterStats, totalCount]);

    useEffect(() => {
        if (hasTopIndustries) {
            let pieChartItems = topIndustries.slice(0, 5);
            pieChartItems.push({
                name: "Others",
                value: getRemainingPercentage(
                    pieChartItems.map(item => item.value),
                ),
                color: "#C95C09",
            });

            setPieChartItems(pieChartItems);
        }
    }, [hasTopIndustries, topIndustries]);

    const theme = useTheme();

    useEffect(() => {
        setLoading(true);
        if (totalCompanies) {
            let timeout = setTimeout(() => {
                setLoading(false);
            }, 800);
            return () => clearTimeout(timeout);
        }
    }, [totalCompanies]);

    useEffect(() => {
        if (teamsizes) {
            var teamsizesValues = Object.values(teamsizes).map(i => i.count);
            var teamsizesKeys = Object.keys(teamsizes);
            setTeamsizesMedian(
                teamsizesKeys[
                    teamsizesValues.findIndex(
                        element => element === median(teamsizesValues),
                    )
                ],
            );
        }
    }, [teamsizes]);

    const {
        handleResetYearChart,
        CustomTooltip,
        handleSliderAfterChange,
        handleSliderChange,
        handleYearBarClick,
        rangeValue,
    } = useGraph({
        chartYears,
        setYearsChartArray,
        selectedFilters,
        displayedFilters,
        setDisplayedFilters,
        setSelectedFilters,
        setSliderMarks,
        theme,
        yearsChartArray,
        resetFilter,
        setMarks,
        t,
        slug,
        localStorageSlugPrefix,
        setPage,
    });

    const {
        handleResetTeamChart,
        TeamCustomTooltip,
        handleTeamSliderAfterChange,
        handleTeamSliderChange,
        handleTeamBarClick,
        rangeTeamValue,
        setTeamRangeValue,
    } = useTeamSizeGraph({
        teamsizes,
        teamsizesArray,
        setTeamsizesArray,
        selectedFilters,
        displayedFilters,
        setDisplayedFilters,
        setSelectedFilters,
        setSizeSliderMarks,
        theme,
        resetFilter,
        setSizeMarks,
        t,
        slug,
        localStorageSlugPrefix,
        setPage,
    });

    const handleResetAll = () => {
        handleResetYearChart(true);
        setTeamRangeValue([0, teamsizesArray.length - 1]);
    };

    if (loading) return <ContentLoader />;

    return (
        <div
            className={classnames([
                classes.charts,
                { [classes.chartsVisible]: overviewActive },
            ])}>
            <div className={classes.chartsHeader}>
                {/*<div className={classes.chartsHeaderLegendWrapper}>*/}
                <Typography
                    variant="subtitle1"
                    className={classes.averageDataLabel}
                    onClick={handleResetAll}>
                    <Tooltip
                        classes={{
                            tooltip: classes.averageDataTooltip,
                            arrow: classes.averageDataTooltipArrow,
                        }}
                        title={
                            <>
                                <Typography
                                    component="div"
                                    className={classnames(
                                        classes.mono,
                                        classes.mb16,
                                    )}
                                    variant="bodyXs">
                                    {t("results.averageDataInfoTooltip")}
                                </Typography>
                                <Typography
                                    component="div"
                                    className={classes.mono}
                                    variant="bodyXs">
                                    {t("results.averageDataInfoTooltip2")}
                                </Typography>
                            </>
                        }
                        arrow>
                        <SuccessInfoIcon className={classes.averageDataIcon} />
                    </Tooltip>
                </Typography>
                <div className={classes.chartsHeaderLegendSubwrapper}>
                    <div className={classes.chartsHeaderLegend}>
                        <Typography
                            variant="bodyXs"
                            color="textLight"
                            className={classnames([
                                classes.mono,
                                classes.chartsLegendAll,
                            ])}>
                            {t("results.allCompanies")}
                        </Typography>
                        <Typography
                            variant="bodyXs"
                            color="textLight"
                            className={classnames([
                                classes.mono,
                                classes.chartsLegend,
                            ])}>
                            {t("results.yourSearch")}
                        </Typography>
                    </div>
                    <Typography
                        variant="bodySmall"
                        color="primaryDark"
                        component="div"
                        className={classnames([
                            classes.mono,
                            classes.resetAll,
                        ])}>
                        {t("results.resetAll")}
                        <ResetArrow className={classes.resetAllIcon} />
                    </Typography>
                </div>
                {/*</div>*/}
            </div>
            {marks && marks.length > 0 && sizeMarks && sizeMarks.length > 0 && (
                <div className={classes.rut}>
                    <div className={classes.graphsWrapper}>
                        <div className={classes.graphsWrapperRow1}>
                            <div className={classes.graphWrapper}>
                                <Typography
                                    variant="paragraph"
                                    color="black"
                                    component="div"
                                    className={classnames([
                                        classes.mono,
                                        classes.chartTitle,
                                    ])}>
                                    {t("results.yearOfInception")}
                                    <ResetArrow
                                        onClick={handleResetYearChart}
                                        className={classes.resetIcon}
                                    />
                                </Typography>
                                <div className={classes.chartYearsLegend}>
                                    <Typography
                                        variant="bodyXs"
                                        color="textLight"
                                        className={classnames([
                                            classes.mono,
                                            classes.year,
                                        ])}>
                                        {t("results.chartMin")}
                                        <Typography
                                            variant="bodyXs"
                                            color="textLight"
                                            className={classnames([
                                                classes.mono,
                                                classes.year,
                                            ])}>
                                            <strong>2002</strong>
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        variant="bodyXs"
                                        color="textLight"
                                        className={classnames([
                                            classes.mono,
                                            classes.year,
                                        ])}>
                                        {t("results.chartMax")}
                                        <Typography
                                            variant="bodyXs"
                                            color="textLight"
                                            className={classnames([
                                                classes.mono,
                                                classes.year,
                                            ])}>
                                            <strong>2022</strong>
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        variant="bodyXs"
                                        color="textLight"
                                        className={classnames([
                                            classes.mono,
                                            classes.year,
                                        ])}>
                                        {t("results.chartMedian")}
                                        <Typography
                                            variant="bodyXs"
                                            color="textLight"
                                            className={classnames([
                                                classes.mono,
                                                classes.year,
                                            ])}>
                                            <strong>
                                                {Math.ceil(
                                                    median(
                                                        Array.from(
                                                            range(
                                                                new Date().getFullYear(),
                                                                2002,
                                                                1,
                                                            ),
                                                        ),
                                                    ),
                                                )}
                                            </strong>
                                        </Typography>
                                    </Typography>
                                </div>
                                <div className={classes.graph}>
                                    <ResponsiveContainer
                                        width="100%"
                                        height={242}>
                                        <BarChart
                                            width={500}
                                            height={400}
                                            data={marks}
                                            margin={{
                                                top: 20,
                                                right: 20,
                                                bottom: 20,
                                                left: 20,
                                            }}>
                                            <CartesianGrid
                                                strokeDasharray="100000"
                                                height={1}
                                                vertical={false}
                                            />
                                            <XAxis
                                                dataKey="x"
                                                minTickGap={10}
                                                wrapperStyle={{
                                                    position: "relative",
                                                }}
                                            />
                                            <YAxis
                                                axisLine={false}
                                                tickFormatter={tick => {
                                                    return `${tick}%`;
                                                }}
                                            />
                                            <ChartTooltip
                                                wrapperStyle={{ top: -100 }}
                                                contentStyle={{ margin: 16 }}
                                                offset={0}
                                                content={<CustomTooltip />}
                                            />
                                            <Bar
                                                dataKey="allPercentage"
                                                barSize={4}
                                                legendType="circle"
                                                fill="#DBDBDA">
                                                {yearsChartArray.map(
                                                    (entry, index) => (
                                                        <Cell
                                                            cursor="pointer"
                                                            onClick={() =>
                                                                handleYearBarClick(
                                                                    index,
                                                                )
                                                            }
                                                            fill={
                                                                index <
                                                                    rangeValue[0] ||
                                                                index >
                                                                    rangeValue[1]
                                                                    ? "#DBDBDA40"
                                                                    : "#DBDBDA"
                                                            }
                                                            key={`year-cell-${index}`}
                                                        />
                                                    ),
                                                )}
                                            </Bar>
                                            <Bar
                                                dataKey="percentage"
                                                barSize={4}
                                                legendType="circle"
                                                fill="#FD750D">
                                                {yearsChartArray.map(
                                                    (entry, index) => (
                                                        <Cell
                                                            cursor="pointer"
                                                            onClick={() =>
                                                                handleYearBarClick(
                                                                    index,
                                                                )
                                                            }
                                                            fill={
                                                                index <
                                                                    rangeValue[0] ||
                                                                index >
                                                                    rangeValue[1]
                                                                    ? "#FD750D40"
                                                                    : "#FD750D"
                                                            }
                                                            key={`year-cell-all-${index}`}
                                                        />
                                                    ),
                                                )}
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                    <div className={classes.graphContent}>
                                        <Slider.Range
                                            dots
                                            min={0}
                                            max={sliderMarks.length - 1}
                                            marks={sliderMarks}
                                            handle={handleProps => {
                                                return (
                                                    <Handle
                                                        {...handleProps}
                                                        dragging={handleProps.dragging.toString()}
                                                        data-intercom-target={`Year of inception chart handle ${handleProps.index}`}
                                                        key={`${handleProps.index}-year-handle`}
                                                    />
                                                );
                                            }}
                                            defaultValue={rangeValue}
                                            value={rangeValue}
                                            onChange={handleSliderChange}
                                            onAfterChange={
                                                handleSliderAfterChange
                                            }
                                            trackStyle={[
                                                {
                                                    backgroundColor: "#FD750D",
                                                    height: 4,
                                                },
                                            ]}
                                            handleStyle={[
                                                {
                                                    borderColor: "#ffffff",
                                                    height: 16,
                                                    width: 16,
                                                    backgroundColor: "#FD750D",
                                                    marginTop: -6,
                                                },
                                                {
                                                    borderColor: "#ffffff",
                                                    height: 16,
                                                    width: 16,
                                                    backgroundColor: "#FD750D",
                                                    marginTop: -6,
                                                },
                                            ]}
                                            railStyle={{
                                                backgroundColor: "#DBDBDA",
                                                height: 2,
                                                top: 6,
                                            }}
                                            dotStyle={{
                                                height: 0,
                                                width: 0,
                                                border: "none",
                                                backgroundColor: "transparent",
                                            }}
                                            activeDotStyle={{
                                                height: 0,
                                                width: 0,
                                                border: "none",
                                                backgroundColor: "transparent",
                                                boxShadow: "none",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={classnames([
                                    classes.teamSizeGraphWrapper,
                                    "team-size-chart",
                                ])}>
                                <Typography
                                    variant="paragraph"
                                    color="black"
                                    component="div"
                                    className={classnames([
                                        classes.mono,
                                        classes.chartTitle,
                                    ])}>
                                    {t("results.teamsize")}
                                    <ResetArrow
                                        onClick={handleResetTeamChart}
                                        className={classes.resetIcon}
                                    />
                                </Typography>
                                <div className={classes.chartYearsLegend}>
                                    <Typography
                                        variant="bodyXs"
                                        color="textLight"
                                        className={classnames([
                                            classes.mono,
                                            classes.year,
                                        ])}>
                                        {t("results.chartMedian")}
                                        <Typography
                                            variant="bodyXs"
                                            color="textLight"
                                            className={classnames([
                                                classes.mono,
                                                classes.year,
                                            ])}>
                                            <strong>{teamsizesMedian}</strong>
                                        </Typography>
                                    </Typography>
                                </div>
                                <div className={classes.graph}>
                                    {unspecifiedAll ? (
                                        <Typography
                                            variant="bodyXs"
                                            color="black"
                                            component="div"
                                            className={classnames([
                                                classes.mono,
                                                classes.noChartTitle,
                                            ])}>
                                            {t("results.noTeamSize")}
                                        </Typography>
                                    ) : (
                                        <>
                                            <ResponsiveContainer
                                                width="100%"
                                                height={242}>
                                                <BarChart
                                                    width={500}
                                                    height={400}
                                                    data={sizeMarks}
                                                    margin={{
                                                        top: 20,
                                                        right: 20,
                                                        bottom: 20,
                                                        left: 20,
                                                    }}>
                                                    <CartesianGrid
                                                        strokeDasharray="100000"
                                                        height={1}
                                                        vertical={false}
                                                    />
                                                    <XAxis
                                                        dataKey="x"
                                                        tick={false}
                                                    />
                                                    <YAxis
                                                        axisLine={false}
                                                        tickFormatter={tick => {
                                                            return `${tick}%`;
                                                        }}
                                                    />
                                                    <ChartTooltip
                                                        wrapperStyle={{
                                                            top: -100,
                                                        }}
                                                        contentStyle={{
                                                            margin: 16,
                                                        }}
                                                        offset={0}
                                                        content={
                                                            <TeamCustomTooltip />
                                                        }
                                                    />
                                                    <Bar
                                                        dataKey="allPercentage"
                                                        barSize={4}
                                                        legendType="circle"
                                                        fill="#DBDBDA">
                                                        {teamsizesArray.map(
                                                            (entry, index) => (
                                                                <Cell
                                                                    cursor="pointer"
                                                                    onClick={() =>
                                                                        handleTeamBarClick(
                                                                            index,
                                                                        )
                                                                    }
                                                                    fill={
                                                                        index <
                                                                            rangeTeamValue[0] ||
                                                                        index >
                                                                            rangeTeamValue[1]
                                                                            ? "#DBDBDA40"
                                                                            : "#DBDBDA"
                                                                    }
                                                                    key={`team-cell-${index}`}
                                                                />
                                                            ),
                                                        )}
                                                    </Bar>
                                                    <Bar
                                                        dataKey="percentage"
                                                        barSize={4}
                                                        legendType="circle"
                                                        fill="#FD750D">
                                                        {teamsizesArray.map(
                                                            (entry, index) => (
                                                                <Cell
                                                                    cursor="pointer"
                                                                    onClick={() =>
                                                                        handleTeamBarClick(
                                                                            index,
                                                                        )
                                                                    }
                                                                    fill={
                                                                        index <
                                                                            rangeTeamValue[0] ||
                                                                        index >
                                                                            rangeTeamValue[1]
                                                                            ? "#FD750D40"
                                                                            : "#FD750D"
                                                                    }
                                                                    key={`team-cell-all-${index}`}
                                                                />
                                                            ),
                                                        )}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                            <div
                                                className={
                                                    classes.graphContent
                                                }>
                                                <Slider.Range
                                                    dots
                                                    min={0}
                                                    max={
                                                        sizeSliderMarks.length -
                                                        1
                                                    }
                                                    marks={sizeSliderMarks}
                                                    handle={handleProps => {
                                                        return (
                                                            <Handle
                                                                {...handleProps}
                                                                dragging={handleProps.dragging.toString()}
                                                                data-intercom-target={`Team size chart handle ${handleProps.index}`}
                                                                key={`${handleProps.index}-team-handle`}
                                                            />
                                                        );
                                                    }}
                                                    defaultValue={
                                                        rangeTeamValue
                                                    }
                                                    value={rangeTeamValue}
                                                    onChange={
                                                        handleTeamSliderChange
                                                    }
                                                    onAfterChange={
                                                        handleTeamSliderAfterChange
                                                    }
                                                    trackStyle={[
                                                        {
                                                            backgroundColor:
                                                                "#FD750D",
                                                            height: 4,
                                                        },
                                                    ]}
                                                    handleStyle={[
                                                        {
                                                            borderColor:
                                                                "#ffffff",
                                                            height: 16,
                                                            width: 16,
                                                            backgroundColor:
                                                                "#FD750D",
                                                            marginTop: -6,
                                                        },
                                                        {
                                                            borderColor:
                                                                "#ffffff",
                                                            height: 16,
                                                            width: 16,
                                                            backgroundColor:
                                                                "#FD750D",
                                                            marginTop: -6,
                                                        },
                                                    ]}
                                                    railStyle={{
                                                        backgroundColor:
                                                            "#DBDBDA",
                                                        height: 2,
                                                        top: 6,
                                                    }}
                                                    dotStyle={{
                                                        height: 0,
                                                        width: 0,
                                                        border: "none",
                                                        backgroundColor:
                                                            "transparent",
                                                    }}
                                                    activeDotStyle={{
                                                        height: 0,
                                                        width: 0,
                                                        border: "none",
                                                        backgroundColor:
                                                            "transparent",
                                                        boxShadow: "none",
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={classes.graphsWrapperRow2}>
                            <div className={classes.industriesGraphWrapper}>
                                <Typography
                                    variant="paragraph"
                                    color="black"
                                    component="div"
                                    className={classnames([
                                        classes.mono,
                                        classes.chartTitle,
                                    ])}>
                                    {t("results.topIndustries")}
                                </Typography>
                                {hasTopIndustries ? (
                                    <>
                                        <div className={classes.industryGraph}>
                                            <div className={classes.pieChart}>
                                                <div
                                                    className={
                                                        classes.pieChartTitle
                                                    }>
                                                    <Typography
                                                        component="div"
                                                        className={
                                                            classes.pieChartTitleNumber
                                                        }>
                                                        {filterStats &&
                                                            filterStats.sectors &&
                                                            Object.keys(
                                                                filterStats.sectors,
                                                            ).length}
                                                    </Typography>
                                                    <Typography
                                                        variant="label"
                                                        component="div"
                                                        className={
                                                            classes.mono
                                                        }>
                                                        <strong>
                                                            {t(
                                                                "results.topIndustriesPieChartTitle",
                                                            )}
                                                        </strong>
                                                    </Typography>
                                                </div>
                                                <PieChart
                                                    center={[15, 10]}
                                                    data={pieChartItems}
                                                    lengthAngle={360}
                                                    lineWidth={25}
                                                    paddingAngle={0}
                                                    radius={6}
                                                    startAngle={0}
                                                    viewBoxSize={[30, 20]}
                                                    segmentsStyle={index => ({
                                                        cursor: "pointer",
                                                        // transform: pieChartTooltip.segmentIndex === index ? 'scale(1.015, 1.015)' : 'scale(1, 1)',
                                                        // transformOrigin: pieChartTooltip.segmentIndex === index ? '50% 50%' : '',
                                                        strokeWidth:
                                                            pieChartTooltip.segmentIndex ===
                                                            index
                                                                ? "2"
                                                                : "1.5",
                                                        transition:
                                                            "stroke-width .2s",
                                                    })}
                                                    onMouseOver={(
                                                        e,
                                                        segmentIndex,
                                                    ) => {
                                                        setPieChartTooltip({
                                                            segmentIndex,
                                                            position: {
                                                                x: e.clientX,
                                                                y: e.clientY,
                                                            },
                                                        });
                                                    }}
                                                    onMouseOut={() => {
                                                        setPieChartTooltip(
                                                            prevState => {
                                                                return {
                                                                    ...prevState,
                                                                    segmentIndex:
                                                                        null,
                                                                };
                                                            },
                                                        );
                                                    }}
                                                />
                                                {pieChartTooltip.segmentIndex !==
                                                    null && (
                                                    <div
                                                        className={
                                                            classes.pieChartTooltip
                                                        }
                                                        title={
                                                            topIndustries[
                                                                pieChartTooltip
                                                                    .segmentIndex
                                                            ] &&
                                                            topIndustries[
                                                                pieChartTooltip
                                                                    .segmentIndex
                                                            ].value
                                                        }
                                                        style={{
                                                            top: pieChartTooltip
                                                                .position.y,
                                                            left: pieChartTooltip
                                                                .position.x,
                                                        }}>
                                                        <Typography variant="label">
                                                            <div
                                                                className={
                                                                    classes.pieChartTooltipNumber
                                                                }>
                                                                <strong>
                                                                    {pieChartItems[
                                                                        pieChartTooltip
                                                                            .segmentIndex
                                                                    ] &&
                                                                        pieChartItems[
                                                                            pieChartTooltip
                                                                                .segmentIndex
                                                                        ].value}
                                                                    %
                                                                </strong>
                                                            </div>
                                                            <Typography
                                                                component="div"
                                                                varant="body">
                                                                {
                                                                    pieChartItems[
                                                                        pieChartTooltip
                                                                            .segmentIndex
                                                                    ].name
                                                                }
                                                            </Typography>
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                            {/*<div>*/}
                                            {/*    <Typography component="div" className={classes.topIndustriesSubtitle}>{t('results.topIndustriesSubtitle')}</Typography>*/}
                                            {/*</div>*/}
                                            <div
                                                className={
                                                    classes.industriesGraphContent
                                                }>
                                                {topIndustries &&
                                                    topIndustries
                                                        .slice(0, 5)
                                                        .map(
                                                            (
                                                                industry,
                                                                index,
                                                            ) => (
                                                                <div
                                                                    className={
                                                                        classes.industriesGraphContentItem
                                                                    }
                                                                    key={
                                                                        industry.name
                                                                    }>
                                                                    <span>
                                                                        <span
                                                                            style={{
                                                                                display:
                                                                                    "inline-block",
                                                                                width: "10px",
                                                                                height: "10px",
                                                                                background:
                                                                                    industry.color,
                                                                                borderRadius:
                                                                                    "50%",
                                                                                marginRight:
                                                                                    "10px",
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            variant="bodySmall"
                                                                            className={
                                                                                classes.mono
                                                                            }>
                                                                            {
                                                                                industry.name
                                                                            }
                                                                        </Typography>
                                                                    </span>
                                                                    <Typography
                                                                        variant="bodySmall"
                                                                        className={
                                                                            classes.mono
                                                                        }>
                                                                        {
                                                                            industry.numberOfCompanies
                                                                        }
                                                                    </Typography>
                                                                </div>
                                                            ),
                                                        )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={
                                                classes.noIndustriesIconWrapper
                                            }>
                                            <NoIndustriesSelected
                                                className={
                                                    classes.noIndustriesIcon
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            {/*  Regions graph will go here  */}
                            <div className={classes.locationsGraphWrapper}>
                                <Typography
                                    variant="paragraph"
                                    color="black"
                                    component="div"
                                    className={classnames([
                                        classes.mono,
                                        classes.chartTitle,
                                    ])}>
                                    {t("results.topLocations")}
                                </Typography>
                                <div className={classes.locationsGraph}>
                                    <div>
                                        <MapChart
                                            topLocations={topLocations}
                                            setTooltipContent={
                                                setLocationsTooltipContent
                                            }
                                        />
                                        <ReactTooltip
                                            arrowColor="#fff"
                                            className={classes.mapChartTooltip}>
                                            {!!locationsTooltipContent && (
                                                <>
                                                    <Typography
                                                        component="div"
                                                        className={
                                                            classes.pieChartTooltipNumber
                                                        }>
                                                        <strong>
                                                            {(topLocations.find(
                                                                item =>
                                                                    item.name ===
                                                                    locationsTooltipContent,
                                                            ) &&
                                                                topLocations.find(
                                                                    item =>
                                                                        item.name ===
                                                                        locationsTooltipContent,
                                                                ).value) ||
                                                                0}{" "}
                                                            %
                                                        </strong>
                                                    </Typography>
                                                    <Typography
                                                        component="div"
                                                        varant="body">
                                                        {
                                                            locationsTooltipContent
                                                        }
                                                    </Typography>
                                                </>
                                            )}
                                        </ReactTooltip>
                                    </div>
                                </div>

                                {/*<div>*/}
                                {/*    <Typography component="div" className={classes.topIndustriesSubtitle}>{t('results.topLocationsSubtitle')}</Typography>*/}
                                {/*</div>*/}
                                <div className={classes.industriesGraphContent}>
                                    {topLocations &&
                                        topLocations
                                            .slice(0, 5)
                                            .map((location, index) => (
                                                <div
                                                    className={
                                                        classes.industriesGraphContentItem
                                                    }
                                                    key={location.name}>
                                                    <span>
                                                        <span
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                                width: "10px",
                                                                height: "10px",
                                                                background:
                                                                    location.color,
                                                                borderRadius:
                                                                    "50%",
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="bodySmall"
                                                            className={
                                                                classes.mono
                                                            }>
                                                            {location.name}
                                                        </Typography>
                                                    </span>
                                                    <Typography
                                                        variant="bodySmall"
                                                        className={
                                                            classes.mono
                                                        }>
                                                        {
                                                            location.numberOfCompanies
                                                        }
                                                    </Typography>
                                                </div>
                                            ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*<Typography className={classes.collapseOverviewLabel} onClick={() => setOverviewActive(false)} variant="body" align="center" component="div" color="black">Collapse overview <ArrowDownBlack className={classes.arrowDownBlack} /></Typography>*/}
        </div>
    );
};

export default GraphsContainer;
