import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        resultsRoot: {
            margin: "0 auto",
            padding: `${spacing(4)}px ${spacing(0)}px`,
            maxWidth: 1136,
        },
        resultsContent: {
            marginTop: spacing(3),
            padding: `0 ${spacing(3)}px`,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: spacing(2),
            [breakpoint.down("lg")]: {
                padding: spacing(2),
                marginTop: 0,
            },
        },
        breadcrumbLink: {
            cursor: "pointer",
            "&:hover": {
                color: palette.primary.dark,
            },
        },
        breadcrumbsOl: {
            alignItems: "center",
        },
        breadcrumbsLi: {
            alignItems: "center",
            display: "flex",
        },
        mono: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        header: {
            position: "relative",
            paddingLeft: spacing(2),
        },
        headerFiltersWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            flexWrap: "wrap",
        },
        toolbarRight: {
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            [breakpoint.down("sm")]: {
                order: 3,
                marginLeft: 0,
            },
        },
        toolbarRightButtons: {
            display: "flex",
            alignItems: "center",
        },
        saveButton: {
            whiteSpace: "nowrap",
            marginRight: spacing(2),
            color: palette.common.black,
            cursor: "pointer",
            display: "flex",
            gap: 15,
            padding: `${spacing(1)}px ${spacing(3)}px`,
            alignItems: "center",
            borderRadius: "100px",
            background: "transparent",
            border: `1px solid ${palette.text.lightest}`,
            "& svg": {
                filter: "grayscale(100%)",
                opacity: 0.3,
            },
            "&:hover": {
                background: palette.success.backgroundLight,
                borderColor: "none",
            },
        },
        tableToolbar: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: "none",
            padding: spacing(2),
            [breakpoint.down("sm")]: {
                order: 2,
                width: "auto",
                flexDirection: "row",
            },
        },
        tableCellCheckbox: {
            flex: 1,
            alignSelf: "flex-start",
            wordBreak: "break-word",
            maxWidth: 56,
            minWidth: 56,
            margin: "auto",
        },
    }),
);
