import React from "react";
import { useLocation } from "react-router-dom";
// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../../../components/Button/Button";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useRouterHook } from "../../../../common/useRouterHook";
//UI Components
import Modal from "../../../../components/Modal/Modal";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import DiscoverPartnershipCandidates from "../../../../assets/images/DiscoverPartnershipCandidates.png";

const SubscriptionSuccessfulModal = ({
    dialogOpened,
    onChange,
    subscriptionName,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    let { pathname } = useLocation();

    const handleConfirm = () => {
        onChange();
        openPage(pathname);
    };

    return (
        <Modal
            onClose={onChange}
            maxWidth="lg"
            disableEscapeKeyDown={true}
            fullWidth={true}
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.subscribeConfirmationModal,
                scrollPaper: classes.dialogScrollPaper,
                paperWidthLg: classes.dialogPaperScrollPaper,
            }}
            dialogOpened={dialogOpened}
            dialogContentClassName={classes.dialogContent}
            dialogFilterClassName={classes.dialogFilter}>
            <IconButton className={classes.closeButton} onClick={handleConfirm}>
                <img
                    src={CloseIcon}
                    alt={t("scope.step2")}
                    className={classes.stepIcon}
                />
            </IconButton>
            <img
                alt={t("subscriptionSuccessfulModal.title", {
                    subscriptionName,
                })}
                src={DiscoverPartnershipCandidates}
            />
            <MuiDialogTitle
                disableTypography
                className={classes.dialogTitleWrapper}>
                <Typography
                    className={classes.subscribeTitle}
                    variant="subtitle1"
                    color="secondaryDark"
                    align="center"
                    component="div">
                    {t("subscriptionSuccessfulModal.title", {
                        subscriptionName,
                    })}
                </Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.subscribeDialogContent}>
                <Typography
                    variant="body"
                    color="black"
                    align="center"
                    component="span">
                    {t(`subscriptionSuccessfulModal.description`)}
                </Typography>
                <div className={classes.confirmCtas}>
                    <Button variant="primary" onClick={handleConfirm}>
                        {t("subscriptionSuccessfulModal.cta")}
                    </Button>
                </div>
            </MuiDialogContent>
        </Modal>
    );
};
export default SubscriptionSuccessfulModal;
