import { useEffect, useState } from "react";
import { SocketService } from "../services/socketService";
import { useCookiesHook } from "./useCookiesHook";

const listen = (channel, event, callBack) => {
    window.echo
        .channel(channel)
        .subscribed(() => console.log(`Subscribed to ${channel} successfully.`))
        .listen(event, payload => callBack(payload));
};

export const useSocket = ({ type, data, callBack }) => {
    const { getCookie } = useCookiesHook();
    const [connectedChannels, setConnectedChannels] = useState([]);

    const csrfToken = getCookie("XSRF-TOKEN");

    const socketService = new SocketService(csrfToken);

    useEffect(() => {
        socketService.getSocketConnection();
        //... do stuff
        switch (type) {
            case "TEST_CONNECTION": {
                return listen("test-channel", ".server.test", callBack);
            }
            case "PUMA_CHAT": {
                const {userId, searchId} = data;
                const channel = `puma-channel.${userId}.${searchId}`;
                if (userId && searchId && !connectedChannels.includes(channel) ) {
                    setConnectedChannels(current => [...current, channel]);
                    return listen(
                        channel,
                        ".puma.chat",
                        callBack,
                    );
                } 
            }
            default:;
        }
    }, [data]);
};
