import React, { useState } from "react";
// Hooks
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { useAuthHook } from "../../../common/useAuthHook";
import { useRouterHook } from "../../../common/useRouterHook";
//Components
import Typography from "../../../components/Typography/Typography";
import Card from "../../Results/components/Card/Card";
import Link from "../../../components/Link/Link";
import LeftArrow from "../../../assets/icons/LeftArrow.svg";
import LeftArrowActive from "../../../assets/icons/LeftArrowActive.svg";
import classnames from "classnames";
import RightArrow from "../../../assets/icons/RightArrow.svg";
import RightArrowActive from "../../../assets/icons/RightArrowActive.svg";
import Tooltip from "@material-ui/core/Tooltip";

const SuggestedCompanies = ({ companies, parentSlug, parentId }) => {
    const { isAuthenticated } = useAuthHook();
    const { openPage } = useRouterHook();
    const classes = useStyles();
    const { t } = useTranslation();
    const { show } = useIntercom();
    const isMobile = window.innerWidth <= 575;

    const [slideIndex, setSlideIndex] = useState(0);

    const next = () => setSlideIndex(prev => prev + 1);
    const prev = () => setSlideIndex(prev => prev - 1);

    const openIntercom = () => {
        if (process.env.REACT_APP_ENV === "prod") {
            show();
        }
    };

    return (
        <div
            className={classes.root}
            data-intercom-target="Other interesting companies">
            <div className={classes.titleInfoWrap}>
                <Typography variant="subtitle1" color="black">
                    <strong>{t("suggestedCompanies.title")}</strong>
                </Typography>
                <Tooltip
                    interactive={true}
                    classes={{ tooltip: classes.averageDataTooltip }}
                    title={
                        <>
                            <div>
                                <Typography variant="bodySmall" color="black">
                                    {t(
                                        "suggestedCompanies.tooltip.description1.text",
                                    )}
                                </Typography>
                                <Typography
                                    className={classes.cursorPointer}
                                    onClick={() => {
                                        openPage(
                                            window.location.origin +
                                                "/profile-settings",
                                            true,
                                        );
                                    }}
                                    variant="bodySmall"
                                    textDecoration="underline"
                                    color="black">
                                    {t(
                                        "suggestedCompanies.tooltip.description1.link",
                                    )}
                                </Typography>
                                <Typography
                                    className={classes.description2}
                                    variant="bodySmall"
                                    color="black"
                                    component="div">
                                    {t(
                                        "suggestedCompanies.tooltip.description2",
                                    )}
                                </Typography>
                            </div>
                        </>
                    }>
                    <div className={classes.infoButton}>i</div>
                </Tooltip>
            </div>
            {companies && companies.length > 0 ? (
                <div className={classes.cards}>
                    {companies &&
                        companies.map(
                            (company, i) =>
                                (i === slideIndex ||
                                    (!isMobile && i === slideIndex + 1)) && (
                                    <Card
                                        isSuggestedCompany={true}
                                        cardIndex={i}
                                        openPage={openPage}
                                        searchResultsSid={null}
                                        t={t}
                                        result={company}
                                        isAuthenticated={isAuthenticated}
                                        deselectAll={() => {}}
                                        selectedCompanies={[]}
                                        key={`card-${i}-${company.id}`}
                                        setSelectedCompanyIndex={() => {}}
                                        showPopUpForIndex={false}
                                        searchTypeLook={null}
                                        parentSlug={parentSlug}
                                        parentId={parentId}
                                        projectSlug={parentSlug}
                                        searchType="1"
                                        view={true}
                                    />
                                ),
                        )}
                </div>
            ) : (
                <div className={classes.noCompanies}>
                    <Typography
                        variant="bodySmall"
                        color="black"
                        component="div">
                        <Typography variant="bodySmall" color="black">
                            {t(
                                "suggestedCompanies.noCompanies.description1.text",
                            )}
                        </Typography>
                        <Typography
                            onClick={() =>
                                openPage(
                                    window.location.origin +
                                        "/profile-settings",
                                    true,
                                )
                            }
                            className={classes.cursorPointer}
                            variant="bodySmall"
                            textDecoration="underline"
                            color="black">
                            {t(
                                "suggestedCompanies.noCompanies.description1.link",
                            )}
                        </Typography>
                        <Typography
                            className={classes.description2}
                            component="div"
                            variant="bodySmall"
                            color="black">
                            {t("suggestedCompanies.noCompanies.description2")}
                        </Typography>
                    </Typography>
                </div>
            )}

            {companies && companies.length > 0 && (
                <div className={classes.stepper}>
                    <div className={classes.backBtnWrapper}>
                        <Link
                            variant="body2"
                            iconRight
                            onClick={prev}
                            className={classes.exampleStepperLink}
                            disabled={slideIndex <= 0}>
                            <img
                                src={
                                    slideIndex <= 0
                                        ? LeftArrow
                                        : LeftArrowActive
                                }
                                alt={t("industry.queryExamples")}
                                className={classes.infoIcon}
                            />
                        </Link>
                    </div>
                    <div className={classes.dotsWrapp}>
                        {companies &&
                            companies.map((item, i) => (
                                <span
                                    key={`${i}-slide-index`}
                                    className={classnames([
                                        classes.dot,
                                        {
                                            [classes.activeDot]:
                                                i === slideIndex,
                                        },
                                    ])}></span>
                            ))}
                    </div>
                    <div className={classes.nextBtnWrapper}>
                        <Link
                            variant="body2"
                            iconRight
                            onClick={next}
                            className={classes.exampleStepperLink}
                            disabled={slideIndex >= companies.length - 1}>
                            <img
                                src={
                                    slideIndex >= companies.length - 1
                                        ? RightArrow
                                        : RightArrowActive
                                }
                                alt={t("industry.queryExamples")}
                                className={classes.infoIcon}
                            />
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};
export default SuggestedCompanies;
