import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, shape, typography, breakpoint }) =>
        createStyles({
            mlChat: {
                backgroundColor: palette.secondary.backgroundLight,
                borderRadius: "18px",
                borderTopLeftRadius: 0,
                //maxHeight: "430px",
                // height: "calc( 100vh - 420px)",
                display: "flex",
                flexDirection: "column",
                padding: `${spacing(1)}px ${spacing(2)}px ${spacing(
                    2,
                )}px ${spacing(2)}px`,
                height: "100%",
            },
            chatHeaderContainer: {
                display: "flex",
                justifyContent: "space-between",
                marginBottom: spacing(2),
                borderBottom: `1px solid ${palette.secondary.backgroundDark}`,
            },
            header: {
                display: "flex",
            },
            headerTitle: {
                fontSize: "18px",
                alignSelf: "center",
                fontWeight: 300,
                color: palette.secondary.main,
                "&:b": {
                    fontWeight: 700,
                },
            },
            title: {
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "30px",
                letterSpacing: "-0.48px",
                marginBottom: spacing(1),
            },
            infoToolTip: {
                display: "inline-block",
            },
            infoIcon: {
                width: "22px",
                height: "22px",
                borderRadius: "100px",
                color: palette.common.white,
                backgroundColor: palette.secondary.dark,
                textAlign: "center",
                lineHeight: 1.5,
                marginLeft: spacing(1),
            },
            infoTooltipText: {
                fontFamily: "GT Flexa",
                fontSize: "12px",
                lineHeight: "16px",
            },
            chatLoading: {
                height: "11px",
                display: "none",
                //paddingLeft: spacing(2),
                marginBottom: spacing(1),
                fontSize: "11px",
                lineHeight: "0.5",
            },
            showLoading: {
                display: "block",
            },
            chatHistoryLoading: {
                display: "flex",
                justifyContent: "center"
            },
            summaryContainer: {
                maxWidth: "700px",
                margin: "auto",
                [`@media (min-width:1681px)`]: {
                    maxWidth: "90%",
                },
            },
            suggestions: {
                paddingLeft: spacing(2),
                paddingRight: spacing(2),
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: spacing(1),
                maxWidth: "700px",
                margin: "auto",
                [`@media (min-width:1681px)`]: {
                    maxWidth: "90%",
                },
                "&>div": {
                    display: "flex",
                    flexWrap: "wrap"
                }
            },
            chip: {
                padding: `${spacing(1)}px ${spacing(2)}px`,
                marginBottom: spacing(1),
                marginRight: spacing(1),
                border: `1px solid ${palette.text.light}`,
                borderRadius: "4px",
                color: palette.text.light,
                fontSize: "16px",
                fontWeight: 500,
                fontFamily: "GT Flexa",
                cursor: "pointer",
                "&:hover": {
                    borderColor: palette.primary.dark,
                },
                "&:disabled": {
                    backgroundColor: palette.secondary.backgroundLight,
                    color: palette.text.lighter,
                    borderColor: palette.text.lighter,
                },
            },
            chipSelected: {
                color: palette.success.dark,
                backgroundColor: palette.success.medium,
                "&:hover": {
                    color: palette.common.white,
                    cursor: "auto",
                    backgroundColor: palette.primary.dark,
                    borderColor: palette.primary.dark,
                },
            },
            chipBtnText: {
                marginLeft: spacing(1),
                borderColor: palette.success.medium,
            },
            placeholder: {
                marginBottom: spacing(1),
                fontSize: "12px",
                lineHeight: "14px",
            },
            chatContent: {
                height: "calc( 100vh - 195px)",
                minHeight: 60,
                overflow: "auto",
                margin: `0 -${spacing(2)}px`,
                fontSize: "16px",
                fontWeight: 300,
                color: palette.text.main,
            },
            pumaLogoWrapper: {
                width: "98px",
                padding: spacing(1),
                paddingTop: 0,
                marginRight: spacing(1),
                borderRadius: "6px",
            },
            pumaLogo: {
                width: "91px",
            },
            emptyConversationPlaceholder: {
                display: "flex",
                margin: "auto",
                width: "765px",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                "& p": {
                    marginTop: "11px",
                    marginBottom: "11px",
                    fontWeight: 400,
                    lineHeight: "normal",
                },
            },
            placeholderContent: {
                height: "100%",
            },
            placeholderTitle: {
                marginTop: "0 !important",
                fontSize: "16px",
                fontWeight: "500 !important",
                lineHeight: "normal",
            },
            placeHolderItem: {
                margin: "auto",
            },
            chatInput: {
                marginTop: "auto",
                display: "flex",
                justifyContent: "center",
            },
            userInput: {
                flex: 1,
                maxWidth: "700px",
                [`@media (min-width:1681px)`]: {
                    maxWidth: "90%",
                },
            },
            textareaField: {
                fontFamily: "GT Flexa",
                fontSize: "14px",
                fontWeight: 300,
                width: "100%",
                borderRadius: "8px",
                borderColor: palette.secondary.backgroundDark,
                paddingLeft: "8px",
                paddingRight: "35px",
                paddingTop: "5px",
                maxWidth: "700px",
                maxHeight: 60,
                "&::placeholder": {
                    color: palette.secondary.light,
                },
                "&:focus": {
                    outline: "none !important",
                    borderColor: palette.text.main,
                },
                [`@media (min-width:1681px)`]: {
                    maxWidth: "100%",
                },
            },
            stopBtnWrapper: {
                position: "relative"
            },
            stopPuma: {
                position: "absolute",
                marginLeft: "-35px",
                marginTop: "17px",
                cursor: "pointer",
                color: palette.text.light,
                "&:hover": {
                    color: palette.primary.dark
                }
            },
            copyIcon: {
                color: palette.secondary.light,
                "&:hover": {
                    cursor: "pointer",
                    color: palette.primary.dark
                }
            },
            copyDoneIcon: {
                color: palette.primary.dark
            },
            getSummaryBtn: {
                marginLeft: spacing(2),
                marginBottom: spacing(2),
                fontFamily: "GT Flexa",
                fontSize: "16px",
            },
            scrollToSummaryBtn: {
                border: `1px solid ${palette.secondary.darker}`,
                color: palette.secondary.darker,
            },
            messageContainer: {
                paddingBottom: spacing(2),
                maxWidth: "700px",
                [breakpoint.up("xxxxl")]: {
                    maxWidth: "90%",
                },
                [`@media (min-width:1681px)`]: {
                    maxWidth: "90%",
                },
                margin: "auto",
            },
            messageContent: {
                display: "flex",
                padding: `${spacing(2)}px ${spacing(2)}px 0 ${spacing(2)}px`,
            },
            contentOptions: {
                paddingLeft: "35px"
            },
            userContent: {
                backgroundColor: palette.secondary.backgroundDark,
            },
            userImgWrapper: {
                marginTop: "3px",
            },
            userImg: {
                width: "14px",
            },
            userText: {
                paddingLeft: spacing(1),
            },
            mlContent: {
                height: "100%",
            },
            mlImgWrapper: {
                display: "flex",
                alignItems: "start",
                marginTop: "3px",
            },
            mlImg: {
                width: "14px",
            },
            mlText: {
                paddingLeft: spacing(1),
            },
        }),
);
