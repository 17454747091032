import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, shape, transitions, typography, breakpoint }) =>
        createStyles({
            main: {
                margin: `0 auto`,
                padding: spacing(4),
                maxWidth: "1136px",
                [breakpoint.down("lg")]: {
                    padding: spacing(3),
                },
                [breakpoint.down("sm")]: {
                    padding: spacing(2),
                },
            },
            infoWrap: {
                marginTop: spacing(3),
            },
            title: {
                borderBottom: "1px solid rgba(107, 119, 140, 0.1)",
                paddingBottom: spacing(2),
            },
            projectInfoWrapper: {
                background: palette.success.backgroundLight,
                padding: spacing(4),
                borderRadius: spacing(3),
                [breakpoint.down("sm")]: {
                    padding: spacing(3),
                },
                [breakpoint.down("xs")]: {
                    padding: spacing(2),
                },
            },
            projectInfo: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                [breakpoint.down("md")]: {
                    flexDirection: "column",
                    justifyContent: "center",
                },
            },
            projectInfoContent: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "70%",
                [breakpoint.down("md")]: {
                    width: "100%",
                },
            },
            projectInfoContentTitle: {
                fontWeight: "500",
                display: "inline-flex",
                "& img": {
                    marginRight: spacing(1),
                },
            },
            projectInfoContentTitleText: {
                textTransform: "capitalize",
            },
            projectInfoContentDescription: {
                fontWeight: "300",
                paddingTop: spacing(3),
                paddingBottom: spacing(3),
            },
            projectInfoContentTimestamp: {
                color: palette.secondary.dark,
            },
            projectInfoComingSoon: {
                width: "30%",
                textAlign: "right",
                [breakpoint.down("md")]: {
                    display: "none",
                },
            },
            resultsTable: {
                background: palette.common.white,
            },
            descriptionText: {
                color: palette.text.main,
                marginTop: spacing(3),
                maxHeight: "50px",
                overflow: "auto",
            },
            infoTitleValue: {
                "&:not(:first-child)": {
                    marginLeft: spacing(7),
                },
                display: "flex",
                flexDirection: "column",
                [breakpoint.down("sm")]: {
                    marginLeft: "0!important",
                    flexBasis: "100%",
                    "&:not(:first-child)": {
                        marginTop: spacing(2),
                    },
                },
                [breakpoint.down("md")]: {
                    "&:nth-child(4)": {
                        marginLeft: 0,
                        marginTop: spacing(2),
                    },
                },
            },
            infoValue: {
                color: palette.text.lighter,
            },
            tag: {
                marginRight: spacing(1),
            },
            projectStatus: {
                color: palette.secondary.light,
            },
            projectStatusValue: {
                color: palette.text.secondary,
                marginLeft: spacing(1),
            },
            tableImage: {
                marginRight: spacing(1),
                maxWidth: spacing(4),
                borderRadius: shape.borderRadius,
            },
            tableRowTitle: {
                color: palette.secondary.main,
            },
            tableRowLink: {
                color: palette.secondary.light,
            },
            tablePoints: {
                color: palette.secondary.light,
            },
            tableStage: {
                color: palette.secondary.light,
            },
            tableLocation: {
                color: palette.secondary.light,
            },
            threeDots: {
                cursor: "pointer",
            },
            typographyTag: {
                background: palette.primary.backgroundLight,
                height: 20,
                color: palette.common.black,
                padding: "0 4px",
                borderRadius: spacing(1),
                display: "inline-flex",
                cursor: "pointer",
            },
            tableHeadRoot: {
                background: "rgba(233, 238, 244, 0.25)",
            },
            tableRowHead: {
                color: palette.info.light,
                "&:first-child": {
                    paddingLeft: spacing(4),
                },
            },
            paddingLeft: {
                "&:first-child": {
                    paddingLeft: spacing(4),
                },
            },
            tableCellBorder: {
                borderBottom: "1px solid rgba(107, 119, 140, 0.1)",
            },
            tableContainerRoot: {
                boxShadow: "none",
                overflow: "visible !important",
            },
            commentActionItems: {
                paddingTop: spacing(3),
                marginTop: spacing(3),
                borderTop: "1px solid #F0F1F3",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
            },
            questionMark: {
                marginRight: spacing(1),
            },
            communicationIcon: {
                padding: `${spacing(1)}px ${spacing(1)}px`,
            },
            emptyTag: {
                height: spacing(3),
            },
            switchTrack: {
                // backgroundColor: "#27AE60!important",
                opacity: "1!important",
            },
            switchThumb: {
                color: "#FFFFFF!important",
                border: "0.5px solid rgba(0, 0, 0, 0.2)",
                boxShadow: "0px 0.5px 4px rgba(0, 0, 0, 0.2)",
            },
            actions: {
                position: "absolute",
                width: 234,
                boxShadow:
                    "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
                borderRadius: shape.borderRadius,
                background: palette.common.white,
                border: "1px solid",
                borderColor: palette.secondary.main,
                right: spacing(3),
                top: 48,
                padding: `${spacing(1)}px 0`,
                zIndex: 3,
            },
            action: {
                padding: spacing(1),
                cursor: "pointer",
                "&:hover": {
                    background: palette.secondary.main,
                },
            },
            positionRelative: {
                position: "relative",
            },
            disabled: {
                pointerEvents: "none",
                opacity: 0.5,
            },
            tableRow: {
                cursor: "pointer",
            },
            rootSwitch: {
                width: 42,
                height: 26,
                padding: 0,
                margin: spacing(1),
            },
            switchBase: {
                padding: 1,
                "&$checked": {
                    transform: "translateX(16px)",
                    color: palette.common.white,
                    "& + $track": {
                        backgroundColor: `${palette.common.white}!important`,
                        border: "none",
                        opacity: 1,
                    },
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            likeDislikeIcons: {
                display: "flex",
            },
            track: {
                borderRadius: 26 / 2,
                border: `1px solid ${palette.common.white}`,
                backgroundColor: palette.common.white,
                opacity: `0.5`,
                transition: transitions.create(["background-color", "border"]),
            },
            checked: {},
            focusVisible: {},
            resultsContent: {
                marginTop: spacing(3),
                padding: 0,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: spacing(2),
                [breakpoint.down("lg")]: {
                    padding: spacing(2),
                    marginTop: 0,
                },
            },
            avatar: {
                border: "1px solid #F0F1F3",
                borderRadius: 4,
                padding: 4,
                marginRight: spacing(2),
            },
            enrichedProfileTag: {
                color: palette.common.white,
                background: palette.primary.main,
                padding: `0 ${spacing(1)}px`,
                borderRadius: shape.borderRadius,
                height: spacing(3),
                display: "flex",
                alignItems: "center",
                width: 105,
            },
            valuerScoreBlock: {
                position: "absolute",
                top: -32,
                right: -32,
                background: palette.primary.backgroundMedium,
                boxShadow: `inset 1px 0px 0px #EFEFEE`,
                height: 164,
                width: 164,
                borderTopRightRadius: spacing(2),
                [breakpoint.down("xl")]: {
                    top: -16,
                    right: -16,
                    width: 80,
                    height: 80,
                    borderTopRightRadius: spacing(2),
                },
            },
            valuerScore: {
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            },
            chart: {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: spacing(2),
            },
            scoreBreakdown: {
                marginTop: -spacing(1),
                position: "relative",
                color: palette.secondary.dark,
            },
            root: {
                flex: "calc(50% - 8px)",
                maxWidth: "calc(50% - 8px)",
                marginBottom: spacing(2),
                "&:nth-child(odd)": {
                    marginRight: spacing(2),
                    [breakpoint.down("sm")]: {
                        marginRight: 0,
                    },
                },
                "&:hover": {
                    boxShadow:
                        "0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)",
                    cursor: "pointer",
                },
                position: "relative",
                [breakpoint.down("sm")]: {
                    width: "100%",
                    flex: "100%",
                    maxWidth: "100%",
                },
            },
            infoIcon: {
                position: "absolute",
                top: 12,
                right: 12,
            },
            score: {
                lineHeight: "40px",
                position: "absolute",
                top: 60,
            },
            tags: {
                display: "flex",
            },
            headerWrapper: {
                minHeight: 132,
                position: "relative",
                "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: -32,
                    height: 1,
                    background: palette.text.backgroundMain,
                    [breakpoint.down("xl")]: {
                        width: "calc(100% + 16px)",
                    },
                },
                [breakpoint.down("xl")]: {
                    minHeight: 0,
                },
            },
            mt24: {
                marginTop: spacing(3),
            },
            mt0: {
                marginTop: -9,
            },
            infoTitle: {
                marginBottom: 2,
                color: palette.text.main,
            },
            description: {
                marginBottom: spacing(3),
                width: "100%",
                height: 94,
                color: palette.text.main,
                "-webkit-line-clamp": 4,
                "-webkit-box-orient": "vertical",
                display: "-webkit-box",
                overflowY: "hidden",
            },
            numberOfEmployees: {
                minHeight: 18,
            },
            rssButton: {
                border: "none",
                background: "transparent",
                color: palette.text.main,
                "&:hover": {
                    background: `${palette.primary.backgroundLight}!important`,
                    border: "none",
                },
                position: "relative",
            },
            actionItems: {
                paddingTop: spacing(3),
                borderTop: "1px solid #F0F1F3",
                display: "flex",
                justifyContent: "space-between",
            },
            arrowIcon: {
                marginLeft: spacing(1),
            },
            sectorTagsWrapper: {
                maxWidth: "calc(100% - 140px)",
                marginTop: spacing(2),
            },
            sectorsWrapper: {
                maxWidth: "300px",
                width: "100%",
            },
            enrichedCompany: {
                display: "flex",
                alignItems: "center",
                backgroundColor: palette.success.backgroundLight,
                padding: `${spacing(1)}px`,
                borderRadius: `${spacing(1)}px`,
            },
            enrichedCompanyInfo: {
                display: "flex",
            },
            checkEnrichedCompanyImg: {
                marginRight: `${spacing(1)}px`,
            },
            enrichedCompanyInfoTitle: {
                color: palette.success.dark,
            },
            scoreMatchWrap: {
                position: "absolute",
                top: 0,
                right: 142,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: -20,
                [breakpoint.down("xl")]: {
                    position: "relative",
                    top: "auto",
                    right: "auto",
                    marginTop: 0,
                    justifyContent: "flex-start",
                },
            },
            infoIconNew: {
                marginLeft: spacing(1),
            },
            info: {
                marginBottom: spacing(3),
                flex: "50%",
                [breakpoint.down("xl")]: {
                    marginBottom: 0,
                },
            },
            sdgTooltip: {
                textAlign: "center",
                color: palette.common.white,
            },
            sdgTooltipTitle: {
                fontWeight: "700",
            },
            infoTitleWrap: {
                display: "flex",
                [breakpoint.down("md")]: {
                    flexWrap: "wrap",
                },
            },
            contentBlock: {
                display: "flex",
                justifyContent: "space-between",
                marginTop: spacing(3),
            },
            flexColumn: {
                display: "flex",
                flexDirection: "column",
            },
            flexWrap: {
                display: "flex",
                flexWrap: "wrap",
            },
            likeIcon: {
                marginLeft: spacing(2),
                marginRight: spacing(1),
                [breakpoint.down("lg")]: {
                    marginLeft: 0,
                },
            },
            dislikeImg: {
                transform: "rotate(180deg)",
                marginLeft: spacing(2),
                marginRight: spacing(1),
            },
            enrichedCompanyHeader: {
                display: "none",
                alignItems: "center",
                padding: 0,
                borderRadius: `${spacing(1)}px`,
                [breakpoint.down("xl")]: {
                    display: "flex",
                },
            },
            downloadButton: {
                display: "flex",
                alignItems: "center",
                background: palette.success.backgroundLight,
                borderRadius: 100,
                padding: `4px ${spacing(2)}px 4px ${spacing(3)}px`,
                cursor: "pointer",
            },
            downloadIcon: {
                "& path": {
                    stroke: palette.common.black,
                },
            },
            header: {
                position: "relative",
                paddingLeft: spacing(2),
            },
            headerFiltersWrapper: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                flexWrap: "wrap",
            },
            toolbarRight: {
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
                [breakpoint.down("sm")]: {
                    order: 3,
                    marginLeft: 0,
                },
            },
            toolbarRightButtons: {
                display: "flex",
                alignItems: "center",
            },
            saveButton: {
                whiteSpace: "nowrap",
                marginRight: spacing(2),
                color: palette.common.black,
                cursor: "pointer",
                display: "flex",
                gap: 15,
                padding: `${spacing(1)}px ${spacing(3)}px`,
                alignItems: "center",
                borderRadius: "100px",
                background: "transparent",
                border: `1px solid ${palette.text.lightest}`,
                "& svg": {
                    filter: "grayscale(100%)",
                    opacity: 0.3,
                },
                "&:hover": {
                    background: palette.success.backgroundLight,
                    borderColor: "none",
                },
            },
            tableToolbar: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: "none",
                padding: spacing(2),
                [breakpoint.down("sm")]: {
                    order: 2,
                    width: "auto",
                    flexDirection: "row",
                },
            },
            tableCellCheckbox: {
                flex: 1,
                alignSelf: "flex-start",
                wordBreak: "break-word",
                maxWidth: 56,
                minWidth: 56,
                margin: "auto",
            },
        }),
);
