import React, { useState, useEffect, createRef } from "react";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import GrowSumo from "react-growsumojs";
import classnames from "classnames";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import FormControl from "@material-ui/core/FormControl";
import Link from "../../components/Link/Link";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useAuthHook } from "../../common/useAuthHook";
// Assets
import { ReactComponent as EyeIcon } from "../../assets/icons/EyeIconNew.svg";
import { ReactComponent as EyeIconActive } from "../../assets/icons/EyeIconNewActive.svg";
import { ReactComponent as Tick } from "../../assets/icons/Tick.svg";
// Redux
import { registerRequest, getMeRequest } from "../../store/auth/requests";
import { actions as authActions } from "../../store/auth/slice";
import {
    getRegisterStatusSelector,
    getRegisterMessageSelector,
    getRegisterFieldMessageSelector,
    getSubscribeStatusSelector,
} from "../../store/auth/selectors";
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
import { useSelector, useDispatch } from "react-redux";
// Constants
import {
    REQUEST_SUCCESS,
    REQUEST_PENDING,
    REQUEST_FAILURE,
} from "../../constants/statuses";

const SignUpForm = ({
    classNames,
    locationState,
    companyNameValueInvite,
    companyDisabledInvite,
    emailValueInvite,
    emailDisabledInvite,
    urlToken,
    ctaButtonCopy,
    showButtonLabel = true,
    emailLabel = "signUp.email",
    emailPlaceholder = "signUp.emailPlaceholder",
    signInLink,
    signUpForFreeTrial,
    openPage,
    isResults,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { getMeStatus, isAuthenticated } = useAuthHook();
    const { search, pathname } = useLocation();
    const registerStatus = useSelector(getRegisterStatusSelector);
    const registerMessage = useSelector(getRegisterMessageSelector);
    const registerFieldMessage = useSelector(getRegisterFieldMessageSelector);
    const subscribeStatus = useSelector(getSubscribeStatusSelector);
    const [nameValue, setNameValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [nameValid, setNameValid] = useState(true);
    const [emailDisabled, setEmailDisabled] = useState(false);
    const [passwordValue, setPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
    const [companyNameValue, setCompanyNameValue] = useState("");
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        process.env.REACT_APP_ENV === "prod" &&
            registerMessage &&
            reCaptchaRef.current.reset();
    }, [registerMessage]);

    const validateEmail = email => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const defaultSingInLink = "/sign-in";
    useEffect(() => {
        if (
            nameValue !== "" &&
            emailValue !== "" &&
            emailValid !== false &&
            passwordValue !== "" &&
            confirmPasswordValue !== "" &&
            passwordValue.length > 7 &&
            passwordValue === confirmPasswordValue &&
            checkboxChecked
        ) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [
        nameValue,
        emailValue,
        emailValid,
        passwordValue,
        confirmPasswordValue,
        checkboxChecked,
    ]);

    //Invitation fill form
    useEffect(() => {
        setEmailValue(emailValueInvite);
        setEmailDisabled(emailDisabledInvite);
        setCompanyNameValue(companyNameValueInvite);
    }, [
        companyNameValueInvite,
        companyDisabledInvite,
        emailValueInvite,
        emailDisabledInvite,
    ]);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const subscribe = query.get("subscribe");
        const yearly = query.get("yearly");
        const monthly = query.get("monthly");
        const currency = query.get("currency") || "";
        const signUpForFreeTrialUrlQuery = query.get("signUpForFreeTrial");
        if (registerStatus === REQUEST_SUCCESS) {
            dispatch(mixpanelTrackRequest({ eventName: "Sign up" }));
            if (process.env.REACT_APP_ENV !== "local") {
                //PartnerStack
                const customerData = {
                    name: nameValue,
                    email: emailValue,
                    customerKey: emailValue,
                };
                GrowSumo.setUserInfo(customerData);
                GrowSumo.createSignup();
            }
            let startFreeTrial = "";
            if (!!signUpForFreeTrialUrlQuery || signUpForFreeTrial) {
                dispatch(getMeRequest());
                if (getMeStatus === REQUEST_SUCCESS) {
                    startFreeTrial = "?startFreeTrial=true";
                    openPage("/search-type" + startFreeTrial);
                }
            } else {
                if (!!isAuthenticated) {
                    ctaButtonCopy === "signUp.goToPayment" &&
                        !!subscribe &&
                        getMeStatus === REQUEST_SUCCESS &&
                        openPage(
                            `/search-type?subscribe=${subscribe}&${
                                !!yearly
                                    ? `yearly=${yearly}`
                                    : `monthly=${monthly}`
                            }&currency=${currency}`,
                        );
                } else {
                    !(ctaButtonCopy === "signUp.goToPayment" && !!subscribe) &&
                        getMeStatus === REQUEST_FAILURE &&
                        openPage("/sign-in");
                }
            }
        }
    }, [registerStatus, getMeStatus, openPage, isAuthenticated]);

    const reCaptchaRef = createRef();

    const handleCaptchaChange = () => {
        !!isResults && dispatch(authActions.registerFromResults());
        let data = !!companyNameValue
            ? {
                  fullName: nameValue,
                  email: emailValue,
                  password: passwordValue,
                  repeatPassword: confirmPasswordValue,
                  companyName: companyNameValue,
                  signUpForFreeTrial: !!signUpForFreeTrial,
              }
            : {
                  fullName: nameValue,
                  email: emailValue,
                  password: passwordValue,
                  repeatPassword: confirmPasswordValue,
                  signUpForFreeTrial: !!signUpForFreeTrial,
              };
        if (urlToken) {
            data.token = urlToken;
        }
        dispatch(registerRequest({ data }));
    };

    const signUp = e => {
        e.preventDefault();
        if (process.env.REACT_APP_ENV === "prod") {
            reCaptchaRef.current.execute();
        } else {
            handleCaptchaChange();
        }
    };

    const handleEmailChange = e => {
        setEmailValue(e.target.value);
        setEmailValid(validateEmail(e.target.value));
    };

    const handleConfirmPasswordChange = e => {
        setConfirmPasswordValue(e.target.value);
    };

    const handlePasswordChange = e => {
        setPasswordValue(e.target.value);
    };

    const handleNameChange = e => {
        setNameValue(e.target.value);
        setNameValid(e.target.value.length > 1);
    };

    const handlePasswordFieldTypeChange = () => {
        setPasswordFieldType(!passwordFieldType);
    };
    const handlePasswordConfirmFieldTypeChange = () => {
        setPasswordConfirmFieldType(!passwordConfirmFieldType);
    };
    const [passwordFieldType, setPasswordFieldType] = useState(true);
    const [passwordConfirmFieldType, setPasswordConfirmFieldType] =
        useState(true);

    const handleCheckboxChange = e => {
        setCheckboxChecked(e.target.checked);
    };

    const iAgreeCheckboxLabel = classNames => (
        <div className={classNames.checkboxLabel}>
            <Typography
                variant="body2"
                className={classes.signInCopyText}
                color="light"
                component="span">
                {t("signUp.iAgreeLabel")}
            </Typography>
            <Link
                href="https://www.valuer.ai/terms-of-service"
                className={classes.signInCopyText}
                variant="body2"
                color="primary">
                {t("signUp.termsAndService")}
            </Link>
            <Typography
                variant="body2"
                color="light"
                className={classes.signInCopyText}
                component="span">
                {t("signUp.and")}
            </Typography>
            <Link
                href="https://www.valuer.ai/privacy-notice?hsLang=en"
                className={classes.signInCopyText}
                variant="body2"
                color="primary">
                {t("signUp.privacyPolicy")}
            </Link>
        </div>
    );

    const fromProjectsOrSH =
        locationState &&
        (locationState.from === "/projects" ||
            locationState.from === "/saved-searches");
    const isResultsPage = pathname === "/search-type";
    let regExp = /[A-Z]/;

    return (
        <form onSubmit={signUp} className={classNames.form}>
            <div className={classNames.formFieldsWrapper}>
                <FormControl className={classes.inputWrapper}>
                    <TextField
                        label={t(
                            fromProjectsOrSH ? "signUp.name2" : "signUp.name",
                        )}
                        placeholder={t("signUp.namePlaceholder")}
                        color="primary"
                        id="name"
                        InputLabelProps={{
                            required: true,
                            classes: {
                                root: classes.inputLabel,
                                shrink: classes.labelShrinked,
                                focused: classes.focusedLabel,
                            },
                        }}
                        value={nameValue}
                        onChange={handleNameChange}
                        error={!nameValid}
                        {...(!nameValid && {
                            helperText: t("signUp.minimum2Characters"),
                        })}
                        InputProps={{
                            classes: {
                                underline: classes.input,
                            },
                            fullWidth: true,
                        }}
                    />
                </FormControl>
                <FormControl className={classes.inputWrapper}>
                    <TextField
                        label={t(emailLabel)}
                        placeholder={t(emailPlaceholder)}
                        id="email"
                        InputLabelProps={{
                            required: true,
                            classes: {
                                root: classes.inputLabel,
                                shrink: classes.labelShrinked,
                                focused: classes.focusedLabel,
                            },
                        }}
                        value={emailValue}
                        disabled={emailDisabled}
                        onChange={handleEmailChange}
                        error={
                            !emailValid ||
                            (!!registerFieldMessage &&
                                !!registerFieldMessage.email &&
                                !!registerMessage)
                        }
                        {...(!emailValid
                            ? { helperText: t("signIn.emailError") }
                            : registerFieldMessage &&
                              registerFieldMessage.email &&
                              registerMessage && {
                                  helperText: registerFieldMessage.email[0],
                              })}
                        InputProps={{
                            classes: {
                                underline: classes.input,
                            },
                            fullWidth: true,
                        }}
                    />
                </FormControl>
                <FormControl className={classNames.passwordInputWrapper}>
                    <InputLabel
                        error={!!passwordValue && passwordValue.length < 8}
                        required={true}
                        classes={{
                            root: classes.inputLabel,
                            shrink: classes.labelShrinked,
                            focused: classes.focusedLabel,
                        }}
                        htmlFor="confirm-password">
                        {t("signUp.password")}
                    </InputLabel>
                    <Input
                        placeholder={t("signUp.passwordPlaceholder")}
                        id="password"
                        endAdornment={
                            passwordFieldType ? (
                                <EyeIcon
                                    className={classes.eye}
                                    onClick={handlePasswordFieldTypeChange}
                                />
                            ) : (
                                <EyeIconActive
                                    className={classes.eye}
                                    onClick={handlePasswordFieldTypeChange}
                                />
                            )
                        }
                        type={passwordFieldType ? "password" : "text"}
                        value={passwordValue}
                        onChange={handlePasswordChange}
                        className={classes.password}
                        classes={{
                            underline: classes.input,
                        }}
                        error={
                            (!!passwordValue && passwordValue.length < 8) ||
                            (!!registerFieldMessage &&
                                !!registerFieldMessage.password &&
                                !!registerMessage)
                        }
                    />
                    <div className={classNames.passwordHints}>
                        <Typography
                            variant="bodyXs"
                            color="black"
                            className={classnames([
                                classes.mono,
                                classes.passwordHint,
                                {
                                    [classes.passwordHintGreen]:
                                        passwordValue.length >= 8,
                                },
                            ])}
                            component="span">
                            <Tick className={classes.passwordHintTick} />
                            {t("signUp.8chars")}
                        </Typography>
                        <Typography
                            variant="bodyXs"
                            color="black"
                            className={classnames([
                                classes.mono,
                                classes.passwordHint,
                                {
                                    [classes.passwordHintGreen]:
                                        regExp.test(passwordValue),
                                },
                            ])}
                            component="span">
                            <Tick className={classes.passwordHintTick} />
                            {t("signUp.1uppercase")}
                        </Typography>
                        <Typography
                            variant="bodyXs"
                            color="black"
                            className={classnames([
                                classes.mono,
                                classes.passwordHint,
                                {
                                    [classes.passwordHintGreen]:
                                        passwordValue.toUpperCase() !==
                                        passwordValue,
                                },
                            ])}
                            component="span">
                            <Tick className={classes.passwordHintTick} />
                            {t("signUp.1lowercase")}
                        </Typography>
                    </div>
                    {!!passwordValue && passwordValue.length < 8 && (
                        <FormHelperText
                            className={classes.errorHelperText}
                            id="component-error-text">
                            {t("signUp.weakPassword", {
                                count: 8 - passwordValue.length,
                            })}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl className={classNames.confirmPasswordInputWrapper}>
                    <InputLabel
                        error={
                            !!confirmPasswordValue &&
                            passwordValue !== confirmPasswordValue
                        }
                        required={true}
                        classes={{
                            root: classes.inputLabel,
                            shrink: classes.labelShrinked,
                            focused: classes.focusedLabel,
                        }}
                        htmlFor="confirm-password">
                        {t("signUp.confirmPassword")}
                    </InputLabel>
                    <Input
                        placeholder={t("signUp.confirmPasswordPlaceholder")}
                        id="confirm-password"
                        endAdornment={
                            passwordConfirmFieldType ? (
                                <EyeIcon
                                    className={classes.eye}
                                    onClick={
                                        handlePasswordConfirmFieldTypeChange
                                    }
                                />
                            ) : (
                                <EyeIconActive
                                    className={classes.eye}
                                    onClick={
                                        handlePasswordConfirmFieldTypeChange
                                    }
                                />
                            )
                        }
                        type={passwordConfirmFieldType ? "password" : "text"}
                        value={confirmPasswordValue}
                        onChange={handleConfirmPasswordChange}
                        error={
                            !!confirmPasswordValue &&
                            passwordValue !== confirmPasswordValue
                        }
                        className={classes.password}
                        classes={{
                            underline: classes.input,
                        }}
                    />
                    {!!confirmPasswordValue &&
                        passwordValue !== confirmPasswordValue && (
                            <FormHelperText
                                className={classes.errorHelperText}
                                id="component-error-text">
                                {t("signUp.passwordMustMatch")}
                            </FormHelperText>
                        )}
                </FormControl>
                {/* <FormControl className={classes.inputWrapper}>
                    <TextField
                        label={t('signUp.companyName')}
                        id="company-name"
                        InputLabelProps={{ classes: { root: classes.inputLabel, shrink: classes.labelShrinked, focused: classes.focusedLabel } }}
                        value={companyNameValue}
                        onChange={handleCompanyNameChange}
                        error={!companyValid}
                        {...!companyValid && { helperText: t("signUp.minimum2Characters") }}
                        disabled={companyDisabled}
                        InputProps={{
                            classes: {
                                underline: classes.input
                            },
                            fullWidth: true
                        }}
                    />
                </FormControl> */}
                {process.env.REACT_APP_ENV === "prod" && (
                    <FormControl>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_CAPTCHA_APP_KEY}
                            size="invisible"
                            badge="bottomleft"
                            ref={reCaptchaRef}
                            onChange={handleCaptchaChange}
                        />
                    </FormControl>
                )}
                <FormControlLabel
                    classes={{ root: classes.formControlLabelRoot }}
                    control={
                        <Checkbox
                            value={checkboxChecked}
                            onChange={handleCheckboxChange}
                            classes={{ root: classNames.checkboxRoot }}
                            name="checkedA"
                        />
                    }
                    label={iAgreeCheckboxLabel(classNames)}
                    color="secondary"
                />
            </div>
            <div className={classNames.ctasWrapper}>
                <div className={classNames.createAccountButton}>
                    {registerFieldMessage &&
                        !registerFieldMessage.email &&
                        registerMessage && (
                            <Typography
                                component="div"
                                className={classes.formError}
                                variant="caption"
                                color="error">
                                {registerMessage[0]}
                            </Typography>
                        )}
                    <Button
                        variant={
                            isResultsPage ? "success" : "primaryDarkContained"
                        }
                        disabled={!formValid}
                        loading={
                            registerStatus === REQUEST_PENDING ||
                            subscribeStatus === REQUEST_PENDING
                        }
                        className={classNames.signUpBtn}
                        size="large">
                        {t(ctaButtonCopy)}
                    </Button>
                    {showButtonLabel && (
                        <>
                            <div className={classNames.createAccountButtonInfo}>
                                <Typography
                                    variant="bodyXs"
                                    color="textLight"
                                    component="span">
                                    {t("signUp.noCardReq")}
                                </Typography>
                                <div className={classes.greyCircle}></div>
                                <Typography
                                    variant="bodyXs"
                                    color="textLight"
                                    component="span">
                                    {t("signUp.14DayTrial")}
                                </Typography>
                            </div>
                        </>
                    )}
                </div>
                <div className={classNames.additionalCtasWrapper}>
                    <div className={classNames.signInCopy}>
                        <Typography
                            variant="body2"
                            color="grey"
                            className={classes.signInCopyText}
                            component="span">
                            {t("signUp.alreadyHaveAnAccount")}
                        </Typography>
                        <Link
                            href={signInLink || defaultSingInLink}
                            variant="body2"
                            className={classnames([
                                classes.signInCopyText,
                                classes.signInCopyLink,
                            ])}
                            color="primary">
                            {t("signUp.signInHere2")}
                        </Link>
                    </div>
                    {/* <div className={classNames.signInCopy}>
                        <Typography variant="body2" color="grey" className={classes.signInCopyText} component="span">{t('signUp.skipSignUp')}</Typography>
                        <Link href="/" variant="body2" className={classnames([classes.signInCopyText, classes.signInCopyLink])} color="primary">
                            {t('signUp.startSearch')}
                        </Link>
                    </div> */}
                </div>
            </div>
        </form>
    );
};

export default SignUpForm;
