import { useEffect, useRef, useState } from "react";
import { useAuthHook } from "../../../common/useAuthHook";
import { useSelector, useDispatch } from "react-redux";
// Redux
import {
    updateProfileRequest,
    getMeStealthRequest,
    getTeamRequest,
} from "../../../store/auth/requests";
import {
    getCreditsSelector,
    getSubscriptionSelector,
    getTeamSelector,
    getUpdateProfileStatusSelector,
    getFetchTeamStatusSelector,
    getUserStatusSelector,
    getSeatsAvailableSelector,
} from "../../../store/auth/selectors";
import { getInvoicesStatusSelector } from "../../../store/billing/selectors";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../../../constants/statuses";
import { useTranslation } from "react-i18next";
import { getBillingSelector } from "../../../store/billing/selectors";
import { useRouterHook } from "../../../common/useRouterHook";
import { fetchInvoicesRequest } from "../../../store/billing/requests";
import { getPricingPage } from "../../../constants/endpoints";

export const useProfileSettingsHook = () => {
    const { t } = useTranslation();
    const billings = useSelector(getBillingSelector);
    const [tabView, setTabView] = useState("profile");
    const { user } = useAuthHook();
    const dispatch = useDispatch();
    const team = useSelector(getTeamSelector);
    const inputFile = useRef(null);
    const subscription = useSelector(getSubscriptionSelector());
    const [showBreakdownModal, setShowBreakdownModal] = useState(false);
    const { openPage } = useRouterHook();
    const getCredits = useSelector(getCreditsSelector());
    const [credits, setCredits] = useState(0);
    const [fileUploadError, setFileUploadError] = useState(false);
    const updateProfileStatus = useSelector(getUpdateProfileStatusSelector);
    const seatsAvailable = useSelector(getSeatsAvailableSelector);
    const [changeName, setChangeName] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [changeCompanyName, setChangeCompanyName] = useState(false);
    const [changeCompanyCountry, setChangeCompanyCountry] = useState(false);
    const [changeCompanyCity, setChangeCompanyCity] = useState(false);
    const [changePhoneNumber, setChangePhoneNumber] = useState(false);
    const isOwner = user && user.team && user.team.role === "owner";
    const isOtherPlan =
        user &&
        user.subscription &&
        Object.keys(user.subscription).length !== 0 &&
        user.subscription.constructor === Object &&
        user.subscription &&
        user.subscription.name !== "pro" &&
        user.subscription &&
        user.subscription.name !== "teams";
    const shouldBeBuying = isOwner || isOtherPlan;
    const [profileName, setProfileName] = useState(
        user && user.name ? user.name : "",
    );
    const [profileEmail, setProfileEmail] = useState(
        user && user.email ? user.email : "",
    );
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordAgain, setNewPasswordAgain] = useState("");
    const [passwordFieldType, setPasswordFieldType] = useState([
        true,
        true,
        true,
    ]);
    const [companyProfileName, setCompanyProfileName] = useState(
        user && user.company ? user.company : "",
    );
    const [companyProfileCountry, setCompanyProfileCountry] = useState(
        user && user.company_country ? user.company_country : "",
    );
    const [companyProfileCity, setCompanyProfileCity] = useState(
        user && user.company_city ? user.company_city : "",
    );
    const [phoneNumber, setPhoneNumber] = useState(
        user && user.phone ? user.phone : "",
    );

    const getTeamStatusSelector = useSelector(getFetchTeamStatusSelector);
    const getUserStatus = useSelector(getUserStatusSelector);
    const getInvoicesStatus = useSelector(getInvoicesStatusSelector);
    const isLoading = () => {
        return (
            getUserStatus === REQUEST_PENDING ||
            getInvoicesStatus === REQUEST_PENDING ||
            getTeamStatusSelector === REQUEST_PENDING ||
            updateProfileStatus === REQUEST_PENDING
        );
    };

    const handleEditImage = () => {
        inputFile.current.click();
    };
    const onFileUploadChange = e => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        let f = files[0];
        let size = 2097152;
        let file_size = files[0].size;
        if (file_size >= size) {
            setFileUploadError(true);
            return false;
        }
        let formData = new FormData();
        formData.append("avatar", f);
        updateProfileImage(formData);
    };

    const removeImage = () => {
        let formData = new FormData();
        formData.append("avatar-r", 1);
        updateProfileImage(formData);
    };
    useEffect(() => {
        user && setCompanyProfileName(user.company);
        user && setCompanyProfileCountry(user.company_country);
        user && setCompanyProfileCity(user.company_city);
        user && setPhoneNumber(user.phone);
    }, [user]);
    const openBilling = () => {
        if (!user.subscription.free_trial) {
            dispatch(fetchInvoicesRequest());
        }
        openTab("billing");
    };
    const openTeam = () => {
        dispatch(getTeamRequest({ team: user.team.slug }));
        openTab("team");
    };
    const handleUpgradePlan = () => {
        window.location.href = getPricingPage(
            subscription && subscription.name,
        );
    };
    const handleGetNewSubscription = () => {
        window.location.href = getPricingPage();
    };
    const openTab = value => {
        setTabView(value);
    };
    const editData = value => {
        switch (value) {
            case "profileName":
                setChangeName(true);
                break;
            case "profileEmail":
                setChangeEmail(true);
                break;
            case "profilePassword":
                setChangePassword(true);
                break;
            case "companyName":
                setChangeCompanyName(true);
                break;
            case "companyCountry":
                setChangeCompanyCountry(true);
                break;
            case "companyCity":
                setChangeCompanyCity(true);
                break;
            case "phone":
                setChangePhoneNumber(true);
                break;
            default:
                break;
        }
    };
    const handleProfileName = event => {
        setProfileName(event.target.value);
    };
    const handleProfileEmail = event => {
        setProfileEmail(event.target.value);
    };
    useEffect(() => {
        setProfileName(user && user.name);
        setProfileEmail(user && user.email);
    }, [user]);
    useEffect(() => {
        getCredits
            ? setCredits(getCredits)
            : user &&
              user.subscription &&
              user.subscription.total_credits &&
              setCredits(user.subscription.total_credits);
    }, [getCredits, user]);

    useEffect(() => {
        if (updateProfileStatus === REQUEST_SUCCESS) {
            dispatch(getMeStealthRequest());
            setChangeName(false);
            setChangeEmail(false);
            setChangePassword(false);
            setChangeCompanyName(false);
            setChangeCompanyCountry(false);
            setChangeCompanyCity(false);
            setChangePhoneNumber(false);
        }
    }, [updateProfileStatus, dispatch]);

    const handleCurrentPassword = event => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPassword = event => {
        setNewPassword(event.target.value);
    };

    const handleNewPasswordAgain = event => {
        setNewPasswordAgain(event.target.value);
    };

    const handlePasswordFieldTypeChange = value => {
        switch (value) {
            case "currentPassword":
                setPasswordFieldType([!passwordFieldType[0], true, true]);
                break;
            case "newPassword":
                setPasswordFieldType([true, !passwordFieldType[1], true]);
                break;
            case "newPasswordAgain":
                setPasswordFieldType([true, true, !passwordFieldType[2]]);
                break;
            default:
                break;
        }
    };
    const handleCompanyProfileName = event => {
        setCompanyProfileName(event.target.value);
    };
    const handleCompanyProfileCountry = event => {
        setCompanyProfileCountry(event.target.value);
    };
    const handleCompanyProfileCity = event => {
        setCompanyProfileCity(event.target.value);
    };
    const handlePhoneNumber = event => {
        setPhoneNumber(event.target.value);
    };
    const updateProfileData = (value, payload) => {
        let data = new FormData();
        switch (value) {
            case "name":
                data.append("name", profileName);
                break;
            case "email":
                data.append("email", profileEmail);
                break;
            case "password":
                data.append("password-old", currentPassword);
                data.append("password", newPassword);
                data.append("password_confirmation", newPasswordAgain);
                break;
            case "companyName":
                data.append("company-name", companyProfileName);
                break;
            case "companyCountry":
                data.append("company-country", companyProfileCountry);
                break;
            case "companyCity":
                data.append("company-city", companyProfileCity);
                break;
            case "phone":
                data.append("phone", phoneNumber);
                break;
            case "favoriteIndustries":
                data.append(
                    "favorite-industries",
                    payload.length ? JSON.stringify(payload) : null,
                );
                break;
            default:
                break;
        }
        dispatch(updateProfileRequest({ data }));
    };
    const updateProfileImage = data => {
        dispatch(updateProfileRequest({ data }));
    };

    return {
        tabView,
        openTab,
        user,
        changeName,
        changeEmail,
        changePassword,
        changeCompanyName,
        changeCompanyCountry,
        changeCompanyCity,
        changePhoneNumber,
        editData,
        profileName,
        handleProfileName,
        profileEmail,
        handleProfileEmail,
        currentPassword,
        handleCurrentPassword,
        newPassword,
        handleNewPassword,
        newPasswordAgain,
        handleNewPasswordAgain,
        passwordFieldType,
        handlePasswordFieldTypeChange,
        companyProfileName,
        handleCompanyProfileName,
        companyProfileCountry,
        handleCompanyProfileCountry,
        companyProfileCity,
        phoneNumber,
        handleCompanyProfileCity,
        handlePhoneNumber,
        updateProfileData,
        setChangeName,
        setChangeEmail,
        setChangePassword,
        setChangeCompanyName,
        setChangeCompanyCountry,
        setChangeCompanyCity,
        setChangePhoneNumber,
        updateProfileImage,
        setCompanyProfileName,
        setCompanyProfileCountry,
        setCompanyProfileCity,
        t,
        billings,
        team,
        inputFile,
        subscription,
        showBreakdownModal,
        setShowBreakdownModal,
        handleEditImage,
        openPage,
        getCredits,
        credits,
        setCredits,
        fileUploadError,
        onFileUploadChange,
        removeImage,
        openBilling,
        openTeam,
        handleUpgradePlan,
        handleGetNewSubscription,
        isOwner,
        isOtherPlan,
        shouldBeBuying,
        isLoading,
        seatsAvailable,
    };
};
