import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        main: {
            background: palette.common.white,
            margin: `0 auto`,
            padding: spacing(4),
            maxWidth: "1136px",
            display: "flex",
            flexDirection: "column",
            [breakpoint.down("md")]: {
                padding: `${spacing(3)}px 0`,
            },
        },
        headerTitle: {
            marginBottom: `${spacing(1)}px`,
            display: "flex",
            flexDirection: "column",
        },
        companiesButton: {
            marginRight: spacing(1),
        },
        headerInput: {
            width: "100%",
            "& input": {
                width: 177,
            },
        },
        tabs: {
            minWidth: 445,
            display: "flex",
            justifyContent: "center",
        },
        tabsWrapper: {
            position: "relative",
            marginBottom: spacing(2),
            maxWidth: "100vw",
            overflow: "auto",
            display: "flex",
            [breakpoint.down("md")]: {
                padding: `0 ${spacing(2)}px`,
            },
            [breakpoint.down("sm")]: {
                justifyContent: "flex-start",
                paddingLeft: spacing(2),
            },
            "&::-webkit-scrollbar": {
                display: "none",
                "-ms-overflow-style": "none",
                scrollbarWidth: "none",
            },
        },
        tab: {
            color: palette.text.light,
            minWidth: 76,
            marginRight: 58,
            cursor: "pointer",
            textAlign: "center",
            borderBottom: "2px solid transparent",
            paddingBottom: spacing(2),
            marginTop: spacing(2),
        },
        activeTab: {
            color: palette.common.white,
            // minWidth: 76,
            // marginRight: 58,
            background: `${palette.secondary.dark}!important`,
        },
        refineAndSearch: {
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            maxWidth: 360,
            [breakpoint.down("md")]: {
                width: "100%",
                maxWidth: "100%",
            },
        },
        startEndornment: {
            position: "absolute",
            right: `${spacing(1)}px`,
        },
        arrowIcon: {
            marginRight: `${spacing(1)}px`,
            cursor: "pointer",
            transform: "rotate(0deg) scale(1.4)",
        },
        rotateIcon: {
            transform: "rotate(180deg) scale(1.4)",
        },
        headerSortAndFilter: {
            display: "inline-flex",
            flexDirection: "row",
            minWidth: 207,
            width: "100%",
            [breakpoint.down("md")]: {
                marginTop: spacing(1),
            },
        },
        sortingButton: {
            background: palette.common.white,
            borderTopLeftRadius: 24,
            borderBottomLeftRadius: 24,
            padding: "7px 8px",
            display: "flex",
            border: `1px solid ${palette.text.lightest}`,
            borderRight: 0,
            cursor: "pointer",
        },
        sortingIcon: {
            width: 24,
            height: 24,
        },
        rotateSortingButton: {
            transform: "rotate(180deg)",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 24,
            borderBottomRightRadius: 24,
            borderLeft: 0,
            borderRight: `1px solid ${palette.text.lightest}`,
        },
        multiselectAdjust: {
            width: 236,
            [breakpoint.down("md")]: {
                width: `auto`,
            },
        },
        arrowDown: {
            width: 12,
            position: "absolute",
            right: spacing(2),
            zIndex: 2,
            cursor: "pointer",
        },
        avatar: {
            width: 40,
            height: 40,
            borderRadius: spacing(1),
            background: palette.primary.backgroundLight,
            fontSize: 23,
            lineHeight: "32px",
            color: palette.primary.dark,
            fontFamily: "GT Flexa Mono, sans-serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: spacing(2),
            [breakpoint.down("md")]: {
                display: "none",
            },
        },
        mono: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        avatarCell: {
            display: "flex",
            alignItems: "center",
        },
        table: {
            [breakpoint.down("md")]: {
                borderTop: `1px solid ${palette.text.backgroundMain}`,
            },
        },
        tableContainer: {
            overflow: "visible",
        },
        tableRow: {
            boxShadow: "inset 0px -1px 0px #EFEFEF",
            [breakpoint.down("md")]: {
                display: "flex",
                flexDirection: "column",
                padding: `${spacing(2)}px 0`,
            },
        },
        cellDate: {
            [breakpoint.down("md")]: {
                justifyContent: "space-between",
            },
        },
        cellDateMulti: {
            [breakpoint.down("md")]: {
                paddingLeft: `7px !important`,
            },
        },
        cellDateMargin: {
            [breakpoint.down("md")]: {
                marginLeft: 42,
            },
        },
        cellCheckbox: {
            marginRight: spacing(2),
            paddingLeft: 0,
            paddingRight: 0,
        },
        companiesLabel: {
            paddingLeft: spacing(4),
        },
        orderedLabel: {
            display: "none",
            [breakpoint.down("md")]: {
                display: "block",
            },
        },
        cellRight: {
            [breakpoint.down("md")]: {
                alignItems: "flex-end",
                display: "flex",
                flexDirection: "column",
            },
        },
        cell: {
            padding: `20px ${spacing(2)}px`,
            border: "none",
            [breakpoint.down("md")]: {
                display: "flex",
                padding: `0 ${spacing(2)}px ${spacing(1)}px ${spacing(2)}px`,
            },
        },
        tableHead: {
            [breakpoint.down("md")]: {
                display: "none",
            },
        },
        sortedBy: {
            marginRight: spacing(1),
        },
        header: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: spacing(2),
            [breakpoint.down("md")]: {
                flexDirection: "column",
                alignItems: "flex-start",
                padding: `0 ${spacing(2)}px`,
            },
        },
        headerWrapper: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
        },
        filterWrap: {
            marginLeft: `${spacing(2)}px`,
            display: "flex",
            alignItems: "center",
            [breakpoint.down("md")]: {
                marginLeft: 0,
            },
        },
        noResults: {
            marginTop: spacing(8),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        noResultsTitle: {
            maxWidth: 464,
            marginTop: spacing(4),
        },
        moreButton: {
            display: "none",
            minWidth: 0,
            padding: 0,
            [breakpoint.down("md")]: {
                display: "block",
            },
        },
        moreButtonActionBox: {
            display: "none",
            [breakpoint.down("md")]: {
                transform: "rotate(90deg)",
                display: "block",
            },
        },
        lastCell: {
            display: "flex",
            width: 112,
            [breakpoint.down("md")]: {
                display: "none",
                width: 0,
            },
        },
        moreIcon: {
            fill: palette.secondary.dark,
        },
        moreIconOpened: {
            fill: palette.primary.dark,
        },
        addToProjectWrapper: {
            position: "relative",
        },
        addPopup: {
            zIndex: 2,
            width: 235,
            maxHeight: 202,
            background: palette.common.white,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: spacing(2),
            border: "1px solid",
            borderColor: palette.text.backgroundDark,
            top: 40,
            right: 0,
            overflow: "auto",
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            "&::-webkit-scrollbar": {
                width: 2,
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: palette.text.main,
                borderRadius: 0,
            },
        },
        projectNameWrapper: {
            width: "100%",
            display: "flex",
            padding: `${spacing(2)}px ${spacing(3)}px`,
            borderBottom: `1px solid ${palette.text.backgroundLight}`,
            color: palette.text.light,
            "&:hover": {
                background: palette.text.backgroundLight,
            },
        },
        blackProjectName: {
            color: palette.common.black,
            cursor: "default",
        },
        addedToProject: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
        },
        projectName: {
            display: "flex",
            alignItems: "center",
            color: palette.text.light,
        },
        createNewProject: {
            display: "flex",
            justifyContent: "center",
        },
        name: {
            cursor: "pointer",
            "&:hover": {
                textDecoration: "underline",
            },
        },
        actionBox: {
            background: palette.success.backgroundLight,
            borderRadius: spacing(2),
            padding: `${spacing(3)}px ${spacing(2)}px`,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            [breakpoint.down("md")]: {
                background: "transparent",
            },
        },
        mergeButtonNotClickable: {
            paddingRight: 0,
        },
        checkBoxLabel: {
            marginLeft: spacing(1),
        },
        selectedRow: {
            background: palette.primary.backgroundMedium,
        },
        actionButtonsRight: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "42px",

            [breakpoint.down("md")]: {
                display: "none",
            },
        },
        relative: {
            position: "relative",
        },
        loaderWrapper: {
            width: "100%",
        },
        downloadIcon: {
            "& path": {
                stroke: palette.common.black,
            },
            verticalAlign: "middle",

            [breakpoint.down("md")]: {
                display: "none",
            },
        },
        downloadIconNotClickable: {
            marginRight: spacing(3),
            "& path": {
                stroke: palette.grey[500],
            },
        },
        downloadButton: {
            textTransform: "capitalize",
            display: "block",
            "& svg": {
                verticalAlign: "middle",
            },
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        flexRow: {
            display: "flex",
            flexDirection: "row",
        },
        downloadButtonComponent: {
            marginRight: spacing(2),
        },
    }),
);
