import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, typography, breakpoint }) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    paperAnchorLeft: {
        top: "56px !important",
    },
    wrapper: {
        padding: `${spacing(3)}px`,
    },
    headerFiltersMain: {
        [breakpoint.down("sm")]: {
            order: 1,
            width: "100%",
        },
    },
    content: {
        flexGrow: 1,
        minHeight: "100vh",
        backgroundColor: palette.grey.light,
        position: "relative",
        width: "calc(100% - 236px)",
        marginLeft: 236,
        marginTop: spacing(6),
        [breakpoint.down("lg")]: {
            width: "100%",
            marginLeft: 0,
        },
    },
    moreIcon: {
        fill: palette.secondary.dark,
    },
    moreIconOpened: {
        fill: palette.primary.dark,
    },
    moreButton: {
        minWidth: 0,
        padding: 0,
        [breakpoint.up("md")]: {
            display: "none",
        },
    },
    // headerLabel: {
    //     color: palette.common.black,
    //     marginBottom: 8
    // },
    headerInput: {
        "& input": {
            width: 167,
        },
        [breakpoint.up("sm")]: {
            width: "100%",
        },
    },
    filterButton: {
        position: "relative",
        background: `${palette.common.white} !important`,
        border: `1px solid ${palette.text.lightest}`,
        padding: `11px 23px`,
        borderRadius: 24,
        marginLeft: `${spacing(2)}px`,
        display: "flex",
        alignItems: "center",
        height: 40,
        "&:hover": {
            background: `${palette.primary.backgroundLight}!important`,
            cursor: "pointer",
        },
        [breakpoint.down("lg")]: {
            marginLeft: spacing(1),
        },
        marginBottom: 0,
    },
    filterButtonFull: {
        border: `1px solid ${palette.common.black}`,
    },
    displayedFiltersCount: {
        position: "absolute",
        top: -1,
        right: -1,
        background: palette.primary.dark,
        borderRadius: "50%",
        width: 16,
        height: 16,
        color: palette.common.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...typography.bodyXs,
    },
    sortingButton: {
        background: palette.common.white,
        borderTopLeftRadius: 24,
        borderBottomLeftRadius: 24,
        padding: "7px 8px",
        display: "flex",
        border: `1px solid ${palette.text.lightest}`,
        borderRight: 0,
        cursor: "pointer",
    },
    sortingIcon: {
        width: 24,
        height: 24,
    },
    rotateSortingButton: {
        transform: "rotate(180deg)",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 24,
        borderBottomRightRadius: 24,
        borderLeft: 0,
        borderRight: `1px solid ${palette.text.lightest}`,
    },
    filterIcon: {
        "& > path": {
            stroke: palette.text.lighter,
        },
    },
    filterIconFull: {
        "& > path": {
            stroke: palette.primary.dark,
        },
    },
    resultsTotal: {
        color: palette.text.light,
        marginLeft: spacing(1),
    },
    headerTitle: {
        marginLeft: "28px",
        marginBottom: `${spacing(1)}px`,
        display: "flex",
        justifyContent: "space-between",
    },
    header: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        [breakpoint.down("md")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    headerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "65px",
    },
    filterWrap: {
        marginLeft: `${spacing(2)}px`,
        display: "flex",
        alignItems: "center",
        [breakpoint.down("md")]: {
            marginLeft: 0,
        },
    },
    headerSortAndFilter: {
        display: "inline-flex",
        flexDirection: "row",
        // [breakpoint.down("md")]: {
        //     marginTop: spacing(1)
        // }
    },
    headerSortAndFilterLiked: {
        [breakpoint.down("md")]: {
            marginTop: 0,
        },
    },
    refineAndSearch: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        maxWidth: 200,
        // [breakpoint.down("md")]: {
        //     width: "100%",
        //     maxWidth: "100%"
        // }
    },
    refineAndSearchWide: {
        maxWidth: 476,
        width: 476,
        [breakpoint.down("md")]: {
            maxWidth: 200,
            width: "100%",
        },
    },
    sidebarRoot: {
        height: "calc(100% - 56px)",
        boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.1)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: 236,
        backgroundColor: palette.common.white,
        position: "fixed",
        left: 0,
        top: spacing(6),
        bottom: 0,
        [breakpoint.down("lg")]: {
            right: 0,
            width: "100%",
            zIndex: 3,
            display: "none",
        },
    },
    mobileOpened: {
        display: "block",
    },
    additionalSidebarMenu: {
        [breakpoint.down("sm")]: {
            backgroundColor: palette.common.white,
            position: "fixed",
            left: 0,
            right: 0,
            top: spacing(6),
            bottom: 0,
            width: "100%",
            zIndex: 4,
            height: "calc(100% - 56px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    mainSidebar: {
        paddingTop: 13,
    },
    creditsSidebarMenu: {
        [breakpoint.down("sm")]: {
            backgroundColor: palette.common.white,
            position: "fixed",
            left: 0,
            right: 0,
            top: spacing(6),
            bottom: 0,
            width: "100%",
            zIndex: 4,
            height: "calc(100% - 56px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    startSearchWrapper: {
        padding: `${spacing(3)}px ${spacing(2)}px`,
    },
    navigationItem: {
        color: palette.text.main,
        padding: `${spacing(3)}px ${spacing(4)}px`,
        position: "relative",
        fontFamily: "GT Flexa, sans-serif",
        fontSize: 16,
        lineHeight: "22px",
        "&:disabled": {
            color: palette.text.lighter,
            cursor: "default",
            "& > svg": {
                stroke: palette.text.lighter,
                marginRight: spacing(2),
            },
        },
        "&.active": {
            color: palette.primary.dark,
        },
        "&:hover": {
            "& > svg": {
                stroke: palette.primary.dark,
            },
        },
        [breakpoint.down("sm")]: {
            padding: `13px ${spacing(4)}px`,
            height: "auto",
        },
    },
    profileNavigation: {
        [breakpoint.down("sm")]: {
            fontFamily: "GT Flexa Mono, sans-serif",
            ...typography.bodyButton,
        },
    },
    isCreditsNavigation: {
        [breakpoint.down("sm")]: {
            color: palette.text.light,
            fontFamily: "GT Flexa Mono, sans-serif",
            ...typography.bodyButton,
        },
    },
    logoutNavigation: {
        [breakpoint.down("sm")]: {
            color: palette.error.main,
            fontFamily: "GT Flexa Mono, sans-serif",
            ...typography.bodyXs,
        },
    },
    linkResources: {
        color: palette.text.main,
        padding: `${spacing(3)}px ${spacing(4)}px`,
        fontFamily: "GT Flexa, sans-serif",
        fontSize: 16,
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            color: palette.primary.dark,
            "& > svg": {
                stroke: palette.primary.dark,
            },
        },
        "& div": {
            marginLeft: spacing(2),
        },
        "& > svg": {
            stroke: palette.text.lighter,
        },
    },
    topNav: {
        height: 56,
        width: "100%",
        background: palette.common.white,
        padding: `9px ${spacing(4)}px`,
        zIndex: 1202,
        boxShadow: "0px 1px 1px 0px rgb(0 0 0 / 12%)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "fixed",
        [breakpoint.down("sm")]: {
            padding: `9px ${spacing(2)}px`,
        },
    },
    avatarIcon: {
        position: "relative",
    },
    logoutMenuItem: {
        position: "absolute",
        zIndex: 1203,
        top: 37,
        right: 0,
        background: palette.common.white,
        borderRadius: spacing(1),
        border: `1px solid ${palette.text.backgroundDark}`,
        fontFamily: "GT Flexa Mono, sans-serif",
        color: palette.error.main,
        padding: `${spacing(2)}px ${spacing(3)}px`,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        ...typography.bodyXs,
        "&:hover": {
            background: palette.common.white,
            textDecoration: "underline",
        },
    },
    logoTopNav: {
        paddingTop: "6px",
        height: 32,
    },
    divider: {
        background: palette.text.backgroundMain,
        width: "100%",
        height: 1,
        borderRadius: 1,
        margin: `${spacing(3)}px 0`,
    },
    avatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        cursor: "pointer",
    },
    topNavItem: {
        marginLeft: spacing(2),
        cursor: "pointer",
    },
    userSection: {
        padding: `${spacing(2)}px 0`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [breakpoint.down("sm")]: {
            padding: 0,
        },
    },
    logOut: {
        margin: `${spacing(3)}px 0`,
    },
    startEndornment: {
        position: "absolute",
        right: `${spacing(1)}px`,
    },
    arrowIcon: {
        marginRight: `${spacing(1)}px`,
        cursor: "pointer",
        transform: "rotate(0deg) scale(1.4)",
    },
    rotateIcon: {
        transform: "rotate(180deg) scale(1.4)",
    },
    inviteIcon: {
        marginRight: spacing(1),
        cursor: "pointer",
    },
    searchImage: {
        marginRight: spacing(3),
    },
    editSearchBtn: {
        border: "none",
        background: "transparent",
        marginRight: spacing(2),
        height: spacing(5),
        "&:hover": {
            background: palette.primary.backgroundLight,
            border: "none",
        },
    },
    startSearchBtn: {
        background: palette.primary.dark,
    },
    multiselectAdjust: {
        width: 166,
        [breakpoint.down("md")]: {
            width: `auto`,
        },
        // border: "none",
        // background: "transparent",
        // borderRadius: spacing(3),
        // "&:hover": {
        //     background: palette.primary.backgroundLight,
        //     border: "none"
        // }
    },
    sortedBy: {
        marginRight: spacing(1),
    },
    creditsIcon: {
        width: 17,
        height: 14,
    },
    creditsIconWrapper: {
        width: 32,
        height: 32,
        background: palette.success.backgroundLight,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    creditsWrapper: {
        display: "flex",
        alignItems: "center",
        fontFamily: "GT Flexa Mono, sans-serif",
    },
    mono: {
        fontFamily: "GT Flexa Mono, sans-serif",
    },
    creditsLeftText: {
        display: "flex",
        alignItems: "center",
        marginRight: spacing(3),
        cursor: "pointer",
    },
    creditsLeftTextDisabled: {
        cursor: "default",
    },
    creditsText: {
        margin: `0 ${spacing(1)}px`,
    },
    arrowDown: {
        width: 12,
        position: "absolute",
        right: spacing(2),
        zIndex: 2,
        cursor: "pointer",
    },
    creditsDropdown: {
        background: palette.common.white,
        border: `1px solid ${palette.text.backgroundDark}`,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: spacing(1),
        position: "absolute",
        top: 32,
        right: 16,
        minWidth: 200,
    },
    dropdown: {
        position: "relative",
    },
    creditsReplenishText: {
        textAlign: "center",
        borderBottom: `1px solid ${palette.text.backgroundLight}`,
        padding: `${spacing(3)}px ${spacing(3)}px ${spacing(2)}px ${spacing(
            3,
        )}px`,
    },
    getMoreCreditsText: {
        padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px ${spacing(
            3,
        )}px`,
    },
    getMoreCreditsTextWIthoutBilling: {
        padding: `${spacing(3)}px`,
    },
    getMoreCredits: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    avatarWrapper: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: palette.success.backgroundLight,
        marginLeft: spacing(2),
    },
    avatarSidebar: {
        width: 24,
        height: 24,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: palette.success.backgroundLight,
        marginRight: spacing(2),
    },
    inviteUsers: {
        cursor: "pointer",
        marginLeft: spacing(1),
    },
    tagWrapper: {
        marginTop: -12,
        padding: "0 12px",
        background: palette.common.white,
    },
    disabledNavigationItem: {
        color: palette.text.lighter,
        cursor: "default",
        padding: "24px 32px",
        width: "100%",
        height: 70,
        margin: 0,
        zIndex: 1,
        maxHeight: 70,
        lineHeight: "22px",
    },
    tooltipContent: {
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        verticalAlign: "top",
        maxHeight: 24,
        minWidth: 160,
    },
    disabledNavigationItemIcon: {
        stroke: palette.text.lighter,
        marginRight: 16,
    },
    tagBorder: {
        borderTop: `1px solid ${palette.text.backgroundMain}`,
        margin: "24px 12px 12px 12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    upgradeBox: {
        background: palette.primary.backgroundMedium,
        borderRadius: spacing(3),
        padding: `${spacing(4)}px ${spacing(3)}px`,
        margin: `${spacing(3)}px ${spacing(3)}px ${spacing(1)}px ${spacing(
            3,
        )}px`,
        [breakpoint.down("lg")]: {
            background: "transparent",
            padding: 0,
            marginBottom: 0,
            marginTop: 0,
        },
    },
    creditsBox: {
        marginBottom: spacing(3),
        marginLeft: 20,
    },
    creditsSummaryBox: {
        marginBottom: spacing(3),
        marginLeft: 20,
    },
    upgradeCta: {
        marginTop: spacing(2),
        textDecoration: "underline",
        cursor: "pointer",
        textAlign: "center",
        fontFamily: "GT Flexa Mono, sans-serif",
        [breakpoint.down("lg")]: {
            textAlign: "left",
            marginLeft: 50,
            marginTop: 0,
        },
        [breakpoint.down("sm")]: {
            marginTop: spacing(2),
        },
    },
    profileArrow: {
        position: "absolute",
        right: 40,
    },
    upgradeText: {
        [breakpoint.down("lg")]: {
            display: "none",
        },
    },
    "@media screen and (max-height: 700px)": {
        removeOnSmallHeight: {
            display: "none",
        },
    },
    ml8: {
        marginLeft: spacing(1),
    },
    burgerMenu: {
        alignSelf: "center",
        cursor: "pointer",
    },
    goBack: {
        padding: `${spacing(3)}px`,
    },
    authenticationButtons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    signInButtonSidebar: {
        marginRight: spacing(3),
    },
    rightSideMenu: {
        display: "flex",
        alignItems: "center",
    },
    sidebarBottomArea: {
        position: "fixed",
        bottom: 0,
        left: 0,
        width: 235,
        paddingBottom: spacing(3),
        [breakpoint.down("lg")]: {
            right: 0,
            width: "auto",
        },
    },
    shareButton: {
        background: palette.secondary.dark,
        border: "none",
        color: palette.common.white,
        height: 40,
        "&:hover": {
            background: palette.secondary.dark,
            border: "none",
        },
        marginLeft: spacing(2),
    },
    shareIcon: {
        marginLeft: spacing(1),
    },
    buttonWrapper: {
        position: "relative",
    },
    copyClipboard: {
        position: "absolute",
        top: -45,
        background: palette.secondary.dark,
        color: palette.common.white,
        borderRadius: spacing(1),
        display: "flex",
        alignItems: "center",
        left: 0,
        zIndex: 9999,
        padding: spacing(1),
        width: 160,
    },
    checkImg: {
        marginRight: spacing(1),
    },
    hiddenInput: {
        position: "absolute",
        left: 0,
        top: -30,
        width: 300,
    },
    upgradeYourPlan: {
        color: palette.primary.dark,
        cursor: "pointer",
        textDecoration: "underline",
        padding: `${spacing(4)}px ${spacing(3)}px`,
    },
    headerTabIcon: {
        marginRight: spacing(1),
    },
    headerTabIconRight: {
        marginLeft: spacing(1),
    },
    headerTab: {
        background: palette.common.white,
        border: `1px solid ${palette.text.lightest}`,
        borderRadius: spacing(3),
        padding: `${spacing(1)}px ${spacing(3)}px`,
        marginBottom: 0,
        alignItems: "center",
        display: "flex",
        cursor: "pointer",
        color: palette.common.black,
        "& svg path": {
            stroke: palette.text.lighter,
        },
        "&:hover": {
            background: palette.success.backgroundLight,
            borderRadius: spacing(3),
        },
        "&:hover svg path": {
            stroke: palette.common.black,
        },
    },
    clusterTab: {
        marginLeft: spacing(2),
        "&:hover": {
            cursor: "pointer",
        },
    },
    clusterTabHover: {
        background: palette.success.backgroundLight,
        borderTopRightRadius: "30px",
        borderTopLeftRadius: "30px",
        borderBottomLeftRadius: "30px",
        borderBottomRightRadius: "30px",
    },
    clasterTabActive: {
        marginBottom: -40,
        borderTopRightRadius: "30px",
        borderTopLeftRadius: "30px",
        background: palette.success.backgroundLight,
    },
    greenConnector: {
        marginTop: "-20px",
        height: "20px",
        background: "#E6F2EF",
    },
    headerTabActive: {
        background: palette.success.backgroundLight,
        paddingBottom: 30,
        color: palette.common.black,
        marginBottom: -24,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        border: "none",
        "& svg path": {
            stroke: palette.common.black,
        },
        "&:hover": {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
    },
    headerLeftPart: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        [breakpoint.down("md")]: {
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginBottom: spacing(1),
        },
    },
    headerLeftPartLiked: {
        [breakpoint.down("md")]: {
            flexDirection: "row",
        },
    },
    downloadChartWrapper: {
        display: "flex",
    },
    viewSwitcherWrapper: {
        marginRight: spacing(1),
        display: "flex",
        alignItems: "center",
    },
    inactiveViewIcon: {
        fill: palette.common.white,
        stroke: palette.text.lighter,
        cursor: "pointer",
        marginLeft: spacing(1),
    },
    activeViewIcon: {
        fill: palette.success.backgroundLight,
        stroke: palette.common.black,
        marginLeft: spacing(1),
    },
}));
