import { all, takeEvery } from "redux-saga/effects";
import {
    fetchProjectsRequest,
    createProjectRequest,
    updateProjectRequest,
    toggleProjectRequest,
    addItemToProjectRequest,
    fetchProjectRequest,
    deleteCompanyRequest,
    enrichCompanyRequest,
    checkProjectNameRequest,
    checkProjectsRequest,
    deleteProjectRequest,
} from "../requests";
import {
    fetchProjects,
    createProject,
    updateProject,
    toggleProject,
    addItemToProject,
    fetchProject,
    deleteCompany,
    enrichCompany,
    checkProjectsTitles,
    checkProjects,
    deleteProject,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchProjects() {
    yield takeEvery(convertTypeFromAction(fetchProjectsRequest), () =>
        fetchProjects(),
    );
}

function* watchFetchProject() {
    yield takeEvery(
        convertTypeFromAction(fetchProjectRequest),
        ({ payload: { slug, data } }) => fetchProject(slug, data),
    );
}

function* watchCreateProjects() {
    yield takeEvery(
        convertTypeFromAction(createProjectRequest),
        ({ payload: { items, projectTitle, projectDescription, type } }) =>
            createProject(items, projectTitle, projectDescription, type),
    );
}

function* watchUpdateProjects() {
    yield takeEvery(
        convertTypeFromAction(updateProjectRequest),
        ({ payload: { oldProjectSlug, projectTitle, projectDescription } }) =>
            updateProject(oldProjectSlug, projectTitle, projectDescription),
    );
}

function* watchToggleProjects() {
    yield takeEvery(
        convertTypeFromAction(toggleProjectRequest),
        ({ payload: { projectSlug } }) => toggleProject(projectSlug),
    );
}

function* watchAddItemToProject() {
    yield takeEvery(
        convertTypeFromAction(addItemToProjectRequest),
        ({ payload: { project, items, type } }) =>
            addItemToProject(project, items, type),
    );
}

function* watchDeleteCompany() {
    yield takeEvery(
        convertTypeFromAction(deleteCompanyRequest),
        ({ payload: { companyId, projectSlug } }) =>
            deleteCompany(companyId, projectSlug),
    );
}

function* watchEnrichCompany() {
    yield takeEvery(
        convertTypeFromAction(enrichCompanyRequest),
        ({ payload: { cId, slug } }) => enrichCompany(cId, slug),
    );
}
function* watchCheckProjectTitle() {
    yield takeEvery(
        convertTypeFromAction(checkProjectNameRequest),
        ({ payload: { project, title } }) =>
            checkProjectsTitles(project, title),
    );
}
function* watchCheckProjects() {
    yield takeEvery(
        convertTypeFromAction(checkProjectsRequest),
        ({ payload: { companyId, type } }) => checkProjects(companyId, type),
    );
}
function* watchDeleteProject() {
    yield takeEvery(
        convertTypeFromAction(deleteProjectRequest),
        ({ payload: { slug } }) => deleteProject(slug),
    );
}

function* saga() {
    yield all([
        watchFetchProjects(),
        watchCreateProjects(),
        watchToggleProjects(),
        watchUpdateProjects(),
        watchAddItemToProject(),
        watchFetchProject(),
        watchDeleteCompany(),
        watchEnrichCompany(),
        watchCheckProjectTitle(),
        watchCheckProjects(),
        watchDeleteProject(),
    ]);
}

export { saga };
