import axiosInstance from "../axiosInstance";
import { getCompanyUrl, getCompanies } from "../../constants/endpoints";

class UserConversationService {
    async getCompany(slug, cid) {
        try {
            const res = await axiosInstance.get(
                getCompanyUrl(`${slug}?cid=${cid}`),
            );
            return res.data.data;
        } catch (error) {
            if (process.env.REACT_APP_ENV !== "prod") {
                console.log(error);
            }
        }
    }

    async getCompanies(ids) {
        try {
            const res = await axiosInstance.get(getCompanies(ids));
            return res.data.data;
        } catch (error) {
            if (process.env.REACT_APP_ENV !== "prod") {
                console.log(error);
            }
        }
    }
}

export default UserConversationService;
