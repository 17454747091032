import React, { useEffect, useState } from "react";
import classnames from "classnames";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
//Components
import Typography from "../../../components/Typography/Typography";
//Redux
import { actions as technologiesAction } from "../../../store/technologies/slice";
import { useDispatch, useSelector } from "react-redux";
import { getTechnologyStatusSelector } from "../../../store/technologies/selectors";
//Constants
import { REQUEST_SUCCESS } from "../../../constants/statuses";
import { stateToHTML } from "draft-js-export-html";
import { EditorState, convertFromRaw } from "draft-js";

const TechnologyOverview = ({ technology }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const getTechnologyStatus = useSelector(getTechnologyStatusSelector);
    const [descHeights, setDescHeights] = useState({});
    const [descExpanded, setDescExpanded] = useState({});
    const [technologyBenefits, setTechnologyBenefits] = useState({
        detailed_description: "",
        applications: "",
        benefits: "",
        maturity: "",
        market_opportunities: "",
        sustainability: "",
    });
    let enrichedDescRefs = [];
    const setRef = ref => enrichedDescRefs.push(ref);

    const setEnrichedTechnologyText = technologyBenefitName => {
        let dataToHtml;
        let text;
        const content = technology[technologyBenefitName];

        text =
            content &&
            EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
        dataToHtml = text && stateToHTML(text.getCurrentContent());

        setTechnologyBenefits(prevState => {
            return {
                ...prevState,
                [technologyBenefitName]: dataToHtml,
            };
        });
    };
    const handleShowMore = technologyBenefitName => {
        setDescExpanded({
            ...descExpanded,
            [technologyBenefitName]: !descExpanded[technologyBenefitName],
        });
    };

    useEffect(() => {
        dispatch(technologiesAction.clearCompaniesResults());
    }, [dispatch]);
    useEffect(() => {
        Object.keys(technologyBenefits).forEach(benefitName => {
            setEnrichedTechnologyText(benefitName);
        });
    }, [technology]);
    useEffect(() => {
        if (
            getTechnologyStatus === REQUEST_SUCCESS &&
            enrichedDescRefs.length
        ) {
            Object.keys(technologyBenefits).forEach(benefitName => {
                setDescHeights(prevState => {
                    return {
                        ...prevState,
                        [benefitName]: enrichedDescRefs.find(
                            ref =>
                                ref.getAttribute("data-name") === benefitName,
                        ).offsetHeight,
                    };
                });
            });
        }
    }, [getTechnologyStatus, technologyBenefits]);

    return (
        <div className={classes.root}>
            <div className={classes.benefitsCardsWrapper}>
                {!technology.ready_for_release && (
                    <Typography
                        className={classes.benefitsCardsWrapperTitle}
                        variant="bodyXs">
                        {t("companyProfile.whatWillIGet")}
                    </Typography>
                )}
                {Object.keys(technologyBenefits).map(technologyBenefitName => (
                    <div
                        className={classes.benefitsCard}
                        key={`technology-overview-benefit-${technologyBenefitName}`}>
                        <div
                            className={classnames([
                                classes.imgBlock,
                                {
                                    [classes.imgBlockReleased]:
                                        technology.ready_for_release,
                                },
                            ])}>
                            <Typography
                                className={classes.blockTitle}
                                variant="subtitle1"
                                color="textPrimary"
                                component="div">
                                {t(
                                    `technologyOverview.${technologyBenefitName}.title`,
                                )}
                            </Typography>
                            <div className={classes.technologies}>
                                <div
                                    className={classes.descriptionBox}
                                    ref={setRef}
                                    data-name={technologyBenefitName}>
                                    <Typography
                                        variant="body"
                                        color={
                                            technology.ready_for_release
                                                ? "textPrimary"
                                                : "textSecondary"
                                        }
                                        component="div">
                                        <div
                                            className={classnames([
                                                {
                                                    [classes.descriptionExpanded]:
                                                        descExpanded[
                                                            technologyBenefitName
                                                        ],
                                                    [classes.description]:
                                                        !descExpanded[
                                                            technologyBenefitName
                                                        ],
                                                },
                                            ])}
                                            dangerouslySetInnerHTML={{
                                                __html: technology.ready_for_release
                                                    ? technologyBenefits[
                                                          technologyBenefitName
                                                      ]
                                                    : t(
                                                          `technologyOverview.${technologyBenefitName}.description`,
                                                      ),
                                            }}
                                        />
                                    </Typography>

                                    {descHeights[technologyBenefitName] > 351 &&
                                        document.body.offsetWidth < 768 && (
                                            <div
                                                className={classnames([
                                                    classes.showMore,
                                                ])}>
                                                <Typography
                                                    variant="bodySmall"
                                                    color="textLighter"
                                                    onClick={() =>
                                                        handleShowMore(
                                                            technologyBenefitName,
                                                        )
                                                    }>
                                                    {descExpanded[
                                                        technologyBenefitName
                                                    ]
                                                        ? t(
                                                              "companyProfile.showLess",
                                                          )
                                                        : t(
                                                              "companyProfile.showMore",
                                                          )}
                                                </Typography>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default TechnologyOverview;
