import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchOrdersRequest = createAction(
    `${REDUCER_PREFIX}/fetchOrdersRequest`,
);

export const exportCsvRequest = createAction(
    `${REDUCER_PREFIX}/exportCsvRequest`,
);
