import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, shape, typography, breakpoint }) =>
        createStyles({
            root: {
                bottom: 0,
                padding: 20,
                boxShadow: "none",
                "& tbody tr:hover": {
                    background: "#FD750D",
                    "& .date": {
                        color: "#FFFFFF",
                    },
                    "& svg path": {
                        fill: "#FFFFFFF",
                        color: "#FFFFFF",
                    },
                    "& .delete": {
                        color: "#FFFFFF",
                    },
                    "& .scope": {
                        color: "#FFFFFF",
                    },
                },
            },
            table: {
                minWidth: "100%",
            },
            date: {
                fontSize: 14,
                color: "#666666",
                cursor: "pointer",
            },
            scope: {
                fontSize: 14,
                color: "#333333",
                lineHeight: 2,
                cursor: "pointer"
            },
            header: {
                fontSize: 14,
                fontWeight: 600,
                color: "#333333",
            },
            delete: {
                cursor: "pointer",
                color: "#999999",
            },
            lookalike: {
                color: "#007A5E",
            },
            searchScope: {
                color: "#0B295F",
            },
            tooltip: {
                lineHeight: 2,
            },
            tbody: {
                overflowY: "scroll",
            },
            head: {
                position: "sticky",
                top: "-40px",
                backgroundColor:
                    "#ffffff" /* Set your header background color */,
                zIndex: 100,
            },
            dialogContent: {
                position: "relative",
            },

            x: {
                cursor: "pointer",
                position: "absolute",
                top: "0",
                right: 10,
                color: "#FD750D",
                fontSize: 24,
                margin: 0,
                height: 0,
            },
            title: {
                fontSize: "20px",
                fontWeight: 500,
            },
            paragraph: {
                fontSize: "16px",
                fontWeight: 300,
                margin: 0,
                marginBottom: 120,
            },
            buttons: {
                width: "100%",
                textAlign: "right",
            },
            cancelButton: {
                marginRight: 10,
            },
            tooltipTitle: {
                fontFamily: "GT Flexa",
                lineHeight: 1.4,
                fontSize: 14,
                fontWeight: 400,
            },
        }),
);
