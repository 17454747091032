import React, { useState, useEffect } from "react";
import classnames from "classnames";
// UI components
import ClickAwayListener from "react-click-away-listener";
import Typography from "../Typography/Typography";
import Tooltip from "@material-ui/core/Tooltip";
// Assets
import { ReactComponent as DownloadAsCSV } from "../../assets/icons/DownloadAsCSV.svg";
// Hooks
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { useAppEventTracking } from "../../common/useAppEventTracking";
//Assets
// Constants
import { getPricingPage } from "../../constants/endpoints";
// Redux
import { exportCsvRequest } from "../../store/orders/requests";

const DownloadButton = ({
    user,
    isIndustry,
    isTechnology,
    isProject,
    isLikedCompanies,
    selected,
    setSelected,
    dispatch,
    id,
    name,
    icon,
    type,
    setShowAreYouSureModal,
    setShowDownloadsExceededModal,
    getDownloadProps,
    className,
    sortBy,
    sortDirection,
    page,
    handleToggleMenu,
}) => {
    const { appTrackEvent } = useAppEventTracking();
    const { t } = useTranslation();
    const classes = useStyles();
    const [showDownloadPopup, setShowDownloadPopup] = useState(false);

    useEffect(() => {
        if (handleToggleMenu) {
            handleToggleMenu(showDownloadPopup);
        }
    }, [showDownloadPopup]);

    const handleDownloadToCsv = () => {
        let idType = isIndustry
            ? "iid"
            : isTechnology
            ? "tid"
            : isProject
            ? "rid"
            : "sid";
        getDownloadProps(
            selected,
            type || "listView",
            id,
            isIndustry
                ? "iid"
                : isTechnology
                ? "tid"
                : isProject
                ? "rid"
                : "sid",
            "csv",
            name,
            idType,
            id,
        );
        if (
            user.downloads === 1 &&
            (user.subscription.plan_type === "free_trial" ||
                user.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            user.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            !user.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!user);
        } else if (user.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            download("csv");
        }
    };

    const handleDownloadEntireListToCsv = () => {
        setSelected([]);
        let idType = isIndustry
            ? "iid"
            : isTechnology
            ? "tid"
            : isProject
            ? "rid"
            : "sid";
        getDownloadProps(
            selected,
            type || "companies",
            id,
            isIndustry
                ? "iid"
                : isTechnology
                ? "tid"
                : isProject
                ? "rid"
                : "sid",
            "csv",
            name,
            idType,
            id,
        );
        if (
            user.downloads === 1 &&
            (user.subscription.plan_type === "free_trial" ||
                user.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            user.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            !user.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!user);
        } else if (user.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            download("csv", true);
        }
    };

    const handleDownloadToPdf = () => {
        getDownloadProps(
            selected,
            type || "listView",
            id,
            isIndustry
                ? "iid"
                : isTechnology
                ? "tid"
                : isProject
                ? "rid"
                : "sid",
            "pdf",
            page,
        );
        if (
            user.downloads === 1 &&
            (user.subscription.plan_type === "free_trial" ||
                user.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            user.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            !user.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!user);
        } else if (user.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            download("pdf");
        }
    };

    const download = (doc, all = false) => {
        let idType = isIndustry
            ? "iid"
            : isTechnology
            ? "tid"
            : isProject
            ? "rid"
            : "sid";
        let typeValue = type || (isLikedCompanies ? "liked" : "companies");
        let payload = {
            ids: all ? [] : selected,
            type: typeValue,
            idType,
            idValue: id,
            doc,
            name,
            sortBy,
            sortDirection,
            pageName: page,
        };
        dispatch(exportCsvRequest(payload));
        appTrackEvent(`Downloaded document`, { type: doc, page });
    };

    return (
        <>
            <div className={!icon ? classes.relative : null}>
                {icon ? (
                    <div onClick={() => setShowDownloadPopup(true)}>{icon}</div>
                ) : (
                    <div
                        className={classnames([
                            className,
                            classes.downloadButton,
                            selected.length === 0 ? classes.downloadButtonDisabled : "",
                        ])}
                        onClick={() => setShowDownloadPopup(true)}
                        data-intercom-target={t(
                            "companyProfile.headerMenu.download",
                        )}>
                        <DownloadAsCSV className={classes.downloadIcon} />
                        <Typography
                            variant="bodyButton"
                            className={classes.mono}>
                            {t("results.download")}
                        </Typography>
                    </div>
                )}
                {showDownloadPopup && (
                    <ClickAwayListener
                        onClickAway={() => setShowDownloadPopup(false)}>
                        <div className={classes.addPopup}>
                            <div
                                className={classnames([
                                    classes.projectNameWrapper,
                                    classes.clickable,
                                    classes.mono,
                                    classes.createNewProject,
                                ])}
                                onClick={handleDownloadEntireListToCsv}>
                                <span
                                    className={classnames([
                                        classes.projectName,
                                        classes.mono,
                                    ])}>
                                    {t("common.downloadEntireListAsCSV")}
                                </span>
                            </div>
                            {selected && selected.length > 0 && (
                                <>
                                    <div
                                        className={classnames([
                                            classes.projectNameWrapper,
                                            classes.clickable,
                                            classes.mono,
                                            classes.createNewProject,
                                        ])}
                                        onClick={handleDownloadToCsv}>
                                        <span
                                            className={classnames([
                                                classes.projectName,
                                                classes.mono,
                                            ])}>
                                            {t("common.downloadAsCSV")}
                                        </span>
                                    </div>
                                    <div
                                        className={classnames([
                                            classes.projectNameWrapper,
                                            classes.clickable,
                                            classes.mono,
                                            classes.createNewProject,
                                        ])}
                                        onClick={handleDownloadToPdf}>
                                        <span
                                            className={classnames([
                                                classes.projectName,
                                                classes.mono,
                                            ])}>
                                            {t("common.downloadAsPDF")}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </ClickAwayListener>
                )}
            </div>
        </>
    );
};
export default DownloadButton;
