import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
import { useScrollTo } from "../../common/useScrollTo";
import useFilters from "../../common/useFilters.js";
import useResults from "../../common/useResults.js";
import usePrevious from "../../common/usePrevious";
import { actions as searchActions } from "../../store/search/slice";
import { actions as resultsActions } from "../../store/results/slice";
// UI components
import Header from "./components/Header";
import Card from "../../components/Card/Card";
import ListView from "./components/ListView/ListView";
import Typography from "../../components/Typography/Typography";
import ContentLoader from "../../components/ContentLoader/ContentLoader";
import NoResults from "./components/NoResults/NoResults.js";
import RecentSearches from "./components/RecentSearches/RecentSearches.js";
import GreenBoxCollapse from "./components/GreenBoxCollapse/GreenBoxCollapse";
import GraphsContainer from "../../components/GraphsContainer/GraphsContainer";
import Pagination from "../../components/Pagination/Pagination";
import Cluster from "../../components/Cluster/Cluster";
import ResultsRightColumn from "./components/RightColumn/ResultsRightColumn";
import EditSearch from "./components/EditSearch/EditSearch";
// Assets
import { ReactComponent as CloseIcon } from "../../assets/icons/CloseIcon.svg";
import { ReactComponent as UpArrowResults } from "../../assets/icons/UpArrowResults.svg";
import { ReactComponent as LookAlikeMessageInfo } from "../../assets/icons/LookAlikeMessageInfo.svg";
// Redux
import {
    getUserStatusSelector,
    getCheckOutUrlSelector,
    getSubscribeStatusSelector,
    getLoginStatusSelector,
} from "../../store/auth/selectors";
import {
    getSectorsSelector,
    getSubsectorsSelector,
} from "../../store/sectors/selectors";
import { getCountriesSelector } from "../../store/countries/selectors";
import { getMeRequest, getMeStealthRequest } from "../../store/auth/requests";
import {
    getSearchResultsSelector,
    getResultsFilterStats,
    getSearchResultsStatusSelector,
    getSearchResultsBySidStatusSelector,
    getSearchResultsSidSelector,
    getSearchResultsTotalCountSelector,
    getSearchResultsTotalPagesSelector,
    getSearchesSelector,
    getSearchesStatusSelector,
    getSearchTypeSelector,
    getLookalikeBannerHiddenSelector,
    getCompaniesChartSelector,
    getTeamSizeChartSelector,
    getTeamsizeChartUnspecifiedAllSelector,
    getResultsSearchQuery,
    getActiveViewSelector,
    getCurrentSearchSidSelector,
    getRecentSearchesStatusSelector,
    getConversationContextSelector,
} from "../../store/results/selectors";
import {
    fetchResultsRequest,
    fetchSearchesRequest,
    fetchResultsCountRequest,
    fetchCompaniesChartRequest,
    fetchTeamSizeChartRequest,
} from "../../store/results/requests";
import {
    REQUEST_SUCCESS,
    REQUEST_PENDING,
    REQUEST_FAILURE,
    REQUEST_UNDEFINED,
} from "../../constants/statuses";
import {
    stageOptions,
    regionsOptions,
    fundingOptionsMin,
    teamsizeOptions,
    valuerScoreOptionsMin,
} from "../../constants/filters";
import { objectHasEmptyValues } from "../../utils/objectHasEmptyValues";
//Shared
import { Shared } from "../../utils/shared.js";
import Description from "../SearchCompanies/Description";
import SearchLookAlike from "../SearchCompanies/SearchLookAlike";
// Utils
import { createCompanyData } from "../../utils/companyUtils.js";
import axios from "axios";
import { useSearches } from "../../common/useSearches";
import { availableTabs } from "../../components/ResultsTabs/tabs";
import Graph3D from "../../components/NewClusterGraph/Graph3D.js";
import PreviousScopes from "./components/PreviousScopes/PreviousScopes.js";
import {useCompanies} from "../../common/useCompanies";
// import NewGraph1 from "../../components/NewGraph/NewGraph1.js";
// import NewGraph2 from "../../components/NewGraph/NewGraph2.js";
// import NewGraph3 from "../../components/NewGraph/NewGraph3.js";
// import NewGraph4 from "../../components/NewGraph/NewGraph3Text.js";
// import NewGraph1Text from "../../components/NewGraph/NewGraph1Text.js";
// import NewGraph2Text from "../../components/NewGraph/NewGraph2Text.js";
// import NewGraph3Text from "../../components/NewGraph/NewGraph3Text.js";

const Results = () => {
    const { isAuthenticated, user, userLoading } = useAuthHook();
    const { t } = useTranslation();
    let { search } = useLocation();
    let { token } = useParams();
    const { scrollToTop, upArrowVisible } = useScrollTo();
    const dispatch = useDispatch();
    const { openPage, getUrlParam } = useRouterHook();
    // Selectors
    const allSectors = useSelector(getSectorsSelector);
    const getSearches = useSelector(getSearchesSelector);
    const getSearchesStatus = useSelector(getSearchesStatusSelector);
    //TODO: Split searches and recent searches in two different state
    // const getRecentSearchesStatus = useSelector(getRecentSearchesStatusSelector);
    const getMeStatus = useSelector(getUserStatusSelector);
    const searchResultsTotalCount = useSelector(
        getSearchResultsTotalCountSelector,
    );
    const getSearchResultsTotalPages = useSelector(
        getSearchResultsTotalPagesSelector,
    );
    const allResults = useSelector(getSearchResultsSelector);
    const filterStats = useSelector(getResultsFilterStats);
    const searchQuery = useSelector(getResultsSearchQuery);
    const searchResultsStatusSelector = useSelector(
        getSearchResultsStatusSelector,
    );
    const searchResultsBySidStatusSelector = useSelector(
        getSearchResultsBySidStatusSelector,
    );
    const getCurrentSearchId = useSelector(getCurrentSearchSidSelector);
    const [currentSearchId, setCurrentSearchId] = useState(
        getUrlParam("sid")?.value || null,
    );
    const searchResultsSid = useSelector(getSearchResultsSidSelector);
    const getFilteringStatus = useSelector(getSearchResultsStatusSelector);
    const getFilteringState = useSelector(getSearchResultsSelector);
    const chartYears = useSelector(getCompaniesChartSelector);
    const teamsizes = useSelector(getTeamSizeChartSelector);
    const unspecifiedAll = useSelector(getTeamsizeChartUnspecifiedAllSelector);
    const getFilterResultsTotalPages = useSelector(
        getSearchResultsTotalPagesSelector,
    );
    const checkOutUrl = useSelector(getCheckOutUrlSelector);
    const subscribeStatus = useSelector(getSubscribeStatusSelector);
    const allCountries = useSelector(getCountriesSelector);
    const allSubsectors = useSelector(getSubsectorsSelector);
    const searchType = useSelector(getSearchTypeSelector);
    const lookalikeBannerHidden = useSelector(getLookalikeBannerHiddenSelector);
    const conversationContext = useSelector(getConversationContextSelector);
    const tableToolbarRef = useRef(null);
    // State
    const [isEditMode, setIsEditMode] = useState(true);
    const activeView = useSelector(getActiveViewSelector);
    const [timeout, setTimeoutVal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAuthed, setIsAuthed] = useState(true);
    const [totalPages, setTotalPages] = useState(getSearchResultsTotalPages);
    const classes = useStyles({ activeView, user });
    const [boxExpanded, setBoxExpanded] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedNames, setSelectedNames] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [tableToolbarClassName, setTableToolbarClassName] = useState(
        classes.header,
    );
    const [currentSearch, setCurrentSearch] = useState(null);
    const [tabs, setTabs] = useState(availableTabs);
    const [listViewActive, setListViewActive] = useState(false);
    const [cardViewActive, setCardViewActive] = useState(false);
    const [overviewActive, setOverviewActive] = useState(false);
    const [clusterActive, setClusterActive] = useState(false);
    const [previousScopesActive, setPreviousScopesActive] = useState(false);
    const [tabLoading, setTabLoading] = useState(false);
    const sidParam = getUrlParam("sid") ? getUrlParam("sid").value : null;
	const pageParam = getUrlParam("page") ? getUrlParam("page").value : 1;
	const [page, setPage] = useState(pageParam);
	const [recentScopesPage, setRecentScopesPage] = useState(1);
	const [filtersURIString, setFiltersURIString] = useState(null);
	
    const {
        getRecentSearches: {
            data: searches,
            error: errorRecentSearches,
            isLoading: isLoadingSearches,
        },
        getSearch: {
            data: searchData
        }
    } = useSearches(currentSearchId, recentScopesPage);
	
	const {
		getScopeCompanies: {
			data: companyResults,
			error: errorCompanyResults,
			isLoading: isLoadingCompanyResults,
			isFetching: isFetchingCompanies,
			refetch
		}
	} = useCompanies(currentSearchId, null, page, filtersURIString);
	
	const handlePageChange = newPage => {
		setPage(newPage);
		resultsRef.current.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}
	
	const handleApplyFilters = filtersStr => {
		setFiltersURIString(filtersStr);
		
		if(filtersURIString) {
			refetch();
		}
	}
	
    useEffect(() => {
        document.querySelector("body").style.height = "100%";
        document.querySelector("body").style.overflow = "hidden";

        return () => {
            document.querySelector("body").style.height = "";
            document.querySelector("body").style.overflow = "";
        };
    }, []);
    useEffect(() => {
        if (searchData && !!user) {
            setCurrentSearchId(searchData.id);
            setCurrentSearch(searchData);
        }
    }, [searchData, sidParam]);

    useEffect(() => {
        if (!!user && !sidParam) {
            let currentSid;

            if (searches?.results.length) {
                currentSid = searches.results[0].id;
            } else {
                currentSid = null;
            }

            let currentSearch = searches?.results.find(s => s.id === currentSid);

            setCurrentSearchId(currentSid);
            setCurrentSearch(currentSearch);
			
        }
    }, [sidParam, searches]);

    useEffect(() => {
        tabs.map(tab => {
            if (tab.active) {
                if (tab.id === 1) {
                    setTabLoading(true);
                    setListViewActive(true);
                    setCardViewActive(false);
                    setClusterActive(false);
                    setOverviewActive(false);
                    setPreviousScopesActive(false);
                } else if (tab.id === 2) {
                    setTabLoading(true);
                    setListViewActive(false);
                    setCardViewActive(true);
                    setClusterActive(false);
                    setOverviewActive(false);
                    setPreviousScopesActive(false);
                } else if (tab.id === 3) {
                    setListViewActive(false);
                    setCardViewActive(false);
                    setClusterActive(true);
                    setOverviewActive(false);
                    setPreviousScopesActive(false);
                } else if (tab.id === 4) {
                    setListViewActive(false);
                    setCardViewActive(false);
                    setClusterActive(false);
                    setOverviewActive(true);
                    setPreviousScopesActive(false);
                } else if (tab.id === 5) {
                    setListViewActive(false);
                    setCardViewActive(false);
                    setClusterActive(false);
                    setOverviewActive(false);
                    setPreviousScopesActive(true);
                }
                else {
                    console.log("state changed");
                }
            }
        });
    }, [tabs]);

    useEffect(() => {
        if (cardViewActive || listViewActive) {
            setTabLoading(true);
            let timer = setTimeout(() => {
                setTabLoading(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [cardViewActive, listViewActive]);

    const switchView = view => {
        setPage(1);
        dispatch(resultsActions.setActiveView(view));
    };

    useEffect(() => {
        if (
            !loadingViewSwitch &&
            searchResultsStatusSelector === "REQUEST_PENDING"
        ) {
            setLoadingViewSwitch(true);
        } else {
            setLoadingViewSwitch(false);
        }
    }, [searchResultsStatusSelector]);

    const [isEditLookalikeClicked, setIsEditLookalikeClicked] = useState(false);
    const getLoginStatus = useSelector(getLoginStatusSelector);
    const urlParams = new URLSearchParams(search);
    const searchTypeLook = urlParams.get("searchTypeLook");

    const { scrollTo } = Shared({});

    useEffect(() => {
        if (getLoginStatus === REQUEST_SUCCESS) {
            const to = setTimeout(() => {
                dispatch(getMeStealthRequest());
            }, 300);
            return () => clearTimeout(to);
        }
    }, [getLoginStatus, dispatch]);

    const [isSharedPageToken, setIsSharedPageToken] = useState("");
	
    useEffect(() => {
        setIsSharedPageToken(token || "");
        localStorage.setItem("isSharedResultsPageToken", token);
        token && dispatch(getMeRequest());
        window.scrollTo(0, 0);
        return () => {
            dispatch(resultsActions.clearResults());
            dispatch(resultsActions.getRecentSearchesStatusUndefined());
            dispatch(searchActions.clearSearch());
        };
    }, []);

    // Filtering
    const {
        filters,
        selectedFilters,
        setDisplayedFilters,
        filterNameValue,
        resetFilters,
        handleSearchChange,
        handleExcludeKeywordsChange,
        handleIncludeKeywordsChange,
        handleSectorChange,
        handleRegionChange,
        handleSubsectorChange,
        handleFundingMinChange,
        handleYearMinChange,
        handleMatchingScoreMinChange,
        handleTeamSizeMinChange,
        teamSizeOptionsMax,
        resetFilter,
        setModalFilters,
        setSelectedFilters,
        displayedFilters,
        fundingOptionsMax,
        valuerScoreOptionsMax,
        handleStageChange,
        handleLocationChange,
        handleFieldChange,
        handleShowResults,
        handleShowFilters,
        isFilterFetching,
        setIsFilterFetching,
        // setPage,
        // page,
        handleSortChange,
        sortValue,
        sortDirection,
        handleSectorsClose,
        handleSubsectorsClose,
        preselectedSectors,
        setPreselectedSectors,
        preselectedSubsectors,
        setPreselectedSubsectors,
        modalFilterNameValue,
        filterMapping,
    } = useFilters({
        isAuthenticated,
        allSectors,
        setTotalPages,
        getFilterResultsTotalPages,
        getSearchResultsTotalPages,
        isSharedPageToken,
        searchTypeLook,
        currentSearchSid: currentSearchId,
        request: fetchResultsRequest,
        countRequest: fetchResultsCountRequest,
        yearChartRequest: fetchCompaniesChartRequest,
        teamSizeChartRequest: fetchTeamSizeChartRequest,
        token,
        localStorageSlugPrefix: "results",
        slug: searchResultsSid,
        isIndustry: false,
        activeView,
    });
    // State
    const [searchResults, setSearchResults] = useState(allResults);
    const [totalCount, setTotalCount] = useState(searchResultsTotalCount);
    const {
        handleSelectingCardIndex,
        selectedCardIndex,
        isFetching,
        addItemToProjectModal,
        deselectAll,
        pageLoading,
        moreData,
    } = useResults({
        totalCount,
        companyResults,
        isAuthenticated,
        getMeStatus,
        search,
        checkOutUrl,
        subscribeStatus,
        page,
        totalPages,
        filterNameValue,
        setPage,
        selectedCompanies,
        setSelectedCompanies,
        setIsAuthed,
        sortValue,
        sortDirection,
        currentSearchId,
        getFilteringState,
        isSharedPageToken,
        displayedFilters,
        request: fetchResultsRequest,
        localStorageSlugPrefix: "results",
        slug: searchResultsSid ? searchResultsSid : "page",
        perPage: activeView === "card" ? 10 : 50,
        activeView,
    });
    const [isLookAlikeResults, setIsLookAlikeResults] = useState(searchType);
    // const [lookAlikeBetaMessageShown, setLookAlikeBetaMessageShown] = useState(true);
	const resultsRef = useRef(null);
	
	useEffect(() => {
        !!searchType && setIsLookAlikeResults(Number(searchType) === 2);
    }, [searchType]);
	
	useEffect(() => {
		totalCount === 0 || page === totalPages || !totalPages ? setIsFilterFetching(false) : setIsFilterFetching(isFetching);
	}, [isFetching, totalCount, totalPages, page])
	
	useEffect(() => {
		if (!userLoading) {
			if (!objectHasEmptyValues(selectedFilters) || getFilteringState.length > 0) {
				setSearchResults(getFilteringState);
				setTotalCount(searchResultsTotalCount);
				getFilterResultsTotalPages !== null && setTotalPages(getFilterResultsTotalPages)
			} else {
				setSearchResults(allResults);
				setTotalCount(searchResultsTotalCount);
				getSearchResultsTotalPages !== null && setTotalPages(getSearchResultsTotalPages);
			}
		}
	}, [allResults, getFilteringState]);

    useEffect(() => {
        localStorage.removeItem("company-look-a-likes");
    }, [allResults, currentSearchId]);

    useEffect(() => {
        sessionStorage.setItem(
            `results-${
                searchResultsSid ? searchResultsSid : "page"
            }-${activeView}-FilterSortValue`,
            sortValue,
        );
    }, [sortValue]);

    useEffect(() => {
        sessionStorage.setItem(
            `results-${
                searchResultsSid ? searchResultsSid : "page"
            }-${activeView}-FilterSortDirection`,
            sortDirection,
        );
    }, [sortDirection]);

    const handleOnSearch = () => {
        console.log("handleOnSearch clicked");
        setLoading(true);
        clearTimeout(timeout);
        setTimeoutVal(
            setTimeout(() => {
                setLoading(false);
            }, 500),
        );
        setPage(1);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = companyResults.results.map(n => n.id);
            const newSelectedNames = companyResults.results.map(n => n.name);
            setSelected(newSelecteds);
            setSelectedCompanies(companyResults.results);
            setSelectedNames(newSelectedNames);
            return;
        }
        setSelected([]);
        setSelectedNames([]);
        setSelectedCompanies([]);
    };

    const handleEditLookalikeSearchClick = () => {
        !!isAuthed &&
            searchResultsStatusSelector === REQUEST_UNDEFINED &&
            dispatch(
                fetchSearchesRequest({
                    data: {
                        orderDirection: "desc",
                        orderBy: "created-at",
                        page: 1,
                    },
                }),
            );
        setIsEditLookalikeClicked(true);
        if (!isAuthed) {
            let lookalikeDescriptionValue = localStorage.getItem(
                "lookalikeDescriptionValue",
            );
            let lookalikeNameValue = localStorage.getItem("lookalikeNameValue");
            let lookalikeWebsiteValue = localStorage.getItem(
                "lookalikeWebsiteValue",
            );
            dispatch(
                searchActions.setCompany({
                    companyName: lookalikeNameValue,
                    companyWebsite: lookalikeWebsiteValue,
                    companyDescription: lookalikeDescriptionValue,
                }),
            );
            openPage(`/add-more-details`);
        }
    };

    // useEffect(() => {
    //     if ((getSearchesStatus === REQUEST_SUCCESS || getSearchesStatus === REQUEST_FAILURE) && isEditLookalikeClicked && allResults.length > 0) {
    //         const urlParams = new URLSearchParams(search);
    //         let sid = urlParams.get("sid");
    //         if (!!isAuthed && getSearches.length > 0) {
    //             let exactSearch = !!sid ? getSearches && Object.values(getSearches).filter(search => search.id === Number(sid))[0] : getSearches.find(result => result.id === allResults[0].search_id);
    //             dispatch(searchActions.setCompany({
    //                 companyName: exactSearch && exactSearch.look_alike_company_name,
    //                 companyWebsite: exactSearch && exactSearch.look_alike_company_website,
    //                 companyDescription: exactSearch && exactSearch.description
    //             }));
    //         }
    //         openPage(`/add-more-details`);
    //     }
    // }, [getSearches, getSearchesStatus, isEditLookalikeClicked]);

    const [loadingViewSwitch, setLoadingViewSwitch] = useState(false);

    const loadingPage = page > 1 && loading;

    useLayoutEffect(() => {
        if (!urlParams.get("sid") && searchResults[0]) {
            dispatch(
                resultsActions.setResultsDisplayFilters({
                    sid: searchResults[0].search_id,
                    displayedFilters: {
                        filterSid: searchResults[0].search_id,
                        sortValue: sortValue || [],
                        sortDirection: sortDirection || [],
                        sector: displayedFilters.sector || [],
                        subsector: displayedFilters.subsector || [],
                        subsectors: displayedFilters.subsectors || [],
                        stage: displayedFilters.stage || [],
                        fundingMin: displayedFilters.fundingMin || "",
                        fundingMax: displayedFilters.fundingMax || "",
                        teamsizeMin: displayedFilters.teamsizeMin || "",
                        teamsizeMax: displayedFilters.teamsizeMax || "",
                        location: displayedFilters.location || [],
                        yearMin: displayedFilters.yearMin || "",
                        yearMax: displayedFilters.yearMax || "",
                        matchingScoreMin: displayedFilters.matchingScoreMin,
                        matchingScoreMax:
                            displayedFilters.matchingScoreMax || "",
                        regions: displayedFilters.regions || [],
                        search: displayedFilters.search || "",
                        excludeKeywords: displayedFilters.excludeKeywords || "",
                        includeKeywords: displayedFilters.includeKeywords || "",
                        storageResultsFilterNameValue: filterNameValue || "",
                        storageResultsFilterPage: Number(page) || 1,
                    },
                }),
            );
        }
        let scrollPosition = sessionStorage.getItem(
            `results-${searchResultsSid}ScrollPosition`,
        );
        const to = setTimeout(() => {
            scrollPosition &&
                companyResults.results.length &&
                scrollTo(Number(scrollPosition), () =>
                    sessionStorage.removeItem(
                        `results-${searchResultsSid}ScrollPosition`,
                    ),
                );
        }, 100);
        return () => clearTimeout(to);
    }, [companyResults, displayedFilters]);

    const companies =
        companyResults && companyResults.results &&
        companyResults.results.map(company =>
            createCompanyData(
                company.name,
                company.description,
                company.total_funding_amount_dollars,
                company.founded_date,
                company.number_of_employees,
                company.investment_stage,
                company.hq_country,
                (company.degree_of_fit * 100).toFixed(),
                company.slug,
                company.id,
                company.enrichment_request,
                company.ready_for_release,
                company.website,
                company.deck,
                company.sectors,
                company.comments_cnt,
                company.subsectors,
                company.projects,
            ),
        );

    return (
        <div
            className={classnames([
                classes.resultsRoot,
                isSharedPageToken && !isAuthenticated && classes.noClick,
            ])}>
            {userLoading && isLoadingSearches ? (
                <ContentLoader fullHeight={true} />
            ) : (
                <>
                    <div className={classes.resultsHeader}>
                        {isAuthenticated && searches?.results.length > 0 && (
                            <RecentSearches
                                user={user}
                                activeView={activeView}
                                currentSearch={currentSearch}
                                currentSearchId={currentSearchId}
                                searches={searches.results}
                                filterNameValue={filterNameValue}
                                sortValue={sortValue}
                                sortDirection={sortDirection}
                                page={page}
                                perPage={
                                    cardViewActive
                                        ? 10
                                        : listViewActive
                                        ? 50
                                        : null
                                }
                                slug={
                                    searchResultsSid ? searchResultsSid : "page"
                                }
                                token={token}
                                searchTypeLook={searchTypeLook}
                            />
                        )}
                    </div>
                    <div
                        className={classnames([
                            searches?.results.length > 0 && classes.resultsSection,
                            (!searches?.results.length) && classes.noResultsNew,
                        ])}>
                        <div
                            className={classnames([
                                searches?.results.length > 0 && classes.leftColumn,
                                user &&
                                    !user.csp &&
                                    classes.leftColumnFullWidth,
                            ])}>
                            <div>
                                {searches?.results.length > 0 ? (
                                    <>
                                        {currentSearch && (
                                            <EditSearch
                                                search={currentSearch}
                                            />
                                        )}
                                        {/* {currentSearch && (
                                        <GreenBoxCollapse
                                            currentSearchSid={currentSearchId}
                                            currentSearch={currentSearch}
                                            isEditMode={isEditMode}
                                            setIsEditMode={setIsEditMode}
                                            searches={searches}
                                            isSharedPageToken={
                                                isSharedPageToken
                                            }
                                            boxExpanded={boxExpanded}
                                            setBoxExpanded={setBoxExpanded}>
                                            {searchType == 1 ? (
                                                <Description
                                                    isResultsPage={true}
                                                    isSharedPage={
                                                        isSharedPageToken
                                                    }
                                                    searchQuery={searchQuery}
                                                    isEditMode={isEditMode}
                                                    setIsEditMode={
                                                        setIsEditMode
                                                    }
                                                    currentSearch={
                                                        currentSearch
                                                    }
                                                    boxExpanded={boxExpanded}
                                                    setBoxExpanded={
                                                        setBoxExpanded
                                                    }
                                                />
                                            ) : (
                                                <>
                                                    <SearchLookAlike
                                                        boxExpanded={
                                                            boxExpanded
                                                        }
                                                        setBoxExpanded={
                                                            setBoxExpanded
                                                        }
                                                        searchedCompanyName={
                                                            searchResults &&
                                                            currentSearchId &&
                                                            searchResults.find(
                                                                res =>
                                                                    res.search_id ==
                                                                    currentSearchId,
                                                            ) &&
                                                            searchResults.find(
                                                                res =>
                                                                    res.search_id ==
                                                                    currentSearchId,
                                                            ).name
                                                        }
                                                    />
                                                    {!lookalikeBannerHidden &&
                                                        !searchTypeLook && (
                                                            <div
                                                                className={
                                                                    classes.lookAlikeBetaMessage
                                                                }>
                                                                <CloseIcon
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            resultsActions.hideLookalikeBanner(),
                                                                        )
                                                                    }
                                                                    className={
                                                                        classes.closeIcon
                                                                    }
                                                                />
                                                                <LookAlikeMessageInfo
                                                                    className={
                                                                        classes.lookAlikeMessageInfoIcon
                                                                    }
                                                                />
                                                                <Typography
                                                                    variant="bodySmall"
                                                                    color="text"
                                                                    className={
                                                                        classes.mono
                                                                    }>
                                                                    {t(
                                                                        "results.betaMessage",
                                                                    )}
                                                                    <Typography
                                                                        onClick={
                                                                            handleEditLookalikeSearchClick
                                                                        }
                                                                        variant="bodySmall"
                                                                        color="text"
                                                                        component="span"
                                                                        className={classnames(
                                                                            [
                                                                                classes.mono,
                                                                                classes.lookAlikeLink,
                                                                            ],
                                                                        )}>
                                                                        {t(
                                                                            "results.betaMessageLink",
                                                                        )}
                                                                    </Typography>
                                                                </Typography>
                                                            </div>
                                                        )}
                                                </>
                                            )}
                                        </GreenBoxCollapse>
                                    )} */}
                                    </>
                                ) : (
                                    <>
                                        {
                                            // don't show this loader if NoResults component is visible
                                            /* isLoadingSearches && (
                                            <div
                                                className={
                                                    classes.searchesLoader
                                                }>
                                                <ContentLoader size="small" />
                                            </div>
                                        ) */
                                        }
                                    </>
                                )}

                                {/* Header */}
                                {searches?.results.length > 0 && (
                                    <Header
                                        search={currentSearch}
                                        onSearch={handleOnSearch}
                                        onFilterSelected={handleShowFilters}
                                        onApplyFilters={handleApplyFilters}
                                        searchResults={companyResults?.results}
                                        searchResultsTotal={
                                            companyResults?.results?.length
                                        }
                                        isAuthenticated={isAuthenticated}
                                        user={user}
                                        onSortChange={handleSortChange}
                                        filters={filters}
                                        allResults={allResults}
                                        total={totalCount}
                                        resetFilters={resetFilters}
                                        stageOptions={stageOptions}
                                        handleRegionChange={handleRegionChange}
                                        regionsOptions={regionsOptions}
                                        fundingOptionsMin={fundingOptionsMin}
                                        fundingOptionsMax={fundingOptionsMax}
                                        yearOptionsMin={
                                            chartYears
                                                ? Object.keys(chartYears)
                                                      .map(year => {
                                                          return { name: year };
                                                      })
                                                      .sort()
                                                : []
                                        }
                                        yearOptionsMax={
                                            chartYears
                                                ? Object.keys(chartYears)
                                                      .map(year => {
                                                          return { name: year };
                                                      })
                                                      .sort()
                                                : []
                                        }
                                        teamSizeOptions={teamsizeOptions}
                                        valuerScoreOptionsMin={
                                            valuerScoreOptionsMin
                                        }
                                        valuerScoreOptionsMax={
                                            valuerScoreOptionsMax
                                        }
                                        teamSizeOptionsMax={teamSizeOptionsMax}
                                        handleTeamSizeMinChange={
                                            handleTeamSizeMinChange
                                        }
                                        handleFieldChange={handleFieldChange}
                                        handleSearchChange={handleSearchChange}
                                        handleExcludeKeywordsChange={
                                            handleExcludeKeywordsChange
                                        }
                                        handleIncludeKeywordsChange={
                                            handleIncludeKeywordsChange
                                        }
                                        handleSectorChange={handleSectorChange}
                                        handleSubsectorChange={
                                            handleSubsectorChange
                                        }
                                        handleStageChange={handleStageChange}
                                        handleLocationChange={
                                            handleLocationChange
                                        }
                                        resetFilter={resetFilter}
                                        handleFundingMinChange={
                                            handleFundingMinChange
                                        }
                                        handleYearMinChange={
                                            handleYearMinChange
                                        }
                                        handleMatchingScoreMinChange={
                                            handleMatchingScoreMinChange
                                        }
                                        allSectors={allSectors}
                                        allSubsectors={allSubsectors}
                                        selectedFilters={selectedFilters}
                                        allCountries={allCountries}
                                        onShowResults={handleShowResults}
                                        displayedFilters={displayedFilters}
                                        setSelectedFilters={setSelectedFilters}
                                        setDisplayedFilters={
                                            setDisplayedFilters
                                        }
                                        isFilterFetching={isFilterFetching}
                                        setModalFilters={setModalFilters}
                                        isLookAlikeResults={isLookAlikeResults}
                                        searchType={searchType}
                                        isEditLookalikeClicked={
                                            isEditLookalikeClicked
                                        }
                                        tabs={tabs}
                                        setTabs={setTabs}
                                        listViewActive={listViewActive}
                                        setListViewActive={setListViewActive}
                                        cardViewActive={cardViewActive}
                                        setCardViewActive={setCardViewActive}
                                        overviewActive={overviewActive}
                                        setOverviewActive={setOverviewActive}
                                        clusterActive={clusterActive}
                                        setClusterActive={setClusterActive}
                                        slug={searchResultsSid}
                                        request={fetchResultsRequest}
                                        countRequest={fetchResultsCountRequest}
                                        filterNameValue={filterNameValue}
                                        sortValue={sortValue}
                                        sortDirection={sortDirection}
                                        page={page}
                                        token={token}
                                        totalPages={companyResults?.pagination.total_pages}
                                        filterStats={filterStats}
                                        searchQuery={searchQuery}
                                        handleSectorsClose={handleSectorsClose}
                                        handleSubsectorsClose={
                                            handleSubsectorsClose
                                        }
                                        preselectedSectors={preselectedSectors}
                                        setPreselectedSectors={
                                            setPreselectedSectors
                                        }
                                        preselectedSubsectors={
                                            preselectedSubsectors
                                        }
                                        setPreselectedSubsectors={
                                            setPreselectedSubsectors
                                        }
                                        activeView={activeView}
                                        setActiveView={switchView}
                                        companiesPageId={searchResultsSid}
                                        modalFilterNameValue={
                                            modalFilterNameValue
                                        }
                                        filterMapping={filterMapping}
                                        currentSearchSid={currentSearchId}
                                        setSelected={setSelected}
                                        setSelectedCompanies={
                                            setSelectedCompanies
                                        }
                                        setSelectedCompany={setSelectedCompany}
                                        setSelectedNames={setSelectedNames}
                                        selected={selected}
                                        selectedNames={selectedNames}
                                        selectedCompany={selectedCompany}
                                        selectedCompanies={selectedCompanies}
                                        tableToolbarRef={tableToolbarRef}
                                        handleSelectAllClick={
                                            handleSelectAllClick
                                        }
                                        tableToolbarClassName={
                                            tableToolbarClassName
                                        }
                                        showClusterTab={true}
                                    />
                                )}
                            </div>
                            <div className={classes.tabContainer}>
                                {
                                    searches?.results.length > 0 &&
                                    overviewActive &&
                                    isAuthenticated &&
                                    chartYears &&
                                    Object.keys(chartYears).length > 0 && (
                                        <GraphsContainer
                                            t={t}
                                            chartYears={chartYears}
                                            teamsizes={teamsizes}
                                            selectedFilters={selectedFilters}
                                            displayedFilters={displayedFilters}
                                            setDisplayedFilters={
                                                setDisplayedFilters
                                            }
                                            setSelectedFilters={setSelectedFilters}
                                            overviewActive={overviewActive}
                                            setOverviewActive={setOverviewActive}
                                            totalCount={totalCount}
                                            resetFilter={resetFilter}
                                            slug={searchResultsSid}
                                            localStorageSlugPrefix="results"
                                            unspecifiedAll={unspecifiedAll}
                                            setPage={setPage}
                                            filterStats={filterStats}
                                        />
                                    )}
                                {isAuthenticated &&
                                    searches?.results.length > 0 &&
                                    clusterActive && (
                                        // <Cluster
                                        //     setClusterActive={setClusterActive}
                                        //     user={user}
                                        //     resourceType="search"
                                        //     resourceId={currentSearchId}
                                        //     filterNameValue={filterNameValue}
                                        // />
                                        <div
                                            style={{
                                                height: "100%",
                                                position: "relative",
                                            }}>
                                            <Graph3D
                                                setClusterActive={setClusterActive}
                                                user={user}
                                                resourceId={currentSearchId}
                                                filterNameValue={filterNameValue}
                                            />
                                            {/* <NewGraph1
                                                setClusterActive={setClusterActive}
                                                user={user}
                                                resourceType="search"
                                                resourceId={currentSearchId}
                                                filterNameValue={filterNameValue}
                                            /> */}
                                            {/* <NewGraph1Text
                                                setClusterActive={setClusterActive}
                                                user={user}
                                                resourceType="search"
                                                resourceId={currentSearchId}
                                                filterNameValue={filterNameValue}
                                            /> */}
                                            {/* <NewGraph2
                                                setClusterActive={setClusterActive}
                                                user={user}
                                                resourceType="search"
                                                resourceId={currentSearchId}
                                                filterNameValue={filterNameValue}
                                            /> */}
                                            {/* <NewGraph2Text
                                                setClusterActive={setClusterActive}
                                                user={user}
                                                resourceType="search"
                                                resourceId={currentSearchId}
                                                filterNameValue={filterNameValue}
                                            /> */}
                                            {/* <NewGraph3
                                                setClusterActive={setClusterActive}
                                                user={user}
                                                resourceType="search"
                                                resourceId={currentSearchId}
                                                filterNameValue={filterNameValue}
                                            /> */}
                                            {/* <NewGraph3Text
                                                setClusterActive={setClusterActive}
                                                user={user}
                                                resourceType="search"
                                                resourceId={currentSearchId}
                                                filterNameValue={filterNameValue}
                                            /> */}
                                        </div>

                                        // <Graph3D />
                                    )}
                                {/* Displayed filters */}
                                {/* {displayedFilters && !objectHasEmptyValues(displayedFilters) && !hasOnlySearchValue(displayedFilters) && <div className={classes.appliedFiltersWrap} >
                                        <Typography variant="body2">{t("results.appliedResults")}</Typography>
                                        <div className={classes.appliedFilters}>
                                            {
                                                Object.keys(displayedFilters).map((filter, i) => {
                                                    return displayedFilters[filter] && filter !== "search" && <div className={classes.filterTag} key={`filter-${i}`}><Typography variant="body2" test-id={`filter-${i}`}>{displayedFilters[filter]}<CloseIcon onClick={() => handleFilterRemove(filter)} className={classes.closeIcon} /></Typography></div>
                                                })
                                            }
                                        </div >

                                    </div >} */}
                                {/* Results */}
                                {(tabLoading || isFetchingCompanies) && <ContentLoader size="auto" />}
                                {!searches || (!searches?.results.length && !isLoadingSearches) ? (
                                    <NoResults />
                                ) : (
                                    <div
	                                    ref={resultsRef}
                                        className={cx([
                                            classes.resultsContent,
                                            listViewActive || cardViewActive
                                                ? classes.resultsContentHeight
                                                : "",
                                            (listViewActive || cardViewActive) && companyResults?.pagination?.total_pages > 1 ? classes.resultsWithMultiplePages : ""
                                        ])}>
                                        {
	                                        !isLoadingSearches &&
	                                        companies &&
	                                        companies.length ? (
	                                            <>
	                                                {!tabLoading && listViewActive && (
	                                                    <div
	                                                        style={{
	                                                            display: listViewActive
	                                                                ? "block"
	                                                                : "none",
	                                                        }}>
	                                                        <ListView
	                                                            companies={
	                                                                companyResults?.results
	                                                            }
	                                                            isTechnology={false}
	                                                            isIndustry={false}
	                                                            isSharedPage={
	                                                                isSharedPageToken
	                                                            }
	                                                            searchResults={
	                                                                companyResults?.results
	                                                            }
	                                                            onSortChange={
	                                                                handleSortChange
	                                                            }
	                                                            sortDirection={
	                                                                sortDirection
	                                                            }
	                                                            sortValue={sortValue}
	                                                            page={page}
	                                                            searchResultsSid={
	                                                                searchResultsSid
	                                                            }
	                                                            source="results"
	                                                            isLikedCompany={false}
	                                                            overviewActive={
	                                                                overviewActive
	                                                            }
	                                                            id={searchResultsSid}
	                                                            setSelected={
	                                                                setSelected
	                                                            }
	                                                            setSelectedCompanies={
	                                                                setSelectedCompanies
	                                                            }
	                                                            setSelectedCompany={
	                                                                setSelectedCompany
	                                                            }
	                                                            setSelectedNames={
	                                                                setSelectedNames
	                                                            }
	                                                            selected={selected}
	                                                            selectedNames={
	                                                                selectedNames
	                                                            }
	                                                            selectedCompany={
	                                                                selectedCompany
	                                                            }
	                                                            selectedCompanies={
	                                                                selectedCompanies
	                                                            }
	                                                            tableToolbar={
	                                                                tableToolbarRef
	                                                            }
	                                                            setTableToolbarClassName={
	                                                                setTableToolbarClassName
	                                                            }
	                                                        />
	                                                    </div>
	                                                )}
	                                                {!tabLoading && cardViewActive && (
	                                                    <div
	                                                        style={{
	                                                            display: cardViewActive
	                                                                ? "flex"
	                                                                : "none",
	                                                        }}
	                                                        className={
	                                                            classes.cardsContent
	                                                        }>
	                                                        {companies
	                                                            ? companies.map(
	                                                                (result, i) => (
	                                                                    <Card
	                                                                        isTechnology={
	                                                                            false
	                                                                        }
	                                                                        cardIndex={
	                                                                            i
	                                                                        }
	                                                                        openPage={
	                                                                            openPage
	                                                                        }
	                                                                        searchResultsSid={
	                                                                            searchResultsSid
	                                                                        }
	                                                                        t={t}
	                                                                        result={
	                                                                            result
	                                                                        }
	                                                                        isAuthenticated={
	                                                                            isAuthenticated
	                                                                        }
	                                                                        deselectAll={() =>
	                                                                            deselectAll
	                                                                        }
	                                                                        selected={
	                                                                            selected
	                                                                        }
	                                                                        selectedCompanies={
	                                                                            selectedCompanies
	                                                                        }
	                                                                        selectedNames={
	                                                                            selectedNames
	                                                                        }
	                                                                        setSelected={
	                                                                            setSelected
	                                                                        }
	                                                                        setSelectedCompanies={
	                                                                            setSelectedCompanies
	                                                                        }
	                                                                        setSelectedNames={
	                                                                            setSelectedNames
	                                                                        }
	                                                                        key={
	                                                                            result.id +
	                                                                            "-" +
	                                                                            i
	                                                                        }
	                                                                        setSelectedCompanyIndex={
	                                                                            handleSelectingCardIndex
	                                                                        }
	                                                                        showPopUpForIndex={
	                                                                            selectedCardIndex ===
	                                                                            i
	                                                                        }
	                                                                        searchTypeLook={
	                                                                            searchTypeLook
	                                                                        }
	                                                                        searchType={
	                                                                            searchType
	                                                                        }
	                                                                        source="results"
	                                                                        className={
	                                                                            classes.cardClass
	                                                                        }
	                                                                        type="company"
	                                                                    />
	                                                                ),
	                                                            )
	                                                            : null}
	                                                    </div>
	                                                )}
	                                            </>
	                                        ) : null}{" "}
                                    </div>
                                )}
                                {!tabLoading && searches?.results.length > 0 && previousScopesActive && (
                                   <PreviousScopes 
                                        currentSearch={currentSearch}
                                        currentSearchId={currentSearchId}
                                        searches={searches.results}
                                        setCurrentSearchId={setCurrentSearchId}
                                        scopesPage={recentScopesPage}
                                   />
                                )}
                                {
	                                companyResults?.pagination?.total_pages > 1 &&
	                                (listViewActive || cardViewActive) &&
	                                !tabLoading ? (
	                                    <Pagination
	                                        count={companyResults.pagination.total_pages}
	                                        onChangePage={handlePageChange}
	                                        page={Number(page)}
	                                    />
	                                ) : null
								}
                                {upArrowVisible && (
                                    <UpArrowResults
                                        onClick={scrollToTop}
                                        className={classes.arrowUp}
                                    />
                                )}
                            </div>
                        </div>
                        {user && user.csp && (searches?.results && searches.results.length > 0) && (
                            <div className={classes.rightColumn}>
                                <ResultsRightColumn
                                    currentSearchId={currentSearchId}
                                    currentSearch={currentSearch}
                                    selectedFilters={selectedFilters}
                                    conversationContext={
                                        conversationContext
                                    }
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Results;
