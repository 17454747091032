import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// UI components
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useRouterHook } from "../../../../common/useRouterHook";
//UI Components
import Modal from "../../../../components/Modal/Modal";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import Handshake from "../../../../assets/images/Handshake.png";
// Redux
import { getPlanSelector } from "../../../../store/credits/selectors";
import { getPlanRequest } from "../../../../store/credits/requests";
import {
    getSubscribeErrorMessageSelector,
    getSubscribeStatusSelector,
} from "../../../../store/auth/selectors";
// Constants
import { REQUEST_PENDING } from "../../../../constants/statuses";
import Switch from "@material-ui/core/Switch";
import { useIntercom } from "react-use-intercom";

const SubscribeConfirmationModal = ({
    dialogOpened,
    onChange,
    onConfirm,
    setSubscriptionDetails,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { openPage } = useRouterHook();
    const { show } = useIntercom();
    const plan = useSelector(getPlanSelector);
    const subscribeErrorMessage = useSelector(getSubscribeErrorMessageSelector);
    const subscribeStatus = useSelector(getSubscribeStatusSelector);
    const [subscribeDisabled, setSubscribeDisabled] = useState(false);
    const [planCheckboxChecked, setPlanCheckboxChecked] = useState(
        plan && plan.interval !== "month",
    );
    const [currencyCheckboxChecked, setCurrencyCheckboxChecked] = useState(
        plan && plan.currency !== "eur",
    );
    let { search } = useLocation();

    const handleCancel = () => {
        window.location.replace("https://www.valuer.ai/pricing");
        // openPage('/results');
        onChange();
    };

    const handleCheckboxChange = (type, e) => {
        const query = new URLSearchParams(search);
        const subscribe = query.get("subscribe");
        let yearly = query.get("yearly") || "";
        let monthly = query.get("monthly") || "";
        let currency = query.get("currency") || "";

        if (type === "plan") {
            if (monthly === "1") {
                monthly = "";
                yearly = "1";
            } else {
                yearly = "";
                monthly = "1";
            }
        }

        if (type === "currency") {
            currency = currency === "usd" ? "eur" : "usd";
        }

        const newUrl = `/search-type?subscribe=${subscribe}&monthly=${monthly}&yearly=${yearly}&currency=${currency}`;

        setPlanCheckboxChecked(!planCheckboxChecked);
        openPage(newUrl);
        setSubscriptionDetails({
            subscribe,
            yearly,
            monthly,
            currency,
        });
        dispatch(
            getPlanRequest({
                plan: subscribe,
                period: yearly ? "yearly" : "monthly",
                amount: yearly ? yearly : monthly,
                currency: currency,
            }),
        );
    };

    const handleConfirm = () => {
        setSubscribeDisabled(true);
        if (!subscribeDisabled) {
            onConfirm();
        }
    };

    const openIntercom = e => {
        e.preventDefault();

        if (process.env.REACT_APP_ENV === "prod") {
            show();
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(search);

        setPlanCheckboxChecked(query.get("monthly") !== "1");
        setCurrencyCheckboxChecked(query.get("currency") !== "eur");
    }, [plan]);

    return (
        <Modal
            onClose={onChange}
            maxWidth="lg"
            disableEscapeKeyDown={true}
            fullWidth={true}
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.subscribeConfirmationModal,
                scrollPaper: classes.dialogScrollPaper,
                paperWidthLg: classes.dialogPaperScrollPaper,
            }}
            dialogOpened={dialogOpened}
            dialogContentClassName={classes.dialogContent}
            dialogFilterClassName={classes.dialogFilter}>
            <img alt={t("subscribeConfirmModal.title")} src={Handshake} />
            <MuiDialogTitle
                disableTypography
                className={classes.dialogTitleWrapper}>
                <Typography
                    className={classes.subscribeTitle}
                    variant="subtitle1"
                    align="center"
                    component="div">
                    {t("subscribeConfirmModal.title")}
                </Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.subscribeDialogContent}>
                <Typography
                    className={classes.upgradePlan}
                    variant="body"
                    color="black"
                    align="center"
                    component="span">
                    {plan && t(`subscribeConfirmModal.${plan.slug}`)}
                </Typography>

                <div className={classes.checkboxContainer}>
                    <span className={classes.checkboxWrapper}>
                        <Typography
                            color={
                                planCheckboxChecked
                                    ? "textSecondary"
                                    : "textPrimary"
                            }>
                            {t("subscribeConfirmModal.interval.month")}
                        </Typography>
                        <Switch
                            color="success"
                            value={planCheckboxChecked}
                            defaultChecked={planCheckboxChecked}
                            onChange={() => handleCheckboxChange("plan")}
                            name="checkedPlan"
                        />
                        <Typography
                            color={
                                planCheckboxChecked
                                    ? "textPrimary"
                                    : "textSecondary"
                            }>
                            {t("subscribeConfirmModal.interval.year")}
                        </Typography>
                    </span>
                    <span className={classes.checkboxWrapper}>
                        <Typography
                            color={
                                currencyCheckboxChecked
                                    ? "textSecondary"
                                    : "textPrimary"
                            }>
                            {t("subscribeConfirmModal.currency.eur")}
                        </Typography>
                        <Switch
                            color="success"
                            value={currencyCheckboxChecked}
                            defaultChecked={currencyCheckboxChecked}
                            onChange={e => handleCheckboxChange("currency", e)}
                            name="checkedCurrency"
                        />
                        <Typography
                            color={
                                currencyCheckboxChecked
                                    ? "textPrimary"
                                    : "textSecondary"
                            }>
                            {t("subscribeConfirmModal.currency.usd")}
                        </Typography>
                    </span>
                </div>

                <div className={classes.price}>
                    <Typography>
                        {t("subscribeConfirmModal.price", {
                            price: plan && plan.price,
                            interval: plan && plan.interval,
                        })}
                    </Typography>
                </div>
                <div>
                    <span>{t("subscribeConfirmModal.subtitle")}</span>
                    <span>
                        <a onClick={openIntercom}>
                            {t("subscribeConfirmModal.subtitleLink")}
                        </a>
                    </span>
                </div>

                <div className={classes.confirmCtas}>
                    <Button
                        className={classes.goBackBtn}
                        variant="tertiarySuccess"
                        onClick={handleCancel}>
                        {t("subscribeConfirmModal.cancel")}
                    </Button>
                    <Button
                        variant="success"
                        loading={subscribeStatus === REQUEST_PENDING}
                        onClick={handleConfirm}>
                        {t("subscribeConfirmModal.confirm")}
                    </Button>
                </div>
                <div>{subscribeErrorMessage}</div>
            </MuiDialogContent>
        </Modal>
    );
};
export default SubscribeConfirmationModal;
