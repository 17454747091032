import { useState, useEffect } from "react";

const useSelectedFiltersCounter = ({ selectedFilters }) => {
    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);

    useEffect(() => {
        if (selectedFilters) {
            let count = 0;
            Object.entries(selectedFilters).map(filter => {
                filter[0] !== "subsectors" &&
                    filter[1] !== "" &&
                    filter[1] &&
                    filter[1].length !== 0 &&
                    filter[1] !== undefined &&
                    count++;
            });
            setSelectedFiltersCount(count);
        }
    }, [selectedFilters]);

    return {
        selectedFiltersCount,
    };
};

export default useSelectedFiltersCounter;
