import { useStyles } from "./style.js";
import MlChat from "../../../../components/MlChat/MlChat";

export const ResultsRightColumn = ({
    currentSearch,
    currentSearchId,
    selectedFilters,
    conversationContext,
    resultsFetched
}) => {
    const classes = useStyles();

    return (
        <div className={classes.rightColumnContainer}>
            {
                <div className={classes.chatBox}>
                    <MlChat
                        currentSearchId={currentSearchId}
                        contextName="search"
                        contextObject={currentSearch}
                        selectedFilters={selectedFilters}
                        conversationContext={conversationContext}
                        resultsFetched={resultsFetched}
                    />
                </div>
            }
        </div>
    );
};

export default ResultsRightColumn;
