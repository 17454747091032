import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing, typography }) => ({
    menu: {
        zIndex: 2,
        borderRadius: spacing(1),
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        "& > ul": {
            padding: `${spacing(1)}px 0`,
            "& > li": {
                fontFamily: "GT Flexa Mono, sans-serif",
                padding: `${spacing(1)}px ${spacing(3)}px`,
                ...typography.bodyXs,
                minWidth: 140,
                "&:hover": {
                    backgroundColor: `${palette.text.backgroundLight}`,
                    color: palette.error.main,
                },
            },
        },
    },
    disabled: {
        cursor: "default",
        opacity: 0.7,
        "&:hover": {
            backgroundColor: `transparent !important`,
            color: `${palette.text.lighter} !important`,
        },
    },
}));
