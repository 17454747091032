import { call } from "redux-saga/effects";
import axios from "axios";
import { getOrdersUrl, getExportCsvUrl } from "../../../constants/endpoints";

function* getOrders(type, team) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getOrdersUrl(type, team)}`,
        withCredentials: true,
    });
    return response.data;
}

function* getExportCsv(
    ids,
    type,
    name,
    idType,
    idValue,
    doc,
    sortBy,
    sortDirection,
    pageName,
) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getExportCsvUrl(
            ids,
            type,
            idType,
            idValue,
            doc,
            sortBy,
            sortDirection,
            pageName,
        )}`,
        responseType: "blob",
        withCredentials: true,
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
        "download",
        name ? `${name}.${doc}` : `valuer_company_reports.${doc}`,
    );
    document.body.appendChild(link);
    link.click();
    return response.data;
}

export { getOrders, getExportCsv };
