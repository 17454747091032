import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchTechnologiesRequest = createAction(
    `${REDUCER_PREFIX}/fetchTechnologiesRequest`,
);
export const fetchTechnologyRequest = createAction(
    `${REDUCER_PREFIX}/fetchTechnologyRequest`,
);
export const filterTechnologyCompaniesRequest = createAction(
    `${REDUCER_PREFIX}/filterTechnologyCompaniesRequest`,
);
export const filterTechnologyCompaniesCountRequest = createAction(
    `${REDUCER_PREFIX}/filterTechnologyCompaniesCountRequest`,
);
export const fetchTechnologyCompaniesPageRequest = createAction(
    `${REDUCER_PREFIX}/fetchTechnologyCompaniesPageRequest`,
);
export const fetchTechnologyCompanyRequest = createAction(
    `${REDUCER_PREFIX}/fetchTechnologyCompanyRequest`,
);
export const enrichTechnologyCompanyRequest = createAction(
    `${REDUCER_PREFIX}/enrichTechnologyCompanyRequest`,
);
export const enrichTechnologyRequest = createAction(
    `${REDUCER_PREFIX}/enrichTechnologyRequest`,
);
export const fetchTechnologyCompaniesChartRequest = createAction(
    `${REDUCER_PREFIX}/fetchTechnologyCompaniesChartRequest`,
);
export const fetchTechnologyCompaniesTeamSizeChartRequest = createAction(
    `${REDUCER_PREFIX}/fetchTechnologyCompaniesTeamSizeChartRequest`,
);
export const suggestTechnologyRequest = createAction(
    `${REDUCER_PREFIX}/suggestTechnologyRequest`,
);
