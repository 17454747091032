import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(
    ({ palette, spacing, shape, breakpoint, typography }) =>
        createStyles({
            dialogRoot: {
                display: "flex",
                justifyContent: "center",
                paddingLeft: 0,
                paddingRight: 0,
            },
            contentRoot: {
                padding: 0,
            },
            dialog: {
                padding: spacing(4),
                background: ({ isCreateNewProject }) =>
                    isCreateNewProject ? "#F0F5F4" : palette.common.white,
                border: ({ isCreateNewProject }) =>
                    isCreateNewProject && "1px solid black",
                position: ({ isCreateNewProject }) =>
                    isCreateNewProject && "relative",
                paddingRight: ({ isCreateNewProject }) =>
                    isCreateNewProject && "260px",
                borderRadius: spacing(2),
                [breakpoint.down("md")]: {
                    width: 528,
                },
                [breakpoint.down("sm")]: {
                    width: "100%!important",
                    borderRadius: "unset",
                    padding: spacing(2),
                },
            },
            createProjectModalImage: {
                position: ({ isCreateNewProject }) =>
                    isCreateNewProject && "absolute",
                right: ({ isCreateNewProject }) => isCreateNewProject && "0",
            },
            dialogFilter: {
                padding: `${spacing(5)}px !important`,
                background: `${palette.success.backgroundLight} !important`,
                borderRadius: 16,
                margin: spacing(3),
                width: "100%",
                maxWidth: 700,
                [breakpoint.down("sm")]: {
                    padding: spacing(2),
                    margin: 0,
                    maxHeight: "none",
                    height: "100%",
                    borderRadius: 0,
                },
            },
            dialogScrollPaper: {
                maxWidth: ({ isCreateNewProject }) =>
                    isCreateNewProject ? "unset" : 800,
                width: "100%",
                [breakpoint.down("sm")]: {
                    width: 500,
                },
            },
            dialogContent: {
                padding: 0,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
            },
            enrichedIcon: {
                position: "absolute",
                bottom: 2,
                right: -4,
                width: 12,
                height: 12,
                padding: 2,
                background: palette.common.white,
                borderRadius: 2,
            },
            closeButton: {
                display: ({ isCreateNewProject }) =>
                    isCreateNewProject && "none",
                position: "absolute !important",
                right: spacing(4),
                top: spacing(3),
                color: palette.grey[500],
                [breakpoint.down("sm")]: {
                    top: 11,
                    right: 15,
                },
            },
            projectTitle: {
                paddingTop: spacing(2),
                color: palette.text.main,
            },
            projectTitleInput: {
                color: palette.secondary.main,
                [breakpoint.down("sm")]: {
                    width: "100%!important",
                },
                "& input": {
                    [breakpoint.down("sm")]: {
                        width: "100%!important",
                    },
                    width: 430,
                },
            },
            projectDescription: {
                marginTop: spacing(3),
                marginBottom: spacing(1),
                color: palette.text.main,
            },
            dialogTitleWrapper: {
                padding: 0,
            },
            filterDialogTitle: {
                marginBottom: spacing(3),
                [breakpoint.down("sm")]: {
                    paddingRight: spacing(4),
                },
            },
            cancelButton: {
                marginRight: spacing(1),
                border: ({ isCreateNewProject }) =>
                    isCreateNewProject && "none !important",
                backgroundColor: ({ isCreateNewProject }) =>
                    isCreateNewProject && "transparent !important",
                color: ({ isCreateNewProject }) =>
                    isCreateNewProject && "black !important",
            },
            goBackBtn: {
                marginRight: spacing(3),
            },
            textarea: {
                borderColor: palette.secondary.dark,
                fontFamily: "GT Flexa, sans-serif",
                marginBottom: spacing(1),
                outline: "none",
                padding: spacing(1),
                resize: "none",
                borderRadius: spacing(1),
                color: palette.secondary.dark,
                width: 480,
                [breakpoint.down("md")]: {
                    width: "100%",
                },
                "&:focus": {
                    borderColor: palette.primary.dark,
                },
                "&::placeholder": {
                    fontSize: 14,
                    lineHeight: "18px",
                    fontFamily: "GT Flexa, sans-serif",
                    color: palette.text.lighter,
                },
                minHeight: 114,
                maxHeight: 114,
            },
            maximumCharactersWrap: {
                marginBottom: spacing(3),
                minHeight: 16,
            },
            maximumCharactersWarning: {
                color: palette.error.main,
            },
            addModal: {
                position: "fixed",
                left: 235,
                bottom: 0,
                right: 0,
                height: 70,
                boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.12)",
                background: palette.common.white,
                padding: spacing(3),
            },
            addPopup: {
                width: 234,
                height: "auto",

                background: palette.common.white,
                boxShadow:
                    "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
                borderRadius: shape.borderRadius,
                border: "1px solid",
                borderColor: palette.secondary.main,
                bottom: "55px",
                right: "0",
            },
            projectName: {
                width: "100%",
                padding: `${spacing(1)}px ${spacing(2)}px`,
                "&:hover": {
                    background: palette.secondary.main,
                },
                cursor: "pointer",
            },
            rowWrap: {
                marginTop: spacing(3),
                width: "calc(50% - 8px)",
                "&:first-child": {
                    marginRight: spacing(2),
                },
                [breakpoint.down("md")]: {
                    width: "100%",
                    "&:first-child": {
                        marginRight: 0,
                    },
                },
            },
            rowWrapButtons: {
                marginTop: spacing(3),
            },
            rowWrapYear: {
                marginTop: spacing(3),
                border: 0,
                margin: 0,
                display: "inline-flex",
                padding: 0,
                position: "relative",
                minWidth: 0,
                flexDirection: "column",
                verticalAlign: "top",
                width: "calc(50% - 8px)",
                "&:first-child": {
                    marginRight: spacing(2),
                },
                [breakpoint.down("md")]: {
                    width: "100%",
                    "&:first-child": {
                        marginRight: 0,
                    },
                },
            },
            resetFilter: {
                cursor: "pointer",
                position: "absolute",
                right: spacing(2),
                zIndex: 2,
            },
            arrowDown: {
                position: "absolute",
                right: spacing(2),
                zIndex: 2,
                cursor: "pointer",
            },
            disabledField: {
                "& $arrowDown": {
                    cursor: "default",
                },
            },
            ml24: {
                marginLeft: spacing(3),
            },
            ml16: {
                marginLeft: spacing(2),
                [breakpoint.down("md")]: {
                    marginLeft: 0,
                },
            },
            ml8: {
                marginLeft: spacing(1),
            },
            disableSubsectors: {
                background: palette.text.backgroundMain,
                border: `1px solid ${palette.text.lighter}!important`,
            },
            projectTitleError: {
                marginTop: spacing(1),
                color: palette.error.main,
            },
            projectTitleErrorWrap: {
                minHeight: 13,
            },
            inputClass: {
                border: ({ isCreateNewProject }) =>
                    isCreateNewProject && "1px solid black !important",
                borderRadius: spacing(1),
                width: "100%",
                padding: `${spacing(1)}px ${spacing(2)}px`,
                color: palette.secondary.dark,
                "&:focus": {
                    borderColor: palette.primary.dark,
                },

                "&::placeholder": {
                    fontSize: 14,
                    lineHeight: "18px",
                    fontFamily: "GT Flexa, sans-serif",
                    color: palette.text.lighter,
                },
            },
            labelClass: {
                color: palette.text.main,
                fontSize: 14,
                lineHeight: "18px",
            },
            multiselect: {
                border: `1px solid ${palette.secondary.dark}`,
                marginTop: `${spacing(3)}px !important`,
                width: "100% !important",
            },
            row: {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                [breakpoint.down("md")]: {
                    width: "100%",
                    flexDirection: "column",
                },
            },
            rows: {
                display: "flex",
                justifyContent: "space-between",
                borderBottom: `1px solid ${palette.text.lightest}`,
                flexDirection: "column",
                paddingBottom: spacing(3),
            },
            multiselectYear: {
                marginTop: 6,
            },
            multiselectFromTo: {
                width: "100%",
            },
            multiselectWrap: {
                width: "50%",
            },
            resetFilterAction: {
                color: palette.text.lighter,
                paddingLeft: 0,
            },
            showResultsButton: {
                // background: palette.primary.dark
            },
            flexColumn: {
                display: "flex",
                flexDirection: "column",
            },
            cancelAndCreate: {
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                [breakpoint.down("sm")]: {
                    flexDirection: "column-reverse",
                    alignItems: "center",
                },
            },
            subscribeConfirmationModal: {
                minWidth: 500,
                padding: spacing(7),
                background: palette.success.backgroundLight,
                borderRadius: spacing(2),
                display: "flex",
                alignItems: "center",
            },
            subscribeTitle: {
                margin: spacing(5),
            },
            subscribeDialogContent: {
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            },
            upgradePlan: {
                fontFamily: "GT Flexa Mono, sans-serif",
                padding: "6px 16px",
                background: palette.success.backgroundDark,
                borderRadius: 8,
                marginBottom: spacing(3),
            },
            confirmCtas: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: spacing(3),
            },
            price: {
                marginBottom: spacing(1),
            },
            priceAmount: {
                textTransform: "uppercase",
            },
            priceLabel: {
                marginRight: 7,
            },
            mono: {
                fontFamily: "GT Flexa Mono, sans-serif",
                [breakpoint.down("md")]: {
                    ...typography.bodyButton,
                },
            },
            checkboxRoot: {
                padding: `0 9px 0`,
                "& svg": {
                    fill: palette.checkboxBorder,
                    width: 19,
                    height: 19,
                },
            },
            checkboxContainer: {
                marginTop: spacing(2),
                marginBottom: spacing(4),
            },
            checkboxWrapper: {
                marginLeft: spacing(2),
                marginRight: spacing(2),
            },
            companyPreviewBox: {
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
            },
            companyPreviewName: {
                paddingLeft: spacing(1),
            },
            companyPreviewDescription: {
                fontWeight: 300,
                marginBottom: spacing(3),
            },
            companyPreviewLeft: {
                padding: 0,
                display: "flex",
                flexDirection: "column",
                flex: 1,
            },
            companyPreviewRight: {
                position: "relative",
                width: 280,
                minHeight: 496,
                background: palette.success.lighter,
                padding: `${spacing(7)}px ${spacing(6)}px ${spacing(
                    5,
                )}px ${spacing(6)}px`,
            },
            companyPreviewInner: {
                "&:first-child": {
                    padding: 0,
                },
            },
            seeResults: {
                position: "absolute",
                bottom: spacing(3),
            },
            sectionValue: {
                fontWeight: 300,
                paddingBottom: spacing(2),
            },
            companyPreviewPaper: {
                borderRadius: `${spacing(4)}px`,
                [breakpoint.up("md")]: {
                    maxWidth: 715,
                    minWidth: 715,
                },
                [breakpoint.up("lg")]: {
                    maxWidth: 1037,
                    minWidth: 1037,
                },
            },
            companyPreviewHeader: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: spacing(2),
            },
            companyPreviewTextContent: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
            companyPreviewContentText: {
                fontWeight: 275,
                marginTop: spacing(3),
                marginRight: spacing(3),
            },
            companyPreviewEnrichmentButton: {
                whiteSpace: "nowrap",
            },
            companyPreviewTextBox: {
                marginTop: spacing(6),
                paddingBottom: spacing(5),
            },
            companyInfoBox: {
                marginBottom: spacing(4),
                display: "flex",
                flexDirection: "column",
            },
            companyInfoLabel: {
                color: palette.common.black,
                fontWeight: 300,
            },
            companyInfoValue: {
                color: palette.common.black,
                fontWeight: 700,
            },
            companyFundings: {
                color: palette.success.dark,
            },
            companyWebsite: {
                wordBreak: "break-all",
                fontFamily: "GT Flexa, sans-serif",
            },
            divider: {
                width: "100%",
                height: "1px",
                background: palette.common.black,
                opacity: 0.1,
            },
            percentageMatch: {
                display: "flex",
                alignItems: "center",
                [breakpoint.down("lg")]: {
                    marginLeft: spacing(1),
                },
            },
            scoreText: {
                color: palette.common.black,
                marginLeft: spacing(1),
            },
            companyRedirect: {
                fontFamily: "GT Flexa Mono,  sans-serif",
                color: palette.primary.dark,
                whiteSpace: "nowrap",
            },
            scoreBar: {
                position: "relative",
                width: 48,
                height: 5,
                background: palette.text.backgroundMain,
                marginTop: 4,
                marginBottom: 0,
                marginLeft: 10,
                borderRadius: spacing(1),
            },
            scoreBarInside: {
                position: "absolute",
                height: 5,
                top: 0,
                borderRadius: spacing(1),
                background: palette.primary.dark,
            },
            weakScore: {
                background: palette.error.dark,
            },
            goodScore: {
                background: palette.warning.dark,
            },
            strongScore: {
                background: palette.success.dark,
            },
            weakText: {
                color: palette.error.dark,
            },
            goodText: {
                color: palette.warning.dark,
            },
            strongText: {
                color: palette.success.dark,
            },
            matchScoreBlock: {
                display: "flex",
                flexDirection: "column",
                width: 118,
                alignItems: "flex-end",
            },
            matchScore: {
                marginLeft: spacing(4),
                width: 84,
            },
            matchScoreLabel: {
                marginBottom: 2,
            },
            titleWrap: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden",
                justifyContent: "center",
                marginTop: -4,
            },
            totalMatch: {
                border: `1px solid ${palette.common.black}`,
                borderRadius: spacing(2),
                "&:hover": {
                    border: `1px solid ${palette.primary.dark}`,
                },
            },
            header: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: `${spacing(5)}px ${spacing(5)}px ${spacing(
                    3,
                )}px ${spacing(5)}px`,
                alignItems: "center",
            },
            avatar: {
                border: "1px solid #F0F1F3",
                borderRadius: spacing(1),
                padding: 4,
                marginRight: spacing(2),
                boxShadow: "0px 2px 100px rgba(0, 0, 0, 0.1)",
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
            },
            sections: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: spacing(2),
                flex: 1,
                marginBottom: spacing(2),
            },
            sectionsColumns: {
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: spacing(2),
                flex: 1,
                marginBottom: spacing(2),
            },
            previewModalFooter: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                padding: `${spacing(2)}px ${spacing(5)}px`,
            },
            centralContent: {
                flex: 1,
                height: "496px",
                maxHeight: "496px",
                overflowY: "auto",
                paddingLeft: spacing(5),
                paddingRight: spacing(5),
            },
            section: {
                flex: "calc(50% - 8px)",
            },
            sectionFullWidth: {
                flex: 1,
            },
            actionItems: {
                display: "flex",
                flexDirection: "row",
            },
            lookAlikeIcon: {
                width: 29,
            },
            actionIconWrapper: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 100,
                cursor: "pointer",
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "none",
                    "& $actionIcon": {
                        width: 32,
                        height: 32,
                    },
                    "& $lookAlikeIcon": {
                        width: 36,
                    },
                    "& $actionText": {
                        fontWeight: "bold",
                    },
                },
            },
            link: {
                "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                },
            },
            actionIconContent: {
                height: 32,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            },
            actionIcon: {},
            actionText: {
                textAlign: "center",
                lineHeight: "12px",
                minHeight: 24,
                marginTop: 4,
            },
            typeWrapper: {},
            typeTag: {
                padding: "6px 16px",
                borderRadius: spacing(1),
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "GT Flexa Mono, sans-serif",
                display: "inline-block",
            },
            CompanyColor: {
                background: palette.success.backgroundLight,
            },
            industryColor: {
                background: palette.warning.backgroundDark,
            },
            technologyColor: {
                background: palette.primary.backgroundLight,
            },
            ProjectColor: {
                background: palette.error.backgroundLight,
            },
            searchQueryColor: {
                background: palette.secondary.backgroundLight,
            },
            loading: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
            }
        }),
);
