import React from "react";
import { useDispatch } from "react-redux";
// UI components
import Box from "@material-ui/core/Box";
import Typography from "../../../../../components/Typography/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Grow from "@material-ui/core/Grow";
import Button from "../../../../../components/Button/Button";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
// Redux

const RemoveFromRecentSearchesModal = ({
    onClose,
    dialogOpened,
    deleteAction,
    search,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleDeleteSearch = e => {
        deleteAction(e, search);
        onClose();
    };

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialogFilter,
                scrollPaper: classes.dialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    onClose(event, reason);
                }
            }}
            open={dialogOpened}>
            <MuiDialogTitle
                disableTypography
                className={classes.dialogTitleWrapper}>
                <Typography variant="subtitle1" className={classes.dialogTitle}>
                    <strong>
                        {t("results.removeFromRecentSearchesTitle")}
                    </strong>
                </Typography>
                <Typography
                    variant="paragraph"
                    className={classes.dialogSubtitle}>
                    {t("results.removeFromRecentSearchesSubtitle")}
                </Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <Box display="flex" justifyContent="flex-end">
                    <Button onClick={onClose} variant="tertiary">
                        {t("warningModal.noCancel")}
                    </Button>
                    <Button
                        onClick={e => handleDeleteSearch(e)}
                        className={classes.goBackButton}>
                        {t("results.deleteSearch")}
                    </Button>
                </Box>
            </MuiDialogContent>
        </Dialog>
    );
};
export default RemoveFromRecentSearchesModal;
