import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            width: "100%",
            margin: `${spacing(9)}px auto`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        projectRoot: {
            width: "100%",
            padding: spacing(5),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: palette.common.white,
            borderRadius: spacing(3),
            "& :first-child": {
                [breakpoint.down("md")]: {
                    flexDirection: "column",
                },
            },
        },
        title: {
            marginTop: spacing(2),
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        subtitle: {
            marginTop: spacing(2),
            marginBottom: spacing(4),
        },
        image: {
            marginTop: spacing(5),
        },
        button: {
            marginTop: spacing(6),
        },
        projectNoResultsBodyWrapper: {
            width: "50%",
            [breakpoint.down("md")]: {
                width: "100%",
            },
        },
        projectNoResultsBody: {
            padding: spacing(5),
            [breakpoint.down("md")]: {
                padding: 0,
            },
        },
        projectNoResultsBodyText: {
            padding: `${spacing(4)}px 0`,
        },
        projectNoResultsBodyTextTitle: {
            fontWeight: "500",
            marginBottom: spacing(2),
        },
        projectNoResultsBodyTextSubtitle: {
            fontWeight: "300",
        },
        projectNoResultsImageWrapper: {
            width: "50%",
            textAlign: "center",
            [breakpoint.down("md")]: {
                width: "100%",
            },
        },
        selectProducts: {
            // marginTop: spacing(3)
        },
        startSearchButton: {
            // width: 320,
            // height: 56,
            [breakpoint.down("md")]: {
                width: "100%",
            },
        },
    }),
);
