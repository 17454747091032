import React from "react";
import classnames from "classnames";
import useStyles from "./style";

const Card = React.forwardRef(
    (
        {
            className,
            children,
            active,
            variant,
            onClick,
            testId,
            isSuggestedCompany,
            ...props
        },
        ref,
    ) => {
        const classes = useStyles({ isSuggestedCompany });
        const cardClasses = classnames(
            className,
            classes.card,
            classes[variant],
        );

        return (
            <div
                className={cardClasses}
                ref={ref}
                onClick={onClick}
                test-id={testId}
                {...props}>
                {children}
            </div>
        );
    },
);

export default Card;

Card.displayName = "UI Card";
