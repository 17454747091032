import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    industriesPagination: {},
    industriesTotalPages: 0,
    industriesTotalCount: 0,
    industriesCurrentPage: null,
    industry: {},
    company: {},
    industryCompanies: [],
    fetchIndustriesStatus: REQUEST_UNDEFINED,
    fetchIndustryStatus: REQUEST_UNDEFINED,
    fetchIndustryCompanyStatus: REQUEST_UNDEFINED,
    getIndustryCompaniesStatus: REQUEST_UNDEFINED,
    getIndustryCompaniesCountStatus: REQUEST_UNDEFINED,
    getIndustryCompaniesChartStatus: REQUEST_UNDEFINED,
    getIndustryCompaniesTeamSizeChartStatus: REQUEST_UNDEFINED,
    enrichIndustryStatus: REQUEST_UNDEFINED,
    totalCount: null,
    currentPage: 1,
    totalPages: 0,
    industryCompaniesCount: null,
    industryCompaniesChart: null,
    industryCompaniesTeamSizeChart: null,
    unspecifiedAll: false,
    filterStats: null,
    lastFilteredField: null,
};

export const REDUCER_PREFIX = "industries";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        setIndustries: (state, { payload }) => {
            state.pool = payload.results;
            state.industriesPagination = payload.pagination;
            state.industriesTotalPages = payload.pagination.total_pages;
            state.industriesTotalCount = payload.pagination.total;
            state.industriesCurrentPage = payload.pagination.current_page;
        },
        setIndustryCompany: (state, { payload }) => {
            state.company = payload.data;
        },
        setIndustry: (state, { payload }) => {
            state.industry = payload.data;
        },
        fetchIndustriesPending: state => {
            state.fetchIndustriesStatus = REQUEST_PENDING;
        },
        fetchIndustriesSuccess: state => {
            state.fetchIndustriesStatus = REQUEST_SUCCESS;
        },
        fetchIndustriesFailure: state => {
            state.fetchIndustriesStatus = REQUEST_FAILURE;
        },
        fetchIndustryPending: state => {
            state.fetchIndustryStatus = REQUEST_PENDING;
        },
        fetchIndustrySuccess: state => {
            state.fetchIndustryStatus = REQUEST_SUCCESS;
        },
        fetchIndustryFailure: state => {
            state.fetchIndustryStatus = REQUEST_FAILURE;
        },
        fetchIndustryCompanyPending: state => {
            state.fetchIndustryCompanyStatus = REQUEST_PENDING;
        },
        fetchIndustryCompanySuccess: state => {
            state.fetchIndustryCompanyStatus = REQUEST_SUCCESS;
        },
        fetchIndustryCompanyFailure: state => {
            state.fetchIndustryCompanyStatus = REQUEST_FAILURE;
        },
        fetchIndustryCompaniesPending: state => {
            state.getIndustryCompaniesStatus = REQUEST_PENDING;
        },
        fetchIndustryCompaniesSuccess: state => {
            state.getIndustryCompaniesStatus = REQUEST_SUCCESS;
        },
        fetchIndustryCompaniesFailure: state => {
            state.getIndustryCompaniesStatus = REQUEST_FAILURE;
        },
        fetchIndustryCompaniesCountPending: state => {
            state.getIndustryCompaniesCountStatus = REQUEST_PENDING;
        },
        fetchIndustryCompaniesCountSuccess: state => {
            state.getIndustryCompaniesCountStatus = REQUEST_SUCCESS;
        },
        fetchIndustryCompaniesCountFailure: state => {
            state.getIndustryCompaniesCountStatus = REQUEST_FAILURE;
        },
        enrichIndustryPending: state => {
            state.enrichIndustryStatus = REQUEST_PENDING;
        },
        enrichIndustrySuccess: state => {
            state.enrichIndustryStatus = REQUEST_SUCCESS;
        },
        enrichIndustryFailure: state => {
            state.enrichIndustryStatus = REQUEST_FAILURE;
        },
        setIndustryCompaniesCount: (
            state,
            {
                payload: {
                    sliceData,
                    lastFilteredField,
                    lastFilteredFieldValue,
                },
            },
        ) => {
            state.industryCompaniesCount = sliceData.companies.pagination.total;
            let lastFilteredState = { ...state.filterStats[lastFilteredField] };
            if (
                (lastFilteredField === "investment_stages" ||
                    lastFilteredField === "countries" ||
                    lastFilteredField === "regions") &&
                lastFilteredFieldValue &&
                lastFilteredFieldValue.length > 0
            ) {
                state.filterStats = {
                    ...sliceData.filter_stats,
                    [lastFilteredField]: lastFilteredState,
                };
            } else {
                state.filterStats = sliceData.filter_stats;
            }
        },
        setLastFilteredField: (state, { payload }) => {
            state.lastFilteredField = payload;
        },
        setIndustryResults: (state, { payload }) => {
            state.industryCompanies = [];
            payload.companies &&
                payload.companies.results.forEach(company => {
                    const {
                        name,
                        degree_of_fit,
                        deck,
                        sectors,
                        sector_slugs,
                        subsectors,
                        subsector_slugs,
                        description,
                        founded_date,
                        hq_city,
                        hq_country,
                        investment_stage,
                        number_of_employees,
                        total_funding_amount_dollars,
                        id,
                        slug,
                        success_potential_score,
                        growth_score,
                        maturity_score,
                        innovation_score,
                        completeness_score,
                        website,
                        sustainability_score,
                        enrichment_request,
                        ready_for_release,
                        priority,
                        search_id,
                        valuer_score,
                        user_vote,
                        voters,
                        comments_cnt,
                        projects,
                    } = company;

                    const uniqueResult = {
                        id,
                        name,
                        degree_of_fit,
                        deck,
                        sectors,
                        sector_slugs,
                        subsectors,
                        subsector_slugs,
                        description,
                        founded_date,
                        hq_city,
                        hq_country,
                        investment_stage,
                        number_of_employees,
                        total_funding_amount_dollars:
                            total_funding_amount_dollars
                                ? Math.round(
                                      parseInt(total_funding_amount_dollars),
                                  )
                                : "",
                        success_potential_score,
                        growth_score,
                        maturity_score,
                        innovation_score,
                        slug,
                        website,
                        sustainability_score,
                        completeness_score,
                        enrichment_request,
                        ready_for_release,
                        priority,
                        search_id,
                        valuer_score,
                        user_vote,
                        voters,
                        comments_cnt,
                        projects,
                    };
                    if (
                        !state.industryCompanies.filter(e => e.id === id)
                            .length > 0
                    ) {
                        state.industryCompanies.push(uniqueResult);
                    }
                });
            state.industryCompaniesCount = payload.companies.pagination
                ? payload.companies.pagination.total
                : payload.companies.results.length;
            state.totalCount = payload.companies.pagination
                ? payload.companies.pagination.total
                : payload.companies.results.length;
            state.currentPage = payload.companies.pagination
                ? payload.companies.pagination.current_page
                : 1;
            state.totalPages = payload.companies.pagination
                ? payload.companies.pagination.total_pages
                : 1;
            state.filterStats = payload.filter_stats;
            // state.getResultsPaginationStatus = REQUEST_UNDEFINED;
        },
        clearIndustry: state => {
            state.industry = null;
            state.totalCount = null;
            state.filterStats = null;
            state.getResultsPaginationStatus = REQUEST_UNDEFINED;
            state.industryCompanies = [];
            state.currentPage = 1;
            state.totalPages = null;
        },
        clearCompaniesResults: state => {
            state.industryCompanies = [];
            state.currentPage = 1;
            state.totalPages = null;
        },
        setIsGuest: (state, { payload }) => {
            state.isGuest = payload;
        },
        getIndustryCompaniesChartPending: state => {
            state.getIndustryCompaniesChartStatus = REQUEST_PENDING;
        },
        getIndustryCompaniesChartSuccess: state => {
            state.getIndustryCompaniesChartStatus = REQUEST_SUCCESS;
        },
        getIndustryCompaniesChartFailure: state => {
            state.getIndustryCompaniesChartStatus = REQUEST_FAILURE;
        },
        setIndustryCompaniesChart: (state, { payload }) => {
            state.industryCompaniesChart = payload;
        },
        getIndustryCompaniesTeamSizeChartPending: state => {
            state.getIndustryCompaniesTeamSizeChartStatus = REQUEST_PENDING;
        },
        getIndustryCompaniesTeamSizeChartSuccess: state => {
            state.getIndustryCompaniesTeamSizeChartStatus = REQUEST_SUCCESS;
        },
        getIndustryCompaniesTeamSizeChartFailure: state => {
            state.getIndustryCompaniesTeamSizeChartStatus = REQUEST_FAILURE;
        },
        setIndustryCompaniesTeamSizeChart: (state, { payload }) => {
            state.unspecifiedAll = payload.Unspecified.percentage === 100;
            delete payload.Unspecified;
            state.industryCompaniesTeamSizeChart = payload;
        },
    },
});
export { reducer, actions };
