import { all, takeEvery } from "redux-saga/effects";
import { fetchInvoicesRequest } from "../requests";
import { fetchInvoices } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchInvoices() {
    yield takeEvery(convertTypeFromAction(fetchInvoicesRequest), () =>
        fetchInvoices(),
    );
}
function* saga() {
    yield all([watchFetchInvoices()]);
}
export { saga };
