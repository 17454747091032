import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        backLinkWrap: {
            cursor: "pointer",
            stroke: "black",
        },
        backLink: {
            marginLeft: spacing(1),
            color: palette.secondary.light,
        },
        shareIcon: {
            marginLeft: spacing(1),
        },
        hiddenInput: {
            color: "transparent",
            position: "absolute",
            background: "transparent",
            border: "none",
            zIndex: 1,
            left: 0,
            top: "-60px",
        },
        moreButton: {
            minWidth: 0,
            padding: 0,
        },
        moreIcon: {
            fill: palette.secondary.dark,
        },
        moreIconOpened: {
            fill: palette.primary.dark,
        },
        tooltipCopy: {
            backgroundColor: palette.secondary.dark,
            boxShadow:
                "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
            borderRadius: spacing(1),
            color: palette.common.white,
            right: -18,
            top: -35,
            position: "absolute",
            height: 30,
            width: 180,
            padding: spacing(1),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
        },
        checkImg: {
            marginRight: spacing(1),
        },
        requestReportButton: {
            background: palette.secondary.white,
            border: `1px solid ${palette.secondary.dark}`,
            color: palette.secondary.dark,
            "&:hover": {
                background: "transparent",
                borderColor: palette.secondary.dark,
            },
            marginLeft: spacing(2),
        },
        shareButton: {
            background: palette.secondary.dark,
            border: "none",
            color: palette.common.white,
            height: 40,
            "&:hover": {
                background: palette.secondary.dark,
                border: "none",
            },
            marginLeft: spacing(2),
        },
        addToProject: {
            cursor: "pointer",
            padding: `0 ${spacing(2)}px`,
            height: 42,
            borderRadius: spacing(3),
            "&:hover": {
                background: palette.primary.backgroundLight,
                border: "none",
            },
            display: "flex",
            alignItems: "center",
            [breakpoint.down("lg")]: {
                display: "none",
            },
        },
        arrowImg: {
            marginLeft: spacing(1),
        },
        addPopup: {
            width: 185,
            maxHeight: 202,
            background: palette.common.white,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: spacing(1),
            border: "1px solid",
            borderColor: palette.text.backgroundDark,
            top: 45,
            right: 160,
            overflow: "auto",
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
            "&::-webkit-scrollbar": {
                width: 2,
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: palette.text.main,
                borderRadius: 0,
            },
            zIndex: 3,
            display: "flex",
            position: "absolute",
            flexDirection: "column",
        },
        projectName: {
            width: "100%",
            padding: `${spacing(2)}px ${spacing(3)}px`,
            "&:hover": {
                background: palette.text.backgroundLight,
            },
            cursor: "pointer",
            display: "flex",
        },
        disabled: {
            opacity: 0.5,
        },
        rotateArrow: {
            transform: "rotate(180deg)",
        },
        arrowIcon: {
            marginLeft: spacing(1),
        },
        actions: {
            display: "flex",
            "& > *": {
                marginLeft: spacing(1),
            },
        },
        tags: {},
        tagsPopoverContent: {
            padding: spacing(2),
        },
        tagsPopoverTags: {
            marginTop: spacing(2),
        },
        tagsPopoverTag: {
            marginBottom: spacing(1),
            padding: spacing(1),
            display: "flex",
            justifyContent: "space-between",
            "&:hover": {
                backgroundColor: palette.secondary.backgroundLight,
                cursor: "pointer",
            },
        },
        tagsBtn: {
            background: palette.secondary.dark,
            border: "none",
            color: palette.common.white,
            height: 40,
            "&:hover": {
                background: palette.secondary.dark,
                border: "none",
            },
        },
        tagItem: {
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
        },
        tagActions: {
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
        },
        removeTag: {
            marginLeft: spacing(1),
            "&:hover $removeTagIcon": {
                display: "none",
            },
            "&:hover $removeTagIconHover": {
                display: "block",
            },
        },
        removeTagIcon: {},
        removeTagIconHover: {
            display: "none",
        },
        formControlLabel: {
            color: palette.grey.main,
            width: "100%",
        },
        checkboxTag: {
            padding: `0 9px 0`,
            "& svg": {
                fill: palette.checkboxBorder,
                width: 19,
                height: 19,
            },
        },
        flexSpaceCenter: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        flexRelative: {
            display: "flex",
            position: "relative",
        },
        flexCenter: {
            display: "flex",
            alignItems: "center",
        },
        disabledMenuItem: {
            backgroundColor: `transparent !important`,
            color: `${palette.text.lighter} !important`,
            cursor: "default",
        },
        actionIconWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            "&:hover": {
                cursor: "pointer",
                "& $actionIcon": {
                    width: 32,
                    height: 32,
                },
                "& $actionText": {
                    fontWeight: "bold",
                },
            },
        },
        actionIconContent: {
            height: 32,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },
        actionIcon: {},
        actionText: {
            textAlign: "center",
            lineHeight: "12px",
            minHeight: 24,
            marginTop: 4,
        },
    }),
);
