import React from "react";
import classnames from "classnames";
import { Button, Typography, Tooltip } from "@material-ui/core";
import { useStyles } from "./style.js";
import { ReactComponent as NewPumaLogo } from "../../../assets/icons/MangaPuma.svg";
import { ReactComponent as ScopeIcon } from "../../../assets/icons/ScopeIcon.svg";
import { ReactComponent as PawIcon } from "../../../assets/icons/Paw.svg";
import { ReactComponent as QuestionMarkTooltip } from "../../../assets/icons/question-mark-tooltip.svg";

const ScopeSelect = () => {
    const classes = useStyles();

    return (
        <div className={classnames([classes.mainWrapper])}>
            <div className={classnames([classes.titleWrapper])}>
                <div className={classnames([classes.image])}>
                    <NewPumaLogo className={classes.newPumaLogo} />
                </div>
                <div className={classnames([classes.titleTextWrapper])}>
                    <Typography
                        variant="h6"
                        className={classnames([classes.title])}>
                        <strong>Choose Your Approach</strong>
                    </Typography>
                </div>
                <Tooltip
                    title={
                        <Typography
                            color="white"
                            variant="body2"
                            className={classes.infoTooltipText}>
                            In the Assisted Approach, you are guided through 5
                            steps which will result in an auto-generation of
                            your Scope Prompt, as a concise, 150-word summary
                            integrating your professional context, technological
                            innovations, challenges, user needs, and practical
                            applications. This assists you and PUMA to fetch the
                            most relevant data from our database, for an
                            analysis well aligned with your specific needs. 
                            <br></br><br></br>
                            Alternatively, the Unassisted Approach grants you
                            complete autonomy to conduct your analysis using the
                            available resources, ideal for those who prefer
                            direct control over their strategic exploration.
                        </Typography>
                    }
                    arrow>
                    <QuestionMarkTooltip
                        className={classnames([classes.scopeSelectTooltip])}
                    />
                </Tooltip>
            </div>
            <div className={classnames([classes.contentWrapper])}>
                <div className={classnames([classes.searchWrapper])}>
                    <div style={{ marginTop: "30px" }}>
                        <Typography
                            className={classnames([
                                classes.scopeGuidanceHelpText,
                            ])}>
                            The Scope Prompt is the initial question or topic
                            you provide, setting the primary context for your
                            research / analysis with PUMA, the proprietary
                            assistant at Valuer. This prompt guides the angle of
                            your Scope and the retrieval of results from the
                            proprietary Valuer database, which include detailed
                            data relevant to your Scope’s inquiry. These
                            profiles may contain information on company business
                            models, technologies, products, and more, ensuring
                            the responses you receive are not only accurate but
                            also directly relevant to your specific needs.
                        </Typography>
                    </div>
                    <div>
                        <Button
                            onClick={() =>
                                (window.location.href = "/search-type/guided")
                            }
                            className={classnames([classes.promtAssistantBtn])}>
                            <PawIcon
                                className={classnames([
                                    classes.scopeSelectBtnIcon,
                                ])}
                            />
                            Start Scope Assistant
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={() =>
                                (window.location.href = "/search-type/free")
                            }
                            className={classnames([classes.inputByPromptBtn])}>
                            <ScopeIcon
                                className={classnames([
                                    classes.scopeSelectBtnIcon,
                                ])}
                            />
                            Create Non-Assisted Scope
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScopeSelect;
