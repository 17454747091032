import React from "react";
import classnames from "classnames";
// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Tag from "../../../../components/Tag/Tag";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import ClusterPreview from "../../../../assets/images/ClusterPreview.png";

const PreviewClusterModal = ({ dialogOpened, onChange }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Dialog
            onClose={onChange}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    onChange(event, reason);
                }
            }}
            open={dialogOpened}>
            <IconButton className={classes.closeButton} onClick={onChange}>
                <img
                    src={CloseIcon}
                    alt={t("scope.step2")}
                    className={classes.stepIcon}
                />
            </IconButton>
            <Typography
                className={classes.clusterTitle}
                variant="paragraph"
                color="black"
                component="div">
                {t("industryProfile.whatsaCluster")}
            </Typography>
            <MuiDialogContent className={classes.dialogContent}>
                <Typography
                    className={classnames([classes.mono, classes.label])}
                    variant="label"
                    color="textLight"
                    component="div">
                    {t(`industryProfile.clusterDescription`)}
                </Typography>
                <Typography
                    className={classes.clusterDesc}
                    variant="bodyButton"
                    color="text"
                    component="div">
                    {t(`industryProfile.clusterDesc`)}
                </Typography>
                <img src={ClusterPreview} className={classes.clusterImage} />
            </MuiDialogContent>
        </Dialog>
    );
};
export default PreviewClusterModal;
