import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, shape, typography, breakpoint }) =>
        createStyles({
            root: {
                flex: "calc(50% - 8px)",
                maxWidth: "calc(50% - 8px)",
                position: "relative",
                marginBottom: spacing(2),
                color: "unset",
                textDecoration: "unset",
                border: `1px solid ${palette.common.white}`,
                "&:nth-child(odd)": {
                    marginRight: spacing(2),
                    [breakpoint.down("sm")]: {
                        marginRight: 0,
                    },
                },
                "&:hover": {
                    boxShadow:
                        "0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)",
                    cursor: "pointer",
                    textDecoration: "unset",
                    borderRadius: spacing(2),
                },
                [breakpoint.down("sm")]: {
                    width: "100%",
                    flex: "100%",
                    maxWidth: "100%",
                },
            },
            header: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "space-between",
                [breakpoint.down("xl")]: {
                    flexDirection: "column",
                    paddingBottom: 13,
                    overflow: "hidden",
                },
            },
            headerLeft: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            },
            name: {
                maxWidth: 190,
                display: "-webkit-box",
                maxHeight: 32,
                overflow: "hidden",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": 1,
                [breakpoint.down("xl")]: {
                    display: "-webkit-box",
                    overflow: "hidden",
                    maxWidth: "none",
                    "-webkit-box-orient": "vertical",
                    "-webkit-line-clamp": 1,
                },
            },
            projectSourceName: {
                maxWidth: "none",
            },
            scoreBar: {
                position: "relative",
                width: 58,
                height: 6,
                background: palette.text.backgroundMain,
                marginTop: 4,
                marginBottom: 0,
                marginLeft: 10,
                borderRadius: spacing(1),
            },
            scoreBarInside: {
                position: "absolute",
                height: 6,
                top: 0,
                width: 25,
                borderRadius: spacing(1),
                background: palette.primary.dark,
            },
            weakScore: {
                background: palette.error.dark,
            },
            goodScore: {
                background: palette.warning.dark,
            },
            strongScore: {
                background: palette.success.dark,
            },
            weakText: {
                color: palette.error.dark,
            },
            goodText: {
                color: palette.warning.dark,
            },
            strongText: {
                color: palette.success.dark,
            },
            matchScoreBlock: {
                paddingLeft: 30,
                borderLeft: `1px solid rgba(0, 0, 0, 0.1)`,
                display: "flex",
                flexDirection: "column",
                minWidth: 140,
                [breakpoint.down("xl")]: {
                    padding: 0,
                    flexDirection: "row",
                    border: "none",
                    alignItems: "baseline",
                },
            },
            avatar: {
                border: "1px solid #F0F1F3",
                borderRadius: spacing(1),
                padding: 4,
                marginRight: spacing(2),
            },
            match: {
                color: palette.success.main,
                display: "flex",
                alignItems: "center",
            },
            infoItemMobile: {
                [breakpoint.down("xl")]: {
                    color: palette.text.main,
                },
            },
            infoIconMatch: {
                marginleft: 4,
            },
            sdgTag: {
                marginRight: spacing(1),
                marginTop: spacing(1),
                height: "20px",
            },
            description: {
                color: palette.text.main,
                "-webkit-line-clamp": 6,
                "-webkit-box-orient": "vertical",
                display: "-webkit-box",
                overflowY: "hidden",
                maxHeight: 148,
                height: 148,
            },
            descriptionWrapper: {
                position: "relative",
                marginBottom: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? spacing(1) : spacing(3),
                borderBottom: ({ isSuggestedCompany }) =>
                    isSuggestedCompany
                        ? `1px solid ${palette.text.lightest}`
                        : "none",
                paddingBottom: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? spacing(1) : 0,
                width: "100%",
                "-webkit-line-clamp": 6,
                "-webkit-box-orient": "vertical",
                display: "-webkit-box",
                overflowY: "hidden",
                maxHeight: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? "none" : 148,
                [breakpoint.down("xl")]: {
                    marginBottom: ({ isSuggestedCompany }) => spacing(1),
                    borderBottom: ({ isSuggestedCompany }) =>
                        `1px solid ${palette.text.lightest}`,
                    paddingBottom: ({ isSuggestedCompany }) => spacing(1),
                    maxHeight: ({ isSuggestedCompany }) => "none",
                },
            },
            infoFunding: {
                paddingLeft: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? spacing(2) : 0,
                borderLeft: ({ isSuggestedCompany }) =>
                    isSuggestedCompany
                        ? `1px solid ${palette.text.lightest}`
                        : "none",
                [breakpoint.down("xl")]: {
                    paddingLeft: ({ isSuggestedCompany }) => spacing(2),
                    borderLeft: ({ isSuggestedCompany }) =>
                        `1px solid ${palette.text.lightest}`,
                },
            },
            infoItems: {
                paddingTop: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? 0 : spacing(3),
                display: "flex",
                flexWrap: "wrap",
                minHeight: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? 0 : 353,
                marginTop: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? spacing(1) : spacing(3),
                [breakpoint.down("xl")]: {
                    minHeight: ({ isSuggestedCompany }) => 0,
                    paddingTop: ({ isSuggestedCompany }) => 0,
                    marginTop: ({ isSuggestedCompany }) => spacing(1),
                },
            },
            mt24: {
                marginTop: spacing(3),
                [breakpoint.down("xl")]: {
                    marginTop: spacing(1),
                },
            },
            mt0: {
                marginTop: -9,
                [breakpoint.down("xl")]: {
                    marginTop: spacing(3),
                },
            },
            info: {
                marginBottom: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? 0 : spacing(3),
                flex: "50%",
                [breakpoint.down("xl")]: {
                    marginBottom: ({ isSuggestedCompany }) => 0,
                },
            },
            infoTitle: {
                marginBottom: 2,
                color: palette.text.main,
                [breakpoint.down("xl")]: {
                    color: palette.text.lighter,
                },
            },
            removeButton: {
                background: "transparent",
                color: palette.text.main,
                alignItems: "center",
                position: "relative",
                marginLeft: spacing(3),
                paddingLeft: spacing(3),
                borderLeft: `1px solid ${palette.text.backgroundMain}`,
                display: "flex",
            },
            removeImg: {
                marginRight: spacing(1),
            },
            rssButton: {
                ...typography.bodyXs,
                padding: `${spacing(1)}px ${spacing(3)}px`,
            },
            addToProjectWrapper: {
                position: "relative",
            },
            rssButtonFreeUser: {
                background: palette.primary.backgroundLight,
                color: palette.common.black,
            },
            actionItems: {
                paddingTop: spacing(3),
                borderTop: "1px solid #F0F1F3",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            commentActionItems: {
                paddingTop: spacing(3),
                marginTop: spacing(3),
                borderTop: "1px solid #F0F1F3",
                display: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? "none" : "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                [breakpoint.down("xl")]: {
                    display: ({ isSuggestedCompany }) => "none",
                },
            },
            displayNone: {
                display: "none !important",
            },
            commentActionItem: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                [breakpoint.down("lg")]: {
                    flexDirection: "column",
                    alignItems: "flex-start",
                },
            },
            userVoted: {
                color: `${palette.primary.dark}!important`,
            },
            votersCount: {
                padding: `${spacing(1)}px 0`,
                color: palette.text.lighter,
                cursor: "pointer",
            },
            commentCountWrap: {
                display: "flex",
                alignItems: "center",
                [breakpoint.down("lg")]: {
                    marginTop: spacing(2),
                },
            },
            commentsCount: {
                color: palette.text.lighter,
            },
            questionMark: {
                marginRight: spacing(1),
            },
            communicationIcon: {
                padding: `${spacing(1)}px ${spacing(1)}px`,
            },
            likeIcon: {
                marginLeft: spacing(2),
                marginRight: spacing(1),
                [breakpoint.down("lg")]: {
                    marginLeft: 0,
                },
            },
            likeDislikeIcons: {
                display: "flex",
            },
            numberOfEmployees: {
                minHeight: 18,
            },
            sdg: {
                marginRight: spacing(1),
            },
            sdgTooltip: {
                textAlign: "center",
                color: palette.common.white,
            },
            sdgTooltipTitle: {
                fontWeight: "700",
            },
            blackProjectName: {
                color: palette.common.black,
                cursor: "default",
            },
            addedToProject: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
            },
            addPopup: {
                zIndex: 2,
                width: 235,
                maxHeight: 202,
                background: palette.common.white,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: spacing(2),
                border: "1px solid",
                borderColor: palette.text.backgroundDark,
                top: 40,
                left: 40,
                overflow: "auto",
                paddingTop: spacing(1),
                paddingBottom: spacing(1),
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                "&::-webkit-scrollbar": {
                    width: 2,
                },
                "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 5px transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                    background: palette.text.main,
                    borderRadius: 0,
                },
            },
            projectNameWrapper: {
                width: "100%",
                display: "flex",
                padding: `${spacing(2)}px ${spacing(3)}px`,
                borderBottom: `1px solid ${palette.text.backgroundLight}`,
                color: palette.text.light,
                "&:hover": {
                    background: palette.text.backgroundLight,
                },
            },
            clickable: {
                cursor: "pointer",
            },
            projectName: {
                display: "flex",
                alignItems: "center",
                color: palette.text.light,
            },
            createNewProject: {
                display: "flex",
                justifyContent: "center",
            },
            headerWrapper: {
                minHeight: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? 62 : 82,
                position: "relative",
                borderBottom: `1px solid ${palette.text.backgroundMain}`,
                [breakpoint.down("xl")]: {
                    minHeight: ({ isSuggestedCompany }) => 0,
                },
            },
            dislikeImg: {
                transform: "rotate(180deg)",
                marginLeft: spacing(2),
                marginRight: spacing(1),
            },
            checkEnrichedCompanyImg: {
                marginRight: `${spacing(1)}px`,
            },
            enrichedCompanyInfo: {
                display: "flex",
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            enrichedCompanyInfoTitle: {
                color: palette.success.dark,
            },
            enrichedCompany: {
                display: "flex",
                alignItems: "center",
                backgroundColor: palette.success.backgroundLight,
                padding: `${spacing(1)}px`,
                borderRadius: `${spacing(1)}px`,
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            sdgs: {
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            checkEnrichedCompanyImgHeader: {
                width: 16,
                height: 16,
                marginRight: 3,
            },
            enrichedCompanyHeader: {
                display: "none",
                alignItems: "center",
                padding: 0,
                borderRadius: `${spacing(1)}px`,
                [breakpoint.down("xl")]: {
                    display: "flex",
                },
            },
            sectorTagsWrapper: {
                maxWidth: "calc(100% - 140px)",
                marginTop: 4,
                cursor: "text",
            },
            percentageMatch: {
                display: "flex",
                alignItems: "baseline",
                [breakpoint.down("xl")]: {
                    marginLeft: spacing(1),
                },
            },
            titleWrap: {
                display: "flex",
                flexDirection: "column",
                [breakpoint.down("xl")]: {
                    marginTop: -6,
                },
            },
            totalMatch: {
                border: `1px solid ${palette.common.black}`,
                borderRadius: spacing(2),
                "&:hover": {
                    border: `1px solid ${palette.primary.dark}`,
                },
            },
            mono: {
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            bgGradient: {
                height: 8,
                left: 0,
                right: 0,
                position: "absolute",
                bottom: 0,
                zIndex: 2,
                background: `linear-gradient(180.03deg, rgba(255, 248, 243, 0) 0.08%, #FFFFFF 99.92%)`,
            },
        }),
);
