import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { styled } from "@material-ui/styles";
// Hooks
import useStyles from "./style";
// UI components
import { IconButton } from "@material-ui/core";

import Typography from "../Typography/Typography";
import Button from "../Button/Button";
import { Collapse } from "@material-ui/core";
//assets
import { ReactComponent as ArrowDown } from "../../assets/icons/CollapseArrowDown.svg";

const ExpandMore = styled(props => {
    const { expand, type, alignButton, ...other } = props;
    return type === "button" ? (
        <div style={{ textAlign: alignButton }}>
            <Button variant="textPrimary" {...other}></Button>
        </div>
    ) : (
        <IconButton {...other} />
    );
})(({ theme, expand, type }) => ({
    transform:
        type === "button"
            ? "rotate(0deg)"
            : !expand
            ? "rotate(0deg)"
            : "rotate(180deg)",
    margin: "0 auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CollapseComponent = ({
    children,
    expanderType,
    expanderButtonStateTexts,
    alignButton,
    expandExtern,
    expandExternState,
    expandExternSetState,
    noButton,
}) => {
    const [explanationsExpanded, setExplanationsExpanded] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (expandExtern) {
            setExplanationsExpanded(expandExternState);
        }
    }, [expandExtern, expandExternState]);

    const handleExpandClick = () => {
        setExplanationsExpanded(!explanationsExpanded);
        if (expandExtern) {
            expandExternSetState(!explanationsExpanded);
        }
    };

    const getExpanderButton = () => {
        switch (expanderType) {
            case "button":
                return (
                    <Typography
                        variant="bodyXs"
                        color="text"
                        className={classnames([
                            classes.buttonText,
                            { [classes.buttonExpanded]: explanationsExpanded },
                        ])}>
                        {explanationsExpanded
                            ? expanderButtonStateTexts[1]
                            : expanderButtonStateTexts[0]}
                    </Typography>
                );
            default:
                return <ArrowDown className={classes.collapseArrow} />;
        }
    };

    return (
        <div
            className={classes.root}
            onClick={() => noButton && handleExpandClick()}>
            <Collapse in={!!explanationsExpanded} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
            {!noButton && (
                <ExpandMore
                    expand={!!explanationsExpanded}
                    onClick={handleExpandClick}
                    aria-expanded={!!explanationsExpanded}
                    aria-label="show more"
                    type={expanderType}
                    alignButton={alignButton}>
                    {getExpanderButton()}
                </ExpandMore>
            )}
        </div>
    );
};
export default CollapseComponent;
