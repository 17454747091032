import { useHistory, generatePath } from "react-router-dom";

export const useRouterHook = () => {
    let history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    const openPage = (url, newTab = false) => {
        newTab ? window.open(url) : history.push(generatePath(url));
    };

    const getUrlParam = name => {
        let queryString = new URLSearchParams(window.location.search);
        let resultParam;

        for (let pair of queryString.entries()) {
            if (pair[0] === name) {
                resultParam = {
                    key: pair[0],
                    value: pair[1],
                };
            }
        }

        return resultParam;
    };

    return { goBack, openPage, getUrlParam };
};
