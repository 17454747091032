import React, { useState, useEffect } from "react";
import classnames from "classnames";
// UI components
import Menu from "../Menu/Menu";
import Typography from "../Typography/Typography";
import Button from "../Button/Button";
import MenuItem from "@material-ui/core/MenuItem";
// Hooks
import { useAddToProjectMenuHook } from "./useAddToProjectMenuHook";
// Style
import { useStyles } from "./style.js";
// Modals
import CreateProjectModal from "../../pages/Results/components/modals/CreateProjectModal";
import RemoveWarningModal from "../../pages/Projects/ProjectProfile/RemoveWarningModal/RemoveWarningModal";
// Assets
import { ReactComponent as CheckboxRectangle } from "../../assets/icons/CheckboxRectangle.svg";
import { ReactComponent as CheckboxTick } from "../../assets/icons/CheckboxTick.svg";
import { ReactComponent as AddedTick } from "../../assets/icons/AddedTick.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDownBlue.svg";
import { ReactComponent as RemoveBin } from "../../assets/icons/RemoveBin.svg";
import { ReactComponent as AddToProject } from "../../assets/icons/AddToProject.svg";
import { ReactComponent as Paw } from "../../assets/icons/Paw.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/ArrowDownBlue.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/ProjectDropdownArrow.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/ProjectDropdownShare.svg";
import { ReactComponent as Checked } from "../../assets/icons/GreenCheck.svg";
import { ReactComponent as SaveItems } from "../../assets/icons/SaveWorkspaceActive.svg";
import Save from "../../assets/icons/SaveW.svg";
import Remove from "../../assets/icons/RemoveW.svg";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
    checkProjectsRequest,
    fetchProjectsRequest,
} from "../../store/projects/requests";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../../constants/statuses";
import NameTheSearch from "../../pages/Results/components/modals/NameTheSearch/NameTheSearch";
import { getPostSearchesStatusSelector } from "../../store/results/selectors";
import { postSearchesRequest } from "../../store/results/requests";
import { useAppEventTracking } from "../../common/useAppEventTracking";
import { actions as resultsActions } from "../../store/results/slice";

export const AddToProjectMenu = ({
    buttonType,
    displayListTypeInMenu,
    company,
    companies,
    companiesNames,
    isProject,
    projectSlug,
    disabled,
    isMenuOpenedListener,
    type,
    currentSearch,
    sid,
    isSaved,
    onUpdateCurrentSearchName,
    buttonLabel
}) => {
    const classes = useStyles();
    const { id, name } = company || {};
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [showProjects, setShowProjects] = useState(true);
    const [checkedProjects, setCheckedProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [searchName, setSearchName] = useState(
        currentSearch?.search_name || null,
    );
    const dispatch = useDispatch();
    const { mixpanelTrackEvent } = useAppEventTracking();
    const getPostSearchesStatus = useSelector(getPostSearchesStatusSelector);

    const {
        t,
        getCheckedProjects,
        checkedProjectsStatusSelector,
        // openProjectMenu,
        showNameTheSearchModal,
        setShowNameTheSearchModal,
        setShowProjectLimitModal,
        showCreateProjectModal,
        setShowCreateProjectModal,
        openCreateProjectDialog,
        addSelectedCompanyToProject,
        addSelectedCompaniesToProject,
        openRemoveWarningModal,
        warningModalProjectSlug,
        warningModalResult,
        removeWarningModalOpened,
        handleRemoveWarningModalChange,
        handleNewProjectCreated,
    } = useAddToProjectMenuHook({
        id,
        name,
        classes,
        type,
        setIsMenuOpened,
        companies,
        currentSearch,
        selectedProject,
    });

    useEffect(() => {
        if (isMenuOpenedListener) {
            isMenuOpenedListener(isMenuOpened);
        }
    }, [isMenuOpened]);

    useEffect(() => {
        !showNameTheSearchModal &&
            dispatch(resultsActions.clearPostSearchesMessage());
    }, [showNameTheSearchModal]);

    useEffect(() => {
        getPostSearchesStatus === REQUEST_SUCCESS &&
            setShowNameTheSearchModal(false);
    }, [getPostSearchesStatus]);

    useEffect(() => {
        if (displayListTypeInMenu) {
            if (!isMenuOpened) {
                setCheckedProjects([]);
            } else {
                if (
                    getCheckedProjects &&
                    Array.isArray(getCheckedProjects) &&
                    getCheckedProjects.length > 0 &&
                    checkedProjects.length === 0
                ) {
                    const options = [...checkedProjects];
                    getCheckedProjects.map(project => {
                        options.push(project);
                    });
                    setCheckedProjects(options);
                } else {
                    setCheckedProjects([...checkedProjects]);
                }
            }
        } else {
            if (!isMenuOpened) {
                setCheckedProjects([]);
            } else {
                const options = [
                    {
                        label: (
                            <Typography
                                variant="bodyXs"
                                className={classes.mono}>
                                <DownArrow
                                    className={classnames(
                                        classes.projectsArrow,
                                        {
                                            [classes.projectsArrowClosed]:
                                                !showProjects,
                                        },
                                    )}
                                />
                                {t("results.project")}
                            </Typography>
                        ),
                        onClick: () => setShowProjects(!showProjects),
                        stopClose: true,
                    },
                ];
                if (!!showProjects) {
                    if (
                        getCheckedProjects &&
                        Array.isArray(getCheckedProjects) &&
                        getCheckedProjects.length > 0
                    ) {
                        getCheckedProjects.map(project => {
                            options.push({
                                label: (
                                    <Typography
                                        variant="bodyXs"
                                        className={classnames([
                                            classes.mono,
                                            classes.projectName,
                                            {
                                                [classes.disabledMenuItem]:
                                                    project.is_in_project,
                                            },
                                        ])}>
                                        {project.is_in_project && company && (
                                            <Checked
                                                className={classes.addedTick}
                                            />
                                        )}
                                        {project.project}
                                    </Typography>
                                ),
                                onClick: e =>
                                    handleAddToProjectAction(e, project),
                                disabled: project.is_in_project && company,
                            });
                        });
                    }
                    options.push(
                        {
                            label: (
                                <Typography
                                    variant="bodyXs"
                                    className={classnames(
                                        classes.mono,
                                        classes.projectName,
                                    )}>
                                    <span>+</span>
                                    {t("results.createProject")}
                                </Typography>
                            ),
                            onClick: e => openCreateProjectDialog(e),
                            stopClose: true,
                        },
                        // {
                        //     label: <Typography variant='bodyXs' className={classes.mono}>
                        //         <ShareIcon className={classes.shareIcon}/>
                        //         {t('results.share')}
                        //     </Typography>,
                        //     onClick: () => console.log("Share"),
                        //     stopClose: true,
                        // }
                    );
                    setCheckedProjects(options);
                } else {
                    // options.push({
                    //     label: <Typography variant='bodyXs' className={classes.mono}>
                    //         <ShareIcon className={classes.shareIcon}/>
                    //         {t('results.share')}
                    //     </Typography>,
                    //     onClick: () => console.log("Share"),
                    //     stopClose: true,
                    // })
                    setCheckedProjects(options);
                }
            }
        }
    }, [displayListTypeInMenu, getCheckedProjects, isMenuOpened, showProjects]);

    const renderAddToProjectButton = () => {
        switch (buttonType) {
            case "checkbox":
                if (isProject) {
                    return (
                        <Typography
                            className={classes.removeButton}
                            variant="tertiary"
                            onClick={e => handleIsProjectButtonAction(e)}>
                            <RemoveBin className={classes.removeImg} />{" "}
                            {t("results.removeCompany")}
                        </Typography>
                    );
                } else {
                    return (
                        <Typography
                            variant="bodyXs"
                            color="textSecondary"
                            className={classnames([
                                classes.addToProject,
                                "non-desktop-flex",
                            ])}>
                            <div className={classes.checkboxRectangle}>
                                <CheckboxRectangle />
                                {isMenuOpened && (
                                    <CheckboxTick
                                        className={classes.checkboxTick}
                                    />
                                )}
                            </div>
                            <strong>
                                {isProject
                                    ? t("projects.removeFromProject")
                                    : t("results.addToProject")}
                            </strong>
                        </Typography>
                    );
                }
            case "button":
                return (
                    <div className={classes.addToProjectButton}>
                        {isProject
                            ? t("projects.removeFromProject")
                            : `${t("results.addToProject")} →`}
                    </div>
                );
            case "greenbutton":
                return (
                    <Button
                        className={classnames([
                            classes.successButton,
                            {
                                [classes.successButtonDisabled]:
                                    !companies || companies.length === 0,
                            },
                        ])}
                        variant="success"
                        disabled={
                            !companies || companies.length === 0 || disabled
                        }>
                        {isProject
                            ? t("projects.removeFromProject")
                            : t("results.addToProject")}
                    </Button>
                );
            case "icon":
                return <AddToProject className={classes.actionIcon} />;
            case "label":
                return (
                    <Button
                        component="div"
                        className={classnames([
                            classes.mono,
                            classes.mergeButton,
                            classes.clickable,
                            {
                                [classes.greyOutSvg]:
                                    !companies || companies.length === 0,
                            },
                        ])}
                        disabled={
                            !companies || companies.length === 0 || disabled
                        }>
                        {isProject
                            ? t("projects.removeFromProject")
                            : t("results.addToProject")}
                        <ArrowIcon
                            className={classes.arrowDown2}
                            onClick={e =>
                                (!companies ||
                                    companies.length === 0 ||
                                    disabled) &&
                                (e.preventDefault(), e.stopPropagation())
                            }
                        />
                    </Button>
                );
            case "save":
                return (
                    <Typography className={classes.hoverIcon}>
                        <img
                            src={isProject ? Remove : Save}
                            alt={
                                isProject
                                    ? t("workspace.listView.remove")
                                    : t("workspace.listView.saveInProject")
                            }
                        />
                        <span className={classes.hoverIconSpan}>
                            {isProject
                                ? t("workspace.listView.remove")
                                : t("workspace.listView.saveInProject")}
                        </span>
                    </Typography>
                );
            case "savecard":
                return (
                    <div className={classes.saveIconWrapper}>
                        <div className={classes.actionIconContent}>
                            <img
                                width="24"
                                height="24"
                                src={isProject ? Remove : Save}
                                alt={t("card.save")}
                                className={classes.actionIcon}
                            />
                        </div>
                        <Typography
                            variant="bodyXs"
                            color="textLight"
                            className={
                                company.projects?.length > 0
                                    ? classes.actionTextProject
                                    : classes.actionText
                            }>
                            {isProject
                                ? t("workspace.listView.remove")
                                : company.projects?.length > 0
                                ? t("workspace.listView.saveSelectedProject")
                                : t("workspace.listView.saveSelected")}
                        </Typography>
                    </div>
                );
            case "saveitems":
                return (
                    <div
                        className={classnames([
                            classes.saveItemsButton,
                            { [classes.saveItemsButtonDisabled]: disabled },
                        ])}>
                        <Paw />
                        <Typography
                            variant="bodyButton"
                            className={classnames([
                                classes.saveItemsText,
                                { [classes.saveItemsTextDisabled]: disabled },
                            ])}>
                            {t("workspace.listView.saveSelected")}
                        </Typography>
                    </div>
                );
            case "search":
                return (
                    <div className={classes.saveSearch}>
                        {currentSearch?.saved || isSaved ? (
                            <Typography variant="bodyButton" color="success">
                                Saved
                            </Typography>
                        ) : (
                            <Typography
                                variant="bodyButton"
                                className={classes.saveBtn}>
                                {buttonLabel || "Save"}
                            </Typography>
                        )}
                    </div>
                );
            case "custom":
                return <div></div>;
            default:
                return <div>{t("results.addToProject")}</div>;
        }
    };

    const handleIsProjectButtonAction = e => {
        if (isProject) {
            const resultValue = {
                id: id || companies,
                name: name || companiesNames,
            };
            openRemoveWarningModal(e, projectSlug, resultValue);
        } else {
            handleMenuAction();
        }
    };

    const handleStopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleAddToProjectAction = (e, project) => {
        setSelectedProject(project);
        if (type === "search" && !currentSearch?.search_name && !searchName) {
            setShowNameTheSearchModal(true);
        } else {
            if (company) {
                addSelectedCompanyToProject(e, project, type);
            } else {
                addSelectedCompaniesToProject(
                    e,
                    companies,
                    companiesNames,
                    project,
                    type,
                );
            }
        }
    };

    const handleMenuAction = () => {
        if (companies && companies.length > 0) {
            dispatch(checkProjectsRequest({ companyId: companies[0], type }));
        } else {
            dispatch(checkProjectsRequest({ companyId: company.id, type }));
        }

        setIsMenuOpened(!isMenuOpened);
    };

    const handlePostSearchName = name => {
        dispatch(
            postSearchesRequest({
                data: { "search-name": name, sid: currentSearch.id },
            }),
        );
        mixpanelTrackEvent("Search saved");
        setSearchName(name);
        onUpdateCurrentSearchName && onUpdateCurrentSearchName(name);
    };

    const handleNameTheSearchModalChange = e => {
        handleStopPropagation(e);
        setShowNameTheSearchModal(false);
    };

    const handleChangeModals = () => {
        setShowCreateProjectModal(false);
    };

    const handleShowTopUpModal = () => {
        setShowProjectLimitModal(false);
    };
    const renderMenu = () => {
        if (displayListTypeInMenu) {
            if (getCheckedProjects.length > 0) {
                if (!isProject) {
                    return (
                        <>
                            <MenuItem
                                variant="bodyXs"
                                onClick={e => handleIsProjectButtonAction(e)}
                                className={classes.mono}
                                data-intercom-target="Add to project button">
                                {t("companyProfile.headerMenu.project.add")}
                                <ArrowDown
                                    className={classnames([
                                        classes.arrowImg,
                                        {
                                            [classes.rotatedIcon]:
                                                !!isMenuOpened,
                                        },
                                    ])}
                                />
                            </MenuItem>
                            {isMenuOpened && (
                                <MenuItem
                                    onClick={e => openCreateProjectDialog(e)}
                                    variant="bodyXs"
                                    className={classes.mono}
                                    data-intercom-target="Create project dialog button">
                                    {t("results.createProject")}
                                </MenuItem>
                            )}
                            {checkedProjects.map((project, i) => {
                                return (
                                    <MenuItem
                                        key={`${project.slug}-${i}`}
                                        onClick={e =>
                                            handleAddToProjectAction(e, project)
                                        }
                                        variant="bodyXs"
                                        className={classnames([
                                            classes.mono,
                                            {
                                                [classes.disabledMenuItem]:
                                                    project.is_in_project,
                                            },
                                        ])}>
                                        {project.project}
                                        {project.is_in_project && (
                                            <AddedTick
                                                className={classes.addedTick}
                                            />
                                        )}
                                    </MenuItem>
                                );
                            })}
                        </>
                    );
                } else {
                    return (
                        <MenuItem
                            variant="bodyXs"
                            onClick={e => handleIsProjectButtonAction(e)}
                            className={classes.mono}>
                            {t("projects.removeFromProject")}
                        </MenuItem>
                    );
                }
            } else {
                return (
                    <>
                        <MenuItem
                            variant="bodyXs"
                            onClick={e => handleIsProjectButtonAction(e)}
                            className={classes.mono}
                            data-intercom-target="Add to project button">
                            {!isProject
                                ? t("companyProfile.headerMenu.project.add")
                                : t("projects.removeFromProject")}
                            {!isProject && (
                                <ArrowDown
                                    className={classnames([
                                        classes.arrowImg,
                                        {
                                            [classes.rotatedIcon]:
                                                !!isMenuOpened,
                                        },
                                    ])}
                                />
                            )}
                        </MenuItem>
                        {checkedProjectsStatusSelector === REQUEST_SUCCESS &&
                            isMenuOpened && (
                                <MenuItem
                                    onClick={e => openCreateProjectDialog(e)}
                                    variant="bodyXs"
                                    className={classes.mono}>
                                    {t("results.createProject")}
                                </MenuItem>
                            )}
                    </>
                );
            }
        } else {
            return (
                <Menu
                    onChange={e => handleIsProjectButtonAction(e)}
                    menuItems={checkedProjects}
                    btnClassName={classes.addToProjectCustomButton}>
                    <div>{renderAddToProjectButton()}</div>
                </Menu>
            );
        }
    };

    const newProjectCreated = project => {
        handleNewProjectCreated(project);
    };

    return (
        <>
            {renderMenu()}
            <CreateProjectModal
                dialogOpened={showCreateProjectModal}
                companyIndustry={company}
                onChange={e => handleChangeModals(e)}
                newProjectCreated={project => newProjectCreated(project)}
                selectedCompanies={companies}
                type={type}
            />
            {removeWarningModalOpened && (
                <RemoveWarningModal
                    projectSlug={warningModalProjectSlug || projectSlug}
                    result={warningModalResult}
                    dialogOpened={removeWarningModalOpened}
                    onChange={() => handleRemoveWarningModalChange(true)}
                    type={type}
                />
            )}
            {showNameTheSearchModal && (
                <NameTheSearch
                    dialogOpened={showNameTheSearchModal}
                    onChange={handleNameTheSearchModalChange}
                    onActionClick={(name, sid) =>
                        handlePostSearchName(name, sid)
                    }
                    succeeded={getPostSearchesStatus === REQUEST_SUCCESS}
                    requestPending={getPostSearchesStatus === REQUEST_PENDING}
                    currentSearchSid={sid}
                    project={selectedProject}
                    currentSearch={currentSearch}
                    addSelectedCompanyToProject={addSelectedCompanyToProject}
                />
            )}
        </>
    );
};
