import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import {
    addCompanyTagRequest,
    deleteCompanyTagRequest,
    selectCompanyTagRequest,
} from "../../../../store/companyProfile/requests";
import {
    addItemToProjectRequest,
    checkProjectsRequest,
} from "../../../../store/projects/requests";
import {
    getAddItemToProjectStatusSelector,
    getCheckProjectsSelector,
    getCheckProjectsStatusSelector,
} from "../../../../store/projects/selectors";
import { postGenerateLinkRequest } from "../../../../store/results/requests";
import {
    getGenerateLinkStatusSelector,
    getGeneratedLink,
} from "../../../../store/results/selectors";

export const useHeaderHook = ({ company, getUser }) => {
    const { t } = useTranslation();
    const [copiedToClipboard, setCopiedToClipboard] = useState("");
    const [showPopoverTags, setShowPopoverTags] = useState(false);
    const [showAddNewTagInput, setShowAddNewTagInput] = useState(false);
    const [tagName, setTagName] = useState("");
    const [errorTagNameInput, setErrorTagNameInput] = useState(false);
    const [errorTagNameMsg, setErrorTagNameMsg] = useState("");
    const dispatch = useDispatch();
    const getGenerateLinkStatus = useSelector(getGenerateLinkStatusSelector);
    const generatedLink = useSelector(getGeneratedLink);
    const [historyFrom, setHistoryFrom] = useState("");
    const { trackEvent } = useIntercom();
    const getCheckedProjects = useSelector(getCheckProjectsSelector);
    const getCheckedProjectsStatusSelector = useSelector(
        getCheckProjectsStatusSelector,
    );
    const [checkedProjects, setCheckedProjects] = useState(getCheckedProjects);
    const [showAddToProjectDialog, setShowAddToProjectDialog] = useState(false);
    const [showMenuAddToProjectDialog, setShowMenuAddToProjectDialog] =
        useState(false);
    const [dialogOpened, setDialogOpened] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const addItemToProjectStatus = useSelector(
        getAddItemToProjectStatusSelector,
    );
    //Company tags - popup
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const popoverTagsId = open ? "popover-tags" : undefined;
    const [topUpCreditsModalShow, setTopUpCreditsModalShow] = useState(false);
    const isSharedResultsPageToken = localStorage.getItem(
        "isSharedResultsPageToken",
    );
    const [isSharedPage, setIsSharedPage] = useState(false);

    const [companyId, setCompanyId] = useState();

    useEffect(() => {
        company && company.id && setCompanyId(company.id);
    }, [company]);

    useEffect(() => {
        setIsSharedPage(isSharedResultsPageToken);
    }, [isSharedResultsPageToken]);

    const generateLink = () => {
        const data = {
            cid:
                window.location.pathname === "/companies/enriched/deck"
                    ? company.cid
                    : company.id,
        };
        dispatch(postGenerateLinkRequest({ data }));
        if (
            process.env.REACT_APP_ENV === "prod" ||
            process.env.REACT_APP_ENV === "staging"
        ) {
            //Intercom event tracking
            trackEvent("Sharing", {
                CompanyName: company.name,
                CompanySlug: company.slug,
            });
        }
    };
    const openAddtoProject = () => {
        !showAddToProjectDialog &&
            dispatch(checkProjectsRequest({ companyId: company.id }));
        setShowAddToProjectDialog(!showAddToProjectDialog);
    };
    const openMenuAddtoProject = () => {
        !showMenuAddToProjectDialog &&
            dispatch(checkProjectsRequest({ companyId: companyId }));
        setShowMenuAddToProjectDialog(true);
    };
    const handleToggleDialog = () => {
        setDialogOpened(false);
    };
    const openCreateProjectDialog = e => {
        e.preventDefault && e.preventDefault();
        e && e.stopPropagation && e.stopPropagation();
        setDialogOpened(true);
    };
    const addSelectedCompanyToProject = (e, value) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedProject(value);
        if (value.is_in_project) {
            return;
        } else {
            let companies = company.id || company.cid;
            localStorage.setItem("addToProjectCompany", company.name);
            localStorage.setItem("addToProjectProject", value.project);
            dispatch(
                addItemToProjectRequest({
                    project: value.slug,
                    companies: companies,
                }),
            );
        }
    };
    const handleNewProjectCreated = () => {
        setDialogOpened(false);
    };
    const handleClickTags = event => {
        setAnchorEl(event.currentTarget);
        setShowPopoverTags(!showPopoverTags);
    };
    const handleClosePopoverTags = () => {
        setAnchorEl(null);
        setShowAddNewTagInput(false);
        setShowPopoverTags(false);
    };
    //Company tags - handle input
    const handleAddNewTagClick = () => {
        setShowAddNewTagInput(true);
    };
    //Add new company tag
    const handleTagNameInputKeyUp = event => {
        validateTagNameError(event.target.value);
        if (!errorTagNameInput && tagName !== "" && event.key === "Enter") {
            let data = new FormData();
            data.append("tag_name", tagName);
            data.append("ml_results_id", company.id);
            dispatch(addCompanyTagRequest({ data }));
        }
    };
    const handleTagNameChange = e => {
        setTagName(e.target.value);
    };
    const validateTagNameError = value => {
        if (value === "") {
            setErrorTagNameInput(true);
            setErrorTagNameMsg(t("companyProfile.errorNewTagEmpty"));
        } else if (value.length < 2) {
            setErrorTagNameInput(true);
            setErrorTagNameMsg(t("companyProfile.errorNewTagMinLength"));
        } else {
            setErrorTagNameInput(false);
        }
    };
    const handleCheckboxChange = tag => {
        let is_selected = 0;
        if (tag.is_selected === 1) {
            is_selected = 0;
        } else {
            is_selected = 1;
        }
        let data = new FormData();
        data.append("tag_id", tag.id);
        data.append("is_selected", is_selected);
        data.append("ml_results_id", company.id);
        dispatch(selectCompanyTagRequest({ data }));
    };
    const deleteTag = tagId => {
        let data = new FormData();
        data.append("tag_id", tagId);
        data.append("ml_results_id", company.id);
        dispatch(deleteCompanyTagRequest({ data }));
    };

    const handleShowTopUpModal = () => {
        setTopUpCreditsModalShow(true);
    };
    const handleClosingTopUpCreditsDialog = () => {
        setTopUpCreditsModalShow(false);
    };
    const handleBuyingTopUpCreditsDialog = () => {
        setTopUpCreditsModalShow(false);
    };

    return {
        t,
        dispatch,
        copiedToClipboard,
        setCopiedToClipboard,
        showPopoverTags,
        showAddNewTagInput,
        errorTagNameMsg,
        getGenerateLinkStatus,
        generatedLink,
        setHistoryFrom,
        trackEvent,
        getCheckedProjects,
        getCheckedProjectsStatusSelector,
        checkedProjects,
        setCheckedProjects,
        generateLink,
        openAddtoProject,
        handleToggleDialog,
        openCreateProjectDialog,
        addSelectedCompanyToProject,
        handleNewProjectCreated,
        handleBuyingTopUpCreditsDialog,
        handleClosingTopUpCreditsDialog,
        handleShowTopUpModal,
        topUpCreditsModalShow,
        deleteTag,
        handleCheckboxChange,
        validateTagNameError,
        handleTagNameChange,
        handleAddNewTagClick,
        handleClosePopoverTags,
        handleClickTags,
        popoverTagsId,
        addItemToProjectStatus,
        selectedProject,
        dialogOpened,
        setShowAddToProjectDialog,
        setShowMenuAddToProjectDialog,
        showAddToProjectDialog,
        showMenuAddToProjectDialog,
        anchorEl,
        open,
        tagName,
        errorTagNameInput,
        handleTagNameInputKeyUp,
        openMenuAddtoProject,
    };
};
