import React, { useState } from "react";
import classnames from "classnames";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
// UI components
import Typography from "../../../../components/Typography/Typography.js";
import Tag from "../../../../components/Tag/Tag.js";
import Link from "../../../../components/Link/Link.js";
import Tooltip from "@material-ui/core/Tooltip";
import RequestModal from "../modal/RequestModal/RequestModal.js";
//Assests
import ImagePlaceholder from "../../../../assets/icons/ImagePlaceholder.svg";
import { ReactComponent as QuestionMark } from "../../../../assets/icons/QuestionMark.svg";
import FramedLock from "../../../../assets/icons/FramedLock.png";
//Shared
import { Shared } from "../../../../utils/shared.js";
import classNames from "classnames";
import { mixpanelUtils } from "../../../../store/mixpanel/utils";

const Success = ({
    company,
    user,
    showEnrichRequestModal,
    funding,
    currency,
    isPreview,
    unspecifiedFunding,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [imageSrc, setImageSrc] = useState(ImagePlaceholder);
    const [requestModalTitle, setRequestModalTitle] = useState("");
    const [requestModalDesc, setRequestModalDesc] = useState("");
    const [requestModalIcon, setRequestModalIcon] = useState(<></>);
    const [showRequestModal, setShowRequestModal] = useState(false);

    const openRequestModal = (title, desc, icon) => {
        setRequestModalTitle(title);
        setRequestModalDesc(desc);
        setRequestModalIcon(icon);
        setShowRequestModal(true);
    };
    const closeRequestModal = () => {
        setShowRequestModal(false);
    };
    const onImgError = () => {
        setImageSrc(ImagePlaceholder);
    };
    const { rounds, customers } = company;
    const { formatNumber } = Shared({ getUser: user });

    return (
        <div className={classes.root} data-intercom-target="Success section">
            {company.ready_for_release || isPreview ? (
                <>
                    <div className={classes.flex}>
                        <div
                            className={classNames([
                                {
                                    [classes.fundingUnlocked]:
                                        company.ready_for_release || isPreview,
                                    [classes.funding]: !(
                                        company.ready_for_release || isPreview
                                    ),
                                },
                            ])}>
                            {(company.ready_for_release || isPreview) && (
                                <div
                                    className={classnames([
                                        classes.tableRow,
                                        classes.totalFunding,
                                    ])}>
                                    <Typography
                                        className={classes.tableCell1}
                                        variant="subtitle1">
                                        {t("companySuccess.funding")}
                                    </Typography>
                                    <div className={classes.tableCell1}></div>
                                    <div className={classes.tableCell1}></div>
                                    <div className={classes.tableCell1}>
                                        <Typography
                                            variant="bodyXs"
                                            component="div">
                                            {t("results.total")}
                                        </Typography>
                                        <Typography
                                            variant="body"
                                            component="div"
                                            color="successDark"
                                            className={classes.mono}>
                                            {funding ? (
                                                <strong>
                                                    {formatNumber(funding)}{" "}
                                                    {currency || " $"}
                                                </strong>
                                            ) : (
                                                <strong>"N/a"</strong>
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                            {!(company.ready_for_release || isPreview) && (
                                <img
                                    src={FramedLock}
                                    className={classes.framedLockIcon}
                                />
                            )}
                            {!(company.ready_for_release || isPreview) && (
                                <Typography
                                    component="div"
                                    className={classes.fundingTitle}
                                    variant="subtitle1">
                                    {t("companySuccess.funding")}
                                </Typography>
                            )}
                            {company.ready_for_release || isPreview ? (
                                <div>
                                    {isPreview && rounds
                                        ? rounds.map((round, i) => {
                                              return (
                                                  <div
                                                      className={
                                                          classes.tableRow
                                                      }
                                                      key={`round-${round.name}-${i}`}>
                                                      <div
                                                          className={
                                                              classes.fundingRowItem
                                                          }>
                                                          <Typography
                                                              className={
                                                                  classes.roundName
                                                              }
                                                              variant="bodySmall">
                                                              {round.name}
                                                          </Typography>
                                                          <div
                                                              className={classnames(
                                                                  [
                                                                      classes.flex,
                                                                      classes.roundNameWrap,
                                                                  ],
                                                              )}>
                                                              {round.investors &&
                                                                  round.investors.map(
                                                                      (
                                                                          investor,
                                                                          i,
                                                                      ) => {
                                                                          return (
                                                                              <div
                                                                                  key={
                                                                                      i
                                                                                  }
                                                                                  className={
                                                                                      classes.tagsWrap
                                                                                  }>
                                                                                  <Typography
                                                                                      variant="bodySmall"
                                                                                      component="span"
                                                                                      className={classnames(
                                                                                          [
                                                                                              classes.amountValue,
                                                                                              classes.mono,
                                                                                          ],
                                                                                      )}>
                                                                                      {
                                                                                          investor
                                                                                      }
                                                                                  </Typography>
                                                                                  <Tooltip
                                                                                      title={round.investors.map(
                                                                                          (
                                                                                              invisibleTag,
                                                                                              i,
                                                                                          ) => (
                                                                                              <Typography
                                                                                                  color="white"
                                                                                                  key={`invisible-tag-${i}`}
                                                                                                  variant="body2"
                                                                                                  component="div">
                                                                                                  {
                                                                                                      invisibleTag
                                                                                                  }
                                                                                              </Typography>
                                                                                          ),
                                                                                      )}
                                                                                      arrow>
                                                                                      <div
                                                                                          className={
                                                                                              classes.marginRight
                                                                                          }>
                                                                                          {round
                                                                                              .investors
                                                                                              .length >
                                                                                              1 && (
                                                                                              <Tag
                                                                                                  className={
                                                                                                      classes.tagName
                                                                                                  }>
                                                                                                  <Typography variant="caption">
                                                                                                      +{" "}
                                                                                                      {round
                                                                                                          .investors
                                                                                                          .length -
                                                                                                          1}
                                                                                                  </Typography>
                                                                                              </Tag>
                                                                                          )}
                                                                                      </div>
                                                                                  </Tooltip>
                                                                              </div>
                                                                          );
                                                                      },
                                                                  )}
                                                          </div>
                                                      </div>
                                                      <div
                                                          className={
                                                              classes.fundingRowItem
                                                          }>
                                                          <Typography
                                                              className={
                                                                  classes.amountText
                                                              }
                                                              variant="bodySmall">
                                                              {t(
                                                                  "companySuccess.securingDate",
                                                              )}
                                                          </Typography>
                                                          <Typography
                                                              className={classnames(
                                                                  [
                                                                      classes.amountValue,
                                                                      classes.mono,
                                                                  ],
                                                              )}
                                                              variant="bodySmall">
                                                              {round.date}
                                                          </Typography>
                                                      </div>
                                                      <div
                                                          className={
                                                              classes.fundingRowItem
                                                          }>
                                                          <Typography
                                                              component="div"
                                                              className={
                                                                  classes.amountText
                                                              }
                                                              variant="bodySmall">
                                                              {t(
                                                                  "companySuccess.amount",
                                                              )}
                                                          </Typography>
                                                          <Typography
                                                              className={classnames(
                                                                  [
                                                                      classes.fundingAmountValue,
                                                                      classes.mono,
                                                                  ],
                                                              )}
                                                              variant="bodySmall">
                                                              {formatNumber(
                                                                  round.total_amount,
                                                              )}{" "}
                                                              {round.currency
                                                                  ? round.currency
                                                                  : " $"}{" "}
                                                          </Typography>
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : company &&
                                          company.rounds &&
                                          company.rounds.map((round, i) => {
                                              return (
                                                  <div
                                                      className={
                                                          classes.tableRow
                                                      }
                                                      key={`round-${round.name}-${i}`}>
                                                      <div
                                                          className={
                                                              classes.fundingRowItem
                                                          }>
                                                          <Typography
                                                              className={
                                                                  classes.amountText
                                                              }
                                                              variant="bodySmall">
                                                              {t(
                                                                  "companySuccess.fundingType",
                                                              )}
                                                          </Typography>
                                                          <Typography
                                                              color="primaryDark"
                                                              className={
                                                                  classes.mono
                                                              }
                                                              variant="bodySmall">
                                                              {round.name}
                                                          </Typography>
                                                      </div>
                                                      <div
                                                          className={
                                                              classes.fundingRowItem
                                                          }>
                                                          <Typography
                                                              className={
                                                                  classes.amountText
                                                              }
                                                              variant="bodySmall">
                                                              {t(
                                                                  "companySuccess.investors",
                                                              )}
                                                          </Typography>
                                                          <div
                                                              className={classnames(
                                                                  [
                                                                      classes.flex,
                                                                      classes.roundNameWrap,
                                                                  ],
                                                              )}>
                                                              {round.investors &&
                                                                  round.investors
                                                                      .slice(
                                                                          0,
                                                                          1,
                                                                      )
                                                                      .map(
                                                                          (
                                                                              investor,
                                                                              i,
                                                                          ) => {
                                                                              return (
                                                                                  <div
                                                                                      key={
                                                                                          i
                                                                                      }
                                                                                      className={
                                                                                          classes.tagsWrap
                                                                                      }>
                                                                                      <Typography
                                                                                          variant="bodySmall"
                                                                                          component="span"
                                                                                          color="textLighter"
                                                                                          className={classnames(
                                                                                              [
                                                                                                  classes.investorsValue,
                                                                                                  classes.mono,
                                                                                              ],
                                                                                          )}>
                                                                                          {
                                                                                              investor
                                                                                          }
                                                                                          <span
                                                                                              className={
                                                                                                  classes.investorsNumberValue
                                                                                              }>
                                                                                              <Tooltip
                                                                                                  classes={{
                                                                                                      tooltip:
                                                                                                          classes.investorsTooltip,
                                                                                                      arrow: classes.investorsArrow,
                                                                                                  }}
                                                                                                  title={
                                                                                                      <>
                                                                                                          <Typography
                                                                                                              color="primaryDark"
                                                                                                              variant="bodyXl"
                                                                                                              component="div">
                                                                                                              {
                                                                                                                  round.name
                                                                                                              }
                                                                                                          </Typography>
                                                                                                          <Typography
                                                                                                              color="textLight"
                                                                                                              variant="bodyXs"
                                                                                                              component="div">
                                                                                                              {round.investors
                                                                                                                  .map(
                                                                                                                      (
                                                                                                                          invisibleTag,
                                                                                                                          i,
                                                                                                                      ) =>
                                                                                                                          invisibleTag,
                                                                                                                  )
                                                                                                                  .join(
                                                                                                                      ", ",
                                                                                                                  )}
                                                                                                          </Typography>
                                                                                                      </>
                                                                                                  }
                                                                                                  arrow>
                                                                                                  <div
                                                                                                      className={
                                                                                                          classes.investorsCount
                                                                                                      }>
                                                                                                      {round
                                                                                                          .investors
                                                                                                          .length >
                                                                                                          1 && (
                                                                                                          <Typography
                                                                                                              color="success"
                                                                                                              variant="caption">
                                                                                                              +{" "}
                                                                                                              {round
                                                                                                                  .investors
                                                                                                                  .length -
                                                                                                                  1}
                                                                                                          </Typography>
                                                                                                      )}
                                                                                                  </div>
                                                                                              </Tooltip>
                                                                                          </span>
                                                                                      </Typography>
                                                                                  </div>
                                                                              );
                                                                          },
                                                                      )}
                                                          </div>
                                                      </div>
                                                      <div
                                                          className={
                                                              classes.fundingRowItem
                                                          }>
                                                          <Typography
                                                              className={
                                                                  classes.amountText
                                                              }
                                                              variant="bodySmall">
                                                              {t(
                                                                  "companySuccess.securingDate",
                                                              )}
                                                          </Typography>
                                                          <Typography
                                                              color="textLighter"
                                                              className={
                                                                  classes.mono
                                                              }
                                                              variant="bodySmall">
                                                              {round.date}
                                                          </Typography>
                                                      </div>
                                                      <div
                                                          className={
                                                              classes.fundingRowItem
                                                          }>
                                                          <Typography
                                                              component="div"
                                                              className={
                                                                  classes.amountText
                                                              }
                                                              variant="bodySmall">
                                                              {t(
                                                                  "companySuccess.amount",
                                                              )}
                                                          </Typography>
                                                          <Typography
                                                              className={classnames(
                                                                  [
                                                                      classes.fundingAmountValue,
                                                                      classes.mono,
                                                                  ],
                                                              )}
                                                              variant="bodySmall">
                                                              {formatNumber(
                                                                  round.total_amount,
                                                              )}{" "}
                                                              {round.currency
                                                                  ? round.currency
                                                                  : " $"}{" "}
                                                          </Typography>
                                                      </div>
                                                  </div>
                                              );
                                          })}
                                </div>
                            ) : (
                                <div
                                    onClick={() =>
                                        openRequestModal(
                                            t("companyProfile.fundingTitle"),
                                            t("companyProfile.fundingDesc"),
                                        )
                                    }
                                    className={classes.openDialogSection}>
                                    <QuestionMark
                                        className={classes.questionMark}
                                    />
                                    <Typography
                                        className={classes.mono}
                                        color="textLighter"
                                        variant="bodyXs">
                                        {t("companyProfile.whatWillIGet")}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.fundingCustomers}>
                        <div
                            className={classNames([
                                {
                                    [classes.customers]:
                                        company.ready_for_release || isPreview,
                                    [classes.customersLocked]: !(
                                        company.ready_for_release || isPreview
                                    ),
                                },
                            ])}>
                            {!(company.ready_for_release || isPreview) && (
                                <img
                                    src={FramedLock}
                                    className={classes.framedLockIcon}
                                />
                            )}
                            <Typography
                                className={classes.fundingTitle}
                                variant="subtitle1">
                                {t("companySuccess.customers")}
                            </Typography>
                            {company.ready_for_release || isPreview ? (
                                <>
                                    <div className={classes.flexCenter}>
                                        <Typography
                                            color="textLighter"
                                            variant="bodySmall">
                                            {company &&
                                                company.customers &&
                                                company.customers.length}{" "}
                                            {t("companySuccess.identified")}
                                        </Typography>
                                    </div>
                                    <div className={classes.customersRow}>
                                        {isPreview && customers
                                            ? customers.map((customer, i) => {
                                                  return (
                                                      <div
                                                          key={`customer-${customer.name}-${i}`}
                                                          className={
                                                              classes.customerRow
                                                          }>
                                                          <div
                                                              className={
                                                                  classes.imgWrapper
                                                              }>
                                                              <img
                                                                  onError={
                                                                      onImgError
                                                                  }
                                                                  className={
                                                                      classes.customerImg
                                                                  }
                                                                  alt={
                                                                      customer.name
                                                                  }
                                                                  src={
                                                                      customer.logo_source_url ??
                                                                      customer.logo_url
                                                                  }
                                                              />
                                                          </div>
                                                          <div
                                                              className={
                                                                  classes.flexColumn
                                                              }>
                                                              <Typography variant="bodySmall">
                                                                  {
                                                                      customer.name
                                                                  }
                                                              </Typography>
                                                              <Link
                                                                  href={
                                                                      customer.website
                                                                  }
                                                                  target="_blank"
                                                                  className={
                                                                      classes.link
                                                                  }>
                                                                  <Typography
                                                                      variant="bodySmall"
                                                                      color="secondaryDark">
                                                                      {
                                                                          customer.website
                                                                      }
                                                                  </Typography>
                                                              </Link>
                                                          </div>
                                                      </div>
                                                  );
                                              })
                                            : company &&
                                              company.customers &&
                                              company.customers.map(
                                                  (customer, i) => {
                                                      return (
                                                          <div
                                                              key={`customer-${customer.name}-${i}`}
                                                              className={
                                                                  classes.customerRow
                                                              }>
                                                              <div
                                                                  className={
                                                                      classes.imgWrapper
                                                                  }>
                                                                  <img
                                                                      onError={
                                                                          onImgError
                                                                      }
                                                                      className={
                                                                          classes.customerImg
                                                                      }
                                                                      alt={
                                                                          customer.name
                                                                      }
                                                                      src={
                                                                          customer.logo_source_url ??
                                                                          customer.logo_url
                                                                      }
                                                                  />
                                                              </div>
                                                              <div
                                                                  className={
                                                                      classes.flexColumn
                                                                  }>
                                                                  <Typography variant="bodySmall">
                                                                      {
                                                                          customer.name
                                                                      }
                                                                  </Typography>
                                                                  <Link
                                                                      href={
                                                                          customer.website
                                                                      }
                                                                      target="_blank"
                                                                      className={
                                                                          classes.link
                                                                      }>
                                                                      <Typography
                                                                          variant="bodySmall"
                                                                          color="secondaryDark">
                                                                          {
                                                                              customer.website
                                                                          }
                                                                      </Typography>
                                                                  </Link>
                                                              </div>
                                                          </div>
                                                      );
                                                  },
                                              )}
                                    </div>
                                </>
                            ) : (
                                <div
                                    onClick={() =>
                                        openRequestModal(
                                            t("companyProfile.customersTitle"),
                                            t("companyProfile.customersDesc"),
                                        )
                                    }
                                    className={classes.openDialogSection}>
                                    <QuestionMark
                                        className={classes.questionMark}
                                    />
                                    <Typography
                                        className={classes.mono}
                                        color="textLighter"
                                        variant="bodyXs">
                                        {t("companyProfile.whatWillIGet")}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={classes.benefitsCardsWrapper}>
                        <Typography
                            className={classes.benefitsCardsWrapperTitle}
                            variant="bodySmall">
                            {t("companyProfile.whatWillIGet")}
                        </Typography>
                        <div className={classes.benefitsCard}>
                            <div
                                className={classnames([
                                    classes.imgBlock,
                                    {
                                        [classes.imgBlockReleased]:
                                            company.ready_for_release,
                                    },
                                ])}>
                                <Typography
                                    className={classes.blockTitle}
                                    variant="subtitle1"
                                    color="textPrimary"
                                    component="div">
                                    {t(
                                        `companyProfile.successEnrichmentBanner.funding.title`,
                                    )}
                                </Typography>
                                <div className={classes.companies}>
                                    <div className={classes.descriptionBox}>
                                        <Typography
                                            variant="body"
                                            color="TextSecondary"
                                            component="div">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: mixpanelUtils().removeHTMLTags(
                                                        t(
                                                            `companyProfile.successEnrichmentBanner.funding.description`,
                                                        ),
                                                    ),
                                                }}
                                            />
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.benefitsCard}>
                            <div
                                className={classnames([
                                    classes.imgBlock,
                                    {
                                        [classes.imgBlockReleased]:
                                            company.ready_for_release,
                                    },
                                ])}>
                                <Typography
                                    className={classes.blockTitle}
                                    variant="subtitle1"
                                    color="TextPrimary"
                                    component="div">
                                    {t(
                                        `companyProfile.successEnrichmentBanner.customers.title`,
                                    )}
                                </Typography>
                                <div className={classes.companies}>
                                    <div className={classes.descriptionBox}>
                                        <Typography
                                            variant="body"
                                            color="TextSecondary"
                                            component="div">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: mixpanelUtils().removeHTMLTags(
                                                        t(
                                                            `companyProfile.successEnrichmentBanner.customers.description`,
                                                        ),
                                                    ),
                                                }}
                                            />
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <RequestModal
                enrichmentRequest={company && company.enrichment_request}
                showEnrichRequestModal={() => showEnrichRequestModal(company)}
                dialogOpened={showRequestModal}
                onChange={closeRequestModal}
                icon={requestModalIcon}
                title={requestModalTitle}
                description={requestModalDesc}
                user={user}
            />
        </div>
    );
};
export default Success;
