import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Grow from "@material-ui/core/Grow";
import Button from "../../../../../components/Button/Button";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as XIcon } from "../../../../../assets/icons/CloseIcon.svg";
import { useStyles } from "../style.js";

const LoadScopeDialog = ({
    open,
    setOpen,
    handleYesClick
}) => {
    const classes = useStyles();

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
            }}
            onClose={() => setOpen(false)}
            open={open}>
            <MuiDialogContent className={classes.dialogContent}>
                <p
                    className={classes.x}
                    onClick={() => setOpen(false)}>
                    <XIcon />
                </p>
                <p className={classes.title}>
                    Are you sure that you want to load this scope?
                </p>
                <p className={classes.paragraph}>
                    Don't worry - you can always find currently active scope in
                    the 'Previous Scopes' tab.
                </p>
                <p className={classes.buttons}>
                    <Button
                        className={classes.cancelButton}
                        variant="tertiary"
                        disabled={false}
                        onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        // disabled={isBtnDisabled}
                        onClick={() => {
                            handleYesClick();
                            setOpen(false);
                        }}
                        // loading={requestPending}
                    >
                        Yes load scope
                    </Button>
                </p>
            </MuiDialogContent>
        </Dialog>
    );
};

export default LoadScopeDialog;
