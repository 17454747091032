// Redux
import React, { useEffect, useState } from "react";
import classnames from "classnames";
// Hooks
import { useStyles } from "./style.js";
import { useDispatch } from "react-redux";
import { useAppEventTracking } from "../../../common/useAppEventTracking";
import { useRouterHook } from "../../../common/useRouterHook";
import { useHeaderHook } from "./hooks/useHeaderHook.js";
import usePrevious from "../../../common/usePrevious";
//UI Components
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "../../../components/Link/Link";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "../../../components/Menu/Menu";
import AreYouSureModal from "../../../components/AreYouSureModal/AreYouSureModal";
import { AddToProjectMenu } from "../../../components/AddToProjectMenu/AddToProjectMenu.js";
import TopUpCreditsModal from "../LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";
//Assets
import { ReactComponent as ArrowRight } from "../../../assets/icons/ArrowRight.svg";
import TextField from "../../../components/TextField/TextField.js";
import RemoveIcon from "../../../assets/icons/RemoveIcon.svg";
import RemoveIconHover from "../../../assets/icons/RemoveIconHover.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/ArrowDownBlue.svg";
import { ReactComponent as ArrowUpOrange } from "../../../assets/icons/ArrowUpOrange.svg";
import { ReactComponent as TooltipCheck } from "../../../assets/icons/TooltipCheck.svg";
import { ReactComponent as MoreIconNoFill } from "../../../assets/icons/MoreIconNoFill.svg";
// Constants
import { REQUEST_SUCCESS } from "../../../constants/statuses";
import { getPricingPage } from "../../../constants/endpoints";
//Listeners
import ClickAwayListener from "react-click-away-listener";
import { actions as resultsActions } from "../../../store/results/slice";
import { checkProjectsRequest } from "../../../store/projects/requests";
import { exportCsvRequest } from "../../../store/orders/requests";
import OrderReportIcon from "../../../assets/icons/OrderReportW.svg";
import { useLockedPageHook } from "../LockedPage/hooks/useLockedPageHook";
import EnrichRequestModal from "../LockedPage/EnrichRequestModal/EnrichRequestModal";
import NotEnoughCreditsModal from "../LockedPage/NotEnoughCreditsModal/NotEnoughCreditsModal";
import OrderReceivedModal from "../LockedPage/OrderReceivedModal/OrderReceivedModal";
import SaveIcon from "../../../assets/icons/SaveW.svg";

const Header = ({ company, getUser, companyState, isDemo = false }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
        useState(false);
    const [downloadProps, setDownloadProps] = useState();
    const {
        t,
        copiedToClipboard,
        setCopiedToClipboard,
        showPopoverTags,
        showAddNewTagInput,
        errorTagNameMsg,
        getGenerateLinkStatus,
        generatedLink,
        setHistoryFrom,
        trackEvent,
        getCheckedProjects,
        getCheckedProjectsStatusSelector,
        checkedProjects,
        setCheckedProjects,
        generateLink,
        handleToggleDialog,
        openCreateProjectDialog,
        addSelectedCompanyToProject,
        handleNewProjectCreated,
        handleBuyingTopUpCreditsDialog,
        handleClosingTopUpCreditsDialog,
        handleShowTopUpModal,
        topUpCreditsModalShow,
        deleteTag,
        handleCheckboxChange,
        handleTagNameChange,
        handleAddNewTagClick,
        handleClosePopoverTags,
        handleClickTags,
        popoverTagsId,
        addItemToProjectStatus,
        selectedProject,
        dialogOpened,
        setShowAddToProjectDialog,
        setShowMenuAddToProjectDialog,
        showAddToProjectDialog,
        showMenuAddToProjectDialog,
        anchorEl,
        open,
        tagName,
        errorTagNameInput,
        handleTagNameInputKeyUp,
    } = useHeaderHook({ getUser, company });

    const {
        enrichRequestModalShow,
        notEnoughCreditsModalShow,
        showEnrichSuccess,
        handleClosingNotEnoughCreditsDialog,
        handleTopUp,
        handleClosingEnrichDialog,
        showEnrichRequestModal,
        handleCreateProjectDialogOpened,
        handleEnrichSuccess,
        handleClosingSuccess,
        credits,
    } = useLockedPageHook({ company, type: "company" });

    const { goBack } = useRouterHook();
    const prevCompany = usePrevious(company);
    useEffect(() => {
        if (
            (!!company.id || !!company.cid) &&
            prevCompany &&
            JSON.stringify(prevCompany) !== JSON.stringify(company) &&
            JSON.stringify(prevCompany) !==
                JSON.stringify({
                    deck: "",
                    name: "",
                    degree_of_fit: "",
                    website: "",
                    number_of_employees: "",
                    total_funding_amount_currency: "",
                    total_funding_amount_dollars: "",
                    hq_city: "",
                    hq_country: "",
                    investment_stage: "",
                    description: "",
                    sectors: "",
                    completeness_score: "",
                    vote_user: "",
                    sdgs: "",
                })
        ) {
            dispatch(
                checkProjectsRequest({
                    companyId:
                        window.location.pathname === "/companies/enriched/deck"
                            ? company.cid
                            : company.id,
                    type: "company",
                }),
            );
        }
    }, [dispatch, company, prevCompany]);
    useEffect(() => {
        let val = sessionStorage.getItem("history");
        setHistoryFrom(val);
    }, [setHistoryFrom]);
    useEffect(() => {
        //Intercom event tracking
        if (
            process.env.REACT_APP_ENV === "prod" ||
            process.env.REACT_APP_ENV === "staging"
        ) {
            trackEvent("Total company profile visits");
        }
    }, []);
    useEffect(() => {
        if (getGenerateLinkStatus === REQUEST_SUCCESS) {
            dispatch(resultsActions.postGenerateLinkUndefined());
            dispatch(resultsActions.generateShareLinkUndefined());
            setTimeout(() => {
                let copyText = document.getElementById("hiddenText");
                copyText && copyText.select();
                copyText && copyText.setSelectionRange(0, 99999);
                document.execCommand("copy");
            }, 500);
            setCopiedToClipboard(true);
            setTimeout(() => {
                setCopiedToClipboard(false);
            }, 3000);
            //Intercom event tracking
            if (
                process.env.REACT_APP_ENV === "prod" ||
                process.env.REACT_APP_ENV === "staging"
            ) {
                trackEvent("Sharing", {
                    CompanyName: company.name,
                    CompanySlug: company.slug,
                });
            }
        }
    }, [getGenerateLinkStatus]);
    useEffect(() => {
        if (addItemToProjectStatus === REQUEST_SUCCESS) {
            setShowAddToProjectDialog(false);
            if (selectedProject) {
                intercomTrackEvent("Companies added to projects", {
                    "Project title": selectedProject.project,
                    "Company slug": company?.slug,
                    "Company name": company?.name,
                });
            }
        }
    }, [addItemToProjectStatus]);
    useEffect(() => {
        getCheckedProjectsStatusSelector === REQUEST_SUCCESS &&
            setCheckedProjects(getCheckedProjects);
    }, [
        getCheckedProjectsStatusSelector,
        setCheckedProjects,
        getCheckedProjects,
    ]);

    const isFreeUserOrGuest =
        !getUser ||
        (getUser &&
            getUser.subscription &&
            (getUser.subscription.name === "free" ||
                !getUser.subscription.is_active));

    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
    };

    const handleOpenAddtoProject = companyId => {
        !showMenuAddToProjectDialog &&
            dispatch(checkProjectsRequest({ companyId: companyId }));
        setShowMenuAddToProjectDialog(
            prevShowAddToProjectDialog => !prevShowAddToProjectDialog,
        );
    };
    const handleDownloadToCsv = () => {
        // console.log("company ", company);
        setDownloadProps({
            ids: company.id || company.cid,
            type: "companies",
            doc: "csv",
            name: `valuer_${company.name || company.deck?.name}_report`,
        });
        if (
            getUser.downloads === 1 &&
            (getUser.subscription.plan_type === "free_trial" ||
                getUser.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            getUser.subscription.plan_type === "free_trial" &&
            getUser.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            getUser.subscription.plan_type === "free_trial" &&
            !getUser.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!getUser);
        } else if (getUser.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            let payload = {
                ids: company.id || company.cid,
                type: "companies",
                doc: "csv",
                name: `valuer_${company.name || company.deck?.name}_report`,
                pageName: "company profile",
            };

            dispatch(exportCsvRequest(payload));
            mixpanelTrackEvent(`Downloaded document`, {
                type: "csv",
                page: "company profile",
            });
        }
    };

    const handleDownloadToPdf = () => {
        setDownloadProps({
            ids: company.id || company.cid,
            type: "companies",
            doc: "pdf",
            name: `valuer_${company.name || company.deck?.name}_report`,
        });
        if (
            getUser.downloads === 1 &&
            (getUser.subscription.plan_type === "free_trial" ||
                getUser.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            getUser.subscription.plan_type === "free_trial" &&
            getUser.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            getUser.subscription.plan_type === "free_trial" &&
            !getUser.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!getUser);
        } else if (getUser.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            let payload = {
                ids: company.id || company.cid,
                type: "companies",
                doc: "pdf",
                name: `valuer_${company.name || company.deck?.name}_report`,
                pageName: "company profile",
            };

            dispatch(exportCsvRequest(payload));
            mixpanelTrackEvent(`Downloaded document`, {
                type: "pdf",
                page: "company profile",
            });
        }
    };

    const download = () => {
        dispatch(exportCsvRequest(downloadProps));
        mixpanelTrackEvent(`Downloaded document`, {
            type: downloadProps.doc,
            page: "company profile",
        });
    };

    const saveCompany = () => {
        // TODO: implement when rdy
    };

    const [menuItems, setMenuItems] = useState([
        {
            label: (
                <Typography variant="bodyXs" className={classes.mono}>
                    {t("companyProfile.headerMenu.share")}
                </Typography>
            ),
            onClick: generateLink,
            intercomId: "Share company profile button",
        },
    ]);

    useEffect(() => {
        let isFreeOrGuest =
            !getUser ||
            (getUser &&
                getUser.subscription &&
                (getUser.subscription.name === "free" ||
                    !getUser.subscription.is_active));
        !isFreeOrGuest
            ? setMenuItems([
                  {
                      label: (
                          <Typography variant="bodyXs" className={classes.mono}>
                              {t("companyProfile.headerMenu.share")}
                          </Typography>
                      ),
                      onClick: generateLink,
                      intercomId: "Share company profile button",
                  },
                  {
                      label: (
                          <Typography variant="bodyXs" className={classes.mono}>
                              {t("common.downloadAsCSV")}
                          </Typography>
                      ),
                      onClick: handleDownloadToCsv,
                      intercomId: "Download to CSV button",
                  },
                  {
                      label: (
                          <Typography variant="bodyXs" className={classes.mono}>
                              {t("common.downloadAsPDF")}
                          </Typography>
                      ),
                      onClick: handleDownloadToPdf,
                      intercomId: "Download to PDF button",
                  },
              ])
            : setMenuItems([
                  {
                      label: (
                          <Typography variant="bodyXs" className={classes.mono}>
                              {t("companyProfile.headerMenu.share")}
                          </Typography>
                      ),
                      onClick: generateLink,
                      intercomId: "Share company profile button",
                  },
                  // TODO: commented out until we figure out if we will reuse the projects logic for projects
                  // {
                  //     label: <Typography variant="bodyXs" className={classes.mono}>{t('companyProfile.headerMenu.project.create')}</Typography>,
                  //     disabled: getUser && !getUser.subscription.is_active,
                  //     onClick: (e) => openCreateProjectDialog(e),
                  //     intercomId: "Create project dialog button"
                  // }
              ]);
    }, [showMenuAddToProjectDialog, checkedProjects, getUser]);

    return (
        <div>
            <div className={classes.flexSpaceCenter}>
                <TextField
                    className={classes.hiddenInput}
                    id="hiddenText"
                    value={generatedLink}
                    type="text"></TextField>
                {getUser && (
                    <Link
                        onClick={goBack}
                        variant="body2"
                        rotateIcon
                        color="secondary"
                        className={classes.goBack}>
                        {t("companyProfile.backToResults")}
                        <ArrowRight />
                    </Link>
                )}

                {companyState !== null && (
                    <div className={classes.flexRelative}>
                        {/*{!isFreeUserOrGuest && <div className={classes.addToProject} onClick={openAddtoProject}>*/}
                        {/*    <Typography color="secondaryDark" variant="bodyButton">{t("companyProfile.headerMenu.project.add")}</Typography>*/}
                        {/*    {!showAddToProjectDialog ? <ArrowDown className={classes.arrowImg} /> : <ArrowUpOrange className={classnames([{ [classes.rotateArrow]: showAddToProjectDialog }], classes.arrowIcon)} />}*/}
                        {/*</div>}*/}
                        {getUser && getUser.selected_company && (
                            <div className={classes.actions}>
                                <div className={classes.tags}>
                                    <div
                                        aria-describedby={popoverTagsId}
                                        className={classes.addToProject}
                                        onClick={handleClickTags}>
                                        <Typography
                                            color="secondaryDark"
                                            variant="bodyButton">
                                            {t("companyProfile.tags")}
                                        </Typography>
                                        {!showPopoverTags ? (
                                            <ArrowDown
                                                className={classes.arrowImg}
                                            />
                                        ) : (
                                            <ArrowUpOrange
                                                className={classnames(
                                                    [
                                                        {
                                                            [classes.rotateArrow]:
                                                                showPopoverTags,
                                                        },
                                                    ],
                                                    classes.arrowIcon,
                                                )}
                                            />
                                        )}
                                    </div>
                                    <Popover
                                        id={popoverTagsId}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClosePopoverTags}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}>
                                        <div
                                            className={
                                                classes.tagsPopoverContent
                                            }>
                                            {!showAddNewTagInput && (
                                                <Button
                                                    onClick={
                                                        handleAddNewTagClick
                                                    }
                                                    variant="primary"
                                                    className={classes.tagsBtn}>
                                                    {t(
                                                        "companyProfile.addNewTag",
                                                    )}
                                                </Button>
                                            )}
                                            {showAddNewTagInput && (
                                                <div>
                                                    <TextField
                                                        placeholder={t(
                                                            "companyProfile.enterNewTagName",
                                                        )}
                                                        id="add-new-company-tag"
                                                        value={tagName}
                                                        onChange={
                                                            handleTagNameChange
                                                        }
                                                        error={
                                                            errorTagNameInput
                                                        }
                                                        errorLabel={
                                                            errorTagNameMsg
                                                        }
                                                        onKeyUp={
                                                            handleTagNameInputKeyUp
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    classes.tagsPopoverTags
                                                }>
                                                {company &&
                                                    company.all_tags &&
                                                    company.all_tags.map(
                                                        (tag, i) => {
                                                            return (
                                                                <div
                                                                    className={
                                                                        classes.tagsPopoverTag
                                                                    }
                                                                    key={
                                                                        tag.id
                                                                    }>
                                                                    <FormControlLabel
                                                                        classes={{
                                                                            root: classes.formControlLabel,
                                                                        }}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={
                                                                                    !!tag.is_selected
                                                                                }
                                                                                onChange={() => {
                                                                                    handleCheckboxChange(
                                                                                        tag,
                                                                                    );
                                                                                }}
                                                                                classes={{
                                                                                    root: classes.checkboxTag,
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={
                                                                            tag.name
                                                                        }
                                                                        color="secondary"
                                                                    />
                                                                    <div
                                                                        className={
                                                                            classes.tagActions
                                                                        }>
                                                                        {!tag.is_predefined && (
                                                                            <div
                                                                                className={
                                                                                    classes.removeTag
                                                                                }>
                                                                                <img
                                                                                    src={
                                                                                        RemoveIcon
                                                                                    }
                                                                                    alt={t(
                                                                                        "companyProfile.removeTag",
                                                                                    )}
                                                                                    className={
                                                                                        classes.removeTagIcon
                                                                                    }
                                                                                />
                                                                                <img
                                                                                    src={
                                                                                        RemoveIconHover
                                                                                    }
                                                                                    onClick={() => {
                                                                                        deleteTag(
                                                                                            tag.id,
                                                                                        );
                                                                                    }}
                                                                                    alt={t(
                                                                                        "companyProfile.removeTag",
                                                                                    )}
                                                                                    className={
                                                                                        classes.removeTagIconHover
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                            </div>
                        )}
                        {showAddToProjectDialog && (
                            <ClickAwayListener
                                onClickAway={() =>
                                    setShowAddToProjectDialog(false)
                                }>
                                <div
                                    className={classnames([
                                        classes.addPopup,
                                        "non-laptop",
                                    ])}
                                    data-intercom-target="Add to project">
                                    <div className={classes.projectName}>
                                        <span
                                            className={classes.flexCenter}
                                            onClick={e =>
                                                openCreateProjectDialog(e)
                                            }
                                            component="span">
                                            {t(
                                                "companyProfile.headerMenu.project.create",
                                            )}
                                        </span>
                                    </div>
                                    {checkedProjects &&
                                    checkedProjects.length > 0 ? (
                                        checkedProjects.map((project, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={classnames([
                                                        classes.projectName,
                                                        {
                                                            [classes.disabled]:
                                                                project.is_in_project,
                                                        },
                                                    ])}
                                                    onClick={e =>
                                                        addSelectedCompanyToProject(
                                                            e,
                                                            project,
                                                        )
                                                    }>
                                                    {project.is_in_project ? (
                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    color="white"
                                                                    variant="body2">
                                                                    {t(
                                                                        "results.companyAlreadyAdded",
                                                                    )}
                                                                </Typography>
                                                            }
                                                            arrow>
                                                            <span>
                                                                {
                                                                    project.project
                                                                }
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <span>
                                                            {project.project}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div
                                            className={
                                                classes.projectNameWrapper
                                            }>
                                            Loading...
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                        )}
                        {/* <Button onClick={() => requestReport()} className={classes.requestReportButton}
                        variant="tertiary">{t("companyProfile.requestReport")}<RequestReport className={classes.shareIcon} /></Button> */}
                        {/*{getUser && !isPreview && <Tooltip title={<Typography color="white" variant="body2">{t("companyProfile.shareWarning")}</Typography>} arrow>*/}
                        {/*    <div className={"non-laptop"}>*/}
                        {/*        <Button onClick={generateLink} className={classes.shareButton} data-intercom-target="Share company" variant="primary">{t("companyProfile.headerMenu.share")}<Share className={classes.shareIcon} /></Button>*/}
                        {/*    </div>*/}
                        {/*</Tooltip>}*/}
                        {!company.ready_for_release &&
                            !company.enrichment_request && (
                                <div
                                    className={classes.actionIconWrapper}
                                    onClick={e =>
                                        showEnrichRequestModal(e, company)
                                    }>
                                    <div className={classes.actionIconContent}>
                                        <img
                                            width="24"
                                            height="24"
                                            src={OrderReportIcon}
                                            alt={t("card.orderReport")}
                                            className={classes.actionIcon}
                                        />
                                    </div>
                                    <Typography
                                        variant="bodyXs"
                                        color="textLight"
                                        className={classes.actionText}>
                                        {t("card.orderReport")}
                                    </Typography>
                                </div>
                            )}
                        {getUser && !isDemo && (
                            <div
                                className={classes.actionIconWrapper}
                                onClick={saveCompany}>
                                <AddToProjectMenu
                                    buttonType="savecard"
                                    company={company}
                                    type={"company"}
                                />
                            </div>
                        )}

                        {/*{ getUser &&*/}
                        {/*    <Menu*/}
                        {/*        btnClassName={classes.moreButton}*/}
                        {/*        onChange={handleMenuClick}*/}
                        {/*        menuItems={menuItems}*/}
                        {/*        // TODO: commented out until we figure out if we will reuse the projects logic for projects*/}
                        {/*        // otherItems={ <AddToProjectMenu displayListTypeInMenu={true} company={company}/> }*/}
                        {/*    >*/}
                        {/*        <MoreIconNoFill className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />*/}
                        {/*    </Menu>*/}
                        {/*}*/}

                        {copiedToClipboard && (
                            <div className={classes.tooltipCopy}>
                                <TooltipCheck className={classes.checkImg} />
                                <Typography color="white" variant="body2">
                                    {t("companyProfile.copiedToClipboard")}
                                </Typography>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {enrichRequestModalShow && (
                <EnrichRequestModal
                    source="company"
                    credits={credits}
                    company={company}
                    dialogOpened={enrichRequestModalShow}
                    onChange={handleClosingEnrichDialog}
                    createProjectDialogOpened={handleCreateProjectDialogOpened}
                    enrichSuccess={handleEnrichSuccess}
                    cId={company && company.id}
                />
            )}
            {notEnoughCreditsModalShow && (
                <NotEnoughCreditsModal
                    credits={credits}
                    dialogOpened={notEnoughCreditsModalShow}
                    onChange={handleClosingNotEnoughCreditsDialog}
                    onTopUp={handleTopUp}
                />
            )}
            {showEnrichSuccess && (
                <OrderReceivedModal
                    dialogOpened={showEnrichSuccess}
                    onChange={handleClosingSuccess}
                />
            )}
            <TopUpCreditsModal
                dialogOpened={topUpCreditsModalShow}
                onBuy={handleBuyingTopUpCreditsDialog}
                onChange={handleClosingTopUpCreditsDialog}
            />
            <AreYouSureModal
                dialogOpened={showAreYouSureModal}
                onChange={() => setShowAreYouSureModal(false)}
                title={t("download.areYouSureTitle")}
                subtitle={
                    <>
                        {t("download.subtitleFreeTrial1")}
                        <Typography
                            component="div"
                            className={classes.actionText}
                            variant="body"
                            color="secondaryDark">
                            {t("download.subtitleFreeTrial2")}
                        </Typography>
                    </>
                }
                actionButtonText={t("download.iWantToDownload")}
                cancelButtonText={t("download.letsTryLater")}
                onActionClick={download}
            />
            <AreYouSureModal
                dialogOpened={showDownloadsExceededModal}
                onChange={() => setShowDownloadsExceededModal(false)}
                title={t("download.downloadsExceededTitle")}
                subtitle={t("download.downloadsExceededSubtitle")}
                actionButtonText={t("download.upgradePlan")}
                cancelButtonText={t("download.upgradeLater")}
                onActionClick={() =>
                    (window.location.href = getPricingPage(!!getUser))
                }
            />
        </div>
    );
};
export default Header;
