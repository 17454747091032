import { all, takeEvery } from "redux-saga/effects";
import {
    fetchTechnologiesRequest,
    fetchTechnologyRequest,
    filterTechnologyCompaniesRequest,
    fetchTechnologyCompaniesPageRequest,
    fetchTechnologyCompanyRequest,
    enrichTechnologyRequest,
    enrichTechnologyCompanyRequest,
    filterTechnologyCompaniesCountRequest,
    fetchTechnologyCompaniesChartRequest,
    fetchTechnologyCompaniesTeamSizeChartRequest,
    suggestTechnologyRequest,
} from "../requests";
import {
    fetchAllTechnologies,
    fetchTechnology,
    fetchFilterTechnologyCompanies,
    fetchTechnologyCompaniesPage,
    fetchTechnologyCompany,
    fetchEnrichTechnologyCompany,
    fetchEnrichTechnology,
    fetchFilterTechnologyCompaniesCount,
    fetchTechnologyCompaniesChart,
    fetchTechnologyCompaniesTeamSizeChart,
    suggestTechnology,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchTechnologies() {
    yield takeEvery(
        convertTypeFromAction(fetchTechnologiesRequest),
        ({ payload: { page, search } }) => fetchAllTechnologies(page, search),
    );
}
function* watchFetchTechnology() {
    yield takeEvery(
        convertTypeFromAction(fetchTechnologyRequest),
        ({ payload: { slug } }) => fetchTechnology(slug),
    );
}
function* watchFilterCompaniesTechnology() {
    yield takeEvery(
        convertTypeFromAction(filterTechnologyCompaniesRequest),
        ({ payload: { slug, data, clearResults, isGuest } }) =>
            fetchFilterTechnologyCompanies(slug, data, clearResults, isGuest),
    );
}
function* watchFilterCompaniesCountTechnology() {
    yield takeEvery(
        convertTypeFromAction(filterTechnologyCompaniesCountRequest),
        ({
            payload: {
                slug,
                data,
                clearResults,
                isGuest,
                lastFilteredField,
                lastFilteredFieldValue,
            },
        }) =>
            fetchFilterTechnologyCompaniesCount(
                slug,
                data,
                clearResults,
                isGuest,
                lastFilteredField,
                lastFilteredFieldValue,
            ),
    );
}
function* watchFetchTechnologyCompaniesPage() {
    yield takeEvery(
        convertTypeFromAction(fetchTechnologyCompaniesPageRequest),
        ({ payload: { slug, data } }) =>
            fetchTechnologyCompaniesPage(slug, data),
    );
}
function* watchFetchTechnologyCompany() {
    yield takeEvery(
        convertTypeFromAction(fetchTechnologyCompanyRequest),
        ({ payload: { slug, cId } }) => fetchTechnologyCompany(slug, cId),
    );
}
function* watchEnrichTechnologyCompany() {
    yield takeEvery(
        convertTypeFromAction(enrichTechnologyCompanyRequest),
        ({ payload: { slug, projectSlug, cId } }) =>
            fetchEnrichTechnologyCompany(slug, projectSlug, cId),
    );
}
function* watchEnrichTechnology() {
    yield takeEvery(
        convertTypeFromAction(enrichTechnologyRequest),
        ({ payload: { slug } }) => fetchEnrichTechnology(slug),
    );
}

function* watchFetchTechnologyCompaniesChart() {
    yield takeEvery(
        convertTypeFromAction(fetchTechnologyCompaniesChartRequest),
        ({ payload: { id, type, grouped, filterNameValue } }) =>
            fetchTechnologyCompaniesChart(id, type, grouped, filterNameValue),
    );
}

function* watchFetchTechnologyCompaniesTeamSizeChart() {
    yield takeEvery(
        convertTypeFromAction(fetchTechnologyCompaniesTeamSizeChartRequest),
        ({ payload: { id, type, grouped, filterNameValue } }) =>
            fetchTechnologyCompaniesTeamSizeChart(
                id,
                type,
                grouped,
                filterNameValue,
            ),
    );
}

function* watchSuggestTechnology() {
    yield takeEvery(
        convertTypeFromAction(suggestTechnologyRequest),
        ({ payload: { data } }) => suggestTechnology(data),
    );
}

function* saga() {
    yield all([
        watchFetchTechnologies(),
        watchFetchTechnology(),
        watchFilterCompaniesTechnology(),
        watchFetchTechnologyCompaniesPage(),
        watchFetchTechnologyCompany(),
        watchEnrichTechnologyCompany(),
        watchEnrichTechnology(),
        watchFilterCompaniesCountTechnology(),
        watchFetchTechnologyCompaniesChart(),
        watchFetchTechnologyCompaniesTeamSizeChart(),
        watchSuggestTechnology(),
    ]);
}

export { saga };
