import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, shape, typography, breakpoint }) =>
        createStyles({
            root: {
                flex: "calc(50% - 8px)",
                maxWidth: "calc(50% - 8px)",
                position: "relative",
                marginBottom: spacing(2),
                "&:nth-child(odd)": {
                    marginRight: spacing(2),
                    [breakpoint.down("sm")]: {
                        marginRight: 0,
                    },
                },
                "&:hover": {
                    boxShadow:
                        "0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)",
                    cursor: "pointer",
                },
                [breakpoint.down("sm")]: {
                    width: "100%",
                    flex: "100%",
                    maxWidth: "100%",
                },
            },
            description: {
                height: 48,
                display: "-webkit-box",
                maxHeight: 48,
                overflow: "hidden",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": 2,
            },
            nameText: {
                fontSize: "14px",
                display: "-webkit-box",
                maxHeight: 48,
                overflow: "hidden",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": 2,
                paddingRight: spacing(2),
                alignItems: "center",
            },
            tablePaper: {
                height: "100%",
                borderRadius: spacing(3),
                boxShadow: "none",
                width: "100%",
                position: "relative !important",
            },
            tablePaperWithStickyHeader: {
                paddingTop: 110,
            },
            activeSort: {
                "& $sortIcon": {
                    fill: palette.primary.main,
                },
            },
            tableTitle: {
                height: "inherit"
            },
            sortIcon: {
                position: "absolute",
                right: `-${spacing(3)}px`,
                "&:hover": {
                    fill: palette.primary.main,
                },
            },
            tableSortLabelRoot: {
                position: "relative",
            },
            iconDirectionAsc: {
                fill: palette.primary.main,
            },
            header: {
                // position: "relative",
                // paddingLeft: spacing(2)
            },
            stickyToolbar: {
                position: "fixed !important",
                top: 0,
                width: ({ tableWidth }) => (tableWidth ? tableWidth : "100%"),
                background: palette.common.white,
                zIndex: 2,
                padding: "16px 30px",
                transform: "translateX(-32px)",
            },
            stickyHeader: {
                position: "fixed",
                top: 65,
                width: ({ tableWidth }) => (tableWidth ? tableWidth : "100%"),
                background: palette.common.white,
                zIndex: 1,
                padding: spacing(2),
                borderBottom: `1px solid ${palette.text.lightest}`,
            },
            downloadButton: {
                display: "flex",
                alignItems: "center",
                background: palette.success.backgroundLight,
                borderRadius: 100,
                padding: `4px ${spacing(2)}px 4px ${spacing(3)}px`,
                cursor: "pointer",
                marginRight: spacing(2),
            },
            downloadButtonDisabled: {
                display: "flex",
                alignItems: "center",
                background: palette.success.backgroundLight,
                borderRadius: 100,
                padding: `4px ${spacing(2)}px 4px ${spacing(3)}px`,
                marginRight: spacing(2),
                cursor: "default",
                color: palette.text.lighter,
                "&:active": {
                    display: "flex",
                    alignItems: "center",
                    background: palette.success.backgroundLight,
                    borderRadius: 100,
                    padding: `4px ${spacing(2)}px 4px ${spacing(3)}px`,
                    cursor: "pointer",
                    marginRight: spacing(2),
                },
                "& $downloadIcon path": {
                    stroke: palette.text.lighter,
                },
            },
            downloadIcon: {
                "& path": {
                    stroke: palette.common.black,
                },
            },
            successButton: {
                background: palette.success.backgroundLight,
                borderColor: palette.success.backgroundLight,
                marginRight: spacing(2),
                color: palette.common.black,
            },
            successButtonDisabled: {
                background: palette.success.backgroundLight,
                borderColor: palette.success.backgroundLight,
                marginRight: spacing(2),
                color: palette.text.lighter,
                cursor: "default",
                "&:active": {
                    background: palette.success.backgroundLight,
                    borderColor: palette.success.backgroundLight,
                },
            },
            customRows: {
                marginTop: "0px",
            },
            tableRow: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: "none",
                padding: spacing(2),
                borderBottom: `1px solid ${palette.text.lightest}`,
                cursor: "pointer",
            },
            tableToolbar: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: "none",
                padding: spacing(2),
            },
            tableHeadRow: {
                // position: "fixed",
                position: "sticky",
                top: "0px",
                left: "97px",
                zIndex: 2,
                padding: `${spacing(2)}px`,
                fontWeight: 500,
                background: "white",
                minWidth: "calc(45% - 62px)",
                [breakpoint.down("xl")]: {
                    paddingLeft: ({ user }) => (user?.csp ? 0 : spacing(4)),
                    paddingright: ({ user }) => (user?.csp ? 0 : spacing(2)),
                },
            },
            tableCell1: {
                flex: 1,
                wordBreak: "break-word",
                paddingLeft: 4,
            },
            tableCell2: {
                flex: 2,
                wordBreak: "break-word",
                paddingLeft: 4,
            },
            tableCell3: {
                flex: 3,
                wordBreak: "break-word",
                paddingLeft: 4,
            },
            tableCell4: {
                flex: 4,
                wordBreak: "break-word",
                paddingLeft: 4,
            },
            tableCellDescription: {
                flex: 3,
                wordBreak: "break-word",
                paddingLeft: 4,
                [breakpoint.up("xxl")]: {
                    flex: 4,
                },
                [breakpoint.up("xxxl")]: {
                    flex: 6,
                },
            },
            tableCellButtonsToolbar: {
                flex: 7,
                paddingLeft: 4,
                display: "flex",
                flexDirection: "row",
            },
            tableCellName: {
                width: 140,
                maxWidth: 140,
                flex: 6,
                wordBreak: "break-word",
                paddingLeft: 4,
                userSelect: "none",
                // height: 52,
                display: "flex",
                // maxHeight: 52,
                overflow: "hidden",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": 2,
                textOverflow: "ellipsis",
                [breakpoint.down("xl")]: {
                    width: 100,
                    maxWidth: 100,
                },
            },
            tableCellCheckbox: {
                display: "flex",
                height: 55,
                flex: 1,
                alignSelf: "flex-start",
                wordBreak: "break-word",
                maxWidth: 56,
                minWidth: 56,
                [breakpoint.down("xl")]: {
                    maxWidth: 40,
                    minWidth: 40,
                },
            },
            tableCellHeaderCheckbox: {
                flex: 1,
                wordBreak: "break-word",
                maxWidth: 56,
                minWidth: 56,
                [breakpoint.down("xl")]: {
                    maxWidth: 24,
                    minWidth: 24,
                },
                // height: "100%"
            },
            checkbox: {},
            companyName: {
                textDecoration: "unset",
                outline: "none",
                "-webkit-tap-highlight-color": "transparent",
                userSelect: "none",
                height: 52,
                maxHeight: 52,
                overflow: "hidden",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": 2,
                textOverflow: "ellipsis",
                display: "flex",
                alignItems: "center",
                gap: 10,
                // paddingTop: '6px'
            },
            moreButton: {
                minWidth: 0,
                padding: 0,
            },
            moreIcon: {
                fill: palette.secondary.dark,
                transform: "rotate(90deg)",
            },
            moreIconOpened: {
                fill: palette.primary.dark,
            },
            tooltip: {
                background: `${palette.common.white} !important`,
                color: `${palette.text.main} !important`,
                boxShadow: "0px 0px 6.94982px rgba(0, 0, 0, 0.1) !important",
                maxWidth: 500,
            },
            tooltipDescriptionContent: {
                maxHeight: 48,
                fontSize: "14px",
                fontWeight: 300,
            },
            tableCellMatch: {
                fontWeight: 500,
            },
            hideOnHover: {},
            previewButtonBox: {
                display: "none",
            },
            previewButton: {
                width: "150px",
                background: palette.success.dark,
                border: "none",
                fontFamily: "GT Flexa, sans-serif",
                color: palette.common.white,
                "&:active": {
                    border: "none",
                    background: palette.success.dark,
                },
            },
            showOnHover: {
                display: "none",
            },
            rowHover: {
                "&:hover": {
                    "& $hideOnHover": {
                        display: "none",
                    },
                    "& $showOnHover": {
                        [breakpoint.up("sm")]: {
                            display: "flex",
                            flex: 17,
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            paddingLeft: spacing(7),
                        },
                    },
                },
            },
            tooltipArrow: {
                color: `${palette.common.white} !important`,
            },
            selectedLabel: {
                display: "flex",
                alignItems: "center",
            },
            checkboxAll: {
                width: 56,
            },
            arrowImg: {
                marginLeft: spacing(1),
            },
            addToProjectWrapper: {
                position: "relative",
            },
            addPopup: {
                zIndex: 2,
                width: 235,
                maxHeight: 202,
                background: palette.common.white,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: spacing(2),
                border: "1px solid",
                borderColor: palette.text.backgroundDark,
                top: 40,
                right: 0,
                overflow: "auto",
                paddingTop: spacing(1),
                paddingBottom: spacing(1),
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                "&::-webkit-scrollbar": {
                    width: 2,
                },
                "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 5px transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                    background: palette.text.main,
                    borderRadius: 0,
                },
            },
            projectNameWrapper: {
                width: "100%",
                display: "flex",
                padding: `${spacing(2)}px ${spacing(3)}px`,
                borderBottom: `1px solid ${palette.text.backgroundLight}`,
                color: palette.text.light,
                "&:hover": {
                    background: palette.text.backgroundLight,
                },
                "&:last-child": {
                    borderBottom: "none",
                },
            },
            blackProjectName: {
                color: palette.common.black,
                cursor: "default",
            },
            addedToProject: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
            },
            projectName: {
                display: "flex",
                alignItems: "center",
                color: palette.text.light,
            },
            createNewProject: {
                display: "flex",
                justifyContent: "center",
            },
            name: {
                cursor: "pointer",
                "&:hover": {
                    textDecoration: "underline",
                },
            },
            mono: {
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            relative: {
                position: "relative",
            },
            clickable: {
                "&:hover": {
                    cursor: "pointer",
                    background: palette.success.backgroundDark,
                },
            },
            rotatedIcon: {
                transform: "rotate(180deg)",
            },
            enrichedCompanyLabel: {
                width: "110px",
                height: spacing(3),
                borderRadius: spacing(2),
                padding: spacing(1),
                textAlign: "center",
                lineHeight: "9px",
                background: palette.success.backgroundLight,
            },
            workspacename: {
                flex: 7,
                paddingLeft: "18px",
            },
            workspacetype: {
                flex: 8,
            },
            workspacedescription: {
                flex: 8,
            },
            companyIcon: {
                width: spacing(5),
                height: spacing(5),
                padding: spacing(1),
                borderRadius: spacing(1),
                background: palette.success.backgroundLight,
            },
            companyIconEnriched: {
                background: "transparent",
                border: `2px solid ${palette.success.backgroundLight}`,
            },
            iconWrapper: {
                position: "relative",
            },
            enrichedIcon: {
                position: "absolute",
                bottom: 2,
                right: -4,
                width: 12,
                height: 12,
                padding: 2,
                background: palette.common.white,
                borderRadius: 2,
            },
            hoverIcon: {
                minWidth: 150,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",

                "&:hover": {
                    fontWeight: 700,
                    "& img": {
                        transform: "scale(1.1)",
                    },
                },
            },
            headerFiltersWrapper: {
                display: "flex",
                alignItems: "center",
            },
            hideOnMobile: {
                [breakpoint.down("xl")]: {
                    display: ({ user }) => user?.csp && "none",
                },
                [breakpoint.down("sm")]: {
                    display: ({ user }) => !user?.csp && "none",
                },
            },
            hideIfSaveMenuIsOpened: {
                display: "none",
            },
            showIfSaveMenuIsOpened: {
                display: "flex",
                flex: 17,
                alignItems: "flex-end",
                justifyContent: "flex-end",
                paddingLeft: spacing(7),
            },
        }),
);
