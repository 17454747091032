import React, { useState, useEffect } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getGenerateClusterDataRequest } from "../../store/cluster/requests";
import {
    clusterSelector,
    getClusterStatusSelector,
} from "../../store/cluster/selectors";
// Constants
import { REQUEST_PENDING } from "../../constants/statuses";
import Scene from "./Scene";
import PreviewModal from "../../pages/Results/components/modals/PreviewModal";
import SwitchToggle from "../SwitchToggle/SwitchToggle";

const Graph3D = ({
    resourceType = "search",
    resourceId = null,
    filterNameValue,
    user,
}) => {
    const dispatch = useDispatch();
    const [traces, setTraces] = useState([]);
    const [filtered, setFiltered] = useState(traces);
    const [isFilter, setIsFilter] = useState(false);
    const [groupBy, setGroupBy] = useState("sectors");
    const [coordinates, setCoordinates] = useState([]);
    const [legend, setLegend] = useState([]);
    const [loading, setLoading] = useState(true);
    let clusterLoadingTimeout;
    const clusterStatusSelector = useSelector(getClusterStatusSelector);
    const clusterData = useSelector(clusterSelector);
    const [resetCamera, setResetCamera] = useState(false);
    const [toggleRotation, setToggleRotation] = useState(true);
    const [modal, setModal] = useState(false);

    function arrayEquals(a, b) {
        return (
            Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index])
        );
    }

    const handleClick = state => {
        setToggleRotation(state);
    };

    const colorByFilter = categoryName => {
        // if isFilter === true and active opacity 1, rest opacity 0.6;
        if (
            isFilter &&
            categoryName === filtered[0]?.name &&
            filtered.length === 1
        )
            return "#000000";
        if (!isFilter) return "#666666";
        return "#666666";
    };

    useEffect(() => {
        setFiltered(traces);
    }, [traces]);
    useEffect(() => {
        const coordinates = [];
        filtered.map(category => {
            category.x.map((c, i) => {
                const coordinate = {
                    x: category.x[i],
                    y: category.y[i],
                    z: category.z[i],
                    color: category.marker.color,
                    company: category.marker.company[i],
                };
                coordinates.push(coordinate);
            });
        });
        setCoordinates(coordinates);
    }, [traces, filtered]);

    useEffect(() => {
        const legendArray = [];
        traces.map(category => {
            const newTrace = {
                name: category.name,
                linkedCompanies: category.marker.company,
                color: category.marker.color,
            };
            legendArray.push(newTrace);
        });
        setLegend(legendArray);
    }, [traces]);

    useEffect(() => {
        dispatch(
            getGenerateClusterDataRequest({
                data: {
                    resourceType,
                    resourceId,
                    group: groupBy,
                    filterUrlString: filterNameValue,
                },
            }),
        );
    }, [resourceId, filterNameValue]);

    useEffect(() => {
        if (clusterData) {
            setTraces(
                clusterData.traces.map(item => {
                    return {
                        x: item.x,
                        y: item.y,
                        z: item.z,
                        text: item.text,
                        hovertemplate: item.hovertemplate,
                        name:
                            item.name +
                            (item.name !== "Center"
                                ? " (" + item.x.length + ")"
                                : ""),
                        mode: item.mode,
                        type: item.type,
                        symbol: item.symbol,
                        opacity: item.opacity,
                        marker: {
                            id: item.marker.id,
                            company: item.marker.company,
                            color: item.marker.color,
                            symbol: item.marker.symbol,
                            size: item.marker.size,
                            opacity: item.marker.opacity,
                            line: {
                                width: item.marker.line.width,
                                color: item.marker.line.color,
                            },
                        },
                    };
                }),
            );
        } else {
            setTraces([]);
        }
    }, [clusterStatusSelector, clusterData, groupBy]);

    const openModal = state => {
        setModal({ isOpen: true, company: state.company });
    };

    useEffect(() => {
        if (clusterStatusSelector === REQUEST_PENDING) {
            setLoading(true);
        } else {
            clusterLoadingTimeout = setTimeout(() => setLoading(false), 800);
            return () => clearTimeout(clusterLoadingTimeout);
        }
    }, [clusterStatusSelector, loading, traces]);

    return (
        <div
            style={{
                height: "100%",
            }}>
            <div
                style={{
                    display: "flex",
                    marginLeft: "5%",
                    flexDirection: "row",
                    maxWidth: "100%",
                    justifyContent: "space-between",
                }}>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                    }}>
                    {legend.map(l => (
                        <div
                            onClick={e => {
                                const array1 = filtered;
                                const array2 = traces.filter(
                                    trace => trace?.name === e.target.innerText,
                                );
                                if (arrayEquals(array1, array2)) {
                                    setIsFilter(false);
                                    setFiltered(traces);
                                } else {
                                    setIsFilter(true);
                                    setFiltered(
                                        traces.filter(
                                            trace =>
                                                trace?.name ===
                                                e.target.innerText,
                                        ),
                                    );
                                }
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                            <div
                                style={{
                                    height: 10,
                                    width: 10,
                                    background: l.color,
                                    borderRadius: "50%",
                                    marginRight: 5,
                                    border: "1px solid gray",
                                }}
                            />
                            <p
                                style={{
                                    margin: 0,
                                    marginRight: 10,
                                    whiteSpace: "nowrap",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    // opacity: calculateOpacity(l.name),
                                    color: colorByFilter(l.name),
                                }}>
                                {l.name}
                            </p>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: "flex",
                        marginRight: "5%",
                        marginBottom: "5px",
                        justifyContent: "end",
                    }}>
                    <SwitchToggle
                        label={"Rotation"}
                        checked={toggleRotation}
                        onChange={() => setToggleRotation(!toggleRotation)}
                    />
                </div>
            </div>
            {modal && (
                <PreviewModal
                    dialogOpened={modal.isOpen}
                    onChange={() => setModal(false)}
                    data={modal.company}
                    user={user}
                    type="company"
                />
            )}

            <div
                style={{
                    visibility: loading ? "hidden" : "visible",
                    display: "flex" /* Have to be block */,
                    height: "100%",
                    position: "relative",
                }}>
                <Scene
                    user={user}
                    coordinates={coordinates}
                    resetCamera={resetCamera}
                    toggleRotation={toggleRotation}
                    handleClick={handleClick}
                    openModal={openModal}
                />
            </div>
        </div>
    );
};

export default Graph3D;
