import React, { useState } from "react";
import useStyles from "./style.js";
import { useTranslation } from "react-i18next";
import { ReactComponent as PreviousPageIcon } from "../../assets/icons/PreviousPageIcon.svg";
import { ReactComponent as NextPageIcon } from "../../assets/icons/NextPageIcon.svg";
import { ReactComponent as FirstPageIcon } from "../../assets/icons/FirstPageIcon.svg";
import { ReactComponent as LastPageIcon } from "../../assets/icons/LastPageIcon.svg";
import { usePagination } from "@material-ui/lab/Pagination";
import { IconButton } from "@material-ui/core";
import Typography from "../../components/Typography/Typography.js";
import Tooltip from "@material-ui/core/Tooltip";

const Pagination = React.forwardRef(
    (
        {
            source = null,
            count,
            className,
            onChangePage,
            page,
            scrollTopPosition,
            ...props
        },
        ref,
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const handleChangePage = (event, newPage) => {
            onChangePage(newPage);
            source === "industries" &&
                sessionStorage.setItem("industriesPageNumber", newPage);
            source === "technologies" &&
                sessionStorage.setItem("technologiesPageNumber", newPage);
            window.scrollTo({
                top: scrollTopPosition || 0,
                behavior: "smooth",
            });
        };
        const { items } = usePagination({
            count,
            onChange: handleChangePage,
            page,
            pageProp: page,
            defaultPage: page,
        });

        const [inputValue, setInputValue] = useState();

        const handleInputChange = e => {
            let value;
            if (e.target.value === "") {
                value = null;
            } else if (e.target.value > count) {
                value = count;
            } else if (e.target.value < 1) {
                value = 1;
            } else {
                value = e.target.value;
            }
            setInputValue(value);
        };

        const handleKeyDown = e => {
            if (e.key === "Enter") {
                handleChangePage(e, Number(inputValue));
            }
        };

        const handleNext10Pages = e => {
            handleChangePage(e, Number(page + 10 > count ? count : page + 10));
        };

        const handleLast10Pages = e => {
            handleChangePage(e, Number(page - 10 < 1 ? 1 : page - 10));
        };

        return (
            <div className={classes.pagination}>
                {page === 1 ? (
                    <IconButton
                        onClick={handleLast10Pages}
                        className={classes.icon}
                        disabled={page === 1}>
                        <FirstPageIcon />
                    </IconButton>
                ) : (
                    <Tooltip
                        title={
                            <Typography
                                color="white"
                                variant="body2"
                                component="div">
                                {t("results.back10Pages")}
                            </Typography>
                        }
                        arrow>
                        <IconButton
                            onClick={handleLast10Pages}
                            className={classes.icon}
                            disabled={page === 1}>
                            <FirstPageIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {items &&
                    items.map(({ page, type, selected, ...item }, index) => {
                        let children = null;

                        if (
                            type === "start-ellipsis" ||
                            type === "end-ellipsis"
                        ) {
                            children = "…";
                        } else if (type === "page") {
                            children = (
                                <IconButton
                                    {...item}
                                    className={classes.pageNumber}>
                                    {page}
                                </IconButton>
                            );
                        } else {
                            children = (
                                <IconButton {...item} className={classes.icon}>
                                    {type === "previous" ? (
                                        <PreviousPageIcon />
                                    ) : (
                                        <NextPageIcon />
                                    )}
                                </IconButton>
                            );
                        }
                        return (
                            <div className={classes.paginationItem} key={index}>
                                {children}
                            </div>
                        );
                    })}
                {page === count ? (
                    <IconButton
                        className={classes.forwardIcon}
                        onClick={handleNext10Pages}
                        disabled={page === count}>
                        <LastPageIcon />
                    </IconButton>
                ) : (
                    <Tooltip
                        title={
                            <Typography
                                color="white"
                                variant="body2"
                                component="div">
                                {t("results.forward10Page")}
                            </Typography>
                        }
                        arrow>
                        <IconButton
                            className={classes.forwardIcon}
                            onClick={handleNext10Pages}
                            disabled={page === count}>
                            <LastPageIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Typography
                    variant="bodySmall"
                    color="textLighter"
                    className={classes.goToPageLabel}>
                    {t("results.goToPage")}
                </Typography>
                <input
                    type="number"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className={classes.input}
                />
            </div>
        );
    },
);

export default Pagination;
