import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const submitSearchRequest = createAction(
    `${REDUCER_PREFIX}/submitSearchRequest`,
);
export const checkSearchCountRequest = createAction(
    `${REDUCER_PREFIX}/checkSearchCountRequest`,
);
export const resetSearchCountRequest = createAction(
    `${REDUCER_PREFIX}/resetSearchCountRequest`,
);
export const postSetSearchCountRequest = createAction(
    `${REDUCER_PREFIX}/postSetSearchCountRequest`,
);
export const deleteSavedSearchesRequest = createAction(
    `${REDUCER_PREFIX}/deleteSavedSearchesRequest`,
);
export const getMlSummaryRequest = createAction(
    `${REDUCER_PREFIX}/getMlSummaryRequest`,
);
export const cancelGetSummaryRequest = createAction(
    `${REDUCER_PREFIX}/cancelGetSummaryRequest`,
);
