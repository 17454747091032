import { makeStyles, createStyles } from "@material-ui/core/styles";

function animationExpand() {
    return {
        "0%": {
            // Fix IE 11 wobbly
            maxHeight: 0,
        },
        "100%": {
            maxHeight: 1200,
        },
    };
}

function animationCollapse() {
    return {
        "0%": {
            // Fix IE 11 wobbly
            maxHeight: 1200,
        },
        "100%": {
            maxHeight: 0,
        },
    };
}

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        greenBoxRoot: {
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            width: ({ user }) =>
                user?.csp ? "calc(100% - 32px)" : "calc(100% - 64px)",
            background: palette.success.backgroundLight,
            borderRadius: spacing(3),
            // border: `1px solid ${palette.success.backgroundDark}`,
            padding: "20px",
            position: "relative",
            margin: `${spacing(0)}px ${spacing(0)}px ${spacing(4)}px 28px`,
            cursor: ({ boxExpanded }) => (!boxExpanded ? "pointer" : "default"),
            [breakpoint.down("lg")]: {
                padding: spacing(2),
                margin: `${spacing(0)}px ${spacing(2)}px ${spacing(
                    2,
                )}px ${spacing(2)}px`,
                width: ({ user }) =>
                    user?.csp ? "calc(100% - 24px)" : "calc(100% - 32px)",
            },
            [breakpoint.down("sm")]: {
                padding: spacing(2),
                margin: `${spacing(0)}px ${spacing(2)}px ${spacing(
                    2,
                )}px ${spacing(2)}px`,
            },
        },
        greenBoxHeader: {
            display: "flex",
            flexDirection: "column",
        },
        greenBoxContent: {
            overflow: "hidden",
            maxHeight: 0,
        },
        descriptionPreview: {
            animation: `$animationCollapse 1s ease-out`,
            minWidth: "200px",
            textAlign: "left",
            fontSize: "14px",
            fontWeight: 300,
        },
        expandableTextWrapper: {
            flex: "1 1 0",
            cursor: "pointer",
        },
        collapsed: {
            maxHeight: 0,
            overflow: "visible",
            animation: `$animationCollapse 1s ease-out`,
            // transition: "height 600ms ease-out"
        },
        expanded: {
            maxHeight: 1200,
            overflow: "visible",
            animation: `$animationExpand 1s ease-out`,
            // transition: "height 600ms ease-out"
        },
        "@keyframes animationCollapse": {
            ...animationCollapse(),
        },
        "@keyframes animationExpand": {
            ...animationExpand(),
        },
        iconsBox: {
            cursor: "pointer",
            display: "flex",
            flexDirection: "flex",
            marginLeft: "auto",
            width: "fit-content",
            "@media only screen and (max-width: 1050px)": {
                alignItems: "start",
                marginLeft: 0,
                marginTop: 0,
                width: "100%",
                div: {
                    marginLeft: 0,
                },
            },
        },
        editSectionOpen: {
            backgroundColor: palette.primary.dark + " !important",
            "& $editBtnText": {
                color: "white",
            },
            "& $iconImgBase": {
                display: "none",
            },
            "& $iconImgHover": {
                display: "flex",
                marginRight: spacing(1),
            },
        },
        iconImgBase: {
            display: "flex",
            marginRight: spacing(1),
        },
        iconImgBaseSaved: {
            marginRight: spacing(1),
        },
        iconImgHover: {
            display: "none",
            backgroundColor: palette.primary.dark,
            color: "white",
        },
        iconImgActive: {
            display: "flex",
        },
        iconDisabled: {
            display: "inline-flex",
            padding: 0,
            marginLeft: spacing(2),
            alignItems: "center",
            borderRadius: 0,
            verticalAlign: "middle",
            justifyContent: "center",
            cursor: "default",
        },
        iconButton: {
            padding: "11px 8px",
            marginLeft: spacing(2),
            [breakpoint.down("xl")]: {
                marginLeft: 0,
                marginRight: spacing(1),
                marginTop: 0,
            },
            // marginBottom: spacing(1),
            display: "inline-flex",
            alignItems: "center",
            // background: palette.common.white,
            borderRadius: spacing(3),
            border: "1px solid " + palette.text.light,
            paddingRight: 12,
            height: "40px",
            width: "91px",
            [breakpoint.down("sm")]: {
                display: "none",
            },
            "&:hover": {
                "& $iconImgBase": {
                    display: "none",
                },
                "& $iconImgHover": {
                    display: "flex",
                    marginRight: spacing(1),
                },
                "& $iconImgActive": {
                    display: "none",
                },
            },
        },
        iconSavedButton: {
            padding: "8px 12px",
            height: "40px",
            background: palette.common.white,
            borderRadius: spacing(3),
            border: "1px solid " + palette.text.light,
            display: "inline-flex",
            alignItems: "center",
            marginLeft: spacing(2),
            [breakpoint.down("sm")]: {
                display: "none",
            },
        },
        editButton: {
            "&:hover": {
                backgroundColor: palette.primary.dark,
                borderColor: palette.primary.dark,
                "& $editBtnText": {
                    color: "white",
                },
            },
        },
        editBtnText: {
            "&:hover": {
                color: "white",
            },
        },
        saveButton: {
            "&:hover": {
                backgroundColor: palette.primary.dark,
                borderColor: palette.primary.dark,
                "& $editBtnText": {
                    color: "white",
                },
                "& span": {
                    color: "white",
                },
            },
        },
        mainBox: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            rowGap: 4,
            flexDirection: "row",
            "@media only screen and (max-width: 1100px)": {
                flexWrap: "wrap",
                rowGap: 20,
                // flexDirection: "column",
                // alignItems: "start"
            },
            cursor: "pointer",
            maxWidth: "100%",
            marginBottom: 20,
        },
        mainBoxTitleIcon: {
            display: "flex",
            width: "fit-content",
        },
        textBox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        image: {
            width: "25px",
            height: "40px",
            marginRight: "8px",
            [breakpoint.down("sm")]: {
                display: "none",
            },
        },
        moreButton: {
            display: "none",
            [breakpoint.down("sm")]: {
                display: "block",
            },
        },
        moreIcon: {
            fill: palette.secondary.dark,
        },
        moreIconOpened: {
            fill: palette.primary.dark,
        },
        searchQueryOverviewTitle: {
            width: "fit-content",
            whiteSpace: "nowrap",
            marginRight: spacing(1),
            display: "-webkit-box",
            "-webkit-line-clamp": 3,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            [breakpoint.down("sm")]: {
                marginBottom: spacing(2),
            },
        },
        noClick: {
            pointerEvents: "none",
        },
    }),
);
