import { createSlice } from "@reduxjs/toolkit";
import findIndex from "lodash/findIndex";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_CANCELLED,
    ML_REQUEST_FAILURE,
    ML_REQUEST_TIMEOUT,
    ML_REQUEST_COMPANY_NOT_FOUND,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    step: 1,
    product: null,
    industryDesc: "",
    sectors: [],
    subsectors: [],
    searchQuery: {
        sectors: [],
        subsectors: [],
    },
    location: {
        regions: [],
        countries: [],
    },
    companyName: "",
    companyWebsite: "",
    companyDescription: "",
    postSearchStatus: REQUEST_UNDEFINED,
    postSearchResponse: {},
    checkSearchCountStatus: REQUEST_UNDEFINED,
    resetSearchCountStatus: REQUEST_UNDEFINED,
    setSearchCountStatus: REQUEST_UNDEFINED,
    removeSavedSearchesStatus: REQUEST_UNDEFINED,
    checkSearchCountState: {},
    editSearchDirty: false,
    summaryShort: null,
    summaryLong: null,
    getMlSummaryStatus: REQUEST_UNDEFINED,
};

export const REDUCER_PREFIX = "search";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        postSearchUndefined: state => {
            state.postSearchStatus = REQUEST_UNDEFINED;
        },
        postSearchPending: state => {
            state.postSearchStatus = REQUEST_PENDING;
        },
        postSearchSuccess: state => {
            state.postSearchStatus = REQUEST_SUCCESS;
        },
        postSearchFailure: state => {
            state.postSearchStatus = REQUEST_FAILURE;
        },
        removeSavedSearchesPending: state => {
            state.removeSavedSearchesStatus = REQUEST_PENDING;
        },
        removeSavedSearchesSuccess: state => {
            state.removeSavedSearchesStatus = REQUEST_SUCCESS;
        },
        removeSavedSearchesUndefined: state => {
            state.removeSavedSearchesStatus = REQUEST_UNDEFINED;
        },
        removeSavedSearchesFailure: state => {
            state.removeSavedSearchesStatus = REQUEST_FAILURE;
        },
        postSearchCancel: state => {
            state.postSearchStatus = REQUEST_CANCELLED;
        },
        postMLSearchFailure: state => {
            state.postSearchStatus = ML_REQUEST_FAILURE;
        },
        postMLSearchTimeout: state => {
            state.postSearchStatus = ML_REQUEST_TIMEOUT;
        },
        postMLSearchCompanyNotFound: state => {
            state.postSearchStatus = ML_REQUEST_COMPANY_NOT_FOUND;
        },
        postMLSearchUndefined: state => {
            state.postSearchStatus = REQUEST_UNDEFINED;
        },
        setProduct: (state, { payload }) => {
            state.product = payload;
        },
        setIndustryDesc: (state, { payload }) => {
            state.industryDesc = payload;
        },
        clearSearch: state => {
            state.step = 1;
            state.product = null;
            state.industryDesc = "";
            state.sectors = [];
            // TODO@lex: Why was this implemented? I removed it for now since its creating some issues (i.e. after a new search if we try to edit and update the search the no results component appears - BUGS-701). clearSearch function should not reset request statuses anyway, so can we just remove this, and if its creating some bug, i think we should handle it differently...
            // state.postSearchStatus = REQUEST_UNDEFINED;
            state.searchQuery = {
                sectors: [],
                subsectors: [],
            };
            state.location = {
                regions: [],
                countries: [],
            };
            state.setSearchCountStatus = null;
        },
        clearLookalikeSearch: state => {
            state.companyName = "";
            state.companyWebsite = "";
            state.companyDescription = "";
        },
        addSector: (state, { payload }) => {
            const sectors = state.sectors;
            const initialSector = {
                name: payload.name,
                slug: payload.slug,
            };
            for (var i = 0; i < sectors.length; i++) {
                if (sectors[i].name === payload.name) {
                    return;
                }
            }
            sectors.push(initialSector);
            state.sectors = sectors;
            const searchQuerySectors = state.searchQuery.sectors;
            searchQuerySectors.push(payload.name);
            state.searchQuery.sectors = searchQuerySectors;
        },
        addSectors: (state, { payload }) => {
            state.sectors = payload;
            const searchQuerySectors = state.searchQuery.sectors;
            const searchQuerySubsectors = state.searchQuery.subsectors;
            payload.forEach(sector => {
                searchQuerySectors.push(sector.name);
                sector.subsectors &&
                    sector.subsectors.forEach(subsector => {
                        searchQuerySubsectors.push(subsector.name);
                    });
            });
            state.searchQuery.sectors = searchQuerySectors;
            state.searchQuery.subsectors = searchQuerySubsectors;
        },
        removeSearchSectors: state => {
            state.searchQuery = {
                sectors: [],
                subsectors: [],
            };
        },
        removeSector: (state, { payload }) => {
            const sectors = state.sectors;
            const removeSectorIndex = findIndex(
                sectors,
                sector => sector.slug === payload.slug,
            );
            if (removeSectorIndex !== -1) {
                sectors.splice(removeSectorIndex, 1);
            }
            state.sectors = sectors;
            const searchQuerySectors = state.searchQuery.sectors;
            const searchQuerySectorIndex = findIndex(
                searchQuerySectors,
                selectedSearchQuerySector =>
                    selectedSearchQuerySector === payload.name,
            );
            searchQuerySectors.splice(searchQuerySectorIndex, 1);
        },
        updateLocation: (state, { payload }) => {
            state.location = payload;
        },
        clearLocation: state => {
            state.location = {
                regions: [],
                countries: [],
            };
        },
        updateRegions: (state, { payload }) => {
            state.location = {
                regions: payload,
                countries: state.location.countries,
            };
        },
        setEditQueryDirty: state => {
            state.editSearchDirty = true;
        },
        setEditQueryClean: state => {
            state.editSearchDirty = false;
        },
        clearRegions: state => {
            state.location = {
                regions: [],
                countries: state.location.countries,
            };
        },
        updateCountries: (state, { payload }) => {
            state.location = {
                regions: state.location.regions,
                countries: payload,
            };
        },
        clearCountries: state => {
            state.location = {
                regions: state.location.regions,
                countries: [],
            };
        },
        removeAllSectors: state => {
            state.sectors = [];
        },
        setSelectedSector: (state, { payload }) => {},
        setSubsectors: (state, { payload }) => {
            const { selectedSector } = payload;
            const { slug, subsectors } = selectedSector;
            const sectorsState = state.sectors;

            const sectorIndex = findIndex(
                sectorsState,
                sector => sector.slug === slug,
            );
            let selectedSearchSector;
            if (sectorIndex !== -1) {
                selectedSearchSector = sectorsState[sectorIndex];
                selectedSearchSector = {
                    ...selectedSector,
                    subsectors,
                };
            }

            state.sectors[sectorIndex] = selectedSearchSector;
            const searchQuerySubsectors = state.searchQuery.subsectors;
            subsectors &&
                subsectors.forEach(subsector => {
                    !searchQuerySubsectors.some(
                        existing => existing === subsector.name,
                    ) && searchQuerySubsectors.push(subsector.name);
                });
            state.searchQuery.subsectors = searchQuerySubsectors;
        },
        addSubsector: (state, { payload }) => {
            const { sectorOpened, subsector } = payload;
            const sectorsState = state.sectors;
            const sectorIndex = findIndex(
                sectorsState,
                sector => sector.slug === sectorOpened.slug,
            );
            if (sectorIndex !== -1) {
                const selectedSector = sectorsState[sectorIndex];
                if (selectedSector["subsectors"]) {
                    if (
                        !selectedSector.subsectors.some(
                            existing => existing.slug === subsector.slug,
                        )
                    ) {
                        selectedSector.subsectors.push(subsector);
                    }
                } else {
                    selectedSector["subsectors"] = [];
                    selectedSector["subsectors"].push(subsector);
                }
            }
            const searchQuerySubsectors = state.searchQuery.subsectors;
            searchQuerySubsectors.push(subsector.name);
            state.searchQuery.subsectors = searchQuerySubsectors;
        },
        removeSubsector: (state, { payload }) => {
            const { sectorSlug, subsector } = payload;
            const sectorsState = state.sectors;
            const sectorIndex = findIndex(
                sectorsState,
                selectedSector => selectedSector.slug === sectorSlug,
            );
            if (sectorIndex !== -1) {
                const selectedSector = sectorsState[sectorIndex];
                const subsectorIndex = findIndex(
                    selectedSector && selectedSector.subsectors,
                    selectedSubsector =>
                        selectedSubsector.slug === subsector.slug,
                );
                if (subsectorIndex !== -1) {
                    selectedSector.subsectors.splice(subsectorIndex, 1);
                }
                state.sectors[sectorIndex] = selectedSector;
                const searchQuerySubsectors = state.searchQuery.subsectors;
                const searchQuerySubsectorIndex = findIndex(
                    searchQuerySubsectors,
                    selectedSearchQuerySubsector =>
                        selectedSearchQuerySubsector === subsector.name,
                );
                searchQuerySubsectors.splice(searchQuerySubsectorIndex, 1);
            }
        },
        setPostSearchResponse: (state, { payload }) => {
            state.postSearchResponse = payload;
        },
        resetPostSearchResponse: state => {
            state.postSearchStatus = REQUEST_UNDEFINED;
        },
        checkSearchCountPending: state => {
            state.checkSearchCountStatus = REQUEST_PENDING;
        },
        checkSearchCountSuccess: state => {
            state.checkSearchCountStatus = REQUEST_SUCCESS;
        },
        checkSearchCountFailure: state => {
            state.checkSearchCountStatus = REQUEST_FAILURE;
        },
        setSearchCountState: (state, { payload }) => {
            state.checkSearchCountState = payload;
        },
        resetSearchCountPending: state => {
            state.resetSearchCountStatus = REQUEST_PENDING;
        },
        resetSearchCountSuccess: state => {
            state.resetSearchCountStatus = REQUEST_SUCCESS;
        },
        resetSearchCountFailure: state => {
            state.resetSearchCountStatus = REQUEST_FAILURE;
        },
        setSearchCountPending: state => {
            state.setSearchCountStatus = REQUEST_PENDING;
        },
        setSearchCountSuccess: state => {
            state.setSearchCountStatus = REQUEST_SUCCESS;
        },
        setSearchCountFailure: state => {
            state.setSearchCountStatus = REQUEST_FAILURE;
        },
        setSearchCountUndefined: state => {
            state.setSearchCountStatus = REQUEST_UNDEFINED;
        },
        setSummaryState: (state, { payload }) => {
            //REQUEST_UNDEFINED is set if user has changed the page
            if (state.getMlSummaryStatus !== REQUEST_UNDEFINED) {
                state.summaryShort = payload.data.summary_short;
                state.summaryLong = payload.data.summary_long;
            }
        },
        setGetSummaryPending: state => {
            state.getMlSummaryStatus = REQUEST_PENDING;
        },
        setGetSummarySuccess: state => {
            //REQUEST_UNDEFINED is set if user has changed the page
            if (state.getMlSummaryStatus !== REQUEST_UNDEFINED) {
                state.getMlSummaryStatus = REQUEST_SUCCESS;
            }
        },
        setGetSummaryFailure: state => {
            state.getMlSummaryStatus = REQUEST_FAILURE;
        },
        setGetSummaryUndefined: state => {
            state.getMlSummaryStatus = REQUEST_UNDEFINED;
        },
        emptyFetchedMlSummary: state => {
            state.summaryShort = null;
            state.summaryLong = null;
        },
        setCompany: (state, { payload }) => {
            state.companyName = payload.companyName;
            state.companyWebsite = payload.companyWebsite;
            state.companyDescription = payload.companyDescription;
        },
    },
});
export { reducer, actions };
