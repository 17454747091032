import React, { useEffect, useState } from "react";

// UI components
import Typography from "../../../../components/Typography/Typography.js";
import Button from "../../../../components/Button/Button";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import OrderReportImg from "../../../../assets/images/NoOrders.png";
import { enrichTechnologyRequest } from "../../../../store/technologies/requests.js";
import { enrichIndustryRequest } from "../../../../store/industries/requests.js";
import { enrichTechnologyStatusSelector } from "../../../../store/technologies/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
    REQUEST_PENDING,
    REQUEST_SUCCESS,
} from "../../../../constants/statuses.js";
import { useIntercom } from "react-use-intercom";

const OrderReportModal = ({
    dialogOpened,
    onChange,
    user,
    onNotEnoughCredits,
    technology,
    enrichSuccess,
    type,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { show } = useIntercom();

    const handleClose = () => onChange();
    const [buttonLoading, setButtonLoading] = useState(false);
    const enrichTechnologyStatus = useSelector(enrichTechnologyStatusSelector);
    const enrichCompany = () => {
        dispatch(
            type === "technology"
                ? enrichTechnologyRequest({ slug: technology.slug })
                : enrichIndustryRequest({ slug: technology.slug }),
        );
    };
    const handleOrder = () => {
        user && user.subscription && user.credits < 1
            ? onNotEnoughCredits()
            : enrichCompany();
    };
    const openIntercom = e => {
        e.preventDefault();

        if (process.env.REACT_APP_ENV === "prod") {
            show();
        }
    };

    useEffect(() => {
        setButtonLoading(enrichTechnologyStatus === REQUEST_PENDING);
        enrichTechnologyStatus === REQUEST_SUCCESS && enrichSuccess();
    }, [enrichTechnologyStatus]);

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    onChange(event, reason);
                }
            }}
            open={dialogOpened}>
            <IconButton className={classes.closeButton} onClick={onChange}>
                <img
                    src={CloseIcon}
                    alt={t("scope.step2")}
                    className={classes.stepIcon}
                />
            </IconButton>
            <img
                src={OrderReportImg}
                alt={t(`${type}Overview.orderTechnologyReport`)}
            />
            <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant="h6">
                    {t(`${type}Overview.orderTechnologyReport`)}
                </Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <Typography variant="subtitle2" component="div">
                    {t(`${type}Overview.${type}OrderCost`)}
                </Typography>
                <Typography variant="subtitle2" component="div">
                    {t(`${type}Overview.youHaveCreditsLeft`, {
                        creditsLeft: user && user.credits,
                    })}
                </Typography>
                <Typography variant="subtitle2" component="div">
                    {t(`${type}Overview.ourAnalystPrepare`)}
                </Typography>
            </MuiDialogContent>
            <div className={classes.buttons}>
                <Button
                    loading={buttonLoading}
                    className={classes.orderButton}
                    variant="primary"
                    onClick={() => handleOrder()}>
                    {t("technologyOverview.orderReport")}
                </Button>
                <Button
                    className={classes.cancelButton}
                    variant="tertiary"
                    onClick={() => handleClose()}>
                    {t("technologyOverview.cancel")}
                </Button>
            </div>
            <div className={classes.footer}>
                <Typography
                    component="div"
                    variant="bodySmall"
                    color="textSecondary"
                    align="center">
                    {t("companyProfile.enrichRequestModalFooter.text")}
                    <a onClick={openIntercom}>
                        {t("companyProfile.enrichRequestModalFooter.link")}
                    </a>
                </Typography>
            </div>
        </Dialog>
    );
};
export default OrderReportModal;
