import React from "react";
import { ToggleSwitchStyle } from "./style";

const SwitchToggle = ({ label, onChange, checked }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "end",
            }}>
            <label style={{ color: "#999999" }}>{label}</label>
            <ToggleSwitchStyle htmlFor="checkbox" checked={checked}>
                <input
                    id="checkbox"
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
            </ToggleSwitchStyle>
        </div>
    );
};

export default SwitchToggle;
