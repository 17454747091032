import axiosInstance from "../axiosInstance";
import {getCompanyFromSearchByMlIdUrl, getCompanyByIdUrl, RESULTS_URL} from "../../constants/endpoints";

class CompanyService {
    getCompanyById = (id) => {
        return axiosInstance
            .get(getCompanyByIdUrl(id))
            .then(res => res.data.data);
    };
    getCompanyByMlId = (sid, mlId) => {
        return axiosInstance
            .get(getCompanyFromSearchByMlIdUrl(sid, mlId))
            .then(res => res.data.data);
    };
	getSearchResults = (searchId, page, filters = null) => {
		let url = `${RESULTS_URL}sid=${searchId}&page=${page}`;
		if(filters) {
			url = url + filters;
		}
		return axiosInstance
			.get(`${url}`)
			.then(res => res.data.data);
	};
}

export default CompanyService;
