import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    buyCreditsStatus: REQUEST_UNDEFINED,
    getCreditsSummaryStatus: REQUEST_UNDEFINED,
    getPlanStatus: REQUEST_UNDEFINED,
    plan: {},
    data: null,
    summary: [],
};
export const REDUCER_PREFIX = "credits";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        buyCreditsStatusPending: state => {
            state.buyCreditsStatus = REQUEST_PENDING;
        },
        buyCreditsStatusSuccess: state => {
            state.buyCreditsStatus = REQUEST_SUCCESS;
        },
        buyCreditsStatusFailure: state => {
            state.buyCreditsStatus = REQUEST_FAILURE;
        },
        getPlanStatusPending: state => {
            state.getPlanStatus = REQUEST_PENDING;
        },
        getPlanStatusSuccess: state => {
            state.getPlanStatus = REQUEST_SUCCESS;
        },
        getPlanStatusFailure: state => {
            state.getPlanStatus = REQUEST_FAILURE;
        },
        getCreditsSummaryStatusPending: state => {
            state.getCreditsSummaryStatus = REQUEST_PENDING;
        },
        getCreditsSummaryStatusSuccess: state => {
            state.getCreditsSummaryStatus = REQUEST_SUCCESS;
        },
        getCreditsSummaryStatusFailure: state => {
            state.getCreditsSummaryStatus = REQUEST_FAILURE;
        },
        setBuyCreditsResponse: (state, { payload }) => {
            state.data = payload;
            state.buyCreditsStatus = REQUEST_UNDEFINED;
        },
        setCreditsSummary: (state, { payload }) => {
            state.summary = payload;
        },
        setPlan: (state, { payload }) => {
            state.plan = payload;
        },
    },
});
export { reducer, actions };
