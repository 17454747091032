import { createUseStyles } from "react-jss";
import colors from "../../constants/colors";

const useStyles = createUseStyles({
    root: {},
    h1: {
        fontSize: 71,
        lineHeight: "80px",
    },
    h2: {
        fontSize: 64,
        lineHeight: "72px",
    },
    h3: {
        fontSize: 56,
        lineHeight: "64px",
        // sm - down
        "@media (max-width:575px)": {
            // theme.h6
            fontSize: 32,
            lineHeight: "40px",
        },
    },
    h4: {
        fontSize: 48,
        lineHeight: "56px",
        // md - down
        "@media (max-width:768px)": {
            // theme.h6
            fontSize: 32,
            lineHeight: "40px",
        },
    },
    h5: {
        fontSize: 40,
        lineHeight: "48px",
        // md - down
        "@media (max-width:768px)": {
            // theme.subtitle1
            fontSize: 24,
            lineHeight: "32px",
        },
    },
    h6: {
        fontSize: 32,
        lineHeight: "40px",
        // md - down
        "@media (max-width:768px)": {
            // theme.subtitle1
            fontSize: 24,
            lineHeight: "32px",
        },
    },
    subtitle1: {
        fontSize: 24,
        lineHeight: "32px",
        // md - down
        "@media (max-width:768px)": {
            // theme.paragraph
            fontSize: 20,
            lineHeight: "28px",
        },
    },
    subtitleResult: {
        fontSize: 20,
        lineHeight: "26px",
        // md - down
        "@media (max-width:768px)": {
            // theme.paragraph
            fontSize: 16,
            lineHeight: "24px",
        },
    },
    bodyXl: {
        fontSize: 18,
        lineHeight: "26px",
    },
    body: {
        fontSize: 16,
        lineHeight: "24px",
    },
    bodySmall: {
        fontSize: 14,
        lineHeight: "22px",
        // md - down
        "@media (max-width:768px)": {
            // theme.bodyXs
            fontSize: 12,
            lineHeight: "20px",
        },
    },
    bodyButton: {
        fontSize: 14,
        lineHeight: "18px",
    },
    bodyXs: {
        fontSize: 12,
        lineHeight: "20px",
    },
    label: {
        fontSize: 10,
        lineHeight: "10px",
        // md - down
        "@media (max-width:768px)": {
            // theme.bodyXs
            fontSize: 12,
            lineHeight: "20px",
        },
    },
    paragraph: {
        fontSize: 20,
        lineHeight: "28px",
    },
    alignLeft: {
        textAlign: "left",
    },
    alignCenter: {
        textAlign: "center",
    },
    alignRight: {
        textAlign: "right",
    },
    alignJustify: {
        textAlign: "justify",
    },
    noWrap: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    gutterBottom: {
        marginBottom: "0.35em",
    },
    colorInherit: {
        color: "inherit",
    },
    colorText: {
        color: colors.text.main,
    },
    colorTextLight: {
        color: colors.text.light,
    },
    colorTitle: {
        color: colors.common.black,
    },
    colorPrimary: {
        color: colors.primary.main,
    },
    colorPrimaryDark: {
        color: colors.primary.dark,
    },
    colorBlack: {
        color: colors.common.black,
    },
    colorLight: {
        color: colors.text.light,
    },
    colorWhite: {
        color: colors.common.white,
    },
    colorSecondary: {
        color: colors.secondary.main,
    },
    colorTextPrimary: {
        color: colors.text.primary,
    },
    colorTextSecondary: {
        color: colors.text.secondary,
    },
    colorDisabled: {
        color: colors.text.disabled,
    },
    colorInfoDark: {
        color: colors.info.dark,
    },
    colorDanger: {
        color: colors.error.main,
    },
    colorSuccess: {
        color: colors.success.main,
    },
    colorWarning: {
        color: colors.warning.main,
    },
    colorSuccessDark: {
        color: colors.success.dark,
    },
    colorTextLighter: {
        color: colors.text.lighter,
    },
    colorSecondaryDark: {
        color: colors.secondary.dark,
    },
    colorHighlight: {
        color: colors.text.highlight,
    },
    colorGrey: {
        color: colors.grey.main,
    },
    colorWarningDark: {
        color: colors.warning.dark,
    },
    textDecorationUnderline: {
        textDecoration: "underline",
    },
    textDecorationLineThrough: {
        textDecoration: "line-through",
    },
    weightLight: {
        fontWeight: "300",
    },
    weightRegular: {
        fontWeight: "normal",
    },
    weightMedium: {
        fontWeight: "500",
    },
    fontStyleItalic: {
        fontStyle: "italic",
    },
    inline: {
        display: "inline",
    },
    cursor: {
        cursor: "pointer",
    },
});

export default useStyles;
