import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import { postTrackUser } from "./helpers";

function* mixpanelTrack(eventName, data = null) {
    try {
        yield put(actions.postMixpanelPending());
        yield call(postTrackUser, eventName, data);
        yield put(actions.postMixpanelSuccess());
    } catch (err) {
        yield put(actions.postMixpanelFailure());
    }
}

export { mixpanelTrack };
