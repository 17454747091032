import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        main: {
            background: palette.common.white,
            margin: `0 auto`,
            padding: spacing(4),
            maxWidth: "1136px",
            display: "flex",
            flexDirection: "column",
            [breakpoint.down("md")]: {
                padding: `${spacing(3)}px 0`,
            },
        },
    }),
);
