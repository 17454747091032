import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import {
    getResults,
    getSearches,
    postSearches,
    getCompany,
    generateLink,
    postVoteMlResult,
    getGenerateShareLink,
    getCompaniesChart,
    getTeamSizeChart,
    getLikedCompanies,
} from "../../results/saga/helpers";

function* fetchResults(data, isSharedPageToken, searchTypeLook, filterStats) {
    const filterNameValue = data && data.filterNameValue;
    const orderBy = data && data.orderBy;
    const orderDirection = data && data.orderDirection;
    const page = data && data.page;
    const sid = data && data.sid;
    const perPage = data && data.perPage;
    try {
        if (page < 2) {
            yield put(actions.clearResults());
        }
        yield put(actions.getResultsPending());
        const filteredResults = yield call(
            getResults,
            filterNameValue,
            orderBy,
            orderDirection,
            page,
            sid,
            isSharedPageToken,
            perPage,
            searchTypeLook,
            filterStats,
        );
        const sliceData = { data: filteredResults.data, page: page };
        yield put(actions.setResults(sliceData));
        yield put(actions.getResultsSuccess());
    } catch (err) {
        yield put(actions.getResultsFailure());
    }
}

function* fetchResultsCount(
    data,
    isSharedPageToken,
    searchTypeLook,
    lastFilteredField,
    lastFilteredFieldValue,
    filterStats,
) {
    const filterNameValue = data && data.filterNameValue;
    const orderBy = data && data.orderBy;
    const orderDirection = data && data.orderDirection;
    const page = data && data.page;
    const sid = data && data.sid;
    const perPage = data && data.perPage;
    try {
        yield put(actions.getResultsCountPending());
        const filteredResults = yield call(
            getResults,
            filterNameValue,
            orderBy,
            orderDirection,
            page,
            sid,
            isSharedPageToken,
            perPage,
            searchTypeLook,
            filterStats,
        );
        const sliceData = { data: filteredResults.data, page: page };
        yield put(
            actions.setResultsCount({
                sliceData,
                lastFilteredField,
                lastFilteredFieldValue,
            }),
        );
        yield put(actions.setLastFilteredField(lastFilteredField));
        yield put(actions.getResultsCountSuccess());
    } catch (err) {
        yield put(actions.getResultsCountFailure());
    }
}

function* fetchCompaniesChart(id, type, grouped, filterNameValue) {
    try {
        yield put(actions.getCompaniesChartPending());
        const response = yield call(
            getCompaniesChart,
            id,
            type,
            grouped,
            filterNameValue,
        );
        yield put(actions.getCompaniesChartSuccess());
        yield put(actions.setCompaniesChart(response.data));
    } catch (err) {
        yield put(actions.getCompaniesChartFailure());
    }
}

function* fetchTeamSizeChart(id, type, filterNameValue) {
    try {
        yield put(actions.getTeamSizeChartPending());
        const response = yield call(
            getTeamSizeChart,
            id,
            type,
            filterNameValue,
        );
        yield put(actions.getTeamSizeChartSuccess());
        yield put(actions.setTeamSizeChart(response));
    } catch (err) {
        yield put(actions.getTeamSizeChartFailure());
    }
}

function* fetchSearches(data, isEditSearch) {
    try {
        const { isSortSearch } = data;
        if (isSortSearch) yield put(actions.clearOnlySearches());
        if (isEditSearch) {
            yield put(actions.getSearchesEditSearchStatusPending());
        } else {
            if (data.sid || data.isRecentSearch) {
                yield put(actions.clearOnlySearches());
                yield put(actions.getRecentSearchesStatusPending());
            }
            yield put(actions.getSearchesStatusPending());
        }

        const searches = yield call(getSearches, data);
        const page = data && data.page;
        const sliceData = { data: searches.data, page: page };
        //TODO: Split searches and recent searches in two different state
        yield put(actions.setSearches(sliceData));
        if (isEditSearch) {
            yield put(actions.getSearchesEditSearchStatusSuccess());
        } else {
            if (data.sid || data.isRecentSearch) {
                yield put(actions.getRecentSearchesStatusSuccess());
            }
            yield put(actions.getSearchesStatusSuccess());
        }
    } catch (err) {
        if (isEditSearch) {
            yield put(actions.getSearchesEditSearchStatusFailure());
        } else {
            if (data.sid || data.isRecentSearch) {
                yield put(actions.getRecentSearchesStatusFailure());
            }
            yield put(actions.getSearchesStatusFailure());
        }
    }
}

function* submitSearches(data) {
    try {
        yield put(actions.postSearchesStatusPending());
        const response = yield call(postSearches, data);
        yield put(
            actions.setSearchName({
                sid: response.message.id,
                search_name: response.message.saved_search_name,
            }),
        );
        yield put(actions.postSearchesStatusSuccess());
        yield put(actions.postSearchesStatusUndefined());
    } catch (err) {
        const errorMsg =
            err.response && err.response.data && err.response.data.data
                ? err.response.data.data.search_name
                : "";
        yield put(actions.postSearchesStatusFailure(errorMsg));
        yield put(actions.postSearchesStatusUndefined());
    }
}

function* fetchCompany(data) {
    try {
        yield put(actions.getCompanyPending());
        yield put(actions.clearResults());
        const company = yield call(getCompany, data);
        yield put(actions.getCompanySuccess());
        yield put(actions.setCompany(company.data));
    } catch (err) {
        yield put(actions.getCompanyFailure());
    }
}

function* postGenerateLink(data) {
    try {
        yield put(actions.postGenerateLinkPending());
        const response = yield call(generateLink, data);
        yield put(actions.setGeneratedLink(response.data.link));
        yield put(actions.postGenerateLinkSuccess());
    } catch (err) {
        yield put(actions.postGenerateLinkFailure());
    }
}

function* voteMlResult(data) {
    try {
        yield put(actions.voteMlResultPending());
        yield call(postVoteMlResult, data);
        yield put(actions.voteMlResultSuccess());
    } catch (err) {
        yield put(actions.voteMlResultFailure());
    }
}

function* generateShareLink(data) {
    try {
        yield put(actions.generateShareLinkPending());
        const response = yield call(getGenerateShareLink, data);
        yield put(actions.setShareableLink(response));
        yield put(actions.generateShareLinkSuccess());
    } catch (err) {
        yield put(actions.generateShareLinkFailure());
    }
}

function* fetchLikedCompanies(data) {
    const filterNameValue = data && data.filterNameValue;
    const orderBy = data && data.orderBy;
    const orderDirection = data && data.orderDirection;
    const page = data && data.page;
    try {
        yield put(actions.getLikedCompaniesPending());
        const filteredResults = yield call(
            getLikedCompanies,
            filterNameValue,
            orderBy,
            orderDirection,
            page,
        );
        const sliceData = { data: filteredResults.data, page: page };
        yield put(actions.setLikedCompanies(sliceData));
        yield put(actions.getLikedCompaniesSuccess());
    } catch (err) {
        yield put(actions.getLikedCompaniesFailure());
    }
}

export {
    fetchResults,
    fetchSearches,
    submitSearches,
    fetchCompaniesChart,
    fetchCompany,
    postGenerateLink,
    voteMlResult,
    generateShareLink,
    fetchResultsCount,
    fetchTeamSizeChart,
    fetchLikedCompanies,
};
