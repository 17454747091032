import React from "react";
import classnames from "classnames";
import { useStyles } from "./style.js";
// Components
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { useAuthHook } from "../../../../common/useAuthHook.js";

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
        showOnMobile: true,
    },
    {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
    },
    {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Description",
        showOnMobile: true,
    },
    /* {
		id: 'total_funding_amount_dollars',
		numeric: false,
		disablePadding: false,
		label: 'Funding',
	}, */
    {
        id: "founded_date",
        numeric: true,
        disablePadding: false,
        label: "Year",
    },
    /* {
		id: 'number_of_employees',
		numeric: true,
		disablePadding: false,
		label: 'Team',
	},
	{
		id: 'investment_stage',
		numeric: true,
		disablePadding: false,
		label: 'Stage',
	}, */
    {
        id: "hq_country",
        numeric: true,
        disablePadding: false,
        label: "Country",
    },
    {
        id: "degree_of_fit",
        numeric: true,
        disablePadding: false,
        label: "Match",
        showOnMobile: true,
    },
];

const TableHead = React.forwardRef(
    (
        {
            order,
            orderBy,
            onRequestSort,
            className,
            matchRemoved,
            typeRemoved = true,
            infoRemoved,
            isWorkspace = false,
            ...props
        },
        ref,
    ) => {
        const { user } = useAuthHook();
        const classes = useStyles({ user });
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        let headerCells = matchRemoved ? headCells.slice(0, -1) : headCells;
        headerCells = infoRemoved ? headerCells.slice(0, -5) : headerCells;
        headerCells = typeRemoved
            ? headerCells.filter(hc => hc.id !== "type")
            : headerCells;

        return (
            <div
                className={classnames([
                    classes.tableRow,
                    classes.tableHeadRow,
                    className,
                ])}
                ref={ref}>
                {!isWorkspace && (
                    <div
                        padding="checkbox"
                        className={classes.tableCellHeaderCheckbox}></div>
                )}
                {headerCells.map((headCell, i) => (
                    <div
                        className={classnames([
                            classes.tableCell1,
                            {
                                [classes.hideOnMobile]: !headCell.showOnMobile,
                                [classes.tableCellName]: i === 0,
                                [classes.tableCellDescription]:
                                    headCell.id === "description",
                                [classes.tableCell2]:
                                    i !== 1 && i !== headCells.length,
                                [classes[`workspace${headCell.id}`]]:
                                    !!isWorkspace,
                            },
                        ])}
                        key={headCell.id}
                        align={
                            headCell.id === "description" ? "left" : "center"
                        }>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "desc"}
                            onClick={createSortHandler(headCell.id)}
                            classes={{
                                root: classes.tableSortLabelRoot,
                                icon: classes.sortIcon,
                                active: classes.activeSort,
                            }}>
                            {headCell.label}
                        </TableSortLabel>
                    </div>
                ))}
                {/* <div
				className={classes.tableCell1}
				align="right"
				padding='normal'
			></div> */}
            </div>
        );
    },
);

export default TableHead;
