import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// Hooks
import { useStyles } from "./style.js";
import { useTranslation } from "react-i18next";
import useLocalStorage from "../../common/useLocalStorage";
import { useRouterHook } from "../../common/useRouterHook.js";
import { useAuthHook } from "../../common/useAuthHook";
// UI components
import Typography from "../../components/Typography/Typography";
import Card from "../../components/Card/Card";
import Pagination from "../../components/Pagination/Pagination";
import ContentLoader from "../../components/ContentLoader/ContentLoader";
// Redux
import { fetchIndustriesRequest } from "../../store/industries/requests";
import {
    getIndustriesSelector,
    getIndustriesTotalPagesSelector,
    getIndustriesTotalNumberSelector,
    getIndustriesStatusSelector,
} from "../../store/industries/selectors";
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
// Shared
import { Shared } from "../../utils/shared.js";
import { REQUEST_FAILURE, REQUEST_SUCCESS } from "../../constants/statuses";

const Industries = () => {
    const classes = useStyles();
    const { openPage, getUrlParam } = useRouterHook();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const industries = useSelector(getIndustriesSelector);
    const industriesStatus = useSelector(getIndustriesStatusSelector);
    const getIndustriesTotalPages = useSelector(
        getIndustriesTotalPagesSelector,
    );
    const getIndustriesTotalNumber = useSelector(
        getIndustriesTotalNumberSelector,
    );
    const sessionSlug = sessionStorage.getItem(`industry-slug`);
    const { clearStorage } = useLocalStorage("industry", sessionSlug);
    const { isAuthenticated } = useAuthHook();

    const { scrollTo } = Shared({});

    const queryParams = new URLSearchParams(window.location.search);
    const pageParam = queryParams.get("page");
    const pageNumber = JSON.parse(
        sessionStorage.getItem("industriesPageNumber"),
    );
    const [page, setPage] = useState(pageParam || pageNumber || 1);

    useEffect(() => {
        dispatch(fetchIndustriesRequest({ page }));
        dispatch(mixpanelTrackRequest({ eventName: "Industries page" }));
    }, [dispatch]);
    useEffect(() => {
        window.scrollTo(0, 0);
        clearStorage();
        sessionStorage.removeItem(
            `industry-${sessionSlug}-FilterSortDirection`,
        );
        sessionStorage.removeItem(`industry-${sessionSlug}-FilterSortValue`);
    }, []);

    const moreData = newPage => {
        dispatch(fetchIndustriesRequest({ page: newPage }));
        setPage(newPage);
    };

    useEffect(() => {
        getUrlParam("page") &&
            getUrlParam("page").value !== page &&
            openPage(`${window.location.pathname}?page=${page}`);
    }, [page]);

    useLayoutEffect(() => {
        let scrollPosition = sessionStorage.getItem("industriesScrollPosition");
        setTimeout(() => {
            scrollPosition &&
                industries.length &&
                scrollTo(Number(scrollPosition), () =>
                    sessionStorage.removeItem("industriesScrollPosition"),
                );
        }, 100);
    }, [industries]);

    return (
        <div className={classes.main}>
            {(industriesStatus === REQUEST_SUCCESS ||
                industriesStatus === REQUEST_FAILURE) && (
                <div className={classes.header}>
                    <div>
                        <div className={classes.headerTitle}>
                            <Typography variant="subtitle1">
                                {t("industries.title")}
                                {page &&
                                getIndustriesTotalNumber &&
                                getIndustriesTotalPages ? (
                                    <Typography
                                        className={classes.ml8}
                                        color="light"
                                        variant="bodySmall">
                                        {getIndustriesTotalPages > 1
                                            ? t(
                                                  "resultsHeader.showingResultsPaginationLabel",
                                                  {
                                                      count: getIndustriesTotalNumber,
                                                      page,
                                                      totalPages:
                                                          getIndustriesTotalPages,
                                                  },
                                              )
                                            : t(
                                                  "resultsHeader.showingResultsLabel",
                                                  {
                                                      count: getIndustriesTotalNumber,
                                                  },
                                              )}
                                    </Typography>
                                ) : null}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
            {industriesStatus === REQUEST_SUCCESS ||
            industriesStatus === REQUEST_FAILURE ? (
                <>
                    <div className={classes.blocks}>
                        {industries &&
                            industries.map((industry, i) => {
                                return (
                                    <Card
                                        key={`${i}-industry`}
                                        result={industry}
                                        index={i}
                                        isTechnology={false}
                                        isIndustry={true}
                                        isIndustryProfile={true}
                                        cardIndex={i}
                                        openPage={openPage}
                                        t={t}
                                        isAuthenticated={isAuthenticated}
                                        source="industry"
                                        type="industry"
                                    />
                                );
                            })}
                    </div>
                    {getIndustriesTotalPages > 1 && (
                        <Pagination
                            source="industries"
                            count={getIndustriesTotalPages}
                            onChangePage={moreData}
                            page={Number(page)}
                        />
                    )}
                </>
            ) : (
                <>
                    <ContentLoader />
                </>
            )}
        </div>
    );
};

export default Industries;
