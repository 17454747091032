import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Hooks
import { useStyles } from "./style.js";
import { useTranslation } from "react-i18next";
import { useRouterHook } from "../../common/useRouterHook";
import { useAppEventTracking } from "../../common/useAppEventTracking";
//UI components
import DashboardCardBig from "../Dashboard/components/DashboardCardBig";
import Typography from "../../components/Typography/Typography";
// Assets
import ArrowLeft from "../../assets/icons/ArrowLeft.png";
// Redux
import { fetchActivitiesRequest } from "../../store/dashboard/requests";
import { getActivitiesSelector } from "../../store/dashboard/selectors";
// Constants

const LatestActivities = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { mixpanelTrackEvent } = useAppEventTracking();

    useEffect(() => {
        mixpanelTrackEvent("Latest Activites visited");
    }, []);
    const { goBack } = useRouterHook();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchActivitiesRequest());
    }, [dispatch]);
    const getActivities = useSelector(getActivitiesSelector);

    const [latestActivities, setLatestActivities] = useState({
        type: "latestActivities",
        subitems: getActivities,
    });

    useEffect(() => {
        getActivities &&
            setLatestActivities({
                type: "latestActivities",
                subitems: getActivities,
            });
    }, [getActivities]);

    return (
        <div className={classes.root}>
            <div className={classes.goBack} onClick={goBack}>
                <img src={ArrowLeft} alt={t("technologies.goBack")} />
                <Typography color="secondaryDark" variant="bodyButton">
                    {t("technologies.goBack")}
                </Typography>
            </div>
            <div className={classes.likedCompaniesPageWrapper}>
                <DashboardCardBig items={latestActivities} page={true} />
            </div>
            <div className={classes.flexOne}></div>
        </div>
    );
};

export default LatestActivities;
