import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { useLocation, useHistory } from "react-router-dom";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useAppEventTracking } from "../../common/useAppEventTracking";
import useLocalStorage from "../../common/useLocalStorage";
// UI Components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Box from "@material-ui/core/Box";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { actions as searchActions } from "../../store/search/slice";
import {
    getPostSearchStatusSelector,
    getProductSelector,
} from "../../store/search/selectors";
import {
    getActiveViewSelector,
    getSearchResultsSidSelector,
} from "../../store/results/selectors";
import { actions as resultsActions } from "../../store/results/slice";
//assets
import WhiteWave from "../../assets/lottie-jsons/loader_white.json";
// Constants
import {
    REQUEST_SUCCESS,
    REQUEST_PENDING,
    REQUEST_FAILURE,
    ML_REQUEST_FAILURE,
    ML_REQUEST_TIMEOUT,
    ML_REQUEST_COMPANY_NOT_FOUND,
} from "../../constants/statuses";

const SearchLoader = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [subtitleTimer, setSubtitleTimer] = useState(0);
    const { openPage } = useRouterHook();
    const [animateOut, setAnimateOut] = useState(false);
    const [animateInBack, setAnimateInBack] = useState(false);
    // const [animateOutBack, setAnimateOutBack] = useState(false);
    const postSearchStatus = useSelector(getPostSearchStatusSelector);
    const { state: locationState, pathname } = useLocation();
    const history = useHistory();
    const [cancelHidden, setCancelHidden] = useState(false);
    const productId = useSelector(getProductSelector);
    const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
    const { clearStorage } = useLocalStorage("results", null);
    const activeView = useSelector(getActiveViewSelector);
    const sid = useSelector(getSearchResultsSidSelector);

    useEffect(() => {
        let data = localStorage.getItem("company-look-a-likes");
        dispatch(resultsActions.getSearchesStatusUndefined());
        if (data) {
            setCancelHidden(true);
        } else {
            setCancelHidden(false);
        }
    }, []);
    useEffect(() => {
        dispatch(resultsActions.clearResults());
        if (
            postSearchStatus === REQUEST_PENDING ||
            postSearchStatus === REQUEST_SUCCESS
        ) {
            if (productId === 1) {
                intercomTrackEvent(
                    "Discover companies using specific technologies",
                );
            } else if (productId === 2) {
                intercomTrackEvent("look-alike searches");
            }
        }
        const interval = setInterval(() => {
            setSubtitleTimer(subtitleTimer =>
                subtitleTimer === 8 ? 0 : subtitleTimer + 1,
            );
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    const cancelSearch = () => {
        dispatch(searchActions.postSearchCancel());
        setAnimateOut(true);
        setTimeout(() => {
            const path = locationState.from;

            history.replace(path, { from: pathname, someOtherProp: "" });
        }, 200);
    };

    useEffect(() => {
        if (postSearchStatus === REQUEST_SUCCESS) {
            clearStorage();
            dispatch(
                resultsActions.setResultsDisplayFilters({
                    sid: "results",
                    displayedFilters: {
                        filterSid: null,
                        sortValue: [],
                        sortDirection: [],
                        sector: [],
                        subsector: [],
                        subsectors: [],
                        stage: [],
                        fundingMin: "",
                        fundingMax: "",
                        teamsizeMin: "",
                        teamsizeMax: "",
                        location: [],
                        yearMin: "",
                        yearMax: "",
                        matchingScoreMin: "",
                        matchingScoreMax: "",
                        regions: [],
                        search: "",
                        storageResultsFilterNameValue: "",
                        storageResultsFilterPage: 1,
                    },
                }),
            );
            setAnimateInBack(false);
            setAnimateOut(true);
            let data = localStorage.getItem("company-look-a-likes");
            data = data && JSON.parse(data);
            setTimeout(() => {
                data
                    ? openPage("/results?searchTypeLook=company-look-a-likes")
                    : openPage(`/results?sid=${sid}&page=1`);
            }, 200);
        } else if (postSearchStatus === ML_REQUEST_FAILURE) {
            setAnimateInBack(false);
            setAnimateOut(true);
            setTimeout(() => {
                openPage("/ml-failure");
            }, 200);
        } else if (postSearchStatus === ML_REQUEST_TIMEOUT) {
            setAnimateInBack(false);
            setAnimateOut(true);
            setTimeout(() => {
                openPage("/ml-timeout");
            }, 200);
        } else if (postSearchStatus === ML_REQUEST_COMPANY_NOT_FOUND) {
            setAnimateInBack(false);
            setAnimateOut(true);
            setTimeout(() => {
                openPage("/ml-company-not-found");
            }, 200);
        } else if (postSearchStatus === REQUEST_FAILURE) {
            setAnimateInBack(false);
            setAnimateOut(true);
            setTimeout(() => {
                openPage("/ml-failure");
            }, 200);
        }
    }, [postSearchStatus, openPage]);

    return (
        <div
            className={classnames([
                {
                    [classes.animateOut]: animateOut,
                    [classes.animateInBack]: animateInBack,
                    [classes.animateIn]: !animateInBack,
                },
            ])}>
            <div className={classes.searchLoaderRoot}>
                <Typography
                    align="center"
                    variant="h4"
                    color="white"
                    className={classes.loaderTitle}>
                    {t("searchLoader.title")}
                </Typography>
                <Box display="flex" alignItems="center" flexDirection="column">
                    {/* <Loader color={theme.palette.common.white} size={120} loaderWrapperClassName={classes.loader} strokeWidth={4} /> */}
                    <div
                        className={classnames([
                            classes.loaderWave,
                            { [classes.marginAdjust]: cancelHidden },
                        ])}>
                        <Player
                            autoplay
                            loop
                            src={WhiteWave}
                            style={{ height: "403px", width: "544px" }}>
                            <Controls
                                visible={false}
                                buttons={["play", "repeat", "frame", "debug"]}
                            />
                        </Player>
                    </div>
                    <Typography
                        align="center"
                        color="white"
                        variant="subtitle2">
                        {t(`searchLoader.subtitleText.${subtitleTimer}`)}
                    </Typography>
                </Box>
                {!cancelHidden && (
                    <Button
                        variant="tertiary"
                        size="large"
                        dark
                        onClick={cancelSearch}>
                        {t("searchLoader.cancelRequest")}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default SearchLoader;
