import React, { useEffect } from "react";

import Select, { components } from "react-select";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";

function arrayMove(array, from, to) {
    const slicedArray = array.slice();
    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0],
    );
    return slicedArray;
}

const SortableMultiValue = SortableElement(props => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(props => (
    <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

export default function MultiSelectSort({
    options,
    onChange,
    isDisabled = false,
    placeholder,
    defaultValue,
    // sendDataToParent,
}) {
    const [selected, setSelected] = React.useState([]);
    const [parsedValue, setParsedValue] = React.useState([]);
    const [parsedOptions, setParsedOptions] = React.useState([]);
    useEffect(() => {
        setParsedOptions(
            options?.map(option => {
                const label = option.label ? option.label : option.name;
                const value = option.value
                    ? option.value
                    : option.slug || option.name;
                const industry = option.industry || "";
                const industryLabel = option.industryLabel || "";
                return {
                    label,
                    value,
                    industry,
                    industryLabel,
                };
            }),
        );
        if (options.length === 0) {
            setSelected([]);
            setParsedValue([]);
        } else {
            // sendDataToParent(selected);
            setParsedValue(
                defaultValue?.map(option => {
                    let label = option;
                    let value = option;
                    if (typeof option === "object") {
                        label = option.label ? option.label : option.name;
                        value = option.value
                            ? option.value
                            : option.slug || option.name;
                    }
                    return {
                        value,
                        label
                    }
                })
            )
        }
    }, [options, defaultValue]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(selected, oldIndex, newIndex);
        setSelected(newValue);
    };

    const handleChange = selectedOptions => {
        setSelected(selectedOptions);
        onChange(selectedOptions);
    };

    return (
        <SortableSelect
            useDragHandle
            // react-sortable-hoc props:
            axis="xy"
            onSortEnd={onSortEnd}
            placeholder={placeholder || "Select..."}
            className="react-select-container"
            classNamePrefix="react-select"
            distance={4}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isOpen ? "red" : "#C2C9D7",
                    outline: "none",
                    border: 0,
                    border: state.isOpen ? "#000000" : "#C2C9D7",
                    "&:hover": {
                        border: state.isFocused ? 0 : 0,
                    },
                    // This line disable the blue border
                    boxShadow: "none",
                }),
                option: base => ({
                    ...base,
                    background: "#fff",
                    "&:hover": {
                        background: "#FD750D",
                        color: "#fff",
                    },
                    "&:active": {
                        background: "#FD750D",
                        color: "#fff",
                    },
                    // kill the white space on first and last option
                    // padding: 0,
                }),
            }}
            // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            // react-select props:
            isMulti
            isDisabled={isDisabled}
            options={parsedOptions}
            value={parsedValue || selected}
            onChange={handleChange}
            components={{
                // @ts-ignore We're failing to provide a required index prop to SortableElement
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
            }}
            closeMenuOnSelect={false}
        />
    );
}
