import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
// Hooks
import { useStyles } from "./style.js";
import { useAuthHook } from "../../common/useAuthHook";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAddToProjects } from "./useAddToProjects";
import { useAppEventTracking } from "../../common/useAppEventTracking";
import { useRouterHook } from "../../common/useRouterHook";
//UI components
import Layout from "../Layout/Layout";
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";
import TopUpCreditsModal from "../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal";
import TabContent from "./components/TabContent";
// Redux
import {
    getOrdersStatusSelector,
    getOrdersCompaniesSelector,
    getOrdersTechnologiesSelector,
} from "../../store/orders/selectors";
// Constants

const Orders = () => {
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isAuthenticated, getMeStatus, user } = useAuthHook();
    const { pathname } = useLocation();
    const history = useHistory();
    const getOrdersStatus = useSelector(getOrdersStatusSelector);
    const ordersCompanies = useSelector(getOrdersCompaniesSelector);
    const ordersTechnologies = useSelector(getOrdersTechnologiesSelector);
    const [activeTab, setActiveTab] = useState("companies");
    const { appTrackEvent } = useAppEventTracking();

    const {
        topUpCreditsModalShow,
        handleClosingTopUpCreditsDialog,
        handleBuyingTopUpCreditsDialog,
    } = useAddToProjects({ order: ordersCompanies[0], user });

    const [selectedOrders, setSelectedOrders] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        appTrackEvent("Orders tab visited");
    }, []);

    const handleSelectedOrdersChange = orders => {
        setSelectedOrders(orders);
    };

    const handleOpenTabCompaniesEnrichments = () => {
        setActiveTab("companies");
        openPage("/orders");
    };

    const handleOpenTabRechnologiesEnrichments = () => {
        setActiveTab("technologies");
        openPage("/orders/technologies");
    };

    useEffect(() => {
        window.location.pathname === "/orders/technologies" &&
            setActiveTab("technologies");
    }, [window.location.pathname]);

    return (
        <Layout match="orders" color="white">
            <div className={classes.main}>
                <div className={classes.header}>
                    <div>
                        <div className={classes.headerTitle}>
                            <Typography variant="subtitle1">
                                {t("orders.title")}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.tabsWrapper}>
                    <div className={classes.tabs}>
                        <Button
                            data-intercom-target="Companies enrichments element"
                            className={classnames([
                                classes.companiesButton,
                                {
                                    [classes.activeTab]:
                                        activeTab === "companies",
                                },
                            ])}
                            variant="tertiary"
                            onClick={handleOpenTabCompaniesEnrichments}>
                            {t("orders.companiesEnrichments")}
                        </Button>
                        <Button
                            data-intercom-target="Technologies deep-dives element"
                            className={classnames([
                                classes.technologyDiveButton,
                                {
                                    [classes.activeTab]:
                                        activeTab === "technologies",
                                },
                            ])}
                            variant="tertiary"
                            onClick={handleOpenTabRechnologiesEnrichments}>
                            {t("orders.technologiesEnrichments")}
                        </Button>
                    </div>
                </div>
                {/* START: Companies */}
                {activeTab === "companies" && ordersCompanies && (
                    <TabContent
                        dispatch={dispatch}
                        ordersStore={ordersCompanies}
                        getOrdersStatus={getOrdersStatus}
                        activeTab={activeTab}
                        user={user}
                        type="companies"
                        onSelectedOrdersChange={handleSelectedOrdersChange}
                    />
                )}
                {/* END: Companies */}
                {/* START: Technologies */}
                {activeTab === "technologies" && ordersTechnologies && (
                    <TabContent
                        dispatch={dispatch}
                        ordersStore={ordersTechnologies}
                        getOrdersStatus={getOrdersStatus}
                        activeTab={activeTab}
                        user={user}
                        type="technologies"
                        onSelectedOrdersChange={handleSelectedOrdersChange}
                    />
                )}
                {/* END: Technologies */}
                {/* {selectedOrders && selectedOrders[0] && <CreateProjectModal source="orders" company={selectedOrders[0].oid} setDialogOpenedResult={setDialogOpenedResult} result={dialogOpenedResult} dialogOpened={dialogOpened} selectedCompanies={selectedOrders} onChange={handleToggleDialog} newProjectCreated={handleNewProjectCreated}></CreateProjectModal>} */}
                <TopUpCreditsModal
                    dialogOpened={topUpCreditsModalShow}
                    onBuy={handleBuyingTopUpCreditsDialog}
                    onChange={handleClosingTopUpCreditsDialog}
                />
            </div>
        </Layout>
    );
};

export default Orders;
