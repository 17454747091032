import { call } from "redux-saga/effects";
import axios from "axios";
import { INVOICES_URL } from "../../../constants/endpoints";

function* getInvoices() {
    const response = yield call(axios, {
        method: "GET",
        url: `${INVOICES_URL}`,
        withCredentials: true,
    });
    return response.data;
}

export { getInvoices };
