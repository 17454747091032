const getQuickStatsSelector = state => state.dashboard.quickStats;
const getLatestOrdersSelector = state => state.dashboard.latestOrders;
const getLatestActivitiesSelector = state => state.dashboard.latestActivities;
const getUnseenActivitiesCountSelector = state =>
    state.dashboard.unseenActivitiesCount;
const getActivitiesSelector = state => state.dashboard.activities;
const getPopularCompaniesSelector = state => state.dashboard.popularCompanies;

export {
    getQuickStatsSelector,
    getLatestOrdersSelector,
    getUnseenActivitiesCountSelector,
    getLatestActivitiesSelector,
    getActivitiesSelector,
    getPopularCompaniesSelector,
};
