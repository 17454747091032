import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import {
    login,
    logout,
    getMe,
    postUpdateProfile,
    postRegister,
    postResetPassword,
    postSendResetLink,
    postCheckResetLink,
    getCookie,
    getSubscribe,
    getTeam,
    postTeamMember,
    getInvitation,
    getHandleInvitation,
    getAccount,
    getLeaveTeam,
    postOpenClusterMap,
} from "./helpers";

function* postLogin(emailValue, passwordValue, token = null) {
    try {
        yield call(getCookie);
        yield put(actions.postLoginPending());
        let data = new FormData();
        data.append("email", emailValue); // "jondoe@mail.com"
        data.append("password", passwordValue); // "asdasdasd"
        if (token) {
            data.append("token", token); // invitation token
        }
        const loginResponse = yield call(login, data);
        if (loginResponse.data) {
            yield put(actions.setAccessToken(loginResponse.data.access_token));
            yield put(actions.postLoginSuccess());
        } else {
            yield put(actions.postLoginFailure(loginResponse.message));
        }
    } catch (err) {
        yield put(actions.postLoginFailure(err?.response?.data?.message));
    }
}

function* postLogout() {
    try {
        yield put(actions.postLogoutPending());

        const response = yield call(logout);

        if (response.status_code === 201) {
            yield put(actions.setUser(null));
            yield put(actions.postLogoutSuccess());
        } else {
            yield put(actions.postLogoutFailure());
        }
    } catch (err) {
        yield put(actions.postLogoutFailure());
    }
}

function* register(data) {
    let response;

    try {
        yield call(getCookie);
        yield put(actions.registerPending());
        response = yield call(postRegister, data);
    } catch (e) {
        response = e.response;
    }
    if (response.data.status === "error" || response.status === "error") {
        yield put(actions.registerFailure(response.data.data));
    } else {
        yield put(actions.registerSuccess());
    }
}

function* subscribe(data) {
    try {
        yield call(getCookie);
        yield put(actions.subscribePending());
        const response = yield call(getSubscribe, data);
        yield put(actions.setCheckOutUrl(response.data));
        if (response.status === "error") {
            yield put(actions.subscribeFailure(response.message));
        } else {
            yield put(actions.subscribeSuccess());
        }
    } catch (err) {
        const errorMsg =
            err.response && err.response.data ? err.response.data.message : "";
        yield put(actions.subscribeFailure(errorMsg));
    }
}

function* fetchCookie() {
    try {
        yield put(actions.getCookiePending());
        yield call(getCookie);
        yield put(actions.getCookieSuccess());
    } catch (err) {
        yield put(actions.getCookieFailure());
    }
}

function* fetchTeam(team) {
    try {
        yield put(actions.getTeamPending());
        const response = yield call(getTeam, team);
        yield put(actions.setTeam(response.data));
        yield put(actions.getTeamSuccess());
        yield put(actions.removeMemberUndefined());
    } catch (err) {
        yield put(actions.getTeamFailure());
    }
}

function* leaveTeam(team) {
    try {
        yield put(actions.leaveTeamPending());
        yield call(getLeaveTeam, team);
        yield put(actions.leaveTeamSuccess());
    } catch (err) {
        yield put(actions.leaveTeamFailure());
    }
}

function* fetchAccount(team) {
    try {
        yield put(actions.getAccountPending());
        yield call(getAccount, team);
        // yield put(actions.setAccount(response.data));
        yield put(actions.getAccountSuccess());
    } catch (err) {
        yield put(actions.getAccountFailure());
    }
}

function* checkResetLink(data) {
    try {
        yield put(actions.checkResetLinkPending());
        const response = yield call(postCheckResetLink, data);
        yield put(actions.checkResetLinkSuccess(response.data));
    } catch (err) {
        yield put(actions.checkResetLinkFailure());
    }
}

function* sendResetLink(data) {
    try {
        yield put(actions.sendResetLinkPending());
        const response = yield call(postSendResetLink, data);
        yield put(actions.sendResetLinkSuccess(response.data.responseMsg));
    } catch (err) {
        yield put(actions.sendResetLinkFailure());
    }
}

function* resetPassword(data) {
    try {
        yield put(actions.resetPasswordPending());
        const response = yield call(postResetPassword, data);
        yield put(actions.resetPasswordSuccess(response.data.responseMsg));
    } catch (err) {
        yield put(actions.resetPasswordFailure());
    }
}

function* fetchMe() {
    try {
        const response = yield call(getMe);
        yield put(actions.getMePending());
        yield put(actions.setUser(response.data));
        yield put(actions.getMeSuccess());
    } catch (err) {
        yield put(actions.setUser(null));
        yield put(actions.getMeFailure());
    }
}

function* fetchMeStealth() {
    try {
        const response = yield call(getMe);
        yield put(actions.getMeStealthPending());
        yield put(actions.setUser(response.data));
        yield put(actions.getMeStealthSuccess());
    } catch (err) {
        yield put(actions.setUser(null));
        yield put(actions.getMeStealthFailure());
    }
}

function* updateProfile(data) {
    try {
        yield put(actions.postUpdateProfilePending());
        yield call(postUpdateProfile, data);
        yield put(actions.postUpdateProfileSuccess());
    } catch (err) {
        yield put(actions.postUpdateProfileFailure());
    }
}

function* removeMember(email) {
    try {
        yield put(actions.removeMemberPending());
        yield call(postTeamMember, email);
        yield put(actions.removeMemberSuccess());
    } catch (err) {
        yield put(actions.removeMemberFailure());
    }
}

function* removeInvitation(email) {
    try {
        yield put(actions.removeInvitationPending());
        yield call(postTeamMember, email);
        yield put(actions.removeInvitationSuccess());
    } catch (err) {
        yield put(actions.removeInvitationFailure());
    }
}

function* fetchInvitation(token) {
    try {
        yield put(actions.fetchInvitationPending());
        const response = yield call(getInvitation, token);
        yield put(actions.setInvitation(response.data));
        yield put(actions.fetchInvitationSuccess());
    } catch (err) {
        yield put(actions.fetchInvitationFailure());
    }
}

function* handleInvitation(token) {
    try {
        yield put(actions.handleInvitationStatusPending());
        yield call(getHandleInvitation, token);
        yield put(actions.handleInvitationStatusSuccess());
    } catch (err) {
        yield put(actions.handleInvitationStatusFailure());
    }
}

function* openClusterMap(token) {
    try {
        yield put(actions.openClusterMapPending());
        yield call(postOpenClusterMap);
        yield put(actions.openClusterMapSuccess());
    } catch (err) {
        yield put(actions.openClusterMapFailure());
    }
}

export {
    postLogin,
    postLogout,
    fetchMe,
    fetchMeStealth,
    updateProfile,
    register,
    sendResetLink,
    resetPassword,
    checkResetLink,
    fetchCookie,
    subscribe,
    fetchTeam,
    removeMember,
    fetchInvitation,
    handleInvitation,
    fetchAccount,
    leaveTeam,
    removeInvitation,
    openClusterMap,
};
