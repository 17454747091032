import { useState } from "react";
import { actions as searchActions } from "../../../store/search/slice";
import { actions as sectorsActions } from "../../../store/sectors/slice";
import {
    getSectorsSelector,
    sectorSelector,
} from "../../../store/sectors/selectors";
import { useSelector, useDispatch } from "react-redux";
import {
    getSearchSectorsSelector,
    getSearchSectorSelector,
} from "../../../store/search/selectors";
import { fetchSubsectorsRequest } from "../../../store/sectors/requests";

export const useSectorsHook = () => {
    const dispatch = useDispatch();
    const selectedSectors = useSelector(getSearchSectorsSelector);
    const allSectors = useSelector(getSectorsSelector);
    const [dialogOpened, setDialogOpened] = useState(false);
    const [sectorOpened, setSectorOpened] = useState({});
    const [newSubsector, setNewsubsector] = useState({ name: "", slug: "" });
    const selectedSearchSector = useSelector(
        getSearchSectorSelector(sectorOpened.slug),
    );
    const sector = useSelector(sectorSelector(sectorOpened.slug));

    const openSubsectorsDialog = sector => {
        setSectorOpened(sector);
        handleToggleDialog();
        dispatch(fetchSubsectorsRequest(sector.slug));
    };

    const handleSectorSelect = (sector, active) => {
        if (active) {
            removeSector(sector);
        } else if (selectedSectors.length < 5) {
            !selectedSectors.some(i => i.slug === sector.slug) &&
                dispatch(searchActions.addSector(sector));
        }
    };

    const removeSector = sector => {
        dispatch(searchActions.removeSector(sector));
    };

    const removeAllSectors = () => {
        dispatch(searchActions.removeAllSectors());
    };

    const handleSubsectorSelect = (sectorSlug, subsector, active) => {
        active
            ? dispatch(searchActions.removeSubsector({ sectorSlug, subsector }))
            : dispatch(searchActions.addSubsector({ subsector, sectorOpened }));
    };

    const handleAddSubsector = subsector => {
        dispatch(sectorsActions.addSubsector({ subsector, sectorOpened }));
        dispatch(searchActions.addSubsector({ subsector, sectorOpened }));
        setNewsubsector({ name: "", slug: "" });
    };

    const handleSubsectorRemove = (sectorSlug, subsector) => {
        dispatch(searchActions.removeSubsector({ sectorSlug, subsector }));
    };

    const handleToggleDialog = () => {
        setDialogOpened(!dialogOpened);
    };

    const handleAddNewSubsector = e => {
        setNewsubsector({
            name: e.target.value,
            slug: e.target.value.replace(/ +/g, "-").toLowerCase(),
        });
    };

    return {
        allSectors,
        selectedSearchSector,
        sector,
        newSubsector,
        handleSectorSelect,
        removeSector,
        removeAllSectors,
        handleSubsectorSelect,
        handleAddSubsector,
        handleSubsectorRemove,
        handleAddNewSubsector,
        openSubsectorsDialog,
        handleToggleDialog,
        dialogOpened,
        selectedSectors,
    };
};
