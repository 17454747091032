import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// Hooks
import { useStyles } from "../style.js";
import { useTranslation } from "react-i18next";
import { useRouterHook } from "../../../common/useRouterHook";
//UI components
import Typography from "../../../components/Typography/Typography";
// Assets
import CommentsIcon from "../../../assets/icons/dashboardCommentsIcon.svg";
import CreditsIcon from "../../../assets/icons/dashboardCreditsIcon.svg";
import LikedCompaniesIcon from "../../../assets/icons/dashboardLikedCompaniesIcon.svg";
import ProjectsIcon from "../../../assets/icons/dashboardProjectsIcon.svg";
import NoOrdersData from "../../../assets/images/NoOrdersData.png";
import NoLatestActivitiesData from "../../../assets/images/NoLatestActivitiesData.png";
import NoSearches from "../../../assets/images/NoSearches.png";
// Redux
import { markActivitySeenRequest } from "../../../store/dashboard/requests";
import { getUnseenActivitiesCountSelector } from "../../../store/dashboard/selectors";
// Constants

const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        return () => {
            setState(false);
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            { rootMargin },
        );

        element.current && observer.observe(element.current);

        return () =>
            element.current && observer && observer.unobserve(element.current);
    }, []);

    return isVisible;
};

const LatestItem = ({ item, index, classes, type, openPage, t }) => {
    let icon;
    const ref = useRef();
    const inViewport = useIntersection(ref, "0px");
    const dispatch = useDispatch();

    useEffect(() => {
        if (type === "latestActivities" && inViewport && !item.seen) {
            dispatch(markActivitySeenRequest({ id: item.id }));
        }
    }, [inViewport, item]);

    if (type === "latestActivities") {
        switch (item.action) {
            case "ADD_COMPANY_TO_RADAR":
                icon = ProjectsIcon;
                break;
            case "COMPANY_VOTE_CREATED":
                icon = LikedCompaniesIcon;
                break;
            case "COMPANY_COMMENT_CREATED":
                icon = CommentsIcon;
                break;
            default:
                icon = CreditsIcon;
        }
    }

    const handleOrderClick = item => {
        sessionStorage.setItem("history", "dashboard");
        openPage(
            `/${item.resource.type}/${item.resource.slug}?cid=${item.resource.id}`,
        );
    };

    return (
        <div
            key={"dashboard-big-card-subitem-" + index}
            test-id={"dashboard-big-card-subitem-" + index}
            className={
                type === "latestOrders" || type === "savedSearches"
                    ? classes.dashboardCardBigBodyItem
                    : classes.dashboardCardBigBodyActivity
            }
            ref={ref}>
            {(item.resource || item.search_by) && (
                <>
                    {type === "latestOrders" ? (
                        <Typography
                            className={classes.dashboardCardBigBodyItemTextLink}
                            variant="bodySmall"
                            color="black"
                            onClick={() => handleOrderClick(item)}>
                            {item.name}
                        </Typography>
                    ) : (
                        <Typography
                            className={classes.dashboardCardBigBodyItemText}
                            variant="bodySmall"
                            color="black">
                            {!item.seen &&
                                !item.description &&
                                !item.look_alike_company_name && (
                                    <div className={classes.unseenDot}></div>
                                )}
                            {icon &&
                                !item.description &&
                                !item.look_alike_company_name && (
                                    <span>
                                        <img
                                            className={
                                                classes.dashboardCardBigBodyItemIcon
                                            }
                                            src={icon}
                                        />
                                    </span>
                                )}
                            {item.description ||
                            item.look_alike_company_name ? (
                                <div className={classes.actionWrap}>
                                    <Typography
                                        variant="bodySmall"
                                        color="black"
                                        component="div"
                                        className={
                                            type === "savedSearches"
                                                ? classes.savedSearch
                                                : classes.latestActivityAction
                                        }>
                                        {(type === "savedSearches"
                                            ? item.search_name
                                            : item.description) ||
                                            item.look_alike_company_name}
                                    </Typography>
                                </div>
                            ) : item.action === "COMPANY_VOTE_CREATED" ? (
                                <div className={classes.actionWrap}>
                                    <Typography
                                        variant="bodySmall"
                                        color="black"
                                        component="div"
                                        className={
                                            classes.latestActivityAction
                                        }>
                                        <u
                                            className={
                                                classes.latestActivityActionLinkLeft
                                            }
                                            onClick={() =>
                                                handleOrderClick(item)
                                            }
                                            test-id={`link-${index}`}>
                                            {item.name}
                                        </u>
                                        {t(
                                            `dashboard.bigCards.latestActivities.activityCodes.${item.action}`,
                                        )}
                                    </Typography>
                                </div>
                            ) : (
                                <div className={classes.actionWrap}>
                                    <Typography
                                        variant="bodySmall"
                                        color="black"
                                        component="div"
                                        className={
                                            classes.latestActivityAction
                                        }>
                                        {t(
                                            `dashboard.bigCards.latestActivities.activityCodes.${item.action}`,
                                        )}
                                        <u
                                            className={
                                                classes.latestActivityActionLink
                                            }
                                            onClick={() =>
                                                handleOrderClick(item)
                                            }
                                            test-id={`link-${index}`}>
                                            {item.name}
                                        </u>
                                    </Typography>
                                </div>
                            )}
                        </Typography>
                    )}
                </>
            )}
            <div className={classes.latestItemRightSide}>
                {item.status && (
                    <Typography
                        variant="bodyXs"
                        color={
                            item.status === "Request complete"
                                ? "success"
                                : "warningDark"
                        }
                        className={classes.mono}>
                        {item.status === "Request complete"
                            ? item.status
                            : item.status}
                    </Typography>
                )}
                {type === "latestOrders" && item.requested_at ? (
                    <Typography
                        variant="bodyXs"
                        color="text"
                        className={classes.dashboardCardBigBodyItemTimestamp}>
                        {item.status === "Request received" ||
                        item.status === "In progress"
                            ? item.requested_at
                            : item.updated_at}
                    </Typography>
                ) : (
                    <Typography
                        variant="bodyXs"
                        color="text"
                        className={
                            classes.dashboardCardBigBodyActivityTimestamp
                        }>
                        {item.created_ago ||
                            item.created_at_human_date ||
                            moment(item.created_date).fromNow()}
                    </Typography>
                )}
            </div>
        </div>
    );
};

const DashboardCardBig = ({ items, type, page = false, testId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const getUnseenActivitiesCount = useSelector(
        getUnseenActivitiesCountSelector,
    );
    return (
        <div
            className={
                page ? classes.dashboardCardBigPage : classes.dashboardCardBig
            }
            test-id={testId}>
            <>
                <div>
                    <div className={classes.dashboardCardBigHeader}>
                        <div className={classes.dashboardCardBigHeaderTitle}>
                            <Typography
                                variant="paragraph"
                                className={classes.bigCardTitle}>
                                {t(`dashboard.bigCards.${items.type}.title`)}{" "}
                                {getUnseenActivitiesCount > 0 &&
                                    type === "activities" && (
                                        <Typography
                                            variant="bodyXs"
                                            color="white"
                                            component="div"
                                            className={classes.unseenNumber}>
                                            {getUnseenActivitiesCount}
                                        </Typography>
                                    )}
                            </Typography>
                        </div>
                        <div className={classes.dashboardCardBigHeaderSubtitle}>
                            <Typography
                                variant="bodySmall"
                                color="textSecondary">
                                {t(`dashboard.bigCards.${items.type}.subtitle`)}
                            </Typography>
                        </div>
                    </div>
                    {items &&
                    items.subitems &&
                    !Object.values(items.subitems).length ? (
                        <div className={classes.noDataWrapper}>
                            <img
                                src={
                                    type === "activities"
                                        ? NoLatestActivitiesData
                                        : type === "orders"
                                        ? NoOrdersData
                                        : NoSearches
                                }
                                className={classes.noDataImg}
                            />
                        </div>
                    ) : (
                        <>
                            <div className={classes.dashboardCardBigBody}>
                                {type === "savedSearches"
                                    ? items &&
                                      items.subitems &&
                                      items.subitems
                                          .slice(0, 5)
                                          .map((subitem, index) => (
                                              <LatestItem
                                                  item={subitem}
                                                  index={index}
                                                  classes={classes}
                                                  type={items.type}
                                                  key={`latest-item-${index}`}
                                                  openPage={openPage}
                                                  t={t}
                                              />
                                          ))
                                    : items &&
                                      items.subitems &&
                                      items.subitems.map((subitem, index) => (
                                          <LatestItem
                                              item={subitem}
                                              index={index}
                                              classes={classes}
                                              type={items.type}
                                              key={`latest-item-${index}`}
                                              openPage={openPage}
                                              t={t}
                                          />
                                      ))}

                                <div className={classes.fade}></div>
                            </div>
                        </>
                    )}
                </div>
                {!page &&
                items &&
                items.subitems &&
                Object.values(items.subitems).length ? (
                    <Typography
                        variant="bodySmall"
                        color="text"
                        component="div"
                        className={classes.dashboardCardBigFooter}>
                        {t(`dashboard.bigCards.${items.type}.footer.text`)}
                        <Typography
                            variant="bodySmall"
                            color="black"
                            className={classes.dashboardCardBigFooterLink}
                            onClick={() =>
                                openPage(
                                    items.type === "latestOrders"
                                        ? "/orders"
                                        : items.type === "savedSearches"
                                        ? "/saved-searches"
                                        : "/latest-activities",
                                )
                            }>
                            {t(`dashboard.bigCards.${items.type}.footer.link`)}
                        </Typography>
                    </Typography>
                ) : null}
            </>
            {/* {
                item.group === 'second' &&
                <>
                    <div><Typography variant="paragraph">{t(`dashboard.bigCards.${item.type}.title`)}</Typography></div>
                </>
            } */}
        </div>
    );
};

export default DashboardCardBig;
