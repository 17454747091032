import React from "react";
import { WorkspaceListView } from "../../components/WorkspaceListView/WorkspaceListView.js";
import Layout from "../Layout/Layout.js";
import { useStyles } from "./style.js";

const WorkspaceListViewPreview = () => {
    const classes = useStyles();

    return (
        <Layout color="white">
            <div className={classes.main}>
                <WorkspaceListView />
            </div>
        </Layout>
    );
};

export default WorkspaceListViewPreview;
