import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        dialogRoot: {
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: "left",
        },
        dialog: {
            padding: spacing(8),
            borderRadius: spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dialogScrollPaper: {
            maxWidth: 800,
        },
        dialogContent: {
            overflow: "auto",
            textAlign: "center",
            padding: 0,
            "&::-webkit-scrollbar": {
                width: 2,
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: palette.text.main,
                borderRadius: 0,
            },
        },
        closeButton: {
            position: "absolute !important",
            right: spacing(4),
            top: spacing(3),
            color: palette.grey[500],
        },
        dialogTitle: {
            width: "100%",
            textAlign: "center",
            paddingBottom: spacing(3),
        },
        goNext: {
            cursor: "pointer",
            width: "100%",
            marginTop: spacing(4),
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        arrowImg: {
            marginLeft: spacing(2),
        },
        buttons: {
            marginTop: spacing(4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        cancelButton: {
            background: "transparent",
            width: "100%",
            height: spacing(5),
            marginTop: spacing(2),
            borderColor: palette.primary.dark,
            color: palette.primary.dark,
            "&:hover": {
                background: "transparent",
                borderColor: palette.primary.dark,
            },
        },
        footer: {
            marginTop: spacing(4),

            "& a": {
                marginLeft: spacing(1),
            },
        },
    }),
);
