// Hooks
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Redux
import {
    getUserSelector,
    getUserStatusSelector,
} from "../store/auth/selectors";
import {
    REQUEST_FAILURE,
    REQUEST_SUCCESS,
    REQUEST_PENDING,
} from "../constants/statuses";

export const useAuthHook = () => {
    const user = useSelector(getUserSelector);
    const getMeStatus = useSelector(getUserStatusSelector);
    const [userLoading, setUserLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(!!user);
    const [isGuestUser, setIsGuestUser] = useState(!user);
    const [isFreeUser, setIsFreeUser] = useState();
    const [isFreeTrialUser, setIsFreeTrialUser] = useState();
    const [isFreeTrialExpiredUser, setIsFreeTrialExpiredUser] = useState();
    const [isExpiredUser, setIsExpiredUser] = useState();
    const [isPayingUser, setIsPayingUser] = useState();
    const [isOwner, setIsOwner] = useState();
    const [userHasCredits, setUserHasCredits] = useState();

    useEffect(() => {
        setIsAuthenticated(!!user);
        setIsGuestUser(!user);

        if (user) {
            setIsFreeUser(user.subscription.plan_type === "free");
            setIsFreeTrialUser(
                user.subscription.free_trial &&
                    user.subscription.free_trial.is_active,
            );
            setIsFreeTrialExpiredUser(
                user.subscription.free_trial &&
                    !user.subscription.free_trial.is_active,
            );
            setIsExpiredUser(!user.subscription.is_active);
            setIsPayingUser(user.subscription.is_active && !isFreeUser);
            setIsOwner(user.team && user.team.role === "owner");
            setUserHasCredits(user.credits > 0);
        }
    }, [user, isFreeUser]);

    useEffect(() => {
        if (
            getMeStatus === REQUEST_SUCCESS ||
            getMeStatus === REQUEST_FAILURE
        ) {
            setUserLoading(false);
        } else if (getMeStatus === REQUEST_PENDING) {
            setUserLoading(true);
        }
    }, [getMeStatus]);

    return {
        user,
        userLoading,
        getMeStatus,
        isAuthenticated,
        isGuestUser,
        isFreeUser,
        isFreeTrialUser,
        isFreeTrialExpiredUser,
        isExpiredUser,
        isPayingUser,
        isOwner,
        userHasCredits,
    };
};
