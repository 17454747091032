import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_PENDING,
} from "../constants/statuses";
import {
    getSearchResultsStatusSelector,
    getSearchResultsBySidStatusSelector,
    getSearchResultsPaginationStatusSelector,
    getSearchResultsBySidPaginationStatusSelector,
} from "../store/results/selectors";
import useLocalStorage from "./useLocalStorage";
import { useAppEventTracking } from "../common/useAppEventTracking";
import { useRouterHook } from "./useRouterHook";
import { actions as resultsActions } from "../store/results/slice";

const useResults = ({
    totalCount,
    searchResults,
    isAuthenticated,
    getMeStatus,
    search,
    page,
    perPage,
    totalPages,
    filterNameValue,
    setPage,
    selectedItems,
    setSelectedItems,
    setIsAuthed,
    sortValue,
    sortDirection,
    currentSearchSid,
    getFilteringState,
    isSharedPageToken,
    displayedFilters,
    request,
    localStorageSlugPrefix,
    slug,
    activeView,
}) => {
    const [noResults, setNoResults] = useState(false);

    const dispatch = useDispatch();
    const searchResultsPaginationStatus = useSelector(
        getSearchResultsPaginationStatusSelector,
    );
    const searchResultsBySidPaginationStatus = useSelector(
        getSearchResultsBySidPaginationStatusSelector,
    );
    const [pageLoading, setPageLoading] = useState(false);
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);
    const searchResultsStatusSelector = useSelector(
        getSearchResultsStatusSelector,
    );
    const searchResultsBySidStatusSelector = useSelector(
        getSearchResultsBySidStatusSelector,
    );
    const { clearStorage } = useLocalStorage("results", "page");
    const { mixpanelTrackEvent } = useAppEventTracking();
    const { openPage } = useRouterHook();

    useEffect(() => {
        setNoResults(
            totalCount &&
                totalCount === 0 &&
                (searchResultsStatusSelector === REQUEST_SUCCESS ||
                    searchResultsBySidStatusSelector === REQUEST_SUCCESS),
        );
    }, [
        totalCount,
        searchResults,
        searchResultsStatusSelector,
        searchResultsBySidStatusSelector,
    ]);

    const handleSelectingCardIndex = value => {
        setSelectedCardIndex(value);
    };

    const moreData = newPage => {
        if (totalCount !== 0 && (!!isAuthenticated || isSharedPageToken)) {
            if (totalPages && page) {
                const urlParams = new URLSearchParams(search);
                let sid = urlParams.get("sid");
                let searchTypeLook = urlParams.get("searchTypeLook");
                if (!sid) {
                    sid = currentSearchSid;
                }
                const sidData = {
                    filterNameValue: filterNameValue,
                    sid,
                    perPage,
                    page: newPage,
                    orderBy: sortValue,
                    orderDirection: sortDirection,
                };
                if (
                    filterNameValue ===
                        `sort-direction=${sortDirection}&sort-by=${sortValue}` ||
                    getFilteringState.length === 0
                ) {
                    const resultsData = {
                        page: newPage,
                        perPage,
                        orderBy: sortValue,
                        orderDirection: sortDirection,
                    };
                    dispatch(
                        resultsActions.setResultsDisplayFilters({
                            sid: slug,
                            displayedFilters: {
                                filterSid: slug,
                                sortValue: [],
                                sortDirection: [],
                                sector: [],
                                subsector: [],
                                subsectors: [],
                                stage: [],
                                fundingMin: "",
                                fundingMax: "",
                                teamsizeMin: "",
                                teamsizeMax: "",
                                location: [],
                                yearMin: "",
                                yearMax: "",
                                matchingScoreMin: "",
                                matchingScoreMax: "",
                                regions: [],
                                search: "",
                                storageResultsFilterNameValue: "",
                                storageResultsFilterPage: newPage,
                            },
                        }),
                    );
                }

                if (Number(page) !== Number(newPage)) {
                    setPage(newPage);
                    let paramsArray = [];
                    const queryParams = new URLSearchParams(
                        window.location.search,
                    );
                    for (const [key, value] of queryParams) {
                        paramsArray.push({ key, value });
                    }
                    if (
                        window.location.search.includes("_gl") ||
                        window.location.search.includes("*_ga*")
                    ) {
                        return;
                        // Only page param in url
                    } else if (
                        paramsArray.filter(e => e.key === "page").length > 0 &&
                        paramsArray.length === 1
                    ) {
                        openPage(`${window.location.pathname}?page=${newPage}`);
                        // no page param, but with others
                    } else if (
                        paramsArray.filter(e => e.key === "page").length == 0 &&
                        paramsArray.length > 0
                    ) {
                        openPage(
                            `${window.location.pathname}${window.location.search}&page=${newPage}`,
                        );
                        // no params at all
                    } else if (
                        paramsArray.filter(e => e.key === "page").length == 0 &&
                        paramsArray.length === 0
                    ) {
                        openPage(`${window.location.pathname}?page=${newPage}`);
                        // Page param and others
                    } else if (
                        paramsArray.filter(e => e.key === "page").length > 0 &&
                        paramsArray.length > 1
                    ) {
                        let paramsString = "";
                        paramsArray.forEach((param, i) => {
                            paramsString = paramsString.concat(
                                `${i !== 0 ? "&" : "?"}${param.key}=${
                                    param.key === "page" ? newPage : param.value
                                }`,
                            );
                        });
                        openPage(`${window.location.pathname}${paramsString}`);
                    }
                }
            }
        }
    };
    // const [isFetching, setIsFetching] = useInfiniteScroll(debounce(moreData, 300), totalCount);

    const addItemToProjectModal = item => {
        let items = selectedItems;
        //TODO: check if company is already in array
        items.push(item);
        setSelectedItems(items);
    };

    useEffect(() => {
        (getMeStatus === REQUEST_SUCCESS || getMeStatus === REQUEST_FAILURE) &&
            setIsAuthed(isAuthenticated) &&
            setIsAuthed(isAuthenticated);
    }, [isAuthenticated, getMeStatus]);

    const deselectAll = () => {
        setSelectedItems([]);
    };

    useEffect(() => {
        mixpanelTrackEvent("Results");
    }, [dispatch]);

    useEffect(() => {
        setPageLoading(
            searchResultsPaginationStatus === REQUEST_PENDING ||
                searchResultsBySidPaginationStatus === REQUEST_PENDING,
        );
    }, [searchResultsPaginationStatus, searchResultsBySidPaginationStatus]);

    return {
        handleSelectingCardIndex,
        selectedCardIndex,
        isFetching: false,
        addItemToProjectModal,
        deselectAll,
        noResults,
        pageLoading,
        moreData,
    };
};

export default useResults;
