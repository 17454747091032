export const EnTranslation = {
    companyName: "Valuer",
    common: {
        loading: "Loading...",
        loadingButton: "Loading ",
        upgrade: "Upgrade",
        planExpired: "Your subscription has expired",
        freeTrialExpired: "Your free trial has expired",
        subscriptionExpired: "Your subscription has expired",
        beta: "BETA",
        downloadAsCSV: "Download as CSV",
        downloadEntireListAsCSV: "Download entire results list as CSV",
        downloadAsPDF: "Download as PDF",
    },
    card: {
        savedInLabel: "Saved in: ",
        orderReport: "Order report",
        seeReport: "See report",
        fullProfile: "Full Profile",
        fullList: "Full List",
        preview: "Preview",
        save: "Save",
        remove: "Remove",
    },
    getStarted: {
        title: "Let the search begin",
        backCta: "Back to valuer.ai",
        description:
            "Start search, and our AI will parse 20+ million data points to find you relevant companies and technologies.",
        descriptionHint: "Happy searching.",
        getStartedCta: "Get started",
        skipCta: "Skip search and ",
        haveProfile: "Already have a profile? ",
        signIn: "Sign in",
        signUpCta: "sign up",
    },
    outcome: {
        title: "Explore Industries and Technologies",
        findCompaniesTitle: "Company search", //mixpanel event name
        product2Title: "Search by Look-alike", //mixpanel event name
        product3Title: "Explore by Industry",
        product4Title: "Explore by Technology",
        product5Title: "Search by Patent Description",
        product4Label:
            "Get deeper insights into various technological fields and companies using these technologies.",
        product5Label:
            "Enter patent descriptions and find companies that work with similar technologies.",
        findCompaniesLabel:
            "Use your own words to search and find innovative companies. Our AI will do the rest.",
        sectorLabel:
            "Already have a company in mind? Enter it, and find others with similar characteristics.",
        trendsLabel:
            "Get your industry overview and uncover how companies and sub-industries intersect within any industry you're curious about.",
        previousStep: "Previous Step",
        nextStep: "Next Step",
        step1: "Outcome",
        step2: "Industry",
        step3: "Focus",
        step4: "Summary",
        comingSoon: "Coming soon",
        beta: "Beta",
        betaTooltipTitle: "Beta Version",
        betaTooltipText: "The look-alike feature is currently a Beta version.",
    },
    breadcrumbs: {
        name: "{{name}}",
    },
    dashboard: {
        textarea:
            "Use one or two full sentences to describe the technology you are looking for the problem you want to solve.",
        mainTitle: "Dashboard",
        quickOverviewTitle: "Quick Overview",
        dashboardOverview: "Dashboard Overview",
        dashboardOverviewSubtitle: `Here you will see companies that our AI finds close to your interests. You can set your preferences in <a href="/profile-settings">Profile & Settings</a>`,
        seeExamples: "See examples",
        aiSearch: "AI Search",
        lookalikes: "Look-alikes",
        lookalikeSearch: "Look-alike Search",
        dashboardSearch: {
            title: "Search for companies",
            tabs: {
                1: "Using specific technologies",
                2: "Look-alikes",
                3: "With similar patents",
            },
            technologies: {
                input: {
                    placeholder:
                        "Discover companies using specific technologies",
                },
                buttons: {
                    addIndustries: "+ Add industries",
                    addSubIndustries: "+ Add sub-industries",
                    search: "Search",
                },
            },
            lookAlikes: {},
            patent: {
                input: {
                    placeholder:
                        "Insert your patent's abstract here and search",
                },
                buttons: {
                    search: "Search",
                },
            },
        },
        smallCards: {
            projects: {
                title: "Projects",
            },
            likedCompanies: {
                title: "Liked companies",
            },
            myOrders: {
                title: "My Orders",
            },
            credits: {
                title: "Credits",
                footer: "Reset on, ",
            },
        },
        bigCards: {
            savedSearches: {
                title: "Saved Searches",
                subtitle: "Don't miss anything",
                subitems: {
                    status: {
                        pending: "Pending",
                        delivered: "Delivered",
                    },
                },
                footer: {
                    text: "All saved Searches can be seen on ",
                    link: "Saved Searches Page.",
                },
            },
            latestOrders: {
                title: "Latest Orders",
                subtitle: "Keep track of your company & technology reports",
                subitems: {
                    status: {
                        pending: "Pending",
                        delivered: "Delivered",
                    },
                },
                footer: {
                    text: "All reports are available on ",
                    link: "My Orders Page.",
                },
            },
            latestActivities: {
                title: "Latest Activities",
                subtitle: "See what your team has been doing",
                activityCodes: {
                    ADD_COMPANY_TO_RADAR: "New companies added to ",
                    COMPANY_VOTE_CREATED: " was added to Liked companies",
                    COMPANY_COMMENT_CREATED: "New comments added to company ",
                    COMPANY_ENRICHMENT_REQUEST_CREATED: "Requested Report of ",
                    TECHNOLOGY_ENRICHMENT_REQUEST_CREATED:
                        "Requested Deep dive of ",
                },
                subitems: {
                    enrichmentRequested: {
                        text: "Request Report of ",
                    },
                    companyAdded: {
                        text: "New companies added to ",
                    },
                    companyLiked: {
                        text: "Liked ",
                    },
                    commentsAdded: {
                        text: "New comment added to ",
                    },
                },
                footer: {
                    text: "All reports available on ",
                    link: "Latest Activities Page.",
                },
            },
            suggestedCompanies: {
                title: "Suggested companies",
            },
            popularCompanies: {
                title: "Popular companies",
            },
        },
        stepperCards: {
            suggestedCompanies: {
                title: "Suggested companies",
                desc1: 'Here you will see companies that our AI finds close to your interests. You can set your preferences in <a href="{{href}}">Profile & Settings</a>.',
                desc2: "If you choose industries in your search, our AI will catch on that and give you suggestions based on your latest search.",
            },
            popularCompanies: {
                title: "Popular companies",
            },
            needPlan:
                "Here you will see companies that our AI finds close to your interests. Too see this you should be on a paid plan or a free trial.",
        },
    },
    industry: {
        title: "Find companies that use specific technologies",
        addNewIndustry: "+ Add more industries",
        textareaPlaceholder: "What are you looking for?",
        subtitle:
            "Please enter a few - or more - lines of plain human text to describe the technology or the problem you want to solve. Or simply add bullet lists. Whatever works for you.",
        subtitle2:
            "Do not worry. There is no right or wrong way of doing it. Our AI will figure it out, it just needs a little something to work with. Also, you can always modify later on.",
        queryStrength: "Query strength: ",
        weakText: "If you need a bit of guidance, follow this bar",
        weak2Text: "Could you add a little more detail?",
        fairText:
            "Alright, this could work, but add a bit more if you can. Otherwise just go.",
        goodText:
            "Super input. Plenty of information. Feel free to add more details, but only if you insist and have more to add.",
        strongText: "Great. We have more than enough to work with.",
        queryExamples: "How others have done it",
        dialogTitle1: "See examples below",
        dialogTitle2: "See examples below",
        dialogTitle3:
            "If you're looking to discover new market trends, we suggest that your search should cover the locality of the market, relevant sectors/industries, technologies, and any other relevant information that could better hone your desired outcome.",
        dialogSubtitle1:
            "A good rule of thumb when interacting with your AI assistant is to think of the description as a conversational google search. It's ok to keep it simple and to the point. But it's also not a bad idea to get a bit more descriptive and in-depth",
        dialogSubtitle2:
            "A good rule of thumb when interacting with your AI assistant is to think of the description as a conversational google search. It's ok to keep it simple and to the point. But it's also not a bad idea to get a bit more descriptive and in-depth",
        dialogSubtitle3:
            "A good rule of thumb when interacting with your AI assistant is to think of the description as a conversational google search. It's ok to keep it simple and to the point. But it's also not a bad idea to get a bit more descriptive and in-depth",
        previous: "Previous",
        next: "Next",
        continue: "Continue",
        cancel: "Cancel",
        example10Title: "Example 1:",
        example20Title: "Example 2:",
        example30Title: "Example 3:",
        addIndustry: "Add industry",
        remove: "Remove",
        example10: `Energy trading decentralized blockchain platform as a tool to record and facilitate transactions between energy generators and consumers of energy, which includes use cases such as Peer-to-peer electricity trading, Management of renewable energy certificates, and automatic control of the grid management system.`,
        example11: `Companies developing E-payment solution systems that encompass the process of paying for goods and services and making transactions through an electronic payment medium while communicating and relaying information from customers’ credit or debit cards to both the sellers’ and buyers' bank accounts.`,
        example12: `Startups that are developing autonomous flying drone technology used in precision agriculture for smart crop spraying of fertilizer, herbicide, fungicide, and pesticide, as well as creating RGB and Normalized Difference Vegetation Index field maps.`,
        example13: `Companies that are developing human resources software as a digital solution for managing and optimizing the daily human resources tasks and overall HR goals of an organization including time scheduling, HR reporting, applicant tracking system, and employee benefits management system.`,
        example1Industry:
            "Startups that are developing agricultural technologies for sustainable food production by optimizing the use of water, pesticides, fertilizers, and other agricultural inputs to reduce the impact on the environment and save resources.",
        example1Technology:
            "I am looking for energy storage solutions that will allow me to store excess renewable energy, heat from industrial processes, or other energy sources and use it at a later time.",
        example11Title: "Example 4:",
        example21Title: "Example 5:",
        example20: `"Find technologies and sustainable solutions that target flooding and natural disasters related to water and floods"`,
        example21: `"IoT and Smart City applications targeting industrial buildings and offices to reduce energy consumption by retrofitting legacy systems with improved sensors and devices"`,
        example22: `"Looking for sustainable approaches to reusing or disposing of plastic that is unrecyclable."`,
        example30: `"Identify acquisition opportunities working with collaborative robotics solutions for warehouse and assembly lines to improve production output and reduce risks of injuries"`,
        example31: `"Looking to discover how startups are applying controllable grid interface technology in the offshore wind and solar energy sector"`,
        example32: `"Our corporation is interested in locating energy management startups in the EU specializing in waste-to-product sustainability."`,
        loremIpsum1:
            "Text example lorem ipsum dolor sit amet consectuetur edipiscing elit. Tell us about the type of technology you’re looking to find companies working. Text example lorem ipsum dolor sit amet consectuetur edipiscing elit. Tell us about the type of technology you’re looking to find companies working. Text example lorem ipsum dolor sit amet consectuetur edipiscing elit.",
        loremIpsum2:
            "Text example lorem ipsum dolor sit amet consectuetur edipiscing elit. Tell us about the type of technology you’re looking to find companies working. Text example lorem ipsum dolor sit amet consectuetur edipiscing elit. Tell us about the type of technology you’re looking to find companies working",
        skip: "No, just skip this step",
        search: "AI Search",
        textExamples: "Company Search Examples",
        useThisExample: "Use this search text as template",
    },
    descriptionSearch: {
        industryExampleTitle: "Industry Search Example",
        industryExampleSubtitle:
            "Startups that are developing agricultural technologies for sustainable food production by optimizing the use of water, pesticides, fertilizers, and other agricultural inputs to reduce the impact on the environment and save resources.",
        technologyExampleTitle: "Technology Search Example",
        technologyExampleSubtitle:
            "I am looking for energy storage solutions that will allow me to store excess renewable energy, heat from industrial processes, or other energy sources and use it at a later time.",
        useExample: "Use Example",
        industrySearchTitle1: "Start with a Detailed Description",
        industrySearchTitle2:
            "Describe what kind of companies you're looking for. Include specific details like technologies, products, use cases, etc.",
        needHelp: "Need Help?",
    },
    searchLookAlike: {
        title: "Enter the name of your Lookalike Company and get matching company results.",
        description:
            "Just type in the name of the company you’re looking for down below and search. If you’re unsure about the name of the company or can’t find what you’re looking for, click the link to the side to add more details. We’ll get you where you need to be, no worries.",
        textareaPlaceholder: "Type company name here",
        cta: "Find similar companies",
        cantFind: "Can’t find what you’re looking for? ",
        addMoreDetails: "Add more details.",
        companySelected: "Company selected",
        showingResults: "({{count}} result)",
        showingResults_plural: "({{count}} results)",
        placeholder:
            "Please write the name of a company you want to use as the reference to create your PUMA scope",
    },
    searchPatent: {
        title: "Find companies working on similar solutions to your patents",
        textareaPlaceholder:
            "Simply copy and paste your patent abstract here. If you don’t have it, just describe your patent technology in your own words.",
        searchButton: "Search",
    },
    addMoreItems: {
        title: "What’s the name of the company you’re looking for?",
        description:
            "Our AI needs just a few more details to get you on your way. Just add the company’s name and description of the company you’re looking for, which is anything from technologies they work with, the industry they’re in, or the size and scale of their operation. The company website helps, but it’s not necessary.",
        cta: "Find similar companies",
        websitePlaceholder: "Company website",
        descriptionPlaceholder: "Add company description",
        namePlaceholder: "Type company name here",
        descriptionLabel: "Company description",
        nameLabel: "Company name",
        backTo: "Back to company name only",
        optional: " (optional)",
        star: " *",
    },
    industriesAdded: {
        title: "Do you want to add sub-sectors to be more precise?",
        subtitle: "Add sub-sectors to your industries",
        addSubsectors: "Add sub-sectors",
    },
    industriesFound: {
        title: "We’ve found these industries. Is this correct?",
        subtitle: "Add sub-sectors to your industries",
        addMoreIndustries: "Add more industries",
        correct: "Correct",
    },
    subsectors: {
        title: "Alright. Would you like to target specific parts of the industries, you have selected? (optional)",
        subtitle:
            "You can add up to 5 sub-sectors to each industry. Selecting sub-sectors will give more focused results, but you may miss good company matches in unexpected industries.",
        industries: "Industries ",
        industriesCount: "({{num}}/5)",
        subsectors: "Sub-industries",
        saveChanges: "Add Industries",
        saveChanges2: "Save Settings",
        reset: "Reset All",
        edit: "Edit",
    },
    focus: {
        title: "Want to add more context? (optional)",
        selectFive:
            "Add up to 5 industries. Use this if you’re pretty certain that you know where to look. Choosing 1-2 industries can help reduce the number of search results to go trough.",
        sectorsDialogTitle:
            "Are you interested in any specific industries? (optional)",
        sectorsDialogSubtitle:
            "Choosing 1-2 industries can help reduce the number of search results to go trough.",
        sectorsDialogSubtitle2: "You can select up to 5 industries",
        dialogTitle: "Select relevant Sub-industries",
        dialogSubtitle: "You can select up to 5 Sub-industries",
        cannotFindSector: "Can’t find a specific sub-sector?",
        addField: "Add an industry field",
        cancel: "Cancel",
        submitSector: "Submit New Sub-Sector",
        addSubsector: "+ Add sub-industries",
        optional: "(optional)",
        addIndustryField: "Add an industry field",
        submitNewSubsector: "Submit New Sub-Sector",
        saveAndExit: "Save and Exit",
        addSubindustries: "Add Sub-industries",
    },
    summary: {
        title: "All good to go",
        subtitle:
            "We have all the information we need to let our AI do its thing",
        subtitle2: "Otherwise, just submit your request, when you’re ready.",
        subtitle3: "Let’s go over your request form one more time",
        yourText: "Your free text input below",
        submitRequest: "Start Search",
        tellUs: `Text example lorem ipsum dolor sit amet consectuetur edipiscing elit. Tell us about the type of technology you’re looking to find companies working with.`,
        startingQuotation: "“ ",
        endingQuotation: " ”",
        industry: "Industry: ",
        youAreTryingTo: "You’re trying to ",
        inThe: " using the following technologies:",
        areYouSure: "Are you sure about going back to valuer.ai webpage?",
        progressWillNotBeSaved:
            "Your progress will not be saved. You can not undo this action",
        backDialogCancel: "No, cancel",
        backDialogConfirm: "Yes, go back to valuer.ai",
        belowButton:
            "Psst - remember -> interact with the results and train the AI, if you want to improve results. It will work for you",
    },
    searchLoader: {
        title: "Parsing the knowledge graph to find matches",
        subtitleText: {
            0: "Analysing your request...",
            1: "Clustering our data points based on your search query...",
            2: "Looking for the most relevant companies in our database...",
            3: "Looking for unique combinations of technologies and business models...",
            4: "Evaluating company data using more than 30 different parameters...",
            5: "Applying Natural Language Processing to your search query...",
            6: "Transforming your request into ideal companies...",
            7: "Listing the companies that best match your needs...",
            8: "Clustering technologies and innovation…",
        },
        cancelRequest: "Cancel Request",
    },
    signUp: {
        illustrationTitlePart1: "Explore the ",
        illustrationTitle: "Want to unlock this feature?",
        illustrationTitlePart2: " for free",
        valuerPlatform: "Valuer platform",
        title: "Sign up and get started",
        subtitle: "Explore the Valuer platform for free",
        name: "Full name",
        title2: "Sign up right here",
        subtitle2: "The most of the platform? ",
        name2: "Full name",
        email: "Company e-mail",
        email2: "E-mail",
        password: "Password",
        confirmPassword: "Confirm password",
        companyName: "Company name",
        namePlaceholder: "Your name",
        emailPlaceholder: "Your company e-mail",
        emailPlaceholder2: "Your e-mail",
        passwordPlaceholder: "Password must be at least 8 characters long",
        confirmPasswordPlaceholder: "Please confirm password",
        companyNamePlaceholder: "Type your company name",
        createAccount: "Create account",
        startFreeTrial: "Start free trial",
        joinTheTeam: "Join the team",
        goToPayment: "Go to payment",
        errorMessage: "This is not a valid {{label}}",
        iAgreeLabel: "I agree to the ",
        termsAndService: "Terms of Service",
        and: " and ",
        privacyPolicy: "Privacy Policy*",
        alreadyHaveAnAccount: "Already have an account? ",
        signInHere: "Sign in",
        signInHere2: "Sign-in here.",
        skipSignUp: "Want to skip the sign-up for now? ",
        startSearch: "Start New Scope.",
        goBack: "Go back",
        exploreValuer: "Learn more about Valuer",
        weakPassword: "Must be 8 characters! {{count}} character left",
        weakPassword_plural: "Must be 8 characters! {{count}} characters left",
        passwordMustMatch: "Must match with Password input",
        minimum2Characters: "Minimum is 2 characters",
        noCardReq: "No credit card required",
        "14DayTrial": "14 day full trial",
        "8chars": "8 characters min.",
        "1uppercase": "1 uppercase",
        "1lowercase": "1 lowercase",
        youGetTitle: "You get 14 days free on Valuer.ai",
        youGetTitle2: "Sign up to Valuer.ai",
        youGetDesc1:
            "Get the full experience of Valuer’s services and start exploring a world of startups and technologies.",
        youGetDesc2: "No commitment or credit card required.",
        getCompleteReport:
            "Get a complete company report. We’ll give you 1 free credit to spend",
        useProjects:
            "Use our Projects features and stay up to date on your favorite companies",
        inviteTeamMembers: "Invite team members and start collaborating",
        seeHowCompaniesInteract:
            "See how companies interact in various industries ",
    },
    signIn: {
        title: "Sign-in to Valuer.ai",
        rememberMe: "Remember me",
        signIn: "Sign-in to your account",
        dontHaveAccount: "Don’t have an account yet? ",
        signUpHere: "Sign-up here.",
        skipSignIn: "Want to skip the sign-in for now? ",
        forgotPassword: "Forgot your password?",
        email: "Email",
        password: "Password",
        emailError: "This is not a valid email",
        passwordError: "This is not a valid password",
        bookADemo: "Book a demo",
    },
    forgotPassword: {
        title: "Forgot password",
        emailPlaceholder: "Enter email",
        cta: "Send reset password link",
    },
    resetPassword: {
        title: "Reset password",
        passwordPlaceholder: "Enter new password",
        passwordLabel: "New password",
        passwordConfirmPlaceholder: "Enter new password again",
        passwordConfirmLabel: "Confirm new password",
        cta: "Reset password",
        signIn: "Sign-in here",
    },
    resultsHeader: {
        refineSearch: "Refine search",
        filters: "Filters",
        sortBy: "Sort by",
        filterResults: "Filters",
        resultsTitle: "Results",
        lookAlikesTitle: "Companies look-alike search results",
        results: "results",
        sort: "Sort by",
        startSearch: "Start New Scope",
        settingsFilter: "filter",
        showingResultsPaginationLabel: "{{count}} result",
        showingResults100Label:
            "100+ results.",
        showingResultsPaginationLabel_plural:
            "({{count}} results. Page {{page}} out of {{totalPages}}.)",
        showingResultsLabel: "{{count}} result",
        showingResultsLabel_plural: "{{count}} results",
        editSearch: "Edit search",
        filterBtnTitle: "Filter",
        sortedBy: "Sorted",
        searchHistory: "Search history",
        copyClipboard: "Copied to clipboard",
        searchInputPlaceholder: "Explore your results",
        searchLookALikeTitle: "Companies look-alike",
    },
    sidebar: {
        startNewSearch: "Start new search",
        dashboard: "Dashboard",
        search: "Scope",
        workspace: "Workspace",
        results: "Deep Dive",
        savedSearches: "Saved Searches",
        searchHistory: "Search History",
        comingSoon: "Coming soon",
        trends: "Trends",
        technologies: "Technologies",
        clusters: "Clusters",
        resources: "Resources",
        helpCenter: "Help center",
        credits: "Credits",
        orders: "Orders",
        industries: "Industries",
        userNameExample: "Avatar",
        profileAndSettings: "Profile & settings",
        onboardingTour: "Onboarding tour",
        logOut: "Logout",
        appVersion: "v4.0",
        upgradeText: "Upgrade your account from free to single/team",
        upgradeCta: "Upgrade account",
        profile: "Profile",
        settings: "Settings",
        sendAnInvite: "Send an invite",
        next: "Next",
        back: "Back",
        startTrial: "Start 14-days free trial",
        sidebarItem: "sidebar item element",
    },
    topNav: {
        creditsLeft: "{{count}} credit left",
        creditsLeft_plural: "{{count}} credits left",
        creditsReplenish: "Credits replenish on {{date}}",
        upgradeYourPlan: "Upgrade your plan",
        getMore: "Get more credits",
        signIn: "Sign in",
        switchAccount: "Switch account",
    },
    createProjectModal: {
        modalTitle: "Create a new <strong>Project</strong>",
        button: "Create Project",
        projectTitle: "Add a title to your Project",
        description: "Add a description to your Project",
        cancel: "Cancel",
        projectTitlePlaceholder: "Enter project title",
        projectDescriptionPlaceholder: "Enter project description",
        editProject: "Edit Project",
        saveChanges: "Save Changes",
        maximumNumCharacters: "Maximum number of characters exceeded.",
        projectAlreadyExists: "This project already exists!",
    },
    results: {
        match: "{{number}}%",
        valuerScore: "Valuer Score",
        matchingScore: "Matching Score:",
        inceptionYear: "Year of inception",
        teamsize: "Team size",
        country: "Country",
        currentStage: "Current stage",
        fundings: "Funding",
        team: "Team",
        addToProject: "Add to project",
        companyDetails: "See Full Profile",
        isThisRecommendationUseful: "Do you like this company?",
        infoDescription:
            "The Valuer score is an objective ranking of a company that sums up different sub-scores the database calculates. Measured on a scale from 1 to 100, it reflects the company’s success, growth potential, maturity, innovativeness, and sustainability based on the available data and in comparison to the other companies in our database.",
        logoDescription:
            "The company logo is added as part of the full profile request",
        matchDescription:
            "The Match score calculates how much the company fits your search criteria. This means the score is dynamic and depends on your search wording.",
        readyForMore: "Want to see all the results?",
        instantAccess: "instant access",
        signUpAndGet: "Sign-up and get ",
        toAll: " to all ",
        thatMatch: "  that match your requirements.",
        signUpNow: "Sign up",
        noResults:
            "Oops... we didn't find anything that matches your search :(",
        noResultsSub: "Try to search for something more general",
        startNewSearch: "Start new search",
        appliedResults: "Applied filters",
        enrichedProfile: "Enriched Profile",
        scoreBreakdown: "Score Breakdown",
        companyAlreadyAdded: "Company already added to this project",
        signUpForOur: "Sign up for our",
        "14DaysFreeTrial": "14 days free trial",
        getInstantAccess:
            "Get instant access to all companies that match your requirements.",
        removeCompany: "Remove Company",
        areYouSure:
            "Are you sure you want to remove the {{type}} {{companyName}} from this project?",
        actionIrreversible: "This action is irreversible",
        yesRemove: "Yes, remove {{type}}",
        noTakeBack: "No, take me back",
        website: "Website",
        questionMark: "Question mark",
        like: "Like",
        download: "Download",
        dislike: "Dislike",
        companyComments: "Company comments",
        areYouSureRemoveProject:
            "Are you sure you want to remove {{projectName}} project?",
        yesRemoveProject: "Yes, remove project",
        noSearchesTitle: "You made it here. Welcome!",
        noResultsTitle: "Hmmm - no results found",
        noResultsSubtitle:
            "Likely there was a glitch. Maybe you were too specific. Try and revise your search again.",
        noSearchesSubtitle: "Now try your first search.",
        startSearch: "Get started",
        searchAgain: "Search again",
        leaveComment: "Leave A Comment",
        betaMessage:
            "Not happy with the results? The Look-alike search helps you discover similar companies based on a known company’s description. ",
        betaMessageLink:
            "Try changing the company description to get more relevant results",
        overview: "Data Overview",
        averageData: "Overview of data",
        yearOfInception: "Year of inception",
        topIndustries: "Top Industries",
        topIndustriesSubtitle: "Number of companies in top industries:",
        topLocationsSubtitle: "Number of companies in top locations:",
        topIndustriesPieChartTitle: "Industries",
        topLocations: "Top Locations",
        allCompanies: "All database companies",
        resetAll: "Reset all",
        yourSearch: "Your search",
        chartMin: "Min ",
        chartMax: "Max ",
        chartMedian: "Median ",
        company: "company",
        companies: "companies",
        averageDataInfoTooltip:
            "These charts represent aggregate data of the company results below. Click, drag and filter the data as you wish.",
        averageDataInfoTooltip2:
            "The grey bar charts represent distribution of data across all companies in the Valuer database.",
        unspecified: "Unspecified",
        noTeamSize:
            "Oops.. seems like the company results have no team size data available. Please try again by changing your search description.",
        goToPage: "Go to page:",
        back10Pages: "Backward 10 pages",
        forward10Page: "Forward 10 pages",
        naTooltip:
            "Use your credit to order an <strong>Report</strong> of this company profile, and our researchers will get to work to provide you with the most relevant and accurate data. ",
        project: "Project",
        createProject: "Create new",
        share: "Share",
        searchQueryOverview1: "Search Query",
        searchQueryOverview2:
            "To edit, save or share your search it with your co-workers, expand the Search Query using the downward arrow.",
        editQuery: "Edit",
        saveQuery: "Save Query",
        shareQuery: "Share Query",
        previewButton: "Preview Company",
        cancelEditButton: "Cancel",
        applyEditButton: "Apply Changes",
        total: "Total",
        shareQueryWarning: "You need to save the search first",
        recentSearches: "Recent searches:",
        currentSearch: "Current search",
        removeFromRecentSearchesTitle:
            "The search has not been saved and will be deleted.",
        removeFromRecentSearchesSubtitle:
            "Are you sure you want to delete this search?",
        deleteSearch: "Delete",
        saveSearchModalTitle: "Save this search query",
        saveSearchModalSubtitle:
            "In order to save this search, you need to name it first:",
        saveSearchInputLabel: "Add a name to this Search Query",
        saveSearchButton: "Save Search",
        saveSearchInputDescription:
            "After you save it, you will find the search in ",
        savedSearches: "Saved Searches",
        nameTheSearchToaster: "Great, your Search Query has been saved!",
    },
    previewModal: {
        contentText1:
            "You can add this company to a project so that you can quickly find and return to it after you leave this session.",
        contentText2:
            "Order a detailed report of this company, including current information about their business model, value proposition, executive team, product portfolio, market opportunities, and more.",
        contentHeader1: "Do you want to track this Profile?",
        contentHeader2: "Get more detailed information.",
        button1: "Add to Project",
        button2: "Request Report",
        year: "Year of inception:",
        companyStage: "Company stage:",
        fundings: "Fundings:",
        location: "Location:",
        teamSize: "Team size:",
        website: "Website:",
        match: "{{number}}% match",
        seeFullProfile: "See full profile",
        industries: "Industries:",
        sdgs: "Sustainable Development Goal:",
        subIndustries: "Sub-industries:",
        comments: "Comments:",
        commentsNumber: "{{count}} comment",
        commentsNumber_plural: "{{count}} comments",
        sdgsHide: "Enrichment to access this information",
        updated: "Updated",
        selectedCompanies: "Selected companies",
        relevantIndustries: "Relevant Industries:",
        relevantSubindustries: "Relevant Sub-industries:",
        queryCreated: "Query created",
        seeResults: "See Results",
    },
    profileSettings: {
        favoriteIndustries: {
            title: "I'm looking for:",
            subtitle: "(Settings for suggested companies)",
            button: "+ Add/Edit",
        },
        title: "Profile and Account Settings",
        accountTitle: "Manage your profile settings",
        accountSubtitle: "Profile Settings",
        accountDescription:
            "Get your team in on the action and change your settings here",
        notificationsTitle: "Notifications Center",
        notificationsSubtitle: "Manage Notifications",
        notificationsDescription:
            "Stay up-to-date and on the innovative project with notifications",
        billingTitle: "Billing",
        billingSubtitle: "Billing Subscription",
        billingDescription:
            "See and manage your subscription and billing settings",
        teamTitle: "Users/ Team",
        teamSubtitle: "Manage your Team",
        teamDescription:
            "Get your team in on the action. You can grant access to team members. Through your online portal.",
        uploadPicture: "Upload Picture",
        topUpCredits: "Top up credits",
        edit: "Edit",
        delete: "Delete",
        profile: "Profile",
        companyProfile: "Company profile",
        email: "Email",
        password: "Password",
        phoneNumber: "Phone number",
        phoneNumberOptional: "Phone number (optional)",
        changeName: "Change name",
        changePassword: "Change password",
        changeEmail: "Change email",
        changePhone: "Change phone number",
        companyName: "Company Name",
        country: "Country",
        city: "City",
        changeCountry: "Change country",
        changeCity: "Change city",
        cancel: "Cancel",
        saveChanges: "Save changes",
        maximumFileSize: "Maximum file size is 2Mb.",
        uploadError: "File is too large to upload. Maximum file size is 2Mb.",
        companyProfileSettingsTitle: "Company Profile Settings",
        noData: "This data is visible only to the Admin of your Organisation",
        warning: "Warning",
        nextInvoiceDate: "Next invoice issue date",
        upgrade: "Upgrade",
        upgradePlan: "Upgrade plan to {{plan}}",
        upgradeYourPlan: "Upgrade your plan",
        invitedUserUpgradeLabel:
            "Only the account owner can manage the subscription.",
        getNewSubscription: "Get a new subscription",
        seeCost: "See cost breakdown",
        usd: "USD ${{count}}",
        monthlyUsers: "Users (up to)",
        statusfailed: "Transaction failed",
        statusunpaid: "Pending...",
        statuspaid: "Paid",
        more: "More",
        deleteUser: "Delete User",
        deleteUserTitle: "Are you sure you want to delete {{param}}?",
        deleteUserDescription: "This action is irreversible.",
        deleteUserCta: "Yes, delete user",
        deleteUserCancel: "No, take me back",
        date: "Date",
        description: "Description",
        userSeats: "User Seats",
        invoiceTotal: "Plan cost",
        status: "Status",
        name: "Name",
        lastActive: "Last active",
        teamPermissions: "Team permissions",
        role: "Role",
        availableCredits: "Available credits",
        credits: "Credits",
        creditsDesc1: "Get your team in on the action.",
        creditsDesc2: "You can grant access to team members.",
        creditsDesc3: "Through your online portal.",
        seeCreditSummary: "See credit summary",
        costBreakdownTitle: "Cost Breakdown",
        costBreakdownSeats: "Users (up to)",
        costBreakdownCredits: "Credits / month",
        costBreakdownPlanMonthly: "Monthly plan pricing",
        costBreakdownPlanYearly: "Yearly plan pricing",
        costBreakdownTotalPrice: "Total Price:",
        na: "N/a",
        invoices: "Invoices",
        youAreOnFreeTrial: "You are on free trial plan",
        yourFreeTrialHasExpired: "Your free trial has expired",
        expireDate: "Expire date",
        leaveThisTeam: "(Leave this team)",
        leaveThisTeamOwnerModalTitle:
            "Oops, you cannot leave this account since you are the owner of this plan. Contact us if you wish to continue.",
        leaveThisTeamModalTitle: "Are you sure you want to leave this team?",
        switchTeam: "Switch team",
    },
    creditSummary: {
        title: "Credit summary",
        toGetMoreCredits: "To get more credits you can always ",
        getMoreCredits: "Get more credits",
        availableCredits: "Available credits",
        report: "Report",
        cost: "Cost",
        nextCreditsArrive: "Next credits arrive {{date}}",
    },
    history: {
        today: "Today",
        previous: "Previous",
        searchedOn: "Searched on",
        results: "Results",
        yourSearchText: "Search query",
        editSearch: "Edit search",
        viewResults: "View results",
        searchThroughHistory: "Search through history",
        sortBy: "Sort by",
        createNewSearch: "Start New Scope",
        searchHistory: "Search history",
        resultsNum: "{{num}} results",
        freeUserTitle: "Sorry, no search history",
        freeUserSubtitle:
            "So either you have not performed a search yet. Or, more likely; You have a free subscription. In that case: Upgrade and unlock your search history.",
        upgradeSubscription: "Upgrade",
        noSearchesDone: "There are no saved searches",
        noResultsFound: "There are no results found",
        startASearch: "Start a search",
        showIndustries: "Show industries",
        hideIndustries: "Hide industries",
        edit: "Edit",
        more: "More",
        yourPlanEnded:
            "Your plan has expired. Your searches will not be saved anymore in the search history. You can not edit your previous searches or view the results. Upgrade your plan and unlock this feature instantly.",
        bannerText:
            "We will soon replace Search History with Saved Searches. Please remember to save your favorite searches from the search history using the save search feature on the search results page. Search History will be removed on the 8th of November.",
    },
    savedSearches: {
        today: "Today",
        previous: "Previous",
        searchedOn: "Searched on",
        results: "Results",
        yourSearchText: "Your search text input",
        editSearch: "Edit search",
        viewResults: "View results",
        searchThroughHistory: "Search through history",
        sortBy: "Sort by",
        createNewSearch: "Start New Scope",
        title: "Saved Searches",
        resultsNum: "{{num}} results",
        description:
            "Here you can find the searches you actively chose to save from your previous sessions.",
        freeUserTitle: "Sorry, no search history",
        freeUserSubtitle:
            "So either you have not performed a search yet. Or, more likely; You have a free subscription. In that case: Upgrade and unlock your search history.",
        upgradeSubscription: "Upgrade",
        noSearchesDone: "There are no saved searches",
        noResultsFound: "There are no results found",
        startASearch: "Start a search",
        showIndustries: "Show industries",
        hideIndustries: "Hide industries",
        edit: "Edit",
        more: "More",
        yourPlanEnded:
            "Your plan has expired. Your searches will not be saved anymore in the search history. You can not edit your previous searches or view the results. Upgrade your plan and unlock this feature instantly.",
        lookAlike: "Look-alike",
        findSpecificCompany: "Find Specific Company",
        yearOfInception: "Year of inception",
        companyStage: "Company stage",
        fundings: "Fundings",
        website: "Website",
        teamSize: "Team size",
        region: "Region",
        shareAction: "Share Search",
        deleteAction: "Delete Search",
        deleteTitle: "Are you sure you want to delete this search?",
        deleteSubtitle: "This action is irreversible.",
        cancel: "Cancel",
        unspecified: "Unspecified",
    },
    noResults: {
        project: {
            projectNoResultsBodyTextTitle: "You have no profiles saved",
            projectNoResultsBodyTextSubtitle:
                "Start a search in and find interesting profiles and add them here in order to keep track of your profiles.",
            startSearchButton: "Start Ai Search",
        },
    },
    addCompanyModal: {
        selected: "You have selected {{count}} company",
        selected_plural: "You have selected {{count}} companies",
        deselectAll: "Deselect all",
        addToProject: "Add to project",
        signUpNow: "Sign-up now",
    },
    projectHeader: {
        createProject: "Create new project",
        title: "My Workspace",
    },
    projects: {
        activeProjects: "Active projects",
        inactiveProjects: "Inactive projects",
        projectAverageScore: "Project Average Score",
        editProject: "Edit project",
        deleteProject: "Delete project",
        deactivateProject: "Deactivate project",
        companiesInProject: "Saved elements in this Project",
        reactivateProject: "Reactivate this Project",
        noActiveProjects: "There are no active projects",
        noProjectsHint:
            "You can create your first project to get updates about the companies that are part of that project.",
        createNewProject: "Create new project",
        projectStatus: "Project Status:",
        active: "Active",
        paused: "Paused",
        freeUserTitle: "Project(s)",
        freeUserSubtitle:
            "No Projects yet. Upgrade to unlock your AI-driven hawkeye.",
        noProjectsTitle: "Ready to set up your first project?",
        noProjectsSubtitle:
            "Think of it as your own AI hawkeye discovery tool. Eyes everywhere.",
        setUpProject: "Set up project",
        upgradeSubscription: "Upgrade",
        toAddMore: " to add more projects.",
        yourPlanEnded:
            "Your plan has ended. You can only view the companies in your project. You are not able to create new projects or add new companies. Get paid subscription now and get back full access to projects.",
        talkToSales: "Talk to sales",
        upgrade: "Upgrade plan",
        removeFromProject: "Remove from Project",
        butOfCourse:
            "But of course you can still find all the elements in the application",
    },
    filterModal: {
        filtersTitle: "Search Results Filter",
        resetButton: "Clear all",
        show: "Show",
        results: "results",
        sectorFilter: "Industry",
        subsectorFilter: "Sub-industry",
        stageFilter: "Investment Stage",
        fundingFilter: "Funding amount",
        teamFilter: "Team size",
        locationFilter: "Country",
        regionFilter: "Region",
        yearFilter: "Year of inception",
        matchingScoreFilter: "Matching score",
        closeX: "close",
        upgradeYourSubscription: "your subscription to unlock this feature.",
        clear: "Clear",
        selectedFilters: "Selected filters",
    },
    companyProfile: {
        headerMenu: {
            share: "Share",
            project: {
                add: "Add to project",
                create: "Create new project",
            },
            download: "Download as CSV",
        },
        enrichmentBanner: {
            title: {
                guest: "Interesting company, right?",
                expired: "Interesting company, right?",
                paying: "Want to learn more?",
                underEnrichment: "",
            },
            description: {
                guest: 'By signing up for a <a href="https://app.valuer.ai/sign-up">free Valuer trial</a>, you can kick start our in-house team of professional analysts to provide you with a current and accurate report of this company.',
                expired:
                    '<a href="https://www.valuer.ai/pricing">Upgrade Subscription</a> to order a research report containing analysis on product portfolio, business model, value prop, and much more.',
                paying: "Order a company report to unlock the full company profile.",
                underEnrichment:
                    'The company is currently under the report process. You can follow up its status on <a target="_blank" href="{{href}}">Orders</a> page.',
            },
            button: {
                guest: "",
                expired: "",
                paying: "Request Report",
                underEnrichment: "",
            },
            link: {
                guest: '<a target="_blank" href="{{href}}">Here you can preview how a full company profile looks.</a>',
                expired:
                    '<a target="_blank" href="{{href}}">Here you can preview how a full company profile looks.</a>',
                paying: '<a target="_blank" href="{{href}}">Here you can preview how a full company profile looks.</a>',
                underEnrichment: "",
            },
        },
        executiveEnrichmentBanner: {
            title: "Executive team",
            description:
                "When you upgrade, you’ll get your hands on Executive Teams. This tab grants you access to the information you need to know when it comes to the executive team of a particular company. With this, you’ll get to see who is who and their specific role within a company, paired with background information and any other relevant information.",
        },
        successEnrichmentBanner: {
            funding: {
                title: "Funding",
                description:
                    "When you unlock Funding you’ll instantly get access to up-to-date information on the current investment round a company is in, how much money they received in each round, securing date, and who the investors were. This, in total, paired with the total funding amount, gives you a pretty practical overview of where a company is at.",
            },
            customers: {
                title: "Customers",
                description:
                    "Here it's pretty straightforward. With Customers, you will get access to who the biggest customers of a particular company are plus their website addresses so you can slip further down the innovative rabbit hole.",
            },
        },
        business_model: {
            title: "Business model",
            description:
                "This section explains how a company operates in a business context, its target customers, and how it generates revenue.",
        },
        value_proposition: {
            title: "Value proposition",
            description:
                "Here, you can learn the core values that the company promises to deliver to customers through its products, expertise, and services.",
        },
        product_portfolio: {
            title: "Product Portfolio",
            description:
                "This section contains an in-depth overview of the company’s product portfolio, summarised in an easily digestible way.",
        },
        market_opportunities: {
            title: "Market opportunities",
            description:
                "Here, you will learn how the company’s target market is expected to grow and what are the market’s most important growth drivers and key global players.",
        },
        achievements: {
            title: "Achievements",
            description:
                "This section summarizes the company’s awards, accomplishments, and other significant milestones.",
        },
        sustainability: {
            title: "Sustainability",
            description:
                "This section summarizes the company’s awards, accomplishments, and other significant milestones.",
        },
        backToResults: "Go back",
        getShareableLink: "Share",
        shareWarning:
            "Warning, anyone with access to this link will be able to access this page.",
        copiedToClipboard: "Copied to clipboard",
        companyLogoInfo:
            "The company logo is added as part of the full profile request",
        match: "match",
        matchScoreText:
            "The Match score calculates how much the company fits your search criteria. This means the score is dynamic and depends on your search wording.",
        matchScoreTextTechnologies:
            "The Match score calculates how much the company fits the technology description.",
        valuerScoreInfo:
            "The Valuer score is an objective ranking of a company that sums up different sub-scores the database calculates. Measured on a scale from 1 to 100, it reflects the company’s success, growth potential, maturity, innovativeness, and sustainability based on the available data and in comparison to the other companies in our database.",
        valuerScore: "Valuer Score",
        website: "Website",
        companySize: "Team size",
        companyStage: "Company stage",
        fundingRaised: "Funding",
        location: "Location",
        sectors: "Industries",
        subsectors: "Sub-industries",
        currentStage: "Company stage",
        enrichmentInProgress: "Enrichment in progress",
        technologies: "Technologies",
        dataCompleteness: "Data completeness",
        dataCompletenessInfo:
            "The Data Completeness score communicates how much data our database currently has about the company.  An enriched company will have a higher completeness score since more fields have been evaluated.",
        profile: "Profile",
        executiveTeam: "Executive Team",
        success: "Success",
        comments: "Comments",
        businessModel: "Business Model",
        valueProposition: "Value Proposition",
        productPortfolio: "Product Portfolio",
        technologyOverview: "Technology Overview",
        marketOpportunities: "Market Opportunities",
        companyProfileIsLocked: "Want to dig deeper?",
        enrichingTitle: "Want to dig deeper?",
        status: "Status:",
        requestSent: "Request Sent",
        availableText: "Available within 1 working week",
        unlockAccess: "Submit research request to get bespoke insights",
        requestFullProfile: "Submit research request",
        orderAnalystResearchPaper: "Order company report",
        requestEnrichment: "Request Report",
        cancel: "Cancel",
        done: "Done",
        thisWillCost: "This will cost {{count}} credit.",
        thisWillCost__plural: "This will cost {{count}} credits.",
        youHaveCredits: "Currently, you have {{count}} credit.",
        youHaveCredits__plural: "Currently, you have {{count}} credits.",
        analystsCanPrepare: "The report will be ready in 5 workdays.",
        enrichRequestModalFooter: {
            text: "Any questions?",
            link: "Talk to sales.",
        },
        enrichedLabel: "Enriched Profile",
        weReceivedOrder: "You ordered a company report",
        weSentConfirmation:
            "We will notify you when it’s ready.\n" +
            "In the meantime, you can follow the progress in <a target='_blank' href={{href}}>Orders</a>.",
        wellSendEmail:
            "... and will receive one again when your data is ready.",
        dontHaveEnoughCreditsTitle: "You do not have enough credits",
        youCan: "You can ",
        topUp: "top up",
        toComplete: " your credits to complete the order.",
        topUpCredits: "Top up credits",
        numberOfCredits: "Number of credits",
        buyNow: "Buy now",
        vatAdded: "Vat will be added as applicable",
        alwaysRunning: "Always running out of credits? ",
        upgradeYourPlan: "Upgrade your plan",
        price: "Price:",
        eur: "{{count}} EUR",
        seeMore: "See more",
        scoreBreakdown: "See Score Breakdown",
        breakDownScoreTitle: "Breakdown Of The Valuer Score",
        successScore: "Success",
        successGrowth: "Growth",
        successMaturity: "Maturity",
        successInnovation: "Innovation",
        successSustainability: "Sustainability",
        successTooltipText: `The Success score uses company data, including its
								funding history, age, and size, to calculate how
								successful it currently is.`,
        growthTooltipText: `The Growth score uses a combination of data, including
								the company’s development and funding history, to
								forecast how likely the company is to grow in the
								future.`,
        maturityTooltipText: `The Maturity score uses a combination of data, including
								a company’s development stage, funding history, and size
								to reflect how self-sustaining it currently is.`,
        innovationTooltipText: `The Innovation score reflects how unique and original a
								company’s approach is at addressing an issue or a market
								gap compared to its peers.`,
        sustainabilityTooltipText: `The Sustainability score reflects the scale at which a
								company’s products and services can be used to make
								business operations more sustainable.`,
        100: "/ 100",
        sdg: "SDG",
        sustainableDevelopmentGoals: "Sustainable Development Goal",
        thisCompanyWorksTowards: "This company works towards",
        mlResultShareContent: "This link is no longer available.",
        unlockItToAccessSdg: "Order report to access this information ",
        tags: "Tags",
        addNewTag: "Add new tag",
        enterNewTagName: "Enter new tag name",
        errorNewTagEmpty: "Field is empty",
        errorNewTagMinLength: "The tag name must be at least 2 characters.",
        removeTag: "Remove tag",
        requestReport: "Request Report",
        addToProject: "Add to project",
        yearOfInception: "Year of inception",
        readMore: "Read more",
        goToNext: "Go to next ({{name}})",
        like: "Like",
        disLike: "Dislike",
        freeUserTitle:
            "Interesting company, right? Upgrade your subscription to order bespoke research and further analysis on product/service model, business model, value prop, and much more.",
        freeUserSubtitle: "You are just a few steps away",
        toOrder:
            "to order a research report containing analysis on product portfolio, business model, value prop, and much more.",
        wantToDig: "Want to dig deeper?",
        submit: "Submit a research request",
        researchRequest:
            "(1 credit) to receive the full research report on this company.",
        whatWillIGet: "What will I get?",
        businessModelDesc:
            "Business models let you know how the inner engine of a company is running. By upgrading your Valuer subscription, you get immediate access to data and detailed descriptions of a company’s business model. So you can determine how they’re operating and where exactly they're headed.",
        valuePropositionDesc:
            "By upgrading your subscription you can discover the Value Proposition of a company right here in this tab. Don’t judge a book by its cover, instead find out what a company promises to deliver to its customers through products, experience, communication, services, etc. See where a company’s core values lie and determine if this company is the right fit for your needs.",
        productPortfolioDesc:
            "Get to the bread and butter of a company through its product portfolio. Here you can get an in-depth overview of what a company is developing and delivering. This is where the final product of a company’s innovation gets put on display and packaged in an easy-to-digest way. To get you the information you need, while cutting the unnecessaries.",
        technologyOverviewDesc:
            "This is where the magic happens. Jump down the technological rabbit hole and discover what makes up the backbone of innovative companies. Get extensive data and information on the technology that makes a company tick and see for yourself how any company you’re interested in can develop, deliver, and create their products and solutions.",
        marketOpportunitiesDesc:
            "Discover the launchpad for any company through our Market Opportunities. Here you’ll find information on where a company is gaining traction and carving out its own position in the market. Here you can gain access to information and hard numbers from various reports, market projections, and any other relevant information.",
        futurePlansDesc:
            "Blast off. Here you will find information on what a company has achieved and where it's headed. Find out what a company’s plan is for the future and the figures related to its investment round, maturity, milestones, accolades, and much more.",
        businessModelTitle: "Business model",
        valuePropositionTitle: "Value Proposition",
        productPortfolioTitle: "Product Portfolio",
        technologyOverviewTitle: "Technology Overview",
        marketOpportunitiesTitle: "Market opportunities",
        futurePlansTitle: "Achievements and Future Plans",
        upgradeSubscription: "Upgrade Subscription",
        commentsTitle: "Comments",
        commentsDesc:
            "What you get here when you upgrade your Valuer subscription is the ability to leave a comment on any company’s profile. Leave a comment for yourself or leave one for your team; it’s up to you.",
        executiveTeamLockDesc:
            "When you upgrade, you’ll get your hands on Executive Teams. This tab grants you access to the information you need to know when it comes to the executive team of a particular company. With this, you’ll get to see who is who and their specific role within a company, paired with background information and any other relevant information.",
        fundingTitle: "Funding",
        fundingDesc:
            "When you unlock Funding you’ll instantly get access to up-to-date information on the current investment round a company is in, how much money they received in each round, securing date, and who the investors were. This, in total, paired with the total funding amount, gives you a pretty practical overview of where a company is at.",
        customersTitle: "Customers",
        customersDesc:
            "Here it's pretty straightforward. With Customers, you will get access to who the biggest customers of a particular company are plus their website addresses so you can slip further down the innovative rabbit hole.",
        industriesLabel: "Industries:",
        showMore: "Show more",
        showLess: "Show less",
        seeLookALike: "See look-alikes",
    },
    companySuccess: {
        funding: "Funding",
        totalFunding: "Total funding",
        customers: "Customers",
        identified: "identified customers",
        secured: "Secured on",
        amount: "Funding amount",
        securingDate: "Securing date",
        fundingType: "Funding Type",
        investors: "Investors",
    },
    companyComments: {
        allComments: "All Comments",
        addYourComments: "Add your comment here...",
        noComments: "There are no comments yet",
        commentsSubtitle: "You can start by adding your own",
        edit: "Edit",
        reply: "Reply",
        delete: "Delete",
        save: "Save",
        cancel: "Cancel",
        areYouSure: "Are you sure?",
    },
    projectProfile: {
        projectAverageScore: "Project Average Score",
        projectStarted: "Project Started",
        industries: "Top Industries",
        projectStatus: "Project status:",
        deleteCompany: "Delete company",
        enrichCompany: "Enrich company",
        goBack: "Back to My Workspace",
        deleteProject: "Delete",
        editProject: "Edit",
        share: "Share Project",
        "100score": "/100",
        points: "points",
        active: "Active",
        disabled: "Disabled",
        companies: "Companies",
        requestReport: "Request Analyst report",
        "100Points": "/100 points",
        numIndustries: "{{num}} industries",
        industry: "Industry",
        emptyStatePage: {
            updatedAt: "Last time edited:",
        },
    },
    enrichRequest: {
        title: "Want to dig deeper?",
        subtitle: "Submit research request to get bespoke insights",
        requestFullProfile: "Submit research request",
        createProject: "Create Project",
        requestSent: "Request Sent",
        requestSentSubtitle:
            "The full company profile will be available in 7 days. You will receive an email notification when it is ready.",
        noCreatedProjects: "There are no created projects.",
        cancel: "Cancel",
    },
    inviteUsersModal: {
        title: "Invite users",
        subtitle:
            "You can invite multiple coworkers at the same time by hitting 'enter' after each email.",
        shareTitle: "Share this with your co-workers",
        cancel: "Cancel",
        invite: "Send invitation",
        youHave: "You have",
        usersNum: "{{count}} user",
        usersNum_plural: "{{count}} users",
        selected: "selected for invite",
        emailExists: "All provided emails already used for invitations.",
        shareText:
            "Sharing this query with users that are signed in will give them full access to your results. Users that are signed out will have <strong>read-only</strong> access.",
        copyShareLink: "Copy link",
    },
    subscribeConfirmModal: {
        title: "You are about to proceed to the subscription of this plan: ",
        cancel: "Go back",
        confirm: "Confirm",
        priceLabel: "Price:",
        single: "Single User",
        pro: "Pro",
        teams: "Teams",
        switchCurrency: "Switch currency",
        "pay-as-you-go": "Pay as you go",
        subtitle: "For subscriptions with more than one user, please ",
        subtitleLink: "talk to our team.",
        price: "Price: {{price}}, per user, per {{interval}}.",
        interval: {
            month: "Monthly",
            year: "Yearly",
        },
        currency: {
            eur: "EUR",
            usd: "USD",
        },
    },
    subscriptionSuccessfulModal: {
        title: "You've succesfully upgraded your subscription to {{subscriptionName}}!",
        description:
            "Looks like you successfully upgraded your subscription, that's pretty awesome. Now get ready and enjoy your access to all the new features our platform has to offer, placing the power of innovation and curiosity into your hands.",
        cta: "Continue back to the app",
    },
    notFound: {
        title: "Hmm, this is not the page you are looking for.",
        goToHomepage: "Take me back to homepage",
    },
    mlFailure: {
        title: "An error occurred, please try again later",
        timeoutTitle: "Our servers are busy, please try again later",
        companyNotFoundTitle: "Company not found, please try again later",
        goToHomepage: "Take me back to homepage",
    },
    warningModal: {
        goBackToSearch: "Are you sure about going back to search request page?",
        goBackToValuerAI: "Are you sure about going back to valuer.ai webpage?",
        progressWontBeSaved:
            "Your progress will not be saved. You can not undo this action",
        noCancel: "No, cancel",
        yesGoBack: "Yes, go back to search",
        yesGoBackAI: "Yes, go back to valuer.ai",
        cancel: "Cancel",
        buyCredits: "Buy credits",
        upgradeSubscription: "Upgrade subscription",
    },
    comingSoon: {
        title: "We are turning the app upside down",
        subtitle: "Please wait patiently for the magic to happen",
        days: "Days",
        hours: "Hours",
        minutes: "Minutes",
        seconds: "Seconds",
    },
    technologies: {
        technologies: "Technologies",
        suggestTechnology: "Suggest technology",
        industry: "Industry",
        subIndustry: "Sub-industry",
        searchForTechnologies: "Search for technologies",
        cantFind: "Can't find what you are looking for?",
        youCanSuggest:
            "You can suggest a technology profile that we can look into and add in the future to our platform.",
        goBack: "Go back",
        requestReport: "Request Report",
        share: "Share",
        wantToSee: "Want to see the remaining companies?",
        signUp: "Sign up to unlock companies.",
        headerMenu: {
            share: "Share technology",
            download: "Download as CSV",
        },
        suggestTechnologyTitle: "Suggest a technology",
        technologyName: "Technology name",
        technologyDescription: "Technology description",
        send: "Send",
        cancel: "Cancel",
        suggestTechnologyThankYou:
            "Thank you for adding your input and suggesting a technology for our platform! Our researchers will get back to you soon.",
        searchPlaceholder: "Explore technologies",
    },
    technologyCard: {
        count: "({{count}} companies)",
    },
    technologyProfile: {
        enrichmentBanner: {
            title: {
                guest: "Go deeper. For free!",
                expired: "Need a deeper dive than this?",
                paying: "Want to learn more?",
                underEnrichment: "",
            },
            description: {
                guest: {
                    text1: "By signing up for a ",
                    text2: " you can kick start our in-house team of professional analysts to provide you with a current and accurate report of this technology.",
                    link: " free Valuer trial",
                },
                expired:
                    'By <a href="{{href}}">upgrading your subscription</a>, you can kick start our in-house team of professional analysts to provide you with a current and accurate report of this technology.',
                paying: "Order a technology report to discover even more insights and opportunities.",
                underEnrichment:
                    'Our analysts are currently developing your technology report. Follow the progress on your <a target="_blank" href="{{href}}">Orders</a> page.',
            },
            button: {
                guest: "",
                expired: "",
                paying: "Request report",
                underEnrichment: "",
            },
            link: {
                guest: '<a target="_blank" href="{{href}}">Get a preview of what a finalized technology report looks.</a>',
                expired:
                    '<a target="_blank" href="{{href}}">Get a preview of what a finalized technology report looks.</a>',
                paying: '<a target="_blank" href="{{href}}">Get a preview of what a finalized technology report looks.</a>',
                underEnrichment: "",
            },
        },

        relevantIndustries: "Relevant industries",
        relevantSubindustries: "Relevant sub-industries",
        overview: "Technology Reports",
        companies: "Companies using this technology",
        numCompanies: "{{number}} companies",
        sortedBy: "Sorted by",
        filters: "Filters",
        technologyReportCost: "This technology report will cost 1 credit",
        weReceivedOrder: "You ordered a technology report",
        ourAnalyst: "Our analyst can prepare your report in 2-4 business days.",
    },
    technologyOverview: {
        detailed_description: {
            title: "Description",
            description:
                "This section summarizes all essential information about the technology. To achieve this, our team of technology experts digests complex concepts into understandable words.",
        },
        applications: {
            title: "Applications",
            description:
                "Here you can learn how different industries or verticals apply the technology in real-world scenarios. This can help you visualize how the technology could fit your needs.",
        },
        benefits: {
            title: "Benefits",
            description:
                "Want to find out how this technology can help companies improve operations or alleviate pains? This section will help you learn about the potential benefits from using this technology.",
        },
        maturity: {
            title: "Maturity",
            description:
                "How developed is this technology? This section will help you understand if the technology has been around for some time or is still in its nascent stage and has some pains to iron out.",
        },
        market_opportunities: {
            title: "Market Opportunities",
            description:
                "This section contains intel on the technology’s expected developments and market predictions.",
        },
        sustainability: {
            title: "Sustainability",
            description:
                "Can this technology help your company become more sustainable? Here you can learn if the technology can help you align business operations with sustainability regulations.",
        },
        submitResearchRequest: "Submit research request",
        orderTechnologyReport: "Order technology report",
        technologyOrderCost: "This will cost 1 credit.",
        youHaveCreditsLeft: "Currently you have {{creditsLeft}} credits.",
        ourAnalystPrepare: "The report will be ready in 5 workdays.",
        cancel: "Cancel",
        orderReport: "Request Report",
        upgradeSubscription: "Upgrade subscription",
    },
    industryOverview: {
        submitResearchRequest: "Submit research request",
        orderTechnologyReport: "Order industry report",
        industryOrderCost: "This will cost 1 credit.",
        youHaveCreditsLeft: "Currently you have {{creditsLeft}} credits.",
        ourAnalystPrepare: "The report will be ready in 5 workdays.",
        cancel: "Cancel",
        orderReport: "Request Report",
        upgradeSubscription: "Upgrade subscription",
    },
    industries: {
        title: "Relevant industries",
        searchBtn: "Search in Industries",
        seeSubindustries: "See sub-industries",
        seeIndustries: "See industries",
        seeTechnologies: "See technologies",
        subIndustries: "Relevant sub-industries",
    },
    industryProfile: {
        goBack: "Go back",
        industryDescription: "Industry Description",
        industries: "Industries",
        technologies: "Technologies",
        subindustries: "Sub-industries",
        description: "Description",
        clusterDesc:
            "Simply put, a cluster is a visual representation of the companies within the target area you are browsing, their positions relative to each other is calculated by our AI. In this case, the cluster of dots is the visual overview of a particular industry, with each dot representing a company and each color representing a sub-industry. The position of the dots/companies is based on their similarity to all other companies on the map. If two companies are very close, then they are suggested to be very similar. Ok, just one last thing, if you see a multi-colored dot don’t worry this only means that this particular company is an overachiever and can be found in multiple different sub-industries. Hope that clears things up!",
        loremIpsum:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, cras congue euismod justo et ullamcorper, nulla quis nunc fermentum, congue ante et, mattis lacus. Lorem ipsum dolor sit amet, consectetur adipiscing elit, cras congue euismod justo et ullamcorper, nulla quis nunc fermentum, congue ante et, mattis lacus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        openCompanyProfile: "See profile",
        whatsaCluster: "What's a Cluster?",
        clusterDescription: "Cluster Description",
        subIndustryDefinitions: "Sub-industry Definitions",
        subIndustryDefinitionsLabel: "See how we define the sub-industries",
        subsectorExampleDesc:
            "Travel accomodation refers to the renting of apartments or hotel rooms using services which use machine learning and big data in order to provide high quality lodging and and accomodation to tourists and travellers.",
        readDefinitions: "Read definitions",
        share: "Share",
        noCompanies: "No companies in this subsector",
        wantToSee: "Want to see the companies?",
        signUpToGet: "Sign up for our 14-days free trial",
        startFreeTrial: "Start free trial",
        noCreditCard: "*No credit card required. ",
        forCustomTrials: "For custom trials, ",
        getAccess: "Get instant access.",
        unselectAll: "Unselect All",
        readMore: "Read more",
        showLess: "Show less",
        addToProject: "Add to project",
        previewCluster: "Preview Cluster map for desktop",
        companiesTitle: "Companies in selected sub-industries",
        likedCompaniesTitle: "Liked companies",
        companiesResultsNum:
            "({{num}} results. Page {{page}} out of {{totalPages}}.)",
        clusterMapHelpText:
            "Open the <strong>Cluster Map</strong> and get an overview of all the startups.",
        exploreClusterMap: "Explore the Cluster Map",
        seeClusterMapTooltip:
            "A cluster map is a visual representation of the companies in a sub-industry where their positions relative to each other is calculated by our AI. Each dot represents one company, and the more similar it is to other companies, the closer it appears to them. If you see a multi-colored dot, don’t worry, this only means that this company is an overachiever and can be found in multiple sub-industries.",
    },
    upgradeProjects: {
        readyForMore: "Ready for more features",
        upgradeYourAccount:
            "Upgrade your account now to get instant access to the Projects feature",
        upgradeSubscription: "Upgrade Subscription",
    },
    toasters: {
        companyAddedToProject:
            "Success! {{companyName}} has been added to your project {{projectName}}",
        companyRemovedFromProject:
            "Success! {{companyName}} has been removed from your project {{projectName}}",
        newMonth: "Need a top up? It's a new month, this means new credits.",
        projectDeleted:
            "Your project {{projectName}} has been successfully deleted.",
        projectCreated:
            "Congrats! Your project {{projectName}} has been successfully created.",
        projectUpdated:
            "Congrats! Your project {{projectName}} has been successfully updated.",
        teammemberDeleted: "Team member has been succesfully deleted",
        invitationDeleted: "Invite has been succesfully deleted",
        inviteDeleted: "Invite has been deleted",
        creditsFallenToZero:
            "Watch out! Your credit balance has fallen to zero. ",
        topUpCredits: "Top up credits here.",
        upgradeSubscription: "Upgrade subscription",
        toGoFurther: " to go further.",
        invitesLimitReached:
            "Notice! You've reached the max amount of invites on your project.",
        companyAlreadyAdded:
            "Oops! This company has already been added to the project.",
        topppedUpCredits:
            "Nice job. Your account has been successfully topped up with more credits.",
        reportOrdered:
            "Success. Your report has been ordered. Now sit back and relax.",
        teamMemberInvited: "Success! The invitation has been sent.",
        teamMemberLeft:
            "Success! You left the team. The page will reload shortly.",
        emailChangeSuccess: "Email successfully changed.",
        emailValidationSent: "Validation email sent successfully.",
        emailValidated: "Your email was validated",
        downloadingDocument: "Downloading document. Please wait a few moments.",
    },
    changeEmailModal: {
        changeYourEmail: "Change your e-mail",
        startFreeTrial: `To start the free trial, you need to validate your account with a company e-mail.`,
        typeCompanyEmail: "Type company e-mail",
        weSendValidation: `We’ll send you a validation e-mail, to confirm the change and start the free trial`,
        haveQuestion: "Have questions?",
        reachOut: " Reach out to our team.",
        changeEmail: "Change e-mail",
        invalidEmailFormat: "Invalid email format",
        invalidCompanyEmail: "Only company e-mails are accepted",
    },
    freeTrial: {
        startFreeTrial: "Start your 14 days free trial now",
        topNavCta: "Start 14 days free trial",
        experience: "Experience the full Valuer power, including  ",
        oneFreeCredit: "1 free credit",
        toUsePlatform: " to use on the platform.",
        startTrial: "Start trial",
        noCreditCard: "*No credit card required.",
        customTrials: "For custom trials,",
        freeTrailEnding: "Your free trial is ending in {{time}}",
        freeTrailEndingMobile: "Free trial ends in {{time}}",
        freeTrialUpgradeNow:
            "Don’t forget to use your credit and request a company or a technology report.",
        upgradeAccount: "Upgrade plan",
        haveQuestions: "Have questions?",
        talkToSales: "Talk to sales.",
        onFreePlan: "You are currently on a free plan",
        onFreePlanDesc:
            "Upgrade now and unlock the full Valuer potential in finding innovation and trends.",
        upgradeNow:
            "Don't forget to use your credit and request a company or a technology report.",
        cannotBuyCreditsTitle: "To buy more credits, upgrade your plan",
        cannotBuyCreditsDescription:
            "Unfortunately, you only get one free credit to spend during the free trial period. If you want more credits and really want the most out of the platform, please consider upgrading your plan to one that fits you best.",
        yourTeam: "Your team",
        downgradeDesc1: "You have ",
        downgradeDesc2:
            " users currently in your team and your new plan has less user seats. Please chose up to ",
        downgradeDesc3: " users that can continue collaborating with you.",
        ifYouDontSelect:
            "If you don't select anyone, your team will continue as Free users",
        haveAnyQuestions: "Have any questions? ",
        talkToSupport: "Talk to support",
        saveNewTeam: "Save new team",
        skipTeamSetup: "Skip team set up",
        skippingTitle: "Skipping team set up",
        skippingDesc1:
            "You are skipping team set up. All of your team mates will continue as a Free User and will not see your Projects and Search history.",
        skippingDesc2: "You can invite them later.",
        youCanChange:
            "You can change your team set up from Profile & Settings > Manage your team",
        takeMeBack: "Take me back",
        confirm: "Confirm",
        youCanSeeYourTeam: "Here you can see your team.",
        completeYourProfileTitle: "Complete your profile",
        completeYourProfileDescription:
            "To complete your profile and free trial requests, please fill out the form and afterwards verify your email address.",
        completeYourProfileFreeTrialUsersDescription:
            "In order to to keep searching, please fill out the form with your company email and company name and you can continue on our free trial.",
        completeProfile: "Complete profile",
    },
    verifyEmailModal: {
        youAreOneStepAway: "You're one step away",
        verifyYourEmail: "Validate your e-mail",
        startYourTrial:
            "You have recieved an email to your inbox, open it and click on the button to validate your email",
        resendEmail: "Resend validation e-mail.",
        emailNotReceived: "E-mail not received? ",
        close: "Close",
    },
    onboardingFreeTrial: {
        slides: {
            1: {
                owner_only: false,
                owner: {
                    title: "Success! Your 14-days free trial has been activated",
                    description1: {
                        text: "Try your way around the product and reach out if you have questions. We’re here to help.",
                        link: "",
                    },
                    description2: {
                        text: "Firstly, let’s get you going by introducing some key features.",
                        link: "",
                    },
                    description3: {
                        text: "",
                        link: "",
                    },
                    button1: "",
                    button2: "Next",
                },
                invitedUser: {
                    title:
                        "Congrats! \n" + "Your free trial has been activated",
                    description1: {
                        text: "Try your way around the product and reach out if you have questions. We’re here to help.",
                        link: "",
                    },
                    description2: {
                        text: "Firstly, let’s get you going by introducing some key features.",
                        link: "",
                    },
                    description3: {
                        text: "",
                        link: "",
                    },
                    button1: "",
                    button2: "Next",
                },
            },
            2: {
                owner_only: false,
                owner: {
                    title: "Use your free credit",
                    description1: {
                        text: "We’ve given you a free credit worth around EUR 100.",
                        link: "",
                    },
                    description2: {
                        text: "Use it to order a research request on a company or technology.",
                        link: "",
                    },
                    description3: {
                        text: "When you do - our human sector and tech analysts will get going and deliver a qualitative report of your choice.",
                        link: "",
                    },
                    button1: "Back",
                    button2: "Next",
                },
                invitedUser: {
                    title: "Use your free credit",
                    description1: {
                        text: "We’ve given you a free credit worth around EUR 100.",
                        link: "",
                    },
                    description2: {
                        text: "Use it to order a research request on a company or technology.",
                        link: "",
                    },
                    description3: {
                        text: "When you do - our human sector and tech analysts will get going and deliver a qualitative report of your choice.",
                        link: "",
                    },
                    button1: "Back",
                    button2: "Next",
                },
            },
            3: {
                owner_only: false,
                owner: {
                    title: "Create a project",
                    description1: {
                        text: "Create a collection of your favourites within your preferred domain - much like a music playlist - add companies and refer to it when you please.",
                        link: "",
                    },
                    description2: {
                        text: "",
                        link: "",
                    },
                    description3: {
                        text: "",
                        link: "",
                    },
                    button1: "Back",
                    button2: "Next",
                },
                invitedUser: {
                    title: "Create a project",
                    description1: {
                        text: "Create a collection of your favourites within your preferred domain - much like a music playlist - add companies and refer to it when you please.",
                        link: "",
                    },
                    description2: {
                        text: "",
                        link: "",
                    },
                    description3: {
                        text: "",
                        link: "",
                    },
                    button1: "Back",
                    button2: "Done",
                },
            },
            4: {
                owner_only: true,
                owner: {
                    title: "Collaborate with your team and discover innovation together",
                    description1: {
                        text: " to your free trial team account. Join your collaborative forces, vote, and comment on companies. Explore the platform together.",
                        link: "Invite your colleagues",
                    },
                    description2: {
                        text: "",
                        link: "",
                    },
                    description3: {
                        text: "",
                        link: "",
                    },
                    button1: "Back",
                    button2: "Done",
                },
                invitedUser: {
                    title: "",
                    description1: {
                        text: "",
                        link: "",
                    },
                    description2: {
                        text: "",
                        link: "",
                    },
                    description3: {
                        text: "",
                        link: "",
                    },
                    button1: "",
                    button2: "",
                },
            },
        },
    },
    planEndedModal: {
        yourPlanEnded: "Your plan has ended",
        planEndedDescription: "Unfortunately, your plan has expired.",
        planEndedDescription2:
            "You can continue on a free plan or upgrade your subscription to get the full experience of the platform. If you have any other questions, talk to sales.",
        upgradePlan: "Upgrade plan",
        continueOnFree: "Continue on free",
    },
    confirmFreeTrialStartModal: {
        title: "Ready to start your 14 days free trial?",
        confirm: "Start free trial",
        cancel: "Not yet",
        noCreditCard: "*No credit card required.",
        forCustomTrials: "For custom trials, ",
        talkToSales: "talk to sales.",
    },
    suggestedCompanies: {
        title: "Suggested companies",
        tooltip: {
            description1: {
                text: "Here you will see companies that our AI finds close to your interests. You can set your preferences in ",
                link: "Profile & Settings.",
            },
            description2:
                "If you choose industries in your search, our AI will catch on that and give you suggestions based on your latest search.",
        },
        noCompanies: {
            description1: {
                text: "Here you will see companies that our AI finds close to your interests. You can set your preferences in ",
                link: "Profile & Settings.",
            },
            description2:
                "If you choose industries in your search, our AI will catch on that and give you suggestions based on your latest search.",
        },
        otherSimilar: "Other interesting companies",
        tooltipTitle: "Other interesting companies",
        tooltipSubtitle:
            "Please provide feedback on this BETA feature through ",
        ourChat: "our chat.",
        toolTipText: `Think of “other interesting companies“ as the interesting products” section on an e-commerce site. We want to give you quick access to companies interesting to the one you are viewing, so you find the most relevant company for your needs.`,
        tooltipTextFooterText:
            "In this version the platform whittles down this list based on companies with interesting industries and sub-industries. From here, the platform ranks the companies found from top to bottom using the Valuer score.",
        oops: "Oops - it looks like we couldn’t find any interesting companies here...",
        pleaseCheck: "Please check out our ",
        lookAlikes: "“See look-alikes“",
        feature:
            " feature for companies sharing interesting descriptions with this one.",
        forMore:
            "For more information on why this did not work out, check out our ",
        helpCenter: "help center.",
    },
    switchPlanModal: {
        title: "You are in a team’s plan",
        description:
            "If you decide to upgrade your plan you will leave your current team and create a new plan as you wish. You will lose access to your team’s project and content. If you have questions, don’t hesitate to talk to sales.",
        tooltipTextFooterText:
            "In this version the platform whittles down this list based on companies with interesting industries and sub-industries. From here, the platform ranks the companies found from top to bottom using the Valuer score.",
        oops: "Oops - it looks like we couldn’t find any interesting companies here...",
        pleaseCheck: "Please check out our ",
        lookAlikes: "“See look-alikes“",
        feature:
            " feature for companies sharing interesting descriptions with this one.",
        forMore:
            "For more information on why this did not work out, check out our ",
        helpCenter: "help center.",
    },
    orders: {
        title: "Orders",
        companiesEnrichments: "Company Reports",
        technologiesEnrichments: "Technology Reports",
        companies: "Companies",
        technologies: "Technologies",
        status: "Status",
        ordered: "Ordered",
        noCompaniesEnrichments:
            "You have not requested any companies reports yet.",
        noTechnologiesDeepDives:
            "You have not requested any technologies reports yet.",
        addToProject: "Add to project",
        addAllToProject: "Add all to project",
        downloadAsCSV: "Download as CSV",
        searchTechnologies: "Search technology reports",
        searchEnrichments: "Search company reports",
        selectAll: "Select all",
        deselectAll: "Deselect all",
        download: "Download",
        selectTwoCompaniesForDownload:
            "Select at least one company to download it as merged document",
        selectTwoTechnologies:
            "Select at least one technology to download it as merged document",
        selectTwoCompaniesForProject:
            "Select at least one company to add it to a project",
    },
    download: {
        areYouSureTitle: "Are you sure you want to download these companies?",
        subtitleFreeTrial1:
            "As a Free trial User you can only export only 1 time",
        subtitleFreeTrial2: "(limited to 10 companies)",
        iWantToDownload: "I want to Download",
        letsTryLater: "Let’s try later",
        downloadsExceededTitle:
            "You have used your 1 time of exporting up to 10 companies",
        downloadsExceededSubtitle:
            "You can upgrade your plan and get unlimited exports",
        upgradePlan: "Upgrade Plan",
        upgradeLater: "Upgrade later",
    },
    workspace: {
        emptyPage: {
            title: "My Workspace",
            createNew: "Create a new",
            project: "Project",
            helpText:
                "Help us understand your current business focus so that we can guide our services to meet your innovation initiatives.",
            buttonText: "Create Project",
        },
        listView: {
            title: "Saved Elements",
            orderReport: "Order Report",
            seeReport: "See Report",
            fullProfile: "Full Profile",
            fullList: "Full List",
            preview: "Preview",
            remove: "Remove",
            save: "Save",
            saveInProject: "Save in project",
            saveSelected: "Save",
            saveSelectedProject: "Save To",
        },
    },
};
