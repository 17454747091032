import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing, typography, breakpoint }) => ({
    tablePaper: {
        borderRadius: spacing(3),
        boxShadow: "none",
        width: "100%",
    },
    headerFiltersWrapper: {
        display: "flex",
        alignItems: "center",
    },
    header: {
        position: "relative",
        paddingLeft: spacing(2),
    },
    tableToolbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: "none",
        padding: spacing(2),
        [breakpoint.down("sm")]: {
            order: 2,
            width: "auto",
            flexDirection: "row",
        },
    },
    titleWrapper: {
        padding: spacing(4),
    },
    tableRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: "none",
        padding: spacing(2),
        paddingLeft: spacing(4),
        borderBottom: `1px solid ${palette.text.lightest}`,
    },
    tableCell1: {
        flex: 6,
        wordBreak: "break-word",
        paddingLeft: 4,
    },
    tableCell2: {
        flex: 8,
        wordBreak: "break-word",
    },
    description: {
        height: 48,
        display: "-webkit-box",
        maxHeight: 48,
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 2,
    },
    tableCellDescription: {
        flex: 9,
        wordBreak: "break-word",
        // paddingLeft: 30,
        display: "table",
        [breakpoint.down("sm")]: {
            display: "none",
        },
    },
    tableCellType: {
        flex: 3,
    },
    tableCellName: {
        flex: 7,
        wordBreak: "break-word",
        userSelect: "none",
        height: 52,
        display: "flex",
        maxHeight: 52,
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 2,
        textOverflow: "ellipsis",
    },
    tableCellCheckbox: {
        flex: 1,
        alignSelf: "flex-start",
        wordBreak: "break-word",
        maxWidth: 56,
        minWidth: 56,
        margin: "auto",
    },
    companyName: {
        textDecoration: "unset",
        outline: "none",
        "-webkit-tap-highlight-color": "transparent",
        userSelect: "none",
        height: 52,
        maxHeight: 52,
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 2,
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center",
        gap: 10,
        width: "100%",
    },
    icon: {
        width: spacing(5),
        height: spacing(5),
        padding: spacing(1),
        borderRadius: spacing(1),
    },
    moreButton: {
        minWidth: 0,
        padding: 0,
    },
    moreIcon: {
        fill: palette.secondary.dark,
    },
    moreIconOpened: {
        fill: palette.primary.dark,
    },
    tooltip: {
        background: `${palette.common.white} !important`,
        color: `${palette.text.main} !important`,
        boxShadow: "0px 0px 6.94982px rgba(0, 0, 0, 0.1) !important",
        maxWidth: 500,
    },
    tooltipDescriptionContent: {
        maxHeight: 48,
        display: "table-cell",
        verticalAlign: "middle",
    },
    hideOnHover: {},
    showOnHover: {
        height: 0,
        width: 0,
        display: "none",
    },
    hoverIcon: {
        minWidth: 80,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        "& span": {
            whiteSpace: "nowrap",
        },
        "&:hover": {
            fontWeight: 700,
            "& img": {
                transform: "scale(1.1)",
            },
        },
    },
    rowHover: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    tooltipArrow: {
        color: `${palette.common.white} !important`,
    },
    typeTag: {
        padding: "6px 16px",
        borderRadius: spacing(1),
        lineHeight: "16px",
        fontWeight: 500,
        fontFamily: "GT Flexa Mono, sans-serif",
        whiteSpace: "nowrap",
        textTransform: "capitalize",
    },
    company: {
        background: palette.success.backgroundLight,
    },
    industry: {
        background: palette.warning.backgroundDark,
    },
    technology: {
        background: palette.primary.backgroundLight,
    },
    search: {
        background: palette.secondary.backgroundLight,
    },
    searchLookAlike: {
        background: palette.text.backgroundMain,
    },
    companyEnriched: {
        background: "transparent",
        border: `2px solid ${palette.success.backgroundLight}`,
    },
    industryEnriched: {
        background: "transparent",
        border: `2px solid ${palette.warning.backgroundDark}`,
    },
    technologyEnriched: {
        background: "transparent",
        border: `2px solid ${palette.primary.backgroundLight}`,
    },
    search_queryEnriched: {
        background: "transparent",
        border: `2px solid ${palette.secondary.backgroundLight}`,
    },
    selectedLabel: {
        display: "flex",
        alignItems: "center",
    },
    resultsTotal: {
        color: palette.text.light,
        marginLeft: spacing(1),
    },
    iconCompany: {
        position: "absolute",
        width: "24px",
        height: "25.27px",
        left: "8px",
        top: "7px",
    },
    iconIndustry: {
        width: "40px",
        height: "24px",
        position: "absolute",
        left: "calc(50% - 40px/2)",
        top: "8px",
    },
    iconTechnology: {
        width: "24px",
        height: "24.98px",
        position: "absolute",
        left: "8px",
        top: "7px",
    },
    iconSearch: {
        width: "18px",
        height: "28px",
        position: "absolute",
        left: "10px",
        top: "7px",
    },
    iconWrapper: {
        width: "40px",
        height: "40px",
        borderRadius: "8px",
        flex: "none",
        order: 0,
        flexGrow: 0,
        position: "relative",
    },
    enrichedIcon: {
        position: "absolute",
        bottom: 2,
        right: -4,
        width: 12,
        height: 12,
        background: palette.common.white,
        borderRadius: 2,
    },
    viewSwitcherWrapper: {
        marginRight: spacing(1),
        display: "flex",
        alignItems: "center",
    },
    inactiveViewIcon: {
        fill: palette.common.white,
        stroke: palette.text.lighter,
        cursor: "pointer",
        marginLeft: spacing(1),
    },
    activeViewIcon: {
        fill: palette.success.backgroundLight,
        stroke: palette.common.black,
        marginLeft: spacing(1),
    },
}));
