import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    comments: {},
    sharedModel: {},
    addCommentStatus: REQUEST_UNDEFINED,
    updateCommentStatus: REQUEST_UNDEFINED,
    getCommentsStatus: REQUEST_UNDEFINED,
    deleteCommentStatus: REQUEST_UNDEFINED,
    postSharedLinkStatus: REQUEST_UNDEFINED,
    voteCommentStatus: REQUEST_UNDEFINED,
    addTagStatus: REQUEST_UNDEFINED,
    getTagsStatus: REQUEST_UNDEFINED,
    removeTagStatus: REQUEST_UNDEFINED,
    selectTagStatus: REQUEST_UNDEFINED,
    similarCompanies: [],
    similarCompaniesStatus: REQUEST_UNDEFINED,
    suggestedCompanies: [],
    suggestedCompaniesStatus: REQUEST_UNDEFINED,
    fetchDeckClientPreviewStatus: REQUEST_UNDEFINED,
    deckClientPreview: {},
};

export const REDUCER_PREFIX = "companyProfile";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getSharedModelPending: state => {
            state.postSharedLinkStatus = REQUEST_PENDING;
        },
        getSharedModelSuccess: state => {
            state.postSharedLinkStatus = REQUEST_SUCCESS;
        },
        getSharedModelFailure: state => {
            state.postSharedLinkStatus = REQUEST_FAILURE;
        },
        setSharedModel: (state, { payload }) => {
            state.sharedModel = payload.model;
        },
        getCommentsPending: state => {
            state.getCommentsStatus = REQUEST_PENDING;
        },
        getCommentsSuccess: state => {
            state.getCommentsStatus = REQUEST_SUCCESS;
        },
        getCommentsFailure: state => {
            state.getCommentsStatus = REQUEST_FAILURE;
        },
        addCommentPending: state => {
            state.addCommentStatus = REQUEST_PENDING;
        },
        addCommentSuccess: state => {
            state.addCommentStatus = REQUEST_SUCCESS;
        },
        addCommentFailure: state => {
            state.addCommentStatus = REQUEST_FAILURE;
        },
        updateCommentPending: state => {
            state.updateCommentStatus = REQUEST_PENDING;
        },
        updateCommentSuccess: state => {
            state.updateCommentStatus = REQUEST_SUCCESS;
        },
        updateCommentFailure: state => {
            state.updateCommentStatus = REQUEST_FAILURE;
        },
        deleteCommentPending: state => {
            state.deleteCommentStatus = REQUEST_PENDING;
        },
        deleteCommentSuccess: state => {
            state.deleteCommentStatus = REQUEST_SUCCESS;
        },
        deleteCommentFailure: state => {
            state.deleteCommentStatus = REQUEST_FAILURE;
        },
        voteCommentPending: state => {
            state.voteCommentStatus = REQUEST_PENDING;
        },
        voteCommentSuccess: state => {
            state.voteCommentStatus = REQUEST_SUCCESS;
        },
        voteCommentFailure: state => {
            state.voteCommentStatus = REQUEST_FAILURE;
        },
        getTagsPending: state => {
            state.getTagsStatus = REQUEST_PENDING;
        },
        getTagsSuccess: state => {
            state.getTagsStatus = REQUEST_SUCCESS;
        },
        getTagsFailure: state => {
            state.getTagsStatus = REQUEST_FAILURE;
        },
        setTags: (state, { payload }) => {
            state.tags = payload.model;
        },
        addTagPending: state => {
            state.addTagStatus = REQUEST_PENDING;
        },
        addTagSuccess: state => {
            state.addTagStatus = REQUEST_SUCCESS;
        },
        addTagFailure: state => {
            state.addTagStatus = REQUEST_FAILURE;
        },
        deleteTagPending: state => {
            state.deleteTagStatus = REQUEST_PENDING;
        },
        deleteTagSuccess: state => {
            state.deleteTagStatus = REQUEST_SUCCESS;
        },
        deleteTagFailure: state => {
            state.deleteTagStatus = REQUEST_FAILURE;
        },
        selectTagPending: state => {
            state.selectTagStatus = REQUEST_PENDING;
        },
        selectTagSuccess: state => {
            state.selectTagStatus = REQUEST_SUCCESS;
        },
        selectTagFailure: state => {
            state.selectTagStatus = REQUEST_FAILURE;
        },
        setComments: (state, { payload }) => {
            let rearrangedComments = {};
            payload.forEach((comment, i) => {
                rearrangedComments[comment.id] = { ...comment };
                rearrangedComments[comment.id].replies = {};
                Object.values(comment.replies).forEach((reply, i) => {
                    rearrangedComments[comment.id].replies[reply.id] = {
                        ...reply,
                    };
                });
            });
            state.comments = rearrangedComments;
        },
        addComment: (state, { payload }) => {
            let commentsState = state.comments;
            let comments = commentsState.push(payload);
            state.comments = comments;
        },
        updateComment: (state, { payload }) => {
            let commentsState = state.comments;
            let comments = commentsState.push(payload);
            state.comments = comments;
        },
        clearComments: state => {
            state.comments = {};
        },
        setSimilarCompanies: (state, { payload }) => {
            state.similarCompanies = payload;
        },
        clearSimilarCompanies: state => {
            state.similarCompanies = [];
        },
        getSimilarCompaniesPending: state => {
            state.similarCompaniesStatus = REQUEST_PENDING;
        },
        getSimilarCompaniesSuccess: state => {
            state.similarCompaniesStatus = REQUEST_SUCCESS;
        },
        getSimilarCompaniesFailure: state => {
            state.similarCompaniesStatus = REQUEST_FAILURE;
        },
        getSimilarCompaniesUndefined: state => {
            state.similarCompaniesStatus = REQUEST_UNDEFINED;
        },
        setSuggestedCompanies: (state, { payload }) => {
            state.suggestedCompanies = payload;
        },
        getSuggestedCompaniesPending: state => {
            state.suggestedCompaniesStatus = REQUEST_PENDING;
        },
        getSuggestedCompaniesSuccess: state => {
            state.suggestedCompaniesStatus = REQUEST_SUCCESS;
        },
        getSuggestedCompaniesFailure: state => {
            state.suggestedCompaniesStatus = REQUEST_FAILURE;
        },
        getSuggestedCompaniesUndefined: state => {
            state.suggestedCompaniesStatus = REQUEST_UNDEFINED;
        },
        fetchDeckClientPreviewPending: state => {
            state.fetchDeckClientPreviewStatus = REQUEST_PENDING;
        },
        fetchDeckClientPreviewSuccess: state => {
            state.fetchDeckClientPreviewStatus = REQUEST_SUCCESS;
        },
        fetchDeckClientPreviewFailure: state => {
            state.fetchDeckClientPreviewStatus = REQUEST_FAILURE;
        },
        setDeckClientPreview: (state, { payload }) => {
            state.deckClientPreview = payload;
        },
    },
});
export { reducer, actions };
