import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    quickStats: {},
    latestOrders: [],
    latestActivities: [],
    activities: [],
    popularCompanies: [],
    unseenActivitiesCount: 0,
    fetchDashboardStatus: REQUEST_UNDEFINED,
    markActivitySeenStatus: REQUEST_UNDEFINED,
    getActivitiesStatus: REQUEST_UNDEFINED,
};

export const REDUCER_PREFIX = "dashboard";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        setDashboard: (state, { payload }) => {
            state.quickStats = payload.data.stats;
            state.latestOrders = payload.data.latest_orders;
            state.latestActivities = payload.data.latest_activities;
            state.unseenActivitiesCount = payload.data.unseen_activities_count;
            state.popularCompanies = payload.data.popular_companies;
        },
        fetchDashboardPending: state => {
            state.fetchDashboardStatus = REQUEST_PENDING;
        },
        fetchDashboardSuccess: state => {
            state.fetchDashboardStatus = REQUEST_SUCCESS;
        },
        fetchDashboardFailure: state => {
            state.fetchDashboardStatus = REQUEST_FAILURE;
        },
        markActivitySeenPending: state => {
            state.markActivitySeenStatus = REQUEST_PENDING;
        },
        markActivitySeenSuccess: state => {
            state.markActivitySeenStatus = REQUEST_SUCCESS;
        },
        markActivitySeenFailure: state => {
            state.markActivitySeenStatus = REQUEST_FAILURE;
        },
        setActivities: (state, { payload }) => {
            state.activities = payload.results;
        },
        getActivitiesPending: state => {
            state.getActivitiesStatus = REQUEST_PENDING;
        },
        getActivitiesSuccess: state => {
            state.getActivitiesStatus = REQUEST_SUCCESS;
        },
        getActivitiesFailure: state => {
            state.getActivitiesStatus = REQUEST_FAILURE;
        },
    },
});
export { reducer, actions };
