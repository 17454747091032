import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// UI components
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import ContentLoader from "../../../components/ContentLoader/ContentLoader.js";
// Hooks
import { useTranslation } from "react-i18next";
import { useSectorsHook } from "../hooks/useSectorsHook";
// Assets
import CloseIcon from "../../../assets/icons/CloseIcon.svg";
// Redux
import { getSubsectorsStatusSelector } from "../../../store/sectors/selectors";
import { actions as searchActions } from "../../../store/search/slice";
// Constants
import { REQUEST_PENDING } from "../../../constants/statuses";

const SectorsDialog = ({
    classes,
    // selectedSearchSector,
    handleToggleDialog,
    dialogOpened,
    allSectors,
    handleSectorSelect,
    onSave = undefined,
    selectedSectorsState,
    showResetButton = false,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const getSubsectorsStatus = useSelector(getSubsectorsStatusSelector);
    const [selectedSectors, setSelectedSectors] =
        useState(selectedSectorsState);
    // const [selectedSector, setSelectedSector] = useState();
    const { removeAllSectors } = useSectorsHook();

    const handleStopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    // useEffect(() => {
    //   setSelectedSector(selectedSearchSector)
    // }, [selectedSearchSector])

    // useEffect(() => {
    //   dialogOpened ? setSelectedSector(selectedSearchSector) : setSelectedSector({})
    // }, [dialogOpened, selectedSearchSector])

    useEffect(() => {
        setSelectedSectors(selectedSectorsState);
    }, [dialogOpened, selectedSectorsState]);

    const handleDialogClose = e => {
        handleStopPropagation(e);
        handleToggleDialog();
    };

    const handleDialogSaveAndClose = e => {
        handleStopPropagation(e);
        handleToggleDialog();
        dispatch(searchActions.addSectors(selectedSectors));
        setSelectedSectors([]);
        onSave !== undefined && onSave(selectedSectors);
    };

    const handleResetSectors = () => {
        removeAllSectors();
    };

    const sectorSelect = (sector, active) => {
        let sectorsArray = [...selectedSectors];
        if (active) {
            sectorsArray = sectorsArray.filter(obj => obj.slug !== sector.slug);
        } else {
            sectorsArray = [
                ...sectorsArray,
                { name: sector.name, slug: sector.slug },
            ];
        }
        setSelectedSectors(sectorsArray);
    };

    const handleSectorSelectClick = (e, sector) => {
        handleStopPropagation(e);
        sectorSelect(
            sector,
            selectedSectors.some(i => i.slug === sector.slug),
        );
    };

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.subsectorsDialog,
                scrollPaper: classes.subsectorsDialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    handleDialogClose(event);
                }
            }}
            open={dialogOpened}>
            <MuiDialogTitle
                disableTypography
                className={classes.dialogTitleWrapper}>
                <Typography variant="subtitle1" component="div">
                    {t("focus.sectorsDialogTitle")}
                </Typography>
                <Typography
                    variant="body"
                    component="div"
                    color="textLight"
                    className={classes.subsesctorsDialogSubtitle}>
                    {t(
                        showResetButton
                            ? "focus.sectorsDialogSubtitle2"
                            : "focus.sectorsDialogSubtitle",
                    )}
                </Typography>
                {dialogOpened ? (
                    <IconButton
                        className={classes.closeButton}
                        onClick={handleDialogClose}>
                        <img
                            src={CloseIcon}
                            alt={t("outcome.step2")}
                            className={classes.stepIcon}
                        />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.subsectorsDialogContent}>
                <div className={classes.relative}>
                    {getSubsectorsStatus === REQUEST_PENDING && (
                        <ContentLoader size="small" />
                    )}
                    <div className={classes.maxHeight}>
                        {allSectors.map(sector => (
                            <Button
                                variant="pill"
                                {...(selectedSectors.some(
                                    i => i.slug === sector.slug,
                                ) && { active: true })}
                                disabled={
                                    selectedSectors.length === 5 &&
                                    !selectedSectors.some(
                                        i => i.slug === sector.slug,
                                    )
                                }
                                className={classes.sectorDialog}
                                onClick={e =>
                                    handleSectorSelectClick(e, sector)
                                }
                                key={sector.slug}>
                                {sector.name}
                            </Button>
                        ))}
                    </div>
                </div>
                <div className={classes.bgGradient}></div>
            </MuiDialogContent>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                alignItems="center"
                className={classes.subsectorsDialogActionItems}>
                {showResetButton && (
                    <Button
                        variant="tertiary"
                        className={classes.continueFocusCta}
                        onClick={handleResetSectors}
                        size="large">
                        {t("subsectors.reset")}
                    </Button>
                )}
                <Button
                    variant="primary"
                    className={classes.continueFocusCta}
                    onClick={handleDialogSaveAndClose}
                    size="large">
                    {t(
                        showResetButton
                            ? "subsectors.saveChanges2"
                            : "subsectors.saveChanges",
                    )}
                </Button>
            </Box>
        </Dialog>
    );
};

export default SectorsDialog;
