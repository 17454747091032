import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        recentSearchesRoot: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: `0`,
            marginRight: ({ user }) => (user?.csp ? 0 : 40),
            [breakpoint.down("lg")]: {
                marginRight: ({ user }) => !user?.csp && 0,
                padding: `${spacing(2)}px ${spacing(2)}px ${spacing(
                    1,
                )}px ${spacing(2)}px`,
            },
            [breakpoint.down("sm")]: {
                padding: `${spacing(2)}px ${spacing(1)}px ${spacing(
                    1,
                )}px ${spacing(1)}px`,
            },
        },
        recentSearchesHolder: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 0,
            [breakpoint.down("xl")]: {
                flexDirection: ({ user }) => (user?.csp ? "column" : "row"),
                alignItems: ({ user }) => (user?.csp ? "start" : "center"),
            },
            [breakpoint.down("md")]: {
                flexDirection: ({ user }) =>
                    !user?.csp ? "column" : "inherit",
                alignItems: ({ user }) => (!user?.csp ? "start" : "inherit"),
            },
            [breakpoint.down("sm")]: {
                padding: `${spacing(2)}px ${spacing(1)}px ${spacing(
                    1,
                )}px ${spacing(1)}px`,
                flexDirection: "column",
            },
        },
        recentSearchesList: {
            padding: 0,
            overflowX: "auto",
            display: "flex",
            width: "70%",
            height: "35px",
            [breakpoint.down("xl")]: {
                width: "95%",
            },
        },
        startSearchBtn: {
            maxHeight: "55px",
            textAlign: "center",
            lineHeight: "18px",
            marginLeft: spacing(1),
            [breakpoint.down("lg")]: {
                fontSize: "14px",
                lineHeight: "18px",
            },
            [breakpoint.down("md")]: {
                display: "flex !important",
            },
            // whiteSpace: "nowrap",
        },
        searchTag: {
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            background: palette.success.lighter,
            borderRadius: spacing(1),
            padding: "6px 15px",
            marginRight: spacing(1),
            cursor: "pointer",
            height: "33px",
            "&:hover": {
                background: palette.success.backgroundDark,
            },
            [breakpoint.down("sm")]: {
                borderRadius: spacing(1),
            },
        },
        searchTagActive: {
            background: palette.success.medium,
            borderRadius: spacing(1),
            padding: "6px 15px",
            marginRight: spacing(1),
            "&:hover": {
                background: palette.success.backgroundDark,
            },
        },
        recentSearchesLabel: {
            // width: "20%",
            width: "fit-content",
            minWidth: "fit-content",
            marginRight: spacing(1),
            [breakpoint.down("lg")]: {
                width: ({ user }) => (user?.csp ? "100%" : "fit-content"),
                marginBottom: spacing(1),
            },
        },
        closeIcon: {
            width: 10,
            height: 10,
            marginLeft: spacing(1),
            cursor: "pointer",
            "&:hover": {
                opacity: 0.8,
            },
        },
        addPopup: {
            zIndex: 2,
            width: 235,
            maxHeight: 202,
            background: palette.common.white,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: spacing(2),
            border: "1px solid",
            borderColor: palette.text.backgroundDark,
            top: 40,
            right: 0,
            overflow: "auto",
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            "&::-webkit-scrollbar": {
                width: 2,
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: palette.text.main,
                borderRadius: 0,
            },
        },
        projectNameWrapper: {
            width: "100%",
            display: "flex",
            padding: `${spacing(2)}px ${spacing(3)}px`,
            borderBottom: `1px solid ${palette.text.backgroundLight}`,
            color: palette.text.light,
            "&:hover": {
                background: palette.text.backgroundLight,
            },
            "&:last-child": {
                borderBottom: "none",
            },
        },
        clickable: {
            "&:hover": {
                cursor: "pointer",
                background: palette.success.backgroundDark,
            },
        },
        createNewProject: {
            display: "flex",
            justifyContent: "center",
        },
        projectName: {
            display: "flex",
            alignItems: "center",
            color: palette.text.light,
        },
        mono: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        relative: {
            position: "relative",
        },
    }),
);
