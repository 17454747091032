import React, { useState, useEffect } from "react";
import findIndex from "lodash/findIndex";
import { actions as resultsActions } from "../../store/results/slice";
import { useDispatch } from "react-redux";

export const useGraph = ({
    chartYears,
    setYearsChartArray,
    selectedFilters,
    displayedFilters,
    setDisplayedFilters,
    setSelectedFilters,
    setSliderMarks,
    theme,
    yearsChartArray,
    resetFilter,
    setMarks,
    slug,
    t,
    setPage,
}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        chartYears &&
            Object.keys(chartYears).length > 0 &&
            setYearsChartArray(Object.keys(chartYears).sort());
    }, [chartYears]);

    const [yearMinIndex, setYearMinIndex] = useState(
        findIndex(
            yearsChartArray,
            yearsChartArray => yearsChartArray === selectedFilters.yearMin,
        ),
    );
    const [yearMaxIndex, setYearMaxIndex] = useState(
        findIndex(
            yearsChartArray,
            yearsChartArray => yearsChartArray === selectedFilters.yearMax,
        ),
    );

    useEffect(() => {
        const yearMin =
            yearsChartArray && yearsChartArray.length > 0
                ? yearsChartArray.filter(y =>
                      y.startsWith(selectedFilters.yearMin),
                  )
                : selectedFilters.yearMin;
        const yearMax =
            yearsChartArray && yearsChartArray.length > 0
                ? yearsChartArray.filter(y =>
                      y.endsWith(selectedFilters.yearMax),
                  )
                : selectedFilters.yearMax;

        const yearMinValue = findIndex(
            yearsChartArray,
            yearsChartArray => String(yearsChartArray) === String(yearMin),
        );
        const yearMaxValue = findIndex(
            yearsChartArray,
            yearsChartArray => String(yearsChartArray) === String(yearMax),
        );
        setYearMinIndex(yearMinValue >= 0 ? yearMinValue : 0);
        setYearMaxIndex(
            yearMaxValue >= 0
                ? yearMaxValue
                : yearsChartArray && yearsChartArray.length - 1,
        );
    }, [selectedFilters, yearsChartArray]);
    const [rangeValue, setRangeValue] = useState([yearMinIndex, yearMaxIndex]);

    useEffect(() => {
        return () => {
            setYearMinIndex();
            setYearMaxIndex();
            setRangeValue();
        };
    }, []);

    const handleResetYearChart = (resetBoth = false) => {
        setRangeValue([0, yearsChartArray.length - 1]);
        if (!!resetBoth) {
            dispatch(
                resultsActions.setResultsDisplayFilters({
                    sid: slug,
                    displayedFilters: {
                        ...displayedFilters,
                        yearMin: "",
                        yearMax: "",
                        teamsizeMin: "",
                        teamsizeMax: "",
                    },
                }),
            );
            setDisplayedFilters(prevDisplayedFilters => {
                return {
                    ...prevDisplayedFilters,
                    yearMin: "",
                    yearMax: "",
                    teamsizeMin: "",
                    teamsizeMax: "",
                };
            });
            setSelectedFilters(prevSelectedFilters => {
                return {
                    ...prevSelectedFilters,
                    yearMin: "",
                    yearMax: "",
                    teamsizeMin: "",
                    teamsizeMax: "",
                };
            });
        } else {
            setDisplayedFilters(prevDisplayedFilters => {
                return { ...prevDisplayedFilters, yearMin: "", yearMax: "" };
            });
            setSelectedFilters(prevSelectedFilters => {
                return { ...prevSelectedFilters, yearMin: "", yearMax: "" };
            });
            dispatch(
                resultsActions.setResultsDisplayFilters({
                    sid: slug,
                    displayedFilters: {
                        ...displayedFilters,
                        yearMin: "",
                        yearMax: "",
                    },
                }),
            );
            resetFilter("yearMin");
            resetFilter("yearMax");
        }
        setPage(1);
        setRangeValue([yearMinIndex, yearMaxIndex]);
    };

    useEffect(() => {
        setRangeValue([yearMinIndex, yearMaxIndex]);
    }, [yearMinIndex, yearMaxIndex]);

    useEffect(() => {
        if (
            chartYears &&
            Object.values(chartYears).length > 0 &&
            yearsChartArray.length > 0
        ) {
            setMarks(
                yearsChartArray.map((year, i) => {
                    return {
                        name: year,
                        percentage: chartYears[year]
                            ? chartYears[year].percentage
                            : 0,
                        count: chartYears[year] ? chartYears[year].count : 0,
                        allPercentage: chartYears[year]
                            ? chartYears[year].all_companies_percentage
                            : 0,
                        allCount: chartYears[year]
                            ? chartYears[year].all_companies_count
                            : 0,
                    };
                }),
            );
            setSliderMarks(
                yearsChartArray.map((year, i) => {
                    return (
                        ((((document.body.offsetWidth > 576 &&
                            document.body.offsetWidth < 768) ||
                            document.body.offsetWidth > 1281) &&
                            i % 2 === 0) ||
                            (document.body.offsetWidth < 320 &&
                                document.body.offsetWidth < 575) ||
                            (document.body.offsetWidth > 769 &&
                                document.body.offsetWidth < 1024) ||
                            (document.body.offsetWidth > 1025 &&
                                document.body.offsetWidth < 1280)) && {
                            // return {
                            label: (
                                <span onClick={() => handleYearBarClick(i)}>
                                    {year}
                                </span>
                            ),
                            percentage: chartYears[year]
                                ? chartYears[year].percentage
                                : 0,
                            count: chartYears[year]
                                ? chartYears[year].count
                                : 0,
                            style: {
                                color: "#666666",
                                fontFamily: "GT Flexa Mono",
                                transformOrigin: "left",
                                transform:
                                    "translate(-80%, 20%) rotate(-30deg)",
                                opacity: 0.25,
                                ...theme.typography.bodyXs,
                            },
                        }
                    );
                }),
            );
        }
    }, [chartYears, yearsChartArray]);

    const handleSliderChange = value => {
        setRangeValue(value);
    };

    const handleSliderAfterChange = value => {
        setPage(1);
        const yearMinValue =
            rangeValue[0] === 0
                ? ""
                : yearsChartArray && yearsChartArray[rangeValue[0]];
        const yearMaxValue =
            yearsChartArray && rangeValue[1] === yearsChartArray.length - 1
                ? ""
                : yearsChartArray[rangeValue[1]];
        dispatch(
            resultsActions.setResultsDisplayFilters({
                sid: slug,
                displayedFilters: {
                    ...displayedFilters,
                    yearMin: yearMinValue.substring(0, 4),
                    yearMax: yearMaxValue.slice(-4),
                },
            }),
        );
        setDisplayedFilters(prevDisplayedFilters => {
            return {
                ...prevDisplayedFilters,
                yearMin: yearMinValue.substring(0, 4),
                yearMax: yearMaxValue.slice(-4),
            };
        });
    };

    const handleYearBarClick = index => {
        setPage(1);
        setRangeValue([index, index]);
        const yearMinValue =
            index === 0 ? "" : yearsChartArray && yearsChartArray[index];
        const yearMaxValue =
            yearsChartArray && index === yearsChartArray.length - 1
                ? ""
                : yearsChartArray[index];
        setDisplayedFilters(prevDisplayedFilters => {
            return {
                ...prevDisplayedFilters,
                yearMin: yearMinValue.substring(0, 4),
                yearMax: yearMaxValue.slice(-4),
            };
        });
        dispatch(
            resultsActions.setResultsDisplayFilters({
                sid: slug,
                displayedFilters: {
                    ...displayedFilters,
                    yearMin: yearMinValue.substring(0, 4),
                    yearMax: yearMaxValue.slice(-4),
                },
            }),
        );
    };

    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            color: "#333333",
                            fontSize: 12,
                            lineHeight: "20px",
                            margin: "0 0 16px 0",
                            fontFamily: "GT Flexa Mono, sans-serif",
                        }}>
                        <div
                            style={{
                                color: "#FD750D",
                                marginRight: "8px",
                                fontSize: 24,
                                lineHeight: "32px",
                            }}>
                            {payload[0].payload.percentage}%
                        </div>
                        <div>
                            <div
                                style={{
                                    color: "#333333",
                                    fontSize: 12,
                                    lineHeight: "20px",
                                }}>
                                Your search
                            </div>
                            <div
                                style={{
                                    color: "#666666",
                                    fontSize: 10,
                                    lineHeight: "12px",
                                    textAlign: "left",
                                }}>
                                ({payload[0].payload.count}{" "}
                                {t(
                                    `results.${
                                        payload[0].payload.count > 1
                                            ? "companies"
                                            : "company"
                                    }`,
                                )}
                                )
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            color: "#333333",
                            fontSize: 12,
                            lineHeight: "20px",
                            margin: 0,
                            fontFamily: "GT Flexa Mono, sans-serif",
                        }}>
                        <div
                            style={{
                                color: "#999999",
                                marginRight: "8px",
                                fontSize: 24,
                                lineHeight: "32px",
                            }}>
                            {payload[0].payload.allPercentage}%
                        </div>
                        <div>
                            <div
                                style={{
                                    color: "#333333",
                                    fontSize: 12,
                                    lineHeight: "20px",
                                }}>
                                All in Valuer DB
                            </div>
                            <div
                                style={{
                                    color: "#666666",
                                    fontSize: 10,
                                    lineHeight: "12px",
                                    textAlign: "left",
                                }}>
                                ({payload[0].payload.allCount}{" "}
                                {t(
                                    `results.${
                                        payload[0].payload.count > 1
                                            ? "companies"
                                            : "company"
                                    }`,
                                )}
                                )
                            </div>
                        </div>
                    </div>
                    <p
                        style={{
                            color: "#007A5E",
                            fontSize: 24,
                            lineHeight: "32px",
                            margin: "8px 0 0 0",
                        }}>
                        {payload[0].payload.name}
                    </p>
                </div>
            );
        }

        return null;
    };
    return {
        handleResetYearChart,
        CustomTooltip,
        handleSliderAfterChange,
        handleSliderChange,
        handleYearBarClick,
        rangeValue,
        setRangeValue,
    };
};
