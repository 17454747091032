import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const buyCreditsRequest = createAction(
    `${REDUCER_PREFIX}/buyCreditsRequest`,
);

export const getCreditsSummaryRequest = createAction(
    `${REDUCER_PREFIX}/getCreditsSummaryRequest`,
);

export const getPlanRequest = createAction(`${REDUCER_PREFIX}/getPlanRequest`);
