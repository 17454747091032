import mixpanel from "mixpanel-browser";
// Hooks
import { useIntercom } from "react-use-intercom";
import { mixpanelTrackRequest } from "../store/mixpanel/requests";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

export const useAppEventTracking = () => {
    const { trackEvent } = useIntercom();

    const intercomTrackEvent = (eventName, eventData = null) => {
        if (
            process.env.REACT_APP_ENV === "prod" ||
            process.env.REACT_APP_ENV === "staging"
        ) {
            //Intercom event tracking
            trackEvent(eventName, eventData);
        }
    };

    const mixpanelSetUser = user => {
        if (user) {
            mixpanel.identify(user.email);
        }
    };

    const mixpanelTrackEvent = (eventName, eventData = null) => {
        if (process.env.REACT_APP_ENV !== "local") {
            let requestData = { eventName: eventName };
            if (eventData) {
                requestData.data = eventData;
            }
            //turn off event tracking through backend api
            //dispatch(mixpanelTrackRequest(requestData));
            //sending event directly to mixpanel
            mixpanel.track(eventName, eventData);
        }
    };

    const appTrackEvent = (eventName, eventData = null) => {
        mixpanelTrackEvent(eventName, eventData);
        intercomTrackEvent(eventName, eventData);
    };

    return {
        intercomTrackEvent,
        mixpanelTrackEvent,
        mixpanelSetUser,
        appTrackEvent,
    };
};
