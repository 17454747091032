import { useEffect, useState } from "react";
//Hooks
import { useAuthHook } from "../../../common/useAuthHook";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { useIntercom } from "react-use-intercom";
import { useRouterHook } from "../../../common/useRouterHook";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { voteMlResultRequest } from "../../../store/results/requests";
import {
    getCompanySelector,
    getCompanyStatusSelector,
    getSearchResultsSelector,
    getSearchResultsStatusSelector,
} from "../../../store/results/selectors";
import { getPostSearchResponseSelector } from "../../../store/search/selectors";
import {
    getDeckClientPreviewSelector,
    getDeckClientPreviewStatusSelector,
    getSharedModelSelector,
    getSelectTagSelector,
    getPostSharedLinkStatusSelector,
    getDeleteTagSelector,
    getAddTagSelector,
} from "../../../store/companyProfile/selectors";
import { getPostProjectsStatusSelector } from "../../../store/projects/selectors";
import {
    getTechnologyCompanyStateSelector,
    getTechnologyCompanyStatusSelector,
} from "../../../store/technologies/selectors";
import {
    getIndustryCompanySelector,
    getIndustryCompanyStatusSelector,
} from "../../../store/industries/selectors";

//Shared
import { Shared } from "../../../utils/shared";
import { useHistory } from "react-router-dom";

export const useCompanyProfileHook = ({ source }) => {
    const { t } = useTranslation();
    let { slug } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    let { search } = useLocation();
    const companySelector = useSelector(
        source === "deckClientPreviewCompany"
            ? getDeckClientPreviewSelector
            : source === "industry"
            ? getIndustryCompanySelector
            : source === "technologyCompany"
            ? getTechnologyCompanyStateSelector
            : source === "shared"
            ? getSharedModelSelector
            : getCompanySelector,
    );
    const [company, setCompany] = useState(companySelector);
    const dispatch = useDispatch();
    const {
        isAuthenticated,
        getMeStatus,
        user,
        isGuestUser,
        isExpiredUser,
        isPayingUser,
    } = useAuthHook();
    const postSearchResponse = useSelector(getPostSearchResponseSelector);
    const searchResultsStatus = useSelector(getSearchResultsStatusSelector);
    const getCompanyStatus = useSelector(
        source === "deckClientPreviewCompany"
            ? getDeckClientPreviewStatusSelector
            : source === "industry"
            ? getIndustryCompanyStatusSelector
            : source === "technologyCompany"
            ? getTechnologyCompanyStatusSelector
            : source === "shared"
            ? getPostSharedLinkStatusSelector
            : getCompanyStatusSelector,
    );
    const allResults = useSelector(getSearchResultsSelector);
    const sharedModel = useSelector(getSharedModelSelector);
    const postSharedLinkStatus = useSelector(getPostSharedLinkStatusSelector);
    const addTagStatus = useSelector(getAddTagSelector);
    const selectTagStatus = useSelector(getSelectTagSelector);
    const deleteTagStatus = useSelector(getDeleteTagSelector);
    const postProjectStatus = useSelector(getPostProjectsStatusSelector);
    const { openPage } = useRouterHook();
    const [companyResult, setCompanyResult] = useState(null);
    //votes
    const [userLike, setUserLike] = useState(false);
    const [userDislike, setUserDislike] = useState(false);
    const [votersUp, setVotersUp] = useState({ cnt: 0, users: [] });
    const [votersDown, setVotersDown] = useState({ cnt: 0, users: [] });
    const companyProperties =
        source !== "deckClientPreviewCompany"
            ? company
            : { ...company, voters: [], comments_cnt: null };
    const [voters, setVoters] = useState();
    const [commentsCnt, setCommentsCnt] = useState();
    const [totalComments, setTotalComments] = useState(commentsCnt);
    const history = useHistory();

    useEffect(() => {
        setVoters((companyProperties && companyProperties.voters) || []);
        setCommentsCnt(
            (companyProperties && companyProperties.comments_cnt) || [],
        );
    }, [company]);

    useEffect(() => {
        setCompany(companySelector);
    }, [companySelector]);
    useEffect(() => {
        if (Object.keys(sharedModel).length > 0) {
            setTotalComments(sharedModel && sharedModel.commentsCnt);
        } else {
            setTotalComments(commentsCnt);
        }
    }, [sharedModel, commentsCnt]);

    const { trackEvent } = useIntercom();
    const query = new URLSearchParams(search);
    const openTab = query.get("openTab");
    const cid = query.get("cid");

    const { formatNumber, checkUserSubscription } = Shared({ getUser: user });

    const [activeTab, setActiveTab] = useState("profile");
    const handleTabSelected = value => {
        setActiveTab(value);
    };
    const [breakDownModal, setShowBreakdownModal] = useState(false);

    if (!companyResult) {
        return {
            t,
            slug,
            search,
            company,
            dispatch,
            getMeStatus,
            isAuthenticated,
            user,
            isGuestUser,
            isExpiredUser,
            isPayingUser,
            postSearchResponse,
            searchResultsStatus,
            getCompanyStatus,
            allResults,
            sharedModel,
            postSharedLinkStatus,
            addTagStatus,
            selectTagStatus,
            deleteTagStatus,
            setCompanyResult,
            deck: {},
            sdgs: [],
            name: "",
            degree_of_fit: "",
            website: "",
            number_of_employees: "",
            total_funding_amount_currency: "",
            total_funding_amount_dollars: "",
            hq_country: "",
            hq_area: "",
            hq_city: "",
            investment_stage: "",
            description: "",
            sectors: [],
            subsectors: [],
            enrichment_request: null,
            founded_date: "",
            checkUserSubscription,
            formatNumber,
            activeTab,
            handleTabSelected,
            breakDownModal,
            setShowBreakdownModal,
            voteMatch: () => {},
            openSectorTag: () => {},
            openTab,
            trackEvent,
            companyResult,
            setUserLike,
            setUserDislike,
            setActiveTab,
            userLike,
            userDislike,
            ready_for_release: false,
            priority: "",
        };
    }
    const {
        deck,
        sdgs,
        name,
        degree_of_fit,
        website,
        number_of_employees,
        total_funding_amount_currency,
        total_funding_amount_dollars,
        hq_country,
        hq_area,
        hq_city,
        investment_stage,
        description,
        sectors,
        subsectors,
        enrichment_request,
        founded_date,
        ready_for_release,
        priority,
    } = companyResult;

    //Votes
    const voteMatch = (vote, e) => {
        e.stopPropagation();
        if (isAuthenticated) {
            if (vote === 1) {
                if (userLike) {
                    let vUp = votersUp;
                    let users = vUp.users
                        ? vUp.users.filter(f => f !== user.name)
                        : [];
                    vUp = { cnt: vUp.cnt - 1, users: users };
                    setVotersUp(vUp);
                } else if (userDislike) {
                    let vDown = votersDown;
                    let users = vDown.users
                        ? vDown.users.filter(f => f !== user.name)
                        : [];
                    vDown = { cnt: vDown.cnt - 1, users: users };
                    setVotersDown(vDown);
                }
                if (!userLike) {
                    let vUp = votersUp;
                    let users = [...vUp.users, user.name];
                    vUp = { cnt: vUp.cnt + 1, users: users };
                    setVotersUp(vUp);
                }
                setUserLike(!userLike);
                setUserDislike(false);
            } else {
                if (userDislike) {
                    let vDown = votersDown;
                    let users = vDown.users
                        ? vDown.users.filter(f => f !== user.name)
                        : [];
                    vDown = { cnt: vDown.cnt - 1, users: users };
                    setVotersDown(vDown);
                } else if (userLike) {
                    let vUp = votersUp;
                    let users = vUp.users
                        ? vUp.users.filter(f => f !== user.name)
                        : [];
                    vUp = { cnt: vUp.cnt - 1, users: users };
                    setVotersUp(vUp);
                }
                if (!userDislike) {
                    let vDown = votersDown;
                    let users = [...vDown.users, user.name];
                    vDown = { cnt: vDown.cnt + 1, users: users };
                    setVotersDown(vDown);
                }
                setUserLike(false);
                setUserDislike(!userDislike);
            }
            let data = new FormData();
            data.append("ml_result_id", companyResult.id);
            data.append("vote", vote);
            dispatch(voteMlResultRequest({ data }));
        }
    };
    //click on company sector, open industry
    const openSectorTag = (slug, subsector = null, e) => {
        e.stopPropagation();
        if (slug) {
            let subsectorSlug = "";
            if (subsector) {
                subsectorSlug = `?subsectorSlug=${subsector}`;
            }
            localStorage.setItem(
                "industryProfileFromRoute",
                history.location.pathname,
            );
            localStorage.setItem("visitedCid", cid);
            openPage(`/industries/${slug}/` + subsectorSlug); //it will not fetch industry when redirecting from industries page
        }
    };
    const openCompanyComments = e => {
        setActiveTab("comments");
    };

    const handleClickSeeLookALike = data => {
        localStorage.setItem("company-look-a-likes", JSON.stringify(data));
        openPage("/search-type/lookalike");
    };

    return {
        t,
        slug,
        search,
        company,
        dispatch,
        getMeStatus,
        isAuthenticated,
        user,
        isGuestUser,
        isExpiredUser,
        isPayingUser,
        postSearchResponse,
        searchResultsStatus,
        getCompanyStatus,
        allResults,
        sharedModel,
        postSharedLinkStatus,
        addTagStatus,
        selectTagStatus,
        deleteTagStatus,
        setCompanyResult,
        deck,
        sdgs,
        name,
        degree_of_fit,
        website,
        number_of_employees,
        total_funding_amount_currency,
        total_funding_amount_dollars,
        hq_country,
        hq_area,
        hq_city,
        investment_stage,
        description,
        sectors,
        subsectors,
        enrichment_request,
        founded_date,
        checkUserSubscription,
        formatNumber,
        activeTab,
        handleTabSelected,
        breakDownModal,
        setShowBreakdownModal,
        voteMatch,
        openSectorTag,
        openTab,
        trackEvent,
        companyResult,
        setUserLike,
        setUserDislike,
        setActiveTab,
        userLike,
        userDislike,
        ready_for_release:
            window.location.pathname === "/companies/enriched/deck"
                ? true
                : ready_for_release,
        priority,
        votersUp,
        votersDown,
        voters,
        setVotersUp,
        setVotersDown,
        openCompanyComments,
        totalComments,
        setTotalComments,
        setCompany,
        handleClickSeeLookALike,
        postProjectStatus,
        cid,
    };
};
