import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useStyles from "./style.js";
import classnames from "classnames";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
// UI components
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import { IconButton } from "@material-ui/core";
// Assets
import { ReactComponent as CheckMarkToaster } from "../../assets/icons/CheckMarkToaster.svg";
import { ReactComponent as CloseToaster } from "../../assets/icons/CloseToaster.svg";
import { ReactComponent as ToasterWarning } from "../../assets/icons/ToasterWarning.svg";
import LoaderJSON from "../../assets/lottie-jsons/loader_orange.json";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}

const Toaster = React.forwardRef(
    (
        {
            className,
            open,
            setIsOpenToaster,
            variant,
            onClose,
            children,
            conditionalOpen,
            ...props
        },
        ref,
    ) => {
        const classes = useStyles();
        const theme = useTheme();
        const { t } = useTranslation();
        const [isOpen, setIsOpen] = useState(false);

        const [timeoutId, settimeoutId] = useState();

        useEffect(() => {
            if (!!open) {
                setIsOpen(true);
                settimeoutId(
                    setTimeout(() => {
                        setIsOpen(false);
                        setIsOpenToaster(false);
                    }, 3500),
                );
            }
        }, [open]);

        useEffect(() => {
            return () => clearTimeout(timeoutId);
        }, []);

        const handleClose = () => {
            setIsOpen(false);
            onClose();
        };
        return (
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={conditionalOpen || isOpen}
                onClose={handleClose}
                key={"top-right"}
                classes={{
                    anchorOriginTopRight: classes.snackbar,
                    root: classes.root,
                }}
                TransitionComponent={TransitionDown}>
                <Alert
                    severity={variant === "loading" ? "success" : variant}
                    icon={
                        variant === "success" ? (
                            <CheckMarkToaster />
                        ) : variant === "loading" ? (
                            <div>
                                <Player
                                    autoplay
                                    loop
                                    src={LoaderJSON}
                                    style={{ height: 26, width: 47 }}>
                                    <Controls
                                        visible={false}
                                        buttons={[
                                            "play",
                                            "repeat",
                                            "frame",
                                            "debug",
                                        ]}
                                    />
                                </Player>
                            </div>
                        ) : (
                            <ToasterWarning
                                className={classnames({
                                    [classes.warningIconFill]:
                                        variant === "warning",
                                    [classes.errorIconFill]:
                                        variant === "error",
                                })}
                            />
                        )
                    }
                    variant="standard"
                    classes={{
                        standardSuccess: classes.standardSuccess,
                        standardWarning: classes.standardWarning,
                        standardError: classes.standardError,
                        icon:
                            variant === "success"
                                ? classes.icon
                                : classes.warningIcon,
                        message: classes.message,
                    }}>
                    {children}
                    <IconButton
                        edge="start"
                        sx={{ mr: 2 }}
                        color="inherit"
                        aria-label="menu"
                        className={classes.iconButton}
                        onClick={handleClose}>
                        <CloseToaster />
                    </IconButton>
                </Alert>
            </Snackbar>
        );
    },
);

Toaster.propTypes = {
    variant: PropTypes.oneOf(["success", "warning", "error", "loading"]),
};

Toaster.defaultProps = {
    size: "normal",
    dark: false,
};

export default Toaster;
