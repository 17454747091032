import React from "react";

export const Done = ({ strokeColor = "#C2C9D7" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            d="M20 6.5L9 17.5L4 12.5"
            stroke={strokeColor}
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
