import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
    getUserSelector,
    getCreditsSelector,
} from "../../store/auth/selectors";
import {
    checkProjectsRequest,
    fetchProjectsRequest,
    addItemToProjectRequest,
} from "../../store/projects/requests";
import {
    getCheckProjectsSelector,
    getCheckProjectsStatusSelector,
} from "../../store/projects/selectors";
// Hooks
import { useAuthHook } from "../../common/useAuthHook";

export const useAddToProjectMenuHook = ({
    id: itemId,
    name,
    type,
    setIsMenuOpened,
    items,
    currentSearch,
}) => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuthHook();
    const dispatch = useDispatch();
    const getUser = useSelector(getUserSelector);
    const getCheckedProjects = useSelector(getCheckProjectsSelector);
    const checkedProjectsStatusSelector = useSelector(
        getCheckProjectsStatusSelector,
    );
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [showNameTheSearchModal, setShowNameTheSearchModal] = useState(false);
    const [warningModalProjectSlug, setWarningModalProjectSlug] = useState();
    const [removeWarningModalOpened, setRemoveWarningModalOpened] =
        useState(false);
    const [warningModalResult, setRemoveWarningModalResult] = useState();
    const [selectedProject, setSelectedProject] = useState(null);

    const addSelectedCompanyToProject = (e, project) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        if (project.is_in_project) {
            return;
        } else {
            // let companies = company.id || company.cid;
            let items = itemId;
            // let pr = selectedProject ? selectedProject : project;
            localStorage.setItem("addToProjectCompany", name);
            localStorage.setItem("addToProjectProject", project.project);
            dispatch(
                addItemToProjectRequest({
                    project: project.slug,
                    items: items,
                    type: type,
                }),
            );
            setIsMenuOpened(false);
        }
    };

    const addSelectedCompaniesToProject = (
        e,
        items,
        selectedOrdersNames,
        value,
    ) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        localStorage.setItem("addToProjectCompany", selectedOrdersNames);
        localStorage.setItem("addToProjectProject", value.project);
        dispatch(
            addItemToProjectRequest({
                project: value.slug,
                items: items,
                type: "company",
            }),
        );
        setIsMenuOpened(false);
        // mixpanelTrackEvent("Add to Project performed in Orders", {
        //     "Order type": 'company',
        //     "Order names": selectedOrdersNames
        // });
    };

    const openCreateProjectDialog = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowCreateProjectModal(true);
    };

    const handleNewProjectCreated = project => {
        console.log(project);
        setSelectedProject(project);
        setShowCreateProjectModal(false);
        dispatch(fetchProjectsRequest());
        if (type === "search" && !currentSearch.search_name) {
            setShowNameTheSearchModal(true);
        }
    };

    const handleRemoveWarningModalChange = (
        useHackyBugfixForClosingTheModal = false,
    ) => {
        // START HACKY SOLUTION
        if (useHackyBugfixForClosingTheModal) {
            // Hack to fix a bug when you close the remove modal, if you click anywhere it will open again
            let elements = document.querySelectorAll('div[aria-hidden="true"]');
            elements &&
                elements.forEach((el, index) => {
                    if (index === 1) {
                        // let parent = el && el.parentNode;
                        // parent && parent.contains(el) && parent.removeChild(el)
                        el.style.display = "none";
                    }
                });
            document.body.style.overflow = "auto";
            document.body.style.padding = "0";
        }
        // END HACKY SOLUTION

        setRemoveWarningModalOpened(false);
    };

    const openRemoveWarningModal = (e, projectSlug, result) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        setWarningModalProjectSlug(projectSlug);
        setRemoveWarningModalResult(result);
        setRemoveWarningModalOpened(true);
    };

    return {
        t,
        isAuthenticated,
        getUser,
        getCheckedProjects,
        checkedProjectsStatusSelector,
        // openProjectMenu,
        showNameTheSearchModal,
        setShowNameTheSearchModal,
        showCreateProjectModal,
        setShowCreateProjectModal,
        openCreateProjectDialog,
        addSelectedCompanyToProject,
        addSelectedCompaniesToProject,
        openRemoveWarningModal,
        warningModalProjectSlug,
        removeWarningModalOpened,
        warningModalResult,
        handleRemoveWarningModalChange,
        handleNewProjectCreated,
    };
};
