import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            padding: `${spacing(5)}px ${spacing(4)}px`,
            [breakpoint.down("sm")]: {
                padding: `${spacing(3)}px`,
            },
        },
        links: {
            paddingBottom: spacing(1),
        },
        link: {
            "&:last-child": {
                marginRight: 0,
            },
            cursor: "pointer",
            marginTop: spacing(4),
            color: palette.grey.main,
            width: "calc(33% - 8px)",
            // width: "calc(25% - 12px)",
            marginRight: spacing(2),
            padding: spacing(3),
            borderRadius: 24,
            background: palette.primary.backgroundMedium,
        },
        active: {
            color: palette.secondary.light,
            position: "relative",
            background: palette.success.backgroundLight,
        },
        imgWrapper: {
            minHeight: 112,
        },
        subtitle: {
            marginBottom: spacing(1),
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        title: {
            marginBottom: spacing(2),
        },
        borderActive: {
            position: "absolute",
            content: "",
            height: 2,
            width: ({ tabView }) => (tabView === "profile" ? 54 : 121),
            background: palette.secondary.main,
            top: 25,
            left: -6,
            right: 0,
        },
        profileWrap: {
            padding: `${spacing(4)}px 0 ${spacing(6)}px 0`,
        },
        companyWrap: {
            borderTop: "1px solid #F1F1F1",
            padding: `${spacing(4)}px 0 ${spacing(6)}px 0`,
        },
        editLogo: {
            width: 250,
        },
        avatarWrapper: {
            width: 142,
            height: 142,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: palette.success.backgroundLight,
        },
        avatar: {
            overflow: "hidden",
            height: 142,
            width: 142,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        avatarImg: {
            width: 150,
        },
        companyIllustration: {
            width: 142,
            height: 142,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: palette.primary.backgroundMedium,
        },
        edit: {},
        maximumSize: {
            color: palette.grey.main,
        },
        uploadBtn: {
            margin: `${spacing(4)}px 0 ${spacing(2)}px 0`,
        },
        profileTitle: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        profileValue: {
            marginBottom: spacing(1),
        },
        changeLink: {
            fontFamily: "GT Flexa Mono, sans-serif",
            marginTop: spacing(1),
            cursor: "pointer",
        },
        infoRow: {
            "&:nth-child(n+2)": {
                marginTop: spacing(6),
            },
        },
        buttons: {
            marginTop: spacing(1),
        },
        textInput: {
            "& input": {
                width: 420,
            },
        },
        eye: {
            position: "absolute",
            right: spacing(1),
            cursor: "pointer",
        },
        noData: {
            marginTop: spacing(6),
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        },
        noDataLabel: {
            marginTop: spacing(6),
        },
        billingHeaderBoxes: {
            display: "flex",
            flexDirection: "row",
            marginTop: spacing(2),
        },
        billingHeaderBox: {
            width: "calc(33% - 12px)",
            marginRight: spacing(3),
            border: `1px solid ${palette.secondary.dark}`,
            borderRadius: spacing(3),
            padding: spacing(4),
            "&:last-child": {
                marginRight: 0,
            },
        },
        upgradePlan: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        nextIssueDate: {
            margin: `${spacing(1)}px 0`,
        },
        tableRow: {
            height: 74,
            color: palette.primary.dark,
            "&:nth-child(2)": {
                "& $teamUserAvatar": {
                    background: palette.primary.backgroundLight,
                },
            },
        },
        teamTable: {
            marginTop: spacing(5),
        },
        statusWrapper: {
            display: "flex",
            alignItems: "center",
            position: "relative",
        },
        warningIcon: {
            position: "absolute",
            left: -30,
        },
        teamUserAvatar: {
            width: 48,
            height: 48,
            borderRadius: "50%",
            background: palette.success.backgroundLight,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& img": {
                width: 31,
            },
        },
        email: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        moreIcon: {
            cursor: "pointer",
        },
        moreIconWrapper: {
            position: "relative",
            width: 24,
        },
        moreIconMenu: {
            position: "absolute",
            top: 20,
            right: 0,
            background: palette.common.white,
            border: `1px solid ${palette.text.backgroundDark}`,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: spacing(1),
            zIndex: 2,
            minWidth: 135,
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        moreIconMenuItem: {
            padding: `${spacing(2)}px ${spacing(3)}px`,
            "&:hover": {
                cursor: "pointer",
                color: palette.error.main,
            },
        },
        dialogScrollPaper: {
            padding: "80px 64px",
            maxWidth: 640,
            borderRadius: 16,
            background: palette.primary.backgroundLight,
        },
        closeButton: {
            position: "absolute !important",
            right: spacing(4),
            top: spacing(3),
            color: palette.grey[500],
        },
        dialodCtas: {
            marginTop: spacing(4),
        },
        credits: {
            marginRight: spacing(1),
        },
        creditsHeader: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginBottom: spacing(4),
        },
        availableCredits: {
            display: "flex",
            alignItems: "baseline",
        },
        upgradeYourPlan: {
            color: palette.primary.dark,
            cursor: "pointer",
            textDecoration: "underline",
        },
        profile: {
            display: "flex",
            justifyContent: "space-between",
        },
        availableCreditsBox: {
            background: palette.success.backgroundLight,
            borderRadius: spacing(2),
            padding: spacing(4),
            marginBottom: spacing(5),
            marginTop: spacing(3),
        },
        coins: {
            width: 32,
            height: 32,
            marginRight: spacing(2),
        },
        goBack: {
            marginBottom: spacing(3),
        },
    }),
);
