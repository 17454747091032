import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        main: {
            margin: `0 auto`,
            padding: spacing(4),
            maxWidth: "1136px",
            [breakpoint.down("md")]: {
                padding: spacing(3),
            },
        },
        header: {
            marginBottom: `${spacing(3)}px`,
            display: "flex",
            justifyContent: "space-between",
        },
        headerTitle: {
            marginBottom: `${spacing(1)}px`,
            display: "flex",
            flexDirection: "column",
        },
        blocks: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: spacing(2),
        },
        ml8: {
            marginLeft: spacing(1),
        },
    }),
);
