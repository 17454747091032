import React from "react";
// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Tag from "../../../../components/Tag/Tag";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";

const SubindustryDefinitionsModal = ({
    dialogOpened,
    onChange,
    mappedSubsectors,
    selectedSubsector,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    onChange(event, reason);
                }
            }}
            open={dialogOpened}>
            <IconButton className={classes.closeButton} onClick={onChange}>
                <img
                    src={CloseIcon}
                    alt={t("scope.step2")}
                    className={classes.stepIcon}
                />
            </IconButton>
            <Typography variant="paragraph" color="black" component="div">
                <strong>{t("industryProfile.subIndustryDefinitions")}</strong>
            </Typography>
            <Typography
                variant="bodyXs"
                className={classes.mono}
                color="text"
                component="div">
                {t("industryProfile.subIndustryDefinitionsLabel")}
            </Typography>
            <MuiDialogContent className={classes.dialogContent}>
                <div className={classes.subsectorsExplanationsWrapper}>
                    {mappedSubsectors &&
                        mappedSubsectors.map(subsector => (
                            <div
                                className={classes.subsectorTitleWrapper}
                                key={`${subsector.slug}-subsector`}>
                                <div
                                    className={classes.subsectorTitle}
                                    {...(selectedSubsector === subsector && {
                                        active: true,
                                    })}>
                                    <div
                                        className={classes.color}
                                        style={{
                                            background: subsector.color,
                                        }}></div>
                                    <Typography
                                        variant="body"
                                        color="secondaryDark"
                                        component="div">
                                        {subsector.name}
                                    </Typography>
                                </div>
                                <Typography
                                    variant="bodyXs"
                                    color="text"
                                    component="div">
                                    {subsector.description}
                                </Typography>
                            </div>
                        ))}
                </div>
            </MuiDialogContent>
        </Dialog>
    );
};
export default SubindustryDefinitionsModal;
