import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import usePrevious from "../../common/usePrevious";
// UI components
import TextField from "../TextField/TextField";
import Multiselect from "../Multiselect/Multiselect";
import FiltersModal from "../FiltersModal/FiltersModal";
import WarningModal from "../../pages/Results/components/modals/WarningModal/WarningModal";
import Typography from "../Typography/Typography";
import DownloadButton from "../DownloadButton/DownloadButton";
import AreYouSureModal from "../AreYouSureModal/AreYouSureModal";
// Hooks
import { useTranslation } from "react-i18next";
import useStyles from "../ResultsTabs/styles";
import { useAppEventTracking } from "../../common/useAppEventTracking";
//Assets
import { ReactComponent as SearchPlaceholderIcon } from "../../assets/icons/SearchMagnifier.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/ArrowDownBlue.svg";
import { ReactComponent as FilterModalIcon } from "../../assets/icons/FilterModalIcon.svg";
import { ReactComponent as SortDown } from "../../assets/icons/SortDown.svg";
import { ReactComponent as SortUp } from "../../assets/icons/SortUp.svg";
import { ReactComponent as ResultsOverview } from "../../assets/icons/ResultsOverview.svg";
import { ReactComponent as OpenIcon } from "../../assets/icons/OpenIcon.svg";
import { ReactComponent as ListViewIcon } from "../../assets/icons/ListViewIcon.svg";
import { ReactComponent as CardViewIcon } from "../../assets/icons/CardViewIcon.svg";
import { ReactComponent as ClusterIcon } from "../../assets/icons/ClusterIcon.svg";
import { ReactComponent as ClusterIconActive } from "../../assets/icons/ClusterIconActive.svg";
// Constants
import { sortOptions } from "../../constants/filters";
import { getPricingPage } from "../../constants/endpoints";
import { availableTabs } from "./tabs";
// Redux
import { getCompaniesChartStatusSelector } from "../../store/results/selectors";
import { getIndustryCompaniesChartStatusSelector } from "../../store/industries/selectors";
import { getTechnologyCompaniesChartStatusSelector } from "../../store/technologies/selectors";
import { exportCsvRequest } from "../../store/orders/requests";
import { actions as resultsActions } from "../../store/results/slice";
import Tab from "./Tab";

const ResultsTabs = ({
    isAuthenticated,
    searchResults,
    onFilterSelected,
    onSortChange,
    onSearch,
    filters,
    total,
    resetFilters,
    stageOptions,
    regionsOptions,
    handleRegionChange,
    fundingOptionsMin,
    fundingOptionsMax,
    yearOptionsMin,
    yearOptionsMax,
    teamsizeOptions,
    valuerScoreOptionsMin,
    valuerScoreOptionsMax,
    teamSizeOptionsMax,
    matchingScoreMin,
    matchingScoreMax,
    handleFieldChange,
    handleSearchChange,
    handleExcludeKeywordsChange,
    handleIncludeKeywordsChange,
    handleSectorChange,
    handleSubsectorChange,
    handleStageChange,
    handleLocationChange,
    resetFilter,
    handleFundingMinChange,
    handleYearMinChange,
    handleMatchingScoreMinChange,
    handleTeamSizeMinChange,
    allSectors,
    allSubsectors,
    tabs,
    setTabs,
    selectedFilters,
    allCountries,
    setSelectedFilters,
    setDisplayedFilters,
    displayedFilters,
    setModalFilters,
    isLookAlikeResults,
    searchType,
    user,
    isIndustry,
    isTechnology,
    isProject,
    countSelector,
    localStorageSlugPrefix,
    slug,
    request,
    countRequest,
    page,
    token,
    searchTypeLook,
    overviewVisibility,
    setOverviewVisibility,
    clusterVisibility,
    setClusterVisibility,
    isLikedCompanies,
    name,
    filterStats,
    handleSectorsClose,
    handleSubsectorsClose,
    preselectedSectors,
    setPreselectedSectors,
    preselectedSubsectors,
    setPreselectedSubsectors,
    getCountStatusSelector,
    activeView,
    setActiveView,
    companiesPageId,
    modalFilterNameValue,
    filterNameValue,
    filterMapping,
    currentSearchSid,
    isWorkspace,
    showClusterTab,
    selected,
    setSelected,
}) => {
    const prevActiveView = usePrevious(activeView);
    const [activeResultView, setActiveResultView] = useState(activeView);
    const { t } = useTranslation();
    const classes = useStyles({ user });
    let { state: locationState, search } = useLocation();
    const { mixpanelTrackEvent } = useAppEventTracking();
    const { appTrackEvent } = useAppEventTracking();
    const companiesChartStatus = useSelector(getCompaniesChartStatusSelector);
    const industryCompaniesChartStatus = useSelector(
        getIndustryCompaniesChartStatusSelector,
    );
    const technologyCompaniesChartStatus = useSelector(
        getTechnologyCompaniesChartStatusSelector,
    );
    const [searchValue, setSearchValue] = useState(
        sessionStorage.getItem(
            `${localStorageSlugPrefix}-${slug}-FilterSearch`,
        ),
    );
    const [displayedFiltersCount, setDisplayedFiltersCount] = useState(0);
    const [downloadProps, setDownloadProps] = useState();
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
        useState(false);
    const [isProjectsPage, setIsProjectsPage] = useState(false);
    const [clusterIconActive, setClusterIconActive] = useState(false);
    const [clusterTabHover, setClusterTabHover] = useState(false);
    // const [tabs, setTabs] = useState(availableTabs);

    const dispatch = useDispatch();
    useEffect(() => {
        prevActiveView !== activeView && setActiveResultView(activeView);
    }, [activeView]);

    useEffect(() => {
        let count = 0;
        Object.entries(displayedFilters).map(filter => {
            filter[0] !== "subsectors" &&
                filter[1] !== "" &&
                filter[1] &&
                filter[1].length !== 0 &&
                filter[1] !== undefined &&
                count++;
        });
        setDisplayedFiltersCount(count);
    }, [displayedFilters]);

    const storageResultsFilterSortValue = sessionStorage.getItem(
        `${localStorageSlugPrefix}-${slug}-FilterSortValue`,
    );
    const sortOption = sortOptions(isIndustry, isTechnology, isProject).find(
        result => result.slug === storageResultsFilterSortValue,
    );
    const storageResultsFilterSortDirection = sessionStorage.getItem(
        `${localStorageSlugPrefix}-${slug}-FilterSortDirection`,
    );
    const [sortValue, setSortValue] = useState(
        storageResultsFilterSortValue
            ? sortOption
            : sortOptions(isIndustry, isTechnology, isProject)[0],
    );
    const [sortDirection, setSortDirection] = useState(
        storageResultsFilterSortDirection
            ? storageResultsFilterSortDirection
            : "desc",
    );

    useEffect(() => {
        setIsProjectsPage(window.location.pathname.includes("projects"));
        return () => {
            setSortDirection(
                storageResultsFilterSortDirection
                    ? storageResultsFilterSortDirection
                    : "desc",
            );
            setSortValue(
                storageResultsFilterSortValue
                    ? sortOption
                    : sortOptions(isIndustry, isTechnology, isProject)[0],
            );
            setIsProjectsPage(false);
            setShowDownloadsExceededModal(false);
            setShowAreYouSureModal(false);
            setShowWarningModal(false);
            setDownloadProps();
            setDisplayedFiltersCount(0);
            setActiveResultView(activeView);
            setSearchValue();
        };
    }, []);

    const handleSelectChange = event => {
        setSortValue(
            sortOptions(isIndustry, isTechnology, isProject).find(
                option => option.name === event.target.value,
            ),
        );
        onSortChange(
            sortOptions(isIndustry, isTechnology, isProject).find(
                option => option.name === event.target.value,
            ).slug,
            sortDirection,
        );
    };

    const handleSearch = event => {
        setSearchValue(event.target.value);
        setDisplayedFilters(prevDisplayedFilters => {
            return {
                ...prevDisplayedFilters,
                search: event.target.value,
            };
        });
        setSelectedFilters(prevSelectedFilters => {
            return {
                ...prevSelectedFilters,
                search: event.target.value || "",
            };
        });
        onSearch(event.target.value);
        sessionStorage.setItem(
            `${localStorageSlugPrefix}-${slug}-FilterSearch`,
            event.target.value,
        );
        const tempDisplayedFilters = {
            ...displayedFilters,
            search: event.target.value,
        };
        if (event.target.value === "") {
            const urlParams = new URLSearchParams(search);
            let sid = urlParams.get("sid");
            let data;
            if (!!filterNameValue) {
                data = {
                    sid,
                    filterNameValue,
                    orderBy: sortValue.slug,
                    orderDirection: sortDirection,
                    page,
                    perPage: activeView === "card" ? 10 : 50,
                };
            } else {
                data = {
                    sid,
                    orderBy: sortValue && sortValue.slug,
                    orderDirection: sortDirection,
                    page,
                    perPage: activeView === "card" ? 10 : 50,
                };
            }
            dispatch(
                request({
                    slug,
                    data,
                    isSharedPageToken: token,
                    searchTypeLook: searchTypeLook,
                }),
            );
        }
    };

    const changeSortDirection = () => {
        setSortDirection(prevSortDirection =>
            prevSortDirection === "asc" ? "desc" : "asc",
        );
    };

    const { search: searchResultsValue } = selectedFilters;

    useEffect(() => {
        handleShowResults(1);
    }, [searchResultsValue]);

    useEffect(() => {
        onSortChange(
            sortValue
                ? sortValue.slug
                : isIndustry || isTechnology || isProject
                ? "founded_date"
                : "degree_of_fit",
            sortDirection,
        );
    }, [sortDirection]);

    let timeoutId;

    useEffect(() => {
        return () => clearTimeout(timeoutId);
    }, []);

    const handleShowResults = value => {
        dispatch(
            resultsActions.setResultsDisplayFilters({
                sid: isIndustry || isTechnology ? slug : currentSearchSid,
                displayedFilters: {
                    filterSid:
                        isIndustry || isTechnology ? slug : currentSearchSid,
                    sortValue: sortValue || [],
                    sortDirection: sortDirection || [],
                    sector: selectedFilters.sector || [],
                    subsector: selectedFilters.subsector || [],
                    subsectors: selectedFilters.subsectors || [],
                    stage: selectedFilters.stage || [],
                    fundingMin: selectedFilters.fundingMin || "",
                    fundingMax: selectedFilters.fundingMax || "",
                    teamsizeMin: selectedFilters.teamsizeMin || "",
                    teamsizeMax: selectedFilters.teamsizeMax || "",
                    location: selectedFilters.location || [],
                    yearMin: selectedFilters.yearMin || "",
                    yearMax: selectedFilters.yearMax || "",
                    matchingScoreMin: selectedFilters.matchingScoreMin,
                    matchingScoreMax: selectedFilters.matchingScoreMax || "",
                    regions: selectedFilters.regions || [],
                    search: selectedFilters.search || "",
                    excludeKeywords: selectedFilters.excludeKeywords || "",
                    includeKeywords: selectedFilters.includeKeywords || "",
                    storageResultsFilterNameValue: value || "",
                    storageResultsFilterPage: Number(page) || 1,
                },
            }),
        );
    };
    const handleClickOnSearch = () => {
        // show error modal
        if (!isAuthenticated) {
            setShowWarningModal(true);
        }
    };

    const handleOverviewTabClick = () => {
        setClusterVisibility(false);
        setOverviewVisibility(!overviewVisibility);
        if (!overviewVisibility) {
            appTrackEvent("Data overview opened");
        }
    };

    const handleClusterTabClick = () => {
        setClusterIconActive(true);
        setOverviewVisibility(false);
        setClusterVisibility(!clusterVisibility);
    };

    const getDownloadProps = (
        ids,
        type,
        page,
        pageType,
        doc,
        name,
        idType,
        idValue,
    ) => {
        setDownloadProps({
            ids,
            type,
            page,
            pageType,
            doc,
            name: "valuer_company_reports",
            idType,
            idValue,
        });
    };

    const download = () => {
        dispatch(exportCsvRequest(downloadProps));
        mixpanelTrackEvent(`Downloaded document`, {
            type: downloadProps.doc,
            page: isIndustry
                ? "industry"
                : isTechnology
                ? "technology"
                : isProject
                ? "project"
                : "results",
        });
    };

    const hanldeViewIconClick = function () {
        if (activeView === "list") {
            setActiveView("card");
            mixpanelTrackEvent("Companies view switched", { Type: "card" });
        } else if (activeView === "card") {
            setActiveView("list");
            mixpanelTrackEvent("Companies view switched", { Type: "list" });
        }
    };
    useEffect(() => {
        if (!clusterVisibility) {
            setClusterIconActive(false);
        }
    }, [clusterVisibility]);

    const handleSwitchTabs = id => {
        const newTabs = tabs.map(t => {
            if (t.id === id) {
                return { ...t, active: true };
            } else {
                return { ...t, active: false };
            }
        });
        setTabs(newTabs);
    };

    return (
        <div className={classes.headerFiltersMain}>
            <div className={classes.headerWrapper}>
                <div className={classes.header}>
                    {tabs.map(tab => (
                        <Tab
                            id={tab.id}
                            key={tab.id}
                            name={tab.name}
                            isActiveTab={tab.active}
                            onSwitchTabs={(e, id) => handleSwitchTabs(id)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ResultsTabs;
