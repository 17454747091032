import React, { useEffect, useState } from "react";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
// UI components
import Typography from "../../../../components/Typography/Typography.js";
import classnames from "classnames";
import { REQUEST_SUCCESS } from "../../../../constants/statuses";
import { useSelector } from "react-redux";
import { getCompanyStatusSelector } from "../../../../store/results/selectors";

const Profile = ({ company, isPreview }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const getCompanyStatus = useSelector(getCompanyStatusSelector);
    const [descHeights, setDescHeights] = useState({});
    const [descExpanded, setDescExpanded] = useState({});
    const [companyBenefits, setCompanyBenefits] = useState({
        business_model: "",
        value_proposition: "",
        product_portfolio: "",
        market_opportunities: "",
        achievements: "",
        sustainability: "",
    });
    let enrichedDescRefs = [];
    const setRef = ref => enrichedDescRefs.push(ref);

    const setEnrichedCompanyText = companyBenefitName => {
        let dataToHtml;
        let text;
        const content = company
            ? company[companyBenefitName]
            : company[companyBenefitName];

        setCompanyBenefits(prevState => {
            return {
                ...prevState,
                [companyBenefitName]: content,
            };
        });
    };
    const handleShowMore = companyBenefitName => {
        setDescExpanded({
            ...descExpanded,
            [companyBenefitName]: !descExpanded[companyBenefitName],
        });
    };

    useEffect(() => {
        Object.keys(companyBenefits).forEach(benefitName => {
            setEnrichedCompanyText(benefitName);
        });
    }, [company]);

    useEffect(() => {
        if (getCompanyStatus === REQUEST_SUCCESS && enrichedDescRefs.length) {
            Object.keys(companyBenefits).forEach(benefitName => {
                setDescHeights(prevState => {
                    return {
                        ...prevState,
                        [benefitName]: enrichedDescRefs.find(
                            ref =>
                                ref.getAttribute("data-name") === benefitName,
                        ).offsetHeight,
                    };
                });
            });
        }
    }, [getCompanyStatus, companyBenefits]);

    return (
        <div className={classes.root}>
            <div
                className={classes.benefitsCardsWrapper}
                data-intercom-target="Company details section">
                {!company.ready_for_release && (
                    <Typography
                        className={classes.benefitsCardsWrapperTitle}
                        variant="bodySmall">
                        {t("companyProfile.whatWillIGet")}
                    </Typography>
                )}
                {Object.keys(companyBenefits).map(companyBenefitName => (
                    <div
                        className={classes.benefitsCard}
                        key={`company-profile-benefit-${companyBenefitName}`}>
                        <div
                            className={classnames([
                                classes.imgBlock,
                                {
                                    [classes.imgBlockReleased]:
                                        company.ready_for_release,
                                },
                            ])}>
                            <Typography
                                className={classes.blockTitle}
                                variant="subtitle1"
                                color="textPrimary"
                                component="div">
                                {t(
                                    `companyProfile.${companyBenefitName}.title`,
                                )}
                            </Typography>

                            <div className={classes.companies}>
                                <div
                                    className={classes.descriptionBox}
                                    ref={setRef}
                                    data-name={companyBenefitName}>
                                    <Typography
                                        color={
                                            company.ready_for_release
                                                ? "textPrimary"
                                                : "textSecondary"
                                        }
                                        variant="body"
                                        component="div">
                                        <div
                                            className={classnames([
                                                {
                                                    [classes.descriptionExpanded]:
                                                        descExpanded[
                                                            companyBenefitName
                                                        ],
                                                    [classes.description]:
                                                        !descExpanded[
                                                            companyBenefitName
                                                        ],
                                                },
                                            ])}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    company.ready_for_release ||
                                                    isPreview
                                                        ? companyBenefits[
                                                              companyBenefitName
                                                          ]
                                                        : t(
                                                              `companyProfile.${companyBenefitName}.description`,
                                                          ),
                                            }}
                                        />
                                    </Typography>

                                    {descHeights[companyBenefitName] > 351 &&
                                        document.body.offsetWidth < 768 && (
                                            <div
                                                className={classnames([
                                                    classes.showMore,
                                                ])}>
                                                <Typography
                                                    variant="bodySmall"
                                                    color="textLighter"
                                                    onClick={() =>
                                                        handleShowMore(
                                                            companyBenefitName,
                                                        )
                                                    }>
                                                    {descExpanded[
                                                        companyBenefitName
                                                    ]
                                                        ? t(
                                                              "companyProfile.showLess",
                                                          )
                                                        : t(
                                                              "companyProfile.showMore",
                                                          )}
                                                </Typography>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Profile;
