import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing }) =>
    createStyles({
        root: {
            backgroundColor: palette.primary.backgroundLight,
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundSize: "auto 100%",
            color: palette.common.white,
        },
        logoSection: {
            display: "flex",
            flexDirection: "column",
        },
        logo: {
            alignSelf: "end",
            marginRight: spacing(7),
            marginTop: spacing(5),
        },
        content: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            "& :first-child": {
                marginBottom: spacing(7),
            },
            "& > *": {
                marginBottom: spacing(5),
            },
        },
        mlFailureCopy: {
            maxWidth: 543,
        },
        img: {
            maxWidth: "433px",
        },
    }),
);
