import { useTranslation } from "react-i18next";

export const Shared = ({ project, getUser, projects }) => {
    const { t } = useTranslation();
    const calculateCompanyScore = company => {
        let sum =
            company.success_potential_score +
            company.growth_score +
            company.maturity_score +
            company.innovation_score +
            company.sustainability_score;
        return (sum / 5) * 100;
    };
    const calculateAverageScore = companies => {
        let index = 0;
        let tempProject = {
            ...project,
        };
        tempProject.average_score = 0;
        tempProject.sectors = [];
        if (companies && companies.length > 0) {
            companies.forEach(company => {
                index++;
                tempProject.average_score += calculateCompanyScore(company);
                tempProject.sectors.push(...company.sectors);
            });
            tempProject.sectors = [...new Set(tempProject.sectors)];
            tempProject.average_score = (
                tempProject.average_score / index
            ).toFixed();
        }
        return tempProject.average_score;
    };
    const getProjectAverageScore = () => {
        let score = 0;
        project.companies &&
            project.companies.forEach(company => {
                score += calculateCompanyScore(company);
            });
        return (
            score / (project.companies && project.companies.length) || 0
        ).toFixed();
    };

    const keyCodes = {
        comma: 188,
        enter: [10, 13],
    };
    const emailReg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

    const formatNumber = value => {
        return value
            ? Math.round(value)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "";
    };
    const checkUserSubscription = subscription => {
        return (
            getUser &&
            getUser.subscription &&
            getUser.subscription.name === subscription
        );
    };

    const getSubscriptionStatus = () => {
        if (getUser && getUser.subscription) {
            return getUser.subscription.is_active ? "active" : "expired";
        }
        return null;
    };

    const getFreeTrialStatus = () => {
        if (
            getUser &&
            getUser.subscription &&
            getUser.subscription.free_trial
        ) {
            return getUser.subscription.free_trial.is_active
                ? "active"
                : "expired";
        }
        return null;
    };

    const getExpiredPlanMsg = () => {
        if (getFreeTrialStatus() === "expired") {
            return t("common.freeTrialExpired");
        } else if (getSubscriptionStatus() === "expired") {
            return t("common.subscriptionExpired");
        }
        return null;
    };

    const userSwitchedFromTrialToFree = () => {
        return (
            getUser &&
            getUser.subscription &&
            getUser.subscription.free_trial &&
            getUser.subscription.free_trial &&
            !getUser.subscription.free_trial.is_active
        );
    };

    const scrollTo = (offset, callback) => {
        const fixedOffset = offset.toFixed();
        const onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener("scroll", onScroll);
                callback();
            }
        };

        window.addEventListener("scroll", onScroll);
        onScroll();
        window.scrollTo({
            top: offset,
        });
    };

    return {
        calculateAverageScore,
        calculateCompanyScore,
        getProjectAverageScore,
        keyCodes,
        emailReg,
        formatNumber,
        checkUserSubscription,
        getSubscriptionStatus,
        getFreeTrialStatus,
        getExpiredPlanMsg,
        userSwitchedFromTrialToFree,
        scrollTo,
    };
};
