import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useStyles } from "./style.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// Components
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "../../../../components/Menu/Menu";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import PreviewModal from "../modals/PreviewModal";
import { AddToProjectMenu } from "../../../../components/AddToProjectMenu/AddToProjectMenu.js";
// Assets
import { ReactComponent as MoreIconNoFill } from "../../../../assets/icons/MoreIconNoFill.svg";
import Save from "../../../../assets/icons/SaveW.svg";
import Preview from "../../../../assets/icons/PreviewW.svg";
import FullProfile from "../../../../assets/icons/FullProfileW.svg";
import OrderReport from "../../../../assets/icons/OrderReportW.svg";
import Company from "../../../../assets/icons/Company.svg";
// Hooks
import { useAppEventTracking } from "../../../../common/useAppEventTracking";
// Redux
import { exportCsvRequest } from "../../../../store/orders/requests";
// Constants
import { getPricingPage } from "../../../../constants/endpoints";
import { useRouterHook } from "../../../../common/useRouterHook";
import { mixpanelTrackRequest } from "../../../../store/mixpanel/requests";
//Shared
import { Shared } from "../../../../utils/shared.js";

const TableRow = React.forwardRef(
    (
        {
            company,
            index,
            isItemSelected,
            selected,
            setSelected,
            selectedNames,
            setSelectedNames,
            className,
            user,
            isEnriched,
            isTechnology,
            isIndustry,
            showEnrichRequestModal,
            selectedCompany,
            setSelectedCompany,
            matchRemoved,
            projectSlug,
            openRemoveWarningModal,
            isProject,
            selectedCompanies,
            setSelectedCompanies,
            searchResultsSid,
            isLikedCompany,
            source,
            setShowDownloadsExceededModal,
            setShowAreYouSureModal,
            setDownloadProps,
            type,
            ...props
        },
        ref,
    ) => {
        const { formatNumber } = Shared({ getUser: user });

        const {
            name,
            deck,
            description,
            slug,
            ready_for_release,
            id,
            match,
            team,
            stage,
            funding,
            enrichment_request,
            year,
            isWorkspace = false,
        } = company;
        const classes = useStyles({ user });
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const { openPage } = useRouterHook();
        const [isLikedCompanies, setIsLikedCompanies] = useState(false);
        const [isMenuOpened, setIsMenuOpened] = useState(false);
        const [isPreviewModalOpened, setIsPreviewModalOpened] = useState(false);
        const [saveMenuOpened, setSaveMenuOpened] = useState(false);

        useEffect(() => {
            setIsLikedCompanies(
                window.location.pathname.includes("liked-companies"),
            );
        }, []);

        const handleDownloadToCsv = () => {
            setDownloadProps({
                ids: id,
                type: "companies",
                page: null,
                pageType: "sid",
                doc: "csv",
                name: `valuer_${name}_report`,
            });
            if (
                user &&
                user.downloads === 1 &&
                (user.subscription.plan_type === "free_trial" ||
                    user.subscription.plan_type === "free")
            ) {
                setShowDownloadsExceededModal(true);
            } else if (
                user &&
                user.subscription.plan_type === "free_trial" &&
                user.subscription.is_active
            ) {
                setShowAreYouSureModal(true);
            } else if (
                user &&
                user.subscription.plan_type === "free_trial" &&
                !user.subscription.is_active
            ) {
                window.location.href = getPricingPage(!!user);
            } else if (user && user.subscription.plan_type === "free") {
                setShowAreYouSureModal(true);
            } else {
                downloadToCsv("csv");
            }
        };

        const handleDownloadToPdf = e => {
            e.preventDefault();
            e.stopPropagation();
            setDownloadProps({
                ids: id,
                type: "companies",
                page: null,
                pageType: "sid",
                doc: "pdf",
                name: `valuer_${name}_report`,
            });
            if (
                user.downloads === 1 &&
                (user.subscription.plan_type === "free_trial" ||
                    user.subscription.plan_type === "free")
            ) {
                setShowDownloadsExceededModal(true);
            } else if (
                user.subscription.plan_type === "free_trial" &&
                user.subscription.is_active
            ) {
                setShowAreYouSureModal(true);
            } else if (
                user.subscription.plan_type === "free_trial" &&
                !user.subscription.is_active
            ) {
                window.location.href = getPricingPage(!!user);
            } else if (user.subscription.plan_type === "free") {
                setShowAreYouSureModal(true);
            } else {
                downloadToCsv("pdf");
            }
        };

        const [menuItems, setMenuItems] = useState([
            {
                label: (
                    <Typography variant="bodyXs" className={classes.mono}>
                        {t("common.downloadAsCSV")}
                    </Typography>
                ),
                onClick: handleDownloadToCsv,
                intercomId: "Download company as CSV",
            },
            {
                label: (
                    <Typography variant="bodyXs" className={classes.mono}>
                        {t("common.downloadAsPDF")}
                    </Typography>
                ),
                onClick: handleDownloadToPdf,
                intercomId: "Download company as PDF",
            },
            // {
            //   label: <Typography variant="bodyXs" className={classes.mono}>{t('companyProfile.enrichmentBanner.button.paying')}</Typography>,
            //   onClick: showEnrichRequestModal,
            //   disabled: enrichment_request,
            //   intercomId: "Request Enrichment button"
            // }
        ]);

        useEffect(() => {
            return () => {
                setIsLikedCompanies(false);
                setIsMenuOpened(false);
                setIsPreviewModalOpened(false);
                setMenuItems([]);
            };
        }, []);
        const isFreeUserOrGuest =
            !user ||
            (user &&
                user.subscription &&
                (user.subscription.name === "free" ||
                    !user.subscription.is_active));

        const { mixpanelTrackEvent } = useAppEventTracking();

        const downloadToCsv = doc => {
            let payload = {
                ids: id,
                type: "companies",
                doc,
                name: `valuer_${name}_report`,
                pageName: isIndustry
                    ? "industry"
                    : isTechnology
                    ? "technology"
                    : isProject
                    ? "project"
                    : isLikedCompany
                    ? "liked companies"
                    : "results",
            };
            dispatch(exportCsvRequest(payload));
            mixpanelTrackEvent(`Downloaded document`, {
                type: doc,
                page: isIndustry
                    ? "industry"
                    : isTechnology
                    ? "technology"
                    : isProject
                    ? "project"
                    : isLikedCompany
                    ? "liked companies"
                    : "results",
            });
        };

        const handleOpenCompany = e => {
            !e.ctrlKey && !e.metaKey && e.preventDefault();
            e.stopPropagation();
            const queryParams = new URLSearchParams(window.location.search);
            const pageParam = queryParams.get("page");
            sessionStorage.setItem(
                `${source}-${
                    isTechnology || isIndustry || isProject
                        ? projectSlug
                        : isLikedCompany
                        ? ""
                        : searchResultsSid
                }ScrollPosition`,
                window.scrollY,
            );
            sessionStorage.setItem(
                `${source}-${
                    isTechnology || isIndustry || isProject
                        ? projectSlug
                        : isLikedCompany
                        ? ""
                        : searchResultsSid
                }-FilterPage`,
                pageParam,
            );
            if (!isLikedCompanies) {
                // results
                !isTechnology &&
                    !isIndustry &&
                    sessionStorage.setItem("history", "results");
                //industries
                isIndustry &&
                    sessionStorage.setItem("history", "industryProfile");
                isIndustry &&
                    localStorage.setItem(
                        "industryCompanyFromRoute",
                        window.location.pathname,
                    );
                //technologies
                isTechnology &&
                    sessionStorage.setItem("history", "technologies");
                // projects
                isProject && sessionStorage.setItem("history", "project");
                localStorage.setItem("project_slug", projectSlug);
            }

            dispatch(
                mixpanelTrackRequest({
                    eventName: "Company profiles viewed under industry",
                    data: { "Company name": name },
                }),
            );
            !e.ctrlKey &&
                !e.metaKey &&
                openPage(`/companies/${slug}?cid=${id}`, isWorkspace);
        };

        const handleClick = event => {
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];
            let newSelectedNames = [];
            let newSelectedCompanies = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
                newSelectedNames = newSelectedNames.concat(selectedNames, name);
                newSelectedCompanies = newSelectedCompanies.concat(
                    selectedCompanies,
                    company,
                );
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
                newSelectedNames = newSelectedNames.concat(
                    selectedNames.slice(1),
                );
                newSelectedCompanies = newSelectedCompanies.concat(
                    selectedCompanies.slice(1),
                );
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
                newSelectedNames = newSelectedNames.concat(
                    selectedNames.slice(0, -1),
                );
                newSelectedCompanies = newSelectedCompanies.concat(
                    selectedCompanies.slice(0, -1),
                );
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
                newSelectedNames = newSelectedNames.concat(
                    selectedNames.slice(0, selectedIndex),
                    selectedNames.slice(selectedIndex + 1),
                );
                newSelectedCompanies = newSelectedCompanies.concat(
                    selectedCompanies.slice(0, selectedIndex),
                    selectedCompanies.slice(selectedIndex + 1),
                );
            }

            setSelected(newSelected);
            setSelectedNames(newSelectedNames);
            setSelectedCompanies(newSelectedCompanies);
        };

        const handleMenuClick = company => {
            setSelectedCompany(company);
            setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
        };

        const handleOpenCompanyPreview = () => {
            setIsPreviewModalOpened(true);
            setSelectedCompany(company);
            mixpanelTrackEvent("Preview Company");
        };

        const handleTableRowClicked = () => {
            console.log("table row clicked");
        };

        return (
            <div className={classnames([classes.tableRow])}>
                <div padding="checkbox" className={classes.tableCellCheckbox}>
                    <Checkbox
                        onClick={event => handleClick(event, company)}
                        className={classes.checkbox}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                            "aria-labelledby": `table-checkbox-${index}`,
                        }}
                    />
                </div>
                <div
                    id={`table-checkbox-${index}`}
                    scope="row"
                    padding="none"
                    className={classes.tableCellName}
                    onClick={
                        window.innerWidth < 768
                            ? handleOpenCompany
                            : handleOpenCompanyPreview
                    }>
                    <Typography
                        component="a"
                        variant="bodyXl"
                        color="black"
                        className={classes.companyName}>
                        <>
                            {/* Icon */}
                            {/* <div
							className={classes.iconWrapper}
						>
							<img
								src={Company}
								alt="Icon"
								className={classnames([classes.companyIcon, {[classes.companyIconEnriched]: isEnriched}])}
							/>
							{isEnriched && <img
								src={OrderReport}
								alt="Report ordered"
								className={classes.enrichedIcon}
							/>}
						</div> */}
                            <Tooltip
                                title={
                                    <Typography
                                        component="div"
                                        align="left"
                                        variant="bodyXs"
                                        color="black">
                                        {name}
                                    </Typography>
                                }
                                classes={{
                                    tooltip: classes.tooltip,
                                    arrow: classes.tooltipArrow,
                                }}
                                arrow
                                placement="bottom">
                                <span className={classes.nameText}>{name}</span>
                            </Tooltip>
                        </>
                    </Typography>
                </div>
                <Typography
                    component="div"
                    align="left"
                    variant="body"
                    className={classnames(
                        [
                            classes.tableCellDescription,
                            classes.description,
                            classes.hideOnHover,
                        ],
                        { [classes.hideIfSaveMenuIsOpened]: saveMenuOpened },
                    )}
                    onClick={
                        window.innerWidth < 768
                            ? handleOpenCompany
                            : handleOpenCompanyPreview
                    }>
                    <Typography
                        component="div"
                        align="left"
                        variant="body"
                        className={classes.tooltipDescriptionContent}>
                        {description}
                    </Typography>
                </Typography>
                <Typography
                    component="div"
                    align="left"
                    variant="body"
                    className={classnames(classes.showOnHover, {
                        [classes.showIfSaveMenuIsOpened]: saveMenuOpened,
                    })}>
                    {!enrichment_request && (
                        <Typography
                            className={classes.hoverIcon}
                            onClick={e => {
                                // TODO: check why company is null in enrich modal (probably because the selected company state is reset several times, this should be refactored). Way to reproduce the bug, remove the setSelectedCompany below and try to enrich through list view.
                                setSelectedCompany(company);
                                showEnrichRequestModal(e, company);
                            }}>
                            <img src={OrderReport} alt="Order report" />
                            <span>{t("workspace.listView.orderReport")}</span>
                        </Typography>
                    )}
                    {ready_for_release && (
                        <Typography
                            className={classes.hoverIcon}
                            onClick={handleDownloadToPdf}>
                            <img src={OrderReport} alt="Order report" />
                            <span>{t("workspace.listView.seeReport")}</span>
                        </Typography>
                    )}
                    <Typography
                        className={classes.hoverIcon}
                        href={`/companies/${slug}?cid=${id}`}
                        onClick={e => handleOpenCompany(e)}>
                        <img src={FullProfile} alt="Full profile" />
                        <span>{t("workspace.listView.fullProfile")}</span>
                    </Typography>
                    <Typography
                        className={classes.hoverIcon}
                        onClick={handleOpenCompanyPreview}>
                        <img src={Preview} alt="Preview" />
                        <span>{t("workspace.listView.preview")}</span>
                    </Typography>
                    {/* <Typography className={classes.hoverIcon}>
                <img
                    src={Save}
                    alt="Save"
                />
                <span>{t("workspace.listView.save")}</span>
            </Typography> */}
                    <AddToProjectMenu
                        buttonType="save"
                        company={company}
                        isMenuOpenedListener={setSaveMenuOpened}
                        type={type}
                    />
                </Typography>
                {/* Funding */}
                {/* <Typography
				component="div"
				className={classnames([classes.tableCell2, classes.hideOnHover, classes.hideOnMobile], {[classes.hideIfSaveMenuIsOpened]: saveMenuOpened})}
				align="left"
				variant="bodySmall"
				color={funding ? "success" : "textLighter"}
				onClick={window.innerWidth < 768 ? handleOpenCompany : handleOpenCompanyPreview}
			>
				{(deck && deck.funding) ? (isNaN(deck.funding) ? deck.funding : formatNumber(deck.funding)) : funding ? parseInt(funding).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " USD" :
					<Tooltip
						title={
							<Typography
								component="div"
								align="left"
								variant="bodyXs"
								dangerouslySetInnerHTML={{__html: t('results.naTooltip')}}
							/>
						}
						classes={{tooltip: classes.tooltip, arrow: classes.tooltipArrow}}
						arrow
						placement="bottom"
					>
						<span>N/A</span>
					</Tooltip>}
			</Typography> */}
                {/* Year */}
                <Typography
                    component="div"
                    className={classnames(
                        [
                            classes.tableCell2,
                            classes.hideOnHover,
                            classes.hideOnMobile,
                        ],
                        { [classes.hideIfSaveMenuIsOpened]: saveMenuOpened },
                    )}
                    align="center"
                    variant="bodySmall"
                    color="textLighter"
                    onClick={
                        window.innerWidth < 768
                            ? handleOpenCompany
                            : handleOpenCompanyPreview
                    }>
                    {year ? (
                        year
                    ) : (
                        <Tooltip
                            title={
                                <Typography
                                    component="div"
                                    align="left"
                                    variant="bodyXs"
                                    dangerouslySetInnerHTML={{
                                        __html: t("results.naTooltip"),
                                    }}
                                />
                            }
                            classes={{
                                tooltip: classes.tooltip,
                                arrow: classes.tooltipArrow,
                            }}
                            arrow
                            placement="bottom">
                            <span>N/A</span>
                        </Tooltip>
                    )}
                </Typography>
                {/* Team */}
                {/* <Typography
				component="div"
				className={classnames([classes.tableCell2, classes.hideOnHover, classes.hideOnMobile], {[classes.hideIfSaveMenuIsOpened]: saveMenuOpened})}
				align="left"
				variant="bodySmall"
				color="textLighter"
				onClick={window.innerWidth < 768 ? handleOpenCompany : handleOpenCompanyPreview}
			>
				{team ? team :
					<Tooltip
						title={
							<Typography
								component="div"
								align="left"
								variant="bodyXs"
								dangerouslySetInnerHTML={{__html: t('results.naTooltip')}}
							/>
						}
						classes={{tooltip: classes.tooltip, arrow: classes.tooltipArrow}}
						arrow
						placement="bottom"
					>
						<span>N/A</span>
					</Tooltip>}
			</Typography> */}
                {/* Steage */}
                {/* <Typography
				component="div"
				className={classnames([classes.tableCell2, classes.hideOnHover, classes.hideOnMobile], {[classes.hideIfSaveMenuIsOpened]: saveMenuOpened})}
				align="left"
				variant="bodySmall"
				color="textLighter"
				onClick={window.innerWidth < 768 ? handleOpenCompany : handleOpenCompanyPreview}
			>
				{(stage && stage !== "Unspecified") ? stage :
					<Tooltip
						title={
							<Typography
								component="div"
								align="left"
								variant="bodyXs"
								dangerouslySetInnerHTML={{__html: t('results.naTooltip')}}
							/>
						}
						classes={{tooltip: classes.tooltip, arrow: classes.tooltipArrow}}
						arrow
						placement="bottom"
					>
						<span>N/A</span>
					</Tooltip>}
			</Typography> */}
                <Typography
                    component="div"
                    className={classnames(
                        [
                            classes.tableCell2,
                            classes.hideOnHover,
                            classes.hideOnMobile,
                        ],
                        { [classes.hideIfSaveMenuIsOpened]: saveMenuOpened },
                    )}
                    align="center"
                    variant="bodySmall"
                    color="textLighter"
                    onClick={
                        window.innerWidth < 768
                            ? handleOpenCompany
                            : handleOpenCompanyPreview
                    }>
                    {company.location ? (
                        company.location
                    ) : (
                        <Tooltip
                            title={
                                <Typography
                                    component="div"
                                    align="left"
                                    variant="bodyXs"
                                    dangerouslySetInnerHTML={{
                                        __html: t("results.naTooltip"),
                                    }}
                                />
                            }
                            classes={{
                                tooltip: classes.tooltip,
                                arrow: classes.tooltipArrow,
                            }}
                            arrow
                            placement="bottom">
                            <span>N/A</span>
                        </Tooltip>
                    )}
                </Typography>
                {match ? (
                    <Typography
                        component="div"
                        className={classnames(
                            [
                                classes.tableCell2,
                                classes.tableCellMatch,
                                classes.hideOnHover,
                            ],
                            {
                                [classes.hideIfSaveMenuIsOpened]:
                                    saveMenuOpened,
                            },
                        )}
                        align="center"
                        variant="body"
                        color={
                            match < 60
                                ? "warning"
                                : match < 40
                                ? "danger"
                                : "success"
                        }
                        onClick={
                            window.innerWidth < 768
                                ? handleOpenCompany
                                : handleOpenCompanyPreview
                        }>
                        {match}%
                    </Typography>
                ) : null}
                {/* <div className={classes.tableCell1} align="right"> */}
                {/*<Menu*/}
                {/*	btnClassName={classes.moreButton}*/}
                {/*	onChange={() => handleMenuClick(company)}*/}
                {/*	menuItems={menuItems}*/}
                {/*>*/}
                {/*	<MoreIconNoFill*/}
                {/*		className={classnames([classes.moreIcon, {[classes.moreIconOpened]: isMenuOpened}])}/>*/}
                {/*</Menu>*/}
                {/* </div> */}
                {isPreviewModalOpened && (
                    <PreviewModal
                        dialogOpened={isPreviewModalOpened}
                        onChange={() => setIsPreviewModalOpened(false)}
                        isProject={isProject}
                        isIndustry={isIndustry}
                        isTechnology={isTechnology}
                        data={company}
                        handleOpenCompany={handleOpenCompany}
                        showEnrichRequestModal={showEnrichRequestModal}
                        user={user}
                        projectSlug={projectSlug}
                        type="company"
                        onOpen={handleOpenCompany}
                    />
                )}
            </div>
        );
    },
);

export default TableRow;
