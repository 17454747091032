import React from "react";
// UI components
import Box from "@material-ui/core/Box";
import Typography from "../../../../../components/Typography/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Grow from "@material-ui/core/Grow";
import Button from "../../../../../components/Button/Button";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useRouterHook } from "../../../../../common/useRouterHook";

const WarningModal = ({ onClose, dialogOpened, backToSearchPage }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const handleGoBack = () => {
        if (!!backToSearchPage) {
            openPage("/results");
        } else {
            window.location.href = "https://www.valuer.ai";
        }
    };
    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialogFilter,
                scrollPaper: classes.dialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    onClose(event, reason);
                }
            }}
            open={dialogOpened}>
            <MuiDialogTitle
                disableTypography
                className={classes.dialogTitleWrapper}>
                <Typography variant="subtitle1" className={classes.dialogTitle}>
                    <strong>
                        {backToSearchPage === true
                            ? t("warningModal.goBackToSearch")
                            : t("warningModal.goBackToValuerAI")}
                    </strong>
                </Typography>
                <Typography
                    variant="subtitle2"
                    className={classes.dialogSubtitle}>
                    {t("warningModal.progressWontBeSaved")}
                </Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <Box display="flex" justifyContent="flex-end">
                    <Button onClick={onClose} variant="tertiary">
                        {t("warningModal.noCancel")}
                    </Button>
                    <Button
                        onClick={handleGoBack}
                        className={classes.goBackButton}>
                        {backToSearchPage === true
                            ? t("warningModal.yesGoBack")
                            : t("warningModal.yesGoBackAI")}
                    </Button>
                </Box>
            </MuiDialogContent>
        </Dialog>
    );
};
export default WarningModal;
