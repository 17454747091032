import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    buttonText: {
        textTransform: "none!important",
        padding: `4px 12px`,
        borderRadius: spacing(1),
        background: palette.primary.backgroundLight,
    },
    buttonExpanded: {
        background: palette.text.backgroundMain,
        padding: `4px 12px`,
        borderRadius: spacing(1),
    },
    collapseArrow: {
        pointerEvents: "all !important",
    },
}));
