import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAddItemToProjectStatusSelector,
    getCheckProjectsSelector,
    getCheckProjectsStatusSelector,
} from "../../store/projects/selectors";
import {
    addItemToProjectRequest,
    fetchProjectsRequest,
    checkProjectsRequest,
} from "../../store/projects/requests";
import { useAppEventTracking } from "../../common/useAppEventTracking";
// Constants
import { REQUEST_SUCCESS } from "../../constants/statuses";

export const useAddToProjects = ({ order, user }) => {
    const dispatch = useDispatch();
    const { mixpanelTrackEvent } = useAppEventTracking();
    const addItemToProjectStatus = useSelector(
        getAddItemToProjectStatusSelector,
    );
    const getCheckedProjects = useSelector(getCheckProjectsSelector);
    const getCheckedProjectsStatusSelector = useSelector(
        getCheckProjectsStatusSelector,
    );
    const [checkedProjects, setCheckedProjects] = useState(getCheckedProjects);
    const [showProjectPopup, setShowProjectPopup] = useState(false);

    useEffect(() => {
        getCheckedProjectsStatusSelector === REQUEST_SUCCESS &&
            setCheckedProjects(getCheckedProjects);
    }, [getCheckedProjectsStatusSelector]);

    useEffect(() => {
        if (addItemToProjectStatus === REQUEST_SUCCESS) {
            setShowProjectPopup(false);
        }
    }, [addItemToProjectStatus]);

    const [selectedProject, setSelectedProject] = useState(null);
    const [dialogOpened, setDialogOpened] = useState(false);
    const handleAddToProject = e => {
        e.preventDefault();
        e.stopPropagation();
        !showProjectPopup &&
            dispatch(
                checkProjectsRequest({ companyId: order.oid, page: "orders" }),
            );
        if (showProjectPopup) {
            // setSelectedCompanyIndex(null);
            setShowProjectPopup(false);
        } else {
            // setSelectedCompanyIndex(cardIndex);
            setShowProjectPopup(true);
        }

        // setShowProjectPopup(!showProjectPopup);
    };

    const addSelectedCompanyToProject = (e, value) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedProject(value);
        if (value.is_in_project) {
            return;
        } else {
            let companies = [order.oid];
            localStorage.setItem("addToProjectCompany", order.name);
            localStorage.setItem("addToProjectProject", value.project);
            dispatch(
                addItemToProjectRequest({
                    project: value.slug,
                    companies: companies,
                    page: "orders",
                }),
            );
            mixpanelTrackEvent("Add to Project performed in Orders", {
                "Order type": "company",
                "Order name": order.name,
            });
        }
    };
    const addSelectedCompaniesToProject = (
        e,
        companies,
        selectedOrdersNames,
        value,
    ) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedProject(value);
        localStorage.setItem("addToProjectCompany", selectedOrdersNames);
        localStorage.setItem("addToProjectProject", value.project);
        dispatch(
            addItemToProjectRequest({
                project: value.slug,
                companies: companies,
                page: "orders",
            }),
        );
        mixpanelTrackEvent("Add to Project performed in Orders", {
            "Order type": "company",
            "Order names": selectedOrdersNames,
        });
    };
    const handleNewProjectCreated = () => {
        setDialogOpened(false);
        dispatch(fetchProjectsRequest());
    };
    const [topUpCreditsModalShow, setTopUpCreditsModalShow] = useState(false);
    const [dialogOpenedResult, setDialogOpenedResult] = useState();

    const openCreateProjectDialog = e => {
        e.preventDefault();
        e.stopPropagation();
        setDialogOpened(true);
        setDialogOpenedResult(order);
    };
    const handleToggleDialog = () => {
        setDialogOpened(false);
    };

    const handleShowTopUpModal = () => {
        setTopUpCreditsModalShow(true);
    };
    const handleClosingTopUpCreditsDialog = () => {
        setTopUpCreditsModalShow(false);
    };
    const handleBuyingTopUpCreditsDialog = () => {
        setTopUpCreditsModalShow(false);
    };

    return {
        handleAddToProject,
        selectedProject,
        dialogOpened,
        addSelectedCompanyToProject,
        addSelectedCompaniesToProject,
        handleNewProjectCreated,
        topUpCreditsModalShow,
        dialogOpenedResult,
        openCreateProjectDialog,
        handleToggleDialog,
        handleShowTopUpModal,
        handleClosingTopUpCreditsDialog,
        handleBuyingTopUpCreditsDialog,
        showProjectPopup,
        setShowProjectPopup,
        getCheckedProjectsStatusSelector,
        checkedProjects,
        setDialogOpenedResult,
    };
};
