import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useStyles from "./style.js";
import theme from "../../theme";
import { capitalize } from "@material-ui/core";

const Typography = React.forwardRef((props, ref) => {
    const {
        align,
        className: classNameProp,
        color,
        component: componentProp,
        gutterBottom,
        headlineMapping,
        noWrap,
        variant,
        textDecoration = "",
        weight = "regular",
        fontStyle = "normal",
        inline,
        cursor,
        testId,
        ...other
    } = props;

    const classes = useStyles(theme);

    const isTitle =
        variant === "h1" ||
        variant === "h2" ||
        variant === "h3" ||
        variant === "h4" ||
        variant === "h5" ||
        variant === "h6" ||
        variant === "subtitle1" ||
        variant === "subtitle2" ||
        variant === "subtitleResult";
    const className = classNames(
        classNameProp,
        classes.root,
        classes[variant],
        {
            [classes[`weight${capitalize(weight)}`]]: weight !== "regular",
            [classes[`fontStyle${capitalize(fontStyle)}`]]:
                fontStyle !== "normal",
            [classes[`textDecoration${capitalize(textDecoration)}`]]:
                !!textDecoration,
            [classes.noWrap]: noWrap,
            [classes.colorTitle]: isTitle && color === "",
            [classes.colorText]: !isTitle && color === "",
            [classes[`color${capitalize(color)}`]]: color !== "",
            [classes.gutterBottom]: gutterBottom,
            [classes.inline]: inline,
            [classes.cursor]: cursor,
            [classes[`align${capitalize(align)}`]]: align !== "inherit",
        },
    );

    const Component = componentProp || "span";

    return (
        <Component
            className={className}
            test-id={testId}
            ref={ref}
            {...other}
        />
    );
});

Typography.propTypes = {
    align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
    textDecoration: PropTypes.oneOf(["underline", "lineThrough", "none"]),
    weight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    fontStyle: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ]),
    gutterBottom: PropTypes.bool,
    headlineMapping: PropTypes.object,
    noWrap: PropTypes.bool,
    inline: PropTypes.bool,
};

Typography.defaultProps = {
    align: "inherit",
    color: "",
    gutterBottom: false,
    headlineMapping: {
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        paragraph: "p",
        bodyXl: "p",
        body: "p",
        bodySmall: "p",
        bodyXs: "p",
        label: "p",
    },
    noWrap: false,
    inline: false,
    variant: "body1",
};

export default Typography;

Typography.displayName = "Typography";
