import React, { useEffect, useState } from "react";
import classnames from "classnames";
// UI components
import Typography from "../Typography/Typography";
import Button from "../Button/Button";
import TextField from "../TextField/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Modal from "../Modal/Modal";
//Assets
import CloseIcon from "../../assets/icons/CloseIcon.svg";
import CheckMark from "../../assets/images/CheckMark.png";

const AddNewModal = ({
    dialogOpened,
    onChange,
    title,
    titleInputLabel,
    descriptionInputLabel,
    onActionClick,
    actionButtonText,
    cancelButtonText,
    succeeded,
    requestPending,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [description, setDescription] = useState("");
    const [titleValue, setTitleValue] = useState("");

    const handleActionClick = e => {
        e.preventDefault();
        e.stopPropagation();
        onActionClick(titleValue, description);
    };

    const closeModal = e => {
        e.preventDefault();
        e.stopPropagation();
        onChange();
    };

    const handleTitle = event => {
        setTitleValue(event.target.value);
    };

    const handleDescription = event => {
        setDescription(event.target.value);
    };

    useEffect(() => {
        setIsBtnDisabled(!description || !titleValue);
    }, [description, titleValue]);

    useEffect(() => {
        setDescription("");
        setTitleValue("");
    }, [dialogOpened]);

    return (
        <Modal
            onClose={closeModal}
            dialogOpened={dialogOpened}
            closeButtonIcon={CloseIcon}
            paddingSpace="32px"
            borderRadius="16px"
            classnames={{
                paperScrollPaper: classes.paperScrollPaper,
            }}>
            <div className={classes.root}>
                <Typography
                    component="div"
                    className={classes.sureText}
                    variant="h6"
                    color="black">
                    {title}
                </Typography>
                {!succeeded ? (
                    <>
                        <div className={classes.flexColumn}>
                            <Typography
                                variant="bodySmall"
                                className={classnames([
                                    classes.projectTitle,
                                    classes.mono,
                                ])}>
                                {titleInputLabel}*
                            </Typography>
                            <TextField
                                onChange={handleTitle}
                                value={titleValue}
                                className={classes.textfieldWrapper}
                                inputProps={{
                                    classes: {
                                        root: classes.textfield,
                                    },
                                    fullWidth: true,
                                }}
                            />
                        </div>
                        <div className={classes.flexColumn}>
                            <Typography
                                variant="bodySmall"
                                className={classnames([
                                    classes.projectDescription,
                                    classes.mono,
                                ])}>
                                {descriptionInputLabel}*
                            </Typography>
                            <TextareaAutosize
                                className={classes.textarea}
                                minRows={6}
                                value={description}
                                onChange={handleDescription}
                            />
                        </div>
                        <div className={classes.cancelAndCreate}>
                            <Button
                                className={classes.cancelButton}
                                variant="tertiary"
                                disabled={false}
                                onClick={e => closeModal(e)}>
                                {cancelButtonText}
                            </Button>
                            <Button
                                variant="primary"
                                disabled={isBtnDisabled}
                                onClick={handleActionClick}
                                loading={requestPending}>
                                {actionButtonText}
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <img
                            src={CheckMark}
                            alt={title}
                            className={classes.thankYouImage}
                        />
                        <Typography
                            component="div"
                            className={classes.thankYouText}
                            align="center"
                            variant="subtitle2"
                            color="black">
                            {t("technologies.suggestTechnologyThankYou")}
                        </Typography>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default AddNewModal;
