import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// UI components
import Box from "@material-ui/core/Box";
import Typography from "../Typography/Typography";
import Button from "../Button/Button";
import TextField from "../TextField/TextField";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Multiselect from "../Multiselect/Multiselect";
import LockAlert from "../LockAlert/LockAlert.js";
import Modal from "../Modal/Modal.js";
//Assets
import CloseIcon from "../../assets/icons/CloseIcon.svg";
import { ReactComponent as ResetIcon } from "../../assets/icons/ResetFilter.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDownGrey.svg";

//Redux
import { fetchSectorsRequest } from "../../store/sectors/requests";
import { getCountriesRequest } from "../../store/countries/requests";
import _ from "lodash";
import classnames from "classnames";
import { getPricingPage } from "../../constants/endpoints";
import { REQUEST_PENDING } from "../../constants/statuses";

const FiltersModal = ({
    dialogOpened,
    onClose,
    onShowResults,
    onShowFilters,
    stageOptions,
    regionsOptions,
    fundingOptionsMin,
    fundingOptionsMax,
    yearOptionsMin,
    yearOptionsMax,
    teamsizeOptions,
    valuerScoreOptionsMin,
    valuerScoreOptionsMax,
    teamSizeOptionsMax,
    handleSearchChange,
    handleExcludeKeywordsChange,
    handleIncludeKeywordsChange,
    handleSectorChange,
    handleSubsectorChange,
    handleStageChange,
    handleLocationChange,
    handleRegionChange,
    handleFieldChange,
    resetFilter,
    handleFundingMinChange,
    handleYearMinChange,
    handleMatchingScoreMinChange,
    handleTeamSizeMinChange,
    allSectors,
    allSubsectors,
    selectedFilters,
    preselectedSectors,
    setPreselectedSectors,
    preselectedSubsectors,
    setPreselectedSubsectors,
    allCountries,
    displayedFilters,
    totalCount,
    user,
    isIndustry,
    countSelector,
    filterStats,
    resetFilters,
    setSelectedFilters,
    handleSectorsClose,
    handleSubsectorsClose,
    getCountStatusSelector,
    filterMapping,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const getFilterResultsCount = useSelector(countSelector);
    const [collapsedSectors, setCollapsedSectors] = useState(allSectors);
    const [expandedSectors, setExpandedSectors] = useState(allSectors);
    const [collapsedRegions, setCollapsedRegions] = useState(regionsOptions);
    const [expandedRegions, setExpandedRegions] = useState(regionsOptions);
    const [regions, setRegions] = useState(collapsedRegions);
    const [sectors, setSectors] = useState(collapsedSectors);
    const [collapsedStages, setCollapsedStages] = useState(stageOptions);
    const [expandedStages, setExpandedStages] = useState(stageOptions);
    const [stages, setStages] = useState(collapsedStages);
    const [collapsedLocations, setCollapsedLocations] = useState(allCountries);
    const [expandedLocations, setExpandedLocations] = useState(allCountries);
    const [locations, setLocations] = useState(collapsedLocations);
    const [collapsedSubsectors, setCollapsedSubsectors] =
        useState(allSubsectors);
    const [expandedSubsectors, setExpandedSubsectors] = useState(allSubsectors);
    const [subsectors, setSubsectors] = useState(collapsedSubsectors);
    const [selectedFiltersCnt, setSelectedFiltersCnt] = useState(0);

    useEffect(() => {
        return () => {
            setCollapsedSectors([]);
            setExpandedSectors([]);
            setCollapsedRegions([]);
            setExpandedRegions([]);
            setRegions([]);
            setSectors([]);
            setCollapsedStages([]);
            setExpandedStages([]);
            setStages([]);
            setCollapsedLocations([]);
            setExpandedLocations([]);
            setLocations([]);
            setCollapsedSubsectors([]);
            setExpandedSubsectors([]);
            setSubsectors([]);
        };
    }, []);

    useEffect(() => {
        let sectorsArray = [];
        sectorsArray =
            allSectors &&
            allSectors.map((sector, i) => {
                if (
                    filterStats &&
                    filterStats.sectors &&
                    filterStats.sectors[sector.name]
                ) {
                    return {
                        ...sector,
                        count: filterStats.sectors[sector.name]
                            ? filterStats.sectors[sector.name]
                            : 0,
                    };
                } else {
                    return { ...sector, count: 0 };
                }
            });
        let subsectorsArray = [];
        subsectorsArray =
            filterStats &&
            filterStats.subsectors &&
            Object.entries(filterStats.subsectors).map(subsector => {
                if (subsector[1] > 0) {
                    return {
                        name: subsector[0],
                        count: subsector[1] ? subsector[1] : 0,
                    };
                } else {
                    return { name: subsector[0], count: 0 };
                }
            });
        let stagesArray = [];
        stagesArray =
            stageOptions &&
            stageOptions.map((stage, i) => {
                if (
                    filterStats &&
                    filterStats.investment_stages &&
                    filterStats.investment_stages[stage.name]
                ) {
                    return {
                        ...stage,
                        count: filterStats.investment_stages[stage.name]
                            ? filterStats.investment_stages[stage.name]
                            : 0,
                    };
                } else {
                    return { ...stage, count: 0 };
                }
            });
        let locationsArray = [];
        locationsArray =
            allCountries &&
            allCountries.map((location, i) => {
                if (
                    filterStats &&
                    filterStats.countries &&
                    filterStats.countries[location.name]
                ) {
                    return {
                        ...location,
                        count: filterStats.countries[location.name]
                            ? filterStats.countries[location.name]
                            : 0,
                    };
                } else {
                    return { ...location, count: 0 };
                }
            });
        let regionsArray = [];
        regionsArray =
            regionsOptions &&
            regionsOptions.map((region, i) => {
                if (
                    filterStats &&
                    filterStats.regions &&
                    filterStats.regions[region.name]
                ) {
                    return {
                        ...region,
                        count: filterStats.regions[region.name]
                            ? filterStats.regions[region.name]
                            : 0,
                    };
                } else {
                    return { ...region, count: 0 };
                }
            });
        const sectorsSorted = sectorsArray
            ? sectorsArray.sort((a, b) => b.count - a.count)
            : [];
        const filteredSectorsArray = sectorsSorted
            ? sectorsSorted.filter(obj => obj.count !== 0)
            : [];
        const stagesSorted = stagesArray
            ? stagesArray.sort((a, b) => b.count - a.count)
            : [];
        const filteredStagesArray = stagesSorted
            ? stagesSorted.filter(obj => obj.count !== 0)
            : [];
        const locationsSorted = locationsArray
            ? locationsArray.sort((a, b) => b.count - a.count)
            : [];
        const filteredLocationsArray = locationsSorted
            ? locationsSorted.filter(obj => obj.count !== 0)
            : [];
        const regionsSorted = regionsArray
            ? regionsArray.sort((a, b) => b.count - a.count)
            : [];
        const filteredRegionsArray = regionsSorted
            ? regionsSorted.filter(obj => obj.count !== 0)
            : [];
        const subsectorsSorted = subsectorsArray
            ? subsectorsArray.sort((a, b) => b.count - a.count)
            : [];
        const filteredSubsectorsArray = subsectorsSorted
            ? subsectorsSorted.filter(obj => obj.count !== 0)
            : [];

        setCollapsedSectors([
            ...filteredSectorsArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setSectors([
            ...filteredSectorsArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setExpandedSectors([...sectorsSorted]);
        setCollapsedStages([
            ...filteredStagesArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setStages([
            ...filteredStagesArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setExpandedStages([...stagesSorted]);
        setCollapsedLocations([
            ...filteredLocationsArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setLocations([
            ...filteredLocationsArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setExpandedLocations([...locationsSorted]);
        setCollapsedRegions([
            ...filteredRegionsArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setRegions([
            ...filteredRegionsArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setExpandedRegions([...regionsSorted]);
        setCollapsedSubsectors([
            ...filteredSubsectorsArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setSubsectors([
            ...filteredSubsectorsArray,
            { name: "Load full view", slug: "load-full-view" },
        ]);
        setExpandedSubsectors([...subsectorsSorted]);
    }, [allSectors, allCountries, allSubsectors, filterStats]);

    useEffect(() => {
        let count = 0;
        Object.entries(selectedFilters).map(filter => {
            filter[0] !== "subsectors" &&
                filter[1] !== "" &&
                filter[1] &&
                filter[1].length !== 0 &&
                filter[1] !== undefined &&
                count++;
        });
        setSelectedFiltersCnt(count);
    }, [selectedFilters]);

    const expandSectors = () => {
        setSectors(expandedSectors);
    };

    const expandStages = () => {
        setStages(expandedStages);
    };

    const expandLocations = () => {
        setLocations(expandedLocations);
    };

    const expandRegions = () => {
        setRegions(expandedRegions);
    };

    const expandSubsectors = () => {
        setSubsectors(expandedSubsectors);
    };
    const getCountStatus = useSelector(getCountStatusSelector);

    useEffect(() => {
        dialogOpened && dispatch(fetchSectorsRequest());
        dialogOpened && dispatch(getCountriesRequest({ regions: [] }));
    }, [dispatch, dialogOpened]);

    const showResults = () => {
        let value = "";
        Object.keys(selectedFilters).forEach(filter => {
            if (
                selectedFilters[filter] !== "" &&
                selectedFilters[filter] &&
                selectedFilters[filter].length !== 0
            ) {
                value = !!filterMapping[filter]
                    ? value.concat(
                          `&${filterMapping[filter]}=${
                              selectedFilters[filter] === "10001+"
                                  ? "10001%2B"
                                  : selectedFilters[filter]
                          }&`,
                      )
                    : value;
            }
        });
        onShowResults(value);
        onShowFilters(selectedFilters);
        onClose();
    };

    const disabledFilters = !user;

    const isShowResultsButtonDisabled = () => {
        if (disabledFilters) {
            return true;
        }
        for (const key in selectedFilters) {
            if (selectedFilters[key] !== displayedFilters[key]) {
                return false;
            }
        }
        return true;
    };

    const handleClose = () => {
        // resetFilters();
        setSelectedFilters(displayedFilters);
        setPreselectedSectors(displayedFilters.sector);
        setPreselectedSubsectors(displayedFilters.subsector);
        onClose();
    };
    const handleOnClose = () => {
        sessionStorage.setItem("closedBannerFM", "filterModal");
        let s = sessionStorage.getItem("closedBannerFM");
        setSessionCloseAlert(s === "filterModal");
    };
    const [showLimitAlert, setShowLimitAlert] = useState(false);
    const [sessionCloseAlert, setSessionCloseAlert] = useState(false);
    useEffect(() => {
        setShowLimitAlert(disabledFilters && !sessionCloseAlert);
    }, [disabledFilters, sessionCloseAlert]);
    useEffect(() => {
        let s = sessionStorage.getItem("closedBannerFM");
        setSessionCloseAlert(s === "filterModal");
    }, []);

    const handleClearSector = () => {
        resetFilter("sector");
        setPreselectedSectors([]);
        setPreselectedSubsectors([]);
    };

    const handleClearStage = () => {
        resetFilter("stage");
    };

    const handleClearLocation = () => {
        resetFilter("location");
    };

    const handleClearRegion = () => {
        resetFilter("regions");
    };

    const handleClearSubsector = () => {
        resetFilter("subsector");
        setPreselectedSubsectors([]);
    };

    return (
        <Modal
            onClose={handleClose}
            dialogOpened={dialogOpened}
            closeButtonIcon={CloseIcon}
            paddingSpace="32px"
            borderRadius="16px">
            <MuiDialogTitle
                disableTypography
                className={classes.dialogTitleWrapper}>
                <Typography
                    variant="paragraph"
                    component="div"
                    className={classes.filterDialogTitle}>
                    {t("filterModal.filtersTitle")}
                </Typography>
                <Typography variant="body2" className={classes.selectedFilters}>
                    {" "}
                    {t("filterModal.selectedFilters")}: {selectedFiltersCnt}
                </Typography>
            </MuiDialogTitle>
            {showLimitAlert && (
                <LockAlert onClose={handleOnClose}>
                    <Typography
                        variant="body2"
                        align="center"
                        test-id="filters-upgrade-link"
                        className={classnames([
                            classes.upgradeCta,
                            "lm-link-cta",
                        ])}
                        onClick={() =>
                            (window.location.href = getPricingPage(
                                !!user && "free",
                            ))
                        }>
                        {t("common.upgrade")}
                    </Typography>
                    &nbsp;
                    <Typography variant="body2">
                        {" "}
                        {t("filterModal.upgradeYourSubscription")}
                    </Typography>
                </LockAlert>
            )}
            <Box className={classes.rows}>
                <div className={classes.row}>
                    <TextField
                        label="Include keywords"
                        labelClass={classes.labelClass}
                        className={classes.rowWrap}
                        inputClass={classnames([
                            classes.filterModalTextField,
                            {
                                [classes.activeFilter]:
                                    selectedFilters.includeKeywords,
                            },
                        ])}
                        onChange={e => handleIncludeKeywordsChange(e)}
                        value={selectedFilters.includeKeywords}
                        infoToolTipText="Enter your keywords separated by commas"
                    />
                    <TextField
                        label="Exclude keywords"
                        labelClass={classes.labelClass}
                        className={classes.rowWrap}
                        inputClass={classnames([
                            classes.filterModalTextField,
                            {
                                [classes.activeFilter]:
                                    selectedFilters.excludeKeywords,
                            },
                        ])}
                        onChange={e => handleExcludeKeywordsChange(e)}
                        value={selectedFilters.excludeKeywords}
                        infoToolTipText="Enter your keywords separated by commas"
                    />
                </div>
                {!isIndustry && (
                    <div className={classes.row}>
                        <Multiselect
                            testId="multiselect-sector"
                            placeholder="Select from the list"
                            disabled={
                                disabledFilters ||
                                (filterStats && !filterStats.sectors)
                            }
                            className={classes.rowWrap}
                            customClass={classnames([
                                classes.multiselect,
                                {
                                    [classes.disabledFilters]: disabledFilters,
                                    [classes.activeFilter]:
                                        selectedFilters.sector.length &&
                                        !disabledFilters,
                                },
                            ])}
                            labelClass={classes.labelClass}
                            iconArrow={
                                selectedFilters.sector.length ? (
                                    <div
                                        onClick={handleClearSector}
                                        className={classes.resetMultiFilter}>
                                        <ResetIcon
                                            test-id="reset-sector"
                                            className={classes.resetFilterIcon}
                                        />
                                    </div>
                                ) : (
                                    <ArrowDown className={classes.arrowDown} />
                                )
                            }
                            label={t("filterModal.sectorFilter")}
                            options={sectors}
                            value={preselectedSectors ? preselectedSectors : []}
                            onChange={e => handleSectorChange(e)}
                            onExpand={expandSectors}
                            isWithCheckboxes={true}
                            onClear={handleClearSector}
                            onClose={() => handleSectorsClose()}
                            menuItemClass={classes.multiselectMenuItem}
                        />
                        <Multiselect
                            testId="multiselect-subsector"
                            placeholder={
                                !!selectedFilters.sector
                                    ? "Select from the list"
                                    : "Select industry first"
                            }
                            className={classes.rowWrap}
                            customClass={classnames([
                                classes.multiselect,
                                {
                                    [classes.disableSubsectors]:
                                        !selectedFilters.sector,
                                    [classes.disabledFilters]: disabledFilters,
                                    [classes.activeFilter]:
                                        selectedFilters.subsector.length &&
                                        !disabledFilters,
                                },
                            ])}
                            labelClass={classes.labelClass}
                            disabled={!selectedFilters.sector}
                            iconArrow={
                                selectedFilters.subsector.length ? (
                                    <div
                                        onClick={handleClearSubsector}
                                        className={classes.resetMultiFilter}>
                                        <ResetIcon
                                            test-id="reset-sector"
                                            className={classes.resetFilterIcon}
                                        />
                                    </div>
                                ) : (
                                    <ArrowDown className={classes.arrowDown} />
                                )
                            }
                            label={t("filterModal.subsectorFilter")}
                            options={subsectors}
                            value={
                                preselectedSubsectors
                                    ? preselectedSubsectors
                                    : []
                            }
                            onChange={e => handleSubsectorChange(e)}
                            onExpand={expandSubsectors}
                            isWithCheckboxes={true}
                            isWithCounts={true}
                            onClear={handleClearSubsector}
                            onClose={() => handleSubsectorsClose()}
                            menuItemClass={classes.multiselectMenuItem}
                        />
                    </div>
                )}
                <div className={classes.row}>
                    <Multiselect
                        testId="multiselect-stage"
                        placeholder="Select from the list"
                        disabled={disabledFilters}
                        customClass={classnames([
                            classes.multiselect,
                            {
                                [classes.disabledFilters]: disabledFilters,
                                [classes.activeFilter]:
                                    selectedFilters.stage.length &&
                                    !disabledFilters,
                            },
                        ])}
                        labelClass={classes.labelClass}
                        className={classes.rowWrap}
                        iconArrow={
                            selectedFilters.stage.length ? (
                                <div
                                    onClick={handleClearStage}
                                    className={classes.resetMultiFilter}>
                                    <ResetIcon
                                        test-id="reset-sector"
                                        className={classes.resetFilterIcon}
                                    />
                                </div>
                            ) : (
                                <ArrowDown className={classes.arrowDown} />
                            )
                        }
                        label={t("filterModal.stageFilter")}
                        options={stages}
                        value={selectedFilters.stage}
                        isWithCheckboxes={true}
                        isWithCounts={true}
                        onExpand={expandStages}
                        onChange={e => handleStageChange(e)}
                        onClear={handleClearStage}
                        menuItemClass={classes.multiselectMenuItem}
                    />
                    <Box
                        className={classes.rowWrapYear}
                        display="flex"
                        flexDirection="column">
                        <Typography
                            variant="bodyButton"
                            className={classes.mono}>
                            {t("filterModal.fundingFilter")}
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.multiselectFromTo}>
                            <Multiselect
                                testId="multiselect-fundingMin"
                                placeholder="From"
                                className={classes.multiselectWrap}
                                disabled={disabledFilters}
                                customClass={classnames([
                                    classes.multiselectYear,
                                    {
                                        [classes.disabledFilters]:
                                            disabledFilters,
                                        [classes.activeFilter]:
                                            selectedFilters.fundingMin &&
                                            !disabledFilters,
                                    },
                                ])}
                                labelClass={classes.labelClass}
                                iconArrow={
                                    selectedFilters.fundingMin ? (
                                        <ResetIcon
                                            test-id="reset-fundingMin"
                                            className={classes.resetFilter}
                                            onClick={() =>
                                                resetFilter("fundingMin")
                                            }
                                        />
                                    ) : (
                                        <ArrowDown
                                            className={classes.arrowDown}
                                        />
                                    )
                                }
                                options={fundingOptionsMin}
                                value={selectedFilters.fundingMin}
                                onChange={handleFundingMinChange}
                            />

                            <Multiselect
                                testId="multiselect-fundingMax"
                                placeholder="To"
                                disabled={disabledFilters}
                                customClass={classnames([
                                    classes.multiselectYear,
                                    {
                                        [classes.disabledFilters]:
                                            disabledFilters,
                                        [classes.activeFilter]:
                                            selectedFilters.fundingMax &&
                                            !disabledFilters,
                                    },
                                ])}
                                labelClass={classes.labelClass}
                                className={classnames([
                                    classes.multiselectWrap,
                                    classes.ml8,
                                ])}
                                iconArrow={
                                    selectedFilters.fundingMax ? (
                                        <ResetIcon
                                            test-id="reset-fundingMax"
                                            className={classes.resetFilter}
                                            onClick={() =>
                                                resetFilter("fundingMax")
                                            }
                                        />
                                    ) : (
                                        <ArrowDown
                                            className={classes.arrowDown}
                                        />
                                    )
                                }
                                options={fundingOptionsMax}
                                value={selectedFilters.fundingMax}
                                onChange={e =>
                                    handleFieldChange(e, "fundingMax")
                                }
                            />
                        </Box>
                    </Box>
                </div>
                <div className={classes.row}>
                    <Box
                        className={classes.rowWrapYear}
                        display="flex"
                        flexDirection="column">
                        <Typography
                            variant="bodyButton"
                            className={classes.mono}>
                            {t("filterModal.teamFilter")}
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.multiselectFromTo}>
                            <Multiselect
                                testId="multiselect-teamsize-min"
                                placeholder="Min"
                                disabled={disabledFilters}
                                customClass={classnames([
                                    classes.multiselectYear,
                                    {
                                        [classes.disabledFilters]:
                                            disabledFilters,
                                        [classes.activeFilter]:
                                            selectedFilters.teamsizeMin &&
                                            !disabledFilters,
                                    },
                                ])}
                                labelClass={classes.labelClass}
                                className={classes.multiselectWrap}
                                iconArrow={
                                    selectedFilters.teamsizeMin ? (
                                        <ResetIcon
                                            test-id="reset-teamsize-min"
                                            className={classes.resetFilter}
                                            onClick={() =>
                                                resetFilter("teamsizeMin")
                                            }
                                        />
                                    ) : (
                                        <ArrowDown
                                            className={classes.arrowDown}
                                        />
                                    )
                                }
                                options={teamsizeOptions}
                                value={selectedFilters.teamsizeMin}
                                onChange={handleTeamSizeMinChange}
                            />
                            <Multiselect
                                testId="multiselect-teamsize-max"
                                placeholder="Max"
                                disabled={disabledFilters}
                                customClass={classnames([
                                    classes.multiselectYear,
                                    {
                                        [classes.disabledFilters]:
                                            disabledFilters,
                                        [classes.activeFilter]:
                                            selectedFilters.teamsizeMax &&
                                            !disabledFilters,
                                    },
                                ])}
                                labelClass={classes.labelClass}
                                className={classnames([
                                    classes.multiselectWrap,
                                    classes.ml8,
                                ])}
                                iconArrow={
                                    selectedFilters.teamsizeMax ? (
                                        <ResetIcon
                                            test-id="reset-teamsize-max"
                                            className={classes.resetFilter}
                                            onClick={() =>
                                                resetFilter("teamsizeMax")
                                            }
                                        />
                                    ) : (
                                        <ArrowDown
                                            className={classes.arrowDown}
                                        />
                                    )
                                }
                                options={teamsizeOptions}
                                value={selectedFilters.teamsizeMax}
                                onChange={e =>
                                    handleFieldChange(e, "teamsizeMax")
                                }
                            />
                        </Box>
                    </Box>
                    <Box
                        className={classes.rowWrapYear}
                        display="flex"
                        flexDirection="column">
                        <Typography
                            variant="bodyButton"
                            className={classes.mono}>
                            {t("filterModal.yearFilter")}
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.multiselectFromTo}>
                            <Multiselect
                                testId="multiselect-yearMin"
                                placeholder="From"
                                disabled={disabledFilters}
                                className={classes.multiselectWrap}
                                customClass={classnames([
                                    classes.multiselectYear,
                                    {
                                        [classes.disabledFilters]:
                                            disabledFilters,
                                        [classes.activeFilter]:
                                            selectedFilters.yearMin &&
                                            !disabledFilters,
                                    },
                                ])}
                                labelClass={classes.labelClass}
                                iconArrow={
                                    selectedFilters.yearMin ? (
                                        <ResetIcon
                                            test-id="reset-yearMin"
                                            className={classes.resetFilter}
                                            onClick={() =>
                                                resetFilter("yearMin")
                                            }
                                        />
                                    ) : (
                                        <ArrowDown
                                            className={classes.arrowDown}
                                        />
                                    )
                                }
                                options={yearOptionsMin}
                                value={
                                    selectedFilters.yearMin === "" ||
                                    selectedFilters.yearMin === null
                                        ? ""
                                        : yearOptionsMin.filter(year =>
                                              year.name.startsWith(
                                                  selectedFilters.yearMin,
                                              ),
                                          )[0].name
                                }
                                onChange={handleYearMinChange}
                            />
                            <Multiselect
                                testId="multiselect-yearMax"
                                placeholder="To"
                                disabled={disabledFilters}
                                customClass={classnames([
                                    classes.multiselectYear,
                                    {
                                        [classes.disabledFilters]:
                                            disabledFilters,
                                        [classes.activeFilter]:
                                            selectedFilters.yearMax &&
                                            !disabledFilters,
                                    },
                                ])}
                                labelClass={classes.labelClass}
                                className={classnames([
                                    classes.multiselectWrap,
                                    classes.ml8,
                                ])}
                                iconArrow={
                                    selectedFilters.yearMax ? (
                                        <ResetIcon
                                            test-id="reset-yearMax"
                                            className={classes.resetFilter}
                                            onClick={() =>
                                                resetFilter("yearMax")
                                            }
                                        />
                                    ) : (
                                        <ArrowDown
                                            className={classes.arrowDown}
                                        />
                                    )
                                }
                                options={yearOptionsMax}
                                value={
                                    selectedFilters.yearMax === "" ||
                                    selectedFilters.yearMax === null
                                        ? ""
                                        : yearOptionsMax.filter(year =>
                                              year.name.endsWith(
                                                  selectedFilters.yearMax,
                                              ),
                                          )[0].name
                                }
                                onChange={e => handleFieldChange(e, "yearMax")}
                            />
                        </Box>
                    </Box>
                </div>
                <div className={classes.row}>
                    <Multiselect
                        testId="multiselect-regions"
                        placeholder="Select from the list"
                        disabled={disabledFilters}
                        customClass={classnames([
                            classes.multiselect,
                            {
                                [classes.disabledFilters]: disabledFilters,
                                [classes.activeFilter]:
                                    selectedFilters.regions.length &&
                                    !disabledFilters,
                            },
                        ])}
                        labelClass={classes.labelClass}
                        className={classes.rowWrap}
                        iconArrow={
                            selectedFilters.regions.length ? (
                                <div
                                    onClick={handleClearRegion}
                                    className={classes.resetMultiFilter}>
                                    <ResetIcon
                                        test-id="reset-region"
                                        className={classes.resetFilterIcon}
                                    />
                                </div>
                            ) : (
                                <ArrowDown className={classes.arrowDown} />
                            )
                        }
                        label={t("filterModal.regionFilter")}
                        options={regions}
                        value={selectedFilters.regions}
                        isWithCheckboxes={true}
                        isWithCounts={true}
                        onExpand={expandRegions}
                        onChange={e => handleRegionChange(e)}
                        onClear={handleClearRegion}
                        menuItemClass={classes.multiselectMenuItem}
                    />
                    <Multiselect
                        testId="multiselect-location"
                        placeholder={
                            !!selectedFilters.regions
                                ? "Select from the list"
                                : "Select region first"
                        }
                        disabled={
                            !selectedFilters.regions ||
                            (selectedFilters.regions &&
                                selectedFilters.regions.length === 0) ||
                            (filterStats && !filterStats.regions)
                        }
                        customClass={classnames([
                            classes.multiselect,
                            {
                                [classes.disabledFilters]: disabledFilters,
                                [classes.activeFilter]:
                                    selectedFilters.location.length &&
                                    !disabledFilters,
                            },
                        ])}
                        labelClass={classes.labelClass}
                        className={classes.rowWrap}
                        iconArrow={
                            selectedFilters.location.length ? (
                                <div
                                    onClick={handleClearLocation}
                                    className={classes.resetMultiFilter}>
                                    <ResetIcon
                                        test-id="reset-sector"
                                        className={classes.resetFilterIcon}
                                    />
                                </div>
                            ) : (
                                <ArrowDown className={classes.arrowDown} />
                            )
                        }
                        label={t("filterModal.locationFilter")}
                        options={locations}
                        value={selectedFilters.location}
                        isWithCheckboxes={true}
                        isWithCounts={true}
                        onExpand={expandLocations}
                        onChange={e => handleLocationChange(e)}
                        onClear={handleClearLocation}
                        menuItemClass={classes.multiselectMenuItem}
                    />
                </div>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.rowWrapButtons}>
                <Button
                    variant="textSecondary"
                    disabled={false}
                    onClick={resetFilters}
                    className={classes.resetFilterAction}
                    test-id="reset-filters">
                    {t("filterModal.resetButton")}
                </Button>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        className={classes.cancelButton}
                        variant="tertiary"
                        disabled={false}
                        onClick={handleClose}
                        test-id="cancel-filters">
                        {t("createProjectModal.cancel")}
                    </Button>
                    <Button
                        variant="primary"
                        className={classes.showResultsButton}
                        disabled={
                            getFilterResultsCount === 0 ||
                            isShowResultsButtonDisabled()
                        }
                        onClick={showResults}
                        test-id="show-results"
                        loading={getCountStatus === REQUEST_PENDING}>
                        {t("filterModal.show")}{" "}
                        {!!getFilterResultsCount || getFilterResultsCount === 0
                            ? getFilterResultsCount
                            : totalCount}{" "}
                        {t("filterModal.results")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
export default FiltersModal;
