import React, { useEffect, useState, createRef } from "react";
import classnames from "classnames";
import { useStyles } from "./style.js";
import { useDispatch } from "react-redux";
import _ from "lodash";
// Components
import Typography from "../Typography/Typography";
import { default as UICard } from "../UICard/UICard";
import Tooltip from "@material-ui/core/Tooltip";
import PreviewModal from "../../pages/Results/components/modals/PreviewModal";
import { useLockedPageHook } from "../../pages/Company/LockedPage/hooks/useLockedPageHook.js";
import EnrichRequestModal from "../../pages/Company/LockedPage/EnrichRequestModal/EnrichRequestModal.js";
import TopUpCreditsModal from "../../pages/Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal.js";
import NotEnoughCreditsModal from "../../pages/Company/LockedPage/NotEnoughCreditsModal/NotEnoughCreditsModal.js";
import OrderReceivedModal from "../../pages/Company/LockedPage/OrderReceivedModal/OrderReceivedModal.js";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
import RemoveWarningModal from "../../pages/Projects/ProjectProfile/RemoveWarningModal/RemoveWarningModal";
import { AddToProjectMenu } from "../AddToProjectMenu/AddToProjectMenu.js";
import OrderReportModal from "../../pages/Technologies/Components/OrderReportModal/OrderReportModal.js";
import Checkbox from "../../components/Checkbox/Checkbox";
// Assets
import NoImagePlaceholder from "../../assets/icons/Company.svg";
import OrderReportIcon from "../../assets/icons/OrderReportW.svg";
import FullProfileIcon from "../../assets/icons/FullProfileW.svg";
import PreviewIcon from "../../assets/icons/PreviewW.svg";
import SaveIcon from "../../assets/icons/SaveW.svg";
import RemoveIcon from "../../assets/icons/RemoveW.svg";
import SeeReport from "../../assets/icons/SeeReport.svg";
import DefaultIndustryAvatar from "../../assets/icons/Industry.svg";
import ProjectDefaultIcon from "../../assets/icons/ProjectDefaultIcon.svg";
import TechnologyIcon from "../../assets/icons/Technology.svg";
// Hooks
import { useHistory } from "react-router-dom";
//Listeners
import { useAuthHook } from "../../common/useAuthHook";
// Redux
import { exportCsvRequest } from "../../store/orders/requests";
import { deleteProjectRequest } from "../../store/projects/requests";
//hooks
import { useAppEventTracking } from "../../common/useAppEventTracking";
import { getPricingPage } from "../../constants/endpoints";

const Card = ({
    isTechnology,
    result,
    className,
    t,
    openPage,
    searchResultsSid,
    isAuthenticated,
    isProjectCompany,
    isProject,
    projectSlug,
    cardIndex,
    source,
    searchTypeLook,
    isIndustry,
    isIndustryProfile,
    isSuggestedCompany = false,
    isLikedCompany,
    searchType,
    parentId,
    view,
    type,
    isWorkspace = false,
    setSelected,
    setSelectedCompanies,
    setSelectedNames,
    selected,
    selectedNames,
    selectedCompanies,
}) => {
    const {
        name,
        deck,
        sectors,
        description,
        slug,
        ready_for_release,
        enrichment_request,
        id,
        match,
        created_date,
        projects,
    } = result;
    const dispatch = useDispatch();
    const { user } = useAuthHook();
    const { intercomTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
    const cardRef = createRef(null);
    const [isPreviewModalOpened, setIsPreviewModalOpened] = useState(false);
    const classes = useStyles({ isSuggestedCompany, user });
    const [imageSrc, setImageSrc] = useState(
        isIndustryProfile ? DefaultIndustryAvatar : NoImagePlaceholder,
    );
    const [imageIsValid, setImageIsValid] = useState(false);
    const [saveMenuOpened, setSaveMenuOpened] = useState(false);
    const [shouldShowMatchScore, setShouldShowMatchScore] = useState(false);
    const [removeWarningModalOpened, setRemoveWarningModalOpened] =
        useState(false);
    //votes
    const [newTabUrl, setNewTabUrl] = useState();
    const history = useHistory();

    const {
        enrichRequestModalShow,
        notEnoughCreditsModalShow,
        setNotEnoughCreditsModalShow,
        topUpCreditsModalShow,
        showEnrichSuccess,
        handleClosingTopUpCreditsDialog,
        handleClosingNotEnoughCreditsDialog,
        handleTopUp,
        handleBuyingTopUpCreditsDialog,
        handleClosingEnrichDialog,
        showEnrichRequestModal,
        handleCreateProjectDialogOpened,
        handleEnrichSuccess,
        handleClosingSuccess,
        credits,
        showOrderReportModal,
        setShowOrderReportModal,
    } = useLockedPageHook({ company: result, type });

    const handleOpenPreview = e => {
        if (document.body.offsetWidth < 576 || type === "project") {
            openProfile(e);
        } else {
            setIsPreviewModalOpened(true);
            mixpanelTrackEvent(`Preview ${type}`);
        }
    };

    useEffect(() => {
        setShouldShowMatchScore(
            type === "company" &&
                !isProjectCompany &&
                source !== "industry" &&
                source !== "technology" &&
                source !== "likedCompanies",
        );
    }, [source, isIndustryProfile, isSuggestedCompany]);

    useEffect(() => {
        if (isProject) {
            setImageSrc(ProjectDefaultIcon);
            setImageIsValid(true);
        } else if (isIndustryProfile && result.image) {
            setImageSrc(result.image);
            setImageIsValid(true);
        } else if (isTechnology) {
            setImageSrc(TechnologyIcon);
            setImageIsValid(true);
        } else if (ready_for_release && deck && deck.icon) {
            setImageSrc(deck.icon);
            setImageIsValid(true);
        } else {
            setImageSrc(NoImagePlaceholder);
            setImageIsValid(false);
        }
    }, [ready_for_release, deck]);

    const onImgError = () => {
        setImageSrc(NoImagePlaceholder);
        setImageIsValid(false);
    };

    const openProfile = e => {
        e.preventDefault();
        e.stopPropagation();
        if (type === "industry") {
            openPage(`/industries/${result.slug}`, isWorkspace);
            return;
        } else if (type === "technology") {
            openPage(`/technologies/${result.slug}`, isWorkspace);
            return;
        } else if (type === "project") {
            openPage(`/projects/${result.slug}`, isWorkspace);
            return;
        }
        const queryParams = new URLSearchParams(window.location.search);
        const pageParam = queryParams.get("page");
        sessionStorage.setItem(
            `${source}-${
                isTechnology || isIndustry
                    ? projectSlug
                    : isLikedCompany
                    ? ""
                    : searchResultsSid
            }ScrollPosition`,
            window.scrollY,
        );
        sessionStorage.setItem(
            `${source}-${
                isTechnology || isIndustry
                    ? projectSlug
                    : isLikedCompany
                    ? ""
                    : searchResultsSid
            }-FilterPage`,
            pageParam,
        );
        if (isTechnology) {
            localStorage.setItem("project_slug", projectSlug);
            mixpanelTrackEvent(`${type} profile under technology opened`, {
                [`${type} name`]: name,
            });
        } else if (isIndustry) {
            mixpanelTrackEvent(`${type} profiles viewed under industry`, {
                [`${type} name`]: name,
            });
            localStorage.setItem(
                "industryCompanyFromRoute",
                history.location.pathname,
            );
            localStorage.setItem("project_slug", projectSlug);
        } else if (isSuggestedCompany) {
            mixpanelTrackEvent("Suggested company profile viewed", {
                [`${type} name`]: name,
            });
            if (source !== "dashboard") {
                localStorage.setItem("historySimilar", "similarCompany");
                localStorage.setItem("visitedCid", parentId);
                sessionStorage.setItem("history", "company");
                localStorage.setItem("project_slug", projectSlug);
            } else {
                sessionStorage.setItem("history", "dashboard");
            }
        } else {
            if (searchType === 1) {
                mixpanelTrackEvent(
                    `${type} profiles viewed in search for companies using a specific technology`,
                    {
                        [`${type} name`]: name,
                    },
                );
            } else if (searchType === 2) {
                mixpanelTrackEvent(
                    `${type} profiles viewed in search for look-alikes`,
                    {
                        [`${type} name`]: name,
                    },
                );
            }
            localStorage.setItem("project_slug", projectSlug);
            source === "project" &&
                localStorage.setItem("is_from_project", true);
            sessionStorage.setItem(
                "history",
                isProjectCompany
                    ? "project"
                    : isSuggestedCompany
                    ? "company"
                    : isLikedCompany
                    ? "likedCompanies"
                    : "results",
            );
        }
        mixpanelTrackEvent(`${type} profiles viewed in total`, {
            [`${type} name`]: name,
        });
        intercomTrackEvent(`${type} profiles viewed`, {
            [`${type} name`]: name,
            [`${type} slug`]: slug,
        });
        let url = `/companies/${slug}`;

        if (result && result.id) {
            if (isTechnology || isIndustry) {
                url = `${url}?cid=${result.id}`;
            } else {
                isAuthenticated
                    ? (url = `${url}?cid=${result.id}`)
                    : (url = ``);
            }
        }
        if (view && isAuthenticated) {
            url = `${url}&view=true`;
        }
        if (!e.metaKey) {
            if (source === "dashboard") {
                sessionStorage.setItem("history", "dashboard");
            }

            !e.ctrlKey && openPage(url, isWorkspace);
        } else {
            if (source === "dashboard") {
                sessionStorage.setItem("history", "dashboard");
            }

            !e.ctrlKey && openPage(url, isWorkspace);
        }
        if (searchTypeLook) {
            sessionStorage.setItem("history", "companies-look-alike");
        }
    };

    useEffect(() => {
        let url = `/companies/${slug}`;

        if (result && result.id) {
            if (isTechnology || isIndustry) {
                url = `${url}?cid=${result.id}`;
            } else {
                isAuthenticated
                    ? (url = `${url}?cid=${result.id}`)
                    : (url = ``);
            }
        }
        if (view && isAuthenticated) {
            url = `${url}&view=true`;
        }

        setNewTabUrl(url);
    }, []);

    const [scoreDegree, setScoreDegree] = useState();

    useEffect(() => {
        if (match < 40) {
            setScoreDegree("weak");
        } else if (match >= 40 && match < 60) {
            setScoreDegree("good");
        } else {
            setScoreDegree("strong");
        }
    }, [match]);

    const openProject = (e, slug) => {
        e.preventDefault();
        e.stopPropagation();
        openPage(`/projects/${slug}`);
    };

    const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
        useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);

    const download = () => {
        let downloadType;
        switch (type) {
            case "technology":
                downloadType = "technologies";
                break;
            case "industry":
                downloadType = "industries";
                break;
            default:
                downloadType = "companies";
        }
        let payload = {
            ids: id,
            type: downloadType,
            doc: "pdf",
            name: `valuer_${name}_report`,
            pageName:
                isIndustry || isIndustryProfile
                    ? "industry"
                    : isTechnology
                    ? "technology"
                    : isProject
                    ? "project"
                    : isLikedCompany
                    ? "liked companies"
                    : "results",
        };
        dispatch(exportCsvRequest(payload));
        mixpanelTrackEvent(`Downloaded document`, {
            type: "pdf",
            page: isIndustry
                ? "industry"
                : isTechnology
                ? "technology"
                : isProject
                ? "project"
                : isLikedCompany
                ? "liked companies"
                : "results",
        });
    };

    const handleDownloadToPdf = e => {
        e.preventDefault();
        e.stopPropagation();
        if (
            user.downloads === 1 &&
            (user.subscription.plan_type === "free_trial" ||
                user.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            user.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            !user.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!user);
        } else if (user.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            download();
        }
    };

    const removeProject = e => {
        e.preventDefault();
        e.stopPropagation();
        setRemoveWarningModalOpened(true);
    };

    const handleEnoughCredits = value => {
        setShowOrderReportModal(false);
        setNotEnoughCreditsModalShow(true);
    };

    const handleCheckboxClick = event => {
        event.preventDefault();
        event.stopPropagation();
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        let newSelectedNames = [];
        let newSelectedCompanies = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            newSelectedNames = newSelectedNames.concat(selectedNames, name);
            newSelectedCompanies = newSelectedCompanies.concat(
                selectedCompanies,
                result,
            );
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newSelectedNames = newSelectedNames.concat(selectedNames.slice(1));
            newSelectedCompanies = newSelectedCompanies.concat(
                selectedCompanies.slice(1),
            );
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newSelectedNames = newSelectedNames.concat(
                selectedNames.slice(0, -1),
            );
            newSelectedCompanies = newSelectedCompanies.concat(
                selectedCompanies.slice(0, -1),
            );
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            newSelectedNames = newSelectedNames.concat(
                selectedNames.slice(0, selectedIndex),
                selectedNames.slice(selectedIndex + 1),
            );
            newSelectedCompanies = newSelectedCompanies.concat(
                selectedCompanies.slice(0, selectedIndex),
                selectedCompanies.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setSelectedNames(newSelectedNames);
        setSelectedCompanies(newSelectedCompanies);
    };

    const isSelected = id => selected.indexOf(id) !== -1;

    return (
        <a
            className={classnames([classes.root, className])}
            onClick={e => !e.ctrlKey && !e.metaKey && e.preventDefault()}
            href={newTabUrl}>
            <UICard
                data-intercom-target={`${type} results ${cardIndex + 1}`}
                testId={`${id}-company-card`}
                id={`results-card-${cardIndex}`}
                ref={cardRef}
                onClick={handleOpenPreview}
                isSuggestedCompany={isSuggestedCompany}
                className={classes.card}>
                <div className={classes.header}>
                    {/* <Tooltip disableHoverListener={imageIsValid} title={<Typography component="div" color="white" variant="body2">{t("results.logoDescription")}</Typography>} arrow>
                        <div className={classnames([classes.avatar, { [classes[`${type}Color`]]: true } ])}>
                            <img
                                width="24"
                                height="24"
                                src={imageSrc}
                                alt={name}
                                onError={onImgError}
                            />
                            {!!ready_for_release ? <img src={SeeReport} className={classes.enrichedIcon} alt={t('card.seeReport')}/> : null}
                        </div>
                    </Tooltip> */}
                    <div className={classes.titleWrap}>
                        <Tooltip
                            title={
                                <Typography
                                    color="white"
                                    variant="body2"
                                    className={classes.mono}>
                                    {name}
                                </Typography>
                            }
                            arrow>
                            <Typography
                                component="div"
                                variant="subtitle1"
                                className={classnames([
                                    classes.name,
                                    classes.titleName,
                                    {
                                        [classes.projectSourceName]:
                                            source === "project" ||
                                            isIndustryProfile,
                                    },
                                ])}>
                                {name}
                            </Typography>
                        </Tooltip>
                        {type === "company" ? (
                            <Typography
                                variant="label"
                                color="textLighter"
                                component="div"
                                className={classnames([
                                    classes.industriesName,
                                ])}>
                                {sectors &&
                                    sectors
                                        .map(sector =>
                                            sector.name ? sector.name : sector,
                                        )
                                        .join(", ")}
                            </Typography>
                        ) : type === "project" ? (
                            <Typography
                                variant="label"
                                color="textLighter"
                                component="div"
                                className={classnames([
                                    classes.industriesName,
                                ])}>
                                {created_date}
                            </Typography>
                        ) : null}
                    </div>
                    {shouldShowMatchScore && (
                        <div className={classes.matchScoreBlock}>
                            <div className={classes.matchScore}>
                                <Typography
                                    variant="label"
                                    color="textLight"
                                    component="div"
                                    className={classes.matchScoreLabel}>
                                    {t("results.matchingScore")}
                                </Typography>
                                <Typography
                                    variant="bodyButton"
                                    className={classnames([
                                        classes.percentageMatch,
                                        classes[`${scoreDegree}Text`],
                                    ])}
                                    color={
                                        match === "100"
                                            ? "primaryDark"
                                            : "black"
                                    }>
                                    {t("results.match", { number: match })}
                                    <div className={classes.scoreBar}>
                                        <div
                                            className={classnames([
                                                classes.scoreBarInside,
                                                classes[`${scoreDegree}Score`],
                                            ])}
                                            style={{
                                                width: `${match}%`,
                                            }}></div>
                                    </div>
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.cardRow}>
                    {!isProject &&
                        !isWorkspace &&
                        !isTechnology &&
                        !isIndustry &&
                        !isSuggestedCompany && (
                            <div>
                                <Checkbox
                                    onClick={event =>
                                        handleCheckboxClick(event, result)
                                    }
                                    checked={isSelected(result.id)}
                                    color="primary"
                                    inputProps={{
                                        "aria-labelledby": `table-checkbox-${cardIndex}`,
                                    }}
                                />
                                <Typography
                                    className={classes.checkboxLabel}
                                    color="textLighter">
                                    Select
                                </Typography>
                            </div>
                        )}
                    <div className={classes.typeWrapper}>
                        <Typography
                            variant="bodyXs"
                            color="black"
                            component="div"
                            className={classnames([
                                classes.typeTag,
                                { [classes[`${type}Color`]]: true },
                            ])}>
                            {_.startCase(type)}
                        </Typography>
                    </div>
                </div>
                <div
                    className={classnames([
                        classes.descriptionWrapper,
                        {
                            [classes.projectDescriptionWrapper]:
                                type === "project",
                        },
                        { [classes.descriptionWrapperShow]: saveMenuOpened },
                    ])}>
                    <Typography
                        variant="bodyButton"
                        color="textSecondary"
                        className={classnames(classes.description, {
                            [classes.hideWhenAddToProjectIsOpen]:
                                saveMenuOpened,
                        })}
                        dangerouslySetInnerHTML={{
                            __html:
                                deck && deck.description
                                    ? deck.description
                                    : description,
                        }}
                    />
                    {type === "project" ? (
                        <>
                            <div
                                className={classes.actionIconWrapperRemove}
                                onClick={removeProject}>
                                <div className={classes.actionIconContent}>
                                    <img
                                        width="24"
                                        height="24"
                                        src={RemoveIcon}
                                        alt={t("card.remove")}
                                        className={classes.actionIcon}
                                    />
                                </div>
                                <Typography
                                    variant="bodyXs"
                                    color="textLight"
                                    className={classes.actionText}>
                                    {t("card.remove")}
                                </Typography>
                            </div>
                        </>
                    ) : (
                        <>
                            {!ready_for_release &&
                            type !== "industry" &&
                            !enrichment_request ? (
                                <div
                                    className={classnames(
                                        classes.actionIconWrapper,
                                        {
                                            [classes.showWhenAddToProjectIsOpen]:
                                                saveMenuOpened,
                                        },
                                    )}
                                    onClick={e =>
                                        showEnrichRequestModal(e, result)
                                    }>
                                    <div className={classes.actionIconContent}>
                                        <img
                                            width="24"
                                            height="24"
                                            src={OrderReportIcon}
                                            alt={t("card.orderReport")}
                                            className={classes.actionIcon}
                                        />
                                    </div>
                                    <Typography
                                        variant="bodyXs"
                                        color="textLight"
                                        className={classes.actionText}>
                                        {t("card.orderReport")}
                                    </Typography>
                                </div>
                            ) : null}
                            <div
                                className={classnames(
                                    classes.actionIconWrapper,
                                    {
                                        [classes.showWhenAddToProjectIsOpen]:
                                            saveMenuOpened,
                                    },
                                )}
                                onClick={e => openProfile(e)}>
                                <div className={classes.actionIconContent}>
                                    <img
                                        width="24"
                                        height="24"
                                        src={FullProfileIcon}
                                        alt={t("card.fullProfile")}
                                        className={classes.actionIcon}
                                    />
                                </div>
                                <Typography
                                    variant="bodyXs"
                                    color="textLight"
                                    className={classes.actionText}>
                                    {t("card.fullProfile")}
                                </Typography>
                            </div>
                            <div
                                className={classnames(
                                    classes.actionIconWrapper,
                                    {
                                        [classes.showWhenAddToProjectIsOpen]:
                                            saveMenuOpened,
                                    },
                                )}
                                onClick={handleOpenPreview}>
                                <div className={classes.actionIconContent}>
                                    <img
                                        width="24"
                                        height="24"
                                        src={PreviewIcon}
                                        alt={t("card.preview")}
                                        className={classes.actionIcon}
                                    />
                                </div>
                                <Typography
                                    variant="bodyXs"
                                    color="textLight"
                                    className={classes.actionText}>
                                    {t("card.preview")}
                                </Typography>
                            </div>
                            <div
                                className={classnames(
                                    classes.actionIconWrapper,
                                    {
                                        [classes.showWhenAddToProjectIsOpen]:
                                            saveMenuOpened,
                                    },
                                )}>
                                <AddToProjectMenu
                                    buttonType="savecard"
                                    company={result}
                                    isMenuOpenedListener={setSaveMenuOpened}
                                    type={type.toLowerCase()}
                                    isProject={isProject}
                                    projectSlug={projectSlug}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <div className={classes.bgGradient} />
                    <div className={classes.footer}>
                        <div>
                            {type !== "industry" && (
                                <>
                                    {isProject ? (
                                        <Typography
                                            variant="bodyXs"
                                            color="text"
                                            className={classes.savedInLink}>
                                            {t("projects.companiesInProject")}
                                            {`: `}
                                            {result.items_count}
                                        </Typography>
                                    ) : !!ready_for_release ? (
                                        <Typography
                                            variant="bodyXs"
                                            color="primaryDark"
                                            className={classnames([
                                                classes.link,
                                                classes.orderReportLink,
                                            ])}
                                            component="div"
                                            onClick={handleDownloadToPdf}>
                                            <img
                                                src={SeeReport}
                                                alt={t("card.seeReport")}
                                                className={
                                                    classes.seeReportIcon
                                                }
                                            />
                                            {t("card.seeReport")}
                                        </Typography>
                                    ) : (
                                        !enrichment_request && (
                                            <Typography
                                                variant="bodyXs"
                                                color="primaryDark"
                                                className={classnames([
                                                    classes.link,
                                                    classes.savedInLink,
                                                ])}
                                                component="div"
                                                onClick={e =>
                                                    showEnrichRequestModal(
                                                        e,
                                                        result,
                                                    )
                                                }>
                                                {t("card.orderReport")}
                                            </Typography>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                        {!isProject && projects?.length ? (
                            <Typography
                                variant="bodyXs"
                                color="textSecondary"
                                component="div"
                                className={classes.savedInLabel}>
                                {t("card.savedInLabel")}
                                <Typography
                                    variant="bodyXs"
                                    color="primaryDark"
                                    className={classes.savedInLink}
                                    component="span">
                                    {projects?.map((project, i) => (
                                        <div key={`saved-in-list-item-${i}`}>
                                            <Typography
                                                variant="bodyXs"
                                                key={i}
                                                color="primaryDark"
                                                className={classnames([
                                                    classes.link,
                                                    classes.savedInLink,
                                                ])}
                                                component="span"
                                                onClick={e =>
                                                    openProject(e, project.slug)
                                                }>
                                                {project.title}
                                            </Typography>
                                            {projects?.length > 1 &&
                                                i < projects?.length - 1 &&
                                                ", "}
                                        </div>
                                    ))}
                                </Typography>
                            </Typography>
                        ) : null}
                    </div>
                </div>
            </UICard>
            {isPreviewModalOpened && (
                <PreviewModal
                    dialogOpened={isPreviewModalOpened}
                    onChange={() => setIsPreviewModalOpened(false)}
                    isProject={isProjectCompany}
                    isIndustry={isIndustry}
                    isTechnology={isTechnology}
                    data={result}
                    handleOpenCompany={openProfile}
                    showEnrichRequestModal={showEnrichRequestModal}
                    user={user}
                    projectSlug={projectSlug}
                    type={type}
                    onOpen={openProfile}
                    shouldShowMatchScore={shouldShowMatchScore}
                    isWorkspace={isWorkspace}
                />
            )}
            {enrichRequestModalShow && (
                <EnrichRequestModal
                    source={type === "company" ? "company" : "technology"}
                    credits={credits}
                    company={result}
                    dialogOpened={enrichRequestModalShow}
                    onChange={handleClosingEnrichDialog}
                    createProjectDialogOpened={handleCreateProjectDialogOpened}
                    enrichSuccess={handleEnrichSuccess}
                    cId={result && result.id}
                    technologySlug={slug}
                />
            )}
            {notEnoughCreditsModalShow && (
                <NotEnoughCreditsModal
                    credits={credits}
                    dialogOpened={notEnoughCreditsModalShow}
                    onChange={handleClosingNotEnoughCreditsDialog}
                    onTopUp={handleTopUp}
                />
            )}
            {showOrderReportModal && (
                <OrderReportModal
                    enrichSuccess={handleEnrichSuccess}
                    technology={result}
                    user={user}
                    dialogOpened={showOrderReportModal}
                    onChange={() => setShowOrderReportModal(false)}
                    onNotEnoughCredits={handleEnoughCredits}
                    type={type}
                />
            )}
            {showEnrichSuccess && (
                <OrderReceivedModal
                    dialogOpened={showEnrichSuccess}
                    onChange={handleClosingSuccess}
                    source={type}
                />
            )}
            {topUpCreditsModalShow && (
                <TopUpCreditsModal
                    dialogOpened={topUpCreditsModalShow}
                    onBuy={handleBuyingTopUpCreditsDialog}
                    onChange={handleClosingTopUpCreditsDialog}
                />
            )}
            {showAreYouSureModal && (
                <AreYouSureModal
                    dialogOpened={showAreYouSureModal}
                    onChange={() => setShowAreYouSureModal(false)}
                    title={t("download.areYouSureTitle")}
                    subtitle={
                        <>
                            {t("download.subtitleFreeTrial1")}
                            <Typography
                                component="div"
                                className={classes.actionText}
                                variant="body"
                                color="secondaryDark">
                                {t("download.subtitleFreeTrial2")}
                            </Typography>
                        </>
                    }
                    actionButtonText={t("download.iWantToDownload")}
                    cancelButtonText={t("download.letsTryLater")}
                    onActionClick={download}
                />
            )}
            {showDownloadsExceededModal && (
                <AreYouSureModal
                    dialogOpened={showDownloadsExceededModal}
                    onChange={() => setShowDownloadsExceededModal(false)}
                    title={t("download.downloadsExceededTitle")}
                    subtitle={t("download.downloadsExceededSubtitle")}
                    actionButtonText={t("download.upgradePlan")}
                    cancelButtonText={t("download.upgradeLater")}
                    onActionClick={() =>
                        (window.location.href = getPricingPage(!!user))
                    }
                />
            )}
            {removeWarningModalOpened && (
                <RemoveWarningModal
                    projectSlug={slug}
                    project={result}
                    dialogOpened={removeWarningModalOpened}
                    onChange={() => setRemoveWarningModalOpened(false)}
                    onClose={() => setRemoveWarningModalOpened(false)}
                    isDeleteProject={true}
                    type={type}
                />
            )}
        </a>
    );
};

export default Card;
