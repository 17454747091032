import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { actions as searchActions } from "../../../store/search/slice";
import { actions as authActions } from "../../../store/auth/slice";
import { actions as resultsActions } from "../../../store/results/slice";
// UI components
import Typography from "../../../components/Typography/Typography";
import Menu from "../../../components/Menu/Menu";
import CreateProjectModal from "./modals/CreateProjectModal";
import ResultsTabs from "../../../components/ResultsTabs/ResultsTabs";
import Box from "@material-ui/core/Box";
// Hooks
import { useTranslation } from "react-i18next";
import useStyles from "../../Layout/styles";
import { useRouterHook } from "../../../common/useRouterHook";
import { useSelector, useDispatch } from "react-redux";
import useLocalStorage from "../../../common/useLocalStorage";
//Assets
import { ReactComponent as MoreIconNoFill } from "../../../assets/icons/MoreIconNoFill.svg";
import { ReactComponent as FilterModalIcon } from "../../../assets/icons/FilterModalIcon.svg";
// Redux
import { mixpanelTrackRequest } from "../../../store/mixpanel/requests";
import { actions } from "../../../store/search/slice";
import { fetchSearchesRequest } from "../../../store/results/requests";
import {
    getSearchesSelector,
    getSearchesEditSearchStatusSelector,
    getResultsCountSelector,
    getResultsCountStatusSelector,
    getCurrentSearchSidSelector,
} from "../../../store/results/selectors";
import {
    getSubscriptionSelector,
    getUpdateProfileStatusSelector,
} from "../../../store/auth/selectors";
// Constants
import { REQUEST_SUCCESS } from "../../../constants/statuses";
import TableToolbar from "./ListView/TableToolbar";
import TableToolbarRight from "./ListView/TableToolbarRight";
import FiltersModal from "../../../components/FiltersModal/FiltersModal";
import {useCompanies} from "../../../common/useCompanies";

const Header = ({
    isAuthenticated,
    searchResults,
    onFilterSelected,
    onSortChange,
    onSearch,
    filters,
    total,
    resetFilters,
    stageOptions,
    regionsOptions,
    handleRegionChange,
    fundingOptionsMin,
    fundingOptionsMax,
    yearOptionsMin,
    yearOptionsMax,
    teamSizeOptions,
    valuerScoreOptionsMin,
    valuerScoreOptionsMax,
    teamSizeOptionsMax,
    handleFieldChange,
    handleSearchChange,
    handleExcludeKeywordsChange,
    handleIncludeKeywordsChange,
    handleSectorChange,
    handleSubsectorChange,
    handleStageChange,
    handleLocationChange,
    resetFilter,
    handleFundingMinChange,
    handleYearMinChange,
    handleMatchingScoreMinChange,
    handleTeamSizeMinChange,
    allSectors,
    allSubsectors,
    selectedFilters,
    allCountries,
    setSelectedFilters,
    setDisplayedFilters,
    displayedFilters,
    setModalFilters,
    isLookAlikeResults,
    searchType,
    user,
    search,
    isEditLookalikeClicked,
    overviewVisibility,
    setOverviewVisibility,
    clusterVisibility,
    setClusterVisibility,
    isFilterFetching,
    slug,
    request,
    countRequest,
    page,
    filterNameValue,
    token,
    totalPages,
    filterStats,
    handleSectorsClose,
    handleSubsectorsClose,
    preselectedSectors,
    setPreselectedSectors,
    preselectedSubsectors,
    setPreselectedSubsectors,
    getCountStatusSelector,
    activeView,
    isIndustry,
    isTechnology,
    teamsizeOptions,
    matchingScoreMin,
    matchingScoreMax,
    countSelector,
    tabs,
    setTabs,
    listViewActive,
    cardViewActive,
    setActiveView,
    companiesPageId,
    modalFilterNameValue,
    filterMapping,
    currentSearchSid,
    isWorkspace,
    tableToolbarClassName,
    selected,
    setSelected,
    selectedNames,
    sortValue,
    sortDirection,
    tableToolbarRef,
    handleSelectAllClick,
    showClusterTab = false,
	onApplyFilters,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    // const getSearchesStatus = useSelector(getSearchesStatusSelector);
    const getSearchesEditSearchStatus = useSelector(
        getSearchesEditSearchStatusSelector,
    );
    const [createProjectDialogOpened, setcreateProjectDialogOpened] =
        useState(false);
    const dispatch = useDispatch();
    let searchID = useSelector(getCurrentSearchSidSelector);
    const { clearStorage } = useLocalStorage("results", searchID);
    const { clearStorage: clearStagePage } = useLocalStorage("results", "page");
    const subscription = useSelector(getSubscriptionSelector());
    const { openPage } = useRouterHook();
    const getUpdateProfileStatus = useSelector(getUpdateProfileStatusSelector);
    const [isStartSearchBtnClickTriggered, setIsStartSearchBtnClickTriggered] =
        useState(false);
    const searchesSelector = useSelector(getSearchesSelector);
    const [searches, setSearches] = useState(searchesSelector);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [displayedFiltersCount, setDisplayedFiltersCount] = useState(0);
    const [filtersModalOpened, setFiltersModalOpened] = useState(false);
    const urlParams = new URLSearchParams(search);
    const searchTypeLook = urlParams.get("searchTypeLook");
	
	const handleCreateProjectDialogToggle = () => {
        setcreateProjectDialogOpened(!createProjectDialogOpened);
    };
    // const openCreateProjectModal = () => {
    //     setcreateProjectDialogOpened(true);
    // }

    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
    };

    const handleShowFilters = value => {
        onFilterSelected(value);
    };
	
    const handleShowResults = value => {
		// console.log(value);
		onApplyFilters(value);
		
        dispatch(
            resultsActions.setResultsDisplayFilters({
                sid: isIndustry || isTechnology ? slug : currentSearchSid,
                displayedFilters: {
                    filterSid:
                        isIndustry || isTechnology ? slug : currentSearchSid,
                    sortValue: sortValue || [],
                    sortDirection: sortDirection || [],
                    sector: selectedFilters.sector || [],
                    subsector: selectedFilters.subsector || [],
                    subsectors: selectedFilters.subsectors || [],
                    stage: selectedFilters.stage || [],
                    fundingMin: selectedFilters.fundingMin || "",
                    fundingMax: selectedFilters.fundingMax || "",
                    teamsizeMin: selectedFilters.teamsizeMin || "",
                    teamsizeMax: selectedFilters.teamsizeMax || "",
                    location: selectedFilters.location || [],
                    yearMin: selectedFilters.yearMin || "",
                    yearMax: selectedFilters.yearMax || "",
                    matchingScoreMin: selectedFilters.matchingScoreMin,
                    matchingScoreMax: selectedFilters.matchingScoreMax || "",
                    regions: selectedFilters.regions || [],
                    search: selectedFilters.search || "",
                    excludeKeywords: selectedFilters.excludeKeywords || "",
                    includeKeywords: selectedFilters.includeKeywords || "",
                    storageResultsFilterNameValue: value || "",
                    storageResultsFilterPage: Number(page) || 1,
                },
            }),
        );
    };

    const { search: searchResultsValue } = selectedFilters;

    // useEffect(() => {
    //     handleShowResults(1);
    // }, [searchResultsValue]);

    useEffect(() => {
        if (
            getUpdateProfileStatus === REQUEST_SUCCESS &&
            isStartSearchBtnClickTriggered
        ) {
            dispatch(mixpanelTrackRequest({ eventName: "Start new search" }));
            dispatch(searchActions.clearSearch());
            dispatch(searchActions.clearLookalikeSearch());
            clearStorage();
            clearStagePage();
            // let searchID = localStorage.getItem("search_id");
            sessionStorage.removeItem(
                `results-${searchID}-FilterSortDirection`,
            );
            sessionStorage.removeItem(`results-${searchID}-FilterSortValue`);
            sessionStorage.removeItem(
                `results-page-${activeView}-FilterSortDirection`,
            );
            sessionStorage.removeItem(
                `results-page-${activeView}-FilterSortValue`,
            );
            openPage("/search-type");
        }
    }, [getUpdateProfileStatus]);

    useEffect(() => {
        return () => {
            dispatch(searchActions.clearSearch());
            dispatch(searchActions.clearLookalikeSearch());
            clearStorage();
            clearStagePage();
            // let searchID = localStorage.getItem("search_id");
            sessionStorage.removeItem(
                `results-${searchID}-FilterSortDirection`,
            );
            sessionStorage.removeItem(`results-${searchID}-FilterSortValue`);
            sessionStorage.removeItem(
                `results-page-${activeView}-FilterSortDirection`,
            );
            sessionStorage.removeItem(
                `results-page-${activeView}-FilterSortValue`,
            );
        };
    }, []);

    const startSearchBtnClick = () => {
        if (
            user &&
            !user.company &&
            subscription &&
            (subscription.name === "free" || subscription.free_trial) &&
            searchesSelector.length > 1
        ) {
            dispatch(authActions.completeProfileModalShowTrue());
            setIsStartSearchBtnClickTriggered(true);
        } else {
            dispatch(mixpanelTrackRequest({ eventName: "Start new search" }));
            dispatch(searchActions.clearSearch());
            dispatch(searchActions.clearLookalikeSearch());
            clearStorage();
            clearStagePage();
            sessionStorage.removeItem(
                `results-${searchID}-${activeView}-FilterSortDirection`,
            );
            sessionStorage.removeItem(
                `results-${searchID}-${activeView}-FilterSortValue`,
            );
            sessionStorage.removeItem(
                `results-page-${activeView}-FilterSortDirection`,
            );
            sessionStorage.removeItem(
                `results-page-${activeView}-FilterSortValue`,
            );
            openPage("/search-type");
        }
    };

    useEffect(() => {
        setSearches(searchesSelector);
    }, [searchesSelector]);

    const handleFiltersModalClose = () => {
        setFiltersModalOpened(!filtersModalOpened);
    };

    const openFiltersModal = () => {
        const urlParams = new URLSearchParams(search);
        let sid = urlParams.get("sid");
        let data;
        if (!!filterNameValue) {
            data = {
                sid,
                filterNameValue,
                orderBy: sortValue && sortValue.slug,
                orderDirection: sortDirection,
                page,
                perPage: activeView === "card" ? 10 : 50,
            };
        } else {
            data = {
                sid,
                filterNameValue,
                orderBy: sortValue && sortValue.slug,
                orderDirection: sortDirection,
                page,
                perPage: activeView === "card" ? 10 : 50,
            };
        }
        setModalFilters();
        setSelectedFilters(displayedFilters);
        setFiltersModalOpened(true);
    };

    useEffect(() => {
        let count = 0;
        Object.entries(displayedFilters).map(filter => {
            filter[0] !== "subsectors" &&
                filter[1] !== "" &&
                filter[1] &&
                filter[1].length !== 0 &&
                filter[1] !== undefined &&
                count++;
        });
        setDisplayedFiltersCount(count);
    }, [displayedFilters]);

    useEffect(() => {
        if (
            getSearchesEditSearchStatus === REQUEST_SUCCESS &&
            !isEditLookalikeClicked
        ) {
            let searchID = localStorage.getItem("search_id");
            let search =
                searches && searches.filter(search => search.id == searchID)[0];
            dispatch(actions.clearSearch());
            search &&
                search.sectors &&
                dispatch(actions.addSectors(search.sectors));
            search &&
                search.location &&
                dispatch(actions.updateLocation(JSON.parse(search.location)));
            search && dispatch(actions.setIndustryDesc(search.description));
            dispatch(actions.setProduct(1));

            const isTechnologySearch =
                search?.scope ===
                "Find companies that use specific technologies";
            // const isPatentSearch = search.scope === "Find companies with similar patent";

            // if (isPatentSearch) {
            //     openPage(`/search-patent`)
            // }
            if (isTechnologySearch) {
                openPage(`/search-type`);
            }
            dispatch(resultsActions.setSearchesEditSearchStatusUndefined());
        }
    }, [getSearchesEditSearchStatus, isEditLookalikeClicked]);

    const editSearch = () => {
        dispatch(
            fetchSearchesRequest({
                data: {
                    orderDirection: "desc",
                    orderBy: "created-at",
                    page: 1,
                },
                isEditSearch: true,
            }),
        );
        localStorage.setItem("edit_search_id", urlParams.get("sid"));
    };

    return (
        <div className={classes.resultsHeader}>
            <div className={classes.headerTitle}>
                <Box display="flex">
                    {/* <SearchImage className={classes.searchImage} /> */}
                    <Box
                        className={classes.headerTitleResultsCount}
                        alignItems="center">
                        {searchType && (
                            <Typography
                                gutterBottom
                                variant="subtitleResult"
                                align="center">
                                {isLookAlikeResults
                                    ? searchTypeLook
                                        ? t(
                                              "resultsHeader.searchLookALikeTitle",
                                          )
                                        : t("resultsHeader.lookAlikesTitle")
                                    : t("resultsHeader.resultsTitle")}
                            </Typography>
                        )}
                        <Typography
                            variant="bodySmall"
                            className={classes.resultsTotal}
                            align="center"
                            data-intercom-target="Number of results label">
                            {total === 100
                                ? t("resultsHeader.showingResults100Label", {
                                      count: total,
                                      page,
                                      totalPages,
                                  })
                                : totalPages > 1
                                ? t(
                                      "resultsHeader.showingResultsPaginationLabel",
                                      { count: total, page, totalPages },
                                  )
                                : t("resultsHeader.showingResultsLabel", {
                                      count: total,
                                  })}
                        </Typography>
                    </Box>
                </Box>
                <div>
                    {/*{*/}
                    {/*    searchType && searches && isAuthenticated && !isLookAlikeResults &&*/}
                    {/*    <Button*/}
                    {/*        test-id="edit-search-button"*/}
                    {/*        onClick={editSearch}*/}
                    {/*        className={classnames([classes.editSearchBtn, "non-tablet-inline-flex"])}*/}
                    {/*        data-intercom-target="Edit search"*/}
                    {/*        variant="tertiary">*/}
                    {/*        {t("resultsHeader.editSearch")}*/}
                    {/*    </Button>*/}
                    {/*}*/}
                    {/* <Menu
                        btnClassName={classes.moreButton}
                        onChange={handleMenuClick}
                        menuItems={!isLookAlikeResults ? [
                            {
                                label: t("resultsHeader.editSearch"),
                                onClick: editSearch
                            },
                            {
                                label: t("resultsHeader.startSearch"),
                                onClick: startSearchBtnClick
                            }
                        ] : [
                            {
                                label: t("resultsHeader.startSearch"),
                                onClick: startSearchBtnClick
                            }
                        ]}
                    >
                        <MoreIconNoFill className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />
                    </Menu> */}
                </div>
                {/* Filter button */}
                {slug !== "likedCompanies" && (
                    <div
                        className={classnames([
                            classes.filterButton,
                            {
                                [classes.filterButtonFull]:
                                    displayedFiltersCount > 0,
                            },
                        ])}
                        onClick={openFiltersModal}
                        test-id="filter-button"
                        data-intercom-target="Filters icon">
                        {displayedFiltersCount > 0 && (
                            <div className={classes.displayedFiltersCount}>
                                {displayedFiltersCount}
                            </div>
                        )}
                        <FilterModalIcon
                            className={classnames([
                                classes.filterIcon,
                                {
                                    [classes.filterIconFull]:
                                        displayedFiltersCount > 0,
                                },
                            ])}
                            count={Object.keys(displayedFilters).length}
                        />
                        <span>{t("resultsHeader.filterBtnTitle")}</span>
                    </div>
                )}
            </div>
            {filtersModalOpened && (
                <FiltersModal
                    results={searchResults}
                    dialogOpened={filtersModalOpened}
                    onClose={handleFiltersModalClose}
                    onShowResults={handleShowResults}
                    onShowFilters={handleShowFilters}
                    totalCount={total}
                    filters={filters}
                    resetFilters={resetFilters}
                    stageOptions={stageOptions}
                    handleRegionChange={handleRegionChange}
                    regionsOptions={regionsOptions}
                    fundingOptionsMin={fundingOptionsMin}
                    fundingOptionsMax={fundingOptionsMax}
                    yearOptionsMin={yearOptionsMin}
                    yearOptionsMax={yearOptionsMax}
                    teamsizeOptions={teamsizeOptions}
                    valuerScoreOptionsMin={valuerScoreOptionsMin}
                    valuerScoreOptionsMax={valuerScoreOptionsMax}
                    teamSizeOptionsMax={teamSizeOptionsMax}
                    matchingScoreMin={matchingScoreMin}
                    matchingScoreMax={matchingScoreMax}
                    handleFieldChange={handleFieldChange}
                    handleSearchChange={handleSearchChange}
                    handleExcludeKeywordsChange={handleExcludeKeywordsChange}
                    handleIncludeKeywordsChange={handleIncludeKeywordsChange}
                    handleSectorChange={handleSectorChange}
                    handleSubsectorChange={handleSubsectorChange}
                    handleStageChange={handleStageChange}
                    handleLocationChange={handleLocationChange}
                    resetFilter={resetFilter}
                    handleFundingMinChange={handleFundingMinChange}
                    handleYearMinChange={handleYearMinChange}
                    handleMatchingScoreMinChange={handleMatchingScoreMinChange}
                    handleTeamSizeMinChange={handleTeamSizeMinChange}
                    allSectors={allSectors}
                    allSubsectors={allSubsectors}
                    selectedFilters={selectedFilters}
                    preselectedSectors={preselectedSectors}
                    setPreselectedSectors={setPreselectedSectors}
                    preselectedSubsectors={preselectedSubsectors}
                    setPreselectedSubsectors={setPreselectedSubsectors}
                    allCountries={allCountries}
                    displayedFilters={displayedFilters}
                    user={user}
                    isIndustry={isIndustry}
                    countSelector={getResultsCountSelector}
                    filterStats={filterStats}
                    setSelectedFilters={setSelectedFilters}
                    handleSectorsClose={handleSectorsClose}
                    handleSubsectorsClose={handleSubsectorsClose}
                    getCountStatusSelector={getResultsCountStatusSelector}
                    filterMapping={filterMapping}
                />
            )}
            <ResultsTabs
                onSearch={onSearch}
                onFilterSelected={handleShowFilters}
                searchResults={searchResults}
                isAuthenticated={isAuthenticated}
                user={user}
                onSortChange={onSortChange}
                filters={filters}
                total={total}
                resetFilters={resetFilters}
                stageOptions={stageOptions}
                handleRegionChange={handleRegionChange}
                regionsOptions={regionsOptions}
                fundingOptionsMin={fundingOptionsMin}
                fundingOptionsMax={fundingOptionsMax}
                yearOptionsMin={yearOptionsMin}
                yearOptionsMax={yearOptionsMax}
                teamsizeOptions={teamSizeOptions}
                valuerScoreOptionsMin={valuerScoreOptionsMin}
                valuerScoreOptionsMax={valuerScoreOptionsMax}
                teamSizeOptionsMax={teamSizeOptionsMax}
                handleFieldChange={handleFieldChange}
                handleSearchChange={handleSearchChange}
                handleExcludeKeywordsChange={handleExcludeKeywordsChange}
                handleIncludeKeywordsChange={handleIncludeKeywordsChange}
                handleSectorChange={handleSectorChange}
                handleSubsectorChange={handleSubsectorChange}
                handleStageChange={handleStageChange}
                handleLocationChange={handleLocationChange}
                resetFilter={resetFilter}
                handleFundingMinChange={handleFundingMinChange}
                handleYearMinChange={handleYearMinChange}
                handleMatchingScoreMinChange={handleMatchingScoreMinChange}
                handleTeamSizeMinChange={handleTeamSizeMinChange}
                tabs={tabs}
                setTabs={setTabs}
                allSectors={allSectors}
                allSubsectors={allSubsectors}
                selectedFilters={selectedFilters}
                preselectedSectors={preselectedSectors}
                setPreselectedSectors={setPreselectedSectors}
                preselectedSubsectors={preselectedSubsectors}
                setPreselectedSubsectors={setPreselectedSubsectors}
                allCountries={allCountries}
                displayedFilters={displayedFilters}
                setSelectedFilters={setSelectedFilters}
                setDisplayedFilters={setDisplayedFilters}
                isFilterFetching={isFilterFetching}
                setModalFilters={setModalFilters}
                isLookAlikeResults={isLookAlikeResults}
                searchType={searchType}
                countSelector={getResultsCountSelector}
                getCountStatusSelector={getResultsCountStatusSelector}
                localStorageSlugPrefix="results"
                slug={slug}
                request={request}
                countRequest={countRequest}
                filterNameValue={filterNameValue}
                page={page}
                token={token}
                searchTypeLook={searchTypeLook}
                overviewVisibility={overviewVisibility}
                setOverviewVisibility={setOverviewVisibility}
                filterStats={filterStats}
                handleSectorsClose={handleSectorsClose}
                handleSubsectorsClose={handleSubsectorsClose}
                activeView={activeView}
                setActiveView={setActiveView}
                isProject={false}
                companiesPageId={companiesPageId}
                modalFilterNameValue={modalFilterNameValue}
                filterMapping={filterMapping}
                currentSearchSid={currentSearchSid}
                isWorkspace={isWorkspace}
                clusterVisibility={clusterVisibility}
                setClusterVisibility={setClusterVisibility}
                showClusterTab={showClusterTab}
                selected={selected}
                setSelected={setSelected}
            />
            <div className={classes.headerFiltersWrapper}>
                {(listViewActive || cardViewActive) && (
                    <div className={classes.headerFiltersWrapper}>
                        <TableToolbar
                            classes={classes}
                            ref={tableToolbarRef}
                            className={tableToolbarClassName}
                            numSelected={selected.length}
                            rowCount={searchResults?.length}
                            onSelectAllClick={handleSelectAllClick}
                            dispatch={dispatch}
                            isTechnology={false}
                            isIndustry={false}
                            searchResults={searchResults}
                            selected={selected}
                            selectedNames={selectedNames}
                            isAuthenticated={isAuthenticated}
                            user={user}
                            isProject={false}
                            // projectSlug={projectSlug}
                            id={companiesPageId}
                            isLikedCompany={false}
                            sortValue={sortValue}
                            sortDirection={sortDirection}
                        />
                    </div>
                )}
                {(listViewActive || cardViewActive) && (
                    <TableToolbarRight
                        numSelected={0}
                        rowCount={0}
                        onSelectAllClick={() => console.log("Selected all")}
                        selected={selected}
                        selectedNames={selectedNames}
                        className={classes.header}
                        classes={classes}
                        user={user}
                        isTechnology={false}
                        isIndustry={false}
                        isProject={false}
                        sortDirection={sortDirection}
                        sortValue={sortValue}
                        dispatch={dispatch}
                        id={companiesPageId}
                        setSelected={setSelected}
                    />
                )}
            </div>

            <CreateProjectModal
                source="results"
                dialogOpened={createProjectDialogOpened}
                onChange={handleCreateProjectDialogToggle}
            />
        </div>
    );
};
export default Header;
