import React, { useState, useEffect, createRef } from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
// UI components
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Box from "@material-ui/core/Box";
import MobileStepper from "@material-ui/core/MobileStepper";
import Link from "../../components/Link/Link";
import SubsectorsDialog from "./components/SubsectorsDialog";
import SectorsDialog from "./components/SectorsDialog";
import IconButton from "@material-ui/core/IconButton";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useSectorsHook } from "./hooks/useSectorsHook";
import { useAppEventTracking } from "../../common/useAppEventTracking";
// Assets
import { ReactComponent as CloseIconEmpty } from "../../assets/icons/CloseIconEmpty.svg";
import LeftArrow from "../../assets/icons/LeftArrow.svg";
import LeftArrowActive from "../../assets/icons/LeftArrowActive.svg";
import RightArrowActive from "../../assets/icons/RightArrowActive.svg";
import RightArrow from "../../assets/icons/RightArrow.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowDivider } from "../../assets/icons/GrayArrowRight.svg";
import { ReactComponent as WhiteArrowRight } from "../../assets/icons/WhiteArrowRight.svg";
import DescriptionSearchIcon from "../../assets/icons/DescriptionSearchIcon.png";
import CloseIconGreen from "../../assets/icons/CloseIconGreen.svg";
// Redux
import { actions as searchActions } from "../../store/search/slice";
import { fetchSectorsRequest } from "../../store/sectors/requests";
import {
    getIndustryDescSelector,
    getSearchSectorsSelector,
    getSearchCountStateSelector,
    getSearchLocationSelector,
    getSetSearchCountStatusSelector,
    getEditSearchDirtySelector,
} from "../../store/search/selectors";
import {
    submitSearchRequest,
    postSetSearchCountRequest,
    resetSearchCountRequest,
    checkSearchCountRequest,
} from "../../store/search/requests";
import { regionsOptions } from "../../constants/filters";
import TextField from "@material-ui/core/TextField";
import Grow from "@material-ui/core/Grow";
import Dialog from "@material-ui/core/Dialog";
import Multiselect from "../../components/Multiselect/Multiselect";
import { getCountriesSelector } from "../../store/countries/selectors";
import { getCountriesRequest } from "../../store/countries/requests";
import {
    getActiveViewSelector,
    getSearchesSelector,
} from "../../store/results/selectors";
import { useRouterHook } from "../../common/useRouterHook";
import { REQUEST_PENDING } from "../../constants/statuses";
import usePrevious from "../../common/usePrevious";

const Description = ({
    isResultsPage = false,
    isDashboard = false,
    isSharedPage = false,
    searchQuery,
    isEditMode,
    setIsEditMode,
    currentSearch,
    boxExpanded,
    setBoxExpanded,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sectorsRef = createRef(null);
    const reCaptchaRef = createRef();
    const { pathname, search } = useLocation();
    const history = useHistory();
    const { openPage } = useRouterHook();
    const editSearchDirty = useSelector(getEditSearchDirtySelector);
    const classes = useStyles({ isResultsPage, editSearchDirty });
    const getSetSearchCountStatus = useSelector(
        getSetSearchCountStatusSelector,
    );
    const maxSteps = 4;
    const searchLocation = useSelector(getSearchLocationSelector);
    const searchCountStateSelector = useSelector(getSearchCountStateSelector);
    const { mixpanelTrackEvent } = useAppEventTracking();
    // regions
    const [regionMenuItems, setRegionMenuItems] = useState(regionsOptions);
    const [filteredRegionMenuItems, setFilteredRegionMenuItems] = useState();
    const [selectedRegions, setSelectedRegions] = useState(
        searchLocation && !isSharedPage
            ? typeof searchLocation === "string"
                ? JSON.parse(searchLocation).regions
                : searchLocation.regions
            : (!!searchQuery &&
                  !!searchQuery.location &&
                  JSON.parse(searchQuery.location).regions) ||
                  [],
    );
    const [regionsInputValue, setRegionsInputValue] = useState("");
    const [regionsMenuOpened, setRegionsMenuOpened] = useState(false);
    const [regionsChanged, setRegionsChanged] = useState(false);
    const [countriesChanged, setCountriesChanged] = useState(false);
    // countries
    const countryMenuItems = useSelector(getCountriesSelector);
    const [filteredCountryMenuItems, setFilteredCountryMenuItems] = useState();
    const [selectedCountries, setSelectedCountries] = useState(
        searchLocation && !isSharedPage
            ? typeof searchLocation === "string"
                ? JSON.parse(searchLocation).countries
                : searchLocation.countries
            : (!!searchQuery &&
                  !!searchQuery.location &&
                  JSON.parse(searchQuery.location).countries) ||
                  [],
    );
    const prevSelectedCountries = usePrevious(selectedCountries);
    const [countriesInputValue, setCountriesInputValue] = useState("");
    const [countriesMenuOpened, setCountriesMenuOpened] = useState(false);
    // sectors
    const sectorsList = useSelector(getSearchSectorsSelector);
    const inputValue = useSelector(getIndustryDescSelector);
    const industryDescription = useSelector(getIndustryDescSelector);
    const [sectorsDialogOpened, setSectorsDialogOpened] = useState(false);
    const {
        selectedSearchSector,
        sector,
        newSubsector,
        handleSubsectorSelect,
        handleAddSubsector,
        handleSubsectorRemove,
        handleAddNewSubsector,
        openSubsectorsDialog,
        handleToggleDialog,
        dialogOpened,
        selectedSectors,
        allSectors,
        handleSectorSelect,
    } = useSectorsHook();
    const [sectorsToShow, setSectorsToShow] = useState(
        isSharedPage && searchQuery
            ? JSON.parse(searchQuery.sectors)
            : selectedSectors,
    );
    // other
    const [typingTimeout, setTypingTimeout] = useState(undefined);
    const [isSearchValid, setIsSearchValid] = useState(
        searchCountStateSelector,
    );
    const [textFieldMinRows, setTextFieldMinROws] = useState(1);
    const [errorMsg, setErrorMsgs] = useState(null);
    const [helpDialogOpened, setHelpDialogOpened] = useState(false);
    const [strength, setStrength] = useState("weak");
    const [activeStep, setActiveStep] = useState(0);
    const viewType = useSelector(getActiveViewSelector);
    const searchesSelector = useSelector(getSearchesSelector);
    const [searches, setSearches] = useState(searchesSelector);
    const isMobile = document.body.offsetWidth <= 768;

    const handleStopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        setSearches(searchesSelector);
    }, [searchesSelector]);

    useEffect(() => {
        !isSharedPage && setSectorsToShow(selectedSectors);
    }, [selectedSectors]);

    useEffect(() => {
        dispatch(checkSearchCountRequest());
        dispatch(getCountriesRequest({ regions: selectedRegions || [] }));
    }, [dispatch]);

    // TODO@jkica: remove this in the new results task (it will be handled in Header.js line 200)
    useEffect(() => {
        if (currentSearch) {
            dispatch(searchActions.setIndustryDesc(currentSearch.description));
            currentSearch.location
                ? dispatch(
                      searchActions.updateLocation(
                          JSON.parse(currentSearch.location),
                      ),
                  )
                : dispatch(searchActions.clearLocation());
            currentSearch.sectors
                ? dispatch(searchActions.addSectors(currentSearch.sectors))
                : dispatch(searchActions.removeSearchSectors());
        }
    }, [currentSearch]);

    useEffect(() => {
        if (searchLocation && !isSharedPage) {
            if (typeof searchLocation === "string") {
                setSelectedRegions(JSON.parse(searchLocation).regions);
                setSelectedCountries(JSON.parse(searchLocation).countries);
            } else {
                setSelectedRegions(searchLocation.regions);
                setSelectedCountries(searchLocation.countries);
            }
        }
    }, [searchLocation]);

    useEffect(() => {
        if (selectedRegions && selectedRegions.length) {
            dispatch(getCountriesRequest({ regions: selectedRegions }));
            // clear selected countries on region deselect
            regionsChanged &&
                setSelectedCountries(
                    selectedCountries.filter(item =>
                        countryMenuItems.map(x => x.name).includes(item),
                    ),
                );
        } else {
            setSelectedCountries([]);
            setFilteredCountryMenuItems(null);
            setCountriesInputValue("");
            setErrorMsgs(null);
        }
    }, [selectedRegions]);

    useEffect(() => {
        // clear selected countries on region deselect
        regionsChanged &&
            setSelectedCountries(
                selectedCountries.filter(item =>
                    countryMenuItems.map(x => x.name).includes(item),
                ),
            );
    }, [countryMenuItems]);

    useEffect(() => {
        countriesChanged &&
            JSON.stringify(prevSelectedCountries) !==
                JSON.stringify(selectedCountries) &&
            dispatch(searchActions.updateCountries(selectedCountries));
    }, [selectedCountries, prevSelectedCountries]);

    useEffect(() => {
        if (strength !== "weak") {
            dispatch(resetSearchCountRequest());
            setIsSearchValid(true);
        } else {
            setIsSearchValid(false);
        }
    }, [dispatch, strength]);

    const handleTextChange = e => {
        dispatch(searchActions.setIndustryDesc(e.target.value));
    };
    useEffect(() => {
        const urlParams = new URLSearchParams(search);
        let hsCtaTracking = urlParams.get("hsCtaTracking");
        dispatch(searchActions.postMLSearchUndefined());
        !!hsCtaTracking && dispatch(searchActions.clearSearch());
    }, [dispatch, search]);

    useEffect(() => {
        const inputValueLength = inputValue && inputValue.length;
        switch (true) {
            case inputValueLength > 150:
                setStrength("strong");
                break;
            case inputValueLength > 100:
                setStrength("good");
                break;
            case inputValueLength > 50:
                setStrength("fair");
                break;
            case inputValueLength > 0:
                setStrength("weak2");
                break;
            default:
                setStrength("weak");
        }
    }, [inputValue]);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    useEffect(() => {
        if (
            filteredRegionMenuItems &&
            filteredRegionMenuItems.length === 0 &&
            regionsInputValue.length !== 0
        ) {
            setErrorMsgs({ region: "No regions found by that name" });
        } else if (
            filteredCountryMenuItems &&
            filteredCountryMenuItems.length === 0 &&
            countriesInputValue.length !== 0
        ) {
            setErrorMsgs({ country: "No countries found by that name" });
        } else {
            setErrorMsgs(null);
        }
    }, [filteredRegionMenuItems, filteredCountryMenuItems]);

    useEffect(() => {
        dispatch(fetchSectorsRequest());
    }, [dispatch]);

    useEffect(
        () =>
            history.listen(() => {
                // reset search on page change
                if (
                    pathname !== window.location.pathname &&
                    !pathname.includes("lookalike")
                ) {
                    // dispatch(searchActions.clearSearch());
                }
                localStorage.removeItem("edit_search_id");
            }),
        [],
    );

    useEffect(() => {
        if (
            currentSearch &&
            industryDescription === currentSearch.description &&
            currentSearch.location &&
            (JSON.stringify(sectorsList) ===
                JSON.stringify(currentSearch.sectors) ||
                (sectorsList.length === 0 && !currentSearch.sectors)) &&
            (JSON.stringify(selectedRegions) ===
                JSON.stringify(JSON.parse(currentSearch.location).regions) ||
                (selectedRegions.length === 0 &&
                    !JSON.parse(currentSearch.location).regions)) &&
            (JSON.stringify(selectedCountries) ===
                JSON.stringify(JSON.parse(currentSearch.location).countries) ||
                (selectedCountries.length === 0 &&
                    !JSON.parse(currentSearch.location).countries))
        ) {
            dispatch(searchActions.setEditQueryClean());
        } else {
            dispatch(searchActions.setEditQueryDirty());
        }
    }, [industryDescription, sectorsList, selectedRegions, selectedCountries]);

    const handleCaptchaChange = () => {
        dispatch(postSetSearchCountRequest());
        dispatch(
            submitSearchRequest({
                description: industryDescription,
                searchSectors: sectorsList,
                location: {
                    regions: selectedRegions,
                    countries: selectedCountries,
                },
                scope: "Find companies that use specific technologies",
                searchType: 1,
                perPage: viewType === "card" ? 10 : 50,
            }),
        );
        setTimeout(() => {
            history.replace("/search-results-loading", {
                from: pathname,
                someOtherProp: "",
            });
        }, 200);
    };

    const handleSubmitSearch = e => {
        handleStopPropagation(e);

        let page = "Search";
        if (isDashboard) {
            page = "Dashboard";
        } else if (isResultsPage) {
            page = "Results";
        }
        let selectedSubsectors = [];
        selectedSectors.map(sector => {
            if (sector.subsectors) {
                sector.subsectors.map(subsector => {
                    selectedSubsectors.push(subsector.name);
                });
            }
        });
        mixpanelTrackEvent("Company search", {
            Page: page,
            Regions: selectedRegions,
            Countries: selectedCountries,
            Industries: selectedSectors.map(sector => sector.name),
            Subindustries: selectedSubsectors,
            EditMode: !!isEditMode,
            Type: "Regular",
        });
        if (process.env.REACT_APP_ENV === "prod") {
            reCaptchaRef.current.execute();
        } else {
            handleCaptchaChange();
            dispatch(searchActions.setEditQueryClean());
        }
    };

    const cancelEditSearch = e => {
        handleStopPropagation(e);
        if (currentSearch && currentSearch.sectors) {
            dispatch(searchActions.addSectors(currentSearch.sectors));
        } else {
            dispatch(searchActions.addSectors([]));
        }
        currentSearch &&
            currentSearch.location &&
            dispatch(
                searchActions.updateLocation(
                    JSON.parse(currentSearch.location),
                ),
            );
        currentSearch &&
            dispatch(searchActions.setIndustryDesc(currentSearch.description));
        setBoxExpanded(false);
    };

    const handleUseExample = (e, type) => {
        handleStopPropagation(e);
        dispatch(
            searchActions.setIndustryDesc(
                t(`industry.example1${type.length ? type : activeStep}`),
            ),
        );
        setHelpDialogOpened(false);
    };

    const handleSectorsDialogToggle = () => {
        setSectorsDialogOpened(!sectorsDialogOpened);
    };

    const handleInputFocus = () => {
        // setTextFieldMinROws(isTablet ? 10 : 3)
    };

    const handleInputBlur = () => setTextFieldMinROws(1);

    const handleRegionSelect = e => {
        setSelectedRegions(e.target.value);
        setRegionsChanged(true);
        dispatch(searchActions.updateRegions(e.target.value));
    };

    const handleCountrySelect = e => {
        setSelectedCountries(e.target.value);
        setCountriesChanged(true);
        dispatch(searchActions.updateCountries(e.target.value));
    };

    const handleMenuSearch = type => {
        setRegionsMenuOpened(false);
        setCountriesMenuOpened(false);
        typingTimeout !== undefined &&
            clearTimeout(typingTimeout) &&
            setTypingTimeout(undefined);
        setTypingTimeout(
            setTimeout(() => {
                filterMenu(type);
                setTypingTimeout(undefined);
            }, 750),
        );
    };

    const filterMenu = type => {
        if (type === "region") {
            regionsInputValue.length
                ? setFilteredRegionMenuItems(
                      regionMenuItems.filter(item =>
                          item.name
                              .toLowerCase()
                              .startsWith(regionsInputValue.toLowerCase()),
                      ),
                  )
                : setFilteredRegionMenuItems(null);
            setRegionsMenuOpened(true);
        }
        if (type === "country") {
            countriesInputValue.length
                ? setFilteredCountryMenuItems(
                      countryMenuItems.filter(item =>
                          item.name
                              .toLowerCase()
                              .startsWith(countriesInputValue.toLowerCase()),
                      ),
                  )
                : setFilteredCountryMenuItems(null);
            setCountriesMenuOpened(true);
        }
    };

    const handleHelpButtonClick = e => {
        handleStopPropagation(e);
        (isEditMode || !isResultsPage) && setHelpDialogOpened(true);
    };

    const handleArrowClick = e => {
        handleStopPropagation(e);
        setRegionsMenuOpened(true);
    };

    const handleSubsectorClick = (e, sector) => {
        handleStopPropagation(e);
        (isEditMode || !isResultsPage) &&
            handleSectorSelect(
                sector,
                selectedSectors.some(i => i.slug === sector.slug),
            );
    };

    const handleSubsectorRemoveClick = (e, sector, selectedSubsector) => {
        handleStopPropagation(e);
        (isEditMode || !isResultsPage) &&
            handleSubsectorRemove(sector.slug, selectedSubsector);
    };

    const handleOpenSubsectorsDialog = (e, sector) => {
        handleStopPropagation(e);
        openSubsectorsDialog(sector);
    };

    const handleAddNewIndustry = e => {
        handleStopPropagation(e);
        setSectorsDialogOpened(true);
    };

    const handleHelpDialogClose = e => {
        handleStopPropagation(e);
        setHelpDialogOpened(false);
    };

    return (
        <div>
            <div>
                <div>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-between"
                        className={classnames([
                            classes.wrapper,
                            classes.roleWrapper,
                        ])}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={classes.contentWrapper}>
                            {!isResultsPage && (
                                <>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.industryTitle1}>
                                        {t(
                                            "descriptionSearch.industrySearchTitle1",
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        className={classes.industryTitle2}>
                                        {t(
                                            "descriptionSearch.industrySearchTitle2",
                                        )}
                                    </Typography>
                                </>
                            )}
                            <div className={classes.industryWrapper}>
                                <div
                                    className={
                                        classes.industryWrapperSearchInput
                                    }
                                    onClick={handleStopPropagation}>
                                    {!isResultsPage && (
                                        <img
                                            src={DescriptionSearchIcon}
                                            alt={t(
                                                "industry.textareaPlaceholder",
                                            )}
                                            className={
                                                classes.descriptionSearchIcon
                                            }
                                        />
                                    )}
                                    <div className={classes.helpButtonWrapper}>
                                        <div
                                            onClick={handleHelpButtonClick}
                                            className={classes.helpButton}>
                                            {t("descriptionSearch.needHelp")}
                                            <ArrowDown
                                                className={
                                                    classes.helpArrowDown
                                                }
                                            />
                                        </div>
                                    </div>
                                    <TextField
                                        className={classnames([
                                            classes.textarea,
                                            {
                                                [classes.disabledClick]: !!(
                                                    !isEditMode && isResultsPage
                                                ),
                                            },
                                        ])}
                                        fullWidth={true}
                                        multiline
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                        minRows={
                                            isMobile ? 6 : textFieldMinRows
                                        }
                                        value={
                                            isSharedPage
                                                ? searchQuery.description
                                                : inputValue
                                        }
                                        onChange={e => handleTextChange(e)}
                                        placeholder={
                                            isMobile
                                                ? t("dashboard.textarea")
                                                : t(
                                                      "industry.textareaPlaceholder",
                                                  )
                                        }
                                        InputProps={{
                                            classes: {
                                                underline:
                                                    classes.removeInputUnderline,
                                                input: classes.textFieldInput,
                                            },
                                        }}
                                        test-id="search-technologies-search-field"
                                        onClick={handleStopPropagation}
                                    />
                                </div>
                                <div
                                    className={classnames([
                                        { [classes.scrollable]: !!isDashboard },
                                    ])}>
                                    <div className={classes.locations}>
                                        <div className={classes.location}>
                                            <div
                                                className={
                                                    classes.locationTitle1
                                                }>
                                                <Typography variant="body">
                                                    Select a Region from the
                                                    list
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    classes.regionsWrapper
                                                }
                                                onClick={handleStopPropagation}>
                                                <Multiselect
                                                    onClose={() =>
                                                        setRegionsMenuOpened(
                                                            false,
                                                        )
                                                    }
                                                    open={regionsMenuOpened}
                                                    placeholder="Region"
                                                    customClass={
                                                        classes.multiSelect
                                                    }
                                                    rootClass={
                                                        classes.multiSelectRoot
                                                    }
                                                    className={classnames([
                                                        classes.rowWrap,
                                                        {
                                                            [classes.disabledClick]:
                                                                !!(
                                                                    !isEditMode &&
                                                                    isResultsPage
                                                                ),
                                                        },
                                                    ])}
                                                    iconArrow={
                                                        <>
                                                            <ArrowDown
                                                                onClick={
                                                                    handleArrowClick
                                                                }
                                                                className={
                                                                    classes.arrowDown
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    classes.verticalDivider
                                                                }
                                                            />
                                                        </>
                                                    }
                                                    options={
                                                        filteredRegionMenuItems ||
                                                        regionMenuItems
                                                    }
                                                    value={
                                                        selectedRegions || []
                                                    }
                                                    isWithCheckboxes={true}
                                                    onChange={e => {
                                                        handleRegionSelect(e);
                                                    }}
                                                    menuItemClass={
                                                        classes.multiselectMenuItem
                                                    }
                                                />
                                                <input
                                                    placeholder="Type"
                                                    onKeyUp={() =>
                                                        handleMenuSearch(
                                                            "region",
                                                        )
                                                    }
                                                    onChange={e =>
                                                        setRegionsInputValue(
                                                            e.target.value,
                                                        )
                                                    }
                                                    className={classnames([
                                                        classes.simpleInput,
                                                        {
                                                            [classes.disabledClick]:
                                                                !isEditMode &&
                                                                isResultsPage,
                                                        },
                                                    ])}
                                                />
                                            </div>
                                            <div className={classes.error1}>
                                                {!!errorMsg && errorMsg.region
                                                    ? errorMsg.region
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className={classes.location}>
                                            <div
                                                className={
                                                    classes.arrowDivider
                                                }>
                                                <ArrowDivider />
                                            </div>
                                            <div
                                                className={
                                                    classes.locationTitle2
                                                }>
                                                <Typography variant="body">
                                                    Select a Country from the
                                                    list
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    classes.countriesWrapper
                                                }
                                                onClick={handleStopPropagation}>
                                                <Multiselect
                                                    onClose={() =>
                                                        setCountriesMenuOpened(
                                                            false,
                                                        )
                                                    }
                                                    open={countriesMenuOpened}
                                                    placeholder={
                                                        selectedRegions &&
                                                        selectedRegions.length
                                                            ? "Country"
                                                            : "Select region first"
                                                    }
                                                    disabled={
                                                        selectedRegions &&
                                                        !selectedRegions.length
                                                    }
                                                    customClass={
                                                        classes.multiSelect
                                                    }
                                                    className={classnames([
                                                        classes.rowWrap,
                                                        {
                                                            [classes.disabledClick]:
                                                                !!(
                                                                    !isEditMode &&
                                                                    isResultsPage
                                                                ),
                                                        },
                                                    ])}
                                                    iconArrow={
                                                        <>
                                                            <ArrowDown
                                                                onClick={() =>
                                                                    selectedRegions &&
                                                                    selectedRegions.length &&
                                                                    setCountriesMenuOpened(
                                                                        true,
                                                                    )
                                                                }
                                                                className={classnames(
                                                                    [
                                                                        {
                                                                            [classes.arrowDown]:
                                                                                selectedRegions &&
                                                                                selectedRegions.length,
                                                                            [classes.arrowDownDisabled]:
                                                                                !(
                                                                                    selectedRegions &&
                                                                                    selectedRegions.length
                                                                                ),
                                                                        },
                                                                    ],
                                                                )}
                                                            />
                                                            <div
                                                                className={
                                                                    classes.verticalDivider
                                                                }
                                                            />
                                                        </>
                                                    }
                                                    options={
                                                        filteredCountryMenuItems ||
                                                        countryMenuItems
                                                    }
                                                    value={
                                                        selectedCountries || []
                                                    }
                                                    isWithCheckboxes={true}
                                                    onChange={e => {
                                                        handleCountrySelect(e);
                                                    }}
                                                    menuItemClass={
                                                        classes.multiselectMenuItem
                                                    }
                                                />
                                                <input
                                                    placeholder="Type"
                                                    disabled={
                                                        selectedRegions &&
                                                        !selectedRegions.length
                                                    }
                                                    onKeyUp={() =>
                                                        handleMenuSearch(
                                                            "country",
                                                        )
                                                    }
                                                    onChange={e =>
                                                        setCountriesInputValue(
                                                            e.target.value,
                                                        )
                                                    }
                                                    value={countriesInputValue}
                                                    className={classnames([
                                                        classes.simpleInput,
                                                        {
                                                            [classes.disabledClick]:
                                                                !!(
                                                                    !isEditMode &&
                                                                    isResultsPage
                                                                ),
                                                        },
                                                    ])}
                                                />
                                            </div>
                                            <div className={classes.error2}>
                                                {!!errorMsg && errorMsg.country
                                                    ? errorMsg.country
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.divider}></div>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="start"
                                        className={classes.subsectors}
                                        ref={sectorsRef}>
                                        <div
                                            className={
                                                classes.industriesLabels
                                            }>
                                            <Typography
                                                variant="body"
                                                color="black"
                                                component="div"
                                                className={
                                                    classes.industryLabel
                                                }>
                                                {t("subsectors.industries")}
                                            </Typography>
                                            <Typography
                                                variant="body"
                                                color="black"
                                                component="div"
                                                className={
                                                    classes.subsectorsLabel
                                                }>
                                                <div
                                                    className={
                                                        classes.arrowDividerSubsectors
                                                    }>
                                                    <ArrowDivider />
                                                </div>
                                                {t("subsectors.subsectors")}
                                            </Typography>
                                        </div>
                                        {sectorsToShow &&
                                            sectorsToShow.map(sector => (
                                                <Box
                                                    className={
                                                        classes.subsectorSection
                                                    }
                                                    key={`selected-sector-${sector.slug}`}>
                                                    <div
                                                        className={
                                                            classes.subsectorSectionIndustry
                                                        }>
                                                        <Button
                                                            variant="pill"
                                                            iconMarginLeft
                                                            iconMargin={false}
                                                            className={classnames(
                                                                [
                                                                    classes.sector,
                                                                    classes.selectedSubsector,
                                                                ],
                                                            )}
                                                            onClick={e =>
                                                                handleSubsectorClick(
                                                                    e,
                                                                    sector,
                                                                )
                                                            }>
                                                            {sector.name}{" "}
                                                            {isEditMode ||
                                                            !isResultsPage ? (
                                                                <CloseIconEmpty
                                                                    className={classnames(
                                                                        [
                                                                            classes.removeIndustryIcon,
                                                                            "blackIcon",
                                                                        ],
                                                                    )}
                                                                />
                                                            ) : null}
                                                        </Button>
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.subsectorSectionIndustry
                                                        }>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            flexWrap="wrap">
                                                            <Typography
                                                                variant="body"
                                                                color="black"
                                                                component="div"
                                                                className={
                                                                    classes.subsectorsLabelMobile
                                                                }>
                                                                {t(
                                                                    "subsectors.subsectors",
                                                                )}
                                                            </Typography>
                                                            {sector &&
                                                                sector.subsectors &&
                                                                sector
                                                                    .subsectors
                                                                    .length >
                                                                    0 && (
                                                                    <>
                                                                        <div
                                                                            className={
                                                                                classes.subsectorsWrapper
                                                                            }>
                                                                            {sector.subsectors.map(
                                                                                selectedSubsector => (
                                                                                    <Button
                                                                                        variant="pill"
                                                                                        iconMarginLeft
                                                                                        iconMargin={
                                                                                            false
                                                                                        }
                                                                                        key={
                                                                                            selectedSubsector.slug
                                                                                        }
                                                                                        className={classnames(
                                                                                            [
                                                                                                classes.sector,
                                                                                                classes.selectedSubsector,
                                                                                            ],
                                                                                        )}
                                                                                        onClick={e =>
                                                                                            handleSubsectorRemoveClick(
                                                                                                e,
                                                                                                sector,
                                                                                                selectedSubsector,
                                                                                            )
                                                                                        }>
                                                                                        {
                                                                                            selectedSubsector.name
                                                                                        }{" "}
                                                                                        {isEditMode ||
                                                                                        !isResultsPage ? (
                                                                                            <CloseIconEmpty
                                                                                                className={classnames(
                                                                                                    [
                                                                                                        classes.removeIndustryIcon,
                                                                                                        "blackIcon",
                                                                                                    ],
                                                                                                )}
                                                                                            />
                                                                                        ) : null}
                                                                                    </Button>
                                                                                ),
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            {(isEditMode ||
                                                                !isResultsPage) &&
                                                                (!sector.subsectors ||
                                                                    (sector.subsectors &&
                                                                        sector
                                                                            .subsectors
                                                                            .length <
                                                                            5)) && (
                                                                    <Button
                                                                        variant={
                                                                            !sector.subsectors
                                                                                ? "textPrimary"
                                                                                : "textPrimaryThin"
                                                                        }
                                                                        className={classnames(
                                                                            [
                                                                                classes.selectedSubsector,
                                                                                {
                                                                                    [classes.addSubsectorBtn]:
                                                                                        !!(
                                                                                            sector.subsectors &&
                                                                                            sector
                                                                                                .subsectors
                                                                                                .length <
                                                                                                5
                                                                                        ),
                                                                                },
                                                                            ],
                                                                        )}
                                                                        onClick={e =>
                                                                            handleOpenSubsectorsDialog(
                                                                                e,
                                                                                sector,
                                                                            )
                                                                        }>
                                                                        {t(
                                                                            "focus.addSubsector",
                                                                        )}
                                                                    </Button>
                                                                )}
                                                        </Box>
                                                    </div>
                                                </Box>
                                            ))}
                                    </Box>
                                    {(isEditMode || !isResultsPage) &&
                                        sectorsToShow &&
                                        sectorsToShow.length < 5 && (
                                            <Button
                                                variant="textPrimaryThin"
                                                onClick={handleAddNewIndustry}>
                                                {t("industry.addNewIndustry")}
                                            </Button>
                                        )}
                                    {process.env.REACT_APP_ENV === "prod" && (
                                        <ReCAPTCHA
                                            sitekey={
                                                process.env
                                                    .REACT_APP_CAPTCHA_APP_KEY
                                            }
                                            size="invisible"
                                            badge="bottomleft"
                                            ref={reCaptchaRef}
                                            onChange={handleCaptchaChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </Box>
                    </Box>
                </div>
                {!isResultsPage ? (
                    <Button
                        variant="primary"
                        size="large"
                        className={classes.continueCta}
                        onClick={handleSubmitSearch}
                        disabled={!isSearchValid}>
                        <span className={classes.continueCtaText}>
                            {t("industry.search")}
                        </span>
                        <WhiteArrowRight />
                    </Button>
                ) : currentSearch ? (
                    <div className={classes.footerButtonsWrapper}>
                        <div className={classes.footerButtons}>
                            {/*TODO@jkica: dont forget to add disabled state for cancel and apply buttons (after recent searches is done)*/}
                            <Button
                                onClick={cancelEditSearch}
                                variant="tertiaryBlack"
                                size="large"
                                className={classes.footerButton1}>
                                {t("results.cancelEditButton")}
                            </Button>
                            <Button
                                onClick={handleSubmitSearch}
                                //   disabled={(!isSearchValid || !editSearchDirty) && getSetSearchCountStatus !== REQUEST_PENDING}
                                variant="primary"
                                size="large"
                                className={classes.footerButton2}
                                loading={
                                    getSetSearchCountStatus === REQUEST_PENDING
                                }
                                disabled={!editSearchDirty}>
                                {t("results.applyEditButton")}
                            </Button>
                        </div>
                    </div>
                ) : null}
                <SubsectorsDialog
                    classes={classes}
                    selectedSearchSector={selectedSearchSector}
                    sector={sector}
                    newSubsector={newSubsector}
                    handleSubsectorSelect={handleSubsectorSelect}
                    handleAddSubsector={handleAddSubsector}
                    handleSubsectorRemove={handleSubsectorRemove}
                    handleAddNewSubsector={handleAddNewSubsector}
                    handleToggleDialog={handleToggleDialog}
                    dialogOpened={dialogOpened}
                />
                <SectorsDialog
                    classes={classes}
                    handleToggleDialog={handleSectorsDialogToggle}
                    dialogOpened={sectorsDialogOpened}
                    selectedSectorsState={selectedSectors}
                    allSectors={allSectors}
                    handleSectorSelect={handleSectorSelect}
                />
                <Dialog
                    maxWidth="lg"
                    TransitionComponent={Grow}
                    classes={{
                        root: classes.dialogRoot,
                        paper: classes.subsectorsDialog,
                        scrollPaper: classes.subsectorsDialogScrollPaper,
                    }}
                    onClose={handleHelpDialogClose}
                    open={helpDialogOpened}>
                    <div
                        className={classes.mobileStepper}
                        onClick={handleStopPropagation}>
                        <div className={classes.industryCompanySearchExamples}>
                            <div className={classes.industrySearchExampleText}>
                                {helpDialogOpened ? (
                                    <IconButton
                                        className={classes.closeButton}
                                        onClick={handleHelpDialogClose}>
                                        <img
                                            src={CloseIconGreen}
                                            alt={t("scope.step2")}
                                            className={classes.stepIcon}
                                        />
                                    </IconButton>
                                ) : null}
                                <Typography variant="bodyXl" component="div">
                                    <strong>
                                        {t("industry.textExamples")}
                                    </strong>
                                </Typography>
                                <div className={classes.exampleTextWrapper}>
                                    <Typography
                                        variant="bodySmall"
                                        className={classes.exampleText}>
                                        "{t(`industry.example1${activeStep}`)}"
                                    </Typography>
                                    {/*<Typography variant="body2" component="span" color="primaryDark" onClick={handleUseExample} className={classes.link}>{t(`industry.useThisExample`)}</Typography>*/}
                                </div>
                                <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    variant="text"
                                    activeStep={activeStep}
                                    classes={{
                                        root: classes.mobileStepperRoot,
                                    }}
                                    nextButton={
                                        <Link
                                            href="#"
                                            variant="body2"
                                            iconRight
                                            onClick={handleNext}
                                            className={
                                                classes.exampleStepperLink
                                            }
                                            disabled={
                                                activeStep === maxSteps - 1
                                            }>
                                            <img
                                                src={
                                                    activeStep === maxSteps - 1
                                                        ? RightArrow
                                                        : RightArrowActive
                                                }
                                                alt={t(
                                                    "industry.queryExamples",
                                                )}
                                                className={classes.infoIcon}
                                            />
                                        </Link>
                                    }
                                    backButton={
                                        <Link
                                            href="#"
                                            variant="body2"
                                            onClick={handleBack}
                                            className={
                                                classes.exampleStepperLink
                                            }
                                            disabled={activeStep === 0}>
                                            <img
                                                src={
                                                    activeStep === 0
                                                        ? LeftArrow
                                                        : LeftArrowActive
                                                }
                                                alt={t(
                                                    "industry.queryExamples",
                                                )}
                                                className={classes.infoIcon}
                                            />
                                        </Link>
                                    }
                                />
                            </div>
                            <Button
                                onClick={e => handleUseExample(e, activeStep)}
                                variant="tertiarySuccessReversed"
                                className={classes.industrySearchExampleBtn}>
                                {t("descriptionSearch.useExample")}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.mobileStepper}>
                        <div className={classes.industrySearchExample}>
                            <div className={classes.industrySearchExampleText}>
                                <Typography component="div" variant="bodyXl">
                                    <strong>
                                        {t(
                                            "descriptionSearch.industryExampleTitle",
                                        )}
                                    </strong>
                                </Typography>
                                <Typography component="div" variant="bodySmall">
                                    {t(
                                        "descriptionSearch.industryExampleSubtitle",
                                    )}
                                </Typography>
                            </div>
                            <Button
                                onClick={e => handleUseExample(e, "Industry")}
                                variant="tertiarySuccessReversed"
                                className={classes.industrySearchExampleBtn}>
                                {t("descriptionSearch.useExample")}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className={classes.technologySearchExample}>
                            <div
                                className={classes.technologySearchExampleText}>
                                <Typography component="div" variant="bodyXl">
                                    <strong>
                                        {t(
                                            "descriptionSearch.technologyExampleTitle",
                                        )}
                                    </strong>
                                </Typography>
                                <Typography component="div" variant="bodySmall">
                                    {t(
                                        "descriptionSearch.technologyExampleSubtitle",
                                    )}
                                </Typography>
                            </div>
                            <Button
                                onClick={e => handleUseExample(e, "Technology")}
                                variant="tertiarySuccessReversed"
                                className={classes.technologySearchExampleBtn}>
                                {t("descriptionSearch.useExample")}
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default withRouter(Description);
