import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import SearchService from "../api/search/searchService";

export const useSearches = (searchId, page) => {
    const queryClient = useQueryClient();
    const searchService = new SearchService();

    const getRecentSearches = useQuery({
        queryKey: ["searches", page],
        retry: 1,
        queryFn: () => searchService.getRecentSearches(searchId),
	    refetchOnWindowFocus: false
    });
    
    const getSearch = useQuery({
        queryKey: ["search", searchId],
        retry: 1,
        enabled: !!searchId,
        queryFn: () => searchService.getSearch(searchId),
	    refetchOnWindowFocus: false
    });
    
    const deleteSearch = useMutation({
        mutationFn: (sid) => {searchService.deleteSearch(sid)},
        onSuccess: (data) => {
            queryClient.invalidateQueries(['searches']);
            queryClient.invalidateQueries(['search', searchId]);
        }
    });

    return {
        getRecentSearches,
        deleteSearch,
        getSearch
    };
};
