import React, { useEffect, useState } from "react";
import classnames from "classnames";
import ClickAwayListener from "react-click-away-listener";
import { useTranslation } from "react-i18next";
import { useStyles } from "../style.js";
import usePrevious from "../../../common/usePrevious";
//UI components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "../../../components/Typography/Typography";
import TextField from "../../../components/TextField/TextField";
import Multiselect from "../../../components/Multiselect/Multiselect";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Menu from "../../../components/Menu/Menu";
import OrderTableRow from "./OrderTableRow";
import ContentLoader from "../../../components/ContentLoader/ContentLoader";
import DownloadButton from "../../../components/DownloadButton/DownloadButton";
import AreYouSureModal from "../../../components/AreYouSureModal/AreYouSureModal";
import { AddToProjectMenu } from "../../../components/AddToProjectMenu/AddToProjectMenu.js";
// Assets
import { ReactComponent as SearchPlaceholderIcon } from "../../../assets/icons/SearchMagnifier.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/ArrowDownBlue.svg";
import { ReactComponent as SortDown } from "../../../assets/icons/SortDown.svg";
import { ReactComponent as SortUp } from "../../../assets/icons/SortUp.svg";
import NoOrders from "../../../assets/images/NoOrders.png";
import { ReactComponent as MoreIconNoFill } from "../../../assets/icons/MoreIconNoFill.svg";
// Redux
import {
    fetchOrdersRequest,
    exportCsvRequest,
} from "../../../store/orders/requests";
// Constants
import {
    REQUEST_SUCCESS,
    REQUEST_PENDING,
    REQUEST_FAILURE,
} from "../../../constants/statuses";
import { getPricingPage } from "../../../constants/endpoints";
//Hooks
import { useAppEventTracking } from "../../../common/useAppEventTracking";

function compare(a, b) {
    if (a.requested_at < b.requested_at) {
        return -1;
    }
    if (a.requested_at > b.requested_at) {
        return 1;
    }
    return 0;
}

const TabContent = ({
    type,
    dispatch,
    ordersStore,
    getOrdersStatus,
    activeTab,
    user,
    onSelectedOrdersChange,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appTrackEvent, mixpanelTrackEvent } = useAppEventTracking();
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isMultiAvailable, setIsMultiAvailable] = useState(false);
    const [isMultiSelected, setIsMultiSelected] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedOrdersNames, setSelectedOrdersNames] = useState("");
    const sortOptions = [{ id: 0, name: "Order date", slug: "date" }];
    const [sortValue, setSortValue] = useState(sortOptions[0]);
    const [sortDirection, setSortDirection] = useState("desc");
    const [clickableOrders, setClickableOrders] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState(ordersStore);
    const prevUser = usePrevious(user);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
        useState(false);
    const [downloadProps, setDownloadProps] = useState();

    const getDownloadProps = (ids, type, page, pageType, doc, name) => {
        setDownloadProps({ ids, type, page, pageType, doc, name });
    };

    const download = () => {
        dispatch(exportCsvRequest(downloadProps));
        mixpanelTrackEvent(`Downloaded document`, {
            type: downloadProps.doc,
            page: "orders",
        });
    };

    useEffect(() => {
        setOrders(
            ordersStore.slice().sort((a, b) => {
                let da = new Date(a.requested_at),
                    db = new Date(b.requested_at);
                return da - db;
            }),
        );
    }, [ordersStore]);

    useEffect(() => {
        if (sortDirection === "desc") {
            setOrders(
                ordersStore
                    .slice()
                    .filter(f =>
                        f.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()),
                    )
                    .sort((a, b) => {
                        let da = new Date(a.requested_at),
                            db = new Date(b.requested_at);
                        return da - db;
                    }),
            );
        } else {
            setOrders(
                ordersStore
                    .slice()
                    .filter(f =>
                        f.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()),
                    )
                    .sort((a, b) => {
                        let da = new Date(a.requested_at),
                            db = new Date(b.requested_at);
                        return da - db;
                    })
                    .reverse(),
            );
        }
    }, [searchValue, sortDirection]);

    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
    };

    const moreButtonActionBoxMenuItems = [
        {
            label: (
                <Typography variant="bodyXs" className={classes.mono}>
                    {t("common.downloadAsCSV")}
                </Typography>
            ),
            onClick: () =>
                handleDownload({
                    ids: selectedOrders,
                    type: type,
                    doc: "csv",
                    name: "valuer_company_reports",
                    pageName: "orders",
                }),
        },
        {
            label: (
                <Typography variant="bodyXs" className={classes.mono}>
                    {t("common.downloadAsPDF")} aa
                </Typography>
            ),
            onClick: () =>
                handleDownload({
                    ids: selectedOrders,
                    type: type,
                    doc: "pdf",
                    name: "valuer_company_reports",
                    pageName: "orders",
                }),
        },
    ];

    useEffect(() => {
        setLoading(getOrdersStatus === REQUEST_PENDING);
    }, [getOrdersStatus]);

    useEffect(() => {
        setIsMultiAvailable(!!clickableOrders.length);
    }, [clickableOrders]);

    useEffect(() => {
        let arr = [];
        orders.map(order => {
            order.status === "Request complete" && arr.push(order.oid);
        });
        setClickableOrders(arr);
    }, [orders]);

    useEffect(() => {
        user &&
            JSON.stringify(user) !== JSON.stringify(prevUser) &&
            dispatch(
                fetchOrdersRequest({
                    type: activeTab,
                    team: user.team && user.team.slug,
                }),
            );
    }, [dispatch, activeTab, user]);

    const changeSortDirection = () => {
        setSortDirection(prevSortDirection =>
            prevSortDirection === "asc" ? "desc" : "asc",
        );
    };

    const handleSelectChange = event => {
        setSortValue(
            sortOptions.find(option => option.name === event.target.value),
        );
        handleSortChange(
            sortOptions.find(option => option.name === event.target.value).slug,
            sortDirection,
        );
    };

    const handleSortChange = (sortValue, sortDirection) => {
        setSortValue(sortValue);
        setSortDirection(sortDirection);
    };

    useEffect(() => {
        handleSortChange(sortValue, sortDirection);
    }, [sortDirection]);

    const handleClickOnSearch = () => {};

    const handleSearch = event => {
        setSearchValue(event.target.value);
    };

    useEffect(() => {
        setIsMultiSelected(selectedOrders && selectedOrders.length > 0);
        let name = "";
        selectedOrders.forEach((selectedOrder, i) => {
            if (name === "") {
                name = orders.filter(f => f.oid === selectedOrder)[0].name;
            } else {
                name = name.concat(
                    i === selectedOrders.length - 1 ? ` and ` : `, `,
                    orders.filter(f => f.oid === selectedOrder)[0].name,
                );
            }
            setSelectedOrdersNames(name);
        });
        onSelectedOrdersChange(selectedOrders);
    }, [selectedOrders]);

    const handleCheckboxChange = id => {
        setSelectedOrders(prevSelectedOrders => {
            let arr = prevSelectedOrders;
            if (arr.includes(id)) {
                arr.splice(arr.indexOf(id), 1);
            } else {
                arr.push(id);
            }
            return [...arr];
        });
    };

    const handleSelectAllChange = () => {
        let arr = [];
        orders.map(order => {
            order.status === "Request complete" && arr.push(order.oid);
        });
        if (selectedOrders.length === arr.length) {
            setSelectedOrders([]);
        } else {
            setSelectedOrders(arr);
        }
    };

    const handleDownload = payload => {
        if (
            user.downloads === 1 &&
            (user.subscription.plan_type === "free_trial" ||
                user.subscription.plan_type === "free")
        ) {
            setShowDownloadsExceededModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            user.subscription.is_active
        ) {
            setShowAreYouSureModal(true);
        } else if (
            user.subscription.plan_type === "free_trial" &&
            !user.subscription.is_active
        ) {
            window.location.href = getPricingPage(!!user);
        } else if (user.subscription.plan_type === "free") {
            setShowAreYouSureModal(true);
        } else {
            dispatch(exportCsvRequest(payload));
            mixpanelTrackEvent(`Downloaded document`, {
                "Order type": type == "companies" ? "company" : "technology",
                "Order names": selectedOrdersNames,
                type: payload.doc,
                page: "orders",
            });
        }
    };

    return (
        <>
            {orders.length > 0 && (
                <div
                    className={classes.headerWrapper}
                    data-intercom-target="Search, sort and filter bar">
                    <div className={classes.header}>
                        <div className={classes.refineAndSearch}>
                            <TextField
                                testId="header-search-field"
                                onClick={handleClickOnSearch}
                                endAdornment={
                                    <SearchPlaceholderIcon
                                        className={classes.startEndornment}
                                    />
                                }
                                value={searchValue || ""}
                                className={classes.headerInput}
                                fullWidth
                                placeholder={t(
                                    type == "companies"
                                        ? "orders.searchEnrichments"
                                        : "orders.searchTechnologies",
                                )}
                                onChange={handleSearch}
                                data-intercom-target={`${t(
                                    type == "companies"
                                        ? "orders.searchEnrichments"
                                        : "orders.searchTechnologies",
                                )} "element"`}
                            />
                        </div>
                        <div className={classes.headerSortAndFilter}>
                            <div className={classes.filterWrap}>
                                <div
                                    test-id={
                                        sortDirection === "asc"
                                            ? "sort-icon-asc"
                                            : "sort-icon-desc"
                                    }
                                    onClick={() => changeSortDirection()}
                                    className={classnames([
                                        classes.sortingButton,
                                        {
                                            [classes.rotateSortingButton]:
                                                sortDirection === "asc",
                                        },
                                    ])}>
                                    {sortDirection === "asc" ? (
                                        <SortUp
                                            className={classes.sortingIcon}
                                        />
                                    ) : (
                                        <SortDown
                                            className={classes.sortingIcon}
                                        />
                                    )}
                                </div>
                                <Multiselect
                                    noLeftRadius
                                    customClass={classes.multiselectAdjust}
                                    testId={
                                        sortDirection === "asc"
                                            ? "sort-asc"
                                            : "sort-desc"
                                    }
                                    removeLabel
                                    iconArrow={
                                        <ArrowIcon
                                            className={classes.arrowDown}
                                        />
                                    }
                                    options={sortOptions}
                                    value={sortValue && sortValue.name}
                                    onChange={handleSelectChange}
                                    data-intercom-target="Orders sorting element"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isMultiAvailable && (
                <div className={classes.actionBox}>
                    <FormControlLabel
                        label={
                            <Typography
                                variant="bodySmall"
                                color="black"
                                className={classnames([
                                    classes.mono,
                                    classes.checkBoxLabel,
                                ])}>
                                {t(
                                    !!(
                                        selectedOrders.length ===
                                        clickableOrders.length
                                    )
                                        ? "orders.deselectAll"
                                        : "orders.selectAll",
                                )}
                            </Typography>
                        }
                        control={
                            <Checkbox
                                checked={
                                    !!(
                                        selectedOrders.length ===
                                        clickableOrders.length
                                    )
                                }
                                onChange={handleSelectAllChange}
                            />
                        }
                    />
                    <div className={classes.flexRow}>
                        {!!isMultiSelected && (
                            <Menu
                                btnClassName={classnames([
                                    classes.moreButton,
                                    classes.moreButtonActionBox,
                                ])}
                                onChange={handleMenuClick}
                                menuItems={moreButtonActionBoxMenuItems}
                                otherItems={
                                    selectedOrders &&
                                    selectedOrders.length > 0 && (
                                        <AddToProjectMenu
                                            displayListTypeInMenu={true}
                                            company={{
                                                id: selectedOrders[0].oid,
                                                name: selectedOrdersNames[0]
                                                    .name,
                                            }}
                                            type="company"
                                        />
                                    )
                                }>
                                <MoreIconNoFill
                                    className={classnames([
                                        classes.moreIcon,
                                        {
                                            [classes.moreIconOpened]:
                                                isMenuOpened,
                                        },
                                    ])}
                                />
                            </Menu>
                        )}
                        <AddToProjectMenu
                            companies={selectedOrders}
                            companiesNames={selectedOrdersNames}
                            buttonType="label"
                            disabled={type === "technologies"}
                            type="company"
                        />
                        <DownloadButton
                            user={user}
                            isIndustry={false}
                            isTechnology={false}
                            isProject={false}
                            selected={selectedOrders}
                            dispatch={dispatch}
                            type={type}
                            setShowAreYouSureModal={setShowAreYouSureModal}
                            setShowDownloadsExceededModal={
                                setShowDownloadsExceededModal
                            }
                            getDownloadProps={getDownloadProps}
                            className={classes.downloadButtonComponent}
                            sortBy={sortValue && sortValue.slug}
                            sortDirection={sortDirection}
                            page="orders"
                            name={
                                selectedOrders.length > 1
                                    ? "valuer_company_reports"
                                    : `valuer_${selectedOrdersNames}_report`
                            }
                        />
                    </div>
                </div>
            )}
            {loading ? (
                <div className={classes.loaderWrapper}>
                    <ContentLoader />
                </div>
            ) : (
                <div>
                    {orders && orders.length ? (
                        <TableContainer className={classes.tableContainer}>
                            <Table
                                className={classes.table}
                                aria-label="simple table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell
                                            align="left"
                                            className={classes.cell}>
                                            <Typography
                                                variant="body"
                                                color="black"
                                                component="div"
                                                className={
                                                    classes.companiesLabel
                                                }>
                                                {t(`orders.${type}`)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.cell}>
                                            <Typography
                                                variant="body"
                                                color="black"
                                                component="div">
                                                {t("orders.status")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.cell}>
                                            <Typography
                                                variant="body"
                                                color="black"
                                                component="div">
                                                {t("orders.ordered")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={
                                                classes.cell
                                            }></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders.map((order, i) => (
                                        <OrderTableRow
                                            order={order}
                                            t={t}
                                            classes={classes}
                                            i={i}
                                            dispatch={dispatch}
                                            user={user}
                                            onCheckboxChange={
                                                handleCheckboxChange
                                            }
                                            selected={selectedOrders.includes(
                                                order.oid,
                                            )}
                                            isMultiSelected={isMultiSelected}
                                            isMultiAvailable={isMultiAvailable}
                                            key={`${i}-order-table-row`}
                                            type={type}
                                            setShowAreYouSureModal={
                                                setShowAreYouSureModal
                                            }
                                            setShowDownloadsExceededModal={
                                                setShowDownloadsExceededModal
                                            }
                                            getDownloadProps={getDownloadProps}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <div className={classes.noResults}>
                            <img
                                src={NoOrders}
                                alt={t(
                                    type == "companies"
                                        ? "orders.noCompaniesEnrichments"
                                        : "orders.noTechnologiesDeepDives",
                                )}
                            />
                            <Typography
                                variant="subtitle1"
                                color="black"
                                component="div"
                                align="center"
                                className={classes.noResultsTitle}>
                                {t(
                                    type == "companies"
                                        ? "orders.noCompaniesEnrichments"
                                        : "orders.noTechnologiesDeepDives",
                                )}
                            </Typography>
                        </div>
                    )}
                    <AreYouSureModal
                        dialogOpened={showAreYouSureModal}
                        onChange={() => setShowAreYouSureModal(false)}
                        title={t("download.areYouSureTitle")}
                        subtitle={
                            <>
                                {t("download.subtitleFreeTrial1")}
                                <Typography
                                    component="div"
                                    className={classes.actionText}
                                    variant="body"
                                    color="secondaryDark">
                                    {t("download.subtitleFreeTrial2")}
                                </Typography>
                            </>
                        }
                        actionButtonText={t("download.iWantToDownload")}
                        cancelButtonText={t("download.letsTryLater")}
                        onActionClick={download}
                    />
                    <AreYouSureModal
                        dialogOpened={showDownloadsExceededModal}
                        onChange={() => setShowDownloadsExceededModal(false)}
                        title={t("download.downloadsExceededTitle")}
                        subtitle={t("download.downloadsExceededSubtitle")}
                        actionButtonText={t("download.upgradePlan")}
                        cancelButtonText={t("download.upgradeLater")}
                        onActionClick={() =>
                            (window.location.href = getPricingPage(!!user))
                        }
                    />
                </div>
            )}
        </>
    );
};

export default TabContent;
