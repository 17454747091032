import React from "react";
// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../../../components/Button/Button";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import AnalystResearchPaper from "../../../../assets/images/NoOrders.png";
import OrderReportNotCredit from "../../../../assets/icons/OrderReportNotCredit.png";

const NotEnoughCreditsModal = ({
    dialogOpened,
    onChange,
    onTopUp,
    credits,
    orderReport,
}) => {
    const { t } = useTranslation();
    const classes = useStyles({ orderReport });

    const topUp = () => {
        onTopUp();
    };

    return (
        <Dialog
            onClose={onChange}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
            }}
            open={dialogOpened}>
            <IconButton className={classes.closeButton} onClick={onChange}>
                <img
                    src={CloseIcon}
                    alt={t("scope.step2")}
                    className={classes.stepIcon}
                />
            </IconButton>
            <MuiDialogContent className={classes.dialogContent}>
                {orderReport ? (
                    <>
                        <img src={OrderReportNotCredit} />
                        <Typography
                            component="div"
                            variant="h6"
                            className={classes.title}>
                            {t("companyProfile.dontHaveEnoughCreditsTitle")}
                        </Typography>
                        <Typography
                            component="div"
                            variant="bodySmall"
                            align="center">
                            {t("technologyProfile.technologyReportCost")}
                        </Typography>
                        <Typography
                            component="div"
                            variant="bodySmall"
                            align="center">
                            {t("companyProfile.youHaveCredits", {
                                count: credits,
                            })}
                        </Typography>
                    </>
                ) : (
                    <>
                        <img src={AnalystResearchPaper} />
                        <Typography
                            component="div"
                            variant="h6"
                            className={classes.title}>
                            {t("companyProfile.dontHaveEnoughCreditsTitle")}
                        </Typography>
                        <Typography
                            component="div"
                            variant="bodySmall"
                            align="center">
                            {t("companyProfile.thisWillCost", { count: 1 })}
                        </Typography>
                        <Typography
                            component="div"
                            variant="bodySmall"
                            align="center">
                            {t("companyProfile.youHaveCredits", {
                                count: credits,
                            })}
                        </Typography>
                        <Typography
                            component="div"
                            variant="bodySmall"
                            align="center"
                            className={classes.analystsCanPrepare}>
                            {t("companyProfile.analystsCanPrepare")}
                        </Typography>
                    </>
                )}
                <div className={classes.topUpMargin}>
                    <Typography
                        component="span"
                        variant="bodySmall"
                        inline
                        align="center">
                        {t("companyProfile.youCan")}
                    </Typography>
                    <Typography
                        component="span"
                        variant="bodySmall"
                        onClick={topUp}
                        className={classes.topUpLink}
                        inline
                        align="center">
                        {t("companyProfile.topUp")}
                    </Typography>
                    <Typography
                        component="span"
                        variant="bodySmall"
                        inline
                        align="center">
                        {t("companyProfile.toComplete")}
                    </Typography>
                </div>
            </MuiDialogContent>
            <div className={classes.buttons}>
                <Button onClick={onChange} variant="quaternary">
                    {t("companyProfile.cancel")}
                </Button>
                <Button
                    className={classes.orderButton}
                    onClick={topUp}
                    variant="primary">
                    {t("companyProfile.topUpCredits")}
                </Button>
            </div>
        </Dialog>
    );
};
export default NotEnoughCreditsModal;
