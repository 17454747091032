import React, { useEffect } from "react";
// Hooks
import { useTranslation } from "react-i18next";
import useStyles from "./style.js";
import { useRouterHook } from "../../../common/useRouterHook";
import { useIntercom } from "react-use-intercom";
// UI components
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
// Assets
import { ReactComponent as TextualLogo } from "../../../assets/icons/TextualLogo.svg";
import PageNotFound from "../../../assets/icons/PageNotFound.png";

const NotFound = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const { trackEvent } = useIntercom();

    useEffect(() => {
        if (
            process.env.REACT_APP_ENV === "prod" ||
            process.env.REACT_APP_ENV === "staging"
        ) {
            //Intercom event tracking
            trackEvent("Error message seen", {
                description: "404 page not found",
            });
        }
    }, []);
    return (
        <div className={classes.root}>
            <div className={classes.logoSection}>
                <TextualLogo className={classes.logo} />
            </div>
            <div className={classes.content}>
                <img src={PageNotFound} className={classes.img} />
                <Typography align="center" variant="h6">
                    {t("notFound.title")}
                </Typography>
                <Button variant="primary" onClick={() => openPage("/results")}>
                    {t("notFound.goToHomepage")}
                </Button>
            </div>
        </div>
    );
};
export default NotFound;
