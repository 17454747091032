import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.pusher = Pusher;

export class SocketService {
    constructor(csrfToken) {
        this.csrfToken = csrfToken;
    }
    getSocketConnection() {
        const options = {
            broadcaster: "pusher",
            key: process.env.REACT_APP_PUSHER_KEY,
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            // authEndpoint: process.env.REACT_APP_BROADCASTING_URL,
            // encrypted: process.env.REACT_APP_WS_FORCE_TLS,
            // auth: {
            //     // withCredentials: true,
            //     headers: {
            //         "X-CSRF-TOKEN": this.csrfToken,
            //     },
            // },
            wsHost: process.env.REACT_APP_WS_HOST,
            wsPort: process.env.REACT_APP_WS_PORT,
            wssPort: process.env.REACT_APP_WS_PORT,
            forceTLS: process.env.REACT_APP_WS_FORCE_TLS,
            enableStats: false,
            enabledTransports: ["ws", "wss"],
        };

        if (!window.echo) {
            window.echo = new Echo(options);
        }
    }

    closeConnection() {
        if (window.echo) window.echo.disconnect();
    }
}
