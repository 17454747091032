import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        addToProjectButton: {
            background: palette.primary.backgroundLight,
            border: `1px solid ${palette.primary.dark}`,
            color: palette.primary.dark,
            whiteSpace: "nowrap",
            fontFamily: "GT Flexa Mono, sans-serif",
            borderRadius: "5000px",
            padding: `${spacing(1)}px ${spacing(3)}px`,
            maxHeight: "40px",
            "&:hover": {
                background: palette.primary.dark,
                color: palette.common.white,
            },
        },
        addToProjectCustomButton: {
            textTransform: "unset !important",
            padding: `0 !important`,
            "&:hover": {
                background: "transparent !important",
            },
            "&:active": {
                background: "transparent !important",
            },
            minWidth: "auto",
        },
        addToProject: {
            background: "transparent",
            color: palette.text.main,
            alignItems: "center",
            position: "relative",
            marginLeft: spacing(3),
            paddingLeft: spacing(3),
            borderLeft: `1px solid ${palette.text.backgroundMain}`,
        },
        checkboxTick: {
            position: "absolute",
            top: 8,
            left: 29,
        },
        checkboxRectangle: {
            marginRight: spacing(1),
            height: spacing(3),
        },
        mono: {
            fontFamily: "GT Flexa Mono, sans-serif",
            display: "flex",
            alignItems: "center",
        },
        saveSearch: {
            fontWeight: "normal",
        },
        projectName: {
            paddingLeft: spacing(2),
            "& span": {
                color: palette.primary.dark,
                marginRight: spacing(1),
            },
        },
        arrowImg: {
            marginLeft: spacing(1),
        },
        rotatedIcon: {
            transform: "rotate(180deg)",
        },
        successButton: {
            whiteSpace: "nowrap",
            background: palette.success.backgroundLight,
            borderColor: palette.success.backgroundLight,
            marginRight: spacing(2),
            color: palette.common.black,
        },
        successButtonDisabled: {
            whiteSpace: "nowrap",
            background: `${palette.success.backgroundLight} !important`,
            borderColor: `${palette.success.backgroundLight} !important`,
            marginRight: spacing(2),
            color: `${palette.text.lighter} !important`,
            cursor: "default",
            "&:active": {
                background: palette.success.backgroundLight,
                borderColor: palette.success.backgroundLight,
            },
        },
        addedTick: {
            marginRight: spacing(1),
        },
        removeButton: {
            background: "transparent",
            color: palette.text.main,
            alignItems: "center",
            position: "relative",
            marginLeft: spacing(3),
            paddingLeft: spacing(3),
            borderLeft: `1px solid ${palette.text.backgroundMain}`,
            display: "flex",
        },
        removeImg: {
            marginRight: spacing(1),
        },
        actionIcon: {
            background: palette.primary.backgroundLight,
            borderRadius: spacing(1),
            cursor: "pointer",
            // marginLeft: spacing(1),
            "& path": {
                stroke: palette.primary.dark,
            },
            "&:hover": {
                background: palette.primary.backgroundDark,
            },
        },
        arrowDown2: {
            marginLeft: spacing(1),
        },
        mergeButton: {
            marginLeft: spacing(2),
            padding: `11px ${spacing(3)}px`,
            paddingRight: spacing(3),
            borderRadius: spacing(8),
            cursor: "default",
            background: "transparent",
            border: "none",
            color: palette.text.dark,
            "& svg": {
                verticalAlign: "middle",
            },
            "&$active": {
                background: palette.success.light,
            },
            "&:focus": {
                border: "none",
            },
            "&:disabled": {
                background: "transparent",
                border: "none",
                color: palette.text.lighter,
                "& path": {
                    stroke: palette.grey[500],
                },
            },

            [breakpoint.down("md")]: {
                display: "none",
            },
        },
        clickable: {
            "&:hover": {
                cursor: "pointer",
                background: palette.success.backgroundDark,
                "&:disabled": {
                    background: "transparent",
                    border: "none",
                    cursor: "auto",
                },
            },
        },
        greyOutSvg: {
            color: palette.text.lighter,
            "& path": {
                stroke: palette.grey[500],
            },
        },
        hoverIcon: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            "& span": {
                fontSize: spacing(2),
                lineHeight: `${spacing(3)}px`,
                fontWeight: 400,
            },
            "&:hover": {
                fontWeight: 700,
                "& img": {
                    transform: "scale(1.1)",
                },
                "& span": {
                    fontWeight: 700,
                },
            },
        },
        projectsArrow: {
            marginRight: spacing(1),
        },
        projectsArrowClosed: {
            transform: "rotate(180deg)",
        },
        shareIcon: {
            marginRight: spacing(1),
        },
        actionIconContent: {
            height: 32,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },
        actionText: {
            textAlign: "center",
            lineHeight: "12px",
            minHeight: 24,
            marginTop: 4,
        },
        actionTextProject: {
            textAlign: "center",
            lineHeight: "12px",
            minHeight: 24,
            marginTop: 4,
            paddingLeft: "7px",
        },
        saveIconWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            "&:hover": {
                "& $actionIcon": {
                    width: 32,
                    height: 32,
                },
                "& $actionText": {
                    fontWeight: "bold",
                },
            },
        },
        saveItemsButton: {
            whiteSpace: "nowrap",
            marginRight: spacing(2),
            cursor: "pointer",
            display: "flex",
            // color: "#666666",
            gap: 15,
            padding: `${spacing(1)}px ${spacing(3)}px`,
            alignItems: "center",
            borderRadius: "100px",
            background: "transparent",
            border: `1px solid #666666`,

            "& svg": {
                // filter: "grayscale(100%)",
                color: "#666666",

                // opacity: 0.3,
            },
            "&:hover": {
                background:"#FD750D",
                color: "#FFF",
                border: `1px solid transparent`,
            },
            "&:hover span": {
                color: "#FFF"
                
            },
            "&:hover svg": {
                color: "#FFF"
                
            },
            "& span": {
                color: "#666666",
            },
        },
        saveItemsButtonDisabled: {
            zIndex: 4,
            pointerEvents: "none",
            border: `1px solid ${palette.text.lightest}`,
            background: "#DBDBDA",
            "& svg": {
                color: "#fff",
                // filter: "grayscale(100%)",
                // opacity: 0.3,
            },
            "& span": {
                // filter: "grayscale(100%)",
                // opacity: 0.3,
                color: "#fff",

            },
            "&:hover": {
                background: "#DBDBDA",
                // borderColor: palette.text.lightest,
            },
        },
        saveItemsText: {
            color: palette.common.black,
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        saveItemsTextDisabled: {
            color: palette.text.lighter,
        },
        saveBtn: {
            minWidth: "30px",
        },
    }),
);
