import { call } from "redux-saga/effects";
import axios from "axios";
import { INVITE_COWORKERS_URL } from "../../../constants/endpoints";

function* inviteCoworkers(emails) {
    const response = yield call(axios, {
        method: "POST",
        url: `${INVITE_COWORKERS_URL} + ${emails}`,
        withCredentials: true,
    });
    return response.data;
}
export { inviteCoworkers };
