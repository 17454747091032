import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";
export const INITIAL_STATE = {
    freeTrialState: null,
    freeTrialStatus: REQUEST_UNDEFINED,
    updateFreeTrialStatus: REQUEST_UNDEFINED,
    emailChange: false,
    emailChangeStatus: REQUEST_UNDEFINED,
    emailVerificationStatus: REQUEST_UNDEFINED,
    emailValidationState: null,
    emailValidationStatus: REQUEST_UNDEFINED,
};

export const REDUCER_PREFIX = "freeTrial";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        startFreeTrialPending: state => {
            state.freeTrialStatus = REQUEST_PENDING;
        },
        startFreeTrialSuccess: state => {
            state.freeTrialStatus = REQUEST_SUCCESS;
        },
        startFreeTrialFailure: state => {
            state.freeTrialStatus = REQUEST_FAILURE;
        },
        setStartFreeTrialResponse: (state, { payload }) => {
            state.freeTrialState = payload;
        },
        updateFreeTrialPending: state => {
            state.updateFreeTrialStatus = REQUEST_PENDING;
        },
        updateFreeTrialSuccess: state => {
            state.updateFreeTrialStatus = REQUEST_SUCCESS;
        },
        updateFreeTrialFailure: state => {
            state.updateFreeTrialStatus = REQUEST_FAILURE;
        },
        setEmailChange: (state, { payload }) => {
            state.emailChange = payload;
        },
        sendEmailVerificationPending: state => {
            state.emailVerificationStatus = REQUEST_PENDING;
        },
        sendEmailVerificationSuccess: state => {
            state.emailVerificationStatus = REQUEST_SUCCESS;
        },
        sendEmailVerificationFailure: state => {
            state.emailVerificationStatus = REQUEST_FAILURE;
        },
        emailChangePending: state => {
            state.emailChangeStatus = REQUEST_PENDING;
        },
        emailChangeSuccess: state => {
            state.emailChangeStatus = REQUEST_SUCCESS;
        },
        emailChangeFailure: state => {
            state.emailChangeStatus = REQUEST_FAILURE;
        },
        sendEmailValidationPending: state => {
            state.emailValidationStatus = REQUEST_PENDING;
        },
        sendEmailValidationSuccess: state => {
            state.emailValidationStatus = REQUEST_SUCCESS;
        },
        sendEmailValidationFailure: state => {
            state.emailValidationStatus = REQUEST_FAILURE;
        },
        setEmailValidationState: (state, { payload }) => {
            state.emailValidationState = payload;
        },
        setEmailStateUndefined: state => {
            state.emailValidationStatus = REQUEST_UNDEFINED;
        },
    },
});
export { reducer, actions };
