import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import { InstantSearch, SearchBox, connectHits } from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
// UI components
import Typography from "../../components/Typography/Typography";
import Box from "@material-ui/core/Box";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useAppEventTracking } from "../../common/useAppEventTracking";
// Redux
import { submitSearchRequest } from "../../store/search/requests";
import { getActiveViewSelector } from "../../store/results/selectors";
// Assets
import LoaderJSON from "../../assets/lottie-jsons/loader_orange.json";
import LookalikeSearchIllustration from "../../assets/images/LookalikeSearchIllustration.png";

const searchClient = instantMeiliSearch(
    "https://ms.valuer.ai",
    "d769b4eee66b7f70d55e2ee9192b7528c0a23c2648a8983cf8bf25e0488ecd97",
);

const Hits = ({
    hits,
    classes,
    t,
    onClick,
    selectedResult,
    onHitsChange,
    searchBoxValue,
}) => {
    const [filteredHits, setFilteredHits] = useState([]);
    const { openPage } = useRouterHook();

    useEffect(() => {
        if (
            selectedResult &&
            hits.filter(e => e.name === selectedResult.name).length === 0
        ) {
            onHitsChange();
        }
        setFilteredHits(
            hits.filter(hit =>
                hit.name.toLowerCase().includes(searchBoxValue.toLowerCase()),
            ),
        );
    }, [hits]);

    const handleAddMoreDetails = () => {
        let timeout = setTimeout(() => {
            openPage("/add-more-details");
        }, 200);
        return () => {
            clearTimeout(timeout);
        };
    };

    return (
        <ol>
            <Typography
                variant="bodyXs"
                color="primaryDark"
                className={classes.showingResults}>
                {t("searchLookAlike.showingResults", {
                    count: filteredHits.length,
                })}
            </Typography>
            {filteredHits.map(hit => (
                <div key={`${hit._id}-result`}>
                    <Hit
                        hit={hit}
                        onClick={onClick}
                        selectedResult={selectedResult}
                    />
                </div>
            ))}
            {searchBoxValue.length > 0 && (
                <Typography
                    variant="body2"
                    className={classnames([
                        classes.mono,
                        classes.queryHint,
                        classes.addMoreDetailsCta,
                    ])}>
                    {t("searchLookAlike.cantFind")}{" "}
                    <Typography
                        variant="body2"
                        display="inline"
                        textDecoration="underline"
                        className={classnames([
                            classes.mono,
                            classes.linkPrimary,
                            classes.addMoreDetailsCta,
                        ])}
                        onClick={handleAddMoreDetails}>
                        {t("searchLookAlike.addMoreDetails")}
                    </Typography>
                </Typography>
            )}
        </ol>
    );
};

const CustomHits = connectHits(Hits);

const Hit = ({ hit, onClick, selectedResult }) => {
    const classes = useStyles();
    const [isSelected, setIsSelected] = useState(false);

    const handleClick = () => {
        onClick(hit);
    };

    useEffect(() => {
        selectedResult
            ? setIsSelected(selectedResult._id === hit._id)
            : setIsSelected(null);
    }, [selectedResult, hit]);

    return (
        <div
            className={classnames(classes.hit, {
                [classes.hitSelected]: isSelected,
            })}
            onClick={handleClick}
            test-id={`search-result-${hit.website}`}>
            <Typography
                size="body"
                color="title"
                className={classes.hitTitle}
                component="div">
                {hit.name}
            </Typography>
            {hit.website && (
                <Typography
                    size="bodySmall"
                    color="title"
                    className={classes.hitDescription}
                    component="div">
                    {hit.website}
                </Typography>
            )}
            {/* <Typography size="bodySmall" className={classes.hitDescription} component="div">{hit.description}</Typography> */}
        </div>
    );
};

const SearchLookAlike = ({ searchedCompanyName = null }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [searchBoxValue, setSearchBoxValue] = useState("");
    const dispatch = useDispatch();
    // const [hitsScrollPosition, setHitsScrollPosition] = useState(0);
    // const [animateOut, setAnimateOut] = useState(false);
    // const [animateInBack, setAnimateInBack] = useState(false);
    // const [animateOutBack, setAnimateOutBack] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const { state: pathname } = useLocation();
    const history = useHistory();
    const { mixpanelTrackEvent } = useAppEventTracking();
    const viewType = useSelector(getActiveViewSelector);
    useEffect(() => {
        selectedResult && dispatchSubmitSearch(selectedResult);
    }, [selectedResult]);

    //see look a like from CompanyProfile
    useEffect(() => {
        let data = localStorage.getItem("company-look-a-likes");
        data = data && JSON.parse(data);
        if (data) {
            dispatchSubmitSearch(data, true);
        }
        document.getElementsByClassName("ais-SearchBox-input")[0].focus();
    }, []);
    //
    const dispatchSubmitSearch = result => {
        localStorage.setItem(
            "lookalikeDescriptionValue",
            result.description ? result.description : result.name,
        );
        localStorage.setItem("lookalikeNameValue", result.name);
        localStorage.setItem(
            "lookalikeWebsiteValue",
            result.website ? result.website : "",
        );
        dispatch(
            submitSearchRequest({
                description: result.description
                    ? result.description
                    : result.name,
                searchSectors: null,
                scope: "Find look-alike",
                searchType: 2,
                lookAlikeName: result.name,
                perPage: viewType === "card" ? 10 : 50,
            }),
        );
        // setAnimateInBack(false);
        // setAnimateOut(true);
        let timeout = setTimeout(() => {
            const path = "/search-results-loading";
            history.replace(path, { from: pathname, someOtherProp: "" });
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    };

    const handleResultClick = selectedHitResult => {
        mixpanelTrackEvent("Company search", {
            Type: "Look-alike",
            "Company name": selectedHitResult.name,
        });
        !selectedResult || selectedResult._id !== selectedHitResult._id
            ? setSelectedResult(selectedHitResult)
            : setSelectedResult(null);
    };

    const handleHitsChange = () => {
        setSelectedResult(null);
    };

    const handleStopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div>
            <div>
                <div className={classes.lookAlikeRoot}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-between"
                        className={classnames([
                            classes.wrapper,
                            classes.lookAlikesWrapper,
                        ])}>
                        <img
                            alt={t("searchLookAlike.title")}
                            src={LookalikeSearchIllustration}
                            className={classnames([
                                classes.lookalikeSearchIllustration,
                                {
                                    [classes.lookalikeSearchIllustrationShrinked]:
                                        searchBoxValue.length,
                                },
                            ])}
                        />
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={classes.contentWrapper}>
                            <Typography
                                variant="subtitle1"
                                align="center"
                                className={classnames([
                                    classes.lookalikeTitle,
                                    classes.mono,
                                ])}>
                                {t("searchLookAlike.title")}
                            </Typography>
                            <div
                                className={classnames([
                                    classes.lookAlikeWrapper,
                                    {
                                        [classes.lookAlikeWrapperOpened]:
                                            searchBoxValue.length > 0,
                                    },
                                ])}>
                                <InstantSearch
                                    indexName="companies"
                                    searchClient={searchClient}>
                                    <SearchBox
                                        translations={{
                                            placeholder: t(
                                                "searchLookAlike.placeholder",
                                            ),
                                        }}
                                        className={classes.searchBox}
                                        onChange={event =>
                                            setSearchBoxValue(
                                                event.currentTarget.value,
                                            )
                                        }
                                        showLoadingIndicator
                                        defaultRefinement={searchedCompanyName}
                                        onClick={handleStopPropagation}
                                        loadingIndicator={
                                            <div
                                                className={
                                                    classes.lookAlikeLoader
                                                }>
                                                <Player
                                                    autoplay
                                                    loop
                                                    src={LoaderJSON}
                                                    style={{
                                                        height: 42,
                                                        width: 47,
                                                    }}>
                                                    <Controls
                                                        visible={false}
                                                        buttons={[
                                                            "play",
                                                            "repeat",
                                                            "frame",
                                                            "debug",
                                                        ]}
                                                    />
                                                </Player>
                                            </div>
                                        }
                                    />
                                    {searchBoxValue.length > 0 && (
                                        <CustomHits
                                            t={t}
                                            searchBoxValue={searchBoxValue}
                                            classes={classes}
                                            selectedResult={selectedResult}
                                            onHitsChange={handleHitsChange}
                                            onClick={handleResultClick}
                                        />
                                    )}
                                </InstantSearch>
                            </div>
                        </Box>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default withRouter(SearchLookAlike);
