import { call } from "redux-saga/effects";
import axios from "axios";
import { SECTORS_URL, SUBSECTORS_URL } from "../../../constants/endpoints";

function* getSectors() {
    const response = yield call(axios, {
        method: "GET",
        url: `${SECTORS_URL}`,
    });

    return response.data;
}

function* getSubsectors(sectorsSlug) {
    const response = yield call(axios, {
        method: "GET",
        url: `${SUBSECTORS_URL}`,
        params: {
            sectors: Array.isArray(sectorsSlug)
                ? sectorsSlug.join(",")
                : sectorsSlug,
        },
    });
    return response.data;
}

export { getSectors, getSubsectors };
