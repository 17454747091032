export const prompts = [
    {
        name: "Unique Selling Points (UPS)",
        content:
            "Hello PUMA, Please provide a executive summary of the Unique Selling Points (USPs) from companies in the results that best represent both my scoping question, and the 6 most prevalent industries. Include how these USPs offer a competitive advantage, and any notable trends in USP development, for instance in response to market changes or technological advancements.",
    },
    {
        name: "Problems Solved",
        content:
            "Hello PUMA, I’m looking for insights into the main challenges faced by companies from the results, that match my question. Where in the market are they currently situated, and what are their problem-solving strategies? Please provide a brief overview of common issues for these challenge types, and which innovative solutions are being adopted, which show significant shifts in solution approaches to these problem types.",
    },
    {
        name: "Products / solutions / services",
        content:
            "Hello PUMA, I’m interested in understanding the range of products, solutions and services offered by the best matching companies from the results, within their various industries. Please provide a brief overview of these offerings, focusing on their unique features and market relevance.",
    },
    {
        name: "Technological Landscape",
        content:
            "Hello PUMA, I’m interested in the technologies used in common solutions amongst the companies in the results that best match my question. Please provide a summary of key technologies, emerging tech trends, and examples of unconventional technological applications with significant impact.",
    },
    {
        name: "Technological Disruption",
        content:
            "Hello PUMA, I’m looking for insights into potential technological disruptions in various industries, amongst the companies found in the results, that best fit my question. Please identify emerging technologies amongst these companies and their technological solutions, that could transform their markets (or new markets). Which industries are most likely to be impacted, including examples of early adopters or significant impacts?",
    },
    {
        name: "Most Prevalent Business Models",
        content:
            "Hello PUMA, please provide a summary of the most prevalent business models from companies in the results, that match my question well. :ook across different industries, and see if you can highlight their key characteristics and the reasons for their widespread adoption.",
    },
    {
        name: "Innovative Business Models",
        content:
            "Hello PUMA, I’m curious about the most promising innovative business models emerging in the market, with reference to the companies in my results. Please provide a brief overview of these models and what makes them potentially impactful, whether it is within their industry, market, technology, solutions, etc.",
    },
    {
        name: "Scalable business models",
        content:
            "Hello PUMA, please provide a detailed summary of the most scalable business models amongst the companies found in this list of results, if you look at their current market. Focus on their adaptability, potential for growth across different markets or regions, and how they leverage technology, innovation, or market trends for scalability.",
    },
    {
        name: "Main Value Propositions ",
        content:
            "Hello PUMA, I’d like a detailed summary of the main value propositions offered by the companies in the list of results, that match my question most relevantly. Please focus on what makes these value propositions unique and how they are communicated and perceived in the market, including any innovative or standout features.",
    },
    {
        name: "Weak Signals",
        content:
            "Hello PUMA, I’m interested in identifying weak signals within the companies found in my list of results that match my question well. These weak signals may suggest or represent emerging trends or upcoming shifts in consumer preferences and industry dynamics, as seen within their market. Please provide a detailed analysis of how these companies from the results list represent these weak signals, their origins, and potential future impact on business strategies.",
    },
    {
        name: "Main Competitors / Players",
        content:
            "Hello PUMA! Based on my my list of results, could you provide an in-depth overview of the key players / main competitors within the 5 most represented industries from my results? Focus on their market positions, strategic strengths, unique offerings, and any notable business strategies they are employing to maintain or enhance their market share.",
    },
    {
        name: "Market Trends",
        content:
            "Hello PUMA, I need an overview and subsequent detailed analysis of the main market trends, across the industries that are most represented by companies in my results. Include considerations such as shifts in consumer behaviour, technological advancements, and economic factors. Please provide insights into how these trends may be shaping industry landscapes and business strategies. Take reference to companies from the results, that represent those market trends.",
    },
    {
        name: "Main industries",
        content:
            "Hello PUMA, I’m interested in a comprehensive overview of the industries that are most represented within my results, based on which are currently dominant and which are currently showing significant growth. Please include examples of companies from the list as use cases to show key characteristics, effects of market dynamics, main challenges, and opportunities within these industries.",
    },
    {
        name: "Investment potential",
        content:
            "Hello PUMA, I’m looking for insights on market segments within my list of results, which could offer the most lucrative investment opportunities. Using companies or industries from the results as case studies, please provide a brief overview of these high-growth potential segments, the key factors driving their profitability, and any notable trends that suggest future investment potential.",
    },
    {
        name: "Sustainability and Corporate Social Responsibility",
        content:
            "Hello PUMA, I’d like to know about sustainability and CSR initiatives in major industries within my list of results. Please summarise how companies from the results are integrating these practices into their business models, their impact on performance and public perception, and any innovative strategies that stand out.",
    },
    {
        name: "Regulatory Landscape",
        content:
            "Hello PUMA, please provide an overview of the regulatory requirements that should be considered most impactful on the industries mostly representative in my list of results? Using companies from the results as case studies, focus on the effects of these regulations on business strategies and operations, highlighting key areas like data protection and environmental compliance.",
    },
    {
        name: "Supply Chain Dynamics",
        content:
            "Hello PUMA, I need information on current trends in supply chain management, based on companies, technologies or industries found in my results. Using parts of my results as use cases, please highlight challenges, innovative practices, and solutions improving resilience and efficiency, especially in response to recent global events.",
    },
];
