import useStyles from "../ResultsTabs/styles";
import cx from "classnames";

const Tab = ({ id, name, isActiveTab, onSwitchTabs }) => {
    const classes = useStyles();

    return (
        <div
            className={cx([classes.tab, isActiveTab ? classes.activeTab : ""])}
            onClick={e => onSwitchTabs(e, id)}>
            {name}
        </div>
    );
};

export default Tab;
