import React from "react";
import classnames from "classnames";
import { useStyles } from "./style.js";
import ScopeSelect from "./NewSearch/ScopeSelect.js";

const ScopeSelector = () => {
    const classes = useStyles();

    return (
        <div className={classnames([classes.root, classes.searchTypeRoot])}>
            <div className={classes.mainSearchBoxWrapper}>
                <ScopeSelect />
            </div>
        </div>
    );
};

export default ScopeSelector;
