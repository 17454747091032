import { useEffect } from 'react';
import { useRemark } from 'react-remark';

const Markdown = ({markdown_text}) => {
  const [reactContent, setMarkdownSource] = useRemark();

  useEffect(() => {
    setMarkdownSource(markdown_text);
  }, [setMarkdownSource, markdown_text]);

  return reactContent;
};

export default Markdown;
