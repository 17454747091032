import { call } from "redux-saga/effects";
import axios from "axios";
import { getGenerateClusterData } from "../../../constants/endpoints";

function* generateClusterData(data) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getGenerateClusterData(
            data.resourceType,
            data.resourceId,
            data.group,
        )}${data.filterUrlString}`,
        data,
        withCredentials: true,
    });
    return response.data;
}

export { generateClusterData };
