import React from "react";

export const Ruler = ({ strokeColor = "#C2C9D7" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
            d="M12.6174 14.1523L14.1381 15.673"
            stroke={strokeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.1514 11.6172L16.672 13.1378"
            stroke={strokeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.6865 9.08203L19.2072 10.6027"
            stroke={strokeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.0823 16.6875L11.6029 18.2081"
            stroke={strokeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.2922 7.91465L8.91392 13.2929C7.42876 11.8077 7.42876 9.39981 8.91392 7.91465C10.3991 6.42949 12.807 6.42949 14.2922 7.91465Z"
            stroke={strokeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.22466 15.9828L5.26626 16.9412C4.91849 17.2921 4.91849 17.8578 5.26626 18.2087L7.79832 20.7438C8.14915 21.0909 8.71402 21.0909 9.06485 20.7438L21.7421 8.06951C22.0892 7.71868 22.0892 7.15381 21.7421 6.80298L19.2031 4.26793C18.8521 3.92015 18.2865 3.92015 17.9355 4.26793L16.9771 5.22633C14.0068 2.25711 9.19188 2.25801 6.22266 5.22833C3.25345 8.19865 3.25434 13.0136 6.22466 15.9828Z"
            stroke={strokeColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
