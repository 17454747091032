import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, shape, typography, breakpoint }) =>
        createStyles({
            root: {
                flex: "33%",
                maxWidth: "50%",
                position: "relative",
                color: "unset",
                textDecoration: "unset",
                border: "0.817814px solid rgba(0, 0, 0, 0.05)",
                borderRadius: 20,
                minHeight: 308,
                overflow: "hidden",
                //filter: "drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.03)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0215656)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0178832)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.015)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0121168)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00843437))",
                boxShadow: "0px 11px 24px -22px rgba(0,0,0,0.75)",
                [breakpoint.down("xxl")]: {
                    flex: "calc(50% - 8px)",
                    maxWidth: "calc(50% - 8px)",
                },
                [breakpoint.down("xl")]: {
                    flex: ({ user }) =>
                        user?.csp ? "calc(100% - 8px)" : "calc(50% - 8px)",
                    maxWidth: "100%",
                },
                [breakpoint.down("md")]: {
                    flex: "100%",
                    maxWidth: "100%",
                },
                "& a": {
                    textDecoration: "unset",
                },
                "&:hover": {
                    cursor: "pointer",
                    textDecoration: "unset",
                },
            },
            actionIconContent: {
                height: 32,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            },
            actionIcon: {},
            actionText: {
                textAlign: "center",
                lineHeight: "12px",
                minHeight: 24,
                marginTop: 4,
            },
            card: {
                padding: `0 !important`,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
            },
            header: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: `${spacing(3)}px ${spacing(3)}px 12px ${spacing(3)}px`,
            },
            cardRow: {
                display: "flex",
                margin: `${spacing(1)}px ${spacing(3)}px`,
                justifyContent: "space-between",
                alignItems: "center",
            },
            checkboxLabel: {
                verticalAlign: "middle",
            },
            typeWrapper: {
                //padding: `${spacing(1)}px ${spacing(3)}px 0 ${spacing(3)}px`
            },
            titleName: {
                lineHeight: "26px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
            },
            industriesName: {
                lineHeight: "13px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
            },
            name: {
                flex: 1,
                maxHeight: 26,
            },
            projectSourceName: {
                maxWidth: "none",
            },
            scoreBar: {
                position: "relative",
                width: 48,
                height: 5,
                background: palette.text.backgroundMain,
                marginTop: 4,
                marginBottom: 0,
                marginLeft: 10,
                borderRadius: spacing(1),
            },
            scoreBarInside: {
                position: "absolute",
                height: 5,
                top: 0,
                borderRadius: spacing(1),
                background: palette.primary.dark,
            },
            weakScore: {
                background: palette.error.dark,
            },
            goodScore: {
                background: palette.warning.dark,
            },
            strongScore: {
                background: palette.success.dark,
            },
            weakText: {
                color: palette.error.dark,
            },
            goodText: {
                color: palette.warning.dark,
            },
            strongText: {
                color: palette.success.dark,
            },
            matchScoreBlock: {
                display: "flex",
                flexDirection: "column",
                width: 110,
                alignItems: "flex-end",
            },
            matchScore: {
                marginLeft: spacing(3),
                width: 84,
            },
            matchScoreLabel: {
                marginBottom: 2,
            },
            avatar: {
                border: "1px solid #F0F1F3",
                borderRadius: spacing(1),
                padding: 4,
                marginRight: spacing(1),
                boxShadow: "0px 2px 100px rgba(0, 0, 0, 0.1)",
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
            },
            match: {
                color: palette.success.main,
                display: "flex",
                alignItems: "center",
            },
            description: {
                color: palette.text.main,
                "-webkit-line-clamp": 6,
                "-webkit-box-orient": "vertical",
                display: "-webkit-box",
                overflowY: "hidden",
                maxHeight: 120,
                marginBottom: spacing(2),
                fontWeight: 300,
            },
            descriptionWrapper: {
                marginTop: spacing(2),
                marginBottom: -4,
                position: "relative",
                padding: `0 ${spacing(3)}px 0 ${spacing(3)}px`,
                width: "100%",
                "-webkit-line-clamp": 6,
                "-webkit-box-orient": "vertical",
                display: "-webkit-box",
                overflowY: "hidden",
                flex: 1,
                maxHeight: ({ isSuggestedCompany }) =>
                    isSuggestedCompany ? "none" : 120,
            },
            projectDescriptionWrapper: {},
            percentageMatch: {
                display: "flex",
                alignItems: "baseline",
            },
            titleWrap: {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden",
                justifyContent: "center",
                marginTop: -4,
            },
            mono: {
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            bgGradient: {
                opacity: 0.1,
                background: palette.common.black,
                height: 1,
                marginLeft: spacing(3),
                marginRight: spacing(3),
            },
            typeTag: {
                padding: "6px 16px",
                borderRadius: spacing(1),
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "GT Flexa Mono, sans-serif",
                display: "inline-block",
            },
            companyColor: {
                background: palette.success.backgroundLight,
            },
            industryColor: {
                background: palette.warning.backgroundDark,
            },
            technologyColor: {
                background: palette.primary.backgroundLight,
            },
            projectColor: {
                background: palette.primary.backgroundDark,
            },
            searchQueryColor: {
                background: palette.secondary.backgroundLight,
            },
            footer: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: `${spacing(2)}px ${spacing(3)}px 20px ${spacing(3)}px`,
                minHeight: 50,
            },
            actionIconWrapper: {
                display: "none",
            },
            actionIconWrapperRemove: {
                display: "none",
            },
            link: {
                "&:hover": {
                    textDecoration: "underline",
                },
            },
            savedInLabel: {
                maxWidth: "70%",
                textAlign: "right",
                lineHeight: "14px",
                placeSelf: "flex-end",
            },
            savedInLink: {
                lineHeight: "14px",
            },
            orderReportLink: {
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "flex-start",
            },
            enrichedIcon: {
                position: "absolute",
                bottom: -3,
                right: -5,
                width: 10,
                height: "auto",
            },
            seeReportIcon: {
                marginRight: 4,
            },
            showWhenAddToProjectIsOpen: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                background: palette.success.lighter,
            },
            descriptionWrapperShow: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                background: palette.success.lighter,
            },
            hideWhenAddToProjectIsOpen: {
                display: "none",
            },
        }),
);
