import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
    ({ palette, spacing, breakpoint, typography }) =>
        createStyles({
            mono: {
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            clusterWrapper: {
                height: "93vh",
                borderRadius: spacing(3),
                background: palette.common.white,
                margin: `0 ${spacing(4)}px 0 0`,
            },
            cluster: {
                display: "flex",
                position: "relative",
                justifyContent: "start",
                background: palette.common.white,
                borderRadius: spacing(3),
                padding: 0,
                width: "100%",
                height: "90vh",
                // marginTop: spacing(1),
                flexDirection: "column",
                "& canvas": {
                    maxWidth: "100% !important",
                    maxHeight: "100% !important",
                    top: "-35px !important",
                    left: "80px !important",
                },
                "& scene": {
                    width: "590px",
                },
                "& .main-svg": {
                    borderRadius: "20px !important",
                    background: "transparent !important",
                    position: "absolute !important",
                    top: "-85px !important",
                    left: "-60px !important",
                },
                "& .js-plotly-plot": {},
                "& .js-plotly-plot .plotly .modebar": {
                    top: "6vh !important",
                    right: "2vw !important",
                    "& .modebar-group": {
                        paddingBottom: "0 !important",
                    },
                },
                "& .js-plotly-plot .plotly .modebar-btn": {
                    paddingBottom: "5px !important",
                    [breakpoint.up("xxl")]: {
                        height: "30px !important",
                    },
                },
                "& .modebar-btn svg": {
                    [breakpoint.up("xxl")]: {
                        height: "1.5em",
                        width: "1.5em",
                    },
                },
                [breakpoint.down("xl")]: {
                    padding: spacing(2),
                },
                [breakpoint.down("sm")]: {
                    width: "calc(100% + 32px)",
                    marginLeft: -16,
                    borderRadius: 0,
                    padding: spacing(4),
                },
                [breakpoint.down("lg")]: {
                    marginLeft: -24,
                    marginRight: -24,
                    width: "calc(100% + 48px)",
                },
            },
            clusterHeader: {
                display: "flex",
                flexDirection: "row",
            },
            clusterHeaderTitle: {
                display: "inline-flex",
                lineHeight: "32px",
            },
            clusterHeaderTitleText: {
                verticalAlign: "middle",
                fontFamily: "GT Flexa",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "24px",
            },
            clusterHeaderSubtitle: {
                fontFamily: "GT Flexa",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "22px",
                color: "#A0A0A0",
                marginBottom: "4px",
                marginLeft: "30px",
            },
            infoButton: {
                backgroundColor: palette.success.backgroundDark,
                width: "19px",
                height: "19px",
                marginTop: "6px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: palette.text.dark,
                marginLeft: spacing(2),
                cursor: "pointer",
            },
            betaTag: {
                color: "#F5C343",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "32px",
                marginLeft: "10px",
            },
            clusterCloseIcon: {
                marginLeft: "auto",
                "&:hover": {
                    cursor: "pointer",
                },
            },
            exploreClusterMapWrap: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
            tooltip: {
                padding: `16px 24px !important`,
                borderRadius: "16px",
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            dropdown: {
                width: "185px",
                background: palette.common.white,
                position: "absolute",
                zIndex: 9,
                left: "30px",
                top: "25px",
                textAlign: "center",
                border: `1px solid ${palette.text.backgroundDark}`,
                borderRadius: "8px",
                lineHeight: "33px",
                "& $menu": {
                    width: "185px !important",
                },
            },
            banana: {
                width: "185px !important",
                borderRadius: spacing(1),
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                "& > ul": {
                    padding: `${spacing(1)}px 0`,
                    "& > li": {
                        fontFamily: "GT Flexa Mono, sans-serif",
                        padding: `${spacing(1)}px ${spacing(3)}px`,
                        ...typography.bodyXs,
                        minWidth: 185,
                        "&:hover": {
                            backgroundColor: `${palette.text.backgroundLight}`,
                            color: palette.error.main,
                        },
                    },
                },
            },
            dropdownButton: {
                width: "185px !important",
                textTransform: "unset !important",
                padding: `0 ${spacing(1)}`,
                "& li": {
                    width: "185px !important",
                },
            },
            dropdownArrow: {
                marginLeft: "auto",
            },
            loaderWrapper: {
                height: "100%",
            },
        }),
);
