import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, typography }) =>
    createStyles({
        textField: {
            padding: `12px 12px 12px 24px`,
            ...typography.bodyXs,
            border: `1px solid ${palette.text.lightest}`,
            background: palette.common.white,
            borderRadius: "24px",
            fontFamily: "GT Flexa, sans-serif",
            maxHeight: 40,
            "& input": {
                padding: 0,
                "&::placeholder": {
                    fontFamily: "GT Flexa, sans-serif",
                    color: palette.text.lighter,
                    opacity: 1,
                },
            },
        },
        fullWidth: {
            width: "100%",
        },
        underlined: {
            padding: `8px 12px 8px 8px`,
            fontSize: 14,
            lineHeight: "18px",
            border: "none",
            borderRadius: 0,
            borderBottom: `1px solid ${palette.text.lighter}`,
            background: palette.common.white,
            color: palette.text.main,
            fontFamily: "GT Flexa, sans-serif",
            "& input": {
                padding: 0,
                "&::placeholder": {
                    ...typography.bodyXl,
                    fontFamily: "GT Flexa, sans-serif",
                    color: palette.text.lighter,
                },
            },
        },
        inputLabel: {
            position: "relative !important",
            transform: "none !important",
            marginBottom: spacing(1),
            fontSize: "14px !important",
            lineHeight: "18px !important",
        },
        focusedInput: {
            borderColor: palette.primary.dark,
        },
        asterisk: {
            color: palette.primary.main,
        },
        errorLabel: {
            color: palette.error.main,
        },
        errorInput: {
            borderColor: palette.error.main,
        },
    }),
);
