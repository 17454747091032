import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            background: palette.grey.light,
            height: "80vh",
        },
        mainBox: {
            background: palette.success.lighter,
            display: "flex",
            flexDirection: "column",
            borderRadius: spacing(3),
            height: "100%",
            [breakpoint.up("sm")]: {
                padding: `${spacing(5)}px ${spacing(6)}px`,
                flexDirection: "row",
                justifyContent: "space-between",
                // alignItems: "center",
            },
        },
        textBox: {
            display: "flex",
            flexDirection: "column",
            marginBottom: spacing(4),
            [breakpoint.up("sm")]: {
                width: "50%",
                marginBottom: 0,
            },
        },
        titleMobile: {
            display: "block",
            fontWeight: 700,
            marginBottom: spacing(4),
            [breakpoint.up("sm")]: {
                display: "none",
            },
        },
        titleDesktop: {
            display: "none",
            [breakpoint.up("sm")]: {
                display: "block",
                fontWeight: 700,
                marginBottom: spacing(5),
            },
        },
        createNewText: {
            display: "flex",
            flexDirection: "column",
            marginBottom: spacing(3),
        },
        helpText: {
            fontWeight: 275,
        },
        createNewButton: {
            background: palette.success.dark,
            borderRadius: 100,
            border: "none",
            marginTop: spacing(3),
            [breakpoint.up("sm")]: {
                width: 250,
                marginTop: spacing(5),
            },
        },
        illustration: {
            margin: "0 auto",
            [breakpoint.up("sm")]: {
                margin: "auto",
            },
        },
    }),
);
