import colors from "../../constants/colors";
import { makeStyles, createStyles } from "@material-ui/core/styles";

function animationFuncLoader() {
    return {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    };
}

function animationIllustrationShrink() {
    return {
        "0%": {
            height: 199,
            opacity: 1,
        },
        "100%": {
            height: 0,
            opacity: 0,
        },
    };
}

function animationIllustrationGetBack() {
    return {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    };
}

function animationWeak2Text() {
    return {
        "0%": {
            width: 0,
        },
        "100%": {
            width: 20,
        },
    };
}

function animationFairText() {
    return {
        "0%": {
            width: 20,
        },
        "100%": {
            width: 120,
        },
    };
}

function animationGoodText() {
    return {
        "0%": {
            width: 120,
        },
        "100%": {
            width: 180,
        },
    };
}

function animationStrongText() {
    return {
        "0%": {
            width: 180,
        },
        "100%": {
            width: 240,
        },
    };
}

function slideIn() {
    return {
        "0%": {
            top: "100%",
        },
        "100%": {
            top: 0,
        },
    };
}

function slideOut() {
    return {
        "0%": {
            top: 0,
        },
        "100%": {
            top: "-100%",
        },
    };
}

function slideInBack() {
    return {
        "0%": {
            top: "-100%",
        },
        "100%": {
            top: 0,
        },
    };
}

function slideOutBack() {
    return {
        "0%": {
            top: 0,
        },
        "100%": {
            top: "100%",
        },
    };
}

export const useStyles = makeStyles(
    ({ palette, spacing, shape, typography, breakpoint }) =>
        createStyles({
            "@keyframes slideIn": {
                ...slideIn(),
            },
            "@keyframes slideOut": {
                ...slideOut(),
            },
            "@keyframes animationWeak2Text": {
                ...animationWeak2Text(),
            },
            "@keyframes animationFairText": {
                ...animationFairText(),
            },
            "@keyframes animationGoodText": {
                ...animationGoodText(),
            },
            "@keyframes animationStrongText": {
                ...animationStrongText(),
            },
            "@keyframes animationIllustrationShrink": {
                ...animationIllustrationShrink(),
            },
            "@keyframes animationIllustrationGetBack": {
                ...animationIllustrationGetBack(),
            },
            animateIn: {
                animation: `$slideIn 0.4s ease-in-out 1`,
                position: "absolute",
                left: 0,
                right: 0,
            },
            animateOut: {
                animation: `$slideOut 0.25s linear 1`,
                position: "absolute",
                left: 0,
                right: 0,
            },
            "@keyframes slideInBack": {
                ...slideInBack(),
            },
            "@keyframes slideOutBack": {
                ...slideOutBack(),
            },
            animateInBack: {
                animation: `$slideInBack 0.4s ease-in-out 1`,
                position: "absolute",
                left: 0,
                right: 0,
            },
            animateOutBack: {
                animation: `$slideOutBack 0.25s linear 1`,
                position: "absolute",
                left: 0,
                right: 0,
            },
            animationRoot: {
                position: "fixed",
                top: "100%",
            },
            root: {
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                fontFamily: "GT Flexa, sans-serif",
                // background: palette.primary.backgroundMedium,
                padding: `${spacing(3)}px ${spacing(4)}px`,
                [breakpoint.down("md")]: {
                    padding: `${spacing(3)}px ${spacing(4)}px`,
                },
                [breakpoint.down("sm")]: {
                    padding: `${spacing(3)}px ${spacing(2)}px`,
                },
            },
            searchTypeRoot: {
                background: palette.grey.light,
                [breakpoint.down("md")]: {
                    padding: 20,
                },
            },
            contentWrapper: {
                width: "100%",
                // paddingRight: ({isResultsPage}) => isResultsPage ? '0px' : '6%',
                // paddingLeft: ({isResultsPage}) => isResultsPage ? '0px' : '6%',
                // paddingBottom: ({isResultsPage, editSearchDirty}) => isResultsPage ? spacing(4) : '5vh',
                padding: "6%",
                paddingBottom: "5vh",
                // [breakpoint.down("md")]: {
                //     paddingRight: ({isResultsPage}) => isResultsPage ? '0px' : '0px',
                //     paddingLeft: ({isResultsPage}) => isResultsPage ? '0px' : '0px',
                // }
            },
            subsectorsContentWrapper: {
                marginTop: 50,
                // [breakpoint.down("md")]: {
                //     marginTop: 20
                // }
            },
            roleRoot: {
                background: palette.common.white,
                marginTop: "25vh",
                [breakpoint.down("md")]: {
                    padding: `${spacing(2)}px 48px`,
                },
                [breakpoint.down("sm")]: {
                    padding: `${spacing(2)}px`,
                },
            },
            industryRoot: {
                maxWidth: 688,
                width: "100%",
                alignSelf: "center",
                position: "relative",
                flex: 1,
                display: "flex",
            },
            title: {
                marginBottom: spacing(5),
            },
            outcomeTitle: {
                fontSize: "32px",
                fontWeight: 700,
                textAlign: "center",
                marginBottom: spacing(4),
            },
            mono: {
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            industryTitle1: {
                fontWeight: 500,
                marginTop: spacing(5),
                marginBottom: spacing(1),
                // [breakpoint.down("md")]: {
                //     display: "none"
                // }
            },
            industryTitle2: {
                marginBottom: spacing(3),
                lineHeight: "20px",
                width: "calc(100% - 120px)",
                [breakpoint.down("md")]: {
                    // width: "100%"
                },
            },
            lookalikeTitle: {
                marginBottom: spacing(6),
                display: "flex",
                alignItems: "center",
                fontWeight: "700",
                maxWidth: "700px",
                margin: "0 auto",
                // [breakpoint.down("md")]: {
                //     marginBottom: 12
                // }
            },
            searchPatentTitle: {
                marginTop: 161,
                marginBottom: spacing(5),
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                // [breakpoint.down("md")]: {
                //     marginBottom: 12
                // }
            },
            industryLabel: {
                marginBottom: spacing(1),
                width: "100%",
                fontFamily: "GT Flexa Mono, sans-serif",
                flex: 1,
            },
            subsectorsLabel: {
                display: "block",
                marginBottom: spacing(1),
                fontFamily: "GT Flexa Mono, sans-serif",
                flex: 1,
                position: "relative",
                // [breakpoint.down("md")]: {
                //     display: "none"
                // }
            },
            subsectorsLabelMobile: {
                display: "none",
                marginBottom: spacing(1),
                width: "100%",
                fontFamily: "GT Flexa Mono, sans-serif",
                // [breakpoint.down("md")]: {
                //     display: "block"
                // }
            },
            arrowDividerSubsectors: {
                left: -32,
                position: "absolute",
            },
            summaryTitle: {
                marginBottom: spacing(1),
            },
            arrowDownSummary: {
                marginBottom: spacing(3),
            },
            getStartedTitle: {
                marginBottom: spacing(4),
                // [breakpoint.down("md")]: {
                //     ...typography.h6,
                //     marginBottom: spacing(3)
                // }
            },
            navigation: {
                padding: `${spacing(5)}px ${spacing(10)}px`,
                position: "absolute",
                width: "100%",
                top: 0,
                left: 0,
                [breakpoint.down("lg")]: {
                    padding: 0,
                    position: "relative",
                },
            },
            roleWrapper: {
                flex: 1,
                width: "100%",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                background: palette.success.backgroundLight,
            },
            lookAlikesWrapper: {
                flex: 1,
                justifyContent: "center",
                [breakpoint.down("sm")]: {
                    paddingBottom: spacing(3),
                },
            },
            getStartedCta: {
                margin: `${spacing(6)}px 0 ${spacing(6)}px 0`,
            },
            getStartedLink: {
                color: palette.primary.dark,
                cursor: "pointer",
                "&:hover": {
                    textDecoration: "underline",
                },
            },
            submitRequestCta: {
                margin: `${spacing(4)}px 0 ${spacing(7)}px 0`,
            },
            currentRoleCta: {
                margin: `${spacing(5)}px ${spacing(4)}px`,
            },
            illustration: {
                margin: "16px 0",
                [breakpoint.down("sm")]: {
                    margin: "40px 0 32px 0",
                },
            },
            description: {
                marginBottom: spacing(4),
                // [breakpoint.down("md")]: {
                //     maxWidth: 336,
                //     marginBottom: spacing(1)
                // }
            },
            linkPrimary: {
                "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: palette.primary.main,
                },
            },
            linkSecondary: {
                color: colors.secondary.main,
                "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: palette.secondary.light,
                    backgroundColor: "transparent",
                },
            },
            backIcon: {
                fill: palette.secondary.light,
                marginRight: spacing(1),
            },
            previousIcon: {
                fill: palette.secondary.light,
                marginRight: spacing(1),
            },
            endIcon: {
                marginLeft: spacing(1),
            },
            signUp: {
                marginBottom: spacing(1),
            },
            boxes: {
                maxWidth: 768,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: spacing(5),
                [breakpoint.up("xxl")]: {
                    "& > :nth-child(1)": {
                        flex: "0 0 32%",
                    },
                    "& > :nth-child(2)": {
                        flex: "0 0 32%",
                    },
                    "& > :nth-child(3)": {
                        flex: "0 0 32%",
                    },
                    "& > :nth-child(4)": {
                        flex: "0 0 50%",
                        flexDirection: "row",
                    },
                    "& > :nth-child(5)": {
                        flex: "0 0 50%",
                        flexDirection: "row",
                    },
                },
                [breakpoint.up("xl")]: {
                    maxWidth: `1200px !important`,
                },
                [breakpoint.up("md")]: {
                    maxWidth: 768,
                },
            },
            box: {
                border: "1px solid #DBDBDA",
                margin: `12px ${spacing(1)}px`,
                background: palette.common.white,
                cursor: "pointer",
                display: "flex",
                alignItems: "flex-start",
                padding: `${spacing(4)}px ${spacing(3)}px`,
                borderRadius: 24,
                maxWidth: "calc(50% - 16px)",
                flex: "calc(50% - 16px)",
                [breakpoint.down("md")]: {
                    justifyContent: "flex-start",
                    padding: `${spacing(3)}px ${spacing(2)}px`,
                    minHeight: "auto",
                },
                [breakpoint.down("md")]: {
                    flex: "100%",
                    maxWidth: "100%",
                    // minHeight: 372,
                    margin: `12px 0`,
                },
            },
            selectedBox: {
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
            },
            enabledBox: {
                cursor: "pointer",
                "&:hover": {
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                    transform: "translateX(4px)",
                    transition: "all .3s ease",
                },
            },
            exampleStepperLink: {
                color: palette.secondary.main,
                "&:first-child": {
                    marginLeft: -90,
                    marginRight: 50,
                },
            },
            previousLink: {
                marginRight: spacing(4),
            },
            tag: {
                position: "absolute",
                top: spacing(2),
                right: spacing(2),
                [breakpoint.down("md")]: {
                    position: "relative",
                    top: "auto",
                    right: "auto",
                    marginTop: spacing(2),
                },
            },
            fixedBottom: {
                paddingBottom: spacing(5),
                background: palette.grey.light,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            industrySubtitle: {
                marginBottom: spacing(3),
                maxWidth: 928,
                [breakpoint.down("sm")]: {
                    marginBottom: 0,
                },
            },
            industrySubtitleAddMoreDetails: {
                marginBottom: spacing(3),
                maxWidth: 928,
                [breakpoint.down("sm")]: {
                    marginBottom: spacing(2),
                },
            },
            summarySubtitle: {
                maxWidth: 751,
            },
            summarySubtitle3: {
                marginBottom: spacing(4),
                marginTop: spacing(9),
                maxWidth: 751,
                fontStyle: "initial",
            },
            youAreTryingLabel: {
                maxWidth: 752,
                marginBottom: spacing(4),
                marginTop: spacing(5),
            },
            sectorNumber: {
                fontFamily: "GT Flexa Mono, sans serif",
                marginRight: 22,
                marginBottom: 39,
            },
            sectorTitleSummary: {
                marginBottom: 39,
            },
            textEntered: {
                padding: "53px 96px",
                fontFamily: "GT Flexa Mono, sans serif",
                border: `1px solid ${palette.common.black}`,
                textAlign: "left",
                borderRadius: spacing(1),
                maxWidth: 751,
                background: "rgba(255, 255, 255, 0.45)",
            },
            lookAlikeWrapper: {
                background: palette.common.white,
                "& ol": {
                    margin: 0,
                    padding: 0,
                    maxHeight: "calc(100vh - 480px)",
                    overflowY: "auto",
                    borderLeft: `1px solid ${palette.common.black}`,
                    borderRight: `1px solid ${palette.common.black}`,
                    paddingTop: 40,
                },
            },
            lookAlikeWrapperOpened: {
                position: "relative",
                paddingBottom: 48,
            },
            inputWrapper: {
                width: "100%",
                marginBottom: `${spacing(2)}px !important`,
                [breakpoint.down("sm")]: {
                    marginBottom: `${spacing(3)}px !important`,
                },
            },
            industryWrapper: {
                position: "relative",
            },
            industryWrapperSearchInput: {
                display: "flex",
                flexDirection: "row",
                background: palette.common.white,
                [breakpoint.down("md")]: {
                    position: "relative",
                },
            },
            descriptionSearchIcon: {
                position: "absolute",
                top: "-77px",
                right: "18px",
                clipPath: "polygon(0 0, 100% 0, 100% 83%, 0 83%)",
                width: 95,
                height: 95,
                [breakpoint.down("md")]: {
                    display: "none",
                },
            },
            helpButtonWrapper: {
                display: "flex",
                flexDirection: "row",
                width: "182px",
                borderTop: `1px solid ${palette.common.black}`,
                borderLeft: `1px solid ${palette.common.black}`,
                borderBottom: `1px solid ${palette.common.black}`,
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                [breakpoint.down("md")]: {
                    borderTopLeftRadius: 0,
                    position: "absolute",
                    bottom: "-40px",
                    left: "calc(50% - 86px)",
                    border: 0,
                },
                "&:hover": {
                    cursor: "pointer",
                },
            },
            disabledClick: {
                pointerEvents: "none",
            },
            helpButton: {
                whiteSpace: "nowrap",
                display: "inline-flex",
                height: "30px",
                lineHeight: "7px",
                marginTop: "13px",
                borderRight: `1px solid ${palette.common.black}`,
                fontFamily: "GT Flexa Mono, sans-serif",
                padding: "14px",
                [breakpoint.down("md")]: {
                    borderRight: 0,
                },
                [breakpoint.down("sm")]: {
                    whiteSpace: "nowrap",
                },
            },
            textarea: {
                border: `1px solid ${palette.common.black} !important`,
                borderLeft: "none !important",
                borderTopRightRadius: "10px !important",
                borderBottomRightRadius: "10px !important",
                paddingLeft: spacing(2),
                paddingRight: spacing(2),
                minHeight: "50px !important",
                paddingTop: "10px !important",
                paddingBottom: "10px !important",
                width: "100% !important",
                [breakpoint.down("md")]: {
                    // borderTopRightRadius: '0 !important',
                    borderTopLeftRadius: "10px !important",
                    borderBottomLeftRadius: "10px !important",
                    borderLeft: `1px solid ${palette.common.black} !important`,
                    // borderTop: `0 !important`,
                    paddingLeft: `${spacing(2)}px !important`,
                },
            },
            removeInputUnderline: {
                "&.MuiInput-underline:before": {
                    borderBottom: "none !important",
                },
                "&.MuiInput-underline:after": {
                    borderBottom: "none !important",
                },
            },
            textFieldInput: {
                paddingTop: "0px !important",
                paddingLeft: "0px !important",
                lineHeight: "24px !important",
                "&::placeholder": {
                    fontSize: "14px",
                    fontFamily: "GT Flexa Mono, sans-serif",
                },
            },
            addSubsectorBtn: {
                marginTop: spacing(2),
                color: palette.primary.dark,
            },
            characterCount: {
                display: "flex",
                justifyContent: "flex-end",
            },
            lookAlikeTextarea: {
                width: "100%",
                borderColor: palette.secondary.main,
                background: "transparent",
                border: "none",
                borderBottom: `1px solid ${palette.text.backgroundDark}`,
                ...typography.body,
                color: palette.common.black,
                outline: "none",
                fontFamily: "GT Flexa, sans-serif",
                "&::placeholder": {
                    ...typography.body,
                    color: palette.text.light,
                },
            },
            strengthBar: {
                position: "relative",
                width: 240,
                height: 8,
                background: palette.text.backgroundDark,
                marginTop: 4,
                marginBottom: 0,
            },
            strength: {
                position: "absolute",
                height: 8,
                top: 0,
                width: 25,
                background: palette.primary.dark,
            },
            centeredContent: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
            },
            continueCta: {
                position: "absolute",
                right: 50,
                bottom: "-27px",
                fontWeight: "400",
                fontSize: "20px",
                "& svg": {
                    stroke: palette.common.white,
                    marginLeft: spacing(2),
                    marginRight: `-${spacing(2)}px`,
                },
                [breakpoint.down("md")]: {
                    bottom: 0,
                    right: 0,
                    position: "relative",
                },
            },
            continueCtaText: {
                marginRight: spacing(2),
            },
            removeIndustryIcon: {
                marginRight: "0 !important",
            },
            continueCtaLookalike: {
                marginTop: spacing(5),
                [breakpoint.down("sm")]: {
                    margin: "32px auto",
                    display: "inherit",
                },
            },
            addMoreDetails: {
                marginTop: 48,
                marginLeft: spacing(3),
                [breakpoint.down("sm")]: {
                    marginTop: 0,
                    marginLeft: 0,
                },
            },
            addMoreDetailsCta: {
                lineHeight: "48px",
                marginLeft: 4,
                [breakpoint.down("sm")]: {
                    marginTop: 0,
                    marginLeft: 0,
                },
            },
            showingResults: {
                padding: `${spacing(1)}px ${spacing(3)}px`,
                position: "absolute",
                top: 58,
                left: 1,
                right: 1,
                background: palette.common.white,
                zIndex: 2,
                height: 40,
                lineHeight: "40px",
            },
            continueFocusCta: {
                marginTop: spacing(1),
                marginRight: spacing(1),
            },
            skipFocusCta: {
                border: "none",
                background: "transparent",
                color: palette.common.black,
                marginTop: spacing(1),
                padding: "11px 24px",
                textDecoration: "underline",
                "&:hover": {
                    border: "none",
                    background: "none",
                },
            },
            skipSubsectorsCta: {
                border: "none",
                background: "transparent",
                color: palette.common.black,
                marginTop: spacing(6),
                marginBottom: spacing(5),
                padding: "11px 24px",
                textDecoration: "underline",
                "&:hover": {
                    border: "none",
                    background: "none",
                },
            },
            subsectorsSubtitle: {
                [breakpoint.down("sm")]: {
                    ...typography.bodySmall,
                },
            },
            continueSubsectorsCta: {
                marginTop: spacing(6),
                marginBottom: spacing(5),
            },
            continueIndustriesAddedCta: {
                marginTop: spacing(5),
                marginRight: spacing(1),
            },
            industriesAddedSubtitle: {
                marginBottom: spacing(1),
            },
            weakStrength: {
                width: 0,
            },
            weak2Strength: {
                width: 20,
                animation: `$animationWeak2Text 0.3s ease-in-out 1`,
            },
            fairStrength: {
                width: 120,
                animation: `$animationFairText 0.3s ease-in-out 1`,
            },
            goodStrength: {
                width: 180,
                animation: `$animationGoodText 0.3s ease-in-out 1`,
            },
            strongStrength: {
                width: 240,
                animation: `$animationStrongText 0.3s ease-in-out 1`,
            },
            weakText: {
                color: palette.text.light,
            },
            fairText: {
                color: palette.text.light,
            },
            goodText: {
                color: palette.text.light,
            },
            strongText: {
                color: palette.text.light,
            },
            queryHint: {
                display: "inline-flex",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                borderTop: `1px solid ${palette.common.black}`,
                borderBottom: `1px solid ${palette.common.black}`,
                borderLeft: `1px solid ${palette.common.black}`,
                borderRight: `1px solid ${palette.common.black}`,
                height: 48,
                lineHeight: "48px",
                marginLeft: 0,
                paddingLeft: spacing(3),
                borderBottomLeftRadius: spacing(1),
            },
            infoIcon: {
                marginRight: spacing(1),
            },
            closeButton: {
                position: "absolute !important",
                right: spacing(2),
                top: spacing(1),
                color: palette.grey[500],
            },
            dialogRoot: {
                display: "flex",
                justifyContent: "center",
            },
            dialogTitle: {
                lineHeight: "18px",
                paddingBottom: spacing(2),
            },
            exampleTextbox: {
                width: "100%",
                minHeight: 270,
                padding: `${spacing(3)}px 0 ${spacing(2)}px 0`,
                borderTop: `1px solid ${palette.text.backgroundDark}`,
                marginTop: spacing(3),
                marginBottom: spacing(5),
                color: palette.grey.main,
                fontFamily: "GT Flexa Mono, sans serif",
                [breakpoint.down("sm")]: {
                    overflowY: "auto",
                },
            },
            exampleText: {
                marginBottom: spacing(2),
                color: palette.text.main,
            },
            exampleTextWrapper: {
                minHeight: 80,
            },
            link: {
                marginLeft: spacing(1),
                cursor: "pointer",
                "&:hover": {
                    textDecoration: "underline",
                },
            },
            exampleTitle: {
                fontFamily: "GT Flexa Mono, sans serif",
            },
            exampleDescDialogTitle: {
                marginBottom: spacing(3),
            },
            dialogScrollPaper: {
                maxWidth: 820,
            },
            subsectorsDialogScrollPaper: {
                maxWidth: 1070,
            },
            subsesctorsDialogSubtitle: {
                marginBottom: spacing(2),
            },
            subsesctorsDialogSectorTitle: {
                marginBottom: spacing(3),
            },
            dialog: {
                padding: spacing(4),
                background: palette.primary.backgroundMedium,
                [breakpoint.down("sm")]: {
                    margin: 0,
                    maxHeight: "none",
                    height: "100%",
                    background: palette.primary.backgroundLight,
                },
            },
            subsectorsDialog: {
                position: "relative",
                background: palette.text.backgroundLight,
                padding: spacing(4),
                borderRadius: 24,
                [breakpoint.down("sm")]: {
                    maxWidth: "none",
                    maxHeight: "none",
                    margin: 0,
                    height: "100%",
                    borderRadius: 0,
                    padding: spacing(3),
                },
            },
            subsectorsDialogIllustration: {
                position: "absolute",
                top: 67,
                right: 120,
                maxHeight: 120,
                [breakpoint.down("sm")]: {
                    display: "none",
                },
            },
            examplesDialogIllustration: {
                textAlign: "center",
                marginBottom: spacing(1),
            },
            dialogTitleWrapper: {
                padding: 0,
                [breakpoint.down("sm")]: {
                    marginTop: spacing(4),
                },
            },
            dialogContent: {
                margin: 0,
                padding: 0,
                "&:first-child": {
                    paddingTop: 0,
                },
            },
            maxHeight: {
                maxHeight: 305,
                [breakpoint.down("sm")]: {
                    maxHeight: "60vh",
                    overflowY: "scroll",
                },
            },
            subsectorsDialogContent: {
                margin: 0,
                padding: 0,
                position: "relative",
                overflow: "hidden",
                "&:first-child": {
                    paddingTop: 0,
                },
                [breakpoint.up("lg")]: {
                    minWidth: 942,
                    minHeight: 92,
                },
            },
            relative: {
                position: "relative",
                width: "100%",
                overflowY: "auto",
            },
            paperWidthLg: {
                minHeight: 647,
                maxWidth: 752,
                padding: "64px 96px",
                [breakpoint.down("sm")]: {
                    padding: `${spacing(5)}px ${spacing(3)}px`,
                },
            },
            industryDialogContent: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
            },
            mobileStepper: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                borderBottom: `1px solid rgba(153, 153, 153, 0.2)`,
            },
            mobileStepperRoot: {
                maxWidth: 130,
                width: "100%",
                background: "transparent !important",
                ...typography.bodyXs,
                fontFamily: "GT Flexa Mono, sans serif",
                position: "relative",
                paddingLeft: "70px !important",
                letterSpacing: "-3px",
                marginTop: spacing(2),
                marginBottom: spacing(2),
                margin: "0 auto",
                "&::before": {
                    content: "'Example'",
                    width: 12,
                    height: 12,
                    position: "absolute",
                    top: 0,
                    left: 10,
                    letterSpacing: "normal",
                },
            },
            sectorNameDialogTitle: {
                color: palette.secondary.light,
                margin: `${spacing(4)}px 0 ${spacing(3)}px 0`,
            },
            divider: {
                width: "100%",
                height: 1,
                background: "#E3E5E9",
                marginBottom: spacing(3),
            },
            sectors: {
                display: "flex",
                minHeight: "235px",
                maxWidth: "749px",
                width: "100%",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                marginBottom: spacing(5),
            },
            selectedSectors: {
                display: "flex",
                maxWidth: "749px",
                width: "100%",
                justifyContent: "flex-start",
                flexWrap: "wrap",
            },
            sector: {
                margin: "0 10px 10px 0px",
                color: palette.common.black,
                border: `1px solid ${palette.common.black}`,
                background: palette.success.lighter,
                "& svg": {
                    stroke: "transparent",
                    filter: "invert(71%) sepia(29%) saturate(4407%) hue-rotate(335deg) brightness(101%) contrast(98%);",
                },
            },
            sectorDialog: {
                margin: "0 10px 10px 0px",
            },
            secondaryLightText: {
                color: palette.secondary.light,
            },
            subsectors: {
                width: "100%",
                marginTop: spacing(2),
                marginBottom: spacing(2),
                position: "relative",
            },
            subsectorSection: {
                padding: `${spacing(3)}px 0 ${spacing(3)}px 0`,
                borderBottom: `1px solid rgba(153, 153, 153, 0.2)`,
                display: "flex",
                flexDirection: "row",
                gap: spacing(6),
                [breakpoint.down("md")]: {
                    flexDirection: "column",
                    gap: spacing(2),
                },
            },
            industriesLabels: {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                gap: spacing(6),
                flexDirection: "row",
                flexWrap: "wrap",
                // [breakpoint.down("md")]: {
                //     flexDirection: 'column',
                //     gap: spacing(2)
                // }
            },
            subsectorSectionIndustry: {
                width: "50%",
                "& $sector": {
                    margin: "0",
                },
                [breakpoint.down("md")]: {
                    width: "100%",
                },
            },
            subsectorSectionSubsectors: {
                width: "50%",
            },
            subsectorTitle: {
                marginBottom: spacing(3),
            },
            focusTitle: {
                marginBottom: spacing(2),
            },
            focusSubtitle: {
                marginBottom: spacing(4),
                [breakpoint.down("sm")]: {
                    ...typography.bodySmall,
                },
            },
            selectedSubsector: {
                marginRight: spacing(1),
            },
            bgGradient: {
                height: 8,
                left: 0,
                right: 0,
                position: "absolute",
                bottom: 0,
                zIndex: 2,
                background: `linear-gradient(180.03deg, rgba(255, 248, 243, 0) 0.03%, #FFF8F3 99.97%)`,
            },
            addSubsector: {
                color: palette.secondary.light,
                cursor: "pointer",
                height: "32px",
                display: "inline-flex",
                alignItems: "center",
            },
            optionalLabel: {
                color: colors.secondary.main,
                marginLeft: 2,
            },
            removeSector: {
                position: "absolute",
                right: spacing(1),
                zIndex: 2,
                display: "flex",
                "&:hover $removeSectorIcon": {
                    display: "none",
                },
                "&:hover $removeSectorIconHover": {
                    display: "block",
                },
            },
            removeSectorIcon: {},
            removeSectorIconHover: {
                display: "none",
            },
            addSubsectorSection: {
                padding: `${spacing(5)}px ${spacing(10)}px`,
                borderTop: "1px solid #E3E5E9",
                borderBottom: "1px solid #E3E5E9",
                marginTop: spacing(6),
            },
            textField: {},
            lookalikeSearchIllustration: {
                maxWidth: 300,
                marginBottom: spacing(6),
                opacity: 1,
                height: 135,
                animation: "$animationIllustrationGetBack 0.5s ease-in-out",
            },
            lookalikeSearchIllustrationShrinked: {
                animation: `$animationIllustrationShrink 1s ease-in-out`,
                height: 0,
                opacity: 0,
            },
            newTextField: {
                marginBottom: 4,
                "& input": {
                    padding: spacing(3),
                    border: `0 solid ${palette.common.black}`,
                    borderRadius: spacing(1),
                    "&:active": {
                        border: `1px solid ${palette.primary.dark}`,
                    },
                },
                "& $labelShrinked": {
                    top: -20,
                    transform: "none",
                },
            },
            inputRoot: {
                borderRadius: spacing(1),
            },
            addSubsectorDialogTitle: {
                color: palette.secondary.light,
                marginBottom: spacing(4),
            },
            saveAndExit: {
                paddingTop: spacing(4),
            },
            subsectorsDialogActionItems: {
                paddingTop: spacing(2),
                flexDirection: "row",
                justifyContent: "center",
            },
            secondaryLightColor: {
                color: palette.secondary.light,
            },
            summaryProductWrapper: {
                marginBottom: spacing(4),
            },
            greyText: {
                color: palette.grey.main,
            },
            backDialogSubtitle: {
                margin: `${spacing(3)}px 0 ${spacing(4)}px 0`,
            },
            searchLoaderRoot: {
                background: palette.primary.main,
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "column",
                color: "white",
            },
            loaderTitle: {
                maxWidth: 833,
            },
            loader: {
                marginBottom: spacing(7),
            },
            "@keyframes loadingAnimation": {
                ...animationFuncLoader(),
            },
            backDialogCancel: {
                marginRight: spacing(2),
            },
            subsectorsWrapper: {
                position: "relative",
                width: "100%",
                paddingRight: 40,
                "& $sector": {
                    marginBottom: spacing(1),
                    marginRight: spacing(1),
                },
            },
            subsectorsEditLabel: {
                display: "block",
                paddingLeft: 0,
            },
            summaryIllustration: {
                margin: `0px 0 ${spacing(5)}px 0`,
                maxWidth: 168,
            },
            already: {
                marginBottom: spacing(6),
            },
            goUpBtn: {
                width: 36,
                marginLeft: 2,
                height: 36,
                alignItems: "center",
                justifyContent: "center",
                zIndex: 2,
                cursor: "pointer",
                borderRadius: 4,
                display: "flex",
                border: `1px solid ${palette.common.black}`,
                "& path": {
                    stroke: "#999999",
                },
                [breakpoint.down("sm")]: {
                    background: palette.primary.backgroundLight,
                },
            },
            goUpBtnDisabled: {
                cursor: "default",
                display: "flex",
            },
            arrowsUpDown: {
                position: "fixed",
                bottom: 0,
                right: 0,
                padding: "50px 123px",
                justifyContent: "center",
                display: "flex",
                zIndex: 1,
                [breakpoint.down("sm")]: {
                    left: 0,
                    padding: "6px 24px",
                    height: 48,
                    background: palette.primary.backgroundLight,
                },
            },
            loaderWave: {
                position: "absolute",
                top: "20%",
            },
            marginAdjust: {
                top: "30%",
            },
            comingSoonRoot: {
                background: palette.primary.backgroundMedium,
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                color: "white",
            },
            count: {
                fontSize: 80,
                lineHeight: "97px",
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            countLabel: {
                textTransform: "uppercase",
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            counterWrapper: {
                display: "flex",
                marginTop: 77,
            },
            counter: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 80,
            },
            counterLast: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            },
            comingSoonSubtitle: {
                margin: "16px 0 57px 0",
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            subsectorsButtons: {
                position: "fixed",
                bottom: 0,
                [breakpoint.down("sm")]: {
                    bottom: 30,
                    position: "relative",
                },
            },
            comingSoonImg: {
                maxWidth: 300,
                maxHeight: 300,
            },
            hitDescription: {
                color: palette.text.light,
                display: "-webkit-box",
                overflowY: "hidden",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": 2,
            },
            hit: {
                padding: `${spacing(1)}px ${spacing(3)}px`,
                borderLeft: `2px solid transparent`,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                "&:hover": {
                    background: palette.primary.backgroundLight,
                    cursor: "pointer",
                    "& $hitDescription": {
                        color: palette.primary.dark,
                    },
                },
                [breakpoint.down("sm")]: {
                    padding: `${spacing(2)}px`,
                },
            },
            hitSelected: {
                borderLeft: `2px solid ${palette.primary.dark}`,
                [breakpoint.down("sm")]: {
                    background: palette.primary.backgroundLight,
                },
            },
            hitTitle: {
                fontWeight: 500,
                minWidth: "40%",
            },
            companySelectedLabel: {
                position: "absolute",
                top: spacing(2),
                right: spacing(3),
                fontFamily: "GT Flexa Mono, sans-serif",
                [breakpoint.down("sm")]: {
                    top: 5,
                    right: 8,
                    background: palette.primary.backgroundLight,
                },
            },
            arrowUp: {
                transform: "rotate(180deg)",
            },
            searchBox: {
                position: "relative",
                "& input": {
                    width: "100%",
                    ...typography.body,
                    color: palette.common.black,
                    outline: "none",
                    padding: spacing(2),
                    background: palette.common.white,
                    border: `1px solid ${palette.common.black}`,
                    borderRadius: spacing(1),
                    fontFamily: "GT Flexa, sans-serif",
                    "&::placeholder": {
                        ...typography.bodySmall,
                        color: palette.text.lighter,
                        fontFamily: "GT Flexa Mono, sans-serif",
                    },
                    "&:active, &:focus": {
                        border: `1px solid ${palette.primary.dark}`,
                    },
                },
                "& .ais-SearchBox-submit, & .ais-SearchBox-reset, & input[type='search' i]::-webkit-search-cancel-button":
                    {
                        display: "none",
                    },
            },
            lookAlikeRoot: {
                marginTop: spacing(6),
                [breakpoint.down("sm")]: {
                    marginTop: spacing(6),
                },
            },
            input: {
                "&.Mui-focused": {
                    "&:after": {
                        borderBottom: `1px solid ${palette.text.lighter} !important`,
                    },
                    "&:before": {
                        borderBottom: `1px solid ${palette.text.lighter} !important`,
                    },
                },
                "&:after": {
                    borderBottom: `1px solid ${palette.text.lighter} !important`,
                },
                "&:before": {
                    borderBottom: `1px solid ${palette.text.lighter} !important`,
                },
            },
            goBack: {
                marginTop: spacing(3),
                marginRight: "auto",
                [breakpoint.down("sm")]: {
                    marginTop: 0,
                },
            },
            backToCompany: {
                fontFamily: "GT Flexa Mono, sans-serif",
                cursor: "pointer",
                [breakpoint.down("sm")]: {
                    textAlign: "center",
                    display: "block",
                },
            },
            backToArray: {
                marginTop: spacing(3),
                stroke: palette.secondary.dark,
                transform: "rotate(180deg)",
                marginRight: spacing(1),
            },
            findSimilarCompanies: {
                marginTop: spacing(2),
                [breakpoint.down("sm")]: {
                    display: "inherit",
                    margin: "16px auto 8px auto",
                },
            },
            labelShrinked: {
                ...typography.bodyXs,
                fontFamily: "GT Flexa Mono, sans-serif",
                transform: "none",
                marginTop: -4,
                color: palette.text.light,
            },
            focusedLabel: {
                color: `${palette.text.lighter} !important`,
            },
            form: {
                maxWidth: 480,
                alignSelf: "center",
            },
            waveLoader: {
                position: "absolute",
                right: 0,
                top: 0,
            },
            lookAlikeLoader: {
                position: "absolute",
                right: 0,
                top: 0,
            },
            lookAlikesTitle: {
                display: "flex",
                alignItems: "center",
            },
            betaWrapper: {
                height: 25,
                display: "flex",
            },

            // new search
            mainSearchBoxWrapper: {
                width: "100%",
                maxWidth: "1500px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // paddingTop: "7vh",
                [breakpoint.down("lg")]: {
                    width: "100%",
                    marginBottom: "50px",
                    padding: spacing(2),
                },
            },
            mainSearchBox: {
                position: "relative",
                height: "100%",
                width: "100%",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                border: `1px solid ${palette.success.backgroundDark}`,
                background: palette.success.backgroundLight,
                // [breakpoint.down("md")]: {
                //     borderTopRightRadius: 0,
                //     borderBottomRightRadius: 0,
                //     borderBottomLeftRadius: 0,
                //     borderRight: 0,
                //     borderLeft: 0,
                //     borderBottom: 0,
                //     background: "transparent"
                // },
            },
            mainSearchBoxTabTwoVariant: {
                borderTopLeftRadius: "20px",
                borderTopRightRadius: 0,
            },
            searchTypeTab: {
                height: "50px",
                width: "50%",
                borderLeft: `1px solid ${palette.success.backgroundDark}`,
                borderTop: `1px solid ${palette.success.backgroundDark}`,
                borderRight: `1px solid ${palette.success.backgroundDark}`,
                borderBottom: `1px solid ${palette.success.backgroundLight}`,
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                background: palette.success.backgroundLight,
                color: palette.success.dark,
                position: "relative",
                zIndex: 1,
                marginBottom: "-1px",
                fontSize: spacing(3),
                "& :hover": {
                    cursor: "pointer",
                },
                [breakpoint.down("md")]: {
                    width: "100%",
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: 0,
                },
            },
            mainSearchBoxTabActive: {
                background: palette.success.backgroundLight,
                color: palette.success.dark,
                fontWeight: 500,
            },
            circle: {
                width: 6,
                height: 6,
                borderRadius: "100%",
                background: palette.primary.dark,
                margin: `2px 6px`,
                display: "inline-flex",
            },
            mainSearchBoxTabOne: {
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                paddingLeft: spacing(8),
                paddingTop: spacing(2),
            },
            mainSearchBoxTabTwo: {
                borderTopRightRadius: "20px",
                textAlign: "center",
                paddingTop: spacing(2),
            },
            tab: {
                display: "flex",
                justifyContent: "space-between",
            },
            tabBtn: {
                width: "328px",
            },
            industriesAndTechnologiesWrapper: {
                width: "71vw",
                margin: "0 auto",
                marginTop: spacing(6),
                [breakpoint.down("md")]: {
                    width: "100%",
                    marginBottom: "50px",
                },
            },
            industrySearchExample: {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                paddingTop: spacing(3),
                paddingBottom: spacing(3),
            },
            industryCompanySearchExamples: {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                paddingTop: spacing(3),
            },
            industrySearchExampleBtn: {
                margin: "auto 0",
                minWidth: "150px",
            },
            technologySearchExampleBtn: {
                margin: "auto 0",
                minWidth: "150px",
            },
            technologySearchExample: {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                paddingTop: spacing(3),
            },
            helpArrowDown: {
                width: 10,
                marginLeft: spacing(1),
                cursor: "pointer",
            },
            arrowDown: {
                position: "absolute",
                right: spacing(2),
                zIndex: 2,
                cursor: "pointer",
            },
            arrowDownDisabled: {
                position: "absolute",
                right: spacing(2),
                zIndex: 2,
                cursor: "not-allowed",
            },
            locations: {
                display: "flex",
                marginTop: spacing(3),
                paddingBottom: spacing(4),
                gap: spacing(6),
                flexDirection: "row",
                flexWrap: "wrap",
                [breakpoint.down("md")]: {
                    flexDirection: "column",
                    gap: spacing(2),
                    marginTop: spacing(6),
                },
            },
            location: {
                display: "flex",
                flexDirection: "column",
                maxWidth: "calc(50% - 28px)",
                position: "relative",
                flex: 1,
                [breakpoint.down("md")]: {
                    maxWidth: "100%",
                },
            },
            locationTitles: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                marginTop: spacing(3),
                marginBottom: spacing(1),
            },
            locationsWrapper: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: spacing(1),
                [breakpoint.down("xl")]: {
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "150px",
                },
            },
            dashboardLocationsWrapper: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: spacing(1),
                [breakpoint.down("xl")]: {
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "150px",
                },
                [breakpoint.up("xxxl")]: {
                    width: "94%",
                },
            },
            regionsWrapper: {
                display: "flex",
                flexDirection: "row",
                border: `1px solid ${palette.common.black}`,
                borderRadius: "10px",
                background: palette.common.white,
            },
            countriesWrapper: {
                display: "flex",
                flexDirection: "row",
                border: `1px solid ${palette.common.black}`,
                borderRadius: "10px",
                background: palette.common.white,
            },
            rowWrap: {
                flex: 1,
                maxWidth: "33%",
                [breakpoint.down("xl")]: {
                    flex: 1,
                    maxWidth: "50%",
                },
            },
            multiSelect: {
                border: "none !important",
                minWidth: "100px",
                background: `${palette.common.white} !important`,
                position: "relative",
                flex: 1,
                "& .Mui-disabled": {
                    background: `${palette.common.white} !important`,
                    height: "40%",
                },
                "& .Mui-disabled:hover": {
                    cursor: "not-allowed",
                },
                "& svg": {
                    marginLeft: spacing(2),
                },
                "& *": {
                    border: "none !important",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                },
            },
            multiSelectRoot: {
                paddingLeft: `${spacing(2)}px !important`,
            },
            verticalDivider: {
                width: "1px",
                height: "70%",
                background: palette.common.black,
                position: "absolute",
                right: 0,
                zIndex: 2,
            },
            arrowDivider: {
                position: "absolute",
                left: -35,
                top: 32,
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            simpleInput: {
                border: "none",
                outline: "none",
                borderRadius: "10px",
                background: `${palette.common.white}`,
                paddingLeft: spacing(1),
                flex: 2,
                maxWidth: "66%",
                [breakpoint.down("xl")]: {
                    flex: 1,
                    maxWidth: "50%",
                },
            },
            industrySearchExampleText: {
                maxWidth: "80%",
            },
            errorsWrapper: {
                height: "20px",
                color: palette.error.main,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: spacing(2),
            },
            error1: {
                width: "50%",
                textAlign: "right",
                paddingRight: spacing(3),
            },
            error2: {
                width: "50%",
                textAlign: "right",
            },
            footerButtonsWrapper: {
                background: palette.common.white,
                height: "100px",
                marginLeft: `-${spacing(4)}px`,
                marginBottom: `-${spacing(4)}px`,
                marginRight: `-${spacing(4)}px`,
                bottom: 0,
                left: 0,
                right: 0,
                borderBottomLeftRadius: spacing(2),
                borderBottomRightRadius: spacing(2),
                borderTop: `1px solid ${palette.success.backgroundDark}`,
            },
            footerButtons: {
                height: "100%",
                width: "100%",
                marginLeft: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottomRightRadius: "22px",
                padding: "0 40px",
            },
            footerButton1: {
                margin: "auto 0",
                height: "40px",
                width: "100px",
            },
            footerButton2: {
                margin: "auto 0",
                height: "40px",
                width: "160px",
                padding: 0,
            },
            scrollable: {
                [breakpoint.up("xxxl")]: {
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "240px",
                },
            },
        }),
);
