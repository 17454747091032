import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            marginTop: spacing(5),
        },
        block: {
            padding: `${spacing(4)}px 0`,
            background: palette.common.white,
            width: "calc(33% - 9px)",
            borderRadius: spacing(1),
            marginBottom: spacing(2),
            marginRight: spacing(2),
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            minHeight: 360,
            "&:nth-child(3n+3)": {
                marginRight: 0,
            },
            [breakpoint.down("lg")]: {
                width: "calc(50% - 9px)",
                "&:nth-child(3n+3)": {
                    marginRight: spacing(2),
                },
                "&:nth-child(2n)": {
                    marginRight: 0,
                },
            },
            [breakpoint.down("md")]: {
                margin: "0 24px 24px 0",
                width: "calc(50% - 25px)",
                "&:nth-child(3n+3)": {
                    marginRight: spacing(3),
                },
                "&:nth-child(2n)": {
                    marginRight: 0,
                },
            },
            [breakpoint.down("sm")]: {
                width: "100%",
                minHeight: 0,
                padding: "24px 24px 16px 24px",
                margin: "0 16px 24px 16px",
                "&:nth-child(3n+3)": {
                    marginRight: spacing(2),
                },
                "&:nth-child(2n)": {
                    marginRight: spacing(2),
                },
            },
        },
        blockText: {
            width: "100%",
        },
        blockTitle: {
            fontSize: "20px",
            width: "100%",
            marginTop: spacing(2),
            marginBottom: spacing(2),
            [breakpoint.down("sm")]: {
                margin: 0,
            },
        },
        imgBlock: {
            width: "100%",
            // background: palette.primary.backgroundLight,
            borderRadius: spacing(1),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: `0 ${spacing(4)}px`,
            marginBottom: spacing(3),
            [breakpoint.down("sm")]: {
                order: 2,
                width: "100%",
                marginTop: 0,
                marginBottom: spacing(1),
                paddingLeft: spacing(5),
                paddingRight: 0,
            },
        },
        blockImg: {
            maxHeight: 160,
        },
        blockImgLast: {
            // marginTop: 74
        },
        ml8: {
            marginLeft: spacing(1),
        },
        readMore: {
            // marginTop: spacing(3),
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            padding: `0 ${spacing(4)}px`,
        },
        openDialogSection: {
            // marginTop: spacing(3),
            padding: `21px ${spacing(4)}px 0 ${spacing(4)}px`,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            width: "100%",
            borderTop: `1px solid ${palette.text.backgroundMain}`,
            "&:hover $mono": {
                color: palette.secondary.dark,
            },
            [breakpoint.down("sm")]: {
                order: 3,
                padding: `21px ${spacing(4)}px 0 0`,
            },
        },
        shTitle: {
            marginTop: spacing(2),
            maxWidth: 727,
        },
        shSubtitle: {
            marginTop: spacing(2),
        },
        shButton: {
            marginTop: spacing(3),
            background: palette.primary.dark,
        },
        freeUserRoot: {
            background: palette.common.white,
            borderRadius: spacing(3),
            padding: "64px 0 78px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        flexWrap: {
            display: "flex",
            flexWrap: "wrap",
        },
        lockIcon: {
            position: "absolute",
            right: 28,
            top: 173,
            [breakpoint.down("sm")]: {
                top: 24,
                right: "auto",
                left: 24,
                order: 1,
            },
        },
        mono: {
            fontFamily: "GT Flexa Mono, sans-serif",
        },
        questionMark: {
            marginRight: spacing(2),
            [breakpoint.down("sm")]: {
                order: 2,
                marginLeft: spacing(1),
            },
        },
        imgWrapper: {
            background: palette.primary.backgroundLight,
            width: "100%",
            height: 160,
            borderRadius: spacing(1),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [breakpoint.down("sm")]: {
                display: "none",
            },
        },
        benefitsCardsWrapper: {
            background: palette.common.white,
            borderRadius: spacing(3),
            marginBottom: spacing(6),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        benefitsCardsWrapperTitle: {
            height: "56px",
            background: palette.primary.backgroundLight,
            width: "100%",
            borderRadius: `${spacing(3)}px ${spacing(3)}px 0 0`,
            textAlign: "center",
            lineHeight: "56px",
        },
        benefitsCard: {
            width: "100%",
            borderBottom: `1px solid ${palette.text.lightest}`,
            paddingTop: spacing(1),
            [breakpoint.down("md")]: {
                paddingTop: spacing(4),
            },
        },
        descriptionExpanded: {
            maxHeight: "none",
            paddingBottom: spacing(4),
        },
        description: {
            overflowY: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
            [breakpoint.down("lg")]: {
                maxHeight: 352,
            },
        },
        descriptionBox: {
            paddingRight: spacing(4),
            position: "relative",
        },
        showMore: {
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            cursor: "pointer",
            textAlign: "center",
            paddingTop: spacing(4),
            background: `linear-gradient(180.03deg, rgba(255, 248, 243, 0) 20%, #FFFFFF 80%)`,
            "&::before": {
                content: '""',
                position: "absolute",
                bottom: 9,
                left: 0,
                width: "42%",
                height: 1,
                background: palette.text.backgroundMain,
            },
            "&::after": {
                content: '""',
                position: "absolute",
                bottom: 9,
                right: 0,
                width: "42%",
                height: 1,
                background: palette.text.backgroundMain,
            },
        },
        companies: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
    }),
);
