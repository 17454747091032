import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        recentSearchesWrapper: {},
        label: {},
        inputWrapper: {
            position: "relative",
        },
        pseudoInput: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #666666",
            borderRadius: "4px",
            height: 44,
            padding: "8px 12px",
            cursor: "pointer",
            color: "#666666",
        },

        menu: {
            position: "absolute",
            top: "45px",
            left: "0",
            background: "#0B295F",
            width: "100%",
            height: "auto",
            borderRadius: "12px",
            zIndex: 10,
            color: "#fff",
            padding: "16px 14px",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        },
        hoveredContent: {
            position: "absolute",
            left: "-50%",
            top: "33px",
            display: "none",
            width: "245px",
            background: "#fff",
            borderRadius: "12px",
            color: "#000",
            padding: 16,
            zIndex: 1
        },
        hoveredContentTitle: {
            fontSize: "12px",
            fontWeight: 500,
        },
        hoveredContentDescription: {
            fontSize: "12px",
        },
        pseudoArrowHoveredContent: {
            content: "",
            width: 0,
            height: 0,
            borderBottom: "8px solid transparent",
            borderLeft: "8px solid transparent",
            borderTop: "8px solid transparent",
            borderRight: "8px solid #fff",
            position: "absolute",
            top: "-14px",
            left: "100px",
            transform: "rotate(90deg)",
        },
        pseudoArrow: {
            content: "",
            width: 0,
            height: 0,
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            borderBottom: "8px solid #0B295F",
            position: "absolute",
            top: -3,
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
        downIcon: {
            marginRight: 8,
        },
        upIcon: {
            marginRight: 8,
            transform: "rotate(180deg)",
        },
        searchLabelWrapper: {
            position: "relative",
        },
        paragraph: {
            width: "fit-content",
            cursor: "pointer",
            margin: "2px 0",
            fontSize: "14px",
            textDecoration: "underline",
            "&:hover": {
                color: palette.primary.main,
            },
            "&:hover + $hoveredContent": {
                display: "block",
                width: "245px",
            },
        },
    }),
);
