import { all, takeEvery } from "redux-saga/effects";
import {
    postLoginRequest,
    postLogoutRequest,
    getMeRequest,
    getMeStealthRequest,
    updateProfileRequest,
    registerRequest,
    sendResetLinkRequest,
    checkResetLinkRequest,
    resetPasswordRequest,
    fetchCookieRequest,
    subscribeRequest,
    getTeamRequest,
    removeMemberRequest,
    removeInvitationRequest,
    fetchInvitationRequest,
    handleInvitationRequest,
    fetchAccountRequest,
    leaveTeamRequest,
    openClusterMapRequest,
} from "../requests";
import {
    postLogin,
    postLogout,
    fetchMe,
    fetchMeStealth,
    updateProfile,
    register,
    sendResetLink,
    checkResetLink,
    resetPassword,
    fetchCookie,
    subscribe,
    fetchTeam,
    removeMember,
    removeInvitation,
    fetchInvitation,
    handleInvitation,
    fetchAccount,
    leaveTeam,
    openClusterMap,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchPostLogin() {
    yield takeEvery(
        convertTypeFromAction(postLoginRequest),
        ({ payload: { emailValue, passwordValue, token } }) =>
            postLogin(emailValue, passwordValue, token),
    );
}

function* watchPostLogout() {
    yield takeEvery(convertTypeFromAction(postLogoutRequest), () =>
        postLogout(),
    );
}

function* watchRegister() {
    yield takeEvery(
        convertTypeFromAction(registerRequest),
        ({ payload: { data } }) => register(data),
    );
}

function* watchSubscribe() {
    yield takeEvery(
        convertTypeFromAction(subscribeRequest),
        ({ payload: { data } }) => subscribe(data),
    );
}

function* watchSendResetLink() {
    yield takeEvery(
        convertTypeFromAction(sendResetLinkRequest),
        ({ payload: { data } }) => sendResetLink(data),
    );
}

function* watchCheckResetLink() {
    yield takeEvery(
        convertTypeFromAction(checkResetLinkRequest),
        ({ payload: { data } }) => checkResetLink(data),
    );
}

function* watchResetPassword() {
    yield takeEvery(
        convertTypeFromAction(resetPasswordRequest),
        ({ payload: { data } }) => resetPassword(data),
    );
}

function* watchGetMe() {
    yield takeEvery(convertTypeFromAction(getMeRequest), () => fetchMe());
}

function* watchGetMeStealth() {
    yield takeEvery(convertTypeFromAction(getMeStealthRequest), () =>
        fetchMeStealth(),
    );
}

function* watchGetTeam() {
    yield takeEvery(
        convertTypeFromAction(getTeamRequest),
        ({ payload: { team } }) => fetchTeam(team),
    );
}

function* watchLeaveTeam() {
    yield takeEvery(
        convertTypeFromAction(leaveTeamRequest),
        ({ payload: { team } }) => leaveTeam(team),
    );
}

function* watchGetCookie() {
    yield takeEvery(convertTypeFromAction(fetchCookieRequest), () =>
        fetchCookie(),
    );
}

function* watchUpdateProfile() {
    yield takeEvery(
        convertTypeFromAction(updateProfileRequest),
        ({ payload: { data } }) => updateProfile(data),
    );
}

function* watchGetAccount() {
    yield takeEvery(
        convertTypeFromAction(fetchAccountRequest),
        ({ payload: { team } }) => fetchAccount(team),
    );
}

function* watchRemoveMember() {
    yield takeEvery(
        convertTypeFromAction(removeMemberRequest),
        ({ payload: { email } }) => removeMember(email),
    );
}

function* watchRemoveInvitation() {
    yield takeEvery(
        convertTypeFromAction(removeInvitationRequest),
        ({ payload: { email } }) => removeInvitation(email),
    );
}

function* watchFetchInvitation() {
    yield takeEvery(
        convertTypeFromAction(fetchInvitationRequest),
        ({ payload: { token } }) => fetchInvitation(token),
    );
}

function* watchHandleInvitation() {
    yield takeEvery(
        convertTypeFromAction(handleInvitationRequest),
        ({ payload: { token } }) => handleInvitation(token),
    );
}

function* watchOpenClusterMap() {
    yield takeEvery(convertTypeFromAction(openClusterMapRequest), () =>
        openClusterMap(),
    );
}

function* saga() {
    yield all([
        watchPostLogin(),
        watchPostLogout(),
        watchGetMe(),
        watchGetMeStealth(),
        watchUpdateProfile(),
        watchRegister(),
        watchResetPassword(),
        watchSendResetLink(),
        watchCheckResetLink(),
        watchGetCookie(),
        watchSubscribe(),
        watchGetTeam(),
        watchRemoveMember(),
        watchFetchInvitation(),
        watchHandleInvitation(),
        watchGetAccount(),
        watchLeaveTeam(),
        watchRemoveInvitation(),
        watchOpenClusterMap(),
    ]);
}

export { saga };
