import React, { useState } from "react";
// Hooks
import { useStyles } from "../style.js";
import { useTranslation } from "react-i18next";
import { useAppEventTracking } from "../../../common/useAppEventTracking";
//UI components
import Typography from "../../../components/Typography/Typography";
import Card from "../../Results/components/Card/Card";
import { useRouterHook } from "../../../common/useRouterHook";
import { useAuthHook } from "../../../common/useAuthHook";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
// Assets
import RightArrow from "../../../assets/icons/RightArrow.svg";
import RightArrowActive from "../../../assets/icons/RightArrowActive.svg";
import LeftArrow from "../../../assets/icons/LeftArrow.svg";
import LeftArrowActive from "../../../assets/icons/LeftArrowActive.svg";
import Link from "../../../components/Link/Link";
// Redux
// Constants

const DashboardStepperCard = ({
    items = [],
    type,
    showNeedPlan = false,
    showNoSuggested = false,
    testId,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const { isAuthenticated } = useAuthHook();
    const { mixpanelTrackEvent } = useAppEventTracking();

    const [slideIndex, setSlideIndex] = useState(0);

    const next = () => setSlideIndex(prev => prev + 1);
    const prev = () => setSlideIndex(prev => prev - 1);

    const trackEvent = e => {
        if (type === "suggestedCompanies") {
            mixpanelTrackEvent("Suggested company profile viewed");
        } else if (type === "popularCompanies") {
            mixpanelTrackEvent("Popular company profile viewed");
        }
    };

    return (
        <div className={classes.dashboardStepperCard} test-id={testId}>
            <div className={classes.titleInfoWrap}>
                <Typography component="div" variant="paragraph">
                    {t(`dashboard.stepperCards.${type}.title`)}
                </Typography>
                {type === "suggestedCompanies" ? (
                    <Tooltip
                        interactive={true}
                        classes={{ tooltip: classes.averageDataTooltip }}
                        title={
                            <>
                                <div>
                                    <Typography
                                        variant="bodySmall"
                                        color="black">
                                        {t(
                                            "suggestedCompanies.tooltip.description1.text",
                                        )}
                                    </Typography>
                                    <Typography
                                        className={classes.cursorPointer}
                                        onClick={() => {
                                            openPage(
                                                window.location.origin +
                                                    "/profile-settings",
                                                true,
                                            );
                                        }}
                                        variant="bodySmall"
                                        textDecoration="underline"
                                        color="black">
                                        {t(
                                            "suggestedCompanies.tooltip.description1.link",
                                        )}
                                    </Typography>
                                    <Typography
                                        className={classes.description2}
                                        variant="bodySmall"
                                        color="black"
                                        component="div">
                                        {t(
                                            "suggestedCompanies.tooltip.description2",
                                        )}
                                    </Typography>
                                </div>
                            </>
                        }>
                        <div className={classes.infoButton}>i</div>
                    </Tooltip>
                ) : null}
            </div>
            {showNoSuggested || showNeedPlan ? (
                <>
                    <div className={classes.noSearchHistory}>
                        {showNeedPlan ? (
                            <>
                                <Typography component="div">
                                    {t("dashboard.stepperCards.needPlan")}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography
                                    component="div"
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "dashboard.stepperCards.suggestedCompanies.desc1",
                                            {
                                                href:
                                                    window.location.origin +
                                                    "/profile-settings",
                                            },
                                        ),
                                    }}
                                />
                                <br />
                                <Typography component="div">
                                    {t(
                                        "dashboard.stepperCards.suggestedCompanies.desc2",
                                    )}
                                </Typography>
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {items &&
                        items.map(
                            (item, i) =>
                                i === slideIndex && (
                                    <div
                                        className={classes.slideWrap}
                                        key={`${i}-slide`}
                                        onClick={e => trackEvent(e)}>
                                        <Card
                                            className={
                                                classes.dashboardStepperCardBody
                                            }
                                            isTechnology={false}
                                            isIndustry={false}
                                            isIndustryProfile={false}
                                            isLikedCompany={false}
                                            isSuggestedCompany={true}
                                            cardIndex={i}
                                            openPage={openPage}
                                            searchResultsSid={null}
                                            t={t}
                                            result={item}
                                            isAuthenticated={isAuthenticated}
                                            deselectAll={() => {}}
                                            selectedCompanies={[]}
                                            key={`card-${i}-${item.id}`}
                                            setSelectedCompanyIndex={() => {}}
                                            showPopUpForIndex={false}
                                            searchTypeLook={null}
                                            parentId={item.id}
                                            source="dashboard"
                                            view={true}
                                        />
                                    </div>
                                ),
                        )}

                    <div className={classes.stepper}>
                        <div className={classes.backBtnWrapper}>
                            <Link
                                variant="body2"
                                iconRight
                                onClick={prev}
                                className={classes.exampleStepperLink}
                                test-id={`${testId}-left-arrow`}
                                disabled={slideIndex <= 0}>
                                <img
                                    src={
                                        slideIndex <= 0
                                            ? LeftArrow
                                            : LeftArrowActive
                                    }
                                    alt={t("industry.queryExamples")}
                                    className={classes.infoIcon}
                                />
                            </Link>
                        </div>
                        <div className={classes.dotsWrapp}>
                            {items &&
                                items.map((item, i) => (
                                    <span
                                        key={`${i}-slide-index`}
                                        className={classnames([
                                            classes.dot,
                                            {
                                                [classes.activeDot]:
                                                    i === slideIndex,
                                            },
                                        ])}></span>
                                ))}
                        </div>
                        <div className={classes.nextBtnWrapper}>
                            <Link
                                variant="body2"
                                iconRight
                                onClick={next}
                                className={classes.exampleStepperLink}
                                test-id={`${testId}-right-arrow`}
                                disabled={slideIndex >= items.length - 1}>
                                <img
                                    src={
                                        slideIndex >= items.length - 1
                                            ? RightArrow
                                            : RightArrowActive
                                    }
                                    alt={t("industry.queryExamples")}
                                    className={classes.infoIcon}
                                />
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default DashboardStepperCard;
