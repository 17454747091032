import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useRouterHook } from "../../common/useRouterHook";
// Components
import ToasterNotifications from "./ToasterNotifications";
import Sidebar from "./Sidebar";
// Hooks
import useStyles from "./styles";
import { useAuthHook } from "../../common/useAuthHook";
import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
// Redux
import { getLogoutStatusSelector } from "../../store/auth/selectors";
// Constants
import { REQUEST_SUCCESS } from "../../constants/statuses";
import { makeStyles } from "@material-ui/core/styles";

const Layout = ({ children, match, color }) => {
    const classes = useStyles();
    const { user } = useAuthHook();
    const logoutSelector = useSelector(getLogoutStatusSelector);
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    // const [openSidebar, setOpenSidebar] = useState(JSON.parse(localStorage.getItem('sidebarOpened')) || false);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [hoveredSidebar, setHoveredSidebar] = useState(false);

    useEffect(() => {
        if (
            localStorage.getItem("sidebarOpened") === null ||
            JSON.parse(localStorage.getItem("sidebarOpened")) !== openSidebar
        ) {
            localStorage.setItem("sidebarOpened", JSON.stringify(openSidebar));
        }
    }, [openSidebar]);

    useEffect(() => {
        if (logoutSelector === REQUEST_SUCCESS) {
            dispatch(mixpanelTrackRequest({ eventName: "Log out" }));
            openPage("/sign-in");
        }
    }, [logoutSelector]);

    const sidebarWidth = openSidebar ? "240px" : "68px";

    const contentStyles = makeStyles(theme => ({
        content: {
            flexGrow: 1,
            minHeight: "100vh",
            backgroundColor: theme.palette.grey.light,
            // position: "relative",
            width: `calc(100% - (${sidebarWidth} + 24px))`,
            marginLeft: `calc(${sidebarWidth} + 24px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            // marginTop: theme.spacing(6),
            [theme.breakpoint.down("md")]: {
                width: `calc(100% - 100px)`,
                marginLeft: `${sidebarWidth}`,
            },
            [theme.breakpoint.down("lg")]: {
                width: `calc(100% - 100px)`,
                marginLeft: `100px`,
            },
            // TODO layout break points smarter
            [theme.breakpoint.down("sm")]: {
                width: `calc(100% - 100px)`,
                marginLeft: `78px`,
            },
        },
        contentShift: {
            marginLeft: `${sidebarWidth}`,
            width: `calc(100% - ${sidebarWidth})`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoint.down("xl")]: {
                width: `calc(100% - ${sidebarWidth} - 30px)`,
                marginLeft: `${sidebarWidth}`,
            },
        },
    }));

    const classes2 = contentStyles();

    return (
        <div className={classes.root}>
            {!!user ? (
                <div>
                    <ToasterNotifications />
                    <Sidebar
                        match={match}
                        sidebarOpened={openSidebar}
                        sidebarHovered={hoveredSidebar}
                        toggleSidebar={() => setOpenSidebar(false)}
                        hoverSidebar={() => setHoveredSidebar(false)}
                    />
                    <main
                        className={classnames([
                            classes2.content,
                            {
                                [classes.contentWhite]: color === "white",
                                [classes2.contentShift]: openSidebar,
                            },
                        ])}>
                        {children}
                    </main>
                </div>
            ) : (
                children
            )}
        </div>
    );
};

Layout.propTypes = {
    color: PropTypes.oneOf(["normal", "white"]),
};

Layout.defaultProps = {
    color: "normal",
};

export default Layout;
