import React, { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { useStyles } from "./style.js";
import Button from "../../../../components/Button/Button.js";
import { ReactComponent as XIcon } from "../../../../assets/icons/CloseIcon.svg";

const Modal = ({ childData, getChip, modalStatus }) => {
    const classes = useStyles();
    const [activeChip, setActiveChip] = useState({
        label: "",
        value: "",
    });
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        if (getChip) {
            setActiveChip(getChip);
        }
    }, [getChip]);

    const examples = [
        {
            label: "Wearable technologies",
            value: "Closely examine startups leading in wearable technology, especially those revolutionizing health monitoring. Focus on their unique approaches to biometric tracking and how they stand out in the sports and fitness industry, enhancing athletic performance and fitness management.",
        },
        {
            label: "Cybersecurity",
            value: "Look for innovative companies that are developing cybersecurity solutions for cloud-based systems. Focus on those offering encryption technologies and how they are being implemented in IT security, particularly in data protection and privacy.",
        },
        {
            label: "Artificial Intelligence and Machine Learning",
            value: "Investigate which companies use artificial intelligence and machine learning to revolutionizing predictive analytics in the finance industry. Focus on applications like risk assessment, customer behavior analysis, and fraud detection, highlighting the effectiveness of these technologies and the viability of a subscription-based business model.",
        },
        {
            label: "Green Technology and Sustainability",
            value: "Search for startups or companies that are leading in green technology, specifically in solar energy solutions. Focus on those developing advanced solar panels and systems for residential and commercial energy generation in the renewable energy sector.",
        },
        {
            label: "Fintech and Blockchain",
            value: "Seek out fintech companies that are utilizing blockchain technology to secure financial transactions. Investigate their use of decentralized ledgers in banking and how these solutions are reshaping financial services and transactions.",
        },
        {
            label: "EdTech",
            value: "Find companies in the EdTech sector that are transforming online learning. Look for those that have developed AI-driven interactive learning platforms and digital classrooms, and explore their implementation in higher education and professional training.",
        },
        {
            label: "Virtual and Augmented Reality",
            value: "Find companies that are leveraging VR and AR technologies for training and education in healthcare. Explore their solutions for surgical training and medical education, and how these technologies are enhancing learning experiences.",
        },
        {
            label: "Smart Cities and Urban Technologies",
            value: "Search for companies involved in smart city projects, especially those focusing on smart traffic management systems. Investigate how they are using IoT and big data analytics to improve urban transportation and infrastructure.",
        },
        {
            label: "E-commerce and Social Commerce",
            value: "Seek out e-commerce companies that are innovating in customer experience and engagement. Focus on those integrating AI and machine learning in social commerce platforms, and explore how they are revolutionizing the retail industry.",
        },
    ];

    const handleModalSubmit = chip => {
        setIsOpen(false);
        childData(chip);
    };
    const handleChipClick = example => {
        if (example.label === activeChip.label) {
            return setActiveChip({ label: "", value: "" });
        }
        setActiveChip(example);
    };

    return (
        <Dialog
            classes={{
                root: classes.dialogRoot,
                paper: classes.dialog,
                scrollPaper: classes.dialogScrollPaper,
            }}
            open={isOpen}>
            <div className={[classes.close]} onClick={() => modalStatus(false)}>
                <XIcon />
            </div>

            <div className={[classes.title]}>Scope Examples</div>
            <div className={[classes.description]}>
                For inspiration to how the Scope prompting can be written for
                PUMA, please press any of the buttons below to see an example of
                an already constructed prompt. From here, you are welcome to use
                the prompt example to have PUMA generate a scope into the
                database, to see the results you can get. From there, you can
                use PUMA to deep dive further into the results, or you can copy
                parts / structural elements from the prompt, and create your own
                custom Scope prompt.
            </div>
            <div className={[classes.examples]}>
                {examples.map(example => (
                    <div
                        key={example.label}
                        onClick={() => handleChipClick(example)}
                        className={[
                            example.label === activeChip.label
                                ? classes.activeChip
                                : classes.chip,
                        ]}>
                        {example.label}
                    </div>
                ))}
            </div>
            <div className={[classes.divide]} />
            {activeChip.label && (
                <div className={[classes.selectedWrapper]}>
                    <div>
                        <div className={[classes.selectedTitle]}>
                            {activeChip.label}
                        </div>
                        <div className={[classes.selectedDescription]}>
                            {activeChip.value}
                        </div>
                    </div>
                    <div className={[classes.button]}>
                        <Button
                            className={[classes.button]}
                            variant="primary"
                            disabled={false}
                            onClick={() => handleModalSubmit(activeChip)}
                            // loading={requestPending}
                        >
                            Use Example
                        </Button>
                    </div>
                </div>
            )}
        </Dialog>
    );
};

export default Modal;
