import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    getInvoicesStatus: REQUEST_UNDEFINED,
    pool: [],
};
export const REDUCER_PREFIX = "billing";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getInvoicesPending: state => {
            state.getInvoicesStatus = REQUEST_PENDING;
        },
        getInvoicesSuccess: state => {
            state.getInvoicesStatus = REQUEST_SUCCESS;
        },
        getInvoicesFailure: state => {
            state.getInvoicesStatus = REQUEST_FAILURE;
        },
        setInvoices: (state, { payload }) => {
            state.pool = payload;
            state.getInvoicesStatus = REQUEST_UNDEFINED;
        },
    },
});
export { reducer, actions };
