import { all, takeEvery } from "redux-saga/effects";
import { getGenerateClusterDataRequest } from "../requests";
import { getGenerateClusterData } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchGetGenerateClusterData() {
    yield takeEvery(
        convertTypeFromAction(getGenerateClusterDataRequest),
        ({ payload: { data } }) => getGenerateClusterData(data),
    );
}

function* saga() {
    yield all([watchGetGenerateClusterData()]);
}

export { saga };
