import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAuthHook } from "../../../../../common/useAuthHook";
import {
    addCommentRequest,
    deleteCommentRequest,
    updateCommentRequest,
    voteCommentRequest,
} from "../../../../../store/companyProfile/requests";
import {
    getAddCommentStatusSelector,
    getDeleteCommentStatusSelector,
    getProfileCommentsSelector,
    getUpdateCommentStatusSelector,
    getVoteCommentSelector,
    getGetCommentsStatusSelector,
} from "../../../../../store/companyProfile/selectors";

export const useCommentsHook = ({ company }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [newCommentText, setNewCommentText] = useState("");
    const [editCommentText, setEditCommentText] = useState({});
    const [replyCommentText, setReplyCommentText] = useState({});
    const commentsSelector = useSelector(getProfileCommentsSelector);
    const [comments, setComments] = useState(commentsSelector);
    const addCommentStatus = useSelector(getAddCommentStatusSelector);
    const updateCommentStatus = useSelector(getUpdateCommentStatusSelector);
    const deleteCommentStatus = useSelector(getDeleteCommentStatusSelector);
    const voteCommentStatus = useSelector(getVoteCommentSelector);
    const getCommentsSelector = useSelector(getGetCommentsStatusSelector);
    const { user } = useAuthHook();
    const [commentEdit, setCommentEdit] = useState({});
    const [commentReply, setCommentReply] = useState({});

    const handleNewCommentText = event => {
        setNewCommentText(event.target.value);
    };
    const handleNewCommentAdd = event => {
        if (event.key === "Enter") {
            let data = new FormData();
            data.append("comment", event.target.value);
            data.append("cid", company.id);
            dispatch(addCommentRequest({ data }));
        }
    };

    const handleNewCommentReply = (commentId, commentText) => {
        let data = new FormData();
        data.append("comment-id", commentId);
        data.append("comment", commentText);
        data.append("cid", company.id);

        dispatch(addCommentRequest({ data }));
    };
    const editComment = (id, replyId, value) => {
        setCommentEdit(prev => {
            let commentReplyClone = Object.assign({}, prev);
            if (!replyId) {
                return {
                    ...commentReplyClone,
                    [id]: {
                        ...comments[id],
                        edit: true,
                    },
                };
            } else {
                return {
                    ...commentReplyClone,
                    [id]: {
                        ...comments[id],
                        replies: {
                            ...comments[id].replies,
                            [replyId]: {
                                ...comments[id].replies[replyId],
                                edit: true,
                            },
                        },
                    },
                };
            }
        });
        setEditCommentText(prev => {
            let commentReplyClone = Object.assign({}, prev);
            if (!replyId) {
                return {
                    ...commentReplyClone,
                    [id]: {
                        ...comments[id],
                        text: value,
                    },
                };
            } else {
                return {
                    ...commentReplyClone,
                    [id]: {
                        ...comments[id],
                        replies: {
                            ...comments[id].replies,
                            [replyId]: {
                                ...comments[id].replies[replyId],
                                text: value,
                            },
                        },
                    },
                };
            }
        });
    };

    const replyComment = (commentId, replyId) => {
        let commentReplyClone = Object.assign({}, comments);
        setCommentReply(prev => {
            if (!replyId) {
                return {
                    ...commentReplyClone,
                    [commentId]: {
                        ...comments[commentId],
                        reply: true,
                        textChange: "",
                    },
                };
            } else {
                return {
                    ...commentReplyClone,
                    [commentId]: {
                        ...comments[commentId],
                        replies: {
                            ...comments[commentId].replies,
                            [replyId]: {
                                ...comments[commentId].replies[replyId],
                                reply: true,
                                textChange: "",
                            },
                        },
                    },
                };
            }
        });
        setReplyCommentText(prev => {
            if (!replyId) {
                return {
                    ...commentReplyClone,
                    [commentId]: {
                        ...comments[commentId],
                        reply: true,
                        textChange: "",
                    },
                };
            } else {
                return {
                    ...commentReplyClone,
                    [commentId]: {
                        ...comments[commentId],
                        replies: {
                            ...comments[commentId].replies,
                            [replyId]: {
                                ...comments[commentId].replies[replyId],
                                reply: true,
                                textChange: "",
                            },
                        },
                    },
                };
            }
        });
    };

    const deleteComment = id => {
        let data = new FormData();
        data.append("comment-id", id);
        data.append("cid", company.id);
        window.confirm(t("companyComments.areYouSure")) &&
            dispatch(deleteCommentRequest({ data }));
    };

    const cancelEditComment = id => {
        setCommentEdit(prev => {
            let commentReplyClone = Object.assign({}, prev);
            return {
                ...commentReplyClone,
                [id]: {
                    ...comments[id],
                    edit: false,
                },
            };
        });
    };

    const cancelReplyComment = id => {
        setCommentReply(prevCommentReply => ({
            ...prevCommentReply,
            [id]: false,
        }));
    };

    const saveComment = (id, replyId) => {
        let data = new FormData();
        data.append("comment-id", !replyId ? id : replyId);
        data.append(
            "comment",
            !replyId
                ? editCommentText[id].text
                : editCommentText[id].replies[replyId].text,
        );
        data.append("cid", company.id);

        dispatch(updateCommentRequest({ data }));
    };

    const handleCommentTextChange = (event, commentId, replyId) => {
        setEditCommentText(prev => {
            let commentReplyClone = Object.assign({}, prev);
            if (!replyId) {
                return {
                    ...commentReplyClone,
                    [commentId]: {
                        ...comments[commentId],
                        text: event.target.value,
                    },
                };
            } else {
                return {
                    ...commentReplyClone,
                    [commentId]: {
                        ...comments[commentId],
                        replies: {
                            ...comments[commentId].replies,
                            [replyId]: {
                                ...comments[commentId].replies[replyId],
                                text: event.target.value,
                            },
                        },
                    },
                };
            }
        });
    };

    const handleReplyTextChange = (event, commentId, replyId) => {
        setReplyCommentText(prev => {
            let commentReplyClone = Object.assign({}, prev);
            if (!replyId) {
                return {
                    ...commentReplyClone,
                    [commentId]: {
                        ...comments[commentId],
                        textChange: event.target.value,
                    },
                };
            } else {
                return {
                    ...commentReplyClone,
                    [commentId]: {
                        ...comments[commentId],
                        replies: {
                            ...comments[commentId].replies,
                            [replyId]: {
                                ...comments[commentId].replies[replyId],
                                textChange: event.target.value,
                            },
                        },
                    },
                };
            }
        });
    };

    const handleLikeComment = comment => {
        if (user) {
            let data = new FormData();
            data.append("comment_id", comment.id);
            data.append("vote", 1);
            data.append("cid", company.id);

            dispatch(voteCommentRequest({ data }));
        }
    };

    const handleDislikeComment = comment => {
        if (user) {
            let data = new FormData();
            data.append("comment_id", comment.id);
            data.append("vote", 0);
            data.append("cid", company.id);

            dispatch(voteCommentRequest({ data }));
        }
    };
    return {
        dispatch,
        comments,
        newCommentText,
        replyCommentText,
        addCommentStatus,
        updateCommentStatus,
        deleteCommentStatus,
        voteCommentStatus,
        commentEdit,
        commentReply,
        handleNewCommentText,
        handleNewCommentAdd,
        handleNewCommentReply,
        editComment,
        setEditCommentText,
        replyComment,
        setReplyCommentText,
        deleteComment,
        cancelEditComment,
        cancelReplyComment,
        saveComment,
        handleCommentTextChange,
        handleReplyTextChange,
        handleLikeComment,
        handleDislikeComment,
        setCommentEdit,
        setCommentReply,
        setNewCommentText,
        user,
        t,
        editCommentText,
        setComments,
        commentsSelector,
        getCommentsSelector,
    };
};
