import { useDispatch } from "react-redux";
import { actions as searchActions } from "../../store/search/slice";
import classnames from "classnames";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style.js";
import { useRouterHook } from "../../common/useRouterHook";
import { useAppEventTracking } from "../../common/useAppEventTracking";
// UI components
import Box from "@material-ui/core/Box";
import Typography from "../../components/Typography/Typography";
//Assets
// import Product1 from '../../assets/images/Product1.png';
// import Product2 from '../../assets/images/Product2.png';
import Product3 from "../../assets/images/Product3.png";
import Product4 from "../../assets/images/Product4.png";
import Button from "../Button/Button";
// import Product5 from '../../assets/images/Product5.png';

const SelectProducts = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const isDesktop = window.screen.width >= 1440;
    const { mixpanelTrackEvent } = useAppEventTracking();

    const selectProduct = product => {
        dispatch(searchActions.setProduct(product));
        mixpanelTrackEvent("Search type selection", {
            "User input":
                product === 1
                    ? t("outcome.findCompaniesTitle")
                    : product === 2
                    ? t("outcome.product2Title")
                    : product === 3
                    ? t("outcome.product3Title")
                    : product === 4
                    ? t("outcome.product4Title")
                    : t("outcome.product5Title"),
        });
        setTimeout(() => {
            openPage(
                product === 1
                    ? "/description"
                    : product === 2
                    ? "/search-look-alike"
                    : product === 3
                    ? "/industries?page=1"
                    : product === 4
                    ? "/technologies?page=1"
                    : "/search-patent",
            );
        }, 200);
    };

    return (
        <Box display="flex" className={classes.productsWrapper}>
            <Box
                onClick={() => selectProduct(3)}
                className={classnames([classes.box, classes.product1Box])}>
                {!isDesktop && (
                    <div
                        className={classnames([
                            classes.productImageWrap,
                            classes.product2ImageWrap,
                        ])}>
                        <img
                            src={Product3}
                            alt={t("outcome.product3Title")}
                            className={classes.boxIcon}
                        />
                    </div>
                )}
                <div>
                    <Typography
                        component="div"
                        variant="subtitle1"
                        color="black"
                        className={classes.outcomeCardTitle}>
                        {t("outcome.product3Title")}
                    </Typography>
                    <Typography
                        variant="bodyButton"
                        color="textLighter"
                        gutterBottom
                        className={classes.outcomeCardSubtitle}>
                        {t("outcome.trendsLabel")}
                    </Typography>
                    <Button
                        variant="quaternary"
                        className={classes.productButton}>
                        Explore industries
                    </Button>
                </div>
                {isDesktop && (
                    <div className={classes.productImageWrap}>
                        <img
                            src={Product3}
                            alt={t("outcome.product3Title")}
                            className={classes.boxIcon}
                        />
                    </div>
                )}
            </Box>
            <Box
                onClick={() => selectProduct(4)}
                className={classnames([classes.box, classes.product2Box])}>
                {!isDesktop && (
                    <div
                        className={classnames([
                            classes.productImageWrap,
                            classes.product2ImageWrap,
                        ])}>
                        <img
                            src={Product4}
                            alt={t("outcome.product4Title")}
                            className={classes.boxIcon}
                        />
                    </div>
                )}
                <div>
                    <Typography
                        component="div"
                        variant="subtitle1"
                        color="black"
                        className={classes.outcomeCardTitle}>
                        {t("outcome.product4Title")}
                    </Typography>
                    <Typography
                        variant="bodyButton"
                        color="textLighter"
                        gutterBottom
                        className={classes.outcomeCardSubtitle}>
                        {t("outcome.product4Label")}
                    </Typography>
                    <Button
                        variant="quaternary"
                        className={classes.productButton}>
                        Explore technologies
                    </Button>
                </div>
                {isDesktop && (
                    <div className={classes.productImageWrap}>
                        <img
                            src={Product4}
                            alt={t("outcome.product4Title")}
                            className={classes.boxIcon}
                        />
                    </div>
                )}
            </Box>
        </Box>
    );
};
export default SelectProducts;
