import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonTwo from "../../../components/Button/Button.js";
import classnames from "classnames";
import {
    Button,
    TextareaAutosize,
    Tooltip,
    Typography,
} from "@material-ui/core";
import ButtonSecondary from "../../../components/Button/Button.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from "@material-ui/core/Dialog";
import Grow from "@material-ui/core/Grow";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useSearches } from "../../../common/useSearches.js";

import { useStyles } from "./style.js";
import { ReactComponent as NewPumaLogo } from "../../../assets/icons/MangaPuma.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash.svg";
import { ReactComponent as MagnifyingGlass } from "../../../assets/icons/magnifying-glass.svg";
import { ReactComponent as Plus } from "../../../assets/icons/Plus.svg";
import { ReactComponent as QuestionMarkTooltip } from "../../../assets/icons/question-mark-tooltip.svg";
import { ReactComponent as ScopeIcon } from "../../../assets/icons/ScopeIcon.svg";
import { ReactComponent as LookAlikeIcon } from "../../../assets/icons/LookalikeSearchIcon.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/CloseIcon.svg";

import Modal from "./Modal/Modal.js";
import LookAlike from "./LookAlike.js";
import MultiSelectSort from "./Select/Select.js";
import { regionsOptions } from "./data.js";
import { getCountriesSelector } from "../../../store/countries/selectors.js";
import { actions as searchActions } from "../../../store/search/slice";
import { getSearchLocationSelector } from "../../../store/search/selectors.js";
import { useHistory, useLocation } from "react-router-dom";

import { fetchSectorsRequest } from "../../../store/sectors/requests.js";
import usePrevious from "../../../common/usePrevious.js";
import { getCountriesRequest } from "../../../store/countries/requests.js";
import { fetchSubsectorsRequest } from "../../../store/sectors/requests.js";
import { useSectorsHook } from "../hooks/useSectorsHook.js";
import { getSubsectorsSelector } from "../../../store/sectors/selectors.js";
import {
    postSetSearchCountRequest,
    submitSearchRequest,
} from "../../../store/search/requests.js";
import DeleteSearchDialog from '../../Results/components/PreviousScopes/components/DeleteSearchDialog';
import LoadScopeDialog from '../../Results/components/PreviousScopes/components/LoadScopeDialog';

const NewSearch = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        getRecentSearches: {
            data: searches,
        },
        deleteSearch
    } = useSearches();
    const { allSectors } = useSectorsHook();
    const searchLocation = useSelector(getSearchLocationSelector);
    const subsectors = useSelector(getSubsectorsSelector);
    const textAreaRef = useRef(null);
    const lookalikeRef = useRef(null);
    const classes = useStyles();
    const [regionsChanged, setRegionsChanged] = useState(false);
    const [countriesChanged, setCountriesChanged] = useState(false);
    const { pathname } = useLocation();
    const [selectedIndustryHistory, setSelectedIndustryHistroy] = useState([]);
    const [filterMenuOpen, setFilterMenuOpen] = useState(false);
    const [activeScope, setActiveScope] = useState(1);
    const [scopeValue, setScopeValue] = useState("");
    const [lookalikeValue, setLookalikeValue] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getChip, setChip] = useState();
    const countryMenuItems = useSelector(getCountriesSelector);
    const [filteredRegionMenuItems, setFilteredRegionMenuItems] = useState();
    const [filteredIndustriesMenuItems, setFIlteredIndustriesMenuItems] =
        useState();
    const [selectedSubindustries, setSelectedSubindustries] = useState([]);
    const [subsectorsValue, setSubsectorsValue] = useState(subsectors);
    const [regionMenuItems, setRegionMenuItems] = useState(regionsOptions);
    const [industriesMenuItems, setIndustriesMenuItems] = useState(allSectors);
    const [regionsInputValue, setRegionsInputValue] = useState("");
    const [regionsMenuOpened, setRegionsMenuOpened] = useState(false);
    const [countriesInputValue, setCountriesInputValue] = useState("");
    const [filteredCountryMenuItems, setFilteredCountryMenuItems] = useState();
    const [countriesMenuOpened, setCountriesMenuOpened] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(undefined);
    const [errorMsg, setErrorMsgs] = useState(null);
    const [selectedIndustriesInfo, setSelectedIndustriesInfo] = useState([]);
    const [activeSubsectors, setActiveSubsectors] = useState([]);
    const [previewScopes, setPreviewScopes] = useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [openDeleteSearchModal, setOpenDeleteSearchModal] = useState(false);
    const [selectedScope, setSelectedScope] = useState(null);

    const transfromToLowerWithDashes = string => {
        if (typeof string === "string") {
            return string.toLowerCase().replaceAll(" ", "-");
        }
        return string;
    };

    const [selectedRegions, setSelectedRegions] = useState(
        searchLocation
            ? typeof searchLocation === "string"
                ? JSON.parse(searchLocation).regions
                : searchLocation.regions
            : [],
    );
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [selectedIndustriesValue, setSelectedIndustriesValue] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState(
        searchLocation
            ? typeof searchLocation === "string"
                ? JSON.parse(searchLocation).countries
                : searchLocation.countries
            : [],
    );
    const [selectedCountriesValue, setSelectedCountriesValue] = useState([]);

    const prevSelectedCountries = usePrevious(selectedCountries);
    const childData = example => {
        setActiveScope(1);
        setScopeValue(example.value);
        setIsModalOpen(false);
        setChip(example);
    };

    const handleMenuSearch = type => {
        setRegionsMenuOpened(false);
        setCountriesMenuOpened(false);
        typingTimeout !== undefined &&
            clearTimeout(typingTimeout) &&
            setTypingTimeout(undefined);
        setTypingTimeout(
            setTimeout(() => {
                filterMenu(type);
                setTypingTimeout(undefined);
            }, 750),
        );
    };

    const handleSubmitSearch = e => {
        // mixpanelTrackEvent("Company search", {
        //     Page: page,
        //     Regions: selectedRegions,
        //     Countries: selectedCountries,
        //     Industries: selectedSectors.map(sector => sector.name),
        //     Subindustries: selectedSubsectors,
        //     EditMode: !!isEditMode,
        //     Type: "Regular",
        // });
        handleCaptchaChange();

        dispatch(searchActions.setEditQueryClean());
    };
    useEffect(() => {
        if (selectedIndustries.length === 0) {
            setActiveSubsectors([]);
            setSelectedSubindustries([]);
            setSelectedIndustryHistroy();
            setSubsectorsValue([]);
        }
        if (selectedRegions.length === 0) {
            setFilteredCountryMenuItems();
            setSelectedCountries([]);
            setSelectedCountriesValue([]);
        }
    }, [selectedRegions, selectedIndustries]);

    const handleClick = () => {
        handleSubmitSearch()
        // alert(handleClick)
    }

    const filterMenu = type => {
        if (type === "region") {
            regionsInputValue.length
                ? setFilteredRegionMenuItems(
                      regionMenuItems.filter(item =>
                          item.name
                              .toLowerCase()
                              .startsWith(regionsInputValue.toLowerCase()),
                      ),
                  )
                : setFilteredRegionMenuItems(null);
            setRegionsMenuOpened(true);
        }
        if (type === "country") {
            countriesInputValue.length
                ? setFilteredCountryMenuItems(
                      countryMenuItems.filter(item =>
                          item.name
                              .toLowerCase()
                              .startsWith(countriesInputValue.toLowerCase()),
                      ),
                  )
                : setFilteredCountryMenuItems(null);
            setCountriesMenuOpened(true);
        }
    };

    const handleTextAreaClick = () => {
        setActiveScope(1);
    };
    const modalStatus = modalStatus => {
        setIsModalOpen(modalStatus);
    };

    const handleActiveScope = () => {
        textAreaRef.current?.focus();
        setActiveScope(1);
    };

    const handleRegionSelect = arrayOfRegion => {
        const parsedSelected = [];
        const selected = [];
        arrayOfRegion.filter(region => {
            selected.push(region.label);
            parsedSelected.push({
                label: region.label,
                value: transfromToLowerWithDashes(region.label),
            });
        });
        setSelectedRegions(selected);
        setRegionsChanged(true);
        dispatch(searchActions.updateRegions(selected));
    };

    const handleCaptchaChange = () => {
        let data;
        if(selectedScope){
            data = {
                description: selectedScope.description,
                searchSectors: selectedIndustriesInfo,
                location: {
                    regions: selectedRegions[0],
                    countries: selectedCountriesValue,
                },
                scope: selectedScope.scope,
                searchType: activeScope,
                perPage: 100,
            };
        } else {
            data = {
                description: scopeValue,
                searchSectors: selectedIndustriesInfo,
                location: {
                    regions: selectedRegions[0],
                    countries: selectedCountriesValue,
                },
                scope: "Find companies that use specific technologies",
                searchType: activeScope,
                perPage: 100,
            };
        }
        dispatch(postSetSearchCountRequest());
        dispatch(
            submitSearchRequest({
                description: data.description,
                searchSectors: data.searchSectors,
                location: {
                    regions: data.location.regions,
                    countries: data.location.countries,
                },
                scope: data.scope,
                searchType: data.searchType,
                perPage: 50,
            }),
        );
        setTimeout(() => {
            history.replace("/search-results-loading", {
                from: pathname,
                someOtherProp: "",
            });
        }, 200);
    };

    const handleCountrySelect = arrayOfCountries => {
        const parsedSelected = [];
        const selected = [];
        arrayOfCountries.filter(country => {
            selected.push(country.label);
            parsedSelected.push({
                label: country.label,
                value: transfromToLowerWithDashes(country.label),
            });
        });

        setSelectedCountries(selected);
        setSelectedCountriesValue(selected);
        setCountriesChanged(true);
        dispatch(searchActions.updateCountries(selected));
    };

    const handleIndustriesSelect = arrayOfIndustries => {
        let selected = [];
        let parseSelected = [];
        let objectedArray = [];
        let parseArray = [];
        arrayOfIndustries.filter(country => {
            selected.push(country.value);
            parseSelected.push(country.value);
            objectedArray.push({ name: country.label, slug: country.value });
            parseArray.push({
                name: country.label,
                slug: country.value,
                subsectors: [],
            });
        });
        setSelectedIndustriesInfo(parseArray);
        setSelectedIndustries(selected);
        setSelectedIndustriesValue(objectedArray);
        setSelectedIndustryHistroy(selectedIndustries);
        dispatch(
            fetchSubsectorsRequest(parseSelected[parseSelected.length - 1]),
        );
        dispatch(searchActions.addSectors(parseSelected));
    };

    useEffect(() => {
        setSubsectorsValue(subsectors);
    }, [subsectors]);

    const handleSubindustrySelect = arrayOfSubindustries => {
        const newSearchArray = [];
        arrayOfSubindustries.map(ele => {
            const findParent = selectedIndustriesInfo.find(
                ind => ind.name === ele.industryLabel,
            );
            if (findParent.subsectors.length >= arrayOfSubindustries.length) {
                findParent.subsectors = [];
            }
            const isSubsectorInside = findParent.subsectors.find(
                sub => sub.name === ele.label,
            );
            if (!isSubsectorInside) {
                const newObj = {
                    name: findParent.name,
                    slug: findParent.slug,
                    subsectors: findParent.subsectors.push({
                        name: ele.label,
                        slug: ele.value,
                    }),
                };
                newSearchArray.push(newObj);
            }
        });
        setSelectedSubindustries(arrayOfSubindustries);
    };

    useEffect(() => {
        let newSubsectors = [];
        if (selectedIndustryHistory?.length < selectedIndustries?.length) {
            subsectors.map(ss => {
                newSubsectors.push({
                    label: ss.name,
                    value: ss.slug,
                    industry: selectedIndustries[selectedIndustries.length - 1],
                    industryLabel:
                        selectedIndustriesInfo[
                            selectedIndustriesInfo.length - 1
                        ].name,
                });
            });

            if (activeSubsectors.length === 0) {
                return setActiveSubsectors(newSubsectors);
            } else {
                return setActiveSubsectors([
                    ...newSubsectors,
                    ...activeSubsectors,
                ]);
            }
        } else {
            const removedIndustry = selectedIndustryHistory?.filter(
                element => !selectedIndustries.includes(element),
            )[0];
            return setActiveSubsectors(
                activeSubsectors.filter(
                    ele => ele.industry !== removedIndustry,
                ),
            );
        }
    }, [subsectors]);

    useEffect(() => {
        dispatch(
            getCountriesRequest({
                regions: selectedRegions || [],
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (activeScope === 1) {
            textAreaRef.current?.focus();
        }
        // dispatch(getCountriesRequest({ regions: ["Africa"] }));
    }, [activeScope, regionsChanged]);

    useEffect(() => {
        if (searchLocation) {
            if (typeof searchLocation === "string") {
                setSelectedRegions([JSON.parse(searchLocation).region]);
                setSelectedCountries([JSON.parse(searchLocation).countries]);
            } else {
                setSelectedRegions([searchLocation.regions]);
                setSelectedCountries([searchLocation.countries]);
            }
        }
    }, [searchLocation]);

    useEffect(() => {
        if (
            filteredRegionMenuItems &&
            filteredRegionMenuItems.length === 0 &&
            regionsInputValue.length !== 0
        ) {
            setErrorMsgs({ region: "No regions found by that name" });
        } else if (
            filteredCountryMenuItems &&
            filteredCountryMenuItems.length === 0 &&
            countriesInputValue.length !== 0
        ) {
            setErrorMsgs({ country: "No countries found by that name" });
        } else {
            setErrorMsgs(null);
        }
    }, [filteredRegionMenuItems, filteredCountryMenuItems]);
    useEffect(() => {
        if (selectedRegions && selectedRegions.length) {
            dispatch(
                getCountriesRequest({
                    regions: selectedRegions,
                }),
            );
            // clear selected countries on region deselect
            regionsChanged &&
                setSelectedCountries(
                    selectedCountries.filter(item =>
                        countryMenuItems.map(x => x.name).includes(item),
                    ),
                );
        } else {
            setSelectedCountries([]);
            setFilteredCountryMenuItems(null);
            setCountriesInputValue("");
            setErrorMsgs(null);
        }
    }, [selectedRegions]);

    useEffect(() => {
        // clear selected countries on region deselect
        regionsChanged &&
            setSelectedCountries(
                selectedCountries.filter(item =>
                    countryMenuItems.map(x => x.name).includes(item),
                ),
            );
    }, [countryMenuItems]);

    useEffect(() => {
        countriesChanged &&
            JSON.stringify(prevSelectedCountries) !==
                JSON.stringify(selectedCountries) &&
            dispatch(searchActions.updateCountries(selectedCountries));
    }, [selectedCountries, prevSelectedCountries]);

    // useEffect(() => {
    //     if (currentSearch) {
    //         dispatch(searchActions.setIndustryDesc(currentSearch.description));
    //         currentSearch.location
    //             ? dispatch(
    //                   searchActions.updateLocation(
    //                       JSON.parse(currentSearch.location),
    //                   ),
    //               )
    //             : dispatch(searchActions.clearLocation());
    //         currentSearch.sectors
    //             ? dispatch(searchActions.addSectors(currentSearch.sectors))
    //             : dispatch(searchActions.removeSearchSectors());
    //     }
    // }, [currentSearch]);

    useEffect(() => {
        dispatch(fetchSectorsRequest());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCountries && selectedCountries) {
            dispatch(searchActions.setEditQueryClean());
        } else {
            dispatch(searchActions.setEditQueryDirty());
        }
    }, [selectedRegions, selectedCountries]);

    const loadScope = () => {
        handleSubmitSearch();
    }

    const handleRemoveSearch = () => {
        if(selectedScope){
            deleteSearch.mutate(selectedScope.id)
        }
    }

    return (
        <div className={classnames([classes.mainWrapper])}>
            {isModalOpen && (
                <Modal
                    childData={childData}
                    modalStatus={modalStatus}
                    getChip={getChip}
                />
            )}

            <div className={classnames([classes.titleWrapper])}>
                <div className={classnames([classes.image])}>
                    <NewPumaLogo className={classes.newPumaLogo} />
                </div>
                <div className={classnames([classes.titleTextWrapper])}>
                    <Typography
                        variant="h6"
                        className={classnames([classes.title])}>
                        PUMA <strong>Scope</strong>
                    </Typography>
                </div>
                <div className={classnames([classes.scopePreviewButtons])}>

                {/*<ButtonTwo*/}
                {/*    className={classes.getSummaryBtn}*/}
                {/*    onClick={() => setPreviewScopes(!previewScopes)}*/}
                {/*    variant="quaternary"*/}
                {/*>*/}
                {/*    {previewScopes ? "New Scope" : "Previous Scopes"}*/}
                {/*</ButtonTwo>*/}
                </div>
            </div>
            {!previewScopes ? 
            <div className={classnames([classes.contentWrapper])}>
                <div className={classnames([classes.searchWrapper])}>
                    <div
                        style={{ position: "relative", zIndex: 10 }}
                        className={classnames([classes.scope])}
                        onClick={() => handleTextAreaClick}>
                        <div
                            className={classnames([classes.scopeTitleWrapper])}>
                            <div
                                className={classnames([classes.promptTitle])}
                                onClick={() => handleActiveScope()}>
                                <div
                                    className={classnames([
                                        classes.prompt,
                                        activeScope === 1 &&
                                            classes.activePrompt,
                                    ])}>
                                    <div
                                        className={classnames([
                                            classes.subPrompt,
                                            activeScope === 1 &&
                                                classes.subActivePrompt,
                                        ])}
                                    />
                                </div>
                                <Typography
                                    className={classnames([
                                        classes.scopeTitle,
                                    ])}>
                                    By Prompt <ScopeIcon />
                                </Typography>
                            </div>
                            <Button
                                className={classes.exampleButton}
                                // disabled={false}
                                onClick={() => setIsModalOpen(true)}>
                                Prompt Examples
                            </Button>
                            <div  className={classnames([classes.tooltipWrapper])}>
                            <Tooltip
                                title={
                                    <Typography
                                        color="white"
                                        variant="body2"
                                        className={classes.infoTooltipText}>
                                        Scope with description. Please type a
                                        description of 1-2 sentences on how PUMA
                                        should scope its deep dive into the
                                        database. Consider aspects such as
                                        company product, technology, industry,
                                        and application context of the solution.
                                        {/* Download scope description as pdf See as
                                        video */}
                                    </Typography>
                                }
                                arrow>
                                <QuestionMarkTooltip
                                    className={classnames([classes.tooltip])}
                                />
                            </Tooltip>
                            </div>
                        </div>
                        <TextareaAutosize
                            style={{
                                background:
                                    activeScope === 1
                                        ? "#FFFFFF"
                                        : "transparent",
                            }}
                            ref={textAreaRef}
                            readonly={activeScope !== 1}
                            minRows={3}
                            className={classes.scopeTextarea}
                            value={scopeValue}
                            onMouseDown={() => setActiveScope(1)}
                            onChange={e => {
                                setActiveScope(1);
                                setScopeValue(e.target.value);
                            }}
                            // placeholder={t("searchPatent.textareaPlaceholder")}
                            placeholder="Please create a 1-2 sentence description of how you want PUMA to scope your entry into our database."
                        />
                        <div
                            className={classnames([
                                classes.scopeDescriptionWrapper,
                            ])}>
                            {/* <div
                                className={classnames([
                                    classes.scopeDescription,
                                ])}>
                                Describe what kind of companies you're looking
                                for. Include specific details like technologies,
                                products, use cases, etc
                            </div> */}
                        </div>
                    </div>
                    {/* <div className={classnames([classes.divider])} /> */}
                    <div
                        className={classnames([classes.lookalikeWrapper])}
                        onClick={() => setActiveScope(2)}>
                        <div
                            className={classnames([
                                classes.lookalikeTitleWrapper,
                            ])}>
                            <div className={classnames([classes.promptTitle])}>
                                <div
                                    className={classnames([
                                        classes.prompt,
                                        activeScope === 2 &&
                                            classes.activePrompt,
                                    ])}>
                                    <div
                                        className={classnames([
                                            classes.subPrompt,
                                            activeScope === 2 &&
                                                classes.subActivePrompt,
                                        ])}
                                    />
                                </div>
                                <div
                                    className={classnames([
                                        classes.lookalikeTitle,
                                    ])}>
                                    By Look-alike <LookAlikeIcon style={{ marginLeft: "10px", color: "#007A5E"}} />
                                </div>
                            </div>
                            <Tooltip
                                title={
                                    <Typography
                                        color="white"
                                        variant="body2"
                                        className={classes.infoTooltipText}>
                                        Scope by company similarity. Please type
                                        the name of a company, by which you wish
                                        PUMA to deep dive into the database, on
                                        other companies with similar attributes,
                                        such as products, services, business
                                        models, technologies, etc.
                                    </Typography>
                                }
                                arrow>
                                <QuestionMarkTooltip
                                    className={classnames([classes.tooltip])}
                                />
                            </Tooltip>
                        </div>
                        {/* <input
                            className={classnames([classes.lookalikeInput])}
                            placeholder={"Search look-alike"}
                            disabled={activeScope !== 2}
                        /> */}
                        <LookAlike
                            setLookalikeValue={setLookalikeValue}
                            setActiveScope={() => setActiveScope(2)}
                            ref={lookalikeRef}
                            isDisabled={activeScope !== 2}
                        />
                    </div>
                    {/* <div
                            className={classnames([
                                classes.lookalikeDescription,
                            ])}>
                            Enter the name of your Lookalike Company and get
                            matching company results.
                        </div> */}
                </div>
                <div className={classnames([classes.filterWrapper])}>
                    <div className={classnames([classes.filterTitleWrapper])}>
                        <div
                            className={classnames([classes.filterTitlesBox])}
                            onClick={() => setFilterMenuOpen(!filterMenuOpen)}>
                            <Plus
                                className={classnames([
                                    filterMenuOpen ||
                                    (selectedRegions[0] &&
                                        selectedRegions[0].length) ||
                                    selectedIndustriesValue.length
                                        ? classes.activePlus
                                        : classes.plus,
                                ])}
                            />
                            <div className={classnames([classes.fitlerTitle])}>
                                Add filter
                            </div>
                            {/* <div
                                className={classnames([
                                    classes.fitlerDescription,
                                ])}>
                                Duis aute irure dolor in reprehenderit in
                                voluptate velit esse cillum dolore eu fugiat
                                nulla pariatur. Excepteur sint occaecat
                                cupidatat non proident.
                            </div> */}
                        </div>
                        <div>
                            <Tooltip
                                title={
                                    <Typography
                                        color="white"
                                        variant="body2"
                                        className={classes.infoTooltipText}>
                                        Please utilize the filtering option, if
                                        you want PUMA to exclusively scope
                                        within certain parameters of regions,
                                        countries or industries. Filtering as
                                        part of your scoping will inform PUMA
                                        and often increases your hit rate
                                        noticeably. <br />
                                        <br />
                                        Both the region and industry filters
                                        have two stages. Choosing a region
                                        allows you to specify any countries
                                        within the region. The same goes for
                                        industries into sub-industries.
                                    </Typography>
                                }
                                arrow>
                                <QuestionMarkTooltip
                                    className={classnames([classes.tooltip])}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div
                        style={{
                            height: filterMenuOpen ? "auto" : "0px",
                            opacity: filterMenuOpen ? 1 : 0,
                            paddingBottom: filterMenuOpen ? 30 : 0,
                        }}
                        className={classnames([classes.inputsWrapper])}>
                        <div
                            className={classnames([
                                classes.inputsCountryWrapper,
                            ])}>
                            <div
                                onKeyUp={() => handleMenuSearch("region")}
                                style={{
                                    width: "100%",
                                    maxWidth: "330px",
                                }}>
                                <MultiSelectSort
                                    options={
                                        filteredRegionMenuItems ||
                                        regionMenuItems
                                    }
                                    placeholder="Region"
                                    onChange={e => {
                                        handleRegionSelect(e);
                                    }}

                                    // selectedValue={selectedRegions}
                                />
                            </div>
                            <div
                                onKeyUp={() => handleMenuSearch("country")}
                                style={{
                                    width: "100%",
                                    maxWidth: "330px",
                                }}>
                                <MultiSelectSort
                                    options={
                                        filteredCountryMenuItems ||
                                        countryMenuItems
                                    }
                                    placeholder={
                                        selectedRegions[0] &&
                                        selectedRegions[0].length === 0
                                            ? "Select region first"
                                            : "Country"
                                    }
                                    onChange={e => {
                                        handleCountrySelect(e);
                                    }}
                                    isDisabled={
                                        selectedRegions[0] &&
                                        selectedRegions[0].length === 0
                                    }
                                    // selectedValue={selectedCountries}
                                />
                            </div>
                        </div>
                        <div
                            className={classnames([
                                classes.inputsIndustryWrapper,
                            ])}>
                            <div
                                style={{
                                    width: "100%",
                                    maxWidth: "330px",
                                }}>
                                <MultiSelectSort
                                    options={
                                        filteredIndustriesMenuItems ||
                                        industriesMenuItems
                                    }
                                    placeholder="Industries"
                                    onChange={e => {
                                        handleIndustriesSelect(e);
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    maxWidth: "330px",
                                }}>
                                <MultiSelectSort
                                    options={
                                        activeSubsectors
                                        // filteredIndustriesSubsectorMenuItems ||
                                        // industriesSubsectorMenuItems
                                    }
                                    placeholder="Sub-industries"
                                    onChange={e => {
                                        handleSubindustrySelect(e);
                                    }}
                                    isDisabled={!selectedIndustries[0]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classnames([classes.buttonWrapper])}>
                    <Button
                        onClick={handleSubmitSearch}
                        disabled={scopeValue === ""}
                        className={classnames([classes.button])}>
                        <MagnifyingGlass
                            className={classnames([classes.buttonIcon])}
                        />
                        Create Scope
                    </Button>
                </div>
            </div>
            :
            <div className={classnames([classes.previousScopesWrapper])}>
                    <div className={classnames([classes.previousScopesTitleWrapper])}>
                        <div className={classnames([classes.previousScopesTitle])}>Previous Scopes</div>
                        <div className={classnames([classes.previousScopesLegend])}>
                          <div className={classnames([classes.previousScopesScopeLegend])}><ScopeIcon style={{color: "#485E87"}}/> Scope</div> 
                            <div className={classnames([classes.previousScopesLookalikeLegend])}><LookAlikeIcon style={{color: "#007A5E"}} /> Look-alike</div>
                            <div> 
                                <Tooltip
                                    title={
                                        <Typography
                                            color="white"
                                            variant="body2"
                                            className={classes.infoTooltipText}>
                                            Some text
                                        </Typography>
                                    }
                                    arrow
                                >
                                <QuestionMarkTooltip
                                    className={classnames([classes.tooltip])}
                                />
                            </Tooltip>
                        </div>
                        </div>
                    </div>
                    <div>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ background: "95%", maxHeight: "95%", overflowY: 'auto' }}>
                        <Table stickyHeader className={classes.table} aria-label="simple table">
                            <TableHead className={classes.head}>
                                <TableRow>
                                <TableCell className={classes.header}>Date</TableCell>
                                <TableCell className={classes.header} align="right">Type</TableCell>
                                <TableCell className={classes.header} align="left">Scope</TableCell>
                                <TableCell className={classes.header} align="right">Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <LoadScopeDialog  
                                open={showAreYouSureModal}
                                setOpen={setShowAreYouSureModal}
                                handleYesClick={loadScope}
                            />
                            <DeleteSearchDialog  
                                open={openDeleteSearchModal}
                                setOpen={setOpenDeleteSearchModal}
                                handleYesClick={handleRemoveSearch}
                                selectedSearch={selectedScope}
                            />
                            <TableBody className={classes.tbody}>
                                {searches && searches.length && searches.length > 0 && searches.map((search) => (
                                <TableRow key={search.id} className={classes.card}>
                                    <TableCell className={`date ${classes.date}`} component="th" scope="row">
                                    <div style={{whiteSpace: "nowrap"}}>
                                        {search.created_date}
                                    </div>
                                    <div style={{whiteSpace: "nowrap"}}>
                                        {search.created_ago}
                                    </div>
                                    </TableCell>
                                    <TableCell className={`type ${search?.scope === "Find look-alike" ? classes.lookalike : classes.searchScope} ${classes.type}`}  align="right">{search?.scope === "Find look-alike" ? <LookAlikeIcon /> : <ScopeIcon />}</TableCell>
                                
                                    <Tooltip title={<div className={classes.tooltipTitle}>{search.description}</div>} arrow>
                                    {/* <TableCell className={`scope ${classes.scope}`} align="left" onClick={() => handleClick(search)}>{search.description.length > 120 ? `${search.description.substring(0, 120)} ...` : search.description}</TableCell> */}

                                    <TableCell className={`scope ${classes.tableScope}`} align="left" onClick={() => { search && setSelectedScope(search); setShowAreYouSureModal(true)}}>{search?.description.length > 120 ? `${search?.description.substring(0, 120)} ...` : search?.description}</TableCell>
                                    </Tooltip>
                                    <TableCell align="right"><TrashIcon className={`delete ${classes.delete}`}  onClick={() => {search && setSelectedScope(search); setOpenDeleteSearchModal(search)}}/></TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>}
        </div>
    );
};

export default NewSearch;
