import React, { useRef, useEffect } from "react";
import { useStyles } from "../style.js";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
    createUserConversationRequest,
    updateUserConversationRequest,
} from "../../../store/userConversation/requests";
import {
    userConversationSelector,
    getConversationHistoryStatusSelector
} from "../../../store/userConversation/selectors";
import { getUserSelector } from "../../../store/auth/selectors";
// Constants
import { REQUEST_SUCCESS, REQUEST_PENDING } from "../../../constants/statuses";
const UserInput = ({
    contextObject,
    contextName,
    selectedFiltersCount,
    conversationContext,
    onEnterPress
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userConversationData = useSelector(userConversationSelector);
    const conversationHistoryStatus = useSelector(getConversationHistoryStatusSelector);
    const user = useSelector(getUserSelector);
    const userQuestionTextarea = useRef(null);

    //Send user input
    const handleKeyDownPress = event => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            onEnterPress(event);
            event.preventDefault();
            // existing conversation
            if (
                contextObject &&
                contextObject.conversation_id &&
                selectedFiltersCount === 0
            ) {
                let payload = {
                    conversation_id: contextObject.conversation_id,
                    deep_dive_question: event.target.value,
                    conversation_context: contextObject.conversation_context,
                    scope_description: contextObject.description,
                    search_id: contextObject.id,
                };
                if (user && user.llm_region) {
                    payload.llm_region = user.llm_region;
                }
                dispatch(
                    updateUserConversationRequest({
                        data: payload,
                        id: contextObject.id,
                    }),
                );
                // create new conversation
            } else {
                //Workaround - if filters are active we are creating a new conversation and sending new conversation_context
                let context = contextObject.conversation_context;
                if (selectedFiltersCount > 0) {
                    context = conversationContext;
                }
                let payload = {
                    deep_dive_question: event.target.value,
                    conversation_context: context,
	                scope_description: contextObject.description,
                    search_id: contextObject.id,
                    has_filters: !!selectedFiltersCount,
                };
                if (user && user.llm_region) {
                    payload.llm_region = user.llm_region;
                }
                dispatch(
                    createUserConversationRequest({
                        data: payload,
                        id: contextObject.id,
                    }),
                );
            }
            userQuestionTextarea.current.value = "";
        }
    };

    /* useEffect(() => {
        if (
            createUserConversationStatus === REQUEST_SUCCESS ||
            updateUserConversationStatus === REQUEST_SUCCESS 
        ) {
            setShowLoading(false);
        }
    }, [createUserConversationStatus, updateUserConversationStatus]); */

    //TODO: delete if no longer needed after sprint
    //Save conversation_id after new conversation is created
    //Temporarily: if filters are active we don't save the conversation id
    /* useEffect(() => {
        if (
            selectedFiltersCount === 0 &&
            contextObject &&
            !contextObject.conversation_id &&
            createUserConversationStatus === REQUEST_SUCCESS &&
            mlConversationId
        ) {
            let contextObjectData = {
                resource_name: contextName,
                resource_id: contextObject.id,
                conversation_id: mlConversationId,
            };
            dispatch(
                saveUserConversationIdRequest({ data: contextObjectData }),
            );
        }
    }, [createUserConversationStatus, selectedFiltersCount, contextObject]); */

    const isDisabled = () => {
        return contextObject && (!userConversationData || 
        !userConversationData[contextObject.id] || 
        !userConversationData[contextObject.id].conversation) &&
        conversationHistoryStatus === REQUEST_PENDING;
    }

    return (
        <div className={classes.userInput}>
            <TextareaAutosize
                disabled={isDisabled()}
                className={classes.textareaField}
                minRows={3}
                placeholder="Ask me anything about your results..."
                ref={userQuestionTextarea}
                onKeyDown={handleKeyDownPress}
            />
        </div>
    );
};

export default UserInput;
