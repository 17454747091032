import React, { useEffect, useState } from "react";

// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../../../components/Button/Button";
import TextField from "../../../../components/TextField/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grow from "@material-ui/core/Grow";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useDispatch, useSelector } from "react-redux";
//UI Components
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
//Assets
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import CreateNewProjectModalIcon from "../../../../assets/icons/CreateNewProjectModalIcon.svg";
// Redux
import {
    createProjectRequest,
    updateProjectRequest,
    checkProjectNameRequest,
    addItemToProjectRequest,
} from "../../../../store/projects/requests";
import {
    getCheckProjectTitleStateSelector,
    getPostProjectsStatusSelector,
    getNewProjectSelector,
    getCheckProjectTitleStatusSelector,
} from "../../../../store/projects/selectors";
import { REQUEST_SUCCESS } from "../../../../constants/statuses";
import { useRouterHook } from "../../../../common/useRouterHook";

const CreateProjectModal = ({
    dialogOpened,
    result,
    company,
    onChange,
    data,
    newProjectCreated,
    selectedCompanies,
    setDialogOpenedResult,
    companyIndustry,
    type,
}) => {
    const { t } = useTranslation();
    const classes = useStyles({
        isCreateNewProject: window.location.pathname.includes("workspace"),
    });
    const dispatch = useDispatch();
    const [modalData, setModalData] = useState(data);
    const [projectTitle, setProjectTitle] = useState(
        modalData ? modalData.name : "",
    );
    const [projectDescription, setProjectDescription] = useState(
        modalData ? modalData.description : "",
    );
    const [projectSlug, setProjectSlug] = useState(
        modalData ? modalData.slug : "",
    );
    const projectTitleStateSelector = useSelector(
        getCheckProjectTitleStateSelector,
    );
    const projectTitleStatusSelector = useSelector(
        getCheckProjectTitleStatusSelector,
    );
    const getPostProjectsStatus = useSelector(getPostProjectsStatusSelector);
    const getNewProject = useSelector(getNewProjectSelector);
    const [projectTitleStatusOk, setProjectTitleStatusOk] = useState(null);
    const [isChecking, setIsChecking] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { openPage } = useRouterHook();

    useEffect(() => {
        setIsMobile(window.innerWidth <= 575);
    }, []);

    useEffect(() => {
        if (!dialogOpened) {
            setProjectTitle("");
            setProjectDescription("");
        }
    }, [dialogOpened]);

    const createNewProject = e => {
        e.preventDefault();
        e.stopPropagation();
        localStorage.setItem("createProjectName", projectTitle);
        let companies = [];
        companies = companyIndustry
            ? [companyIndustry.id || companyIndustry.cId]
            : selectedCompanies.map(company => company);
        dispatch(
            createProjectRequest({
                items: companies,
                projectTitle,
                projectDescription,
                type,
            }),
        );
        const projectCreated = {
            is_in_project: false,
            project: projectTitle,
            slug: slugify(projectTitle),
        };
        if (newProjectCreated) {
            newProjectCreated(projectCreated);
        }
        onChange(e);
    };

    // useEffect(() => {
    //     if (getPostProjectsStatus === REQUEST_SUCCESS) {
    //         newProjectCreated && newProjectCreated();
    //     }
    // }, [getNewProject]);

    const saveChanges = e => {
        const oldProjectSlug = modalData ? modalData.slug : "";
        e.preventDefault();
        e.stopPropagation();
        localStorage.setItem("updateProjectName", projectTitle);
        dispatch(
            updateProjectRequest({
                oldProjectSlug,
                projectTitle,
                projectDescription,
            }),
        );
        onChange(e);

        projectSlug === oldProjectSlug
            ? window.location.reload()
            : openPage(`/projects/${projectSlug}`);
    };
    const slugify = str =>
        str
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, "")
            .replace(/[\s_-]+/g, "-")
            .replace(/^-+|-+$/g, "");

    const handleTitle = event => {
        setIsChecking(true);
        setProjectTitle(event.target.value);
        setProjectSlug(slugify(event.target.value));
        let project = modalData;
        let title = event.target.value;
        setTimeout(() => {
            dispatch(checkProjectNameRequest({ project, title }));
        }, 800);
    };
    const handleDescription = event => {
        setProjectDescription(event.target.value);
    };
    useEffect(() => {
        setModalData(data);
    }, []);

    useEffect(() => {
        projectTitleStatusSelector === REQUEST_SUCCESS && setIsChecking(false);
    }, [projectTitleStatusSelector]);
    useEffect(() => {
        setProjectTitleStatusOk(projectTitleStateSelector);
        modalData &&
            projectTitle === modalData.name &&
            setProjectTitleStatusOk(false);
    }, [projectTitleStateSelector]);

    const disableSaveButton = () => {
        if (isChecking) {
            return true;
        } else if (!projectTitle) {
            return true;
        } else if (
            projectTitle &&
            modalData &&
            projectTitle === modalData.name &&
            projectTitleStatusOk
        ) {
            return false;
        } else {
            return projectTitleStatusOk;
        }
    };
    useEffect(() => {
        if (
            getNewProject &&
            getNewProject.length > 0 &&
            getPostProjectsStatus === REQUEST_SUCCESS &&
            selectedCompanies.length > 0 &&
            !!result
        ) {
            dispatch(
                addItemToProjectRequest({
                    project: getNewProject,
                    companies: [company],
                }),
            );
            setDialogOpenedResult(null);
        }
    }, [getPostProjectsStatus, getNewProject, result]);
    const closeDialog = e => {
        setProjectTitle("");
        setProjectDescription("");
        onChange(e);
    };

    return (
        <div>
            <Dialog
                fullScreen={isMobile}
                maxWidth="lg"
                TransitionComponent={Grow}
                classes={{
                    root: classes.dialogRoot,
                    paper: classes.dialog,
                    scrollPaper: classes.dialogScrollPaper,
                }}
                onClose={(event, reason) => {
                    if (reason === "backdropClick") {
                        closeDialog(event, reason);
                    }
                }}
                open={dialogOpened}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                {dialogOpened ? (
                    <IconButton
                        className={classes.closeButton}
                        onClick={e => closeDialog(e)}>
                        <img
                            src={CloseIcon}
                            alt={t("outcome.step2")}
                            className={classes.stepIcon}
                        />
                    </IconButton>
                ) : null}
                <MuiDialogTitle
                    disableTypography
                    className={classes.dialogTitleWrapper}>
                    {modalData ? (
                        <Typography
                            variant="subtitle1"
                            className={classes.dialogTitle}>
                            {t("createProjectModal.editProject")}
                        </Typography>
                    ) : (
                        <Typography
                            variant="subtitle1"
                            className={classes.dialogTitle}
                            dangerouslySetInnerHTML={{
                                __html: t("createProjectModal.modalTitle"),
                            }}
                        />
                    )}
                </MuiDialogTitle>
                <MuiDialogContent className={classes.dialogContent}>
                    <div className={classes.flexColumn}>
                        <Typography
                            variant="bodySmall"
                            className={classes.projectTitle}>
                            {t("createProjectModal.projectTitle")}
                            <span style={{ color: "#FD750D" }}>*</span>
                        </Typography>
                        <TextField
                            inputClass={classes.inputClass}
                            className={classes.projectTitleInput}
                            onChange={handleTitle}
                            value={projectTitle}
                            placeholder={t(
                                "createProjectModal.projectTitlePlaceholder",
                            )}></TextField>

                        {projectTitleStatusSelector === REQUEST_SUCCESS &&
                            projectTitleStatusOk === true && (
                                <Typography
                                    className={classes.projectTitleError}
                                    variant="caption">
                                    {t(
                                        "createProjectModal.projectAlreadyExists",
                                    )}
                                </Typography>
                            )}
                    </div>
                    <div className={classes.flexColumn}>
                        <Typography
                            variant="bodySmall"
                            className={classes.projectDescription}>
                            {t("createProjectModal.description")}
                        </Typography>
                        <TextareaAutosize
                            className={classes.textarea}
                            minRows={6}
                            placeholder={t(
                                "createProjectModal.projectDescriptionPlaceholder",
                            )}
                            value={projectDescription}
                            onChange={handleDescription}
                        />
                        <div className={classes.maximumCharactersWrap}>
                            {projectDescription &&
                                projectDescription.length > 1000 && (
                                    <Typography
                                        className={
                                            classes.maximumCharactersWarning
                                        }
                                        variant="caption">
                                        {t(
                                            "createProjectModal.maximumNumCharacters",
                                        )}
                                    </Typography>
                                )}
                        </div>
                    </div>
                    <div className={classes.cancelAndCreate}>
                        <Button
                            className={classes.cancelButton}
                            variant="tertiary"
                            disabled={false}
                            onClick={e => closeDialog(e)}>
                            {t("createProjectModal.cancel")}
                        </Button>
                        {modalData ? (
                            <Button
                                variant="primary"
                                disabled={disableSaveButton()}
                                onClick={saveChanges}>
                                {t("createProjectModal.saveChanges")}
                            </Button>
                        ) : (
                            <Button
                                variant="primary"
                                disabled={
                                    disableSaveButton() ||
                                    projectDescription.length > 1000
                                }
                                onClick={createNewProject}>
                                {t("createProjectModal.button")}
                            </Button>
                        )}
                    </div>
                </MuiDialogContent>
                {window.location.pathname.includes("workspace") && (
                    <img
                        className={classes.createProjectModalImage}
                        src={CreateNewProjectModalIcon}
                    />
                )}
            </Dialog>
        </div>
    );
};

CreateProjectModal.defaultProps = {
    selectedCompanies: [],
};

export default CreateProjectModal;
