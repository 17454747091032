import React from "react";
import classnames from "classnames";
import { useStyles } from "./style.js";
import OwnPrompt from "./NewSearch/OwnPrompt.js";

const AssistedScope = () => {
    const classes = useStyles();

    return (
        <div className={classnames([classes.root, classes.searchTypeRoot])}>
            <div className={classes.mainSearchBoxWrapper}>
                <OwnPrompt />
            </div>
        </div>
    );
};

export default AssistedScope;
