import React, { useEffect, useState } from "react";
import classnames from "classnames";
import ClickAwayListener from "react-click-away-listener";
import { capitalize } from "@material-ui/core";
// Hooks
import { useRouterHook } from "../../../common/useRouterHook";
import { useAddToProjects } from "../useAddToProjects";
import { useAppEventTracking } from "../../../common/useAppEventTracking";
//UI components
import Typography from "../../../components/Typography/Typography";
import Checkbox from "../../../components/Checkbox/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "../../../components/Menu/Menu";
import TopUpCreditsModal from "../../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal";
import CreateProjectModal from "../../Results/components/modals/CreateProjectModal";
import DownloadButton from "../../../components/DownloadButton/DownloadButton";
import { AddToProjectMenu } from "../../../components/AddToProjectMenu/AddToProjectMenu";
// Assets
import { ReactComponent as DownloadAsCSV } from "../../../assets/icons/DownloadAsCSV.svg";
import { ReactComponent as MoreIconNoFill } from "../../../assets/icons/MoreIconNoFill.svg";
// Redux
import { exportCsvRequest } from "../../../store/orders/requests";

const OrderTableRow = ({
    order,
    t,
    classes,
    dispatch,
    i,
    user,
    onCheckboxChange,
    selected,
    isMultiSelected,
    isMultiAvailable,
    type,
    setShowAreYouSureModal,
    setShowDownloadsExceededModal,
    getDownloadProps,
}) => {
    const {
        topUpCreditsModalShow,
        handleClosingTopUpCreditsDialog,
        handleBuyingTopUpCreditsDialog,
    } = useAddToProjects({ order, user });
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isSelectable, setIsSelectable] = useState(
        order.status === "Request complete",
    );
    const [isSelected, setIsSelected] = useState(false);
    const { openPage } = useRouterHook();

    useEffect(() => {
        setIsSelectable(order.status === "Request complete");
    }, [order.status]);

    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
    };
    const { appTrackEvent, mixpanelTrackEvent } = useAppEventTracking();

    const menuItems = [
        {
            label: (
                <Typography variant="bodyXs" className={classes.mono}>
                    {t("common.downloadAsCSV")}
                </Typography>
            ),
            onClick: () =>
                handleDownload({
                    ids: [order.oid],
                    type: type,
                    doc: "csv",
                    name: `valuer_${order.name}_report`,
                    pageName: "orders",
                }),
        },
        {
            label: (
                <Typography variant="bodyXs" className={classes.mono}>
                    {t("common.downloadAsPDF")}
                </Typography>
            ),
            onClick: () =>
                handleDownload({
                    ids: [order.oid],
                    type: type,
                    doc: "pdf",
                    name: `valuer_${order.name}_report`,
                    pageName: "orders",
                }),
        },
    ];

    const openCompany = () => {
        localStorage.setItem("is_from_project", false);
        sessionStorage.setItem("history", "orders");
        sessionStorage.setItem("history", "orders");
        if (type == "companies") {
            appTrackEvent("Specific Company Enrichment opened in orders");
        } else {
            appTrackEvent("Specific Technology Enrichment opened in orders");
        }
        openPage(
            type === "companies"
                ? `/companies/${order.slug}?cid=${order.oid}`
                : `/technologies/${order.slug}`,
        );
    };

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    const handleChangeCheckbox = () => {
        onCheckboxChange(order.oid);
    };

    const handleDownload = payload => {
        dispatch(exportCsvRequest(payload));
        appTrackEvent(`Downloaded document`, {
            "Order type": type == "companies" ? "company" : "technology",
            "Order name": order.name,
            type: payload.doc,
            page: "orders",
        });
    };

    return (
        <TableRow
            className={classnames([
                classes.tableRow,
                { [classes.selectedRow]: isSelected },
            ])}
            key={`${i}-order`}
            data-intercom-target={`${i + 1} order row element`}>
            <TableCell
                align="left"
                component="th"
                scope="row"
                className={classnames([
                    classes.cell,
                    classes.cellDate,
                    { [classes.cellDateMulti]: isMultiAvailable },
                ])}>
                <div className={classes.avatarCell}>
                    <Checkbox
                        disabled={!isSelectable}
                        checked={isSelected}
                        onChange={handleChangeCheckbox}
                        className={classes.cellCheckbox}
                    />
                    <Typography
                        variant="bodyButton"
                        color="secondaryDark"
                        component="div">
                        <div className={classes.avatar}>
                            {capitalize(order.name.charAt(0))}
                        </div>
                    </Typography>
                    <Typography
                        variant="body"
                        color="black"
                        component="div"
                        className={classes.name}
                        onClick={openCompany}>
                        {order.name}
                    </Typography>
                </div>
                {!isMultiSelected && (
                    <Menu
                        btnClassName={classes.moreButton}
                        onChange={handleMenuClick}
                        menuItems={menuItems}
                        otherItems={
                            <AddToProjectMenu
                                displayListTypeInMenu={true}
                                company={{ id: order.oid, name: order.name }}
                                type={"company"}
                            />
                        }>
                        <MoreIconNoFill
                            className={classnames([
                                classes.moreIcon,
                                { [classes.moreIconOpened]: isMenuOpened },
                            ])}
                        />
                    </Menu>
                )}
            </TableCell>
            <TableCell
                align="left"
                className={classnames([
                    classes.cell,
                    classes.cellDate,
                    classes.cellDateMargin,
                ])}>
                <Typography
                    variant="bodyButton"
                    color={isSelectable ? "success" : "primaryDark"}
                    component="div">
                    {isSelectable ? order.status : <i>{order.status}</i>}
                </Typography>
                <Typography
                    variant="bodyButton"
                    color="textLighter"
                    component="div">
                    <i>{order.estimated_at}</i>
                </Typography>
            </TableCell>
            <TableCell
                align="left"
                className={classnames([
                    classes.cell,
                    classes.cellDate,
                    classes.cellDateMargin,
                ])}>
                <Typography
                    variant="bodyButton"
                    color="textLighter"
                    component="div"
                    className={classes.orderedLabel}>
                    {t("orders.ordered")}
                </Typography>
                <div className={classes.cellRight}>
                    <Typography
                        variant="bodyButton"
                        color="secondaryDark"
                        component="div">
                        <i>{order.requested_by}</i>
                    </Typography>
                    <Typography
                        variant="bodyButton"
                        color="textLighter"
                        component="div">
                        <i>{order.requested_at}</i>
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                align="right"
                className={classnames([classes.cell, classes.lastCell])}>
                {!isMultiSelected && (
                    <>
                        <DownloadButton
                            user={user}
                            isIndustry={false}
                            isTechnology={false}
                            isProject={false}
                            selected={[order.oid]}
                            dispatch={dispatch}
                            type={type}
                            icon={
                                <DownloadAsCSV className={classes.actionIcon} />
                            }
                            setShowAreYouSureModal={setShowAreYouSureModal}
                            setShowDownloadsExceededModal={
                                setShowDownloadsExceededModal
                            }
                            getDownloadProps={getDownloadProps}
                            className={classes.downloadButtonComponent}
                            name={`valuer_${order.name}_report`}
                            page="orders"
                        />
                        {type === "companies" && (
                            <div className={classes.addToProjectWrapper}>
                                <AddToProjectMenu
                                    buttonType="icon"
                                    company={{
                                        id: order.oid,
                                        name: order.name,
                                    }}
                                    type="company"
                                />
                            </div>
                        )}
                    </>
                )}
            </TableCell>
            <TopUpCreditsModal
                dialogOpened={topUpCreditsModalShow}
                onBuy={handleBuyingTopUpCreditsDialog}
                onChange={handleClosingTopUpCreditsDialog}
            />
        </TableRow>
    );
};

export default OrderTableRow;
