import axios from "axios";
import {PROMPT_TEST_URL, PROMPT_CONSTRUCT_URL} from "../../constants/endpoints";

const basicAuth = 'Basic ' + btoa(process.env.REACT_APP_ML_USER + ':' + process.env.REACT_APP_ML_PASSWORD);

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = basicAuth;
    config.headers.common["Content-Type"] = "application/json";
    return config;
}, function (error) {
    return Promise.reject(error);
});

class ScopeGuidanceService {
    sendPromptTest = ({llm_id, query, system_message_group}) => {
        return axios
            .post(PROMPT_TEST_URL, {llm_id, query, system_message_group})
            .then(res => res.data.suggestion);
    };
    generateScopePrompt = ({llm_id, STEP_1, STEP_2, STEP_3, STEP_4, STEP_5}) => {
        return axios
            .post(PROMPT_CONSTRUCT_URL, {llm_id, STEP_1, STEP_2, STEP_3, STEP_4, STEP_5})
            .then(res => res.data.scope);
    }
}

export default ScopeGuidanceService;
