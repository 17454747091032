export const availableTabs = [
    {
        id: 5,
        name: "Previous Scope",
        active: false,
    },
    {
        id: 3,
        name: "Cluster",
        active: true,
    },
    {
        id: 1,
        name: "List",
        active: false,
    },
    {
        id: 2,
        name: "Card",
        active: false,
    },
    {
        id: 4,
        name: "Overview",
        active: false,
    },
];
