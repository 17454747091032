import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// UI components
import Typography from "../../components/Typography/Typography";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useRouterHook } from "../../common/useRouterHook";
import { useHistory } from "react-router-dom";
//UI Components
import Tag from "../../components/Tag/Tag";
import Modal from "../../components/Modal/Modal";
//Assets
import CloseIcon from "../../assets/icons/CloseIcon.svg";
// Redux
import { actions as industriesActions } from "../../store/industries/slice";
import { getIndustrySelector } from "../../store/industries/selectors";

const SeeSectorsModal = ({
    onChange,
    sectors,
    subsectors,
    technologies,
    industrySlug,
    isIndustryProfile,
    isSimilarCompanyCard,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const getIndustry = useSelector(getIndustrySelector);

    useEffect(() => {
        !isIndustryProfile &&
            getIndustry &&
            getIndustry.length &&
            dispatch(industriesActions.clearIndustry());
    }, []);

    const handleOpenSubsector = (e, subsectorSlug, sectorSlug) => {
        e.preventDefault();
        e.stopPropagation();
        if ((industrySlug || sectorSlug) && subsectorSlug) {
            localStorage.setItem(
                "industryProfileFromRoute",
                history.location.pathname,
            );
            openPage(
                `/industries/${
                    industrySlug ? industrySlug : sectorSlug
                }/?subsectorSlug=${subsectorSlug}`,
            );
            setShowModal(false);
        }
    };

    const handleOpenTechnology = (e, slug) => {
        e.preventDefault();
        e.stopPropagation();
        if (!!slug) {
            openPage(`/technologies/${slug}/`);
            setShowModal(false);
        }
    };

    const handleOpenSector = (e, slug) => {
        e.preventDefault();
        e.stopPropagation();
        if (!!slug) {
            localStorage.setItem(
                "industryProfileFromRoute",
                window.location.pathname + window.location.search,
            );
            openPage(`/industries/${slug}/`);
            setShowModal(false);
        }
    };

    const handleSeeSectors = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(true);
    };

    const handleCloseModal = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(false);
        onChange && onChange();
    };

    return (
        <>
            {((sectors && sectors.length > 0) ||
                (subsectors && subsectors.length > 0) ||
                (technologies && technologies.length > 0)) && (
                <Tag
                    variant={
                        sectors && sectors.length !== 0
                            ? "lightOrange"
                            : isSimilarCompanyCard
                            ? "darkGreen"
                            : "lightGreen"
                    }
                    onClick={handleSeeSectors}
                    className={classes.seeSectorsTag}>
                    {t(
                        `industries.${
                            sectors && sectors.length > 0
                                ? "seeIndustries"
                                : "seeSubindustries"
                        }`,
                    )}
                </Tag>
            )}
            <Modal
                onClose={handleCloseModal}
                dialogOpened={showModal}
                closeButtonIcon={CloseIcon}
                paddingSpace="32px"
                borderRadius="16px"
                classnames={{
                    root: classes.dialogRoot,
                    paper: classes.dialog,
                    scrollPaper: classes.dialogScrollPaper,
                }}>
                <>
                    {sectors && sectors.length > 0 && (
                        <>
                            <Typography
                                component="div"
                                variant="paragraph"
                                color="black"
                                className={classes.title}>
                                {t("industries.title")}
                            </Typography>
                            {sectors
                                .filter(sector => !!sector.slug)
                                .map((sector, i) => (
                                    <Tag
                                        key={`${i}-sector`}
                                        className={classes.sector}
                                        onClick={e =>
                                            handleOpenSector(e, sector.slug)
                                        }>
                                        {sector.name}
                                    </Tag>
                                ))}
                        </>
                    )}
                    {subsectors && subsectors.length > 0 && (
                        <>
                            <Typography
                                component="div"
                                variant="paragraph"
                                color="black"
                                className={classes.title}>
                                {t("industries.subIndustries")}
                            </Typography>
                            {subsectors
                                .filter(subSector => !!subSector.slug)
                                .map((sector, i) => (
                                    <Tag
                                        key={`${i}-subsector`}
                                        variant="lightGreen"
                                        className={classes.sector}
                                        onClick={e =>
                                            handleOpenSubsector(
                                                e,
                                                sector.slug,
                                                sector.sector_slug,
                                            )
                                        }>
                                        {sector.name}
                                    </Tag>
                                ))}
                        </>
                    )}
                    {technologies && technologies.length > 0 && (
                        <>
                            <Typography
                                component="div"
                                variant="paragraph"
                                color="black"
                                className={classes.title}>
                                {t("industries.seeTechnologies")}
                            </Typography>
                            {technologies.map((technology, i) => (
                                <Tag
                                    key={`${i}-technology`}
                                    clickable={!!technology.slug}
                                    variant="lightGreen"
                                    className={classes.sector}
                                    onClick={e =>
                                        handleOpenTechnology(e, technology.slug)
                                    }>
                                    {technology.name}
                                </Tag>
                            ))}
                        </>
                    )}
                </>
            </Modal>
        </>
    );
};
export default SeeSectorsModal;
