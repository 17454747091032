import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
// UI components
import Typography from "../../../../../components/Typography/Typography";
import Button from "../../../../../components/Button/Button";
import TextField from "../../../../../components/TextField/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// Hooks
import { useTranslation } from "react-i18next";
import { useStyles } from "./style";
import { useRouterHook } from "../../../../../common/useRouterHook";
//UI Components
import Modal from "../../../../../components/Modal/Modal";
//Assets
import CloseIcon from "../../../../../assets/icons/CloseIcon.svg";
import CheckMark from "../../../../../assets/images/CheckMark.png";
// Redux
import {
    getSearchesSelector,
    getPostSearchesMessageSelector,
} from "../../../../../store/results/selectors";

const NameTheSearch = ({
    dialogOpened,
    onChange,
    title,
    titleInputLabel,
    onActionClick,
    succeeded,
    requestPending,
    currentSearch,
    project,
    currentSearchSid,
    addSelectedCompanyToProject,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [nameValue, setNameValue] = useState("");

    const errorMessage = useSelector(getPostSearchesMessageSelector);

    const handleActionClick = e => {
        e.preventDefault();
        e.stopPropagation();
        onActionClick(nameValue);
        addSelectedCompanyToProject(e, project, "search");
        localStorage.setItem("addToProjectCompany", nameValue);
    };

    const closeModal = e => {
        onChange(e);
    };

    const handleNameChange = event => {
        setNameValue(event.target.value);
    };

    useEffect(() => {
        setIsBtnDisabled(!nameValue);
    }, [nameValue]);

    const handleStopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleOpenSavedSearches = e => {
        handleStopPropagation(e);
        openPage("/saved-searches");
    };

    return (
        <Modal
            onClose={closeModal}
            dialogOpened={dialogOpened}
            closeButtonIcon={CloseIcon}
            paddingSpace="32px"
            borderRadius="16px"
            classnames={{
                paperScrollPaper: classes.paperScrollPaper,
            }}>
            <div className={classes.root} onClick={handleStopPropagation}>
                <Typography
                    component="div"
                    className={classes.sureText}
                    variant="subtitle1"
                    color="black">
                    {t("results.saveSearchModalTitle")}
                </Typography>
                <>
                    <div className={classes.flexColumn}>
                        <Typography
                            variant="bodyXs"
                            className={classnames([
                                classes.nameTheSearchSubtitle,
                                classes.mono,
                            ])}>
                            {t("results.saveSearchModalSubtitle")}
                        </Typography>
                        <Typography
                            variant="bodySmall"
                            className={classes.mono}>
                            {t("results.saveSearchInputLabel")}
                        </Typography>
                        <TextField
                            onChange={handleNameChange}
                            value={nameValue}
                            className={classes.textfieldWrapper}
                            inputProps={{
                                classes: {
                                    root: classes.textfield,
                                },
                                fullWidth: true,
                            }}
                        />
                        {errorMessage && (
                            <Typography
                                component="div"
                                variant="caption"
                                color="danger">
                                {errorMessage}
                            </Typography>
                        )}
                    </div>
                    {/*<Typography*/}
                    {/*    variant="bodyXs"*/}
                    {/*    className={classes.mono}*/}
                    {/*    onClick={handleStopPropagation}*/}
                    {/*>*/}
                    {/*    {t("results.saveSearchInputDescription")}*/}
                    {/*    <Typography*/}
                    {/*        variant="bodyXs"*/}
                    {/*        className={classes.link}*/}
                    {/*        onClick={handleOpenSavedSearches}*/}
                    {/*        component="span"*/}
                    {/*        color="primaryDark"*/}
                    {/*    >*/}
                    {/*        {t('results.savedSearches')}*/}
                    {/*    </Typography>*/}
                    {/*</Typography>*/}
                    <div className={classes.cancelAndCreate}>
                        <Button
                            className={classes.cancelButton}
                            variant="tertiary"
                            disabled={false}
                            onClick={closeModal}>
                            {t("technologies.cancel")}
                        </Button>
                        <Button
                            variant="primary"
                            disabled={isBtnDisabled}
                            onClick={handleActionClick}
                            loading={requestPending}>
                            {t("results.saveSearchButton")}
                        </Button>
                    </div>
                </>
            </div>
        </Modal>
    );
};

export default NameTheSearch;
