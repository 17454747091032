import { createContext, useEffect, useState } from "react";
// Hooks
import { useSelector, useDispatch } from "react-redux";
import { useAuthHook } from "../../common/useAuthHook";
// Redux
import { getMeRequest } from "../../store/auth/requests";
import {
    getLogoutStatusSelector,
    getLoginStatusSelector,
} from "../../store/auth/selectors";
import {
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_PENDING,
    REQUEST_UNDEFINED,
} from "../../constants/statuses";
// Components
import ContentLoader from "../../components/ContentLoader/ContentLoader.js";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { getMeStatus } = useAuthHook();
    const logoutStatus = useSelector(getLogoutStatusSelector);
    const loginStatus = useSelector(getLoginStatusSelector);
    // User is the name of the "data" that gets stored in context
    useEffect(() => {
        if (
            loginStatus !== REQUEST_PENDING &&
            logoutStatus !== REQUEST_PENDING &&
            logoutStatus !== REQUEST_SUCCESS
        ) {
            dispatch(getMeRequest());
        }
    }, [dispatch, logoutStatus]);
    const [user, setUser] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Login updates the user data with a name parameter
    const login = us => {
        setUser(us);
        setIsAuthenticated(true);
    };

    // Logout updates the user data to default
    const logout = () => {
        // TODO:decide if this function should be removed since it's not being used atm
        setUser(null);
        setIsAuthenticated(false);
    };

    return (
        <UserContext.Provider value={{ user, isAuthenticated, login, logout }}>
            {getMeStatus === REQUEST_PENDING ? <ContentLoader /> : children}
        </UserContext.Provider>
    );
};
