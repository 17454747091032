import { useEffect, useState } from "react";
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
import { useLocation } from "react-router-dom";

const VerifyEmail = () => {
    const { isAuthenticated } = useAuthHook();
    const [countDown, setCountDown] = useState(3);
    const { openPage } = useRouterHook();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const startFreeTrial = query.get("startFreeTrial");
    useEffect(() => {
        if (countDown > 0) {
            setTimeout(() => {
                let t = countDown - 1;
                setCountDown(t);
            }, 1000);
        } else {
            let url = "results";
            if (!!startFreeTrial) {
                if (isAuthenticated) {
                    url = "results?startFreeTrial=true";
                } else {
                    url = "sign-in?signInForFreeTrial=true";
                }
            }
            openPage(url);
        }
    }, [countDown, openPage]);

    return (
        <div>
            <p>Thank you for verifying. Redirecting in: {countDown}</p>
        </div>
    );
};
export default VerifyEmail;
