import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import BlankPageImage from "../../assets/images/WorkspaceBlankPage.png";
import { useStyles } from "./style.js";

const WorkspaceEmptyPage = ({ onCreate }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Typography variant="subtitle1" className={classes.titleMobile}>
                {t("workspace.emptyPage.title")}
            </Typography>
            <div className={classes.mainBox}>
                <div className={classes.textBox}>
                    <Typography
                        variant="subtitle1"
                        className={classes.titleDesktop}>
                        {t("workspace.emptyPage.title")}
                    </Typography>
                    <Typography variant="h4" className={classes.createNewText}>
                        <span>{t("workspace.emptyPage.createNew")}</span>
                        <strong>{t("workspace.emptyPage.project")}</strong>
                    </Typography>
                    <Typography
                        variant="paragraph"
                        className={classes.helpText}>
                        {t("workspace.emptyPage.helpText")}
                    </Typography>
                    <Button
                        className={classes.createNewButton}
                        onClick={onCreate}>
                        {t("workspace.emptyPage.buttonText")}
                    </Button>
                </div>
                <img
                    src={BlankPageImage}
                    alt={t("workspace.emptyPage.title")}
                    className={classes.illustration}
                />
            </div>
        </div>
    );
};

export default WorkspaceEmptyPage;
