// import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import useStyles from "./style.js";
// Components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "../Checkbox/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "../Typography/Typography";

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#999999",
        fontSize: 12,
        lineHeight: "20px",
    },
    blackText: {
        color: theme.palette.common.black,
    },
}));

const Placeholder = ({ children, hasPlaceholder }) => {
    const classes = usePlaceholderStyles();
    return (
        <div
            className={classnames([
                classes.placeholder,
                { [classes.blackText]: hasPlaceholder },
            ])}>
            {children}
        </div>
    );
};

const Multiselect = ({
    className,
    id,
    labelProps,
    label,
    error,
    onChange,
    value,
    options,
    iconArrow,
    width,
    disabled,
    removeLabel,
    customClass,
    labelClass,
    menuItemClass,
    rootClass,
    placeholder,
    testId,
    noLeftRadius,
    onExpand,
    isWithCheckboxes = false,
    onClear,
    onOpen,
    isWithCounts,
    onClose,
    open = false,
}) => {
    const classes = useStyles({ width, removeLabel });
    const [isFocused, setIsFocused] = useState(false);
    const multiSelectClasses = classnames(customClass, classes.select, {
        [classes.filled]: value !== "",
        [classes.disabled]: disabled,
        [classes.focused]: isFocused,
        [classes.noLeftRadius]: noLeftRadius,
    });
    const labelClasses = classnames(classes.headerLabel, labelClass);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        onOpen && onOpen();
        setIsFocused(true);
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsFocused(false);
        setIsOpen(false);
        onClose && onClose();
    };

    useEffect(() => {
        open && handleOpen();
    }, [open]);

    const handleChange = e => {
        let value;
        if (typeof e.target.value === "number") {
            value = String(e.target.value);
        } else {
            value = e.target.value;
        }
        if (value === "Load full view" || value.includes("Load full view")) {
            onExpand();
            setIsOpen(true);
        } else {
            onChange(e);
            if (isWithCheckboxes) {
                setIsFocused(true);
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        }
    };

    const handleClick = e => {
        if (e.target._wrapperState) {
            if (e.target._wrapperState.initialValue === value) {
                onClear && onClear();
            }
        } else {
            if (
                e.target.value === value ||
                e.target.dataset.value === value ||
                e.target.innerText.includes(value) ||
                e.target.parentNode.innerText.includes(value)
            ) {
                onClear && onClear();
            }
        }
    };

    return (
        <FormControl className={className}>
            {label && (
                <InputLabel
                    disableAnimation={true}
                    focused={false}
                    shrink={true}
                    error={!!error}
                    {...labelProps}
                    className={labelClasses}
                    htmlFor={id}>
                    {label}
                </InputLabel>
            )}
            <Select
                open={isOpen}
                id={id}
                className={multiSelectClasses}
                classes={{
                    select: classnames([classes.root, rootClass]),
                    disabled: classes.disabled,
                    nativeInput: classes.nativeInput,
                }}
                value={
                    isWithCheckboxes && value.length === 0
                        ? []
                        : !Array.isArray(value) && isWithCheckboxes
                        ? [value]
                        : value
                }
                onChange={handleChange}
                IconComponent={() => iconArrow || null}
                disabled={disabled}
                autoWidth={false}
                onOpen={handleOpen}
                onClose={handleClose}
                disableUnderline
                displayEmpty
                test-id={testId}
                multiple={isWithCheckboxes}
                MenuProps={{
                    classes: {
                        paper: classes.menuPaper,
                        list: classes.menuList,
                    },
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    getContentAnchorEl: null,
                }}
                renderValue={value =>
                    (value && Array.isArray(value) && value.length < 3) ||
                    (value && !Array.isArray(value) && value.length < 30) ? (
                        <Placeholder hasPlaceholder={value && value.length}>
                            {value && value.length
                                ? isWithCheckboxes && Array.isArray(value)
                                    ? value.join(", ")
                                    : value
                                : placeholder}
                        </Placeholder>
                    ) : (
                        <Tooltip
                            title={
                                <>
                                    <Typography
                                        component="div"
                                        className={classnames([
                                            classes.mono,
                                            classes.mb16,
                                        ])}
                                        variant="bodyXs"
                                        color="white">
                                        {value && value.length
                                            ? isWithCheckboxes
                                                ? value.join(", ")
                                                : value
                                            : placeholder}
                                    </Typography>
                                </>
                            }
                            arrow>
                            <div>
                                <Placeholder
                                    hasPlaceholder={value && value.length}>
                                    {value && value.length
                                        ? isWithCheckboxes &&
                                          Array.isArray(value)
                                            ? value.join(", ")
                                            : value
                                        : placeholder}
                                </Placeholder>
                            </div>
                        </Tooltip>
                    )
                }>
                {options &&
                    options.map((option, i) => {
                        if (isWithCheckboxes) {
                            return (
                                <MenuItem
                                    className={classnames([
                                        classes.menuItem,
                                        menuItemClass,
                                        {
                                            [classes.menuItemWithCheckbox]:
                                                isWithCheckboxes,
                                            [classes.loadFullView]:
                                                option.slug ===
                                                "load-full-view",
                                        },
                                    ])}
                                    classes={{
                                        selected: classes.menuItemSelected,
                                    }}
                                    onClick={handleClick}
                                    key={`option-${i}`}
                                    value={option.name}>
                                    <div className={classes.checkboxWrapper}>
                                        {option.slug !== "load-full-view" ? (
                                            <Checkbox
                                                size="small"
                                                className={classes.checkbox}
                                                checked={value.includes(
                                                    option.name,
                                                )}
                                                value={option.name}
                                            />
                                        ) : null}
                                        <div
                                            className={classnames([
                                                classes.menuItemWrapper,
                                                {
                                                    [classes.expandWrapper]:
                                                        option.slug ===
                                                        "load-full-view",
                                                },
                                            ])}>
                                            <div>{option.name}</div>
                                            {isWithCounts &&
                                                (option.slug !==
                                                "load-full-view" ? (
                                                    <div>({option.count})</div>
                                                ) : null)}
                                        </div>
                                    </div>
                                </MenuItem>
                            );
                        } else {
                            return (
                                <MenuItem
                                    className={classes.menuItem}
                                    classes={{
                                        selected: classes.menuItemSelected,
                                    }}
                                    key={`option-${i}`}
                                    value={option.name}>
                                    {isWithCounts
                                        ? option.count
                                            ? `${option.name} (${option.count})`
                                            : option.name
                                        : option.name}
                                </MenuItem>
                            );
                        }
                    })}
            </Select>
        </FormControl>
    );
};
export default Multiselect;
