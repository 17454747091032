import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import findIndex from "lodash/findIndex";
import { actions as searchActions } from "../../../store/search/slice";
// UI components
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import ContentLoader from "../../../components/ContentLoader/ContentLoader.js";
// Hooks
import { useTranslation } from "react-i18next";
// Assets
import CloseIcon from "../../../assets/icons/CloseIcon.svg";
// Redux
import { getSubsectorsStatusSelector } from "../../../store/sectors/selectors";

const SubsectorsDialog = ({
    classes,
    selectedSearchSector,
    sector,
    handleSubsectorRemove,
    handleToggleDialog,
    dialogOpened,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const getSubsectorsStatus = useSelector(getSubsectorsStatusSelector);
    const [selectedSector, setSelectedSector] = useState();
    const [additionalSubsectors, setAdditionalSubsectors] = useState([]);

    const handleStopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        setSelectedSector(selectedSearchSector);
    }, [selectedSearchSector]);

    useEffect(() => {
        dialogOpened
            ? setSelectedSector(selectedSearchSector)
            : setSelectedSector({});
    }, [dialogOpened, selectedSearchSector]);

    const handleDialogClose = () => {
        handleToggleDialog();
    };

    const handleDialogSaveAndClose = e => {
        handleStopPropagation(e);
        handleToggleDialog();
        dispatch(searchActions.setSubsectors({ selectedSector }));
    };

    const subsectorSelect = (subsector, active) => {
        let sector = Object.assign({}, selectedSector);
        if (active) {
            const subsectorIndex = findIndex(
                selectedSector.subsectors,
                selectedSubsector => selectedSubsector.slug === subsector.slug,
            );
            if (subsectorIndex !== -1) {
                let array = [...sector.subsectors];
                array.splice(subsectorIndex, 1);
                sector.subsectors = array;
            }
        } else if (!selectedSector.subsectors) {
            sector.subsectors = [subsector];
        } else if (
            selectedSector.subsectors &&
            !selectedSector.subsectors.some(
                existing => existing.slug === subsector.slug,
            )
        ) {
            sector.subsectors = [...sector.subsectors, subsector];
        }
        setSelectedSector(sector);
    };

    // const addNewSubsector = subsector => {
    //   let sector = Object.assign({}, selectedSector);
    //   sector.subsectors = [...sector.subsectors, subsector];
    //   setAdditionalSubsectors(prevAdditionalSubsectors => [...prevAdditionalSubsectors, subsector]);
    //   setSelectedSector(sector);
    // }

    useEffect(() => {
        setAdditionalSubsectors([]);
    }, [sector]);

    const handleSubsectorSelectClick = (e, subsector) => {
        handleStopPropagation(e);
        subsectorSelect(
            subsector,
            selectedSector &&
                selectedSector.subsectors &&
                selectedSector.subsectors.some(i => i.slug === subsector.slug),
        );
    };

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{
                root: classes.dialogRoot,
                paper: classes.subsectorsDialog,
                scrollPaper: classes.subsectorsDialogScrollPaper,
            }}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    handleDialogClose();
                }
            }}
            open={dialogOpened}>
            <MuiDialogTitle
                disableTypography
                className={classes.dialogTitleWrapper}>
                <Typography variant="subtitle1" component="div">
                    {t("focus.dialogTitle")}
                </Typography>
                <Typography
                    variant="body"
                    component="div"
                    color="textLight"
                    className={classes.subsesctorsDialogSubtitle}>
                    {t("focus.dialogSubtitle")}
                </Typography>
                {dialogOpened ? (
                    <IconButton
                        className={classes.closeButton}
                        onClick={handleDialogClose}>
                        <img
                            src={CloseIcon}
                            alt={t("outcome.step2")}
                            className={classes.stepIcon}
                        />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.subsectorsDialogContent}>
                <div className={classes.relative}>
                    <div className={classes.maxHeight}>
                        {sector &&
                            sector.subsectors &&
                            sector.subsectors.map(subsector => (
                                <Button
                                    variant="pill"
                                    key={subsector.slug}
                                    disabled={
                                        selectedSector &&
                                        selectedSector.subsectors &&
                                        selectedSector.subsectors.length ===
                                            5 &&
                                        !selectedSector.subsectors.some(
                                            i => i.slug === subsector.slug,
                                        )
                                    }
                                    className={classes.sectorDialog}
                                    onClick={e =>
                                        handleSubsectorSelectClick(e, subsector)
                                    }
                                    deactivateButton={() =>
                                        handleSubsectorRemove(
                                            sector.slug,
                                            subsector,
                                        )
                                    }
                                    {...(selectedSector &&
                                        selectedSector.subsectors &&
                                        selectedSector.subsectors.some(
                                            i => i.slug === subsector.slug,
                                        ) && { active: true })}>
                                    {subsector.name}
                                </Button>
                            ))}
                        {additionalSubsectors &&
                            additionalSubsectors.map(subsector => (
                                <Button
                                    variant="pill"
                                    key={subsector.slug}
                                    disabled={
                                        selectedSector &&
                                        selectedSector.subsectors &&
                                        selectedSector.subsectors.length ===
                                            5 &&
                                        !selectedSector.subsectors.some(
                                            i => i.slug === subsector.slug,
                                        )
                                    }
                                    className={classes.sectorDialog}
                                    onClick={e =>
                                        handleSubsectorSelectClick(e, subsector)
                                    }
                                    deactivateButton={() =>
                                        handleSubsectorRemove(
                                            sector.slug,
                                            subsector,
                                        )
                                    }
                                    {...(selectedSector &&
                                        selectedSector.subsectors &&
                                        selectedSector.subsectors.some(
                                            i => i.slug === subsector.slug,
                                        ) && { active: true })}>
                                    {subsector.name}
                                </Button>
                            ))}
                    </div>
                </div>
                <div className={classes.bgGradient}></div>
            </MuiDialogContent>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.subsectorsDialogActionItems}>
                <Button
                    variant="primary"
                    className={classes.continueFocusCta}
                    onClick={handleDialogSaveAndClose}
                    size="large">
                    {t("focus.addSubindustries")}
                </Button>
            </Box>
        </Dialog>
    );
};

export default SubsectorsDialog;
