import { all, takeEvery } from "redux-saga/effects";
import {
    fetchIndustriesRequest,
    fetchIndustryRequest,
    fetchIndustryCompanyRequest,
    fetchIndustryCompaniesRequest,
    fetchIndustryCompaniesCountRequest,
    fetchIndustryCompaniesChartRequest,
    fetchIndustryCompaniesTeamSizeChartRequest,
    enrichIndustryRequest,
} from "../requests";
import {
    fetchIndustries,
    fetchIndustry,
    fetchIndustryCompany,
    fetchIndustryCompanies,
    fetchIndustryCompaniesCount,
    fetchIndustryCompaniesChart,
    fetchIndustryCompaniesTeamSizeChart,
    fetchEnrichIndustry,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchIndustries() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustriesRequest),
        ({ payload: { page } }) => fetchIndustries(page),
    );
}

function* watchFetchIndustry() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustryRequest),
        ({ payload: slug }) => fetchIndustry(slug),
    );
}

function* watchFetchIndustryCompany() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustryCompanyRequest),
        ({ payload: slug }) => fetchIndustryCompany(slug),
    );
}
function* watchFetchIndustryCompanies() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustryCompaniesRequest),
        ({ payload: { slug, data, clearResults, isGuest } }) =>
            fetchIndustryCompanies(slug, data, clearResults, isGuest),
    );
}

function* watchFetchIndustryCompaniesCount() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustryCompaniesCountRequest),
        ({
            payload: {
                slug,
                data,
                clearResults,
                isGuest,
                lastFilteredField,
                lastFilteredFieldValue,
            },
        }) =>
            fetchIndustryCompaniesCount(
                slug,
                data,
                clearResults,
                isGuest,
                lastFilteredField,
                lastFilteredFieldValue,
            ),
    );
}

function* watchFetchIndustryCompaniesChart() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustryCompaniesChartRequest),
        ({ payload: { id, type, grouped, filterNameValue } }) =>
            fetchIndustryCompaniesChart(id, type, grouped, filterNameValue),
    );
}

function* watchFetchIndustryCompaniesTeamSizeChart() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustryCompaniesTeamSizeChartRequest),
        ({ payload: { id, type, grouped, filterNameValue } }) =>
            fetchIndustryCompaniesTeamSizeChart(
                id,
                type,
                grouped,
                filterNameValue,
            ),
    );
}

function* watchEnrichIndustry() {
    yield takeEvery(
        convertTypeFromAction(enrichIndustryRequest),
        ({ payload: { slug } }) => fetchEnrichIndustry(slug),
    );
}

function* saga() {
    yield all([
        watchFetchIndustries(),
        watchFetchIndustry(),
        watchFetchIndustryCompany(),
        watchFetchIndustryCompanies(),
        watchFetchIndustryCompaniesCount(),
        watchFetchIndustryCompaniesChart(),
        watchFetchIndustryCompaniesTeamSizeChart(),
        watchEnrichIndustry(),
    ]);
}

export { saga };
