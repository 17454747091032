import React, { useState, useEffect } from "react";
import clsx from "clsx";
import classnames from "classnames";
// Hooks
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useFreeTrialHook } from "./hooks/useFreeTrialHook";
// Components
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";
import { ReactComponent as InviteUsers } from "../../assets/icons/InviteIcon.svg";
import InviteUsersModal from "../../components/InviteUsersModal/InviteUsersModal.js";
import Link from "../../components/Link/Link";
import PlanEndedModal from "../FreeTrial/PlanEndedModal/PlanEndedModal";
// import ConfirmFreeTrialStartModal from "../FreeTrial/ConfirmFreeTrialStartModal/ConfirmFreeTrialStartModal";
import ChangeEmailModal from "../FreeTrial/ChangeEmailModal/ChangeEmailModal";
import VerifyEmailModal from "../FreeTrial/VerifyEmailModal/VerifyEmailModal";
import UpgradePlanModal from "../FreeTrial/UpgradePlanModal/UpgradePlanModal";
import OnboardingFreeTrialModal from "../FreeTrial/OnboardingFreeTrialModal/OnboardingFreeTrialModal";
// Assets
import LogoNavbar from "../../assets/icons/LogoNavbar.svg";
import LogoShort from "../../assets/icons/LogoShort.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/sidemenu/Search.svg";
import { ReactComponent as OrdersIcon } from "../../assets/icons/sidemenu/Orders.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/sidemenu/Dashboard.svg";
// import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/sidemenu/ArrowLeft.svg";
// import { ReactComponent as ArrowRightIcon } from "../../assets/icons/sidemenu/ArrowRight.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/sidemenu/SettingsIcon.svg";
// import { ReactComponent as ProjectsIcon } from '../../assets/icons/ProjectsIcon.svg';
import { ReactComponent as ResultsIcon } from "../../assets/icons/sidemenu/Results.svg";
// import { ReactComponent as SearchHistoryIcon } from "../../assets/icons/SearchHistoryIcon.svg";
import { ReactComponent as TechnologiesIcon } from "../../assets/icons/sidemenu/Technologies.svg";
import { ReactComponent as IndustriesIcon } from "../../assets/icons/sidemenu/Industries.svg";
// import { ReactComponent as ResourcesIcon } from "../../assets/icons/ResourcesIcon.svg";
// import { ReactComponent as HelpCenterIcon } from '../../assets/icons/sidemenu/Help.svg';
import { ReactComponent as HelpCenterIcon } from "../../assets/icons/sidemenu/HelpIcon.svg";
import { ReactComponent as WorkspaceIcon } from "../../assets/icons/sidemenu/Workspace.svg";
import { ReactComponent as ShareLinkIcon } from "../../assets/icons/ShareLinkIcon.svg";
import { ReactComponent as TopUpCredits } from "../../assets/icons/CreditsLeft.svg";
import { ReactComponent as ProfileSettingsIcon } from "../../assets/icons/ProfileSettingsTopNavIcon.svg";
import { ReactComponent as ProfileSettingsActiveIcon } from "../../assets/icons/Settings.svg";
import { ReactComponent as ProfileArrow } from "../../assets/icons/ProfileArrow.svg";
import { ReactComponent as OnboardingIcon } from "../../assets/icons/question-checkmark-circle.svg";
import NoAvatarUser from "../../assets/icons/AvatarDefault.png";
// import NoImagePlaceholder from '../../assets/icons/AvatarDefault.png';
import { ReactComponent as ArrowRight } from "../../assets/icons/ArrowRight.svg";
import { ReactComponent as TickSmall } from "../../assets/icons/TickSmall.svg";
import { getPricingPage } from "../../constants/endpoints";
// Redux
import {
    postLogoutRequest,
    fetchAccountRequest,
} from "../../store/auth/requests";
import { actions as resultsActions } from "../../store/results/slice";
import {
    getCreditsSelector,
    getSubscriptionSelector,
    getTeamSelector,
    getUserSelector,
    getLogoutStatusSelector,
    getAccountStatusSelector,
    getRegisterStatusSelector,
} from "../../store/auth/selectors";
// import { mixpanelTrackRequest } from "../../store/mixpanel/requests";
// Constants
import { REQUEST_SUCCESS } from "../../constants/statuses";
import LinkBtn from "../../components/LinkBtn/LinkBtn";
import { Divider, Drawer, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import IconButton from "@material-ui/core/IconButton";
// import TopUpCreditsModal from "../Company/LockedPage/TopUpCreditsModal/TopUpCreditsModal";
import useLocalStorage from "../../common/useLocalStorage";

const Sidebar = ({
    match,
    sidebarOpened,
    toggleSidebar,
    sidebarHovered,
    hoverSidebar,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openPage } = useRouterHook();
    const [isMobileOpened, setIsMobileOpened] = useState(false);
    const { isAuthenticated, user } = useAuthHook();
    // const [creditsDropdownOpened, setCreditsDropdownOpened] = useState(false);
    const [freeTrialEndingModalShow, setFreeTrialEndingModalShow] =
        useState(false);
    const [currentlyOnFreePlanModalShow, setCurrentlyOnFreePlanModalShow] =
        useState(false);
    const getUser = useSelector(getUserSelector);
    const getCredits = useSelector(getCreditsSelector());
    const subscription = useSelector(getSubscriptionSelector());
    // const nextBillingDate = useSelector(getNextBillingDateSelector());
    const isOwner = getUser && getUser.team && getUser.team.role === "owner";
    const isOtherPlan =
        getUser &&
        getUser.subscription &&
        Object.keys(getUser.subscription).length !== 0 &&
        getUser.subscription.constructor === Object &&
        getUser.subscription &&
        getUser.subscription.name !== "pro" &&
        getUser.subscription &&
        getUser.subscription.name !== "teams";
    const shouldBeBuying = isOwner || isOtherPlan;
    const isInviteBtnVisible =
        getUser &&
        getUser.team &&
        (getUser.team.plan === "pro" || getUser.team.plan === "teams") &&
        isOwner;
    const [credits, setCredits] = useState(0);
    const [isAdditionalSidebarMenu, setIsAdditionalSidebarMenu] =
        useState(false);
    const [isCreditsSidebarMenu, setIsCreditsSidebarMenu] = useState(false);
    const [isTeamsSidebarMenu, setIsTeamsSidebarMenu] = useState(false);
    const [logOutOpened, setLogOutOpened] = useState(false);
    // const [confirmFreeTrialModalShow, setConfirmFreeTrialModalShow] = useState(false);
    const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
    const logoutSelector = useSelector(getLogoutStatusSelector);
    const registerStatus = useSelector(getRegisterStatusSelector);
    const team = useSelector(getTeamSelector);
    const history = useHistory();
    const { state: locationState, pathname } = useLocation();
    const [menuItems, setMenuItems] = useState([]);
    const getAccountStatus = useSelector(getAccountStatusSelector);

    const sessionSlugResults = sessionStorage.getItem(`results-slug`);
    const sessionSlugIndustry = sessionStorage.getItem(`industry-slug`);
    const sessionSlugTechnology = sessionStorage.getItem(`technology-slug`);
    const { clearStorage: clearResultsStorage } = useLocalStorage(
        "results",
        sessionSlugResults,
    );
    const { clearStorage: clearResultsPageStorage } = useLocalStorage(
        "results",
        "page",
    );
    const { clearStorage: clearIndustryStorage } = useLocalStorage(
        "industry",
        sessionSlugIndustry,
    );
    const { clearStorage: clearTechnologyStorage } = useLocalStorage(
        "technology",
        sessionSlugTechnology,
    );

    const handleClosingInviteModal = () => {
        setShowInviteUsersModal(false);
    };

    const signOut = () => {
        dispatch(resultsActions.getSearchesStatusUndefined());
        dispatch(postLogoutRequest());
        clearResultsStorage();
        clearIndustryStorage();
        clearTechnologyStorage();
        clearResultsPageStorage();
        localStorage.removeItem("isSharedResultsPageToken");
        localStorage.removeItem("search_id");
        dispatch(resultsActions.setCurrentSearchSid(null));
        dispatch(resultsActions.setCurrentSearch(null));
        dispatch(resultsActions.getRecentSearchesStatusUndefined());
    };

    useEffect(() => {
        logoutSelector === REQUEST_SUCCESS &&
            registerStatus !== REQUEST_SUCCESS &&
            openPage("/sign-in");
    }, [logoutSelector]);

    useEffect(() => {
        getCredits
            ? setCredits(getCredits)
            : getUser &&
              getUser.team &&
              getUser.team.credits &&
              setCredits(getUser.team.credits);
    }, [getCredits, getUser]);

    // useEffect(() => {
    //     setIsMobileOpened(sidebarOpened);
    //     if (!!sidebarOpened) {
    //         document.body.style.overflow = "hidden";
    //     } else {
    //         document.body.style.overflow = "auto";
    //         setIsAdditionalSidebarMenu(false);
    //         setIsTeamsSidebarMenu(false);
    //     }
    // }, [sidebarOpened]);

    const isSharedResultsPageToken = localStorage.getItem(
        "isSharedResultsPageToken",
    );

    const handleCloseSidebar = () => {
        // onVisibilityChange(false);
        setIsAdditionalSidebarMenu(false);
        setIsTeamsSidebarMenu(false);
    };

    const slugShared = `share/search/${isSharedResultsPageToken}`;

    const NavigationItem = React.forwardRef((props, ref) => {
        const {
            children,
            match,
            disabled,
            slug,
            additionalSlug,
            isProfile,
            isLogout,
            isCredits,
            onClick,
            className,
            intercomId,
        } = props;

        const classes = useStyles();

        const handleItemClick = slug => {
            if (!isProfile) {
                handleCloseSidebar();
                // onVisibilityChange(false);
            }
            if (
                !(match === slug || match === additionalSlug) &&
                (match === "results" || match === "saved-searches")
            ) {
                dispatch(resultsActions.clearSearchesResults());
            }
            if (onClick) {
                onClick();
            } else {
                openPage(`/${slug}`);
            }
        };

        return (
            <div ref={ref}>
                <LinkBtn
                    path={`/${slug}`}
                    variant="sidebar"
                    leftAligned
                    {...((match === slug ||
                        (!!additionalSlug && match === additionalSlug)) && {
                        active: true,
                    })}
                    className={classnames({
                        [classes.navigationItemClosed]:
                            !sidebarOpened && !sidebarHovered,
                        [classes.navigationItem]:
                            sidebarOpened || sidebarHovered,
                        [classes.profileNavigation]: isProfile,
                        [classes.logoutNavigation]: isLogout,
                        [classes.isCreditsNavigation]: isCredits,
                        [className]: className,
                    })}
                    disabled={disabled}
                    fullWidth
                    test-id={`${props.testId}-link-sidebar`}
                    data-intercom-target={`${intercomId} ${t(
                        "sidebar.sidebarItem",
                    )}`}>
                    {children}
                </LinkBtn>
            </div>
        );
    });

    const handleProfileClick = () => {
        setIsAdditionalSidebarMenu(true);
    };

    const handleCreditsMenuClick = () => {
        setIsCreditsSidebarMenu(true);
    };

    const handleTeamsMenuClick = () => {
        setIsTeamsSidebarMenu(true);
    };

    const handleCloseAdditionalSidebar = () => {
        setIsAdditionalSidebarMenu(false);
    };
    //Free trial
    const {
        // freeTrialState,
        // trialStatusSelector,
        // handleStartTrial,
        handleSendVerificationEmail,
        showChangeEmailModal,
        setShowChangeEmailModal,
        freeEmailInputError,
        showEmailValidateModal,
        setShowEmailValidateModal,
        handleClosingOnboardingFreeTrialModal,
        showPlanEndedModal,
        setShowPlanEndedModal,
        changeEmailAction,
        // emailChangeSelector,
        talkToSales,
    } = useFreeTrialHook({ user: getUser });

    const [showOnboardingTrialModal, setShowOnboardingTrialModal] =
        useState(false);

    const handleOnboardingClose = () => {
        handleClosingOnboardingFreeTrialModal();
        setShowOnboardingTrialModal(false);
    };

    // useEffect(() => {
    //     trialStatusSelector === REQUEST_SUCCESS && dispatch(getMeRequest());
    // }, [trialStatusSelector])
    //Free trial

    // const handleConfirmStartTrial = () => {
    //     handleStartTrial();
    //     setConfirmFreeTrialModalShow(false);
    // }

    // const handleStartFreeTrial = () => {
    //     if(!user){
    //         openPage("/sign-up?signUpForFreeTrial=true");
    //     } else if (user.subscription && user.subscription.name === "free"){
    //         setConfirmFreeTrialModalShow(true);
    //     }
    // }

    let teamsMenuItems = [];

    const [currentTeam, setCurrentTeam] = useState();
    const [currentTeamName, setCurrentTeamName] = useState();

    useEffect(() => {
        currentTeam && setCurrentTeamName(currentTeam.name);
    }, [currentTeam]);

    useEffect(() => {
        if (user && user.team && user.teams_other) {
            teamsMenuItems[0] = {
                team: user.team.name,
                slug: user.team.slug,
                label: (
                    <Typography className={classes.menuItem}>
                        {user.team.name}
                        {currentTeam && currentTeam.slug === user.team.slug && (
                            <TickSmall />
                        )}
                    </Typography>
                ),
                onClick: () =>
                    dispatch(fetchAccountRequest({ team: user.team.slug })),
            };
            user.teams_other.map((team, i) => {
                teamsMenuItems[i + 1] = {
                    team: team.name,
                    slug: team.slug,
                    label: (
                        <Typography className={classes.menuItem}>
                            {team.name}
                            {currentTeam && currentTeam.slug === team.slug && (
                                <TickSmall />
                            )}
                        </Typography>
                    ),
                    onClick: () =>
                        dispatch(fetchAccountRequest({ team: team.slug })),
                };
            });
            setCurrentTeam(user.team);
            setMenuItems(teamsMenuItems);
        }
    }, [user, team]);

    useEffect(() => {
        if (user && user.team && user.teams_other) {
            teamsMenuItems[0] = {
                team: user.team.name,
                slug: user.team.slug,
                label: (
                    <Typography className={classes.menuItem}>
                        {user.team.name}
                        {currentTeam && currentTeam.slug === user.team.slug && (
                            <TickSmall />
                        )}
                    </Typography>
                ),
                onClick: () =>
                    dispatch(fetchAccountRequest({ team: user.team.slug })),
            };
            user.teams_other.map((team, i) => {
                teamsMenuItems[i + 1] = {
                    team: team.name,
                    slug: team.slug,
                    label: (
                        <Typography className={classes.menuItem}>
                            {team.name}
                            {currentTeam && currentTeam.slug === team.slug && (
                                <TickSmall />
                            )}
                        </Typography>
                    ),
                    onClick: () =>
                        dispatch(fetchAccountRequest({ team: team.slug })),
                };
            });
            setMenuItems(teamsMenuItems);
        }
    }, [currentTeam, user]);

    useEffect(() => {
        getAccountStatus === REQUEST_SUCCESS && window.location.reload();
    }, [getAccountStatus]);

    const drawerWidth = 240;

    const drawerStyles = makeStyles(theme => ({
        hide: {
            display: "none",
        },
        hcPaddingOpened: {
            padding: `16px 32px 0 37px !important`,
            "& > svg": {
                marginRight: "15px",
            },
        },
        hcPaddingClosed: {
            display: "flex",
            flexDirection: "column",
            padding: "15px 0 5px 0",
        },
        creditsPaddingOpened: {
            display: "flex",
            flexDirection: "column",
            padding: "0 49px 0 20px !important",
            color: theme.palette.primary.dark,
            lineHeight: 1,
            fontSize: "14px",
        },
        creditsPaddingClosed: {
            display: "flex",
            flexDirection: "column",
            margin: 0,
            fontSize: `12px`,
            color: theme.palette.primary.dark,
            lineHeight: 1,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9) + 1,
            },
        },
    }));

    const classes2 = drawerStyles();

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes2.drawer, {
                [classes2.drawerOpen]: sidebarOpened || sidebarHovered,
                [classes2.drawerClosed]: !sidebarOpened && !sidebarHovered,
            })}
            classes={{
                paper: clsx({
                    [classes2.drawerOpen]: sidebarOpened || sidebarHovered,
                    [classes2.drawerClose]: !sidebarOpened && !sidebarHovered,
                }),
            }}>
            <div className={classes.mainSidebar}>
                <div className={classes.logoTopNav}>
                    {sidebarOpened || sidebarHovered ? (
                        <img src={LogoNavbar} alt="Valuer Logo" />
                    ) : (
                        <img src={LogoShort} alt="Valuer Logo" />
                    )}
                </div>
                {/*<IconButton onClick={() => toggleSidebar(!sidebarOpened)} className={classes.arrowIcon2}>*/}
                {/*	{sidebarOpened ? <ArrowLeftIcon/> : <ArrowRightIcon/>}*/}
                {/*</IconButton>*/}
                <div
                    className={classes.navigationItems}
                    // onMouseOver={() => hoverSidebar(true)}
                    // onMouseLeave={() => hoverSidebar(false)}>
                >
                    <div className={classes.topIcons}>
                        {/* <NavigationItem
                            className={clsx({
                                [classes.iconFull]:
                                    sidebarOpened || sidebarHovered,
                                [classes.iconShort]:
                                    !sidebarOpened && !sidebarHovered,
                            })}
                            match={match}
                            slug={"dashboard"}
                            additionalSlug={"dashboard/lookalike"}
                            testId="dashboard"
                            intercomId={t("sidebar.Dashboard")}>
                            <DashboardIcon />
                            <span
                                className={clsx({
                                    [classes.shortIconLabel]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.iconLabel]:
                                        sidebarOpened || sidebarHovered,
                                })}>
                                {t("sidebar.dashboard")}
                            </span>
                        </NavigationItem> */}
                        <NavigationItem
                            match={match}
                            slug="search-type"
                            additionalSlug="search-type/lookalike"
                            testId="search-type"
                            intercomId={t("sidebar.search")}>
                            <SearchIcon />
                            <span
                                className={clsx({
                                    [classes.shortIconLabel]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.iconLabel]:
                                        sidebarOpened || sidebarHovered,
                                })}>
                                {t("sidebar.search")}
                            </span>
                        </NavigationItem>
                        <NavigationItem
                            match={match}
                            slug={"results"}
                            additionalSlug={"results"}
                            testId="results"
                            intercomId={t("sidebar.results")}>
                            <ResultsIcon />
                            <span
                                className={clsx({
                                    [classes.shortIconLabel]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.iconLabel]:
                                        sidebarOpened || sidebarHovered,
                                })}>
                                {t("sidebar.results")}
                            </span>
                        </NavigationItem>
                        <NavigationItem
                            match={match}
                            slug={"workspace"}
                            testId="workspace"
                            additionalSlug="projects"
                            intercomId={t("sidebar.workspace")}>
                            <WorkspaceIcon />
                            <span
                                className={clsx({
                                    [classes.shortIconLabel]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.iconLabel]:
                                        sidebarOpened || sidebarHovered,
                                })}>
                                {t("sidebar.workspace")}
                            </span>
                        </NavigationItem>
                        {/* <NavigationItem
                            match={match}
                            slug={"industries"}
                            testId="industries"
                            intercomId={t("sidebar.industries")}>
                            <IndustriesIcon />
                            <span
                                className={clsx({
                                    [classes.shortIconLabel]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.iconLabel]:
                                        sidebarOpened || sidebarHovered,
                                })}>
                                {t("sidebar.industries")}
                            </span>
                        </NavigationItem>
                        <NavigationItem
                            match={match}
                            slug={"technologies"}
                            testId="technologies"
                            intercomId={t("sidebar.technologies")}>
                            <TechnologiesIcon />
                            <span
                                className={clsx({
                                    [classes.shortIconLabel]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.iconLabel]:
                                        sidebarOpened || sidebarHovered,
                                })}>
                                {t("sidebar.technologies")}
                            </span>
                        </NavigationItem>
                        <NavigationItem
                            match={match}
                            slug={"orders"}
                            testId="orders"
                            intercomId={t("sidebar.orders")}>
                            <OrdersIcon />
                            <span
                                className={clsx({
                                    [classes.shortIconLabel]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.iconLabel]:
                                        sidebarOpened || sidebarHovered,
                                })}>
                                {t("sidebar.orders")}
                            </span>
                        </NavigationItem> */}
                    </div>
                    <div className={classes.bottomIcons}>
                        {/* <a
                            href="https://help.valuer.ai/en/"
                            className={clsx(
                                classes.linkResources,
                                classes.helpCenter,
                                {
                                    [classes2.hcPaddingOpened]:
                                        sidebarOpened || sidebarHovered,
                                    [classes2.hcPaddingClosed]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.navigationItemClosed]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.navigationItem]:
                                        sidebarOpened || sidebarHovered,
                                },
                            )}
                            target="_blank">
                            <HelpCenterIcon />
                            {
                                <>
                                    <span
                                        className={clsx({
                                            [classes.shortIconLabel]:
                                                !sidebarOpened &&
                                                !sidebarHovered,
                                            [classes.iconLabel]:
                                                sidebarOpened || sidebarHovered,
                                        })}>
                                        {t("sidebar.helpCenter")}
                                    </span>
                                    {sidebarOpened ||
                                        (sidebarHovered && (
                                            <ShareLinkIcon
                                                className={classes.ml8}
                                            />
                                        ))}
                                </>
                            }
                        </a> */}
                        <NavigationItem
                            match={match}
                            slug={"profile-settings"}
                            testId="profile-settings"
                            intercomId={t("sidebar.settings")}
                            className={clsx(classes.settingsLink, {
                                [classes.settingsLinkClosed]:
                                    !sidebarOpened && !sidebarHovered,
                                [classes.settingsLinkOpen]:
                                    sidebarOpened || sidebarHovered,
                            })}>
                            <SettingsIcon />
                            <span
                                className={clsx({
                                    [classes.shortIconLabel]:
                                        !sidebarOpened && !sidebarHovered,
                                    [classes.iconLabel]:
                                        sidebarOpened || sidebarHovered,
                                    [classes.settingsLinkOpen]:
                                        sidebarOpened || sidebarHovered,
                                })}>
                                {t("sidebar.settings")}
                            </span>
                        </NavigationItem>
                    </div>
                </div>
            </div>
        </Drawer>
    );

    /* Saving this for later because it holds the free trial logic*/
    return (
        <div
            className={classnames([
                classes.sidebarRoot,
                { [classes.mobileOpened]: isMobileOpened },
            ])}
            test-id="sidebar">
            <div className={classes.mainSidebar}>
                <div>
                    {
                        <>
                            <NavigationItem
                                match={match}
                                slug={"dashboard"}
                                additionalSlug={"dashboard/lookalike"}
                                testId="dashboard"
                                intercomId={t("sidebar.Dashboard")}>
                                <DashboardIcon />
                                {t("sidebar.dashboard")}
                            </NavigationItem>
                        </>
                    }
                    {/*{isSharedResultsPageToken && isSharedResultsPageToken !== "undefined" ?*/}
                    {/*    <NavigationItem*/}
                    {/*        match={match}*/}
                    {/*        slug={slugShared}*/}
                    {/*        testId="share"*/}
                    {/*        intercomId={t('sidebar.search')}*/}
                    {/*    >*/}
                    {/*        <SearchIcon />{t('sidebar.search')}*/}
                    {/*    </NavigationItem> :*/}
                    {/*    <NavigationItem*/}
                    {/*        match={match}*/}
                    {/*        slug="results"*/}
                    {/*        testId="results"*/}
                    {/*        intercomId={t('sidebar.search')}*/}
                    {/*    >*/}
                    {/*        <SearchIcon />{t('sidebar.search')}*/}
                    {/*    </NavigationItem>*/}
                    {/*}*/}
                    <NavigationItem
                        match={match}
                        slug="search-type"
                        additionalSlug="search-type/lookalike"
                        testId="search-type"
                        intercomId={t("sidebar.search")}>
                        <SearchIcon />
                        {t("sidebar.search")}
                    </NavigationItem>
                    <NavigationItem
                        match={match}
                        slug={"results"}
                        additionalSlug={"results"}
                        testId="results"
                        intercomId={t("sidebar.results")}>
                        <ResultsIcon />
                        {t("sidebar.results")}
                    </NavigationItem>
                    <NavigationItem
                        match={match}
                        slug={"workspace"}
                        testId="workspace"
                        additionalSlug="projects"
                        intercomId={t("sidebar.workspace")}>
                        <WorkspaceIcon />
                        {t("sidebar.workspace")}
                    </NavigationItem>
                    <NavigationItem
                        match={match}
                        slug={"industries"}
                        testId="industries"
                        intercomId={t("sidebar.industries")}>
                        <IndustriesIcon />
                        {t("sidebar.industries")}
                    </NavigationItem>
                    <NavigationItem
                        match={match}
                        slug={"technologies"}
                        testId="technologies"
                        intercomId={t("sidebar.technologies")}>
                        <TechnologiesIcon />
                        {t("sidebar.technologies")}
                    </NavigationItem>
                    <NavigationItem
                        match={match}
                        slug={"orders"}
                        testId="orders"
                        intercomId={t("sidebar.orders")}>
                        <OrdersIcon />
                        {t("sidebar.orders")}
                    </NavigationItem>
                    <a
                        href="https://help.valuer.ai/en/"
                        className={classes.linkResources}
                        target="_blank">
                        <HelpCenterIcon className={classes.helpCenterIcon} />
                        <div>{t("sidebar.helpCenter")}</div>
                        <ShareLinkIcon className={classes.ml8} />
                    </a>
                </div>
                <div className={classes.sidebarBottomArea}>
                    <div className="laptop">
                        {/* trial starting block */}
                        {/* {!userLoading && user && user.subscription && user.subscription.name === "free" && <div className={classes.trialBlock}>
                            <div className={classes.trialBannerWrap}>
                                <Typography className={classes.trialTitle} variant="body"><strong>{t('freeTrial.startFreeTrial')}</strong></Typography>
                                <Typography className={classes.trialBannerWrapText} variant="bodyXs">{t('freeTrial.experience')} <Typography variant="bodyXs" color="primary">{t('freeTrial.oneFreeCredit')}</Typography><Typography variant="bodyXs">{t('freeTrial.toUsePlatform')}</Typography></Typography>
                                <Button onClick={() => setConfirmFreeTrialModalShow(true)} className={classes.startTrialButton} variant="primaryDarkContained">{t('freeTrial.startTrial')}</Button>
                                <Typography className={classes.noCreditCard} variant="label" color="textLighter">{t('freeTrial.noCreditCard')}</Typography>
                                <div className={classes.talkToSalesWrap}>
                                    <Typography className={classes.customTrials} variant="label">{t('freeTrial.customTrials')}</Typography>
                                    <Typography onClick={() => talkToSales()} className={classes.talkSales} variant="label">{t('freeTrial.talkToSales')}</Typography>
                                </div>
                            </div>

                        </div>} */}

                        {/* trial starting block */}
                        {/* during trial */}
                        {user &&
                            user.subscription &&
                            user.subscription.free_trial &&
                            user.subscription.free_trial && (
                                <div className={classes.trialBlock}>
                                    <div className={classes.trialBannerWrap}>
                                        {user.subscription.free_trial
                                            .is_active ? (
                                            <Typography
                                                className={classes.trialTitle}
                                                variant="body">
                                                <strong>
                                                    {t(
                                                        "freeTrial.freeTrailEnding",
                                                        {
                                                            time:
                                                                user &&
                                                                user.subscription &&
                                                                user
                                                                    .subscription
                                                                    .free_trial &&
                                                                user
                                                                    .subscription
                                                                    .free_trial
                                                                    .remaining_time,
                                                        },
                                                    )}
                                                </strong>
                                            </Typography>
                                        ) : (
                                            <Typography
                                                className={classes.trialTitle}
                                                variant="body">
                                                <strong>
                                                    {t("freeTrial.onFreePlan")}
                                                </strong>
                                            </Typography>
                                        )}

                                        {user.subscription.free_trial
                                            .is_active ? (
                                            <Typography variant="bodyXs">
                                                {t(
                                                    "freeTrial.freeTrialUpgradeNow",
                                                )}{" "}
                                                <Typography
                                                    variant="bodyXs"
                                                    color="primary">
                                                    {t(
                                                        "freeTrial.onCreditBonus",
                                                    )}
                                                </Typography>
                                                <Typography variant="bodyXs">
                                                    {" "}
                                                    {t("freeTrial.instantly")}
                                                </Typography>
                                            </Typography>
                                        ) : (
                                            <Typography
                                                className={
                                                    classes.trialBannerWrapText
                                                }
                                                variant="bodyXs">
                                                {t("freeTrial.onFreePlanDesc")}
                                            </Typography>
                                        )}
                                        <Button
                                            onClick={() =>
                                                (window.location.href =
                                                    getPricingPage(
                                                        !!user && "free",
                                                    ))
                                            }
                                            className={classes.startTrialButton}
                                            variant="primaryDarkContained">
                                            {t("freeTrial.upgradeAccount")}
                                        </Button>
                                        <div
                                            className={classes.talkToSalesWrap}>
                                            <Typography
                                                className={classes.customTrials}
                                                variant="label">
                                                {t("freeTrial.haveQuestions")}
                                            </Typography>
                                            <Typography
                                                onClick={() => talkToSales()}
                                                className={classes.talkSales}
                                                variant="label">
                                                {t("freeTrial.talkToSales")}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            )}
                        {/* during trial */}
                    </div>
                    <div className={classes.divider} />
                    {isAuthenticated ? (
                        <div
                            className={classnames([
                                classes.userSection,
                                "laptop",
                            ])}>
                            {getUser && getUser.team && getUser.teams_other && (
                                <NavigationItem
                                    match={match}
                                    isProfile
                                    onClick={handleTeamsMenuClick}
                                    className={classnames([
                                        classes.accountSwitcherLaptop,
                                        "non-mobile-flex",
                                    ])}>
                                    {currentTeamName}
                                    <ProfileArrow
                                        className={classes.profileArrow}
                                    />
                                </NavigationItem>
                            )}
                            {user &&
                                user.subscription &&
                                user.subscription.name !== "free" && (
                                    <div className={"non-mobile"}>
                                        <NavigationItem
                                            match={match}
                                            isProfile
                                            onClick={handleCreditsMenuClick}>
                                            <TopUpCredits />
                                            {t("topNav.creditsLeft", {
                                                count: credits,
                                            })}
                                            <ProfileArrow
                                                className={classes.profileArrow}
                                            />
                                        </NavigationItem>
                                    </div>
                                )}
                            {subscription && isInviteBtnVisible && (
                                <NavigationItem
                                    match={match}
                                    isProfile
                                    onClick={() =>
                                        setShowInviteUsersModal(true)
                                    }>
                                    <InviteUsers
                                        test-id="invite-users"
                                        onClick={() =>
                                            setShowInviteUsersModal(true)
                                        }
                                    />
                                    {t("sidebar.sendAnInvite")}
                                </NavigationItem>
                            )}
                            {showInviteUsersModal && (
                                <InviteUsersModal
                                    dialogOpened={showInviteUsersModal}
                                    onChange={handleClosingInviteModal}
                                />
                            )}
                            <div className={"mobile"}>
                                <NavigationItem
                                    match={match}
                                    slug={"profile"}
                                    testId="profile"
                                    isProfile
                                    onClick={handleProfileClick}>
                                    <div
                                        className={classes.avatarSidebar}
                                        test-id="sidebar-avatar">
                                        {user && user.avatar ? (
                                            <img
                                                onClick={() =>
                                                    setLogOutOpened(
                                                        !logOutOpened,
                                                    )
                                                }
                                                src={user.avatar}
                                                className={classes.avatar}
                                                alt={t(
                                                    "sidebar.userNameExample",
                                                )}
                                            />
                                        ) : (
                                            <img
                                                onClick={() =>
                                                    setLogOutOpened(
                                                        !logOutOpened,
                                                    )
                                                }
                                                src={NoAvatarUser}
                                                className={classes.avatar}
                                                alt={t(
                                                    "sidebar.userNameExample",
                                                )}
                                            />
                                        )}
                                    </div>
                                    {t("sidebar.profile")}
                                    <ProfileArrow
                                        className={classes.profileArrow}
                                    />
                                </NavigationItem>
                                {/* {(!user || (user && user.subscription && user.subscription.name === "free")) && <div className={classes.upgradeBox} test-id="start-free-trial-cta-mobile">
                                <Typography color="black" component="div" className={classes.upgradeText} align="center" variant="bodyXs">{t('sidebar.upgradeText')}</Typography>
                                <Typography color="primaryDark" component="div" test-id="start-trial-link-sidebar" className={classes.startTrial} variant="bodyButton" onClick={() => setConfirmFreeTrialModalShow(true)}>{t('sidebar.startTrial')}</Typography>
                            </div>} */}
                                {user &&
                                user.subscription &&
                                user.subscription.free_trial &&
                                user.subscription.free_trial.is_active ? (
                                    <div
                                        className={classes.upgradeBox}
                                        test-id="free-trial-ending-cta-mobile">
                                        <Typography
                                            color="black"
                                            component="div"
                                            className={classes.upgradeText}
                                            align="center"
                                            variant="bodyXs">
                                            {t("sidebar.upgradeText")}
                                        </Typography>
                                        <Typography
                                            color="primaryDark"
                                            component="div"
                                            test-id="start-trial-link-sidebar"
                                            className={classes.startTrial}
                                            variant="bodyButton"
                                            onClick={() =>
                                                setFreeTrialEndingModalShow(
                                                    true,
                                                )
                                            }>
                                            {t(
                                                "freeTrial.freeTrailEndingMobile",
                                                {
                                                    time:
                                                        user &&
                                                        user.subscription &&
                                                        user.subscription
                                                            .free_trial &&
                                                        user.subscription
                                                            .free_trial
                                                            .remaining_time,
                                                },
                                            )}
                                        </Typography>
                                    </div>
                                ) : (
                                    user &&
                                    user.subscription &&
                                    user.subscription.free_trial &&
                                    !user.subscription.free_trial.is_active && (
                                        <div
                                            className={classes.upgradeBox}
                                            test-id="upgrade-cta-mobile">
                                            <Typography
                                                color="black"
                                                component="div"
                                                className={classes.upgradeText}
                                                align="center"
                                                variant="bodyXs">
                                                {t("sidebar.upgradeText")}
                                            </Typography>
                                            <Typography
                                                color="primaryDark"
                                                component="div"
                                                test-id="start-trial-link-sidebar"
                                                className={classes.startTrial}
                                                variant="bodyButton"
                                                onClick={() =>
                                                    setCurrentlyOnFreePlanModalShow(
                                                        true,
                                                    )
                                                }>
                                                {t("freeTrial.upgradeAccount")}
                                            </Typography>
                                        </div>
                                    )
                                )}
                            </div>
                            <div className={"non-mobile"}>
                                {user &&
                                    user.subscription &&
                                    user.subscription.free_trial &&
                                    user.subscription.free_trial.is_active && (
                                        <NavigationItem
                                            match={match}
                                            slug={"onboarding"}
                                            testId="onboarding"
                                            isProfile
                                            onClick={() =>
                                                setShowOnboardingTrialModal(
                                                    true,
                                                )
                                            }>
                                            <OnboardingIcon
                                                test-id="profile-settings"
                                                className={
                                                    classes.avatarSidebar
                                                }
                                            />
                                            {t("sidebar.onboardingTour")}
                                        </NavigationItem>
                                    )}
                                <NavigationItem
                                    match={match}
                                    slug={"profile"}
                                    testId="profile"
                                    isProfile
                                    onClick={() =>
                                        openPage("/profile-settings")
                                    }>
                                    {match === "profile-settings" ? (
                                        <ProfileSettingsActiveIcon
                                            test-id="profile-settings"
                                            className={classes.avatarSidebar}
                                            onClick={() =>
                                                openPage("/profile-settings")
                                            }
                                        />
                                    ) : (
                                        <ProfileSettingsIcon
                                            className={classes.avatarSidebar}
                                            onClick={() =>
                                                openPage("/profile-settings")
                                            }
                                        />
                                    )}
                                    {t("sidebar.profileAndSettings")}
                                </NavigationItem>
                                <NavigationItem
                                    match={match}
                                    slug={"profile"}
                                    testId="profile"
                                    onClick={signOut}
                                    isLogout>
                                    <div
                                        className={classes.avatarSidebar}
                                        test-id="sidebar-logout">
                                        {user && user.avatar ? (
                                            <img
                                                onClick={() =>
                                                    setLogOutOpened(
                                                        !logOutOpened,
                                                    )
                                                }
                                                src={user.avatar}
                                                className={classes.avatar}
                                                alt={t(
                                                    "sidebar.userNameExample",
                                                )}
                                            />
                                        ) : (
                                            <img
                                                onClick={() =>
                                                    setLogOutOpened(
                                                        !logOutOpened,
                                                    )
                                                }
                                                src={NoAvatarUser}
                                                className={classes.avatar}
                                                alt={t(
                                                    "sidebar.userNameExample",
                                                )}
                                            />
                                        )}
                                    </div>
                                    {t("sidebar.logOut")}
                                </NavigationItem>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={classnames([
                                classes.authenticationButtons,
                                "laptop-flex",
                            ])}>
                            <Button
                                variant="textSecondary"
                                className={classes.signInButtonSidebar}
                                onClick={() =>
                                    history.replace("/sign-in", {
                                        from: pathname,
                                        someOtherProp: "",
                                    })
                                }>
                                {t("topNav.signIn")}
                            </Button>
                            {/* <Button variant="quaternary" onClick={() => openPage("/sign-up")}>{t("results.signUpNow")}</Button> */}
                        </div>
                    )}
                    {/* {user && user.subscription && (user.subscription.name === "free" || user.subscription.free_trial) && (
                        <div className={classes.upgradeBox}>
                            <Typography color="black" component="div" className={classes.upgradeText} align="center" variant="bodyXs">{t('sidebar.upgradeText')}</Typography>
                            <Typography color="primaryDark" component="div" test-id="upgrade-link-sidebar" className={classes.upgradeCta} variant="bodyButton" onClick={() => window.location.href = getPricingPage("free")}>{t('sidebar.upgradeCta')}</Typography>
                        </div>
                    )} */}
                </div>
            </div>
            {isAdditionalSidebarMenu && (
                <div className={classes.additionalSidebarMenu}>
                    <Link
                        onClick={handleCloseAdditionalSidebar}
                        variant="body2"
                        rotateIcon
                        color="text"
                        className={classes.goBack}>
                        {t("sidebar.back")}
                        <ArrowRight />
                    </Link>
                    <div className={classes.avatarIcon}>
                        {user &&
                            user.subscription &&
                            user.subscription.name !== "free" && (
                                <div>
                                    <NavigationItem
                                        match={match}
                                        isCredits
                                        disabled>
                                        <TopUpCredits />
                                        {t("topNav.creditsLeft", {
                                            count: credits,
                                        })}
                                    </NavigationItem>

                                    <div className={classes.creditsSummaryBox}>
                                        <Typography
                                            color="primaryDark"
                                            component="div"
                                            test-id="see-credits-summary-sidebar"
                                            className={classes.upgradeCta}
                                            variant="bodyButton"
                                            onClick={() =>
                                                openPage("/credit-summary")
                                            }>
                                            {t(
                                                "profileSettings.seeCreditSummary",
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                        {subscription && isInviteBtnVisible && (
                            <NavigationItem
                                match={match}
                                isProfile
                                onClick={() => setShowInviteUsersModal(true)}>
                                <InviteUsers test-id="invite-users" />
                                {t("sidebar.sendAnInvite")}
                            </NavigationItem>
                        )}
                        {user &&
                            user.subscription &&
                            user.subscription.free_trial &&
                            user.subscription.free_trial.is_active && (
                                <NavigationItem
                                    match={match}
                                    slug={"onboarding"}
                                    testId="onboarding"
                                    isProfile
                                    onClick={() =>
                                        setShowOnboardingTrialModal(true)
                                    }>
                                    <OnboardingIcon
                                        test-id="profile-settings"
                                        className={classes.avatarSidebar}
                                    />
                                    {t("sidebar.onboardingTour")}
                                </NavigationItem>
                            )}
                        <NavigationItem
                            match={match}
                            slug={"profile"}
                            testId="profile"
                            onClick={() => openPage("/profile-settings")}>
                            {match === "profile-settings" ? (
                                <ProfileSettingsActiveIcon
                                    test-id="profile-settings"
                                    className={classes.avatarSidebar}
                                    onClick={() =>
                                        openPage("/profile-settings")
                                    }
                                />
                            ) : (
                                <ProfileSettingsIcon
                                    className={classes.avatarSidebar}
                                    onClick={() =>
                                        openPage("/profile-settings")
                                    }
                                />
                            )}
                            {t("sidebar.profileAndSettings")}
                        </NavigationItem>
                        <NavigationItem
                            match={match}
                            slug={"profile"}
                            testId="profile"
                            onClick={signOut}
                            isLogout>
                            <div
                                className={classes.avatarSidebar}
                                test-id="mobile-sidebar-logout">
                                {user && user.avatar ? (
                                    <img
                                        onClick={() =>
                                            setLogOutOpened(!logOutOpened)
                                        }
                                        src={user.avatar}
                                        className={classes.avatar}
                                        alt={t("sidebar.userNameExample")}
                                    />
                                ) : (
                                    <img
                                        onClick={() =>
                                            setLogOutOpened(!logOutOpened)
                                        }
                                        src={NoAvatarUser}
                                        className={classes.avatar}
                                        alt={t("sidebar.userNameExample")}
                                    />
                                )}
                            </div>
                            {t("sidebar.logOut")}
                        </NavigationItem>
                    </div>
                </div>
            )}
            {isTeamsSidebarMenu && (
                <div className={classes.additionalSidebarMenu}>
                    <Link
                        onClick={() => setIsTeamsSidebarMenu(false)}
                        variant="body2"
                        rotateIcon
                        color="text"
                        className={classes.goBack}>
                        {t("sidebar.back")}
                        <ArrowRight />
                    </Link>
                    {menuItems.map(item => (
                        <NavigationItem
                            match={match}
                            isCredits
                            onClick={() =>
                                dispatch(
                                    fetchAccountRequest({ team: item.slug }),
                                )
                            }>
                            {item.label}
                        </NavigationItem>
                    ))}
                </div>
            )}
            {isCreditsSidebarMenu && (
                <div className={classes.creditsSidebarMenu}>
                    <Link
                        onClick={() => setIsCreditsSidebarMenu(false)}
                        variant="body2"
                        rotateIcon
                        color="text"
                        className={classes.goBack}>
                        {t("sidebar.back")}
                        <ArrowRight />
                    </Link>
                    <div className={classes.avatarIcon}>
                        {user &&
                            user.subscription &&
                            user.subscription.name !== "free" && (
                                <div>
                                    <NavigationItem
                                        match={match}
                                        isCredits
                                        disabled>
                                        <TopUpCredits />
                                        {t("topNav.creditsLeft", {
                                            count: credits,
                                        })}
                                    </NavigationItem>
                                    <div className={classes.creditsSummaryBox}>
                                        <Typography
                                            color="primaryDark"
                                            component="div"
                                            test-id="see-credits-summary-sidebar"
                                            className={classes.upgradeCta}
                                            variant="bodyButton"
                                            onClick={() =>
                                                openPage("/credit-summary")
                                            }>
                                            {t(
                                                "profileSettings.seeCreditSummary",
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            )}
            <div className="non-laptop">
                <div className={classes.dividerDesktop} />
                {/* trial starting block */}
                {/* {!userLoading && (!user || (user && user.subscription && user.subscription.name === "free")) && <div className={classes.trialBlock}>
                    <div className={classes.trialBannerWrap}>
                        <Typography className={classes.trialTitle} variant="body"><strong>{t('freeTrial.startFreeTrial')}</strong></Typography>
                        <Typography className={classes.trialBannerWrapText} variant="bodyXs">{t('freeTrial.experience')} <Typography variant="bodyXs" color="primary">{t('freeTrial.oneFreeCredit')}</Typography><Typography variant="bodyXs">{t('freeTrial.toUsePlatform')}</Typography></Typography>
                        <Button onClick={handleStartFreeTrial} className={classes.startTrialButton} variant="primaryDarkContained">{t('freeTrial.startTrial')}</Button>
                        <Typography className={classes.noCreditCard} variant="label" color="textLighter">{t('freeTrial.noCreditCard')}</Typography>
                        <div className={classes.talkToSalesWrap}>
                            <Typography className={classes.customTrials} variant="label">{t('freeTrial.customTrials')}</Typography>
                            <Typography onClick={() => talkToSales()} className={classes.talkSales} variant="label">{t('freeTrial.talkToSales')}</Typography>
                        </div>
                    </div>

                </div>} */}

                {/* trial starting block */}
                {/* during trial */}
                {user &&
                    user.subscription &&
                    user.subscription.free_trial &&
                    user.subscription.free_trial && (
                        <div className={classes.trialBlock}>
                            <div className={classes.trialBannerWrap}>
                                {user.subscription.free_trial.is_active ? (
                                    <Typography
                                        className={classes.trialTitle}
                                        variant="body">
                                        <strong>
                                            {t("freeTrial.freeTrailEnding", {
                                                time:
                                                    user &&
                                                    user.subscription &&
                                                    user.subscription
                                                        .free_trial &&
                                                    user.subscription.free_trial
                                                        .remaining_time,
                                            })}
                                        </strong>
                                    </Typography>
                                ) : (
                                    <Typography
                                        className={classes.trialTitle}
                                        variant="body">
                                        <strong>
                                            {t("freeTrial.onFreePlan")}
                                        </strong>
                                    </Typography>
                                )}

                                {user.subscription.free_trial.is_active ? (
                                    <Typography
                                        className={
                                            classes.activeTrialBannerWrapText
                                        }
                                        variant="bodyXs">
                                        {t("freeTrial.freeTrialUpgradeNow")}
                                    </Typography>
                                ) : (
                                    <Typography
                                        className={classes.trialBannerWrapText}
                                        variant="bodyXs">
                                        {t("freeTrial.onFreePlanDesc")}
                                    </Typography>
                                )}
                                {/* <Button onClick={() => window.location.href = getPricingPage(!!user && "free")} className={classes.startTrialButton} variant="primaryDarkContained">{t('freeTrial.upgradeAccount')}</Button> */}
                                <div className={classes.talkToSalesWrap}>
                                    <Typography
                                        className={classes.customTrials}
                                        variant="label">
                                        {t("freeTrial.haveQuestions")}
                                    </Typography>
                                    <Typography
                                        onClick={() => talkToSales()}
                                        className={classes.talkSales}
                                        variant="label">
                                        {t("freeTrial.talkToSales")}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}
                {/* during trial */}
            </div>
            <ChangeEmailModal
                user={getUser}
                freeEmailInputError={freeEmailInputError}
                dialogOpened={showChangeEmailModal}
                onClose={() => setShowChangeEmailModal(false)}
                onChangeEmailAction={changeEmailAction}
            />
            <VerifyEmailModal
                user={getUser}
                dialogOpened={showEmailValidateModal}
                onClose={() => setShowEmailValidateModal(false)}
                onResendValidationEmail={handleSendVerificationEmail}
            />
            <PlanEndedModal
                user={getUser}
                dialogOpened={showPlanEndedModal}
                onClose={() => setShowPlanEndedModal(false)}
            />
            {/* <ConfirmFreeTrialStartModal user={getUser} onClose={() => setConfirmFreeTrialModalShow(false)} onConfirm={handleConfirmStartTrial} dialogOpened={confirmFreeTrialModalShow}/> */}
            <UpgradePlanModal
                user={getUser}
                onClose={() => setFreeTrialEndingModalShow(false)}
                title={t("freeTrial.freeTrailEnding", {
                    time:
                        user &&
                        user.subscription &&
                        user.subscription.free_trial &&
                        user.subscription.free_trial.remaining_time,
                })}
                desc={t("freeTrial.freeTrialUpgradeNow")}
                dialogOpened={freeTrialEndingModalShow}
            />
            <UpgradePlanModal
                user={getUser}
                onClose={() => setCurrentlyOnFreePlanModalShow(false)}
                title={t("freeTrial.onFreePlan")}
                desc={t("freeTrial.onFreePlanDesc")}
                dialogOpened={currentlyOnFreePlanModalShow}
            />
            <OnboardingFreeTrialModal
                user={getUser}
                dialogOpened={showOnboardingTrialModal}
                onClose={handleOnboardingClose}
                onInvite={() => setShowInviteUsersModal(true)}
            />
        </div>
    );
};

export default Sidebar;
