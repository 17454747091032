import { createSlice } from "@reduxjs/toolkit";
import findIndex from "lodash/findIndex";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    searches: null,
    totalCount: null,
    totalSearchesCount: null,
    totalSearchesPages: null,
    sid: null,
    searchType: null,
    currentPage: 1,
    company: {},
    generatedLink: "",
    getResultsStatus: REQUEST_UNDEFINED,
    getSearchesStatus: REQUEST_UNDEFINED,
    getRecentSearchesStatus: REQUEST_UNDEFINED,
    getSearchesEditSearchStatus: REQUEST_UNDEFINED,
    postSearchesStatus: REQUEST_UNDEFINED,
    getResultsBySidStatus: REQUEST_UNDEFINED,
    getCompanyStatus: REQUEST_UNDEFINED,
    getGenerateLinkStatus: REQUEST_UNDEFINED,
    voteMlResultStatus: REQUEST_UNDEFINED,
    getResultsPaginationStatus: REQUEST_UNDEFINED,
    getResultsBySidPaginationStatus: REQUEST_UNDEFINED,
    generateShareLinkStatus: REQUEST_UNDEFINED,
    getResultsCountStatus: REQUEST_UNDEFINED,
    getCompaniesChartStatus: REQUEST_UNDEFINED,
    getTeamSizeChartStatus: REQUEST_UNDEFINED,
    getLikedCompaniesStatus: REQUEST_UNDEFINED,
    likedCompanies: [],
    likedCompaniesTotalCount: null,
    likedCompaniesCurrentPage: 1,
    likedCompaniesTotalPages: 0,
    generateShareLinkState: {},
    totalPages: 0,
    lookalikeBannerHidden: false,
    resultsCount: null,
    companiesChart: null,
    teamsizeChart: null,
    unspecifiedAll: false,
    filterStats: null,
    searchQuery: null,
    likedFilterStats: null,
    lastFilteredField: null,
    displayFilters: {},
    lastSearchNamed: null,
    postSearchesMessage: null,
    activeView: "list",
    currentSearchSid: null,
    currentSearch: null,
    conversationContext: null,
};

export const REDUCER_PREFIX = "results";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        setActiveView: (state, { payload }) => {
            state.activeView = payload;
        },
        getResultsPending: state => {
            state.getResultsStatus = REQUEST_PENDING;
        },
        getResultsSuccess: state => {
            state.getResultsStatus = REQUEST_SUCCESS;
        },
        getResultsFailure: state => {
            state.getResultsStatus = REQUEST_FAILURE;
        },
        getLikedCompaniesPending: state => {
            state.getLikedCompaniesStatus = REQUEST_PENDING;
        },
        getLikedCompaniesSuccess: state => {
            state.getLikedCompaniesStatus = REQUEST_SUCCESS;
        },
        getLikedCompaniesFailure: state => {
            state.getLikedCompaniesStatus = REQUEST_FAILURE;
        },
        getResultsPaginationPending: state => {
            state.getResultsPaginationStatus = REQUEST_PENDING;
        },
        getResultsPaginationSuccess: state => {
            state.getResultsPaginationStatus = REQUEST_SUCCESS;
        },
        getResultsPaginationFailure: state => {
            state.getResultsPaginationStatus = REQUEST_FAILURE;
        },
        getResultsBySidPaginationPending: state => {
            state.getResultsBySidPaginationStatus = REQUEST_PENDING;
        },
        getResultsBySidPaginationSuccess: state => {
            state.getResultsBySidPaginationStatus = REQUEST_SUCCESS;
        },
        getResultsBySidPaginationFailure: state => {
            state.getResultsBySidPaginationStatus = REQUEST_FAILURE;
        },
        getSearchesStatusPending: state => {
            state.getSearchesStatus = REQUEST_PENDING;
        },
        getSearchesStatusSuccess: state => {
            state.getSearchesStatus = REQUEST_SUCCESS;
        },
        getSearchesStatusFailure: state => {
            state.getSearchesStatus = REQUEST_FAILURE;
        },
        getRecentSearchesStatusPending: state => {
            state.getRecentSearchesStatus = REQUEST_PENDING;
        },
        getRecentSearchesStatusSuccess: state => {
            state.getRecentSearchesStatus = REQUEST_SUCCESS;
        },
        getRecentSearchesStatusFailure: state => {
            state.getRecentSearchesStatus = REQUEST_FAILURE;
        },
        getRecentSearchesStatusUndefined: state => {
            state.getRecentSearchesStatus = REQUEST_UNDEFINED;
        },
        postSearchesStatusPending: state => {
            state.postSearchesStatus = REQUEST_PENDING;
        },
        postSearchesStatusSuccess: state => {
            state.postSearchesStatus = REQUEST_SUCCESS;
        },
        postSearchesStatusFailure: (state, { payload }) => {
            state.postSearchesStatus = REQUEST_FAILURE;
            state.postSearchesMessage = payload;
        },
        clearPostSearchesMessage: state => {
            state.postSearchesMessage = null;
        },
        postSearchesStatusUndefined: state => {
            state.postSearchesStatus = REQUEST_UNDEFINED;
        },
        getSearchesEditSearchStatusPending: state => {
            state.getSearchesEditSearchStatus = REQUEST_PENDING;
        },
        getSearchesEditSearchStatusSuccess: state => {
            state.getSearchesEditSearchStatus = REQUEST_SUCCESS;
        },
        getSearchesEditSearchStatusFailure: state => {
            state.getSearchesEditSearchStatus = REQUEST_FAILURE;
        },
        getSearchesStatusUndefined: state => {
            state.getSearchesStatus = REQUEST_UNDEFINED;
        },
        setSearchesEditSearchStatusUndefined: state => {
            state.getSearchesEditSearchStatus = REQUEST_UNDEFINED;
        },
        getCompanyPending: state => {
            state.getCompanyStatus = REQUEST_PENDING;
        },
        getCompanySuccess: state => {
            state.getCompanyStatus = REQUEST_SUCCESS;
        },
        getCompanyFailure: state => {
            state.getCompanyStatus = REQUEST_FAILURE;
        },
        postGenerateLinkPending: state => {
            state.getGenerateLinkStatus = REQUEST_PENDING;
        },
        postGenerateLinkSuccess: state => {
            state.getGenerateLinkStatus = REQUEST_SUCCESS;
        },
        postGenerateLinkFailure: state => {
            state.getGenerateLinkStatus = REQUEST_FAILURE;
        },
        postGenerateLinkUndefined: state => {
            state.getGenerateLinkStatus = REQUEST_UNDEFINED;
        },
        setCompany: (state, { payload }) => {
            state.company = payload;
        },
        clearCompany: state => {
            state.company = {};
            state.getCompanyStatus = REQUEST_UNDEFINED;
        },
        hideLookalikeBanner: (state, { payload }) => {
            state.lookalikeBannerHidden = true;
        },
        removeResult: (state, { payload }) => {
            const {
                data: {
                    data: { pagination, results },
                },
            } = payload;
            if (results) {
                state.searches = results;
                state.totalSearchesCount = pagination.total;
                state.totalSearchesCount = pagination.total;
                state.currentPage = pagination.current_page;
                state.totalSearchesPages = pagination.total_pages;
            }
        },
        setResults: (state, { payload }) => {
            const statePool = state.pool;
            state.pool = !payload.url ? [] : statePool;
            payload.data &&
                payload.data.results.forEach(result => {
                    const {
                        name,
                        degree_of_fit,
                        deck,
                        sectors,
                        subsectors,
                        description,
                        founded_date,
                        hq_city,
                        hq_country,
                        investment_stage,
                        number_of_employees,
                        total_funding_amount_dollars,
                        id,
                        slug,
                        success_potential_score,
                        growth_score,
                        maturity_score,
                        innovation_score,
                        completeness_score,
                        website,
                        sustainability_score,
                        enrichment_request,
                        ready_for_release,
                        priority,
                        search_id,
                        valuer_score,
                        user_vote,
                        voters,
                        comments_cnt,
                        projects,
                    } = result;
                    const uniqueResult = {
                        id,
                        name,
                        degree_of_fit,
                        deck,
                        sectors,
                        subsectors,
                        description,
                        founded_date,
                        hq_city,
                        hq_country,
                        investment_stage,
                        number_of_employees,
                        total_funding_amount_dollars:
                            total_funding_amount_dollars
                                ? Math.round(
                                      parseInt(total_funding_amount_dollars),
                                  )
                                : "",
                        success_potential_score,
                        growth_score,
                        maturity_score,
                        innovation_score,
                        slug,
                        website,
                        sustainability_score,
                        completeness_score,
                        enrichment_request,
                        ready_for_release,
                        priority,
                        search_id,
                        valuer_score,
                        user_vote,
                        voters,
                        comments_cnt,
                        projects,
                    };
                    state.pool.push(uniqueResult);
                });
            state.totalCount = payload.data && payload.data.pagination.total;
            state.filterStats = payload.data && payload.data.filter_stats;
            state.searchQuery = payload.data && payload.data.search_query;
            state.currentPage =
                payload.data && payload.data.pagination.current_page;
            state.sid = payload.data && payload.data.sid;
            state.totalPages =
                payload.data && payload.data.pagination.total_pages;
            state.searchType =
                payload.data && payload.data.pagination.search_type;
            state.getResultsBySidStatus = REQUEST_UNDEFINED;
            // state.getResultsStatus = REQUEST_UNDEFINED;
            state.getResultsPaginationStatus = REQUEST_UNDEFINED;
            state.getResultsBySidPaginationStatus = REQUEST_UNDEFINED;
            state.resultsCount = payload.data.pagination.total;
            state.cluster = payload.data && payload.data.cluster;
            state.conversationContext =
                payload.data && payload.data.conversation_context;
        },
        setCurrentSearchSid: (state, { payload }) => {
            state.currentSearchSid = payload;
        },
        setCurrentSearch: (state, { payload }) => {
            state.currentSearch = payload;
        },
        setCurrentSearchUnsaved: state => {
            const currentSearch = state.currentSearch;
            state.currentSearch = { ...currentSearch, saved: false };
        },
        setLikedCompanies: (state, { payload }) => {
            const stateLikedCompanies = state.likedCompanies;
            state.likedCompanies = !payload.url ? [] : stateLikedCompanies;
            payload.data &&
                payload.data.results.forEach(result => {
                    const {
                        name,
                        degree_of_fit,
                        deck,
                        sectors,
                        subsectors,
                        description,
                        founded_date,
                        hq_city,
                        hq_country,
                        investment_stage,
                        number_of_employees,
                        total_funding_amount_dollars,
                        id,
                        slug,
                        success_potential_score,
                        growth_score,
                        maturity_score,
                        innovation_score,
                        completeness_score,
                        website,
                        sustainability_score,
                        enrichment_request,
                        ready_for_release,
                        priority,
                        search_id,
                        valuer_score,
                        user_vote,
                        voters,
                        comments_cnt,
                        projects,
                    } = result;
                    const uniqueResult = {
                        id,
                        name,
                        degree_of_fit,
                        deck,
                        sectors,
                        subsectors,
                        description,
                        founded_date,
                        hq_city,
                        hq_country,
                        investment_stage,
                        number_of_employees,
                        total_funding_amount_dollars:
                            total_funding_amount_dollars
                                ? Math.round(
                                      parseInt(total_funding_amount_dollars),
                                  )
                                : "",
                        success_potential_score,
                        growth_score,
                        maturity_score,
                        innovation_score,
                        slug,
                        website,
                        sustainability_score,
                        completeness_score,
                        enrichment_request,
                        ready_for_release,
                        priority,
                        search_id,
                        valuer_score,
                        user_vote,
                        voters,
                        comments_cnt,
                        projects,
                    };
                    state.likedCompanies.push(uniqueResult);
                });
            state.likedCompaniesTotalCount =
                payload.data && payload.data.pagination.total;
            state.likedCompaniesCurrentPage =
                payload.data && payload.data.pagination.current_page;
            state.likedCompaniesTotalPages =
                payload.data && payload.data.pagination.total_pages;
            state.likedFilterStats = payload.data && payload.data.filter_stats;
        },
        setLikedCompaniesCount: (
            state,
            { payload: { sliceData, lastFilteredField } },
        ) => {
            state.resultsCount = sliceData && sliceData.data.pagination.total;
            let lastFilteredState = {
                ...state.likedFilterStats[lastFilteredField],
            };
            state.likedFilterStats = {
                ...sliceData.data.filter_stats,
                [lastFilteredField]: lastFilteredState,
            };
        },
        setResultsCount: (
            state,
            {
                payload: {
                    sliceData,
                    lastFilteredField,
                    lastFilteredFieldValue,
                },
            },
        ) => {
            state.resultsCount = sliceData.data.pagination.total;
            let lastFilteredState =
                state.filterStats && state.filterStats[lastFilteredField]
                    ? { ...state.filterStats[lastFilteredField] }
                    : {};
            if (
                (lastFilteredField === "investment_stages" ||
                    lastFilteredField === "countries" ||
                    lastFilteredField === "regions") &&
                lastFilteredFieldValue &&
                lastFilteredFieldValue.length > 0
            ) {
                state.filterStats = {
                    ...sliceData.data.filter_stats,
                    [lastFilteredField]: lastFilteredState,
                };
            } else {
                state.filterStats = sliceData.data.filter_stats;
            }
        },
        setLastFilteredField: (state, { payload }) => {
            state.lastFilteredField = payload;
        },
        getResultsCountPending: state => {
            state.getResultsCountStatus = REQUEST_PENDING;
        },
        getResultsCountSuccess: state => {
            state.getResultsCountStatus = REQUEST_SUCCESS;
        },
        getResultsCountFailure: state => {
            state.getResultsCountStatus = REQUEST_FAILURE;
        },
        clearResults: state => {
            state.pool = [];
            state.currentPage = 1;
            state.totalPages = null;
        },
        clearSearchesResults: state => {
            state.searches = [];
            state.getSearchesStatus = REQUEST_UNDEFINED;
            state.totalCount = null;
            state.totalPages = null;
        },
        clearOnlySearches: state => {
            state.searches = [];
            state.getSearchesStatus = REQUEST_UNDEFINED;
        },
        removeSearchesResult: (state, { payload }) => {
            const searches = state.searches;
            const currentSearchSid = state.currentSearchSid;
            const removeSearchIndex = findIndex(
                searches,
                search => search.id === payload.sid,
            );
            if (removeSearchIndex !== -1) {
                searches.splice(removeSearchIndex, 1);
            }
            if (payload.sid === currentSearchSid) {
                state.currentSearchSid = null;
                state.currentSearch = null;
            }
            state.searches = searches;
            state.totalSearchesCount = state.totalSearchesCount - 1;
        },
        setSearches: (state, { payload }) => {
            const statePool = state.searches;
            const currentSearch = state.currentSearch;
            const updatedSearches = !!statePool && [
                { ...currentSearch },
                ...statePool,
            ];
            state.searches = !payload.url
                ? []
                : !!currentSearch
                ? updatedSearches
                : statePool;
            payload &&
                payload.data &&
                payload.data.results.forEach(res => {
                    state.searches.push(res);
                });
            state.totalSearchesCount =
                payload && payload.data && payload.data.pagination.total;
            state.totalSearchesPages =
                payload && payload.data && payload.data.pagination.total_pages;
        },
        setSearchName: (state, { payload }) => {
            const statePool = state.searches;
            const searchIndex = findIndex(
                statePool,
                search => search.id === payload.sid,
            );
            state.lastSearchNamed = payload.sid;
            state.searches[searchIndex] = {
                ...statePool[searchIndex],
                search_name: payload.search_name,
                saved: true,
            };
            const currentSearchState = state.currentSearch;
            state.currentSearch = {
                ...currentSearchState,
                search_name: payload.search_name,
                saved: true,
            };
        },
        setGeneratedLink: (state, { payload }) => {
            state.generatedLink = payload;
        },
        voteMlResultPending: state => {
            state.voteMlResultStatus = REQUEST_PENDING;
        },
        voteMlResultSuccess: state => {
            state.voteMlResultStatus = REQUEST_SUCCESS;
        },
        voteMlResultFailure: state => {
            state.voteMlResultStatus = REQUEST_FAILURE;
        },
        generateShareLinkPending: state => {
            state.generateShareLinkStatus = REQUEST_PENDING;
        },
        generateShareLinkSuccess: state => {
            state.generateShareLinkStatus = REQUEST_SUCCESS;
        },
        generateShareLinkFailure: state => {
            state.generateShareLinkStatus = REQUEST_FAILURE;
        },
        generateShareLinkUndefined: state => {
            state.generateShareLinkStatus = REQUEST_UNDEFINED;
        },
        setShareableLink: (state, { payload }) => {
            state.generateShareLinkState = payload.data.link;
        },
        getCompaniesChartPending: state => {
            state.getCompaniesChartStatus = REQUEST_PENDING;
        },
        getCompaniesChartSuccess: state => {
            state.getCompaniesChartStatus = REQUEST_SUCCESS;
        },
        getCompaniesChartFailure: state => {
            state.getCompaniesChartStatus = REQUEST_FAILURE;
        },
        setCompaniesChart: (state, { payload }) => {
            state.companiesChart = payload;
        },
        getTeamSizeChartPending: state => {
            state.getTeamSizeChartStatus = REQUEST_PENDING;
        },
        getTeamSizeChartSuccess: state => {
            state.getTeamSizeChartStatus = REQUEST_SUCCESS;
        },
        getTeamSizeChartFailure: state => {
            state.getTeamSizeChartStatus = REQUEST_FAILURE;
        },
        setTeamSizeChart: (state, { payload }) => {
            state.unspecifiedAll =
                payload.Unspecified && payload.Unspecified.percentage === 100;
            delete payload.Unspecified;
            state.teamsizeChart = payload;
        },
        setResultsDisplayFilters: (state, { payload }) => {
            state.displayFilters = {
                ...state.displayFilters,
                [payload.sid ? payload.sid : "results"]:
                    payload.displayedFilters,
            };
        },
        setResultsDisplayFilter: (state, { payload }) => {
            state.displayFilters = {
                ...state.displayFilters,
                [payload.sid ? payload.sid : "results"]: {
                    ...state.displayFilters[
                        payload.sid ? payload.sid : "results"
                    ],
                    [payload.displayedFilterName]: payload.displayedFilterValue,
                },
            };
        },
    },
});
export { reducer, actions };
