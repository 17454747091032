import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
// Components
import AreYouSureModal from "../../../../components/AreYouSureModal/AreYouSureModal";
import Typography from "../../../../components/Typography/Typography";
import DownloadButton from "../../../../components/DownloadButton/DownloadButton";
import { AddToProjectMenu } from "../../../../components/AddToProjectMenu/AddToProjectMenu";
// Hooks
import { useAppEventTracking } from "../../../../common/useAppEventTracking";
// Redux
import { exportCsvRequest } from "../../../../store/orders/requests";
// Constants
import { getPricingPage } from "../../../../constants/endpoints";
//Assets
import { ReactComponent as Save } from "../../../../assets/icons/SaveWorkspaceActive.svg";
import { ReactComponent as MoreIcon } from "../../../../assets/icons/MoreIconWorkspace.svg";

const TableToolbarRight = React.forwardRef(
    (
        {
            isLikedCompanies,
            setSelected,
            companiesPageId,
            slug,
            numSelected,
            rowCount,
            onSelectAllClick,
            className,
            classes,
            dispatch,
            isTechnology,
            isIndustry,
            isLikedCompany,
            searchResults,
            selected,
            selectedNames,
            isAuthenticated,
            user,
            isProject,
            projectSlug,
            id,
            sortValue,
            sortDirection,
            isWorkspace = false,
            ...props
        },
        ref,
    ) => {
        const { t } = useTranslation();
        const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
        const [showDownloadsExceededModal, setShowDownloadsExceededModal] =
            useState(false);
        const [isMenuOpened, setIsMenuOpened] = useState(false);
        const [downloadProps, setDownloadProps] = useState();

        useEffect(() => {
            return () => {
                setShowAreYouSureModal(false);
                setShowDownloadsExceededModal(false);
                setDownloadProps();
            };
        }, []);

        const { mixpanelTrackEvent } = useAppEventTracking();
        const getDownloadProps = (ids, type, page, pageType, doc) => {
            setDownloadProps({
                ids,
                type,
                page,
                pageType,
                doc,
                name:
                    ids.length > 1
                        ? "valuer_company_reports"
                        : `valuer_${selectedNames[0]}_report`,
                sortBy: sortValue,
                sortDirection,
            });
        };

        const download = () => {
            dispatch(exportCsvRequest(downloadProps));
            mixpanelTrackEvent(`Downloaded document`, {
                type: downloadProps.doc,
                page: isIndustry
                    ? "industry"
                    : isTechnology
                    ? "technology"
                    : isProject
                    ? "project"
                    : "results",
            });
        };

        const handleToggleMenu = value => {
            setIsMenuOpened(value);
        };

        return (
            <div
                className={classnames([classes.toolbarRight, className])}
                ref={ref}>
                <div className={classes.toolbarRightButtons}>
                    {!isWorkspace ? (
                        <DownloadButton
                            user={user}
                            isIndustry={isIndustry}
                            isTechnology={isTechnology}
                            isProject={isProject}
                            isLikedCompanies={isLikedCompanies}
                            selected={selected}
                            setSelected={setSelected}
                            dispatch={dispatch}
                            id={id}
                            setShowAreYouSureModal={setShowAreYouSureModal}
                            setShowDownloadsExceededModal={
                                setShowDownloadsExceededModal
                            }
                            getDownloadProps={getDownloadProps}
                            name="valuer_company_reports"
                            sortBy={sortValue && sortValue.slug}
                            sortDirection={sortDirection}
                            page={
                                isIndustry
                                    ? "industry"
                                    : isTechnology
                                    ? "technology"
                                    : isProject
                                    ? "project"
                                    : slug === "likedCompanies"
                                    ? "liked companies"
                                    : "results"
                            }
                        />
                    ) : null}

                    <AddToProjectMenu
                        buttonType="saveitems"
                        companies={selected}
                        companiesNames={selectedNames}
                        disabled={!selected || selected.length === 0}
                        type="company"
                    />
                    {/*<DownloadButton*/}
                    {/*	user={user}*/}
                    {/*	isIndustry={isIndustry}*/}
                    {/*	isTechnology={isTechnology}*/}
                    {/*	isProject={isProject}*/}
                    {/*	selected={selected}*/}
                    {/*	dispatch={dispatch}*/}
                    {/*	id={id}*/}
                    {/*	setShowAreYouSureModal={setShowAreYouSureModal}*/}
                    {/*	setShowDownloadsExceededModal={setShowDownloadsExceededModal}*/}
                    {/*	getDownloadProps={getDownloadProps}*/}
                    {/*	name={selected.length > 1 ? "valuer_company_reports" : `valuer_${selectedNames[0]}_report`}*/}
                    {/*	className={classes.downloadButton}*/}
                    {/*	sortBy={(sortValue && sortValue.slug) ? sortValue.slug : sortValue}*/}
                    {/*	sortDirection={sortDirection}*/}
                    {/*	page={isIndustry ? "industry" : isTechnology ? "technology" : isProject ? "project" : "results"}*/}
                    {/*	icon={<MoreIcon*/}
                    {/*		className={classnames([classes.moreIcon, {[classes.iconEnabled]: (selected.length > 0)}, {[classes.iconDownloadOpen]: isMenuOpened}])}/>}*/}
                    {/*	handleToggleMenu={handleToggleMenu}*/}
                    {/*/>*/}
                    {/* <MoreIcon className={classnames([classes.moreIcon])} /> */}
                </div>
                <AreYouSureModal
                    dialogOpened={showAreYouSureModal}
                    onChange={() => setShowAreYouSureModal(false)}
                    title={t("download.areYouSureTitle")}
                    subtitle={
                        <>
                            {t("download.subtitleFreeTrial1")}
                            <Typography
                                component="div"
                                className={classes.actionText}
                                variant="body"
                                color="secondaryDark">
                                {t("download.subtitleFreeTrial2")}
                            </Typography>
                        </>
                    }
                    actionButtonText={t("download.iWantToDownload")}
                    cancelButtonText={t("download.letsTryLater")}
                    onActionClick={download}
                />
                <AreYouSureModal
                    dialogOpened={showDownloadsExceededModal}
                    onChange={() => setShowDownloadsExceededModal(false)}
                    title={t("download.downloadsExceededTitle")}
                    subtitle={t("download.downloadsExceededSubtitle")}
                    actionButtonText={t("download.upgradePlan")}
                    cancelButtonText={t("download.upgradeLater")}
                    onActionClick={() =>
                        (window.location.href = getPricingPage(!!user))
                    }
                />
            </div>
        );
    },
);

export default TableToolbarRight;
