import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) =>
    createStyles({
        dialogRoot: {
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: "center",
            userSelect: "none",
        },
        dialog: {
            padding: spacing(4),
            background: palette.primary.backgroundLight,
            borderRadius: spacing(2),
            width: "100%",
            maxWidth: 816,
        },
        dialogFilter: {
            padding: spacing(3),
            background: palette.common.white,
            borderRadius: shape.borderRadius,
        },
        dialogScrollPaper: {
            maxWidth: 816,
            width: "100%",
        },
        dialogContent: {
            overflow: "auto",
            margin: "auto",
        },
        closeButton: {
            position: "absolute !important",
            right: spacing(4),
            top: spacing(3),
            color: palette.grey[500],
        },
        title: {
            margin: `51px 0 ${spacing(3)}px 0`,
        },
        analystsCanPrepare: {
            marginTop: spacing(3),
        },
        subtitle: {
            color: palette.text.main,
            marginTop: spacing(2),
        },
        projectRow: {
            paddingBottom: spacing(1),
            borderBottom: "1px solid",
            borderBottomColor: palette.secondary.main,
            marginTop: spacing(3),
            cursor: "pointer",
        },
        selectedProject: {
            color: palette.primary.main,
        },
        buttons: {
            marginTop: spacing(6),
            marginBottom: spacing(3),
            display: "flex",
            justifyContent: "center",
        },
        requestIcon: {
            marginRight: spacing(1),
        },
        eur: {
            fontSize: 24,
            lineHeight: "22px",
            marginLeft: 5,
        },
        priceWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: spacing(3),
            userSelect: "none",
        },
        topUpDownNumber: {
            margin: `0 ${spacing(3)}px`,
            userSelect: "none",
        },
        topUpDownIcon: {
            cursor: "pointer",
        },
        createButton: {
            marginRight: spacing(1),
            background: palette.primary.dark,
        },
        noCreatedProjects: {
            color: palette.grey.main,
        },

        upgradePlan: {
            color: palette.primary.dark,
            textDecoration: "underline",
            cursor: "pointer",
        },
    }),
);
