import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            margin: `0 auto`,
            padding: spacing(4),
            [breakpoint.down("lg")]: {
                padding: spacing(3),
            },
            [breakpoint.down("sm")]: {
                padding: spacing(2),
            },
        },
        // rootHeader: {
        //     margin: `0 auto`,
        //     padding: `${spacing(4)}px ${spacing(4)}px 0px ${spacing(4)}px`,
        //     maxWidth: "1136px",
        // },
        title: {
            color: palette.secondary.main,
            marginBottom: spacing(1),
            marginTop: spacing(3),
        },
        arrowUp: {
            position: "fixed",
            bottom: 10,
            right: 80,
            fill: "#0B295F",
            cursor: "pointer",
            "&:hover": {
                fill: "#485E87",
            },
        },
        rootPricing: {
            margin: `0 auto`,
            padding: spacing(4),
            maxWidth: "1136px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        noSearches: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        shTitle: {
            marginTop: spacing(9),
        },
        shSubtitle: {
            marginTop: spacing(2),
            marginBottom: spacing(4),
            maxWidth: 571,
        },
        image: {
            marginTop: spacing(3),
        },
        shImage: {
            marginTop: spacing(5),
            maxWidth: 416,
        },
        shButton: {
            marginTop: spacing(8),
            background: palette.primary.dark,
        },
        rootSubscription: {
            margin: `0 auto`,
            padding: spacing(4),
            maxWidth: "1136px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    }),
);
