import { Canvas } from "@react-three/fiber";
import { useRef, useState, useEffect } from "react";
import Cluster from "./Cluster";
import * as THREE from "three";
import { Box } from "@react-three/drei";
import ContentLoader from "../ContentLoader/ContentLoader";

const Scene = ({
    coordinates,
    resetCamera,
    toggleRotation,
    handleClick,
    openModal,
    user,
}) => {
    const groupRef = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 4000);
    }, []);
    if (loading) return <ContentLoader />;
    if (coordinates) {
        return (
            <div
                style={{
                    position: "absolute",
                    top: 10,
                    left: "5%",
                    right: "5%",
                    bottom: "80px",
                    // width: "800px",
                    // minWidth: "90%",
                    // marginLeft: "5%",
                    // maxWidth: "90%",
                    borderRadius: "8px",
                    visible: loading ? "hidden" : "visible",
                }}
                onMouseDown={() => handleClick()}>
                <Canvas
                    style={{ borderRadius: "8px" }}
                    camera={{ position: [70, 70, 70], fov: 40 }}>
                    <ambientLight intensity={0.5} />
                    <pointLight position={[15, 15, 15]} />
                    <directionalLight intensity={1} />
                    {/* Large cube background */}
                    <Box args={[600, 600, 600]} position={[0, 0, 0]}>
                        <meshStandardMaterial
                            color="#E6F2EF"
                            side={THREE.BackSide}
                        />
                    </Box>
                    {/* Wrap the Cluster in a Group for easy rotation */}
                    <group ref={groupRef}>
                        <Cluster
                            user={user}
                            toggleRotation={toggleRotation}
                            openModal={openModal}
                            coordinates={coordinates}
                            resetCamera={resetCamera}
                            handleClick={handleClick}
                        />
                    </group>
                </Canvas>
            </div>
        );
    }
};

export default Scene;
