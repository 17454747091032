import { makeStyles, createStyles } from "@material-ui/core/styles";
import BlurryResults from "../../assets/images/BlurryResults.png";
import BlurryResultsList from "../../assets/images/BlurryResultsList.png";

const closedSidebarWidth = 68;
const openedSidebarWidth = 240;

export const useStyles = makeStyles(
    ({ palette, spacing, shape, typography, breakpoint }) =>
        createStyles({
            resultsRoot: {
                margin: `0 auto`,
                padding: "0",
                background: palette.common.white,
                flexGrow: 1,
                [breakpoint.up("xxxl")]: {
                    width: "100%",
                    // maxWidth: 1900,
                    margin: "0 auto",
                    padding: `0 ${spacing(4)}px ${spacing(4)}px ${spacing(
                        4,
                    )}px`,
                    alignItems: "center",
                },
                [breakpoint.down("lg")]: {
                    padding: `0 0 ${spacing(2)}px 0`,
                },
                [breakpoint.down("sm")]: {
                    padding: 0,
                },
            },
            resultsLayout: {
                width: "50%",
            },
            resultsHeader: {
                padding: `4px ${spacing(3)}px ${spacing(1)}px ${spacing(3)}px`,
            },
            resultsSection: {
                height: "100%",
                display: "flex",
            },
            leftColumnFullWidth: {
                width: "100%",
            },
            leftColumn: {
                display: "flex",
                minWidth: "45%",
                flexDirection: "column",
                height: "calc(100vh - 70px)"

                // [breakpoint.up("lg")]: {
                //     height: "91vh",
                // },
                // [breakpoint.up("xxl")]: {
                //     height: "93vh",
                // },
                // [breakpoint.up("xxxxl")]: {
                //     height: "95vh",
                // },
            },
            rightColumn: {
                minWidth: "55%",
                height: "100%",
                paddingRight: spacing(2),
            },
            resultsContent: {
                // marginTop: spacing(3),
                // padding: "24px 0px 24px 15px",
                // margin: "0 32px",
                padding: 0,
                width: "90%",
                overflowY: "auto",
            },
            tabContainer: {
                height: "100%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start"
            },
            resultsContentHeight: {
                height: "calc(100% - 43px)",
            },
            resultsWithMultiplePages: {
                marginLeft: "5%",
                height: "100%"
            },
            cardsContent: {
                padding: `0 18px 0 ${spacing(3)}px`,
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: spacing(2),
                [breakpoint.down("xl")]: {
                    flexDirection: ({ user }) => (user?.csp ? "column" : "row"),
                    padding: spacing(2),
                    marginTop: 0,
                },
                [breakpoint.down("md")]: {
                    flexDirection: ({ user }) => !user?.csp && "column",
                },
            },
            cardClass: {},
            signUpBox: {
                maxWidth: 640,
                width: "100%",
                left: "calc(50% - 320px)",
                top: spacing(5),
                background: palette.secondary.main,
                color: palette.common.white,
                padding: spacing(4),
            },
            signUpText: {
                maxWidth: 344,
                marginTop: spacing(1),
                marginBottom: spacing(2),
            },
            selectedCard: {
                border: `1px solid ${palette.primary.main} !important`,
            },
            appliedFiltersWrap: {
                marginTop: spacing(1),
                display: "flex",
                flexDirection: "column",
            },
            appliedFilters: {
                display: "flex",
                flexWrap: "wrap",
            },
            filterTag: {
                background: palette.common.white,
                border: `1px solid ${palette.secondary.main};`,
                color: palette.secondary.main,
                padding: `0 ${spacing(1)}px`,
                borderRadius: shape.borderRadius,
                boxSizing: "border-box",
                marginTop: spacing(1),
                marginRight: spacing(1),
            },
            closeIcon: {
                maxWidth: spacing(1),
                maxHeight: spacing(1),
                marginLeft: spacing(1),
                cursor: "pointer",
            },
            root: {
                // background: palette.common.white,
                // border: "1px solid #F0F1F3",
                flex: "calc(50% - 8px)",
                maxWidth: "calc(50% - 8px)",
                // boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
                // borderRadius: 4,
                position: "relative",
                marginBottom: spacing(2),
                // padding: spacing(3),
                "&:nth-child(odd)": {
                    marginRight: spacing(2),
                    [breakpoint.down("sm")]: {
                        marginRight: 0,
                    },
                },
                "&:hover": {
                    boxShadow:
                        "0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)",
                    cursor: "pointer",
                },
                [breakpoint.down("sm")]: {
                    width: "100%",
                    flex: "100%",
                    maxWidth: "100%",
                },
            },
            header: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "space-between",
                [breakpoint.down("xl")]: {
                    flexDirection: "column",
                    paddingBottom: 13,
                    overflow: "hidden",
                },
            },
            tags: {
                display: "flex",
                marginTop: spacing(2),
            },
            tag: {
                marginRight: spacing(1),
            },
            avatar: {
                border: "1px solid #F0F1F3",
                borderRadius: spacing(1),
                padding: 4,
                marginRight: spacing(2),
            },
            arrowDown: {
                marginLeft: spacing(2),
            },
            score: {
                lineHeight: "40px",
                position: "absolute",
                top: 60,
            },
            scoreCaption: {
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",
            },
            infoIcon: {
                // marginLeft: spacing(1)
                position: "absolute",
                top: 12,
                right: 12,
            },
            typographyTag: {
                alignItems: "center",
                background: palette.primary.backgroundLight,
                height: 20,
                color: palette.text.main,
                padding: "4px 12px",
                borderRadius: "8px",
                display: "inline-flex",
                fontFamily: "GT Flexa Mono, sans-serif",
                ...typography.caption,
            },
            mt24: {
                marginTop: spacing(3),
                [breakpoint.down("xl")]: {
                    marginTop: spacing(1),
                },
            },
            mt0: {
                marginTop: -9,
                [breakpoint.down("xl")]: {
                    marginTop: spacing(3),
                },
            },
            info: {
                marginBottom: spacing(3),
                flex: "50%",
                [breakpoint.down("xl")]: {
                    marginBottom: 0,
                },
            },
            infoTitle: {
                marginBottom: 2,
                color: palette.text.main,
                [breakpoint.down("xl")]: {
                    color: palette.text.lighter,
                },
            },
            commentActionItems: {
                paddingTop: spacing(3),
                marginTop: spacing(3),
                borderTop: "1px solid #F0F1F3",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            questionMark: {
                marginRight: spacing(1),
            },
            communicationIcon: {
                padding: `${spacing(1)}px ${spacing(1)}px`,
            },
            likeIcon: {
                marginLeft: spacing(2),
                marginRight: spacing(1),
                [breakpoint.down("lg")]: {
                    marginLeft: 0,
                },
            },
            likeDislikeIcons: {
                display: "flex",
            },
            numberOfEmployees: {
                minHeight: 18,
            },
            enrichedProfileTag: {
                color: palette.common.white,
                background: palette.primary.main,
                padding: `0 ${spacing(1)}px`,
                borderRadius: shape.borderRadius,
                height: spacing(3),
                display: "flex",
                alignItems: "center",
                width: 105,
            },
            emptyTag: {
                height: spacing(3),
            },
            valuerScoreBlock: {
                position: "absolute",
                top: -32,
                right: -32,
                background: palette.primary.backgroundMedium,
                boxShadow: `inset 1px 0px 0px #EFEFEE`,
                height: 164,
                width: 164,
                borderTopRightRadius: spacing(2),
                [breakpoint.down("xl")]: {
                    top: -16,
                    right: -16,
                    width: 80,
                    height: 80,
                    borderTopRightRadius: spacing(2),
                },
            },
            valuerScore: {
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            },
            chart: {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: spacing(2),
            },
            scoreBreakdown: {
                marginTop: -spacing(1),
                position: "relative",
                color: palette.secondary.dark,
            },
            rotateArrow: {
                transform: "rotate(180deg)",
            },
            arrowIcon: {
                marginLeft: spacing(1),
            },
            disabled: {
                // pointerEvents: "none",
                opacity: 0.5,
            },
            sdg: {
                marginRight: spacing(1),
            },
            sdgTooltip: {
                textAlign: "center",
                color: palette.common.white,
            },
            sdgTooltipTitle: {
                fontWeight: "700",
            },
            blurryBg: {
                position: "relative",
                backgroundImage: ({ activeView }) =>
                    activeView === "list"
                        ? `url(${BlurryResultsList})`
                        : `url(${BlurryResults})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 611px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: spacing(2),
                minHeight: 611,
                width: "100%",
                height: 800,
                marginTop: spacing(1),
                [breakpoint.down("xl")]: {
                    backgroundImage: "none",
                    minHeight: 500,
                    height: 650,
                },
                [breakpoint.down("md")]: {
                    backgroundImage: "none",
                    minHeight: 500,
                    height: "auto",
                },
            },
            guestBlock: {
                background: palette.success.backgroundLight,
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.16)",
                borderRadius: spacing(2),
                padding: `${spacing(8)}px ${spacing(8)}px ${spacing(
                    5,
                )}px ${spacing(8)}px`,
                width: "86%",
                position: "absolute",
                marginTop: 100,
                textAlign: "center",
                marginBottom: 77,
                [breakpoint.down("xl")]: {
                    padding: `${spacing(3)}px ${spacing(4)}px ${spacing(
                        4,
                    )}px ${spacing(4)}px`,
                    margin: 0,
                    width: "100%",
                    position: "relative",
                    top: 0,
                },
            },
            guestBlockContent: {
                display: "flex",
                flexDirection: "column",
            },
            freeStartTrial: {
                position: "absolute",
                right: 0,
            },
            readyText: {
                color: palette.text.dark,
            },
            startTrialText: {
                color: palette.text.dark,
                marginTop: spacing(1),
            },
            getAccessText: {
                marginTop: spacing(3),
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            actions: {
                marginTop: spacing(4),
                display: "flex",
                justifyContent: "center",
            },
            signUp: {
                background: palette.success.dark,
                width: 200,
                border: "none",
                marginLeft: spacing(3),
            },
            signIn: {
                background: "transparent",
                border: `1px solid ${palette.success.dark}`,
                color: palette.success.dark,
                width: 200,
            },
            cardName: {
                display: "flex",
                flexDirection: "column",
                minHeight: 61,
            },
            headerWrapper: {
                minHeight: 132,
                position: "relative",
                "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: -32,
                    height: 1,
                    background: palette.text.backgroundMain,
                    [breakpoint.down("xl")]: {
                        width: "calc(100% + 16px)",
                    },
                },
                [breakpoint.down("xl")]: {
                    minHeight: 0,
                },
            },
            noFilteredResultsImg: {
                marginTop: spacing(4),
            },
            dislikeImg: {
                transform: "rotate(180deg)",
                marginLeft: spacing(2),
                marginRight: spacing(1),
            },
            noResultsNew: {
                margin: "0 auto",
            },
            noResults: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: `${spacing(8)}px 0`,
            },
            checkEnrichedCompanyImg: {
                marginRight: `${spacing(1)}px`,
            },
            enrichedCompanyInfo: {
                display: "flex",
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            enrichedCompanyInfoTitle: {
                color: palette.success.dark,
            },
            enrichedCompany: {
                display: "flex",
                alignItems: "center",
                backgroundColor: palette.success.backgroundLight,
                padding: `${spacing(1)}px`,
                borderRadius: `${spacing(1)}px`,
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            sdgs: {
                [breakpoint.down("xl")]: {
                    display: "none",
                },
            },
            checkEnrichedCompanyImgHeader: {
                width: 16,
                height: 16,
                marginRight: 3,
            },
            enrichedCompanyHeader: {
                display: "none",
                alignItems: "center",
                padding: 0,
                borderRadius: `${spacing(1)}px`,
                [breakpoint.down("xl")]: {
                    display: "flex",
                },
            },
            loader: {
                margin: `${spacing(10)}px auto`,
            },
            sectorTagsWrapper: {
                maxWidth: "calc(100% - 140px)",
                marginTop: 4,
                cursor: "text",
            },
            scoreMatchWrap: {
                position: "absolute",
                top: 0,
                right: 142,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: -20,
                [breakpoint.down("xl")]: {
                    position: "relative",
                    top: "auto",
                    right: "auto",
                    marginTop: 0,
                    justifyContent: "flex-start",
                },
            },
            titleWrap: {
                display: "flex",
                flexDirection: "column",
                [breakpoint.down("xl")]: {
                    marginTop: -6,
                },
            },
            infoIconNew: {
                marginLeft: spacing(1),
            },
            arrowUp: {
                position: "fixed",
                bottom: 10,
                left: 120,
                fill: "#0B295F",
                cursor: "pointer",
                zIndex: 2,
                "&:hover": {
                    fill: "#485E87",
                },
            },
            resultsLoader: {
                width: "100%",
            },
            searchesLoader: {
                width: "100%",
                height: "235px",
            },
            formFieldsWrapper: {
                // maxHeight: 287,
                overflowY: "auto",
                marginBottom: spacing(3),
            },
            ctasWrapper: {
                display: "flex",
                flexDirection: "row",
            },
            createAccountButton: {
                margin: `0 ${spacing(2)}px 0 0`,
            },
            signInCopy: {
                textAlign: "left",
                marginBottom: 6,
            },
            form: {
                marginTop: spacing(2),
            },
            signUpBtn: {
                marginTop: 4,
            },
            passwordInputWrapper: {
                marginBottom: spacing(3),
                width: "calc(50% - 8px)",
            },
            confirmPasswordInputWrapper: {
                marginBottom: spacing(3),
                marginLeft: spacing(2),
                width: "calc(50% - 8px)",
            },
            [breakpoint.down("md")]: {
                guestBlock: {
                    padding: "24px 24px 62px 24px",
                },
                ctasWrapper: {
                    flexDirection: "column",
                },
                signInCopy: {
                    textAlign: "center",
                    marginBottom: spacing(2),
                },
                signUpBtn: {
                    marginBottom: spacing(4),
                },
                formFieldsWrapper: {
                    maxHeight: "none",
                },
                checkboxRoot: {
                    alignSelf: "flex-start",
                    paddingTop: 3,
                },
                checkboxLabel: {
                    textAlign: "left",
                },
                confirmPasswordInputWrapper: {
                    width: "100%",
                    marginBottom: spacing(3),
                    marginLeft: 0,
                },
                passwordInputWrapper: {
                    width: "100%",
                    marginBottom: spacing(3),
                },
            },
            lookAlikeBetaMessage: {
                // background: palette.success.backgroundLight,
                padding: `0 ${spacing(2)}px ${spacing(3)}px ${spacing(2)}px`,
                borderRadius: spacing(2),
                display: "flex",
                alignItems: "center",
                position: "relative",
                paddingRight: "6%",
                paddingLeft: "6%",
                width: "100%",
                background: "transparent",
                "& $closeIcon": {
                    position: "absolute",
                    top: spacing(2),
                    right: spacing(2),
                    display: "none",
                    maxWidth: 12,
                    maxHeight: 12,
                    [breakpoint.down("sm")]: {
                        display: "block",
                    },
                },
            },
            lookAlikeMessageInfoIcon: {
                marginRight: spacing(2),
                width: 34,
                [breakpoint.down("md")]: {
                    display: "none",
                },
            },
            mono: {
                fontFamily: "GT Flexa Mono, sans-serif",
            },
            lookAlikeLink: {
                color: palette.primary.main,
                textDecoration: "underline",
                cursor: "pointer",
            },
            mb16: {
                marginBottom: spacing(2),
            },
            noClick: {
                pointerEvents: "none",
            },
        }),
);
