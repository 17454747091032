import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, breakpoint }) =>
    createStyles({
        root: {
            padding: `${spacing(1)}px ${spacing(8)}px ${spacing(1)}px ${spacing(
                8,
            )}px`,
        },
        main: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            maxWidth: 351,
            alignItems: "center",
        },
        title: {
            marginBottom: spacing(3),
        },
        headerInput: {
            [breakpoint.down("md")]: {
                width: "100%",
            },
            "& input": {
                width: 288,

                [breakpoint.down("md")]: {
                    width: "100%",
                },
            },
            marginTop: spacing(2),
        },
        changeEmailText: {
            color: palette.grey.main,
            marginTop: spacing(2),
            marginBottom: spacing(2),
        },
        haveQuestionWrapText: {
            display: "flex",
            justifyContent: "center",
        },
        haveQuestion: {
            color: palette.grey.main,
        },
        reachOut: {
            color: palette.primary.main,
            textDecoration: "underline",
            marginLeft: spacing(1),
            cursor: "pointer",
        },
        changeButton: {
            marginTop: spacing(3),
            width: 170,
        },
        emailErrorText: {
            display: "flex",
            justifyContent: "center",
            width: "100%",
        },
        invalidEmail: {
            "& div": {
                borderColor: palette.error.dark,
            },
        },
        errorText: {
            color: palette.error.dark,
        },
    }),
);
