import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const postLoginRequest = createAction(
    `${REDUCER_PREFIX}/postLoginRequest`,
);

export const postLogoutRequest = createAction(
    `${REDUCER_PREFIX}/postLogoutRequest`,
);

export const getMeRequest = createAction(`${REDUCER_PREFIX}/getMeRequest`);

export const getMeStealthRequest = createAction(
    `${REDUCER_PREFIX}/getMeStealthRequest`,
);

export const updateProfileRequest = createAction(
    `${REDUCER_PREFIX}/updateProfileRequest`,
);

export const registerRequest = createAction(
    `${REDUCER_PREFIX}/registerRequest`,
);

export const subscribeRequest = createAction(
    `${REDUCER_PREFIX}/subscribeRequest`,
);

export const sendResetLinkRequest = createAction(
    `${REDUCER_PREFIX}/sendResetLinkRequest`,
);

export const checkResetLinkRequest = createAction(
    `${REDUCER_PREFIX}/checkResetLinkRequest`,
);

export const resetPasswordRequest = createAction(
    `${REDUCER_PREFIX}/resetPasswordRequest`,
);

export const fetchCookieRequest = createAction(
    `${REDUCER_PREFIX}/fetchCookieRequest`,
);

export const getTeamRequest = createAction(`${REDUCER_PREFIX}/getTeamRequest`);

export const leaveTeamRequest = createAction(
    `${REDUCER_PREFIX}/leaveTeamRequest`,
);

export const removeMemberRequest = createAction(
    `${REDUCER_PREFIX}/removeMemberRequest`,
);

export const removeInvitationRequest = createAction(
    `${REDUCER_PREFIX}/removeInvitationRequest`,
);

export const fetchInvitationRequest = createAction(
    `${REDUCER_PREFIX}/fetchInvitationRequest`,
);

export const handleInvitationRequest = createAction(
    `${REDUCER_PREFIX}/handleInvitationRequest`,
);

export const fetchAccountRequest = createAction(
    `${REDUCER_PREFIX}/fetchAccountRequest`,
);

export const openClusterMapRequest = createAction(
    `${REDUCER_PREFIX}/openClusterMapRequest`,
);
