import React from "react";
import { useStyles } from "../style.js";
import Typography from "../../Typography/Typography";
import personUrl from "../../../assets/images/chat/PumaPersonIcon.svg";
import { ReactComponent as PersonIcon } from "../../../assets/images/chat/personIcon.svg";

const UserContent = ({ text }) => {
    const classes = useStyles();

    return (
        <div className={classes.userContent}>
            <div className={classes.messageContainer}>
                <div className={classes.messageContent}>
                    <div className={classes.userImgWrapper}>
                        <PersonIcon className={classes.userImg} />
                    </div>
                    <div className={classes.userText}>
                        <Typography>{text}</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserContent;
