import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Menu as MuiMenu } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import useStyles from "./style";
import classnames from "classnames";

const Menu = ({
    children,
    btnClassName,
    menuClassName = null,
    onChange,
    menuItems,
    additionalMenuItems,
    align = "right",
    otherItems,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const [isMobileAdditionalMenuOpened, setIsMobileAdditionalMenuOpened] =
        useState(false);

    const handleClick = event => {
        event.stopPropagation();
        event.preventDefault();
        onChange();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = e => {
        e && e.stopPropagation && e.stopPropagation();
        onChange();
        setAnchorEl(null);
        setIsMobileAdditionalMenuOpened(false);
    };

    const handleItemClick = (e, item) => {
        e.stopPropagation && e.stopPropagation();
        const { onClick, stopClose, disabled } = item;
        !disabled && onClick(e);
        if (!stopClose && !disabled) {
            if (!item.additionalMenuOpener) {
                handleClose();
            } else {
                onChange();
                setIsMobileAdditionalMenuOpened(true);
            }
        }
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className={btnClassName}>
                <>{children}</>
            </Button>
            <MuiMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: align }}
                transformOrigin={{ vertical: "top", horizontal: align }}
                classes={{ paper: menuClassName || classes.menu }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}>
                {!isMobileAdditionalMenuOpened &&
                    menuItems.map((item, index) => (
                        <MenuItem
                            data-intercom-target={item.intercomId}
                            key={`menu-item-${index}`}
                            className={classnames([
                                { [classes.disabled]: item.disabled },
                            ])}
                            onClick={e => handleItemClick(e, item)}>
                            {item.label}
                        </MenuItem>
                    ))}
                {isMobileAdditionalMenuOpened &&
                    additionalMenuItems &&
                    additionalMenuItems.length &&
                    additionalMenuItems.map((item, index) => (
                        <MenuItem
                            data-intercom-target={item.intercomId}
                            key={`menu-item-${index}`}
                            className={classnames([
                                { [classes.disabled]: item.disabled },
                            ])}
                            onClick={e => handleItemClick(e, item)}>
                            {item.label}
                        </MenuItem>
                    ))}
                {otherItems}
            </MuiMenu>
        </div>
    );
};

export default Menu;
